import MaterialTable from "material-table";
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import AddIconButton from "../../buttons/AddIconButton";
export class GstUserList extends Component {
  title = "Gst User List";
  breadCrumb = [
    { pageid: "/filing", uiname: "Filing", cssclass: "fa fa-files-o" },
    { pageid: "/gstr1usermgmt", uiname: "Gst User List" },
  ];
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      showAddcustomer: false,
      customerList: "",
      userList: [],
      pageSize: 10,
      isPaging: false,
      initialStep: 0,
    };
    this.getUserListData();
  }

  getUserListData() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/gstr1cread/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let userListArray = output.data;
          let isPaging = false;
          if (userListArray.length > 10) {
            isPaging = true;
          }
          this.setState({
            userList: userListArray,
            isPaging: isPaging,
          });
        } else {
        }
      }
    );
  }

  userDelete = (e, data) => {
    const header = store.getState().header.header;

    let userId = data.id;
    const postObject = {
      credential: {
        id: userId,
      },
      header: header,
    };

    FetchServerData.callPostService("/gstr1cread/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let userData = output.data;
          this.props.history.push({
            pathname: "/gstr1usermgmt",
            breadCrumb: this.breadCrumb,
          });
        } else {
        }
      }
    );
  };

  addUser = (e) => {
    this.props.history.push({
      pathname: "/gstr1usercreate",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  userEdit = (e, data) => {
    let userId = data.id;
    this.props.history.push({
      pathname: "/gstr1usercreate",
      state: { userId: userId, action: "edit", breadCrumb: this.breadCrumb },
    });
  };

  userView = (e, data) => {
    let userId = data.id;
    this.props.history.push({
      pathname: "/gstr1userview",
      state: { userId: userId, action: "view", breadCrumb: this.breadCrumb },
    });
  };

  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />

        <Row md={12} style={{ marginTop: "2em" }}>
          <Col md={12} style={{ width: "100%" }}>
            {/* <CardBody> */}
            <MaterialTable
              icons={this.tableIcons}
              localization={{
                body: {
                  emptyDataSourceMessage: Utils.communizeTableText(),
                },
              }}
              title=""
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "User",
                  field: "username",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "GSTN",
                  field: "gstin",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "Filing Frequency",
                  render: (rowData) =>
                    rowData.filefrequency
                      ? rowData.filefrequency.frequency
                      : "",
                  align: "left",
                  width: "20%",
                },
              ]}
              data={this.state.userList}
              // options={{
              //   sorting: true,
              //   filtering: true,
              //   actionsColumnIndex: -1,
              //   headerStyle: { backgroundColor: '#1b7189', fontWeight: 'bold', color: '#fff', align: 'center' },
              // }}
              // style={{
              //   boder: '1px solid black',
              // }}
              options={{
                sorting: true,
                paging: this.state.isPaging,
                pageSize: this.state.pageSize,
                initialPage: this.state.initialPage,
                pageSizeOptions: Utils.materialTablePageSize(),
                actionsColumnIndex: -1,
                // pageSize: 10,
                headerStyle: {
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: {},
              }}
              style={{
                boder: "1px solid black",
              }}
              actions={[
                {
                  icon: () => <AddIconButton />,
                  tooltip: "Add User",
                  isFreeAction: true,
                  onClick: (event) => this.addUser(),
                },
              ]}
              onRowClick={(e, rowData) => this.userView(e, rowData)}
            />
            {/* </CardBody> */}
          </Col>
        </Row>
      </>
    );
  }
}
export default GstUserList;
