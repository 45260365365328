import { GET_STATIC_DATA, SET_STATIC_DATA } from './staticDataTypes';

export const getStaticData = () => {
  return {
    type: GET_STATIC_DATA,
  };
};
export const setStaticData = data => {
  return {
    type: SET_STATIC_DATA,
    payload: data,
  };
};
const staticDataAction = {
  getStaticData,
  setStaticData,
}
export default staticDataAction;
