import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import ReactApexCharts from "react-apexcharts";
import DashboardMenu from "./DashboardMenu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import appDataAction from "../../../redux/appData/appDataAction";
import "./Dashboard.scss";
class Dashboard extends Component {
  businessUnitsArray = store.getState().appData.businessUnitList;
  defaultCountry = store.getState().organization.organization.defaultCountry;
  financialsYearArray = store.getState().appData.fyList;
  recordCounts = store.getState().appData.recordCount;
  constructor(props) {
    super(props);
    this.state = {
      showSuccessAlert: false,
      showFailAlert: false,
      dashboardSubMenus: [],
      monthlyFinancials: [],
      monthlyFinancialOptions: [],
      monthlyFinancialSeries: [],
      monthlyGSTAnalysisSeries: [],
      monthlyGSTAnalysisOptions: [],
      inputValue: "",
      financialsYear:
        this.financialsYearArray && this.financialsYearArray.length > 0
          ? this.financialsYearArray[0]
          : "",
      businessUnit:
        this.businessUnitsArray && this.businessUnitsArray.length > 0
          ? this.businessUnitsArray[0].code
          : "",
      data: [],
      render: false,
    };
    this.getFinancialsYearDeatils();
  }

  toggleProBanner() {
    document.querySelector(".proBanner").classList.toggle("hide");
  }
  updateFinancialYear(e) {
    this.setState({ financialsYear: e.target.value });
  }
  updateBusinessUnit(e) {
    this.setState({ businessUnit: e.target.value });
  }

  async setFinancialsYearDeatils(postObject) {
    return new Promise(function (accept, reject) {
      FetchServerData.callPostService(
        "/dashboardms/getFinancialData",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          accept(data);
        } else {
          reject(output);
        }
      });
    });
  }

  async getFinancialsYearDeatils(e) {
    if (
      this.state.financialsYear === null ||
      this.state.financialsYear === ""
    ) {
      alert("Please select Financial Year");
      return;
    }
    if (this.state.businessUnit === null || this.state.businessUnit === "") {
      alert("Please select Business Unit");
      return;
    }

    let header = store.getState().header.header;

    const postObject = {
      fyear: this.state.financialsYear,
      bucode: this.state.businessUnit,
      header: header,
    };
    let output = await this.setFinancialsYearDeatils(postObject);
    let monthlyGraphData = output.monthlyGraphData;
    let recordCounts = output.recordcount;
    let salesLabels = [];
    let salesValue = [];
    let puchaseValue = [];
    let expenseValue = [];
    let gstliabilityValue = [];
    let gstcreditValue = [];
    let gstpayableValue = [];
    let defaultCountryCode = this.defaultCountry?.code;
    let defaultCurrency = this.defaultCountry?.currency;
    for (let i = 0; i < monthlyGraphData.length; i++) {
      let date =
        Utils.toCamelCaseUL(monthlyGraphData[i].month) +
        "-" +
        monthlyGraphData[i].year.toString().slice(2, 4);
      salesLabels.push(date);
      salesValue.push(monthlyGraphData[i].sales);
      puchaseValue.push(monthlyGraphData[i].purchase);
      expenseValue.push(monthlyGraphData[i].expense);
      gstliabilityValue.push(monthlyGraphData[i].gstliability);
      gstcreditValue.push(monthlyGraphData[i].gstcredit);
      gstpayableValue.push(monthlyGraphData[i].gstpayable);
    }

    let subMenuDashboard = [];
    let menu;
    if (this.props.location && this.props.location.pathname) {
      if (this.props.location.pathname === "/dashboard") {
        this.props.location.pathname = "/dashboard/";
      }
      menu = Utils.getMenu(this.props.location.pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    if (menu) {
      this.breadCrumb = Utils.getMenuHierarchy(menu.pageid);

      store.dispatch(appDataAction.setActiveMenu(menu.id));
    }
    let bgColor = ["#48d1cc", "#6fa26f", "#d86f6f", "#4682b4", "#cd853f"];
    let bgColorDark = ["#27a9a4", "#337b33", "#944d4d", "#305371", "#9a632d"];
    let pageUrl = ["/cust", "/sales", "/supp", "/purchase", "/expense"];
    let bgColorClass = [
      "customerCount",
      "salesCount",
      "supplierCount",
      "purchaseCount",
      "purchaseNogGstCount",
    ];
    let bgColorDarkClass = [
      "customerCountDark",
      "salesCountDark",
      "supplierCountDark",
      "purchaseCountDark",
      "purchaseNogGstCountDark",
    ];
    if (menu) {
      subMenuDashboard = Utils.getSubMenus(menu.id);
      for (let i = 0; i < subMenuDashboard.length; i++) {
        // subMenuDashboard.forEach((menu, index) => {
        let element = subMenuDashboard[i];
        let count = 0;
        switch (element.menuid) {
          case "Customer":
            count = recordCounts.customercount;
            break;
          case "Supplier":
            count = recordCounts.suppliercount;
            break;
          case "Sales":
            count = recordCounts.invoicecount;
            break;
          case "Purchase":
            count = recordCounts.purchaseinvoicecount;
            break;
          case "Expense":
            count = recordCounts.expensecount;
            break;
          default:
            break;
        }
        // menu.count = count;
        subMenuDashboard[i].count = count; //.push(menu);
      }
      subMenuDashboard.sort((a, b) => a.displaysequence - b.displaysequence);
      for (let i = 0; i < subMenuDashboard.length; i++) {
        subMenuDashboard[i].bgColor = bgColor[i];
        subMenuDashboard[i].bgColorDark = bgColorDark[i];
        subMenuDashboard[i].bgColorClass = bgColorClass[i];
        subMenuDashboard[i].bgColorDarkClass = bgColorDarkClass[i];
        subMenuDashboard[i].pageUrl = pageUrl[i];
      }
      this.state.dashboardSubMenus = subMenuDashboard;
      this.setState({ dashboardSubMenus: subMenuDashboard });
    }

    this.setState({
      monthlyFinancials: monthlyGraphData,
      render: true,
      monthlyFinancialSeries: [
        {
          name: "Sales",
          type: "bar",
          data: salesValue,
          // marginTop: "10px"
        },
        {
          name: "Purchase",
          type: "bar",
          data: puchaseValue,
        },
        {
          name: "Expense",
          type: "bar",
          data: expenseValue,
        },
      ],

      monthlyFinancialOptions: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 5,
          hover: {
            size: 9,
          },
        },
        xaxis: {
          // type: 'datetime',
          labels: {
            rotate: -45,
          },
          tickPlacement: "on",
          categories: salesLabels,
          axisBorder: {
            show: true,
            color: "#1B7189",
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#1B7189",
            },
            labels: {
              formatter: function (val) {
                if (Utils.equalsIgnoreCase("IND", defaultCountryCode)) {
                  return Utils.indianNumberSystemForLable(val);
                } else {
                  return Utils.internationalNumberSystemForLable(val);
                }
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            format: "dd MMM yyyy HH:mm",
          },
          y: {
            formatter: function (val) {
              if (Utils.equalsIgnoreCase("IND", defaultCountryCode)) {
                return (
                  defaultCurrency?.symbol +
                  " " +
                  Utils.indianNumberSystemForTooltip(val)
                );
              } else {
                return (
                  defaultCurrency?.symbol +
                  " " +
                  Utils.internationalNumberSystemForTooltip(val)
                );
              }
            },
          },
        },
      },

      monthlyGSTAnalysisSeries: [
        {
          name: "GST Liability",
          type: "bar",
          data: gstliabilityValue,
          marginTop: "10px",
        },
        {
          name: "GST Credit",
          type: "bar",
          data: gstcreditValue,
        },
        {
          name: "GST Payable",
          type: "bar",
          data: gstpayableValue,
        },
      ],

      monthlyGSTAnalysisOptions: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 5,
          hover: {
            size: 9,
          },
        },
        xaxis: {
          // type: 'datetime',
          labels: {
            rotate: -45,
          },
          tickPlacement: "on",
          categories: salesLabels,
          axisBorder: {
            show: true,
            color: "#1B7189",
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#1B7189",
            },
            labels: {
              formatter: function (val) {
                if (Utils.equalsIgnoreCase("IND", defaultCountryCode)) {
                  return Utils.indianNumberSystemForLable(val);
                } else {
                  return Utils.internationalNumberSystemForLable(val);
                }
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            format: "dd MMM yyyy HH:mm",
          },
          y: {
            formatter: function (val) {
              if (Utils.equalsIgnoreCase("IND", defaultCountryCode)) {
                return (
                  defaultCurrency?.symbol +
                  " " +
                  Utils.indianNumberSystemForTooltip(val)
                );
              } else {
                return (
                  defaultCurrency?.symbol +
                  " " +
                  Utils.internationalNumberSystemForTooltip(val)
                );
              }
            },
          },
        },
      },
    });
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    }
    const menuCard = [];
    this.state.dashboardSubMenus.forEach((t) => {
      t.isactive &&
        menuCard.push(
          <Col md={2} style={{ marginRight: "2.8em" }} className="cardCol">
            <DashboardMenu key={t.id} data={t} history={this.props.history} />
          </Col>
        );
    });
    return (
      <div md={12} style={{ padding: "0.5em", marginTop: "10px" }}>
        <Row md={12} className="dashboardRow">
          {menuCard}
        </Row>
        <Row
          md={12}
          style={{ alignItems: "flex-end", marginTop: "2em", padding: "5px" }}
        >
          <Col md={2} style={{ marginTop: "auto", marginBottom: "auto" }}>
            <InputLabel style={{ float: "right", color: "#000" }}>
              <strong>Financial Year</strong>
            </InputLabel>
          </Col>
          <Col md={2}>
            <Select
              // style={{ width: "-webkit-fill-available" }}
              id="financialYear"
              className="selectDropdown"
              value={this.state.financialsYear}
              variant="outlined"
              style={{ background: "#fff", borderRadius: "5px" }}
              fullWidth
              onChange={this.updateFinancialYear.bind(this)}
              input={<OutlinedInput />}
            >
              {this.financialsYearArray.map((e, keyIndex) => {
                return (
                  <MenuItem key={keyIndex} value={e}>
                    {e}
                  </MenuItem>
                );
              })}
            </Select>
          </Col>
          <Col md={2} style={{ marginTop: "auto", marginBottom: "auto" }}>
            <InputLabel style={{ float: "right", color: "#000" }}>
              <strong>Business Unit</strong>
            </InputLabel>
          </Col>
          <Col md={2}>
            <Select
              // style={{ width: "-webkit-fill-available" }}
              className="selectDropdown"
              id="businessUnit"
              fullWidth
              style={{ background: "#fff", borderRadius: "5px" }}
              variant="outlined"
              value={this.state.businessUnit}
              onChange={this.updateBusinessUnit.bind(this)}
              input={<OutlinedInput />}
            >
              {this.businessUnitsArray.map((e, keyIndex) => {
                return (
                  <MenuItem key={keyIndex} value={e.code}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Col>
          <Col
            md={2}
            style={{ marginTop: "auto", marginBottom: "auto" }}
            id="btnCol"
          >
            <Button
              variant="contained"
              size="small"
              style={{ background: "#1b7189", color: "#fff" }}
              onClick={(e) => this.getFinancialsYearDeatils()}
            >
              Update
            </Button>
          </Col>
        </Row>
        {/* <Row md={12} style={{ minHeight: "2em" }}></Row> */}
        <div className="row d-flex mt-4">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row mb-12 row d-flex justify-content-around">
                  <h5 className="text-start mb-0">
                    Sales, Purchase & Expense{" "}
                  </h5>
                  <div className="text-start" id="chart">
                    <ReactApexCharts
                      options={this.state.monthlyFinancialOptions}
                      series={this.state.monthlyFinancialSeries}
                      type="bar"
                      height={350}
                      width="90%"
                      info="Sales and Purchase includes Outward and Inward Supply of Capital Asset if Any."
                    />
                    <div className="row">
                      <span
                        className="text-center"
                        style={{ fontSize: "13px", color: "black" }}
                      >
                        <b>Note:</b>&nbsp;Sales and Purchase includes Outward
                        and Inward Supply of Capital Asset if Any.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" id="gstAnalysisCard">
              <div className="card-body">
                <div className="row mb-12 row d-flex">
                  <h5 className="text-start mb-0">GST Analysis</h5>
                  <div className="text-start">
                    <ReactApexCharts
                      options={this.state.monthlyGSTAnalysisOptions}
                      series={this.state.monthlyGSTAnalysisSeries}
                      type="bar"
                      height={350}
                      width="90%"
                    />
                    <div className="row">
                      <span
                        className="text-center"
                        style={{ fontSize: "13px", color: "black" }}
                      >
                        <b>Note:</b>&nbsp;GST Liability Includes Tax Payable on
                        Inward Supply Liable to RCM.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
