import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { Col, Label, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Address.scss";
class Address extends Component {
  header = store.getState().header.header;
  countryListArray = store.getState().staticData.countryList;
  stateListArray = [];
  constructor(props) {
    super(props);
    let address = props.data;
    let action = props.action;
    let pageId = "";
    let defaultCountry =
      this.countryListArray[
        this.countryListArray.findIndex(
          (i) => i.code.toLowerCase() === "ind".toLowerCase()
        )
      ];
    if (props.state) {
      action = props.state.action;
      pageId = props.state.pageId;
    }
    if (action && Utils.equalsIgnoreCase(action, "create")) {
      this.state = {
        id: "",
        line1: "",
        country: defaultCountry,
        state: null,
        city: "",
        pincode: "",
        latitude: "",
        longitude: "",
        pageId: pageId,
        action: this.props.action,
        onChange: this.props.onChange,
        render: true,
      };
      this.handleCountryChange(defaultCountry);
    } else {
      if (address) {
        if (address.statevo) {
          this.state = {
            address: address,
            id: address.id ? address.id : "",
            line1: null === address.line1 ? "" : address.line1,
            state: address.statevo ? address.statevo : "",
            selectedState: address.statevo ? address.statevo : "",
            country: address.statevo ? address.statevo.countryvo : "",
            city: address.city ? address.city : "",
            pincode: address.pin ? address.pin : "",
            stateClear: false,
            action: this.props.action,
            onChange: this.props.onChange,
            render: true,
          };
          this.handleCountryChange(address.statevo?.countryvo);
        } else {
          if (address.state?.countryvo) {
            this.state = {
              address: address,
              id: address.id ? address.id : "",
              line1: null === address.line1 ? "" : address.line1,
              state: address.state ? address.state : "",
              selectedState: address.state ? address.state : "",
              country: address.state ? address.state.countryvo : "",
              city: address.city ? address.city : "",
              pincode: address.pin ? address.pin : "",
              stateClear: false,
              action: this.props.action,
              onChange: this.props.onChange,
              render: true,
            };
            this.handleCountryChange(address.state.countryvo);
          } else {
            this.state = {
              address: address,
              id: address.id ? address.id : "",
              line1: null === address.line1 ? "" : address.line1,
              state: address.state ? address.state : "",
              selectedState: address.state ? address.state : "",
              country: address.state ? address.state.country : "",
              city: address.city ? address.city : "",
              pincode: address.pin ? address.pin : "",
              stateClear: false,
              action: this.props.action,
              onChange: this.props.onChange,
              render: true,
            };
            this.handleCountryChange(address?.state?.country);
          }
        }
      } else {
        this.state = {
          id: "",
          line1: "",
          country: defaultCountry,
          state: null,
          city: "",
          pincode: "",
          latitude: "",
          longitude: "",
          pageId: pageId,
          action: this.props.action,
          onChange: this.props.onChange,
          render: true,
        };
        this.handleCountryChange(defaultCountry);
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props?.data?.state !== this.state.state) {
      this.setState({
        address: props?.data,
        state: props?.data?.state ? props?.data?.state : props?.data?.statevo,
        line1: props?.data?.line1,
        city: props?.data?.city,
        pincode: props?.data?.pin,
      });
    }
  }

  setStatesForCountry(selectedCountry) {
    this.setState({
      country: selectedCountry,
      selectedCountry: selectedCountry,
    });
  }

  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleLine1Change(e) {
    this.setState({ line1: e.target.value });
  }

  handleLine2Change(e) {
    this.setState({ line2: e.target.value });
  }

  handleCityChange(e) {
    let val = Utils.toCamelCaseUL(e.target.value);
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ city: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handlePinCodeChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 16);
    if (res.length < 16) {
      this.setState({ pincode: res });
    } else {
      Utils.maxFieldLength(16);
    }
  }

  handleCountryChange(selectedCountry) {
    if (null === selectedCountry || selectedCountry === "") {
      this.stateListArray = [];
      this.setState({
        stateClear: !this.state.stateClear,
      });
      return;
    }
    this.state.country = selectedCountry;
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }

    this.setState({
      country: selectedCountry,
      state: null,
    });

    const postObject = {
      countryVo: {
        id: selectedCountry?.id,
        code: selectedCountry?.code,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/staticms/getstates", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let stateTimezoneList = output.data;
          this.stateListArray = stateTimezoneList;
          this.setState({
            render: true,
          });
        } else {
        }
      }
    );
  }
  validateAddress() {
    if (Utils.isNullOrEmpty(this.state.country)) {
      document.getElementById("country").focus();
      PtsAlert.error("Please Select Country");

      return false;
    }
    if (Utils.isNullOrEmpty(this.state.state)) {
      document.getElementById("state").focus();
      PtsAlert.error("Please Select State");
      return false;
    }
    return true;
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      this.countryRef = React.createRef();
      this.stateRef = React.createRef();
      if (this.props.action === "create") {
        return this.createAddress();
      } else if (this.props.action === "edit") {
        return this.editAddress();
      } else {
        return this.viewAddress();
      }
    }
  }
  editAddress() {
    return (
      <>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row md={12}>
            <Col md={12}>
              <label
                style={{
                  fontWeight: "600",
                  color: "#1b7189",
                  marginLeft: "-0.5em",
                  fontSize: "15px",
                }}
              >
                <b>{this.props.title}</b>
              </label>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Address Line
            </label>

            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="line1"
              variant="outlined"
              placeholder="Address Line"
              value={this.state.line1}
              onChange={this.handleLine1Change.bind(this)}
              size="small"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Country<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              size="small"
              id="country"
              ListboxProps={{
                className: "myCustomList",
              }}
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.select}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Country *"
                  variant="outlined"
                  required
                  className="regCountry"
                />
              )}
            />
          </Col>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              State<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              id="country"
              ListboxProps={{
                className: "myCustomList",
              }}
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) => option.name}
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="State *"
                  variant="outlined"
                  size="small"
                  className="regCountry"
                  required
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              City<span className="asterisk"> *</span>
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              size="small"
              id="basic-default-city"
              variant="outlined"
              placeholder="City"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                if (Utils.isNotNull(this.props.onChange)) {
                  this.props.onChange(this.state);
                }
              }}
              name="city"
              inputprops={{
                maxLength: 128,
                style: { textTransform: "capitalize" },
              }}
            />
          </Col>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              ZipCode
            </label>
            <TextField
              type="text"
              size="small"
              className="textField mt-1"
              fullWidth
              id="basic-default-postalcode"
              variant="outlined"
              placeholder="Postal Code"
              value={this.state.pincode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputprops={{ maxLength: 16 }}
              onBlur={() => {
                if (Utils.isNotNull(this.props.onChange)) {
                  this.props.onChange(this.state);
                }
              }}
              name="zipCode"
            />
          </Col>
        </Row>
      </>
    );
  }

  viewAddress() {
    let stateName =
      null !== this.state.state && undefined !== this.state.state
        ? this.state.state.name
        : "";
    let countryName =
      null !== this.state.country && undefined !== this.state.country
        ? this.state.country.name
        : "";
    let statecountryName = stateName + "," + countryName;
    let title = "";
    if (this.props.title && this.props.title.length > 0) {
      title = (
        <div md={12}>
          <label
            style={{ fontWeight: "bold", color: "#1b7189", fontSize: "15px" }}
          >
            {this.props.title}
          </label>
        </div>
      );
    }
    return (
      <>
        <div style={{ width: "100%" }}>
          {title}
          <div md={12}>
            {!this.state.address ? (
              <span style={{ color: "#000" }}>
                {Utils.getAddressAsText(this.state)}{" "}
              </span>
            ) : (
              <span style={{ color: "#000" }}>
                {Utils.getAddressAsText(this.state.address)}{" "}
              </span>
            )}
          </div>
        </div>
        {/* <Row md={12} className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>
              {undefined !== this.state.line ? this.state.line : ""}
            </Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>
              {undefined !== this.state.city ? this.state.city : ""}
            </Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>{statecountryName} </Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>{this.state.zipCode}</Label>
          </Col>
        </Row> */}
      </>
    );
  }

  createAddress() {
    return (
      <>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row md={12}>
            <Col md={12}>
              <label
                style={{
                  fontWeight: "600",
                  color: "#1b7189",
                  marginLeft: "-0.5em",
                  fontSize: "15px",
                }}
              >
                <b>{this.props.title}</b>
              </label>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Address Line
            </label>
            <TextField
              size="small"
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-line"
              variant="outlined"
              placeholder="Address Line"
              value={this.state.line1}
              onChange={this.handleLine1Change.bind(this)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Country<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              size="small"
              id="country"
              ListboxProps={{
                className: "myCustomList",
              }}
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.select}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Country *"
                  variant="outlined"
                  required
                  className="regCountry"
                />
              )}
            />
          </Col>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              State<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              id="country"
              ListboxProps={{
                className: "myCustomList",
              }}
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) => option.name}
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="State *"
                  variant="outlined"
                  size="small"
                  className="regCountry"
                  required
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              City<span className="asterisk"> *</span>
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-city"
              variant="outlined"
              placeholder="City"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                if (Utils.isNotNull(this.props.onChange)) {
                  this.props.onChange(this.state);
                }
              }}
              size="small"
              name="city"
              inputprops={{
                maxLength: 128,
                style: { textTransform: "capitalize" },
              }}
            />
          </Col>
          <Col md={6} style={{ padding: "0.5em" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              ZipCode
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-postalcode"
              variant="outlined"
              placeholder="Postal Code"
              value={this.state.pincode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputprops={{ maxLength: 16 }}
              onBlur={() => {
                if (Utils.isNotNull(this.props.onChange)) {
                  this.props.onChange(this.state);
                }
              }}
              name="zipCode"
              size="small"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default Address;
