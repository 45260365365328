import MaterialTable from "material-table";
import React, { Component } from "react";
import { Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";

export class ExpenseSupplierList extends Component {
  expenseSupplierMasterState =
    store.getState().applicationState.expenseSupplierMasterState;
  expenseSupplierMasterStateDetails = {};
  breadCrumb = [
    { pageid: "/master", uiname: "Master", cssclass: "fa fa-book" },
    { pageid: "/expensesupplier", uiname: "Expense Supplier List" },
  ];
  title = "Expense Supplier List";

  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();

    if (this.expenseSupplierMasterState) {
      this.expenseSupplierMasterStateDetails = Object.getOwnPropertyNames(
        this.expenseSupplierMasterState
      );
    }
    if (this.expenseSupplierMasterStateDetails.length > 0) {
      this.state = {
        expenseSupplierList: [],
        privileges: [],
        expenseSupplierId: this.expenseSupplierMasterState.expenseSupplierId,
        pageSize: this.expenseSupplierMasterState.pageSize,
        initialPage: this.expenseSupplierMasterState.initialPage,
        isPaging: false,
        render: false,
      };
    } else {
      this.state = {
        expenseSupplierList: [],
        privileges: [],
        pageSize: 10,
        initialStep: 0,
        isPaging: false,
        render: false,
      };
    }
    this.getExpenseSupplierList();
  }

  getExpenseSupplierList() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService(
      "/expenseSupplierms/getList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseSupplierList = output.data.expenseSupplierList;
        let privileges = output.data.privileges;
        let isPaging = false;
        if (expenseSupplierList.length > 10) {
          isPaging = true;
        }
        this.setState({
          render: true,
          isPaging: isPaging,
          expenseSupplierList: expenseSupplierList,
          privileges: privileges,
        });
      }
    });
  }

  addSupplier = (e) => {
    this.props.history.push({
      pathname: "/exsupplier",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  toSupplierDetails = (e, data) => {
    let expenseSupplierId = data.id;
    let expenseSupplierMasterState = {
      expenseSupplierId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ expenseSupplierMasterState })
    );
    this.props.history.push({
      pathname: "/exsupplierview",
      state: {
        expenseSupplierId: expenseSupplierId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  cancelView() {
    this.props.history.push({ pathname: "/expensesupplier/" });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Supplier",
        isFreeAction: true,
        onClick: (event) => this.addSupplier(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <Row md={12} style={{ margin: 0, padding: 0 }}>
          <div style={{ width: "100%", padding: "1em" }}>
            <MaterialTable
              icons={this.tableIcons}
              title="Expense Supplier List"
              localization={{
                body: {
                  emptyDataSourceMessage: Utils.communizeTableText(),
                },
              }}
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Name",
                  field: "name",
                  align: "left",
                  cellStyle: {
                    width: "25%",
                  },
                },
                {
                  title: "Country",
                  field: "countryName",
                  align: "left",
                  cellStyle: {
                    width: "40%",
                  },
                },
                {
                  title: "Currency",
                  render: (rowData) =>
                    `${rowData.currencyName} (${rowData.currencySymbol})`,
                  align: "left",
                  cellStyle: {
                    width: "40%",
                  },
                },
              ]}
              data={this.state.expenseSupplierList}
              options={{
                paging: this.state.isPaging,
                pageSize: this.state.pageSize,
                initialPage: this.state.initialPage,
                pageSizeOptions: Utils.materialTablePageSize(),
                sorting: true,
                actionsColumnIndex: -1,
                headerStyle: {
                  // overflow: "scroll",
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.id === this.state.expenseSupplierId
                      ? "#FEF5E7"
                      : "",
                  height: "3em",
                  textTransform: "capitalize",
                }),
              }}
              onChangePage={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              actions={actionList}
              onRowClick={(e, rowData) => this.toSupplierDetails(e, rowData)}
            />
          </div>
        </Row>
      </>
    );
  }
}

export default ExpenseSupplierList;
