import {
  GET_APP_DATA,
  SET_ACTIVE_MENU,
  SET_APP_DATA,
  SET_EXPENSE_CATEGORIES,
  SET_FILING_TAB,
  SET_GSTR1_TAB,
  SET_GSTR3B_TAB,
  SET_HISTORY_EXPENSE,
  SET_HISTORY_EXP_HEADER_TAB,
  SET_HISTORY_EXP_TAB,
  SET_HISTORY_PURCHASE,
  SET_HISTORY_PURCHASE_HEADER_TAB,
  SET_HISTORY_PURCHASE_TAB,
  SET_HISTORY_SALES,
  SET_HISTORY_SALES_HEADER_TAB,
  SET_HISTORY_SALES_TAB,
  SET_IMPORT_PURCHASE_TAB,
  SET_IMPORT_SALES_TAB,
  SET_LOGO,
  SET_PURCHASE_CN_TAB,
  SET_PURCHASE_DN_TAB,
  SET_PURCHASE_INVOICE_TAB,
  SET_PURCHASE_RCMPAYMENT_TAB,
  SET_PURCHASE_RCM_TAB,
  SET_PURCHASE_RECEIPT_TAB,
  SET_PURCHASE_REFUND_TAB,
  SET_REPORT_TAB,
  SET_SALES_CN_TAB,
  SET_SALES_DN_TAB,
  SET_SALES_INVOICE_TAB,
  SET_SALES_RECEIPT_TAB,
  SET_SALES_REFUND_TAB,
  SET_SETTINGS_TAB,
  SET_USER_BU,
  SET_SETTINGS,
} from './appDataTypes';

export const getAppData = () => {
  return {
    type: GET_APP_DATA,
  };
};

export const setLogo = (data) => {
  return {
    type: SET_LOGO,
    payload: data,
  };
};
export const setAppData = (data) => {
  return {
    type: SET_APP_DATA,
    payload: data,
  };
};

export const setActiveMenu = (menuid) => {
  return {
    type: SET_ACTIVE_MENU,
    payload: menuid,
  };
};

export const setUsersBusinessUnits = (bulist) => {
  return {
    type: SET_USER_BU,
    payload: bulist,
  };
};

export const setExpenseCategories = (expCatList) => {
  return {
    type: SET_EXPENSE_CATEGORIES,
    payload: expCatList,
  };
};

export const setSalesReceiptTab = (data) => {
  return {
    type: SET_SALES_RECEIPT_TAB,
    payload: data,
  };
};

export const setSalesInvoiceTab = (data) => {
  return {
    type: SET_SALES_INVOICE_TAB,
    payload: data,
  };
};

export const setSalesCreditNoteTab = (data) => {
  return {
    type: SET_SALES_CN_TAB,
    payload: data,
  };
};

export const setSalesDebitNoteTab = (data) => {
  return {
    type: SET_SALES_DN_TAB,
    payload: data,
  };
};

export const setSalesRefundTab = (data) => {
  return {
    type: SET_SALES_REFUND_TAB,
    payload: data,
  };
};

export const setPurchaseReceiptTab = (data) => {
  return {
    type: SET_PURCHASE_RECEIPT_TAB,
    payload: data,
  };
};

export const setPurchaseInvoiceTab = (data) => {
  return {
    type: SET_PURCHASE_INVOICE_TAB,
    payload: data,
  };
};

export const setPurchaseCreditNoteTab = (data) => {
  return {
    type: SET_PURCHASE_CN_TAB,
    payload: data,
  };
};

export const setPurchaseDebitNoteTab = (data) => {
  return {
    type: SET_PURCHASE_DN_TAB,
    payload: data,
  };
};

export const setPurchaseRefundTab = (data) => {
  return {
    type: SET_PURCHASE_REFUND_TAB,
    payload: data,
  };
};

export const setPurchaseRcmTab = (data) => {
  return {
    type: SET_PURCHASE_RCM_TAB,
    payload: data,
  };
};

export const setPurchaseRcmPaymentTab = (data) => {
  return {
    type: SET_PURCHASE_RCMPAYMENT_TAB,
    payload: data,
  };
};

export const setFilingTab = (data) => {
  return {
    type: SET_FILING_TAB,
    payload: data,
  };
};

export const setReportTab = (data) => {
  return {
    type: SET_REPORT_TAB,
    payload: data,
  };
};

export const setHistoryExpenseHeaderTab = (data) => {
  return {
    type: SET_HISTORY_EXP_HEADER_TAB,
    payload: data,
  };
};

export const setHistoryExpenseTab = (data) => {
  return {
    type: SET_HISTORY_EXP_TAB,
    payload: data,
  };
};
export const setHistoryExpenseSearchData = (data) => {
  return {
    type: SET_HISTORY_EXPENSE,
    payload: data,
  };
};

export const setHistorySalesHeaderTab = (data) => {
  return {
    type: SET_HISTORY_SALES_HEADER_TAB,
    payload: data,
  };
};

export const setHistorySalesTab = (data) => {
  return {
    type: SET_HISTORY_SALES_TAB,
    payload: data,
  };
};
export const setHistorySalesSearchData = (data) => {
  return {
    type: SET_HISTORY_SALES,
    payload: data,
  };
};

export const setHistoryPurchaseHeaderTab = (data) => {
  return {
    type: SET_HISTORY_PURCHASE_HEADER_TAB,
    payload: data,
  };
};

export const setHistoryPurchaseTab = (data) => {
  return {
    type: SET_HISTORY_PURCHASE_TAB,
    payload: data,
  };
};
export const setHistoryPurchaseSearchData = (data) => {
  return {
    type: SET_HISTORY_PURCHASE,
    payload: data,
  };
};

export const setImportSalesTab = (data) => {
  return {
    type: SET_IMPORT_SALES_TAB,
    payload: data,
  };
};

export const setImportPurchaseTab = (data) => {
  return {
    type: SET_IMPORT_PURCHASE_TAB,
    payload: data,
  };
};

export const setSettingsTab = (data) => {
  return {
    type: SET_SETTINGS_TAB,
    payload: data,
  };
};

export const setGstr1Tab = (data) => {
  return {
    type: SET_GSTR1_TAB,
    payload: data,
  };
};

export const setGstr3BTab = (data) => {
  return {
    type: SET_GSTR3B_TAB,
    payload: data,
  };
};

export const setSettings = (data) => {
  return {
    type: SET_SETTINGS,
    payload: data,
  };
}
const appDataAction = {
  setGstr1Tab,
  setGstr3BTab,
  setSettingsTab,
  setSettings,
  setImportSalesTab,
  setImportPurchaseTab,
  setHistoryExpenseSearchData,
  setHistoryExpenseHeaderTab,
  setHistoryExpenseTab,
  setHistorySalesSearchData,
  setHistorySalesHeaderTab,
  setHistorySalesTab,
  setHistoryPurchaseSearchData,
  setHistoryPurchaseHeaderTab,
  setHistoryPurchaseTab,
  setReportTab,
  setFilingTab,
  setSalesReceiptTab,
  setSalesInvoiceTab,
  setSalesCreditNoteTab,
  setSalesDebitNoteTab,
  setSalesRefundTab,
  setPurchaseReceiptTab,
  setPurchaseInvoiceTab,
  setPurchaseCreditNoteTab,
  setPurchaseDebitNoteTab,
  setPurchaseRefundTab,
  setPurchaseRcmTab,
  setPurchaseRcmPaymentTab,
  setLogo,
  getAppData,
  setAppData,
  setActiveMenu,
  setUsersBusinessUnits,
  setExpenseCategories,
};
export default appDataAction