import React, { Component } from 'react';
import { Row } from 'reactstrap';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import appDataAction from '../../../redux/appData/appDataAction';
import store from '../../../redux/store';
import MenuWithBreadScrum from '../menuBox/MenuWithBreadScrum';
import masterDataAction from '../../../redux/masterData/masterDataAction';
import FetchServerData from '../../../provider/FetchServerData';

export default class Sales extends Component {
  header = store.getState().header.header;
  breadCrumb = [{ pageid: "/sales", uiname: "Sales", cssclass: "menu-icon tf-icons fa fa-line-chart" }];
  title = 'Sales';
  constructor(props) {
    super(props);
    let menu = null;
    if (this.props.location && this.props.location.pathname) {
      let pathname = this.props.location.pathname;
      let char = pathname.slice(-1);
      if (char !== '/') {
        pathname = pathname + '/';
      }
      menu = Utils.getMenu(pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      // this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }

    let menuArray = [];
    if (menu) {
      store.dispatch(appDataAction.setActiveMenu(menu.id));
      menuArray = Utils.getSubMenus(menu.id);
    }

    this.state = {
      menuArray: menuArray,
      render: true,
    };
  }
  componentDidMount() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/masterms/getMasterData", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let masterData = output.data;
          store.dispatch(masterDataAction.setMasterData(masterData));
          this.setState({ render: true });
        }
      }
    );
    // let masterData = DataProvider.setMasterData(this.header);
  }
  render() {
    if (this.state.render === false) {
      return '';
    }

    return (
      <>
        <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <div style={{ justifyContent: 'center', margin: '0 auto' }}>
          <Row md={12} style={{ paddingTop: '3em', margin: '0 auto', width: '100%' }}>
            {UiUtils.getUiMenuArray(this.state.menuArray, this.props.history, this.state.color)}
          </Row>
        </div>
      </>
    );
  }
}
