import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";

export default class PaymentDetailsDialogue extends Component {
  header = store.getState().header.header;
  tableIcons = UiUtils.getMaterialTableIcons();

  constructor(props) {
    super(props);
    this.state = {
      expense: null,
      paymentList: [],
      expenseid: props.expense?.id,
      open: false,
    };
  }

  showPaymentDetails(invoiceId) {
    this.state = { open: false, invoiceId: invoiceId };
    if (Utils.isNullOrEmpty(invoiceId)) {
      return;
    }
    const postObject = {
      invoice: { id: invoiceId },
      header: this.header,
    };
    FetchServerData.callPostService("/sams/getPayments", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let paymentList = output.data;
          this.setState({ paymentList: paymentList, open: true });
        } else {
        }
      }
    );
  }

  showPurchasePaymentDetails(invoiceId) {
    this.state = { open: false, invoiceId: invoiceId };
    if (Utils.isNullOrEmpty(invoiceId)) {
      return;
    }
    const postObject = {
      invoice: { id: invoiceId },
      header: this.header,
    };
    FetchServerData.callPostService("/psams/getPayments", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let paymentList = output.data;
          this.setState({ paymentList: paymentList, open: true });
        } else {
        }
      }
    );
  }

  showExpensePaymentDetails(expenseId) {
    this.state = { open: false, expenseId: expenseId };
    if (Utils.isNullOrEmpty(expenseId)) {
      return;
    }
    const postObject = {
      expense: { id: expenseId },
      header: this.header,
    };
    FetchServerData.callPostService("/expensems/getPayments", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let paymentList = output.data;
          this.setState({ paymentList: paymentList, open: true });
        } else {
        }
      }
    );
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleServiceGoodsSelectChange(e) {
    this.setState({ serviceGoods: e.target.value });
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  hsnCodeSelected(e, selectedCode) {
    if (Utils.isNotNull(this.props.onCodeChange)) {
      this.props.onCodeChange(selectedCode);
      this.setState({ open: false });
    }
  }

  getTableHeader() {
    return (
      <TableHead className="paymentMethodCell">
        <TableRow style={{ background: "rgb(239, 244, 247)" }}>
          <TableCell
            style={{
              width: "5%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>#</strong>
          </TableCell>
          <TableCell
            style={{
              width: "15%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Date</strong>
          </TableCell>
          <TableCell
            style={{
              width: "20%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Reference</strong>
          </TableCell>
          <TableCell
            style={{
              width: "15%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Method</strong>
          </TableCell>
          <TableCell
            style={{
              width: "15%",
              textAlign: "right",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Amount</strong>
          </TableCell>
          <TableCell
            style={{
              width: "15%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong style={{ fontSize: "small" }}>Status</strong>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        maxWidth="xxl"
        className="salesDialogue"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ backgroundColor: "#1b7189" }}
        >
          <span style={{ color: "#fff" }}>Payment Details</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={this.handleClose.bind(this)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "#fff", fontWeight: "600" }} />
        </IconButton>
        <DialogContent>
          <Row md={12} style={{ flexDirection: "column" }}>
            <div
              style={{
                display: "block",
                overflow: "scroll",
                width: "100%",
                padding: "0px",
              }}
            >
              <Row
                md={12}
                style={{
                  fontSize: "small",
                  margin: 0,
                  padding: 0,
                  background: "#ececec",
                  border: "0.5px solid #cacaca",
                }}
              >
                <Col md={6}>
                  <span style={{ fontSize: "15px" }}>
                    <strong>{this.state.title}</strong>
                  </span>
                </Col>
                {/* <Col md={6}>
                                    <AddIconButton onClick={this.addPayment.bind(this)} />
                                </Col> */}
              </Row>
              {this.state.paymentList.length > 0 && (
                <Row
                  md={12}
                  style={{
                    fontSize: "small",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <Table
                    size="small"
                    style={{ color: "black", fontSize: "small" }}
                  >
                    {this.getTableHeader()}
                    <TableBody>
                      {this.state.paymentList?.map((rowData, index) => (
                        <TableRow className="paymentMethodCell">
                          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                            <span style={{ fontSize: "small" }}>
                              {index + 1}
                            </span>
                          </TableCell>
                          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                            <span style={{ fontSize: "small" }}>
                              {" "}
                              {Utils.ptsDateFormat(rowData.paymentdate)}
                            </span>
                          </TableCell>
                          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                            <span style={{ fontSize: "small" }}>
                              {rowData.note}
                            </span>
                          </TableCell>

                          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                            <span style={{ fontSize: "small" }}>
                              {rowData.paymentmethod
                                ? rowData.paymentmethod.description
                                : ""}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "right",
                              border: "0.5px solid #ece6e6",
                            }}
                          >
                            <span style={{ fontSize: "small" }}>
                              {rowData.amount
                                ? (rowData.currency !== null
                                    ? rowData.currency.symbol
                                    : "") +
                                  " " +
                                  parseFloat(rowData.amount).toFixed(2)
                                : 0.0}
                            </span>
                          </TableCell>
                          <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                            <span style={{ fontSize: "small" }}>
                              {rowData.paymentStatus
                                ? rowData.paymentStatus.status
                                : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Row>
              )}
            </div>
          </Row>
        </DialogContent>
      </Dialog>
    );
  }
}
