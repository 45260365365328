import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
// import TextField from "@material-ui/core/TextField";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
// import Visibility from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import salesImportFile from "../../../../assets/importTemplate/Sales_Invoice_Import.xlsx";
export class ImportSalesInvoicesList extends Component {
  header = store.getState().header.header;
  fileUploadRef = React.createRef();
  selectAllCheckboxRef = React.createRef();
  invoiceTypeList = ["All", "B2B", "B2CL", "B2CS", "EXP", "AT", "CDNR"];
  breadCrumb = [
    { pageid: "/import", uiname: "Import", cssclass: "fa fa-upload" },
    { pageid: "/sa/imp/", uiname: "Import Sales Invoice List" },
  ];
  title = "Import Sales Invoice List";
  constructor(props) {
    super(props);
    // if (this.props.location && this.props.location.pathname) {
    //   let menu = Utils.getMenu(this.props.location.pathname);
    //   if (menu && menu.uiname && menu.uiname.length > 0) {
    //     this.title = menu.uiname;
    //   }
    //   this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    // }
    this.tableIcons = UiUtils.getMaterialTableIcons();

    this.state = {
      selectedFile: { name: "" },
      showAddsalesInvoice: false,
      salesInvoiceList: [],
      selectedItems: [],
      isSelectAll: false,
    };
    this.getSalesInvoiceData();
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = (e) => {
    // Create an object of formData
    e.preventDefault();

    if (!this.state.selectedFile.name || this.state.selectedFile.name === "") {
      PtsAlert.error("Please select file to upload");
      return;
    }
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("sid", this.header.sessionid);
    formData.append("tid", this.header.tid);
    formData.append("uid", this.header.email);
    formData.append("appclient", this.header.appclient);

    FetchServerData.callPostFileUpload("/bulkimport/invoice", formData).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.setState({ selectedFile: null });
          this.getSalesInvoiceData();
        }
        // this.setState({ selectedFile: '' });
        if (this.fileUploadRef) {
          this.fileUploadRef.current.value = null;
          this.fileUploadRef.current.value = "";
          // this.state.selectedFile.name = '';
        }
      }
    );
  };

  getSalesInvoiceData() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService("/impexp/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let salesInvoiceList = output.data.invoiceList;
          this.setState({
            salesInvoiceList: salesInvoiceList,
          });
        }
      }
    );
  }

  addSalesInvoice = (e) => {
    this.props.history.push({
      pathname: "/importSalesInvoice",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  viewSalesInvoice = (e, data) => {
    let salesInvoiceId = data.id;
    this.props.history.push({
      pathname: "/importSalesVoucher",
      state: {
        invoiceId: salesInvoiceId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  selectAllSalesInvoices = (e) => {
    let selectedIndex = store.getState().appData.importSalesSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }
    let type = this.invoiceTypeList[selectedIndex];
    let filteredData = [];
    if (Utils.equalsIgnoreCase(type, "All")) {
      filteredData = this.state.salesInvoiceList;
    } else {
      filteredData = this.state.salesInvoiceList.filter(function (inv) {
        return inv.invtype === type;
      });
    }
    let selectedItems = this.state.salesInvoiceList;
    for (let i = 0; i < selectedItems.length; i++) {
      selectedItems[i].isSelected = false;
    }

    for (let i = 0; i < selectedItems.length; i++) {
      for (let j = 0; j < filteredData.length; j++) {
        if (filteredData[j].id === selectedItems[i].id) {
          selectedItems[i].isSelected = e.target.checked;
        }
      }
    }
    this.setState({
      salesInvoiceList: selectedItems,
      isSelectAll: e.target.checked,
    });
  };

  rowItemSelected(rowData, e) {
    let selectedItems = this.state.salesInvoiceList;
    for (let i = 0; i < selectedItems.length; i++) {
      if (rowData.id === selectedItems[i].id) {
        if (e.target.checked) {
          selectedItems[i].isSelected = true;
        } else {
          selectedItems[i].isSelected = false;
        }
      }
    }
    this.setState({ salesInvoiceList: selectedItems });
    //   let selectedItemsObj = {};
    //   let selectedItems = [];
    //   for (let i = 0; i < rowData.length; i++) {
    //     selectedItemsObj = rowData[i].id;
    //     selectedItems.push(selectedItemsObj);

    //     this.setState({
    //       salesInvoiceList: selectedItems,
    //     });
    //   }
  }

  bukImportSalesInvoices(e) {
    let importSalesInvoiceList = [];
    for (let i = 0; i < this.state.salesInvoiceList.length; i++) {
      if (this.state.salesInvoiceList[i].tableData?.checked === true) {
        importSalesInvoiceList.push(this.state.salesInvoiceList[i].id);
      }
    }
    if (!importSalesInvoiceList.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    const postObject = {
      header: this.header,
      invoices: importSalesInvoiceList,
    };
    FetchServerData.callPostService("/impexp/impInvoices", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ selectedItems: [], isSelectAll: false });
          this.getSalesInvoiceData();
        }
      }
    );
  }

  bulkOverrideSalesInvoice(e) {
    let importSalesInvoiceList = [];
    for (let i = 0; i < this.state.salesInvoiceList.length; i++) {
      if (this.state.salesInvoiceList[i].tableData?.checked === true) {
        importSalesInvoiceList.push(this.state.salesInvoiceList[i].id);
      }
    }
    if (!importSalesInvoiceList.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    const postObject = {
      header: this.header,
      invoices: importSalesInvoiceList,
    };
    FetchServerData.callPostService(
      "/impexp/overrideinvoices",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.setState({ selectedItems: [], isSelectAll: false });
        this.getSalesInvoiceData();
      }
    });
  }

  bulkDeleteSalesInvoice(e) {
    let importSalesInvoiceList = [];
    for (let i = 0; i < this.state.salesInvoiceList.length; i++) {
      if (this.state.salesInvoiceList[i].tableData?.checked === true) {
        importSalesInvoiceList.push(this.state.salesInvoiceList[i].id);
      }
    }
    if (!importSalesInvoiceList.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    const postObject = {
      header: this.header,
      invoice: { invoiceids: importSalesInvoiceList },
    };
    FetchServerData.callPostService("/impexp/deleteInvoices", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ selectedItems: [], isSelectAll: false });
          this.getSalesInvoiceData();
        }
      }
    );
  }
  getTablesWithInvoiceType(type) {
    let filteredData = [];
    if (Utils.equalsIgnoreCase(type, "All")) {
      filteredData = this.state.salesInvoiceList;
    } else {
      filteredData = this.state.salesInvoiceList.filter(function (inv) {
        return inv.invtype === type;
      });
    }
    return (
      <MaterialTable
        component={Paper}
        icons={this.tableIcons}
        title=""
        columns={[
          // {
          //   title: " ",
          //   render: (rowData) => {
          //     return (
          //       <Checkbox
          //         checked={
          //           undefined === rowData.isSelected ||
          //           null === rowData.isSelected ||
          //           false === rowData.isSelected
          //             ? false
          //             : rowData.isSelected
          //         }
          //         color="primary"
          //         inputProps={{ "aria-label": "checkbox with default color" }}
          //         id={rowData.tableData.id}
          //         onClick={(e) => this.rowItemSelected(e, rowData.id)}
          //       ></Checkbox>
          //     );
          //   },
          //   width: "5%",
          // },
          {
            title: "#",
            render: (rowData) => rowData.tableData.id + 1,
            cellStyle: {
              width: "5%",
            },
          },
          {
            title: "Invoice Number",
            field: "invnumber",
            align: "left",
            width: "20%",
          },
          {
            title: "Month",
            field: "month",
            align: "left",
            width: "15%",
          },
          {
            title: "Year",
            field: "year",
            align: "left",
            width: "15%",
          },
          {
            title: "Type",
            field: "invtype",
            align: "left",
            width: "20%",
          },
          {
            title: "Is Duplicate",
            field: "isexistinmain",
            align: "left",
            width: "20%",
          },
        ]}
        data={filteredData}
        onSelectionChange={(rows) => {
          this.rowItemSelected(rows);
        }}
        options={{
          selection: true,
          showTextRowsSelected: false,
          sorting: true,
          actionsColumnIndex: -1,
          paging: filteredData.length > 10,
          pageSizeOptions: Utils.materialTablePageSize(),
          headerStyle: {
            backgroundColor: "#1b7189",
            fontWeight: "bold",
            color: "#fff",
            align: "center",
          },
          rowStyle: (rowData) => {
            if (
              undefined !== rowData.isexistinmain &&
              rowData.isexistinmain &&
              rowData.isexistinmain === true
            ) {
              return { backgroundColor: "#ecc0a0" };
            }
          },
        }}
        style={{
          boder: "1px solid black",
        }}
        onRowClick={(event, rowData) => {
          this.viewSalesInvoice(event, rowData);
          event.stopPropagation();
        }}
        actions={
          [
            // {
            //   icon: () => (
            //     <Checkbox
            //       checked={this.state.isSelectAll}
            //       ref={this.selectAllCheckboxRef}
            //       color="primary"
            //       inputProps={{ "aria-label": "checkbox with default color" }}
            //     ></Checkbox>
            //   ),
            //   tooltip: "SelectAll",
            //   isFreeAction: true,
            //   onClick: (event) => this.selectAllSalesInvoices(event),
            // },
            // {
            //   icon: () => <Visibility />,
            //   tooltip: "View Customer",
            //   onClick: (e, rowData) => this.viewSalesInvoice(e, rowData),
            // },
          ]
        }
        // onRowClick={(e, rowData) => this.viewSalesInvoice(e, rowData)}
      />
    );
  }
  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setImportSalesTab(index));
    let selectedList = this.state.salesInvoiceList;
    for (let i = 0; i < selectedList.length; i++) {
      selectedList[i].isSelected = false;
    }
    this.setState({
      isSelectAll: false,
      selectedTabIndex: index,
      salesInvoiceList: selectedList,
    });
  }

  cancelView(e) {
    this.props.history.push({
      pathname: "/import/",
      state: {},
    });
  }

  render() {
    const tabList = this.invoiceTypeList.map((invType, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(invType, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{invType}</span>
        </Tab>
      );
    });

    const tabPanelList = this.invoiceTypeList.map((invType, keyIndex) => {
      return (
        <TabPanel key={keyIndex}>
          {this.getTablesWithInvoiceType(invType)}
        </TabPanel>
      );
    });

    let selectedIndex = store.getState().appData.importSalesSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        {/* <div
          style={{
            display: "grid",
            backgroundColor: "#d6dde6",
            width: "100%",
            marginTop: "1em",
            padding: "1em",
          }}
        > */}
        <Row md={12} style={{ padding: "1em", margin: "0" }}>
          <ValidatorForm
            style={{ width: "100%" }}
            ref="form"
            onSubmit={this.onFileUpload.bind(this)}
          >
            <Card md={12} component={Paper} style={{ width: "100%" }}>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={4}>
                  <label>
                    Choose File To Import (
                    <span style={{ color: "red" }}>Excel file only</span>)
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ float: "right" }}>
                    <a
                      href={salesImportFile}
                      id="dwnlink"
                      download="SalesInvoice_Import.xlsx"
                      // class="pull-right"
                    >
                      <b> Download Sample Excel Format</b>
                    </a>
                  </label>
                </Col>
              </Row>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={12}>
                  <div
                    style={{
                      border: "1px solid gray",
                      padding: "1em",
                      marginTop: "0.5em",
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      ref={this.fileUploadRef}
                      onChange={this.onFileChange.bind(this)}
                      required
                    />
                    {/* <TextField
                        required
                        type="file"
                        id="file"
                        label="Select File to Upload"
                        ref={this.fileUploadRef}
                        onChange={this.onFileChange.bind(this)}
                        InputLabelProps={{ shrink: true }}
                      /> */}
                  </div>
                </Col>
              </Row>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<SaveIcon />}
                    // onClick={(e) => this.onFileUpload()}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              {/* </form> */}
            </Card>
          </ValidatorForm>
        </Row>
        <Row md={12} style={{ padding: "1em", margin: "0" }}>
          {/* <div style={{ width: '-webkit-fill-available' }}> */}
          <Tabs
            selectedIndex={selectedIndex}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList
              style={{
                minWidth: "5em",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {tabList}
            </TabList>
            {tabPanelList}
          </Tabs>
          {/* </div> */}
        </Row>
        <Row md={12} style={{ padding: "1em", margin: "0" }}>
          <Col md={12} style={{ textAlign: "center", alignItems: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bukImportSalesInvoices(e)}
            >
              Bulk Import
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bulkOverrideSalesInvoice(e)}
            >
              Bulk Override
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<DeleteIcon />}
              onClick={(e) => this.bulkDeleteSalesInvoice(e)}
            >
              Bulk Delete
            </Button>
          </Col>
        </Row>
        {/* </div> */}
      </>
    );
  }
}
export default ImportSalesInvoicesList;
