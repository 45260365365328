import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import BackspaceIcon from '@material-ui/icons/Backspace';
import React, { Component } from 'react';

export class BackButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      type: props.type,
      name: props.name,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }

  render() {
    return (
      <Tooltip title="Back">
        <Button
          type={this.state.type ? this.state.type : ''}
          variant="contained"
          size="small"
          style={{ marginRight: '1rem' }}
          className='BackButtonBtn'
          startIcon={<BackspaceIcon />}
          onClick={this.onClick.bind(this)}
        >
          {this.state.name ? this.state.name : 'Back'}
        </Button>
      </Tooltip>
    );
  }
}
export default BackButton;
