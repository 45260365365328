import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row, Card, Col } from "reactstrap";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import { NavLink } from "react-router-dom";
import masterDataAction from "../../../redux/masterData/masterDataAction";
import FetchServerData from "../../../provider/FetchServerData";
const styles = (theme) => ({
  bigIndicator: {
    height: 10,
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

export class Filing extends Component {
  header = store.getState().header.header;
  breadCrumb = [
    { pageid: "/filing", uiname: "Filing", cssclass: "fa fa-files-o" },
  ];
  title = "Filing";
  selectedIndex = store.getState().appData.filingSelectedTab;
  constructor(props) {
    super(props);
    store.dispatch(appDataAction.setFilingTab());
    if (this.props.location && this.props.location.pathname) {
      let menu = Utils.getMenu(this.props.location.pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      // this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    if (this.selectedIndex === undefined) {
      this.state = {
        render: false,
        selectedTabIndex: 0,
        selectedIndex: 0,
        filingTabList: [
          { uiname: "General" },
          { uiname: "GSTR-1" },
          { uiname: "GSTR-3" },
        ],
      };
    } else {
      this.state = {
        render: false,
        selectedTabIndex: this.selectedIndex,
        selectedIndex: this.selectedIndex,
        filingTabList: [
          { uiname: "General" },
          { uiname: "GSTR-1" },
          { uiname: "GSTR-3" },
        ],
      };
    }
  }

  componentDidMount() {
    let activeMenu = store.getState().appData.activeMenu;
    let menuArray = Utils.getSubMenus(activeMenu);
    for (let i = 0; i < menuArray.length; i++) {
      let menu = menuArray[i];
      if (Utils.equalsIgnoreCase(menu.menuid, "General")) {
        let generalMenuArray = Utils.getSubMenus(menu.id);
        this.setState({ render: true, generalMenuArray: generalMenuArray });
      } else if (Utils.equalsIgnoreCase(menu.menuid, "Gstr-1")) {
        let gstr1MenuArray = Utils.getSubMenus(menu.id);
        this.setState({ render: true, gstr1MenuArray: gstr1MenuArray });
      } else if (Utils.equalsIgnoreCase(menu.menuid, "Gstr-3")) {
        let gstr3MenuArray = Utils.getSubMenus(menu.id);
        this.setState({ render: true, gstr3MenuArray: gstr3MenuArray });
      }
    }
    this.getMasterData();
  }

  getMasterData() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/masterms/getMasterData", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let masterData = output.data;
          store.dispatch(masterDataAction.setMasterData(masterData));
        }
      }
    );
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setFilingTab(index));
    this.setState({ selectedTabIndex: index });
  }
  render() {
    // const classes = useStyles();
    // let selectedIndex = store.getState().appData.filingSelectedTab;
    let selectedIndex = this.state.selectedTabIndex;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }
    let tabList = this.state.filingTabList.map((filingTab, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(filingTab, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{filingTab.uiname}</span>
        </Tab>
      );
    });

    let tabPanelList = this.state.filingTabList.map((filingTab, keyIndex) => {
      if (Utils.equalsIgnoreCase(filingTab.uiname, "General")) {
        return (
          <TabPanel key={keyIndex}>
            <Card>
              {/* <div style={{ justifyContent: "center", margin: "0 auto" }}> */}
              <Row
                md={12}
                style={{ padding: "3em", margin: "0 auto", width: "100%" }}
              >
                <Col md={6} style={{ justifyContent: "center" }}>
                  <NavLink
                    md={7}
                    to={{
                      pathname: "/gstr1usermgmt",
                      state: {
                        breadCrumb: this.breadCrumb,
                      },
                    }}
                    style={{
                      // padding: '0.5em',
                      textAlign: "left",
                      display: "block",
                      justifyContent: "center",
                      margin: "1.2em auto",
                      width: "75%",
                    }}
                  >
                    <Card
                      className="gstMenu"
                      style={{
                        // backgroundColor: '#3a90a8',
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      // onClick={(e) => goToCard(menuData, breadCrumb)}
                      // onClick={(e) =>
                      //   history.push({
                      //     pathname: menuData.pageid.split("#/").join("/"),
                      //     state: {
                      //       breadCrumb: breadCrumb,
                      //     },
                      //   })
                      // }
                    >
                      <Row>
                        <Col
                          md={4}
                          className="gstMenuDark"
                          style={{
                            // backgroundColor: '#2e7387',
                            height: "5em",
                            maxWidth: "40%",
                            cursor: "pointer",
                            textAlign: "center",
                            // boxShadow: '0px 0px 3px 3px #2b5d6b inset',
                          }}
                        >
                          <span>
                            <i
                              className="fa fa-3x fa-calendar-check-o icon"
                              style={{
                                color: "#fff",
                                fontSize: "xxx-large",
                                maxHeight: "1em",
                                marginTop: "10px",
                              }}
                            ></i>
                          </span>
                        </Col>
                        <Col
                          md={8}
                          style={{
                            maxWidth: "60%",
                            display: "flex",
                            textAlign: "left",
                            alignItems: "center",
                            fontSize: "large",
                          }}
                        >
                          GST User
                        </Col>
                      </Row>
                    </Card>
                  </NavLink>
                </Col>
                <Col md={6} style={{ justifyContent: "center" }}>
                  <NavLink
                    md={7}
                    to={{
                      pathname: "/filinghisory",
                      state: {
                        breadCrumb: this.breadCrumb,
                      },
                    }}
                    style={{
                      // padding: '0.5em',
                      textAlign: "left",
                      display: "block",
                      justifyContent: "center",
                      margin: "1.2em auto",
                      width: "75%",
                    }}
                  >
                    <Card
                      className="gstMenu"
                      style={{
                        // backgroundColor: '#3a90a8',
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      // onClick={(e) => goToCard(menuData, breadCrumb)}
                      // onClick={(e) =>
                      //   history.push({
                      //     pathname: menuData.pageid.split("#/").join("/"),
                      //     state: {
                      //       breadCrumb: breadCrumb,
                      //     },
                      //   })
                      // }
                    >
                      <Row>
                        <Col
                          md={4}
                          className="gstMenuDark"
                          style={{
                            // backgroundColor: '#2e7387',
                            height: "5em",
                            maxWidth: "40%",
                            cursor: "pointer",
                            textAlign: "center",
                            // boxShadow: '0px 0px 3px 3px #2b5d6b inset',
                          }}
                        >
                          <span>
                            <i
                              className="fa fa-3x fa-user-circle-o icon"
                              style={{
                                color: "#fff",
                                fontSize: "xxx-large",
                                maxHeight: "1em",
                                marginTop: "10px",
                              }}
                            ></i>
                          </span>
                        </Col>
                        <Col
                          md={8}
                          style={{
                            maxWidth: "60%",
                            display: "flex",
                            textAlign: "left",
                            alignItems: "center",
                            fontSize: "large",
                          }}
                        >
                          Filling History
                        </Col>
                      </Row>
                    </Card>
                  </NavLink>
                </Col>
              </Row>
              {/* </div> */}
            </Card>
          </TabPanel>
        );
      } else if (Utils.equalsIgnoreCase(filingTab.uiname, "GSTR-1")) {
        return (
          <TabPanel key={keyIndex}>
            <Card>
              {/* <div style={{ justifyContent: "center", margin: "0 auto" }}> */}
              <Row
                md={12}
                style={{ padding: "3em", margin: "0 auto", width: "100%" }}
              >
                <Col md={6} style={{ justifyContent: "center" }}>
                  <NavLink
                    md={7}
                    to={{
                      pathname: "/gstr1list",
                      state: {
                        breadCrumb: this.breadCrumb,
                      },
                    }}
                    style={{
                      // padding: '0.5em',
                      textAlign: "left",
                      display: "block",
                      justifyContent: "center",
                      margin: "1.2em auto",
                      width: "75%",
                    }}
                  >
                    <Card
                      className="gstMenu"
                      style={{
                        // backgroundColor: '#3a90a8',
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      // onClick={(e) => goToCard(menuData, breadCrumb)}
                      // onClick={(e) =>
                      //   history.push({
                      //     pathname: menuData.pageid.split("#/").join("/"),
                      //     state: {
                      //       breadCrumb: breadCrumb,
                      //     },
                      //   })
                      // }
                    >
                      <Row>
                        <Col
                          md={4}
                          className="gstMenuDark"
                          style={{
                            // backgroundColor: '#2e7387',
                            height: "5em",
                            maxWidth: "40%",
                            cursor: "pointer",
                            textAlign: "center",
                            // boxShadow: '0px 0px 3px 3px #2b5d6b inset',
                          }}
                        >
                          <span>
                            <i
                              className="icon gstrIcon"
                              style={{
                                color: "#fff",
                                fontSize: "xxx-large",
                                maxHeight: "1em",
                                marginTop: "10px",
                              }}
                            ></i>
                          </span>
                        </Col>
                        <Col
                          md={8}
                          style={{
                            maxWidth: "60%",
                            display: "flex",
                            textAlign: "left",
                            alignItems: "center",
                            fontSize: "large",
                          }}
                        >
                          GSTR-1
                        </Col>
                      </Row>
                    </Card>
                  </NavLink>
                </Col>
              </Row>
              {/* </div> */}
            </Card>
          </TabPanel>
        );
      } else if (Utils.equalsIgnoreCase(filingTab.uiname, "GSTR-3")) {
        return (
          <TabPanel key={keyIndex}>
            <Card>
              {/* <div style={{ justifyContent: "center", margin: "0 auto" }}> */}
              <Row
                md={12}
                style={{ padding: "3em", margin: "0 auto", width: "100%" }}
              >
                <Col md={6} style={{ justifyContent: "center" }}>
                  <NavLink
                    md={7}
                    to={{
                      pathname: "/gstr3list",
                      state: {
                        breadCrumb: this.breadCrumb,
                      },
                    }}
                    style={{
                      // padding: '0.5em',
                      textAlign: "left",
                      display: "block",
                      justifyContent: "center",
                      margin: "1.2em auto",
                      width: "75%",
                    }}
                  >
                    <Card
                      className="gstMenu"
                      style={{
                        // backgroundColor: '#3a90a8',
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      // onClick={(e) => goToCard(menuData, breadCrumb)}
                      // onClick={(e) =>
                      //   history.push({
                      //     pathname: menuData.pageid.split("#/").join("/"),
                      //     state: {
                      //       breadCrumb: breadCrumb,
                      //     },
                      //   })
                      // }
                    >
                      <Row>
                        <Col
                          md={4}
                          className="gstMenuDark"
                          style={{
                            // backgroundColor: '#2e7387',
                            height: "5em",
                            maxWidth: "40%",
                            cursor: "pointer",
                            textAlign: "center",
                            // boxShadow: '0px 0px 3px 3px #2b5d6b inset',
                          }}
                        >
                          <span>
                            <i
                              className="icon gstrIcon"
                              style={{
                                color: "#fff",
                                fontSize: "xxx-large",
                                maxHeight: "1em",
                                marginTop: "10px",
                              }}
                            ></i>
                          </span>
                        </Col>
                        <Col
                          md={8}
                          style={{
                            maxWidth: "60%",
                            display: "flex",
                            textAlign: "left",
                            alignItems: "center",
                            fontSize: "large",
                          }}
                        >
                          GSTR3B
                        </Col>
                      </Row>
                    </Card>
                  </NavLink>
                </Col>
              </Row>
              {/* </div> */}
            </Card>
          </TabPanel>
        );
      } else {
        return (
          <TabPanel key={keyIndex}>
            {/* <div style={{ justifyContent: "center", margin: "0 auto" }}> */}
            <Row
              md={12}
              style={{ padding: "3em", margin: "0 auto", width: "100%" }}
            ></Row>
            {/* </div> */}
          </TabPanel>
        );
      }
    });
    if (!this.state.render) {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
      );
    }

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row md={12} style={{ marginTop: "2em" }}>
          <div style={{ width: "100%" }}>
            <Tabs
              selectedIndex={selectedIndex}
              md={12}
              style={{
                width: "100%",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              <TabList
                style={{
                  minWidth: "5em",
                  color: "#1b7189",
                  outline: "none",
                  margin: "0",
                }}
              >
                {tabList}
              </TabList>
              {tabPanelList}
            </Tabs>
          </div>
        </Row>
      </>
    );
  }
}
Filing.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Filing);
