import {
  GET_APP_DATA,
  SET_ACTIVE_MENU,
  SET_APP_DATA,
  SET_EXPENSE_CATEGORIES,
  SET_FILING_TAB,
  SET_GSTR1_TAB,
  SET_GSTR3B_TAB,
  SET_HISTORY_EXPENSE,
  SET_HISTORY_EXP_HEADER_TAB,
  SET_HISTORY_EXP_TAB,
  SET_HISTORY_PURCHASE,
  SET_HISTORY_PURCHASE_HEADER_TAB,
  SET_HISTORY_PURCHASE_TAB,
  SET_HISTORY_SALES,
  SET_HISTORY_SALES_HEADER_TAB,
  SET_HISTORY_SALES_TAB,
  SET_IMPORT_PURCHASE_TAB,
  SET_IMPORT_SALES_TAB,
  SET_LOGO,
  SET_PURCHASE_CN_TAB,
  SET_PURCHASE_DN_TAB,
  SET_PURCHASE_INVOICE_TAB,
  SET_PURCHASE_RCMPAYMENT_TAB,
  SET_PURCHASE_RCM_TAB,
  SET_PURCHASE_RECEIPT_TAB,
  SET_PURCHASE_REFUND_TAB,
  SET_REPORT_TAB,
  SET_SALES_CN_TAB,
  SET_SALES_DN_TAB,
  SET_SALES_INVOICE_TAB,
  SET_SALES_RECEIPT_TAB,
  SET_SALES_REFUND_TAB,
  SET_SETTINGS_TAB,
  SET_SETTINGS,
  SET_USER_BU,
} from './appDataTypes';

const initialState = {
  bankAccountList: [],
  customerStatusList: [],
  supplierStatusList: [],
  appuserStatusList: [],
  paymentStatusList: [],
  businessUnitList: [],
  paymentMethodList: [],
  productTypeList: [],
  appUserTypeList: [],
  purchaseDocTypeList: [],
  expenseDocTypeList: [],
  roleList: [],
  supplierCategoryList: [],
  supplierTypeList: [],
  customerCategoryList: [],
  customerTypeList: [],
  businessTypeList: [],
  cessTypeList: [],
  expInvoiceTypeList: [],
  sezInvoiceTypeList: [],
  filingFrequencyList: [],
  signatureFrequencyList: [],
  monthlyFinancials: [],
  // settings: [],
  settings: [],
  fyList: [],
  yearList: [],
  accountTypeList: [],
  recordCount: [],
  rcmServiceCategories: [],
  expenseCategoryList: [],
  gstr1StatusList: [],
  gstr3bStatusList: [],
  gstr1Credentials: [],
  logoURL: '',
  logoData: '',
  userBUList: [],
  salesReciptSelectedTab: 0,
  salesInvoiceSelectedTab: 0,
  salesCNSelectedTab: 0,
  salesDNSelectedTab: 0,
  salesRefundSelectedTab: 0,
  purchaseReceiptSelectedTab: 0,
  purchaseInvoiceSelectedTab: 0,
  purchaseDNSelectedTab: 0,
  purchaseCNSelectedTab: 0,
  purchaseRefundSelectedTab: 0,
  purchaseRcmSelectedTab: 0,
  purchaseRcmPaymentSelectedTab: 0,
  filingSelectedTab: 0,
  reportSelectedTab: 0,
  historyExpenseHeaderSelectedTab: 0,
  historyExpenseSelectedTab: 0,
  historyExpenseSearchData: {},

  historySalesHeaderSelectedTab: 0,
  historySalesSelectedTab: 0,
  historySalesSearchData: {},

  historyPurchaseHeaderSelectedTab: 0,
  historyPurchaseSelectedTab: 0,
  historyPurchaseSearchData: {},

  importSalesSelectedTab: 0,
  importPurchaseSelectedTab: 0,
  settingSelectedTab: 0,
  gstr1SelectedTab: 0,
  gstr3BSelectedTab: 0,
};

const appDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APP_DATA:
      return {
        ...state,
      };
    case SET_SETTINGS_TAB:
      return {
        ...state,
        settingSelectedTab: action.payload,
      };

    case SET_GSTR1_TAB:
      return {
        ...state,
        gstr1SelectedTab: action.payload,
      };
    case SET_GSTR3B_TAB:
      return {
        ...state,
        gstr3BSelectedTab: action.payload,
      };
    case SET_IMPORT_SALES_TAB:
      return {
        ...state,
        importSalesSelectedTab: action.payload,
      };

    case SET_IMPORT_PURCHASE_TAB:
      return {
        ...state,
        importPurchaseSelectedTab: action.payload,
      };
    case SET_HISTORY_SALES:
      return {
        ...state,
        historySalesSearchData: action.payload,
      };
    case SET_HISTORY_SALES_HEADER_TAB:
      return {
        ...state,
        historySalesHeaderSelectedTab: action.payload,
      };
    case SET_HISTORY_SALES_TAB:
      return {
        ...state,
        historySalesSelectedTab: action.payload,
      };

    case SET_HISTORY_PURCHASE:
      return {
        ...state,
        historyPurchaseSearchData: action.payload,
      };
    case SET_HISTORY_PURCHASE_HEADER_TAB:
      return {
        ...state,
        historyPurchaseHeaderSelectedTab: action.payload,
      };
    case SET_HISTORY_PURCHASE_TAB:
      return {
        ...state,
        historyPurchaseSelectedTab: action.payload,
      };

    case SET_HISTORY_EXPENSE:
      return {
        ...state,
        historyExpenseSearchData: action.payload,
      };
    case SET_HISTORY_EXP_HEADER_TAB:
      return {
        ...state,
        historyExpenseHeaderSelectedTab: action.payload,
      };
    case SET_HISTORY_EXP_TAB:
      return {
        ...state,
        historyExpenseSelectedTab: action.payload,
      };
    case SET_FILING_TAB:
      return {
        ...state,
        filingSelectedTab: action.payload,
      };

    case SET_REPORT_TAB:
      return {
        ...state,
        reportSelectedTab: action.payload,
      };
    case SET_LOGO:
      return {
        ...state,
        logoData: action.payload,
      };
    case SET_SALES_RECEIPT_TAB:
      return {
        ...state,
        salesReciptSelectedTab: action.payload,
      };

    case SET_SALES_INVOICE_TAB:
      return {
        ...state,
        salesInvoiceSelectedTab: action.payload,
      };

    case SET_SALES_CN_TAB:
      return {
        ...state,
        salesCNSelectedTab: action.payload,
      };

    case SET_SALES_DN_TAB:
      return {
        ...state,
        salesDNSelectedTab: action.payload,
      };

    case SET_SALES_REFUND_TAB:
      return {
        ...state,
        salesRefundSelectedTab: action.payload,
      };

    case SET_USER_BU:
      return {
        ...state,
        userBUList: action.payload,
      };

    case SET_PURCHASE_RECEIPT_TAB:
      return {
        ...state,
        purchaseReceiptSelectedTab: action.payload,
      };

    case SET_PURCHASE_INVOICE_TAB:
      return {
        ...state,
        purchaseInvoiceSelectedTab: action.payload,
      };

    case SET_PURCHASE_CN_TAB:
      return {
        ...state,
        purchaseCNSelectedTab: action.payload,
      };

    case SET_PURCHASE_DN_TAB:
      return {
        ...state,
        purchaseDNSelectedTab: action.payload,
      };

    case SET_PURCHASE_REFUND_TAB:
      return {
        ...state,
        purchaseRefundSelectedTab: action.payload,
      };
    case SET_PURCHASE_RCM_TAB:
      return {
        ...state,
        purchaseRcmSelectedTab: action.payload,
      };

    case SET_PURCHASE_RCMPAYMENT_TAB:
      return {
        ...state,
        purchaseRcmPaymentSelectedTab: action.payload,
      };
    case SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload,
      };
    case SET_EXPENSE_CATEGORIES:
      return {
        ...state,
        expenseCategoryList: action.payload,
      };
    case SET_SETTINGS:
      return {
        ...state,
        setting: action.payload,
      };
    case SET_APP_DATA:
      return {
        ...state,
        loading: false,
        bankAccountList: action.payload.bankAccountList,
        gstr3bStatusList: action.payload.gstr3bStatusList,
        gstr1StatusList: action.payload.gstr1StatusList,
        gstr1Credentials: action.payload.gstr1Credentials,
        customerStatusList: action.payload.customerStatusList,
        supplierStatusList: action.payload.supplierStatusList,
        appuserStatusList: action.payload.appuserStatusList,
        paymentStatusList: action.payload.paymentStatus,
        businessUnitList: action.payload.businessUnits,
        paymentMethodList: action.payload.paymentMethods,
        productTypeList: action.payload.productTypes,
        appUserTypeList: action.payload.appusertypeList,
        purchaseDocTypeList: action.payload.purchaseDocTypes,
        expenseDocTypeList: action.payload.expenseDocTypes,
        roleList: action.payload.roleList,
        supplierCategoryList: action.payload.supCatList,
        supplierTypeList: action.payload.suppliertypeList,
        customerCategoryList: action.payload.custCatList,
        customerTypeList: action.payload.customertypeList,
        businessTypeList: action.payload.businessTypeList,
        cessTypeList: action.payload.cesstypeList,
        expInvoiceTypeList: action.payload.exportInvTypeList,
        sezInvoiceTypeList: action.payload.sezInvTypeList,
        filingFrequencyList: action.payload.fileFrequencyList,
        signatureFrequencyList: action.payload.signaturetypeList,
        monthlyFinancials: action.payload.monthlyFinancials,
        settings: action.payload.settings,
        fyList: action.payload.fyList,
        yearList: action.payload.yearList,
        accountTypeList: action.payload.accountypeList,
        recordCount: action.payload.recordcount,
        rcmServiceCategories: action.payload.rcmServiceCategories,
        expenseCategoryList: action.payload.expenseCategoryList,
        logoURL: action.payload.logo,
        error: '',
      };
    default:
      return state;
  }
};

export default appDataReducer;
