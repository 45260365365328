import { Card } from "@material-ui/core";
// import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import {
  default as Search,
  default as SearchIcon,
} from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";
import DeleteIconButton from "../pts/components/buttons/DeleteIconButton";
import EditIconButton from "../pts/components/buttons/EditIconButton";
import AddIconButton from "../pts/components/buttons/AddIconButton";
import PaymentCancelButton from "../pts/components/buttons/PaymentCancelButton";
import PaymentSaveButton from "../pts/components/buttons/PaymentSaveButton";

export const getUiMenuArray = (menuArray, history, rightColor, breadCrumb) => {
  if (!menuArray) {
    return "";
  }

  return menuArray.map((menuData, index) => {
    return (
      menuData.isactive && (
        <Col md={6} key={index} style={{ justifyContent: "center" }}>
          <NavLink
            md={7}
            to={{
              pathname: menuData.pageid.split("#/").join("/"),
              state: {
                breadCrumb: breadCrumb,
              },
            }}
            style={{
              // display: "flex",
              // padding: '0.5em',
              textAlign: "left",
              display: "block",
              justifyContent: "center",
              margin: "1.2em auto",
              width: "75%",
            }}
          >
            <Card
              className="gstMenu"
              style={{
                // backgroundColor: '#3a90a8',
                color: "#fff",
                cursor: "pointer",
              }}
              // onClick={(e) => goToCard(menuData, breadCrumb)}
              onClick={(e) =>
                history.push({
                  pathname: menuData.pageid.split("#/").join("/"),
                  state: {
                    breadCrumb: breadCrumb,
                  },
                })
              }
            >
              <Row>
                <Col
                  md={4}
                  className="gstMenuDark"
                  style={{
                    // backgroundColor: '#2e7387',
                    height: "5em",
                    maxWidth: "40%",
                    cursor: "pointer",
                    textAlign: "center",
                    // boxShadow: '0px 0px 3px 3px #2b5d6b inset',
                  }}
                >
                  <span>
                    <i
                      className={menuData.cssclass}
                      style={{
                        color: "#fff",
                        fontSize: "xxx-large",
                        maxHeight: "1em",
                        marginTop: "10px",
                      }}
                    ></i>
                  </span>
                </Col>
                <Col
                  md={8}
                  style={{
                    maxWidth: "60%",
                    display: "flex",
                    textAlign: "left",
                    alignItems: "center",
                    fontSize: "large",
                  }}
                >
                  {menuData.menuid}
                </Col>
              </Row>
            </Card>
          </NavLink>
        </Col>
      )
    );
  });
};

export const getMaterialTableIcons = () => {
  // forwardRef((props, ref) => console.log(props));
  return {
    Add: forwardRef((props, ref) => <AddIconButton {...props} ref={ref} />),
    Visibility: forwardRef((props, ref) => (
      <VisibilityIcon {...props} ref={ref} />
    )),
    Check: forwardRef((props, ref) => (
      <PaymentSaveButton hideToolTip={true} {...props} ref={ref} />
    )),
    Clear: forwardRef((props, ref) => (
      <PaymentCancelButton hideToolTip={true} {...props} ref={ref} />
    )),
    Delete: forwardRef((props, ref) => (
      <DeleteIconButton hideToolTip={true} {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
      <EditIconButton hideToolTip={true} {...props} ref={ref} />
    )),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => (
      <SearchIcon style={{ fontSize: "small" }} {...props} ref={ref} />
    )),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
};
export default {
  getMaterialTableIcons,
  getUiMenuArray,
};
