import { Card } from "@material-ui/core";
import React, { Component } from "react";
import { CardBody, CardHeader, Col } from "reactstrap";
import AdminFetchServerData from '../../../provider/AdminFetchServerData';
import UiUtils from "../../../provider/UiUtils";
import store from "../../../redux/store";
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
// import PtsAlert from "../../ptsAlert/PtsAlert";
import SaveButton from "../../components/buttons/SaveButton";
import Pagination from "../../components/pagination/Pagination";
import AddIconButton from "../../components/buttons/AddIconButton";

export class AdminGoodHsnCodeList extends Component {
	header = store.getState().adminHeader.adminHeader;
	breadCrumb = [
		{ pageid: "/goods", uiname: "Goods HSN List" }
	];
	title = "Goods HSN List";
	constructor(props) {
		super(props);
		this.tableIcons = UiUtils.getMaterialTableIcons();
		this.state = {
			customerHsnCodesArray: new Map(),
			selectedHsnCodes: [],
			GoodsHSNList: [],
			deletedSacCode: [],
			searchText: "",
			pageNo: 0,
			showPerPage: 10,
			totalRecords: 0,
			checkedSacCode: false,
			render: false,
		};
		// this.getGoodsHSNCodes(this.state.pageNo, this.state.showPerPage);
	}
	componentDidMount() {
		this.onPaginationChange({
			buttonIndex: this.state.pageNo,
			showPerPage: this.state.pageSize,
		});
	}

	getGoodsHSNCodes = (pageNo, pageSize) => {
		const postObject = {
			header: this.header,
			page: {
				searchText: this.state.searchText,
				pageNo: pageNo,
				pageSize: pageSize,
			},
		};
		AdminFetchServerData.callPostService("/goodsHsnCodes/getList", postObject).then(
			(output) => {
				if (output.status === "SUCCESS") {
					let custHSNList = output.data.goodsCodeList;
					let page = output.data.page;
					// PtsAlert.success(output.message);
					this.setState({
						GoodsHSNList: custHSNList,
						searchText: page.searchText,
						pageNo: page.pageNo,
						totalPages: page.totalPages,
						totalRecords: page.total,
						showPerPage: page.pageSize,
						render: true,
					});
				} else {
				}
			}
		);
	};

	// getCustomerGoodsHSNCodes = () => {
	// 	const header = store.getState().header;
	// 	AdminFetchServerData.callPostService("/goodsHsnCodes/getList", header).then(
	// 		(output) => {
	// 			if (output.status === "SUCCESS") {
	// 				let custHSNList = output.data;
	// 				for (let i = 0; i < custHSNList.length; i++) {
	// 					this.state.customerHsnCodesArray.set(
	// 						custHSNList[i].code,
	// 						custHSNList[i]
	// 					);
	// 				}
	// 				// PtsAlert.success(output.message);
	// 				this.setState({
	// 					// customerHsnCodesArray: custHSNList,
	// 					render: true,
	// 				});
	// 			} else {
	// 			}
	// 		}
	// 	);
	// };

	selectedHsn(e, rowData) {
		const { checked } = e.target;
		// let selectedHsnCodesArray = this.state.customerHsnCodesArray;
		let selectedHsnCodes = this.state.selectedHsnCodes;
		if (checked === true) {
			let sacObj = {
				description: rowData.description,
				code: rowData.code,
				id: rowData.id,
			};
			this.state.customerHsnCodesArray.set(sacObj.code, sacObj);
			selectedHsnCodes.push(sacObj);
			this.setState({
				// customerHsnCodesArray: selectedHsnCodesArray,
				selectedHsnCodes: selectedHsnCodes,
			});
		} else {
			if (this.state.customerHsnCodesArray.has(rowData.code)) {
				this.state.customerHsnCodesArray.delete(rowData.code);
				let deletedSacCode = [...this.state.deletedSacCode, rowData.code];
				this.setState({
					deletedSacCode: deletedSacCode,
				});
			}
		}
	}

	cancelView() {
		this.props.history.push({ pathname: "/master/" });
	}
	onPaginationChange(returnProp) {
		let counter = returnProp.counter;
		let pageNo = this.state.pageNo + counter;
		let pageSize = returnProp.showPerPage;
		let buttonIndex = returnProp.buttonIndex;
		let currentPageIndex =
			typeof counter !== "undefined" ? pageNo : buttonIndex;
		let resetPageNo = returnProp.resetPageNo;
		if (resetPageNo === true) {
			currentPageIndex = 0;
		}
		this.setState({
			pageNo: currentPageIndex,
		});
		this.getGoodsHSNCodes(currentPageIndex, pageSize);
	}

	onSearchClick() {
		this.getGoodsHSNCodes(this.state.pageNo, this.state.pageSize);
	}

	onSearchChange(e) {
		this.setState({ searchText: e.target.value });
	}

	toGoodHsnCodeDetails(data) {
		this.props.history.push({
			pathname: "/viewGoodsHsn",
			state: {
				goodsHsnId: data.id,
				action: "view",
				breadCrumb: this.breadCrumb,
			},
		});
	}

	addGoodsHsnCode() {
		this.props.history.push({
			pathname: "/createGoodsHsn",
			state: {
				action: "create",
				breadCrumb: this.breadCrumb,
			},
		});
	}
	render() {
		if (this.state.render === false) {
			return <div />;
		}

		const selectedHsnCodes = [];
		this.state.customerHsnCodesArray.forEach((v, k) => {
			let htmlData = (
				<li key={k} className="list-group-item">
					<span>
						<b>{v.code}</b>
						{" - " + v.description}
					</span>
				</li>
			);
			selectedHsnCodes.push(htmlData);
		});
		return (
			<>
				<AdminMenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					history={this.props.history}
					name={this.title}
					backCallback={this.cancelView.bind(this)}
				/>
				<div md={12} style={{ width: "100%", height: "100%", padding: "1em" }}>
					<div className="row">
						<Col md={12} style={{ flexDirection: "column", display: "flex" }}>
							<div className="card">
								<div className="row justify-content-between p-3">
									<div className="d-flex col-12 col-lg-4 col-xl-4">
										<div className="col-12 text-start totalResults">
											<span>Total Results: {this.state.totalRecords}</span>
										</div>
									</div>
									<div className="d-flex col-12 col-lg-8 col-xl-8">
										<div className="col-12 text-end">
											<input
												type="search"
												id="gsearch"
												placeholder="Search"
												name="gsearch"
												value={this.state.searchText}
												onChange={this.onSearchChange.bind(this)}
											/>
											<button
												className="gsearchButton"
												onClick={this.onSearchClick.bind(this)}
											>
												<i className="fa fa-search" aria-hidden="true"></i>
											</button>
											&nbsp;&nbsp;
											<AddIconButton onClick={this.addGoodsHsnCode.bind(this)} />
										</div>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table text-start table-hover">
										<thead className="tableThead">
											<tr>
												<th scope="col fw-bold ">#</th>
												<th scope="col fw-bold">HSN Description</th>
												<th scope="col fw-bold">Codes</th>
											</tr>
										</thead>
										<tbody>
											{this.state.GoodsHSNList?.map((rowData, index) => (
												<tr
													className="hoverTable"
													key={index}
													onClick={this.toGoodHsnCodeDetails.bind(this, rowData)}
												// style={{ backgroundColor: rowData.id === this.state.assetCycleId || this.state.assetCycleIds.includes(rowData.assetid) ? '#FEF5E7' : '#fff', cursor: "pointer" }}
												>
													<td style={{ width: "6px" }}>
														{this.state.pageNo * this.state.showPerPage +
															index +
															1}
													</td>
													<td>{rowData.description}</td>
													<td>{rowData.code}</td>
													{/* <td>
														<input
															type="checkbox"
															checked={this.state.customerHsnCodesArray.has(
																rowData.code
															)}
															onChange={(e) =>
																this.selectedHsn(e, rowData, index)
															}
														/>
													</td> */}
												</tr>
											))}
										</tbody>
									</table>
								</div>
								{this.state.totalRecords === 0 && (
									<div className="row text-center p-2">
										<h5>Data not Available</h5>
									</div>
								)}
								{this.state.totalRecords > 10 && (
									<div className="row justify-content-between">
										<div className="d-flex col-12 col-lg-3 col-xl-3">
											<div
												className="pageSize col-12 text-start ms-4"
												style={{ marginTop: "30px" }}
											>
												Page {this.state.pageNo + 1} of {this.state.totalPages}
											</div>
										</div>
										<div className="d-flex col-12 col-lg-9 col-xl-9">
											<div className="col-12 text-end">
												<Pagination
													onPaginationChange={this.onPaginationChange.bind(
														this
													)}
													showPerPage={this.state.showPerPage}
													total={this.state.totalRecords}
													pageNo={this.state.pageNo}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						</Col>
					</div>
				</div>
			</>
		);
	}
}
