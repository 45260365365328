import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Row, Card, Col } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import store from "../../../redux/store";
import BankAccountList from "../bankAccount/BankAccountList";
import BusinessUnitList from "../businessUnit/BusinessUnitList";
import ExpenseCategoryList from "../expense/ExpenseCategoryList";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import Organization from "../org/Organization";
import RoleList from "../rolemgmt/RoleList";
import ExpenseNumberFormat from "./ExpenseNumberFormat";
import GeneralSettings from "./GeneralSettings";
import "./GeneralSettings.scss";
import InvoiceDisclaimer from "./InvoiceDisclaimer";
import SalesInvoiceNumberFormat from "./SalesInvoiceNumberFormat";
import "./Settings.scss";
import OrganizationDataAction from "../../../redux/organizationData/OrganizationDataAction";
import ETINManagementList from "../etinManagement/ETINManagementList";
import SettingDataAction from "../../../redux/settings/SettingDataAction";

export class Settings extends Component {
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  allMenus = store.getState().userMenus.allMenus;
  breadCrumb = [];
  title = "Settings";
  constructor(props) {
    super(props);
    let infoPageId = "";
    if (this.props.location && this.props.location.pathname) {
      let menu = Utils.getMenu(this.props.location.pathname);
      // this.tabList = Utils.getSubMenus(menu.id);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      infoPageId = props.history.location.state.infoPageId;
    }
    if (infoPageId === "organizationInfo") {
      store.dispatch(appDataAction.setSettingsTab(1));
    } else {
      store.dispatch(appDataAction.setSettingsTab(0));
    }

    this.state = {
      id: "",
      org: {},
      roleList: {},
      tabValue: 0,
      alldata: {},
      disclaimer: {},
      tabList: this.allMenus
        .filter((menu) => menu.menuid === "Settings")[0]
        .childMenus.sort((a, b) => a.displaysequence - b.displaysequence),
      selectedTabIndex: 0,
      selectedTabName: this.allMenus[0].menuid,
      render: false,
    };
    this.getSettingsDetails();
  }

  setSettingOutputToState(settings) {
    let neworg = {
      id: settings.org?.id,
      name: settings.org?.name,
      tradeName: settings.org?.tradeName,
      phone: settings.org?.phone,
      email: settings.org?.email,
      faxNumber: settings.org?.faxNumber,
      gstn: settings.org?.gstn,
      panNo: settings.org?.panNo,
      website: settings.org?.website,
      logo: settings.org?.logo,
      usetnforinvoicing: settings.org?.usetnforinvoicing,
      address: {
        id: settings.org?.addressvo.id,
        line1: settings.org?.addressvo.line1,
        line2: settings.org?.addressvo.line2,
        city: settings.org?.addressvo.city,
        pin: settings.org?.addressvo.pin,
        statevo: {
          id: settings.org?.addressvo.statevo.id,
          name: settings.org?.addressvo.statevo.name,
          code: settings.org?.addressvo.statevo.code,
          snum: settings.org?.addressvo.statevo.snum,
          countryvo: {
            id: settings.org?.addressvo.statevo.countryvo.id,
            name: settings.org?.addressvo.statevo.countryvo.name,
            code: settings.org?.addressvo.statevo.countryvo.code,
          },
        },
      },
    };
    settings.businessUnits.forEach((bu, index) => {
      let newbu = bu;
      if (bu.address) {
        newbu.address = {
          id: bu.address.id,
          line1: bu.address.line1,
          line2: bu.address.line2,
          city: bu.address.city,
          pin: bu.address.pin,
          state: bu.address.statevo,
        };
        settings.businessUnits[index] = newbu;
      }
      if (
        undefined !== bu.address &&
        bu.address &&
        undefined !== bu.address.statevo &&
        bu.address.statevo
      ) {
        let state = {
          id: bu.address.statevo.id,
          name: bu.address.statevo.name,
          code: bu.address.statevo.code,
          snum: bu.address.statevo.snum,
        };
        let country = {
          id: bu.address.statevo.countryvo.id,
          name: bu.address.statevo.countryvo.name,
          code: bu.address.statevo.countryvo.code,
        };
        newbu.address.state = state;
        newbu.address.country = country;
      }
    });
    let allData = settings;
    allData.org = neworg;
    // for (let i = 0; i < allData.businessUnits.length; i++) {
    //   allData.businessUnits[i].startdate = Utils.ptsDateFormat(allData.businessUnits[i].startdate);
    // }

    this.setState({
      id: allData.id,
      org: allData.org,
      roleList: allData.roleList,
      alldata: allData,
      disclaimer: allData.disclaimer,
      render: true,
    });
  }
  getSettingsDetails = (customerId) => {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/settings/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let settings = output.data;
          console.log("settings", settings);
          store.dispatch(OrganizationDataAction.setOrganization(output.data));
          this.setSettingOutputToState(settings);
        } else {
        }
      }
    );
  };

  selectedTab(tabName, index) {
    console.log(tabName.menuid);
    store.dispatch(appDataAction.setSettingsTab(index));
    this.setState({ selectedTabIndex: index, selectedTabName: tabName.menuid });
    let settings = this.state;
    if (
      settings &&
      settings.org &&
      settings.org.addressvo &&
      undefined !== settings.org.addressvo
    ) {
      let address = {
        id: settings.org.addressvo.id,
        line1: settings.org.addressvo.line1,
        line2: settings.org.addressvo.line2,
        city: settings.org.addressvo.city,
        pin: settings.org.addressvo.pin,
        statevo: {
          id: settings.org.addressvo.statevo.id,
          name: settings.org.addressvo.statevo.name,
          code: settings.org.addressvo.statevo.code,
          snum: settings.org.addressvo.statevo.snum,
          countryvo: {
            id: settings.org.addressvo.statevo.countryvo.id,
            name: settings.org.addressvo.statevo.countryvo.name,
            code: settings.org.addressvo.statevo.countryvo.code,
          },
        },
      };
      let neworg = this.state.org;
      neworg.address = address;
      this.setState({
        org: neworg,
        tabValue: index,
      });
    } else {
      this.setState({ tabValue: index });
    }
  }
  handleChange(e) {}

  businessUnitChanged(businessunit) {
    if (businessunit.isAdd && businessunit.isAdd === true) {
      businessunit.isAdd = false;
      let allData = this.state.alldata;
      allData.businessUnits.push(businessunit);
      this.setState({ alldata: allData });
    } else if (businessunit.isUpdate && businessunit.isUpdate === true) {
      this.state.render = false;
      businessunit.isUpdate = false;
      let allData = this.state.alldata;
      allData.businessUnits.forEach((t, index) => {
        if (t.id === businessunit.id) {
          allData.businessUnits[index] = businessunit;
        } else {
          allData.businessUnits[index] = t;
        }
      });
      this.setState({ alldata: allData, render: true });
    } else if (businessunit.isDelete && businessunit.isDelete === true) {
      this.state.render = false;
      let allData = this.state.alldata;
      allData.businessUnits.forEach((t, index) => {
        if (t.id === businessunit.id) {
          allData.businessUnits.splice(index, 1);
        } else {
          allData.businessUnits[index] = t;
        }
      });
      this.setState({ alldata: allData, render: true });
    }
  }

  generalSettingsChanged(settings) {
    let allData = this.state.alldata;
    let allMenus = settings.profile.menus;
    allData.isexportapplicable = settings.isexportapplicable;
    allData.issezapplicable = settings.issezapplicable;
    allData.issalesthrecom = settings.issalesthrecom;
    allData.ispregstinvoices = settings.ispregstinvoices;
    allData.isoriginalduplicatebills = settings.isoriginalduplicatebills;
    store.dispatch(SettingDataAction.setSettings(settings));
    this.setState({
      alldata: allData,
      tabList: allMenus
        .filter((menu) => menu.menuid === "Settings")[0]
        .childMenus.sort((a, b) => a.displaysequence - b.displaysequence),
    });
  }

  invoiceDisclaimerChanged(disclaimer) {
    this.setState({ disclaimer: disclaimer });
  }
  bankAccountChanged(bankAccount) {
    if (bankAccount.isAdd && bankAccount.isAdd === true) {
      bankAccount.isAdd = false;
      let allData = this.state.alldata;
      allData.bankAccounts.push(bankAccount);
      this.setState({ alldata: allData });
    } else if (bankAccount.isUpdate && bankAccount.isUpdate === true) {
      this.state.render = false;
      bankAccount.isUpdate = false;
      let allData = this.state.alldata;
      allData.bankAccounts.forEach((t, index) => {
        if (t.id === bankAccount.id) {
          allData.bankAccounts[index] = bankAccount;
        } else {
          allData.bankAccounts[index] = t;
        }
      });
      this.setState({ alldata: allData, render: true });
    } else if (bankAccount.isDelete && bankAccount.isDelete === true) {
      this.state.render = false;
      let allData = this.state.alldata;
      allData.bankAccounts.forEach((t, index) => {
        if (t.id === bankAccount.id) {
          allData.bankAccounts.splice(index, 1);
        } else {
          allData.bankAccounts[index] = t;
        }
      });
      this.setState({ alldata: allData, render: true });
    }
  }
  organizationChanged(organization) {
    this.state.render = false;
    this.setState({ org: organization, tabValue: 1, render: true });
  }
  renderKey() {
    var key = this.state.selectedTabName;
    switch (key) {
      case "SettingGeneral":
        return (
          <Row style={{ position: "relative", margin: "0.1em 0em 1em 0em" }}>
            <Col md={12} style={{ position: "relative", padding: "1px" }}>
              <Card>
                <GeneralSettings
                  data={this.state.alldata}
                  onChange={this.generalSettingsChanged.bind(this)}
                  action="view"
                />
              </Card>
            </Col>
          </Row>
        ); // dont forget to import this component
      case "SettingOrganization":
        return (
          <>
            <Row md={12}>
              <Col md={12} style={{ position: "relative", padding: "1px" }}>
                <Organization
                  data={this.state.org}
                  onChange={this.organizationChanged.bind(this)}
                  action="view"
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={12} style={{ position: "relative", margin: "1em 0em" }}>
                <BankAccountList
                  data={this.state.alldata.bankAccounts}
                  onChange={this.bankAccountChanged.bind(this)}
                  action="view"
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={12} style={{ position: "relative", margin: "1em 0em" }}>
                <BusinessUnitList
                  data={this.state.alldata.businessUnits}
                  onChange={this.businessUnitChanged.bind(this)}
                  action="view"
                />
              </Col>
            </Row>
          </>
        );
      case "SettingSales":
        return (
          <>
            <Row md={12} style={{ padding: "0.1em 1em" }}>
              <Col md={12} style={{ position: "relative", padding: "1px" }}>
                <InvoiceDisclaimer
                  data={this.state.disclaimer}
                  action="view"
                  onChange={this.invoiceDisclaimerChanged.bind(this)}
                />
              </Col>
            </Row>
            <Row md={12} style={{ padding: "1em" }}>
              <Col md={12} style={{ position: "relative", padding: "1px" }}>
                <SalesInvoiceNumberFormat action="view" />
              </Col>
            </Row>
          </>
        );
      case "SettingExepense":
        return (
          <>
            {" "}
            <Row md={12} style={{ padding: "0.1em 1em" }}>
              <ExpenseNumberFormat style={{ marginTop: "5em" }} action="view" />
            </Row>
            <Row md={12} style={{ padding: "1em" }}>
              <Col md={12} style={{ position: "relative", padding: "1px" }}>
                <ExpenseCategoryList
                  style={{ marginTop: "5em" }}
                  action="view"
                />
              </Col>
            </Row>
          </>
        );
      case "SettingRole":
        return (
          <Row md={12} style={{ padding: "0.1em 1em" }}>
            <Col md={12} style={{ position: "relative", padding: "1px" }}>
              <RoleList data={this.state.roleList} action="view" />
            </Col>
          </Row>
        );
      case "SettingEtin":
        return (
          <Row md={12} style={{ padding: "0.1em 1em" }}>
            <Col md={12} style={{ position: "relative", padding: "1px" }}>
              <ETINManagementList style={{ marginTop: "5em" }} action="view" />
            </Col>
          </Row>
        );
      default:
        return (
          <Row style={{ position: "relative", margin: "0.1em 0em 1em 0em" }}>
            <Col md={12} style={{ position: "relative", padding: "1px" }}>
              <Card>
                <GeneralSettings
                  data={this.state.alldata}
                  onChange={this.generalSettingsChanged.bind(this)}
                  action="view"
                />
              </Card>
            </Col>
          </Row>
        );
    }
  }
  render() {
    if (this.state.render === false) {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
      );
    }
    let activeMenuList = [];
    for (let i = 0; i < this.state.tabList.length; i++) {
      if (this.state.tabList[i].isactive === true) {
        activeMenuList.push(this.state.tabList[i]);
      }
    }
    const tabList = activeMenuList.map((tab, keyIndex) => {
      return (
        // tab.isactive && (
        <Tab key={keyIndex} onClick={(e) => this.selectedTab(tab, keyIndex)}>
          <span style={{ fontWeight: "600" }}>{tab.uiname}</span>
        </Tab>
        // )
      );
    });
    let selectedIndex = store.getState().appData.settingSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }
    console.log(
      this.state.alldata.businessUnits,
      "this.state.alldata.businessUnits"
    );
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row>
          <Col md={12} className="settingTabRow">
            <Tabs
              md={12}
              style={{
                width: "100%",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
              selectedIndex={selectedIndex}
              className="settingTab"
            >
              <TabList
                style={{ color: "#1b7189", outline: "none", margin: "0" }}
              >
                {tabList}
              </TabList>
              {this.renderKey()}
              {/* <TabPanel>
                <Row
                  style={{ position: "relative", margin: "0.1em 0em 1em 0em" }}
                >
                  <Col md={12} style={{ position: "relative", padding: "1px" }}>
                    <Card>
                      <GeneralSettings
                        data={this.state.alldata}
                        onChange={this.generalSettingsChanged.bind(this)}
                        action="view"
                      />
                    </Card>
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel>
                <Row md={12}>
                  <Col md={12} style={{ position: "relative", padding: "1px" }}>
                    <Organization
                      data={this.state.org}
                      onChange={this.organizationChanged.bind(this)}
                      action="view"
                    />
                  </Col>
                </Row>
                <Row md={12}>
                  <Col
                    md={12}
                    style={{ position: "relative", margin: "1em 0em" }}
                  >
                    <BankAccountList
                      data={this.state.alldata.bankAccounts}
                      onChange={this.bankAccountChanged.bind(this)}
                      action="view"
                    />
                  </Col>
                </Row>
                <Row md={12}>
                  <Col
                    md={12}
                    style={{ position: "relative", margin: "1em 0em" }}
                  >
                    <BusinessUnitList
                      data={this.state.alldata.businessUnits}
                      onChange={this.businessUnitChanged.bind(this)}
                      action="view"
                    />
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel>
                <Row md={12} style={{ padding: "0.1em 1em" }}>
                  <Col md={12} style={{ position: "relative", padding: "1px" }}>
                    <InvoiceDisclaimer
                      data={this.state.disclaimer}
                      action="view"
                      onChange={this.invoiceDisclaimerChanged.bind(this)}
                    />
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "1em" }}>
                  <Col md={12} style={{ position: "relative", padding: "1px" }}>
                    <SalesInvoiceNumberFormat action="view" />
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel>
                <Row md={12} style={{ padding: "0.1em 1em" }}>
                  <ExpenseNumberFormat
                    style={{ marginTop: "5em" }}
                    action="view"
                  />
                </Row>
                <Row md={12} style={{ padding: "1em" }}>
                  <Col md={12} style={{ position: "relative", padding: "1px" }}>
                    <ExpenseCategoryList
                      style={{ marginTop: "5em" }}
                      action="view"
                    />
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel>
                <Row md={12} style={{ padding: "0.1em 1em" }}>
                  <Col md={12} style={{ position: "relative", padding: "1px" }}>
                    <RoleList data={this.state.roleList} action="view" />
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel>
                <Row md={12} style={{ padding: "0.1em 1em" }}>
                  <Col md={12} style={{ position: "relative", padding: "1px" }}>
                    <ETINManagementList
                      style={{ marginTop: "5em" }}
                      action="view"
                    />
                  </Col>
                </Row>
              </TabPanel> */}
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}
export default Settings;
