import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import PaymentCancelButton from '../buttons/PaymentCancelButton';
import PaymentSaveButton from '../buttons/PaymentSaveButton';
import DeleteIconButton from '../buttons/DeleteIconButton';
import EditIconButton from '../buttons/EditIconButton';

class ETINManagement extends Component {
	constructor(props) {
		super(props);
		let action = props.action;
		// let etinId = props.etin;
		let data = props.data;

		this.state = {
			action: action,
			id: data.id,
			etin: data.etin,
			description: data.description,
			operation: props.data.operation,
			srno: props.srno,
			iteamIndex: props.iteamIndex,
			onEtinAdd: props.onAdd,
			onEtinEdit: props.onEdit,
			onEtinDelete: props.onEtinDelete,
			onEtinUpdateSave: props.onEtinUpdateSave,
			onEtinRemove: props.onEtinRemove,
			onEtinEditCallback: props.onEtinEditClick,
			key: props.key,
			render: false,
		};
	}

	componentDidMount() {
		this.setState({
			id: this.props.data.id,
		});
	}

	componentWillReceiveProps(props) {
		if (props?.data?.state !== this.state.state) {
			this.setState({
				action: props.action,
				id: props.data.id,
				etin: props.data.etin,
				description: props.data.description,
				operation: props.data.operation,
				srno: props.srno,
				iteamIndex: props.iteamIndex,
				onEtinAdd: props.onAdd,
				onEtinEdit: props.onEdit,
				onEtinDelete: props.onEtinDelete,
				onEtinUpdateSave: props.onEtinUpdateSave,
				onEtinRemove: props.onEtinRemove,
				onEtinEditCallback: props.onEtinEditClick,
				key: props.key,
				render: false,
			});
		}
	}

	onEtinAdd() {
		if (this.state.onEtinAdd) {
			this.state.onEtinAdd(this.state);
		}
	}

	handleEtinChange(e) {
		this.setState({
			etin: e.target.value,
		})
	}
	handleDescriptionChange(e) {
		this.setState({
			description: e.target.value,
		})
	}

	updateNotes(e) {
		if (this.state.onNoteUpdate) {
			this.setState({ action: 'view' });
			this.state.onNoteUpdate(this.state.key);
		}
	}

	deleteEtin(e) {
		if (undefined !== this.state.onEtinDelete && null !== this.state.onEtinDelete) {
			this.props.onEtinDelete(this.props.data);
		}
	}

	onEtinEditClick(id) {
		this.setState({ action: 'edit' });
	}


	updateEtin(e) {
		if (this.state.onEtinUpdateSave) {
			this.setState({ action: 'view' });
			this.state.onEtinUpdateSave(this.state);
		}
	}

	removeEtin() {
		if (this.state.onEtinRemove) {
			this.state.onEtinRemove(this.state);
		}
	}

	editEtinCancel() {
		this.setState({
			action: "view"
		})
	}

	render() {
		if (this.state.action === "create") {
			return this.addEtins()
		} else if (this.state.action === "view") {
			return this.viewEtin()
		} else if (this.state.action === "edit") {
			return this.editEtin()
		}
	}

	addEtins() {
		return (
			<>
				< tr >
					<td style={{ width: "5%" }}></td>
					<td>
						<TextField
							type="text"
							fullWidth
							className="textField"
							// style={{ width: "310px" }}
							variant="outlined"
							placeholder="Electronic Transmitter Identification Number(ETIN)"
							value={this.state.etin}
							onChange={this.handleEtinChange.bind(this)}
							required
						/>
					</td>
					<td>
						<TextField
							type="text"
							fullWidth
							className="textField"
							// style={{ width: "310px" }}
							variant="outlined"
							placeholder="Description"
							value={this.state.description}
							onChange={this.handleDescriptionChange.bind(this)}
							required
						/>
					</td>
					<td style={{ width: "20%", alignItems: "center" }}>
						<PaymentCancelButton
							onClick={this.removeEtin.bind(this)}
						/>
						<PaymentSaveButton onClick={this.onEtinAdd.bind(this)} />
					</td>
				</tr>
			</>
		)
	}

	viewEtin() {
		return (
			<tr
				className="hoverTable"
				key={this.props.iteamIndex}
			>
				<td style={{ width: "5%" }}>
					{this.props.iteamIndex}
				</td>
				<td style={{ width: "20%" }}>{this.state.etin}</td>
				<td >{this.state.description}</td>
				<td style={{ width: "20%" }}>
					<DeleteIconButton
						onClick={(e) => this.deleteEtin(e)}
						domainObject="Payment Transaction"
					/>
					<EditIconButton
						onClick={this.onEtinEditClick.bind(this)}
					/>
				</td>
			</tr>
		)
	}

	editEtin() {
		return (
			<>
				< tr >
					<td style={{ width: "5%" }}>{this.props.iteamIndex}</td>
					<td>
						<TextField
							type="text"
							fullWidth
							className="textField"
							// style={{ width: "310px" }}
							variant="outlined"
							placeholder="Electronic Transmitter Identification Number(ETIN)"
							value={this.state.etin}
							onChange={this.handleEtinChange.bind(this)}
							required
						/>
					</td>
					<td>
						<TextField
							type="text"
							fullWidth
							className="textField"
							// style={{ width: "310px" }}
							variant="outlined"
							placeholder="Description"
							value={this.state.description}
							onChange={this.handleDescriptionChange.bind(this)}
							required
						/>
					</td>
					<td style={{ width: "20%", alignItems: "center" }}>
						<PaymentCancelButton
							onClick={this.editEtinCancel.bind(this)}
						/>
						<PaymentSaveButton onClick={this.updateEtin.bind(this)} />
					</td>
				</tr>
			</>
		)
	}
}
export default ETINManagement;