import { GET_STATIC_DATA, SET_STATIC_DATA } from './staticDataTypes';

const initialState = {
  countryList: [],
  stateList: [],
  taxRateList: [],
  uomList: [],
  currencyList: [],
};

const staticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIC_DATA:
      return {
        ...state,
      };
    case SET_STATIC_DATA:
      return {
        ...state,
        countryList: action.payload.countryList,
        stateList: action.payload.stateList,
        currencyList: action.payload.currencyList,
        uomList: action.payload.uomList,
        taxRateList: action.payload.taxrateList,
      };

    default:
      return state;
  }
};

export default staticDataReducer;
