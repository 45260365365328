import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Col, Row } from "reactstrap";
import React, { Component } from 'react';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import './AuditNote.scss';

export class AuditNoteList extends Component {
  auditNoteRefList = null;

  constructor(props) {
    super(props);
    let auditNote = props.data;
    this.tableIcons = UiUtils.getMaterialTableIcons();
    let auditNoteList = props.data;
    for (let i = 0; i < auditNoteList?.length; i++) {
      auditNoteList[i].createddate = Utils.ptsDateFormat(auditNoteList[i].createddate);
    }
    if (auditNote) {
      this.state = {
        history: props.history,
        title: props.title,
        aid: props.aid,
        auditNoteList: null === auditNoteList ? [] : auditNoteList,
      };
    } else {
      this.state = {
        title: 'Audit Notes',
        auditNoteList: [],
      };
    }
  }

  render() {
    if (Utils.isNull(this.state.auditNoteList) || this.state.auditNoteList.length <= 0) {
      return <div></div>;
    }
    this.state.auditNoteList.sort((a, b) => new Date(b.createddate).getTime() - new Date(a.createddate).getTime());
    return (
      <>
        {/* <Col md={8} style={{ margin: "0", padding: "1em" }}>
          <Row md={12} style={{ flexDirection: "column" }}> */}
        <div style={{ display: "block", overflow: "scroll", width: "100%", padding: "0px" }}>
          <Row
            md={12}
            style={{
              fontSize: "small",
              margin: 0,
              padding: 0,
              backgroundColor: "#ececec",
              border: "0.5px solid #cacaca",
            }}
          >
            <Col md={12} className='pt-2 pb-2'>
              <span style={{ fontSize: "15px" }}>
                <strong>{this.props.title}</strong>
              </span>
            </Col>
          </Row>
          {this.state.auditNoteList.length > 0 && (
            <Row
              md={12}
              style={{
                fontSize: "small",
                margin: 0,
                padding: 0,
              }}
            >
              <Table size="small" style={{ color: "black", fontSize: "small" }}>
                <TableHead className="paymentMethodCell">
                  <TableRow style={{ backgroundColor: "#ececec" }}>
                    <TableCell
                      style={{
                        width: "5%",
                        fontSize: "small",
                        border: "0.5px solid #cacaca",
                      }}
                    >
                      <strong style={{ fontSize: "small" }}>#</strong>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        fontSize: "small",
                        border: "0.5px solid #cacaca",
                      }}
                    >
                      <strong style={{ fontSize: "small" }}>Date</strong>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                        fontSize: "small",
                        border: "0.5px solid #cacaca",
                      }}
                    >
                      <strong style={{ fontSize: "small" }}>Note</strong>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        fontSize: "small",
                        border: "0.5px solid #cacaca",
                      }}
                    >
                      <strong style={{ fontSize: "small" }}>Created By</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {this.state.auditNoteList?.map((rowData, index) => (
                  <TableBody>
                    <TableRow className="paymentMethodCell" style={{ backgroundColor: "#FFFEE3" }}>
                      <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                        <span style={{ fontSize: "small" }}>{index + 1}</span>
                      </TableCell>
                      <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                        <span style={{ fontSize: "small" }}> {Utils.ptsDateFormat(rowData.createddate)}</span>
                      </TableCell>
                      <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                        <span style={{ fontSize: "small" }}>{rowData.note}</span>
                      </TableCell>
                      <TableCell style={{ border: "0.5px solid #ece6e6" }}>
                        <span style={{ fontSize: "small" }}>{rowData.auditor.firstName + ' ' + rowData.auditor.lastName}</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </Row>
          )}
        </div>
        {/* </Row>
        </Col> */}
      </>
    );
  }
}

export default AuditNoteList;
