import React, { Component } from 'react'
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { Card } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import SalesInvoiceDialogue from "../../sales/SalesInvoiceDialogue";
import Address from "../../address/Address";
import store from '../../../../redux/store';
import Utils from "../../../../provider/Utils";
import FetchServerData from '../../../../provider/FetchServerData';
import SalesVoucherItem from "../../sales/salesVoucher/SalesVoucherItem";
import PtsAlert from '../../ptsAlert/PtsAlert';

class SupplierInvoice extends Component {
	header = store.getState().header.header;
	constructor(props) {
		super(props);
		let supplierId = props.supplierId;
		let supplierInvoiceId = props.supplierInvoiceId;
		let gstn = props.gstn;
		let invoiceNumber = props.invoiceNumber;
		let invoiceDate = props.invoiceDate;
		let supTid = props.supTid;
		let supEmail = props.supEmail;
		let customerGstn = props.customerGstn;
		let customerTid = props.customerTid;
		let customerEmail = props.customerEmail;
		if (
			!supplierInvoiceId &&
			props.history &&
			props.history.location &&
			props.history.location.state
		) {
			supplierId = props.history.location.state.supplierId;
			supplierInvoiceId = props.history.location.state.supplierInvoiceId;
			gstn = props.history.location.state.gstn;
			invoiceNumber = props.history.location.state.invoiceNumber;
			invoiceDate = props.history.location.state.invoiceDate;
			supTid = props.history.location.state.supTid;
			supEmail = props.history.location.state.supEmail;
			customerGstn = props.history.location.state.customerGstn;
			customerTid = props.history.location.state.customerTid;
			customerEmail = props.history.location.state.customerEmail;
			this.breadCrumb = props.history.location.state.breadCrumb;
		}

		this.state = {
			invoice: {},
			supplierId: supplierId,
			supplierInvoiceId: supplierInvoiceId,
			gstn: gstn,
			supTid: supTid,
			supEmail: supEmail,
			customerGstn: customerGstn,
			customerTid: customerTid,
			customerEmail: customerEmail,
			invoiceNumber: invoiceNumber,
			invoiceDate: invoiceDate,
		}
		this.getSupplierInvoiceDetails(supplierInvoiceId);
	}
	getSupplierInvoiceDetails() {
		const postObject = {
			header: this.header,
			supplier: {
				gstn: this.state.gstn,
				supTid: this.state.supTid,
				supEmail: this.state.supEmail
			},
			customerGstn: this.state.customerGstn,
			customerTid: this.state.customerTid,
			customerEmail: this.state.customerEmail,
			invoice: {
				id: this.state.supplierInvoiceId,
				number: this.state.invoiceNumber,
				invdate: this.state.invoiceDate
			},
		};
		FetchServerData.callPostService("/supplierms/getSupInvoice", postObject).then((output) => {
			if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
				let invoice = output.data.invoice;
				this.setResponseToState(invoice);
			} else {
			}
		});
	}

	setResponseToState(invoice) {
		if (Utils.isNull(invoice.privileges)) {
			invoice.privileges = this.state.privileges;
		}
		this.setState({
			invoice: invoice,
			paymentTransactions: invoice.paymentTransactions,
			advanceAmountUsed: invoice.advanceAmountUsed,
			advanceGstUsed: invoice.advanceGstUsed,
			auditnotes: invoice.auditnotes,
			bankaccount: invoice.bankaccount,
			barcode: invoice.barcode,
			barcodeImage: invoice.barcodeImage,
			billtoaddress: invoice.billtoaddress,
			shiptoaddress: invoice.shiptoaddress,
			bu: invoice.bu,
			cdnreason: invoice.cdnreason,
			cess: invoice.cess,
			cesspercent: invoice.cesspercent,
			cgst: invoice.cgst,
			createdon: invoice.createdon,
			currency: invoice.currency,
			customerVo: invoice.customerVo,
			description: invoice.description,
			electronicreference: invoice.electronicreference,
			etin: invoice.etin,
			exchangerate: invoice.exchangerate,
			expinvoicetype: invoice.expinvoicetype,
			freez: invoice.freez,
			fright: invoice.fright,
			gstdate: invoice.gstdate,
			supplierInvoiceId: invoice.id,
			igst: invoice.igst,
			invnumber: invoice.invnumber,
			invoicedate: invoice.invoicedate,
			invoicesource: invoice.invoicesource,
			invoiceStatusList: invoice.invoiceStatusList,
			isAsset: invoice.isAsset,
			isbillingshippingsame: invoice.isbillingshippingsame,
			isexport: invoice.isexport,
			isimported: invoice.isimported,
			ismanualrefinv: invoice.ismanualrefinv,
			isnilrated: invoice.isnilrated,
			ispregst: invoice.ispregst,
			isrcmsale: invoice.isrcmsale,
			isRecurring: invoice.isRecurring,
			isroundup: invoice.isroundup,
			issez: invoice.issez,
			isSystemGenerated: invoice.isSystemGenerated,
			loadingpackaging: invoice.loadingpackaging,
			manualrefinv: invoice.manualrefinv,
			miscellaneous: invoice.miscellaneous,
			month: invoice.month,
			payment: invoice.payment,
			privileges: invoice.privileges,
			purchasedate: invoice.purchasedate,
			purchaseorder: invoice.purchaseorder,
			referencedInvoice: invoice.referencedInvoice,
			referenceinvnumber: invoice.referenceinvnumber,
			referencingInvoices: invoice.referencingInvoices,
			refinvoice: invoice.refinvoice,
			salesthrecom: invoice.salesthrecom,
			satype: invoice.satype,
			sezinvoicetype: invoice.sezinvoicetype,
			sgst: invoice.sgst,
			sharewithcust: invoice.sharewithcust,
			supplytype: invoice.supplytype,
			status: invoice.status,
			totaladvance: invoice.totaladvance,
			totalamount: invoice.totalamount,
			totalgst: invoice.totalgst,
			totalinwords: invoice.totalinwords,
			totaltaxable: invoice.totaltaxable,
			totaltaxableinwords: invoice.totaltaxableinwords,
			utgst: invoice.utgst,
			year: invoice.year,
			invoiceItems: invoice.invoiceItems,
			render: true,
		});
	}

	cancelView() {
		this.props.history.push({
			pathname: "/supplierview",
			state: {
				supplierId: this.state.supplierId,
				action: "view",
				breadCrumb: this.breadCrumb,
			}
		});
	}

	supplierInvoicePullToPurchase() {
		const postObject = {
			header: this.header,
			supplier: {
				gstn: this.state.gstn,
				supTid: this.state.supTid,
				supEmail: this.state.supEmail
			},
			customerGstn: this.state.customerGstn,
			customerTid: this.state.customerTid,
			customerEmail: this.state.customerEmail,
			invoice: this.state.invoice,
		};
		FetchServerData.callPostService("/supplierms/pullSupInvoice", postObject).then((output) => {
			if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
				PtsAlert.success(output.message);
				this.props.history.push({
					pathname: "/supplierview",
					state: {
						supplierId: this.state.supplierId,
						action: "view",
						breadCrumb: this.breadCrumb,
					}
				});
			} else {
			}
		});
	}

	render() {
		let invoiceItemsList = [];
		let isIgstApplicable = Utils.isIgstApplicable(this.state.customerVo);
		this.state.invoiceItems &&
			this.state.invoiceItems.forEach((element, index) => {
				invoiceItemsList.push(
					<SalesVoucherItem
						isIgstApplicable={isIgstApplicable}
						key={index}
						data={element}
						backgroundColor={
							index % 2 ? { background: "#EFF4F7" } : { background: "#F7F7F7" }
						}
						srno={index + 1}
						action="view"
						productList={this.productList}
						custHsnCodeList={this.custHsnCodeList}
					/>
				);
			});

		let menuWithBreadScrum = (
			<MenuWithBreadScrum
				ref={this.menuWithBreadScrumRef}
				breadCrumb={this.breadCrumb}
				history={this.props.history}
				addToBreadCrumb="View Sales Voucher"
				name="View Sales Voucher"
			// docTitle={this.state.invnumber}
			// backCallback={this.cancelView.bind(this)}
			/>
		);

		return (
			<div style={{ display: "grid" }}>
				<SalesInvoiceDialogue
					ref={this.referenceInvoiceDialogueRef}
					style={{ float: "right" }}
				/>
				{menuWithBreadScrum}

				<Card md={12} style={{ margin: "1em" }}>
					<CardHeader>
						<Row md={12} style={{ margin: "0", padding: "0", display: "flex" }}>
							<Col
								md={12}
								style={{ padding: "5px", margin: "0", display: "flex" }}
							>
								<div className="row pb-1">
									<span
										style={{
											fontSize: "17px",
											fontWeight: "600",
											color: "#1b7189",
										}}
									>
										{this.state.invnumber}
									</span>
								</div>
							</Col>
							<Col
								md={4}
								style={{ padding: "5px", margin: "0", display: "flex" }}
							>
								<div style={{ width: "100%" }}>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",

												textAlign: "left",
											}}
										>
											<strong>Customer Name</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.customerVo?.name}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",

												textAlign: "left",
											}}
										>
											<strong>Customer Type</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.customerVo?.customertype.description}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",

												textAlign: "left",
											}}
										>
											<strong>GSTN</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.customerVo?.gstn}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>PAN</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{/* {this.state.customerVo.pan} */}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",

												textAlign: "left",
											}}
										>
											<strong>Share With Customer</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.sharewithcust &&
												this.state.sharewithcust === true
												? "Yes"
												: "No"}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>Sales Type</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.satype ? this.state.satype.type : ""}
										</label>
									</Row>

									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>Business Unit</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.bu?.name}
										</label>
									</Row>
									{this.state.exchangerate && this.state.exchangerate > 1 && (
										<Row style={{ margin: 0, padding: 0, width: "100%" }}>
											<label
												style={{
													margin: 0,
													padding: 0,
													color: "#000",
													width: "40%",
													textAlign: "left",
												}}
											>
												<strong>Currency Rate</strong>
											</label>
											<label
												style={{
													width: "60%",
													color: "#000",
													margin: "0",
													padding: "0",
													textAlign: "left",
												}}
											>
												{this.state.exchangerate}
											</label>
										</Row>
									)}
								</div>
							</Col>
							<Col md={4} style={{ display: "flex" }}>
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Row style={{ flex: 1 }}>
										<Address
											ref={this.billingAddressRef}
											title="Billing Address"
											data={this.state.billtoaddress}
											action="view"
										/>
									</Row>
									<Row style={{ flex: 1 }}>
										<Address
											ref={this.shippingAddressRef}
											title="Shipping Address"
											data={this.state.shiptoaddress}
											action="view"
										/>
									</Row>
								</div>
							</Col>
							<Col
								md={4}
								style={{ padding: "5px", margin: "0", display: "flex" }}
							>
								<div style={{ width: "100%" }}>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>Created On</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{/* { this.state.createdon ? format(new Date(), 'yyyy-MM-dd') : ''} */}
											{Utils.ptsDateFormat(this.state.createdon)}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>Invoice Date</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{/* {this.state.invoicedate} */}
											{Utils.ptsDateFormat(this.state.invoicedate)}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>GST Date</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{/* {this.state.gstdate} */}
											{Utils.ptsDateFormat(this.state.gstdate)}
										</label>
									</Row>

									{(this.state.isexport === true ||
										Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
											<Row style={{ margin: 0, padding: 0, width: "100%" }}>
												<label
													style={{
														margin: 0,
														padding: 0,
														color: "#000",
														width: "40%",
														textAlign: "left",
													}}
												>
													<strong>Is Export</strong>
												</label>
												<label
													style={{
														width: "60%",
														color: "#000",
														margin: "0",
														padding: "0",
														textAlign: "left",
													}}
												>
													Yes
												</label>
											</Row>
										)}

									{this.state.isAsset === true && (
										<Row style={{ margin: 0, padding: 0, width: "100%" }}>
											<label
												style={{
													margin: 0,
													padding: 0,
													color: "#000",
													width: "40%",
													textAlign: "left",
												}}
											>
												<strong>Is Asset Sale</strong>
											</label>
											<label
												style={{
													width: "60%",
													color: "#000",
													margin: "0",
													padding: "0",
													textAlign: "left",
												}}
											>
												Yes
											</label>
										</Row>
									)}
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>Status</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.status ? this.state.status?.description : ""}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>PO </strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.purchaseorder ? this.state.purchaseorder : ""}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>PO Date</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{/* {this.state.purchasedate ? this.state.purchasedate : ''} */}
											{Utils.ptsDateFormat(this.state.purchasedate)}
										</label>
									</Row>
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												margin: 0,
												padding: 0,
												color: "#000",
												width: "40%",
												textAlign: "left",
											}}
										>
											<strong>Remark</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{undefined !== this.state.description
												? this.state.description
												: ""}
										</label>
									</Row>
									{Utils.isNotNullAndEmpty(this.state.referencingInvoices) && (
										<Row style={{ margin: 0, padding: 0, width: "100%" }}>
											<label
												style={{
													margin: 0,
													padding: 0,
													color: "#000",
													width: "40%",
													textAlign: "left",
												}}
											>
												<strong>Referencing Invoices</strong>
											</label>
											<label
												style={{
													width: "60%",
													color: "#000",
													margin: "0",
													padding: "0",
													textAlign: "left",
												}}
											>
												{this.getReferencingInvoices()}
											</label>
										</Row>
									)}
									{Utils.isNotNullAndEmpty(this.state.refinvoice) && (
										<Row style={{ margin: 0, padding: 0, width: "100%" }}>
											<label
												style={{
													margin: 0,
													padding: 0,
													color: "#000",
													width: "40%",
													textAlign: "left",
												}}
											>
												<strong>Advance Voucher</strong>
											</label>
											<label
												style={{
													width: "60%",
													color: "#000",
													margin: "0",
													padding: "0",
													textAlign: "left",
												}}
											>
												{this.state.referenceinvnumber && (
													<Link
														style={{ cursor: "pointer" }}
													// onClick={this.showReferenceInvoice.bind(this)}
													>
														<strong>
															{this.state.referenceinvnumber
																? this.state.referenceinvnumber
																: ""}
														</strong>
													</Link>
												)}
											</label>
										</Row>
									)}
									<Row style={{ margin: 0, padding: 0, width: "100%" }}>
										<label
											style={{
												color: "#000",
												width: "40%",
												margin: 0,
												padding: 0,
												textAlign: "left",
											}}
										>
											<strong>Bank Account</strong>
										</label>
										<label
											style={{
												width: "60%",
												color: "#000",
												margin: "0",
												padding: "0",
												textAlign: "left",
											}}
										>
											{this.state.bankaccount && (
												<span>
													{this.state.bankaccount.accountname + " - " + this.state.bankaccount.bankname}
												</span>
											)}
										</label>
									</Row>
								</div>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						{/* {this.getAdvanceVoucherDetails()} */}
						<Row
							md={12}
							style={{
								padding: "5px",
								margin: "0",
								minHeight: "1em",
							}}
						></Row>

						<table>
							<thead>
								<tr md={12} style={{ padding: "0px", margin: "0" }}>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											width: "10%",
										}}
									>
										<span style={{ float: "left" }}>#</span>
										<span style={{ float: "right" }}>SAC/HSN</span>
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											width: "20%",
										}}
									>
										Item Details
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											width: "10%",
										}}
									>
										UOM
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "3%",
										}}
									>
										Qty
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "8%",
										}}
									>
										Unit Price
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "10%",
										}}
									>
										Gross Value
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "10%",
										}}
									>
										Discount %
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "10%",
										}}
									>
										Net Value
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "5%",
										}}
									>
										CESS %
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "15%",
										}}
									>
										Tax Rate %
									</td>
									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "10%",
										}}
									>
										GST
									</td>

									<td
										style={{
											background: "#ececec",
											color: "#000",
											fontWeight: "bold",
											border: "0.5px solid #ece6e6",
											padding: "0.5em",
											textAlign: "right",
											width: "10%",
										}}
									>
										Total
									</td>
								</tr>
							</thead>
							{invoiceItemsList}
						</table>
						<Row
							md={12}
							style={{ padding: "0px", margin: "0", marginTop: "1em" }}
						>
							<Col md={8} style={{ margin: "0", padding: "1em" }}>
								{Utils.equalsIgnoreCase(
									"VERIFICATIONREQUESTED",
									this.state.status ? this.state.status?.status : ""
								) && (
										<Row md={12} style={{ flexDirection: "column" }}>
											<label
												style={{
													color: "#000",
													width: "40%",
													margin: 0,
													paddingBottom: "6px",
													textAlign: "left",
												}}
											>
												<strong>Audit Note</strong>
											</label>
											<TextField
												id="auditnote"
												type="text"
												variant="outlined"
												multiline={true}
												rows={2}
												value={this.state.auditNote ? this.state.auditNote : " "}
												onChange={this.addAuditNote.bind(this)}
											/>
										</Row>
									)}
							</Col>
							<Col md={4} style={{ margin: "0", padding: "0" }}>
								<Row md={12} style={{ padding: "5px", margin: "0" }}>
									<Col
										md={9}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										<strong>Value Of Supply</strong>
									</Col>
									<Col
										md={3}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										{/* {this.state.currency.symbol !== null
											? this.state.currency.symbol
											: ""} */}
										{Utils.getFloatValue(this.state.totaltaxable)}
									</Col>
								</Row>
								{Utils.isIgstApplicable(this.state.customerVo) === false && (
									<>
										<Row md={12} style={{ padding: "5px", margin: "0" }}>
											<Col
												md={9}
												style={{
													color: "#000",
													padding: "0.1em",
													textAlign: "right",
												}}
											>
												<strong>CGST</strong>
											</Col>
											<Col
												md={3}
												style={{
													color: "#000",
													padding: "0.1em",
													textAlign: "right",
												}}
											>
												{Utils.getFloatValue(this.state.cgst)}
											</Col>
										</Row>
										<Row md={12} style={{ padding: "5px", margin: "0" }}>
											<Col
												md={9}
												style={{
													color: "#000",
													padding: "0.1em",
													textAlign: "right",
												}}
											>
												<strong>SGST</strong>
											</Col>
											<Col
												md={3}
												style={{
													color: "#000",
													padding: "0.1em",
													textAlign: "right",
												}}
											>
												{Utils.getFloatValue(this.state.sgst)}
											</Col>
										</Row>
									</>
								)}

								{Utils.isIgstApplicable(this.state.customerVo) === true && (
									<Row md={12} style={{ padding: "5px", margin: "0" }}>
										<Col
											md={9}
											style={{
												color: "#000",
												padding: "0.1em",
												textAlign: "right",
											}}
										>
											<strong>IGST</strong>
										</Col>
										<Col
											md={3}
											style={{
												color: "#000",
												padding: "0.1em",
												textAlign: "right",
											}}
										>
											{Utils.getFloatValue(this.state.igst)}
										</Col>
									</Row>
								)}

								<Row md={12} style={{ padding: "5px", margin: "0" }}>
									<Col
										md={9}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										<strong>CESS</strong>
									</Col>
									<Col
										md={3}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										{parseFloat(this.state.cess)}
									</Col>
								</Row>
								<Row md={12} style={{ padding: "5px", margin: "0" }}>
									<Col
										md={9}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										<strong>Misc.(If Any)</strong>
									</Col>
									<Col
										md={3}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										{Utils.getFloatValue(this.state.miscellaneous)}
									</Col>
								</Row>
								<Row md={12} style={{ padding: "5px", margin: "0" }}>
									<Col
										md={9}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										<strong>Total Amount</strong>
									</Col>
									<Col
										md={3}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										{/* {this.state.currency.symbol !== null
											? this.state.currency.symbol
											: ""} */}
										{Utils.getFloatValue(this.state.totalamount)}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row md={12}>
							<Col md={12}>
								<Row md={12} style={{ padding: "5px", margin: "0" }}>
									<Col
										md={12}
										style={{
											color: "#000",
											padding: "0.1em",
											textAlign: "right",
										}}
									>
										{this.state.totalinwords ? this.state.totalinwords : ""}
									</Col>
								</Row>
								{true === this.state.isAdvanceReceived &&
									Utils.isNotNullAndEmpty(this.state.refinvoice) && (
										<>
											<Row md={12} style={{ padding: "5px", margin: "0" }}>
												<Col
													md={6}
													style={{
														color: "#000",
														padding: "0.1em",
														textAlign: "right",
													}}
												>
													<span>Advance Used</span>
												</Col>
												<Col
													md={6}
													style={{
														color: "#000",
														padding: "0.1em",
														textAlign: "right",
													}}
												>
													{Utils.getFloatValue(this.state.advanceAmountUsed)}
												</Col>
											</Row>
											<Row md={12} style={{ padding: "5px", margin: "0" }}>
												<Col
													md={6}
													style={{
														color: "#000",
														padding: "0.1em",
														textAlign: "right",
													}}
												>
													<span>Advance GST Used</span>
												</Col>
												<Col
													md={6}
													style={{
														color: "#000",
														padding: "0.1em",
														textAlign: "right",
													}}
												>
													{Utils.getFloatValue(this.state.advanceGstUsed)}
												</Col>
											</Row>
										</>
									)}
							</Col>
						</Row>
					</CardBody>
				</Card>
				<CardFooter style={{ width: "100%", padding: "10px" }}>
					<div style={{ textAlign: "center" }}>
						<Button
							type="submit"
							variant="contained"
							style={{
								background: "#1b7189",
								color: "#fff",
								marginRight: "1rem",
							}}
							startIcon={<ArrowUpwardIcon />}
							onClick={(e) => this.supplierInvoicePullToPurchase(e)}
						>
							Pull To Purchase
						</Button>
						<Button
							type="submit"
							variant="contained"
							style={{
								background: "#717373",
								color: "#fff",
								marginRight: "1rem",
							}}
							startIcon={<ArrowBackIcon />}
							onClick={(e) => this.cancelView(e)}
						>
							Back
						</Button>
					</div>
				</CardFooter>
			</div>
		);
	}
}
export default SupplierInvoice;