import { GET_USER_MENUS, SET_USER_MENUS, SET_USER_TOPMENUS, SET_USER_DETAILS } from './userMenusTypes';

export const getUserMenus = () => {
  return {
    type: GET_USER_MENUS,
  };
};
export const setUserMenus = data => {
  return {
    type: SET_USER_MENUS,
    payload: data,
  };
};

export const setUserTopMenus = data => {
  return {
    type: SET_USER_TOPMENUS,
    payload: data,
  };
};
export const setUserDetails = data => {
  return {
    type: SET_USER_DETAILS,
    payload: data,
  };
};
const userMenusAction = {
  getUserMenus,
  setUserMenus,
  setUserTopMenus,
  setUserDetails,
}
export default userMenusAction;
