import { toast } from 'react-toastify';
import './PtsAlert.scss';

const PtsAlert = {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: 'toast-success-container toast-success-container-after',
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: 'toast-error-container toast-error-container-after',
    });
  },
  warn(msg, options = {}) {
    return toast.warn(msg, {
      ...options,
      className: 'toast-warn-container toast-warn-container-after',
    });
  },
};

export default PtsAlert;
