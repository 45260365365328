import { format } from "date-fns";
import Utils, { isNull } from "./Utils";
export const createEmptyInvoiceItem = () => {
  let newItem = {
    description: "",
    name: "",
    quantity: "0",
    productcode: "",
    rate: 0.0,
    total: 0.0,
    igst: 0.0,
    sgst: 0.0,
    cgst: 0.0,
    utgst: 0.0,
    discount: 0.0,
    taxable: 0.0,
    // nontaxable: 0.0,
    total: 0.0,
    cess: 0.0,
    operation: "add",
    suphsncodes: null,
  };
  return newItem;
};
export const createInvoiceItem = (item) => {
  let newItem = {
    id: item.id,
    description: item.description,
    name: item.name,
    quantity: item.quantity,
    productcode: item.productcode,
    rate: item.rate,
    total: item.total,
    uuid: item.uuid,
    igst: item.igst,
    sgst: item.sgst,
    cgst: item.cgst,
    utgst: item.utgst,
    discount: item.discount,
    taxable: item.taxable,
    // nontaxable: item.nontaxable,
    refinvitemamount: item.refinvitemamount,
    uom: item.uom,
    suprcmhsncode: item.suprcmhsncode,
    refinvitem: item.refinvitem,
    invoiceitemnumber: item.invoiceitemnumbe,
    cess: item.cess,
    taxrate: item.taxrate,
    // custhsncode: item.custhsncode,
    suphsncodes: item.suphsncodes,
    operation: item.operation,
  };
  if (item.operation) {
    newItem.operation = item.operation;
  }
  return newItem;
};
export const setInvoiceItem = (targetObject, item) => {
  if (!item) {
    return null;
  }
  if (!targetObject) {
    targetObject = {};
  }
  targetObject.id = item.id;
  targetObject.description = item.description;
  targetObject.name = item.name;
  targetObject.quantity = item.quantity;
  targetObject.productcode = item.productcode;
  targetObject.rate = item.rate;
  targetObject.total = item.total;
  targetObject.uuid = item.uuid;
  targetObject.igst = item.igst;
  targetObject.sgst = item.sgst;
  targetObject.cgst = item.cgst;
  targetObject.utgst = item.utgst;
  targetObject.discount = item.discount;
  targetObject.taxable = item.taxable;
  targetObject.nontaxable = item.nontaxable;
  targetObject.refinvitemamount = item.refinvitemamount;
  targetObject.uom = item.uom;
  targetObject.suprcmhsncode = item.suprcmhsncode;
  targetObject.refinvitem = item.refinvitem;
  targetObject.invoiceitemnumber = item.invoiceitemnumbe;
  targetObject.cess = item.cess;
  targetObject.taxrate = item.taxrate;
  //   targetObject.custhsncode = item.custhsncode;
  return targetObject;
};

export const createInvoice = (invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  let outInvoice = {
    advanceAmountUsed: invoice.advanceAmountUsed,
    advanceGstUsed: invoice.advanceGstUsed,
    // auditnotes: invoice.auditnotes,
    bankaccount: invoice.bankaccount,
    billtoaddress: invoice.billtoaddress,
    bu: invoice.bu,
    capitalasset: invoice.capitalasset,
    cdnreason: invoice.cdnreason,
    cess: invoice.cess,
    cesspercent: invoice.cesspercent,
    cgst: invoice.cgst,
    createdon: invoice.createdon,
    currency: invoice.currency,
    description: invoice.description,
    doctypes: invoice.doctypes,
    electronicreference: invoice.electronicreference,
    etin: invoice.etin,
    exchangerate: invoice.exchangerate,
    expinvoicetype: invoice.expinvoicetype,
    freez: invoice.freez,
    fright: invoice.fright,
    gstdate: invoice.gstdate,
    id: invoice.id,
    igst: invoice.igst,
    invnumber: invoice.invnumber,
    invoicedate: invoice.invoicedate,
    invoiceDocuments: invoice.invoiceDocuments,
    invoiceitems: invoice.invoiceitems,
    invoicesource: invoice.invoicesource,
    invoiceStatusList: invoice.invoiceStatusList,
    invoicetype: invoice.invoicetype,
    // isAsset: invoice.isAsset,
    miscellaneous: invoice.miscellaneous,

    isbillingshippingsame: invoice.isbillingshippingsame,
    isimported: invoice.isimported,
    ismanualrefinv: invoice.ismanualrefinv,
    isnilrated: invoice.isnilrated,
    ispregst: invoice.ispregst,
    isrcm: invoice.isrcm,
    isrcmsale: invoice.isrcmsale,
    isrcmsale: invoice.isrcmsale,
    isRecurring: invoice.isRecurring,
    isroundup: invoice.isroundup,
    issez: invoice.issez,
    isSystemGenerated: invoice.isSystemGenerated,
    isUtilized: invoice.isUtilized,
    loadingpackaging: invoice.loadingpackaging,
    manualrefinv: invoice.manualrefinv,
    month: invoice.month,
    payment: invoice.payment,
    paymentTransactions: invoice.paymentTransactions,
    privileges: invoice.privileges,
    purchasedate: invoice.purchasedate,
    purchaseorder: invoice.purchaseorder,
    referencedInvoice: invoice.referencedInvoice,
    referenceinvnumber: invoice.referenceinvnumber,
    referencingInvoices: invoice.referencingInvoices,
    refinvoice: invoice.refinvoice,
    salesthrecom: invoice.salesthrecom,
    satype: invoice.satype,
    sezinvoicetype: invoice.sezinvoicetype,
    sgst: invoice.sgst,
    shiptoaddress: invoice.shiptoaddress,
    status: invoice.status,
    supplier: invoice.supplier,
    supplytype: invoice.supplytype,
    // totaladvance: invoice.totaladvance,
    totalamount: invoice.totalamount,
    totalgst: invoice.totalgst,
    totalinwords: invoice.totalinwords,
    totaltaxable: invoice.totaltaxable,
    totaltaxableinwords: invoice.totaltaxableinwords,
    utgst: invoice.utgst,
    year: invoice.year,
  };
  let itemArray = [];
  if (Utils.isNotNull(invoice.invoiceitems)) {
    for (let i = 0; i < invoice.invoiceitems.length; i++) {
      let item = createInvoiceItem(invoice.invoiceitems[i]);
      itemArray.push(item);
    }
  }

  outInvoice.invoiceitems = itemArray;
  return outInvoice;
};

export const setInvoice = (targetObject, invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  if (!targetObject) {
    targetObject = {};
  }
  targetObject.advanceAmountUsed = invoice.advanceAmountUsed;
  targetObject.advanceGstUsed = invoice.advanceGstUsed;
  // targetObject.auditnotes = invoice.auditnotes;
  targetObject.bankaccount = invoice.bankaccount;
  targetObject.billtoaddress = invoice.billtoaddress;
  targetObject.bu = invoice.bu;
  targetObject.capitalasset = invoice.capitalasset;
  targetObject.cdnreason = invoice.cdnreason;
  targetObject.cess = invoice.cess;
  targetObject.cesspercent = invoice.cesspercent;
  targetObject.cgst = invoice.cgst;
  targetObject.createdon = invoice.createdon;
  targetObject.currency = invoice.currency;
  targetObject.description = invoice.description;
  targetObject.doctypes = invoice.doctypes;
  targetObject.electronicreference = invoice.electronicreference;
  targetObject.etin = invoice.etin?.etin;
  targetObject.exchangerate = invoice.exchangerate;
  targetObject.expinvoicetype = invoice.expinvoicetype;
  targetObject.freez = invoice.freez;
  targetObject.fright = invoice.fright;
  targetObject.gstdate = invoice.gstdate;
  targetObject.id = invoice.id;
  targetObject.igst = invoice.igst;
  targetObject.invnumber = invoice.invnumber;
  targetObject.invoicedate = invoice.invoicedate;
  targetObject.invoiceDocuments = invoice.invoiceDocuments;
  targetObject.invoiceitems = invoice.invoiceitems;
  targetObject.invoicesource = invoice.invoicesource;
  targetObject.invoiceStatusList = invoice.invoiceStatusList;
  targetObject.invoicetype = invoice.invoicetype;
  // targetObject.isAsset = invoice.isAsset;
  targetObject.miscellaneous = invoice.miscellaneous;
  targetObject.isbillingshippingsame = invoice.isbillingshippingsame;
  targetObject.isimported = invoice.isimported;
  targetObject.ismanualrefinv = invoice.ismanualrefinv;
  targetObject.isnilrated = invoice.isnilrated;
  targetObject.ispregst = invoice.ispregst;
  targetObject.isrcm = invoice.isrcm;
  targetObject.isrcmsale = invoice.isrcmsale;
  targetObject.isrcmsale = invoice.isrcmsale;
  targetObject.isRecurring = invoice.isRecurring;
  targetObject.isroundup = invoice.isroundup;
  targetObject.issez = invoice.issez;
  targetObject.isexport = invoice.isexport;
  targetObject.isSystemGenerated = invoice.isSystemGenerated;
  targetObject.isUtilized = invoice.isUtilized;
  targetObject.loadingpackaging = invoice.loadingpackaging;
  targetObject.manualrefinv = invoice.manualrefinv;
  targetObject.month = invoice.month;
  targetObject.payment = invoice.payment;
  targetObject.paymentTransactions = invoice.paymentTransactions;
  targetObject.privileges = invoice.privileges;
  targetObject.purchasedate = invoice.purchasedate;
  targetObject.purchaseorder = invoice.purchaseorder;
  targetObject.referencedInvoice = invoice.referencedInvoice;
  targetObject.referenceinvnumber = invoice.referenceinvnumber;
  targetObject.referencingInvoices = invoice.referencingInvoices;
  targetObject.refinvoice = invoice.refinvoice;
  targetObject.salesthrecom = invoice.salesthrecom;
  targetObject.satype = invoice.satype;
  targetObject.sezinvoicetype = invoice.sezinvoicetype;
  targetObject.sgst = invoice.sgst;
  targetObject.shiptoaddress = invoice.shiptoaddress;
  targetObject.status = invoice.status;
  targetObject.supplier = invoice.supplier;
  targetObject.supplytype = invoice.supplytype;
  // targetObject.totaladvance = invoice.totaladvance;
  targetObject.totalamount = invoice.totalamount;
  targetObject.totalgst = invoice.totalgst;
  targetObject.totalinwords = invoice.totalinwords;
  targetObject.totaltaxable = invoice.totaltaxable;
  targetObject.totaltaxableinwords = invoice.totaltaxableinwords;
  targetObject.utgst = invoice.utgst;
  targetObject.year = invoice.year;

  let itemArray = [];
  for (let i = 0; i < invoice.invoiceitems.length; i++) {
    let item = createInvoiceItem(invoice.invoiceitems[i]);
    itemArray.push(item);
  }
  targetObject.invoiceitems = itemArray;
  return targetObject;
};

export const setImportInvoice = (targetObject, invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  if (!targetObject) {
    targetObject = {};
  }
  // targetObject.advanceAmountUsed = invoice.advanceAmountUsed;
  // targetObject.advanceGstUsed = invoice.advanceGstUsed;
  // targetObject.auditnotes = invoice.auditnotes;
  targetObject.bankaccount = invoice.bankaccount;
  targetObject.billtoaddress = invoice.billtoaddress;
  targetObject.bu = invoice.bu;
  // targetObject.capitalasset = invoice.capitalasset;
  targetObject.cdnreason = invoice.cdnreason;
  targetObject.cess = invoice.cess;
  targetObject.cesspercent = invoice.cesspercent;
  targetObject.cgst = invoice.cgst;
  targetObject.createdon = invoice.createdon;
  targetObject.currency = invoice.currency;
  targetObject.description = invoice.description;
  // targetObject.doctypes = invoice.doctypes;
  targetObject.electronicreference = invoice.electronicreference;
  targetObject.etin = invoice.etin;
  targetObject.exchangerate = invoice.exchangerate;
  targetObject.expinvoicetype = invoice.expinvoicetype;
  targetObject.freez = invoice.freez;
  targetObject.fright = invoice.fright;
  targetObject.gstdate = invoice.gstdate;
  targetObject.id = invoice.id;
  targetObject.igst = invoice.igst;
  targetObject.invnumber = invoice.invnumber;
  targetObject.invoicedate = invoice.invoicedate;
  // targetObject.invoiceDocuments = invoice.invoiceDocuments;
  targetObject.invoiceitems = invoice.invoiceitems;
  targetObject.invoicesource = invoice.invoicesource;
  targetObject.invoiceStatusList = invoice.invoiceStatusList;
  targetObject.invoicetype = invoice.invoicetype;
  // targetObject.isAsset = invoice.isAsset;
  targetObject.miscellaneous = invoice.miscellaneous;
  targetObject.isbillingshippingsame = invoice.isbillingshippingsame;
  targetObject.isimported = invoice.isimported;
  targetObject.ismanualrefinv = invoice.ismanualrefinv;
  targetObject.isnilrated = invoice.isnilrated;
  targetObject.ispregst = invoice.ispregst;
  targetObject.isrcm = invoice.isrcm;
  targetObject.isrcmsale = invoice.isrcmsale;
  targetObject.isrcmsale = invoice.isrcmsale;
  targetObject.isRecurring = invoice.isRecurring;
  targetObject.isroundup = invoice.isroundup;
  targetObject.issez = invoice.issez;
  targetObject.isSystemGenerated = invoice.isSystemGenerated;
  // targetObject.isUtilized = invoice.isUtilized;
  targetObject.loadingpackaging = invoice.loadingpackaging;
  targetObject.manualrefinv = invoice.manualrefinv;
  targetObject.month = invoice.month;
  targetObject.payment = invoice.payment;
  // targetObject.paymentTransactions = invoice.paymentTransactions;
  // targetObject.privileges = invoice.privileges;
  targetObject.purchasedate = invoice.purchasedate;
  targetObject.purchaseorder = invoice.purchaseorder;
  targetObject.referencedInvoice = invoice.referencedInvoice;
  targetObject.referenceinvnumber = invoice.referenceinvnumber;
  targetObject.referencingInvoices = invoice.referencingInvoices;
  targetObject.refinvoice = invoice.refinvoice;
  targetObject.salesthrecom = invoice.salesthrecom;
  targetObject.satype = invoice.satype;
  targetObject.sezinvoicetype = invoice.sezinvoicetype;
  targetObject.sgst = invoice.sgst;
  targetObject.shiptoaddress = invoice.shiptoaddress;
  targetObject.status = invoice.status;
  targetObject.supplier = invoice.supplier;
  targetObject.supplytype = invoice.supplytype;
  // targetObject.totaladvance = invoice.totaladvance;
  targetObject.totalamount = invoice.totalamount;
  targetObject.totalgst = invoice.totalgst;
  targetObject.totalinwords = invoice.totalinwords;
  targetObject.totaltaxable = invoice.totaltaxable;
  targetObject.totaltaxableinwords = invoice.totaltaxableinwords;
  targetObject.utgst = invoice.utgst;
  targetObject.year = invoice.year;

  let itemArray = [];
  for (let i = 0; i < invoice.invoiceitems.length; i++) {
    let item = createInvoiceItem(invoice.invoiceitems[i]);
    itemArray.push(item);
  }
  targetObject.invoiceitems = itemArray;
  return targetObject;
};

export const setInvoiceToState = (targetObject, invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  if (!targetObject) {
    return null;
  }
  let object = {
    advanceAmountUsed: invoice.advanceAmountUsed,
    advanceGstUsed: invoice.advanceGstUsed,
    // auditnotes: invoice.auditnotes,
    bankaccount: invoice.bankaccount,
    billtoaddress: invoice.billtoaddress,
    bu: invoice.bu,
    capitalasset: invoice.capitalasset,
    cdnreason: invoice.cdnreason,
    cess: invoice.cess,
    cesspercent: invoice.cesspercent,
    cgst: invoice.cgst,
    createdon: invoice.createdon,
    currency: invoice.currency,
    description: invoice.description,
    doctypes: invoice.doctypes,
    electronicreference: invoice.electronicreference,
    etin: invoice.etin,
    exchangerate: invoice.exchangerate,
    expinvoicetype: invoice.expinvoicetype,
    freez: invoice.freez,
    fright: invoice.fright,
    gstdate: invoice.gstdate,
    id: invoice.id,
    igst: invoice.igst,
    invnumber: invoice.invnumber,
    invoicedate: invoice.invoicedate,
    invoiceDocuments: invoice.invoiceDocuments,
    invoiceitems: invoice.invoiceitems,
    invoicesource: invoice.invoicesource,
    invoiceStatusList: invoice.invoiceStatusList,
    invoicetype: invoice.invoicetype,
    // isAsset: invoice.isAsset,
    miscellaneous: invoice.miscellaneous,

    isbillingshippingsame: invoice.isbillingshippingsame,
    isimported: invoice.isimported,
    ismanualrefinv: invoice.ismanualrefinv,
    isnilrated: invoice.isnilrated,
    ispregst: invoice.ispregst,
    isrcm: invoice.isrcm,
    isrcmsale: invoice.isrcmsale,
    isrcmsale: invoice.isrcmsale,
    isRecurring: invoice.isRecurring,
    isroundup: invoice.isroundup,
    issez: invoice.issez,
    isSystemGenerated: invoice.isSystemGenerated,
    isUtilized: invoice.isUtilized,
    loadingpackaging: invoice.loadingpackaging,
    manualrefinv: invoice.manualrefinv,
    month: invoice.month,
    payment: invoice.payment,
    paymentTransactions: invoice.paymentTransactions,
    privileges: invoice.privileges,
    purchasedate: invoice.purchasedate,
    purchaseorder: invoice.purchaseorder,
    referencedInvoice: invoice.referencedInvoice,
    referenceinvnumber: invoice.referenceinvnumber,
    referencingInvoices: invoice.referencingInvoices,
    refinvoice: invoice.refinvoice,
    salesthrecom: invoice.salesthrecom,
    satype: invoice.satype,
    sezinvoicetype: invoice.sezinvoicetype,
    sgst: invoice.sgst,
    shiptoaddress: invoice.shiptoaddress,
    status: invoice.status,
    supplier: invoice.supplier,
    supplytype: invoice.supplytype,
    // totaladvance: invoice.totaladvance,
    totalamount: invoice.totalamount,
    totalgst: invoice.totalgst,
    totalinwords: invoice.totalinwords,
    totaltaxable: invoice.totaltaxable,
    totaltaxableinwords: invoice.totaltaxableinwords,
    utgst: invoice.utgst,
    year: invoice.year,

    render: true,
  };
  return object;
};

export const getEmptyInvoice = () => {
  let emptyInvoice = {
    doctype: [],
    invoiceDocuments: [],
    advanceAmountUsed: 0.0,
    advanceGstUsed: 0.0,
    // auditnotes: [],
    bankaccount: null,
    billtoaddress: null,
    bu: null,
    capitalasset: false,
    cdnreason: null,
    cess: "",
    cesspercent: 0.0,
    cgst: 0.0,
    createdon: format(new Date(), "yyyy-MM-dd"),
    currency: null,
    description: "",
    doctypes: null,
    electronicreference: "",
    etin: "",
    exchangerate: 0.0,
    expinvoicetype: null,
    freez: false,
    fright: 0.0,
    gstdate: "",
    id: "",
    igst: 0.0,
    invnumber: "",
    invoicedate: "",
    invoiceDocuments: null,
    invoiceitems: [],
    invoicesource: { source: "USERCREATED" },
    invoiceStatusList: [],
    invoicetype: null,
    // isAsset: false,
    miscellaneous: 0.0,

    isbillingshippingsame: "No",
    isimported: false,
    ismanualrefinv: false,
    isnilrated: false,
    ispregst: false,
    isrcm: false,
    isrcmsale: false,
    isrcmsale: false,
    isRecurring: false,
    isroundup: false,
    issez: false,
    isSystemGenerated: false,
    isUtilized: false,
    loadingpackaging: "",
    manualrefinv: "",
    month: "",
    payment: null,
    paymentTransactions: [],
    privileges: [],
    purchasedate: null,
    purchaseorder: "",
    referencedInvoice: null,
    referenceinvnumber: "",
    referencingInvoices: null,
    refinvoice: null,
    salesthrecom: "",
    satype: null,
    sezinvoicetype: null,
    sgst: 0.0,
    shiptoaddress: null,
    status: null,
    supplier: null,
    supplytype: null,
    // totaladvance: 0.0,
    totalamount: 0.0,
    totalgst: 0.0,
    totalinwords: "",
    totaltaxable: 0.0,
    totaltaxableinwords: "",
    utgst: 0.0,
    year: "",
  };
  return emptyInvoice;
};
export const getPurchaseProducts = (inputProductList) => {
  let productList = [];
  if (isNull(inputProductList)) {
    return productList;
  }
  for (let i = 0; i < inputProductList.length; i++) {
    let product = inputProductList[i];
    if (
      product &&
      product.producttype &&
      (Utils.equalsIgnoreCase("Trading", product.producttype.type) ||
        Utils.equalsIgnoreCase("Purchase", product.producttype.type))
    ) {
      productList.push(product);
    } else {
    }
  }
  return productList;
};

export const getRCMSuppliers = (supplierList) => {
  let suppList = [];
  if (Utils.isNull(supplierList)) {
    return suppList;
  }

  for (let i = 0; i < supplierList.length; i++) {
    if (
      supplierList[i] &&
      Utils.equalsIgnoreCase(supplierList[i].isrcm, "YES")
    ) {
      suppList.push(supplierList[i]);
    }
  }
  return suppList;
};

export const getNonRCMSuppliers = (supplierList) => {
  let suppList = [];
  if (Utils.isNull(supplierList)) {
    return suppList;
  }
  for (let i = 0; i < supplierList.length; i++) {
    if (
      supplierList[i] &&
      !Utils.equalsIgnoreCase(supplierList[i].isrcm, "YES")
    ) {
      suppList.push(supplierList[i]);
    }
  }
  return suppList;
};

export default {
  getRCMSuppliers,
  getNonRCMSuppliers,
  getPurchaseProducts,
  createInvoiceItem,
  createEmptyInvoiceItem,
  setInvoiceItem,
  createInvoice,
  setInvoice,
  setImportInvoice,
  setInvoiceToState,
  getEmptyInvoice,
};
