import MaterialTable from "material-table";
import React, { Component } from "react";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
import BreadCrumbDetailsAction from "../../../../redux/breadCrumbDetails/BreadCrumbDetailsAction";
import appDataAction from "../../../../redux/appData/appDataAction";

export class CustomerList extends Component {
  customerMasterState = store.getState().applicationState.customerMasterState;
  customerMasterStateDetails = {};

  breadCrumb = [
    { pageid: "/master/", uiname: "Master", cssclass: "fa fa-book" },
    { pageid: "/cust", uiname: "Customer Master List" },
  ];
  title = "Customer Master";
  constructor(props) {
    super(props);
    let userMenuArray = Utils.getTopMenus();
    if (this.breadCrumb) {
      for (let i = 0; i < userMenuArray.length; i++) {
        for (let j = 0; this.breadCrumb && j < this.breadCrumb.length; j++) {
          if (userMenuArray[i].pageid === this.breadCrumb[j].pageid) {
            store.dispatch(appDataAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
      store.dispatch(
        BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb)
      );
    }
    this.tableIcons = UiUtils.getMaterialTableIcons(true);
    if (this.customerMasterState) {
      this.customerMasterStateDetails = Object.getOwnPropertyNames(
        this.customerMasterState
      );
    }
    if (this.customerMasterStateDetails.length > 0) {
      this.state = {
        showAddcustomer: false,
        customerList: [],
        privileges: [],
        invoiceId: this.customerMasterState.invoiceId,
        pageSize: this.customerMasterState.pageSize,
        initialPage: this.customerMasterState.initialPage,
        isPaging: false,
        render: false,
      };
    } else {
      this.state = {
        showAddcustomer: false,
        customerList: [],
        privileges: [],
        pageSize: 10,
        initialStep: 0,
        isPaging: false,
        render: false,
      };
    }
    this.getCustemerData();
  }

  getCustemerData() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/customerms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let privileges = output.data.privileges;
          let customerList = output.data.customerList;
          let isPaging = false;
          if (customerList.length > 10) {
            isPaging = true;
          }
          this.setState({
            render: true,
            isPaging: isPaging,
            customerList: customerList,
            privileges: privileges,
          });
        }
      }
    );
  }

  addCustomer = (e) => {
    this.props.history.push({
      pathname: "/customer",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };
  viewCustomer = (e, data) => {
    let customerId = data.id;
    let customerMasterState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ customerMasterState })
    );
    this.props.history.push({
      pathname: "/customerview",
      state: {
        customerId: customerId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };
  cancelView() {
    this.props.history.push({ pathname: "/master/" });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Customer",
        isFreeAction: true,
        textAlign: "left",
        onClick: (event) => this.addCustomer(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />

        <div
          style={{ background: "transparent", width: "100%", padding: "1em" }}
        >
          <MaterialTable
            icons={this.tableIcons}
            title="Customer List"
            localization={{
              body: {
                emptyDataSourceMessage: Utils.communizeTableText(),
              },
            }}
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },
              {
                title: "Name",
                field: "name",
                align: "left",
                cellStyle: {
                  width: "25%",
                },
              },
              {
                title: "GSTN",
                field: "gstn",
                align: "left",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "TradeName",
                field: "tradeName",
                align: "left",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Address",
                field: "address",
                align: "left",
                cellStyle: {
                  width: "40%",
                },
              },
            ]}
            data={this.state.customerList}
            options={{
              paging: this.state.isPaging,
              pageSize: this.state.pageSize,
              initialPage: this.state.initialPage,
              pageSizeOptions: Utils.materialTablePageSize(),
              sorting: true,
              toolbar: true,
              actionsColumnIndex: -1,
              headerStyle: {
                margin: 0,
                backgroundColor: "#1b7189",
                // border: "1px solid #e2dddd",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
            actions={actionList}
            onRowClick={(e, rowData) => this.viewCustomer(e, rowData)}
          />
        </div>
      </>
    );
  }
}
export default CustomerList;
