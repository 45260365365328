import React from "react";
import { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import PtsAlert from "../../ptsAlert/PtsAlert";
import Logo3 from "../../../../assets/images/logo3.png";
import "./VerifyOtp.css";
export class VerifyOtp extends Component {
  constructor(props) {
    super(props);
    let email = props.email;
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      email = props.history.location.state.email;
    }
    this.state = {
      name: "",
      email: email,
      referralCode: "",
      showPassword: false,
      showConfirmPassword: false,
      value1: "",
      value1Ref: React.createRef(),
      value2: "",
      value2Ref: React.createRef(),
      value3: "",
      value3Ref: React.createRef(),
      value4: "",
      value4Ref: React.createRef(),
      value5: "",
      value5Ref: React.createRef(),
      value6: "",
      value6Ref: React.createRef(),
    };
  }

  componentDidMount() {
    this.state.value1Ref.current.focus();
  }

  handleValueChange = async (e) => {
    const { name, value } = e.target;

    if (e.key === "Backspace") {
      return;
    }
    if (value.length > 1) {
      PtsAlert.error("Please enter a single digit");
      return;
    }

    await this.setState({ [name]: value });

    switch (name) {
      case "value1":
        this.value2Focus();
        this.value2Select();
        break;
      case "value2":
        this.value3Focus();
        this.value3Select();
        break;
      case "value3":
        this.value4Focus();
        this.value4Select();
        break;
      case "value4":
        this.value5Focus();
        this.value5Select();
        break;
      case "value5":
        this.value6Focus();
        this.value6Select();
        break;
      case "value6":
        this.VerifyOtpInBackend();
        break;
      default:
        break;
    }
  };

  handleKeyUp = (e) => {
    const { name } = e.target;

    if (e.key === "Enter") {
      this.VerifyOtpInBackend();
    } else if (e.key === "Backspace") {
      this.setState({ [name]: "" });

      switch (name) {
        case "value1":
          break;
        case "value2":
          this.setState({ value1: "" });
          this.value1Focus();
          break;
        case "value3":
          this.setState({ value2: "" });
          this.value2Focus();
          break;
        case "value4":
          this.setState({ value3: "" });
          this.value3Focus();
          break;
        case "value5":
          this.setState({ value4: "" });
          this.value4Focus();
          break;
        case "value6":
          this.setState({ value5: "" });
          this.value5Focus();
          break;
        default:
          break;
      }
    } else if (e.key === "ArrowLeft") {
      switch (name) {
        case "value1":
          break;
        case "value2":
          this.value1Focus();
          this.value1Select();
          break;
        case "value3":
          this.value2Focus();
          this.value2Select();
          break;
        case "value4":
          this.value3Focus();
          this.value3Select();
          break;
        case "value5":
          this.value4Focus();
          this.value4Select();
          break;
        case "value6":
          this.value5Focus();
          this.value5Select();
          break;
        default:
          break;
      }
    } else if (e.key === "ArrowRight") {
      switch (name) {
        case "value1":
          this.value2Focus();
          this.value2Select();
          break;
        case "value2":
          this.value3Focus();
          this.value3Select();
          break;
        case "value3":
          this.value4Focus();
          this.value4Select();
          break;
        case "value4":
          this.value5Focus();
          this.value5Select();
          break;
        case "value5":
          this.value6Focus();
          this.value6Select();
          break;
        case "value6":
          break;
        default:
          break;
      }
    }
  };

  value1Focus = () => {
    this.state.value1Ref.current.focus();
  };
  value2Focus = () => {
    this.state.value2Ref.current.focus();
  };
  value3Focus = () => {
    this.state.value3Ref.current.focus();
  };
  value4Focus = () => {
    this.state.value4Ref.current.focus();
  };
  value5Focus = () => {
    this.state.value5Ref.current.focus();
  };
  value6Focus = () => {
    this.state.value6Ref.current.focus();
  };

  value1Select = () => {
    this.state.value1Ref.current.select();
  };
  value2Select = () => {
    this.state.value2Ref.current.select();
  };
  value3Select = () => {
    this.state.value3Ref.current.select();
  };
  value4Select = () => {
    this.state.value4Ref.current.select();
  };
  value5Select = () => {
    this.state.value5Ref.current.select();
  };
  value6Select = () => {
    this.state.value6Ref.current.select();
  };

  VerifyOtpInBackend = () => {
    const { value1, value2, value3, value4, value5, value6 } = this.state;

    const otpValue = `${value1}${value2}${value3}${value4}${value5}${value6}`;

    if (otpValue.length !== 6) {
      return;
    }

    const postObject = {
      header: {},
      registration: {
        email: this.state.email,
        verificationcode: otpValue,
      },
    };

    FetchServerData.callPostService("/regms/verify", postObject)
      .then((response) => {
        let output = response;
        let verifyData = output.data.registration;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          const postObject = {
            header: {},
            registration: verifyData,
          };
          FetchServerData.callPostService("/regms/postVerify", postObject).then(
            (output) => {
              if (output.status === "SUCCESS") {
                PtsAlert.success(output.message);
                this.props.history.push({
                  pathname: "/login",
                  state: {
                    email: this.state.email,
                  },
                });
              }
            }
          );
        }
      })
      .catch((error) => {});
  };

  renderInputBox = (name, ref) => {
    return (
      <Col md={2} style={{ padding: "0rem 0rem 0rem 1rem" }}>
        <input
          type="number"
          ref={this.state[ref]}
          className="form-control otpFormControlInput"
          id={"otpFormControlInput-" + name}
          maxLength="1"
          name={name}
          value={this.state[name]}
          onKeyUp={this.handleKeyUp}
          onChange={this.handleValueChange}
          aria-describedby="defaultFormControlHelp"
        />
      </Col>
    );
  };

  backToSignin() {
    this.props.history.push({
      pathname: "/login",
    });
  }

  render() {
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div
              className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground "
              style={{ background: "#1a7088", textAlign: "center" }}
            >
              <div className="mx-auto">
                {/* <div className="logoBackground"> */}
                <img
                  src={Logo3}
                  className="img-fluid logoImage"
                  // id="logoStyle"
                  alt=""
                  data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                  data-app-light-img="illustrations/girl-with-laptop-light.png"
                />
                {/* </div> */}
                <h2 className="logoHeding">
                  <b>Taxonomy Hub</b>
                </h2>
                {/* <span className="logoText">
                  One Stop Solution for Business Financials along with GST
                  Compliance
                </span> */}
              </div>
            </div>
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
              <div>
                <p className="heding">Sign Up</p>
                <p className="heding-1">Verification Code</p>
                <Row style={{ margin: "0.6em" }}>
                  {this.renderInputBox("value1", "value1Ref")}
                  {this.renderInputBox("value2", "value2Ref")}
                  {this.renderInputBox("value3", "value3Ref")}
                  {this.renderInputBox("value4", "value4Ref")}
                  {this.renderInputBox("value5", "value5Ref")}
                  {this.renderInputBox("value6", "value6Ref")}
                </Row>
                <div className="p-4">
                  <button
                    className="submit"
                    size="small"
                    style={{ textTransform: "capitalize" }}
                    onClick={this.VerifyOtpInBackend.bind(this)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VerifyOtp;
