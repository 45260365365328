import { Card } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import CancelButton from "../../buttons/CancelButton";
import RemoveIconButton from "../../buttons/RemoveIconButton";
import SaveButton from "../../buttons/SaveButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import "./GstRB.scss";
export class GSTRBDetails extends Component {
  stateListArray = store.getState().staticData.stateList;
  header = store.getState().header.header;
  oldBreadCrumb = "";
  title = "GSTR-3B";
  constructor(props) {
    super(props);

    // let action = props.action;
    let gstr3bData = props.gstr3bData;
    if (
      !gstr3bData &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      // action = props.history.location.state.action;
      gstr3bData = props.history.location.state.gstr3bData;
      this.state = gstr3bData;
      this.state.render = true;
      // this.setGstrbDetails(gstr3bData);
      this.breadCrumb = props.history.location.state.breadCrumb;
      this.oldBreadCrumb = this.breadCrumb;
    }
  }

  // handleYearChange(e) {
  //   this.setState({ year: e.target.value });
  // }

  // handleGstr1CustomerChange(e) {
  //   this.setState({ customerType: e.target.value });
  // }

  // handleGstr1InvoiceChange(e) {
  //   this.setState({ invoiceType: e.target.value });
  // }

  // handleMonthlyGSTR1Change(e) {
  //   this.setState({ month: e.target.value });
  // }

  handleltfeeIntrdetailIamtChange(e) {
    this.setState({ ltfeeIntrdetailIamt: e.target.value });
  }

  handleItfeeIntrdetailCamtChange(e) {
    this.setState({ ltfeeIntrdetailCamt: e.target.value });
  }

  handleltfeeIntrdetailSamtChange(e) {
    this.setState({ ltfeeIntrdetailSamt: e.target.value });
  }

  handlelltfeeIntrdetailCsamtChange(e) {
    this.setState({ ltfeeIntrdetailCsamt: e.target.value });
  }

  handleItfeelatefeeCamtChange(e) {
    this.setState({ ItfeelatefeeCamt: e.target.value });
  }

  handleltfeelatefeeSamtChange(e) {
    this.setState({ ltfeelatefeeSamt: e.target.value });
  }

  handleInwardOtherInterChange(e) {
    this.setState({ inwardOtherInter: e.target.value });
  }

  handleInwardOtherIntraChange(e) {
    this.setState({ inwardOtherIntra: e.target.value });
  }

  handleInwardNongstInterChange(e) {
    this.setState({ inwardNongstInter: e.target.value });
  }

  handleInwardNongstIntraChange(e) {
    this.setState({ inwardNongstIntra: e.target.value });
  }

  handleSupdetIamtChange(e) {
    this.setState({ supdetTxval: e.target.value });
  }

  handleSupdetTxvalChange(e) {
    this.setState({ supdetIamt: e.target.value });
  }

  handleSupdetCamtChange(e) {
    this.setState({ supdetCamt: e.target.value });
  }

  handleSupdetSamtChange(e) {
    this.setState({ supdetSamt: e.target.value });
  }

  handleSupdetCsamtChange(e) {
    this.setState({ supdetCsamt: e.target.value });
  }

  handleSupzeroTxvalChange(e) {
    this.setState({ supzeroTxval: e.target.value });
  }

  handleSupzeroIamtChange(e) {
    this.setState({ supzeroIamt: e.target.value });
  }

  handleSupzeroCsamtChange(e) {
    this.setState({ supzeroCsamt: e.target.value });
  }

  handleSupNilexmpTxvalChange(e) {
    this.setState({ supNilexmpTxval: e.target.value });
  }

  handleSuprevTxvalChange(e) {
    this.setState({ suprevTxval: e.target.value });
  }

  handleSuprevIamtChange(e) {
    this.setState({ suprevIamt: e.target.value });
  }

  handleSuprevCamtChange(e) {
    this.setState({ suprevCamt: e.target.value });
  }

  handleSuprevSamtChange(e) {
    this.setState({ suprevSamt: e.target.value });
  }

  handleSuprevCsamtChange(e) {
    this.setState({ suprevCsamt: e.target.value });
  }
  handleSupnongstTxvalChange(e) {
    this.setState({ supnongstTxval: e.target.value });
  }

  handleImportGoodIntvalChange(e) {
    this.setState({ importGoodIntval: e.target.value });
  }
  handleImportGoodCessChange(e) {
    this.setState({ importGoodCess: e.target.value });
  }
  handleImportServiceIntvalChange(e) {
    this.setState({ importServiceIntval: e.target.value });
  }

  handleImportServiceCessChange(e) {
    this.setState({ importServiceCess: e.target.value });
  }
  handleInwardRevIntvalChange(e) {
    this.setState({ inwardRevIntval: e.target.value });
  }
  handleInwardRevCessChange(e) {
    this.setState({ inwardRevCess: e.target.value });
  }
  handleInwardRevCamtChange(e) {
    this.setState({ inwardRevCamt: e.target.value });
  }

  handleInwardRevSamtChange(e) {
    this.setState({ inwardRevSamt: e.target.value });
  }
  handleInwardSuppISDIntvalChange(e) {
    this.setState({ inwardSuppISDIntval: e.target.value });
  }

  handleInwardSuppISDCamtChange(e) {
    this.setState({ inwardSuppISDCamt: e.target.value });
  }
  handleInwardSuppISDSamtChange(e) {
    this.setState({ inwardSuppISDSamt: e.target.value });
  }

  handleInwardSuppISDCessChange(e) {
    this.setState({ inwardSuppISDCess: e.target.value });
  }

  handleOtherItcIntvalChange(e) {
    this.setState({ otherItcIntval: e.target.value });
  }

  handleOtherItcCamtChange(e) {
    this.setState({ otherItcCamt: e.target.value });
  }
  handleOtherItcSamtChange(e) {
    this.setState({ otherItcSamt: e.target.value });
  }

  handleOtherItcCessChange(e) {
    this.setState({ otherItcCess: e.target.value });
  }

  handleItcReversedSgstIntvalChange(e) {
    this.setState({ itcReversedSgstIntval: e.target.value });
  }

  handleItcReversedSgstCamtChange(e) {
    this.setState({ itcReversedSgstCamt: e.target.value });
  }

  handleItcReversedSgstSamtChange(e) {
    this.setState({ itcReversedSgstSamt: e.target.value });
  }
  handleItcReversedSgstCessChange(e) {
    this.setState({ itcReversedSgstCess: e.target.value });
  }

  handleItcReversedOtherIntvalChange(e) {
    this.setState({ itcReversedOtherIntval: e.target.value });
  }

  handleItcReversedOtherCamtChange(e) {
    this.setState({ itcReversedOtherCamt: e.target.value });
  }

  handleItcReversedOtherSamtChange(e) {
    this.setState({ itcReversedOtherSamt: e.target.value });
  }
  handleItcReversedOtherCessChangee(e) {
    this.setState({ itcReversedOtherCess: e.target.value });
  }

  handleNetItcReversedIntvalChange(e) {
    this.setState({ netItcReversedIntval: e.target.value });
  }

  handleNetItcReversedCamtChange(e) {
    this.setState({ netItcReversedCamt: e.target.value });
  }

  handleNetItcReversedSamtChange(e) {
    this.setState({ netItcReversedSamt: e.target.value });
  }
  handleNetItcReversedCessChange(e) {
    this.setState({ netItcReversedCess: e.target.value });
  }

  handleInterStateSuptaxChange = (i) => (e) => {
    // const { interStateSuptax } = this.state;
    // interStateSuptax[e.target.name] = e.target.value;
    this.setState({ interStateSuptax: e.target.value });
    const { name, value } = e.target;
    const interStateSuptaxlist = [...this.state.interStateSuptaxlist];
    interStateSuptaxlist[i] = {
      [name]: value,
    };
    this.setState({
      interStateSuptaxlist,
    });
  };

  setGstrbDetails = (gstrbData) => {
    let supnongstxval = gstrbData.sup_details.osup_nongst;
    let suppNongstvalue;
    if (supnongstxval === null) {
      suppNongstvalue = 0;
    }
    this.setState({
      createdon: gstrbData.createdon,
      status: gstrbData.status,
      id: gstrbData.id,
      returnPeriod: gstrbData.ret_period,
      createdDate: gstrbData.createdon,
      sup_detailsIamt: gstrbData.sup_details.osup_det.iamt,
      sup_detailsCamt: gstrbData.sup_details.osup_det.camt,
      sup_detailsSamt: gstrbData.sup_details.osup_det.samt,
      sup_detailsCsamt: gstrbData.sup_details.osup_det.csamt,
      inter_supIamt: gstrbData.sup_details.total.iamt,
      inter_supTxval: gstrbData.sup_details.total.txval,
      itc_elgIamt: gstrbData.itc_elg.netitc.iamt,
      itc_elgCamt: gstrbData.itc_elg.netitc.camt,
      itc_elgSamt: gstrbData.itc_elg.netitc.samt,
      itc_elgCsamt: gstrbData.itc_elg.netitc.csamt,
      inward_supInter: gstrbData.inward_sup.total.inter,
      inward_supIntra: gstrbData.inward_sup.total.intra,
      intr_ltfeeIamt: gstrbData.intr_ltfee.total.iamt,
      intr_ltfeeCamt: gstrbData.intr_ltfee.total.camt,
      intr_ltfeeSamt: gstrbData.intr_ltfee.total.samt,
      intr_ltfeeCsamt: gstrbData.intr_ltfee.total.csamt,
      ltfeeIntrdetailIamt: gstrbData.intr_ltfee.intr_details.iamt,
      ltfeeIntrdetailCamt: gstrbData.intr_ltfee.intr_details.camt,
      ltfeeIntrdetailSamt: gstrbData.intr_ltfee.intr_details.samt,
      ltfeeIntrdetailCsamt: gstrbData.intr_ltfee.intr_details.csamt,
      ltfeelatefeeIamt: gstrbData.intr_ltfee.latefee_details.iamt,
      ItfeelatefeeCamt: gstrbData.intr_ltfee.latefee_details.camt,
      ltfeelatefeeSamt: gstrbData.intr_ltfee.latefee_details.samt,
      ltfeelatefeeCsamt: gstrbData.intr_ltfee.latefee_details.csamt,
      inwardOtherInter: gstrbData.inward_sup.others.inter,
      inwardOtherIntra: gstrbData.inward_sup.others.intra,
      inwardNongstInter: gstrbData.inward_sup.nongst.inter,
      inwardNongstIntra: gstrbData.inward_sup.nongst.intra,
      supdetTxval: gstrbData.sup_details.osup_det.txval,
      supdetIamt: gstrbData.sup_details.osup_det.iamt,
      supdetCamt: gstrbData.sup_details.osup_det.camt,
      supdetSamt: gstrbData.sup_details.osup_det.samt,
      supdetCsamt: gstrbData.sup_details.osup_det.csamt,
      supzeroTxval: gstrbData.sup_details.osup_zero.txval,
      supzeroIamt: gstrbData.sup_details.osup_zero.iamt,
      supzeroCamt: gstrbData.sup_details.osup_zero.camt,
      supzeroSamt: gstrbData.sup_details.osup_zero.samt,
      supzeroCsamt: gstrbData.sup_details.osup_zero.csamt,
      supNilexmpTxval: gstrbData.sup_details.osup_nil_exmp.txval,
      suprevTxval: gstrbData.sup_details.isup_rev.txval,
      suprevIamt: gstrbData.sup_details.isup_rev.iamt,
      suprevCamt: gstrbData.sup_details.isup_rev.camt,
      suprevSamt: gstrbData.sup_details.isup_rev.samt,
      suprevCsamt: gstrbData.sup_details.isup_rev.csamt,
      supnongstTxval: suppNongstvalue,
      importGoodIntval: gstrbData.itc_elg.itcavailable.importgoods.iamt,
      importGoodCess: gstrbData.itc_elg.itcavailable.importgoods.csamt,
      importServiceIntval: gstrbData.itc_elg.itcavailable.importservice.iamt,
      importServiceCess: gstrbData.itc_elg.itcavailable.importservice.csamt,
      inwardRevIntval: gstrbData.itc_elg.itcavailable.rcmitc.iamt,
      inwardRevCess: gstrbData.itc_elg.itcavailable.rcmitc.csamt,
      inwardRevCamt: gstrbData.itc_elg.itcavailable.rcmitc.camt,
      inwardRevSamt: gstrbData.itc_elg.itcavailable.rcmitc.samt,
      inwardSuppISDIntval: gstrbData.itc_elg.itcavailable.isditc.iamt,
      inwardSuppISDCamt: gstrbData.itc_elg.itcavailable.isditc.camt,
      inwardSuppISDSamt: gstrbData.itc_elg.itcavailable.isditc.samt,
      inwardSuppISDCess: gstrbData.itc_elg.itcavailable.isditc.csamt,
      otherItcIntval: gstrbData.itc_elg.itcavailable.allitc.iamt,
      otherItcCamt: gstrbData.itc_elg.itcavailable.allitc.camt,
      otherItcSamt: gstrbData.itc_elg.itcavailable.allitc.samt,
      otherItcCess: gstrbData.itc_elg.itcavailable.allitc.csamt,
      netItcReversedIntval: gstrbData.itc_elg.netitc.iamt,
      netItcReversedCamt: gstrbData.itc_elg.netitc.camt,
      netItcReversedSamt: gstrbData.itc_elg.netitc.samt,
      netItcReversedCess: gstrbData.itc_elg.netitc.csamt,
      render: true,
    });
  };

  refreshGSTR3() {
    const postObject = {
      gstr3b: {
        id: this.state.id,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/refresh", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let refreshGSTR3 = output.data;
        } else {
        }
      }
    );
  }

  saveOutwardReg = () => {
    let suppNongstvalue;
    if (this.state.supnongstTxval === 0) {
      suppNongstvalue = null;
    }
    const postObject = {
      supply: {
        id: this.state.id,
        osup_det: {
          txval: this.state.supdetTxval,
          iamt: this.state.supdetIamt,
          camt: this.state.supdetCamt,
          samt: this.state.supdetSamt,
          csamt: this.state.supdetCsamt,
        },
        osup_zero: {
          txval: this.state.supzeroTxval,
          iamt: this.state.supzeroIamt,
          csamt: this.state.supzeroCsamt,
        },
        osup_nil_exmp: {
          txval: this.state.supNilexmpTxval,
        },
        isup_rev: {
          txval: this.state.suprevTxval,
          iamt: this.state.suprevIamt,
          camt: this.state.suprevCamt,
          samt: this.state.suprevSamt,
          csamt: this.state.suprevCsamt,
        },
        osup_nongst: suppNongstvalue,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/savesalesdata", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let interlatefeeData = output.data;
          this.backToGSTR3BPage();
        } else {
        }
      }
    );
  };

  createIntersupp() {
    let list = [];
    for (let i = 0; i < this.state.interStateSuptaxlist.length; i++) {
      list[i] = {
        pos: this.state.interStateSuptaxlist[i].pos.num,
        iamt: this.state.interStateSuptaxlist[i].iamt,
        txval: this.state.interStateSuptaxlist[i].txval,
      };
    }
    const postObject = {
      interstate: {
        id: this.state.id,
        interstatesuptaxlist: list,
      },

      header: this.header,
    };
    FetchServerData.callPostService(
      "/gstr3b/saveinterstatedata",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        this.backToGSTR3BPage();
      } else {
      }
    });
  }

  createEligibleitcdata() {
    const postObject = {
      itc: {
        id: this.state.id,
        itcavailable: {
          importgoods: {
            iamt: this.state.importGoodIntval,
            csamt: this.state.importGoodCess,
          },
          importservice: {
            iamt: this.state.importServiceIntval,
            csamt: this.state.importServiceCess,
          },
          rcmitc: {
            iamt: this.state.inwardRevIntval,
            camt: this.state.inwardRevCamt,
            samt: this.state.inwardRevSamt,
            csamt: this.state.inwardRevCess,
          },
          isditc: {
            iamt: this.state.inwardSuppISDIntval,
            camt: this.state.inwardSuppISDCamt,
            samt: this.state.inwardSuppISDSamt,
            csamt: this.state.inwardSuppISDCess,
          },
          allitc: {
            iamt: this.state.otherItcIntval,
            camt: this.state.otherItcCamt,
            samt: this.state.otherItcSamt,
            csamt: this.state.otherItcCess,
          },
        },
        itcreversed: {
          ruleitc: {
            iamt: this.state.itcReversedSgstIntval,
            camt: this.state.itcReversedSgstCamt,
            samt: this.state.itcReversedSgstSamt,
            csamt: this.state.itcReversedSgstCess,
          },
          otheritc: {
            iamt: this.state.itcReversedOtherIntval,
            camt: this.state.itcReversedOtherCamt,
            samt: this.state.itcReversedOtherSamt,
            csamt: this.state.itcReversedSgstCess,
          },
        },
        netitc: {
          iamt: this.state.netItcReversedIntval,
          camt: this.state.netItcReversedCamt,
          samt: this.state.netItcReversedSamt,
          csamt: this.state.netItcReversedCess,
        },
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/saveitc", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let EligibleitcData = output.data;
          // this.props.history.push({
          //   pathname: '/gstr3view',
          //   state: { gstrbId: EligibleitcData.id, action: 'view' },
          // });
          this.backToGSTR3BPage();
        } else {
        }
      }
    );
  }

  createExemptData = () => {
    const postObject = {
      inwadsupply: {
        id: this.state.id,
        others: {
          inter: this.state.inwardOtherInter,
          intra: this.state.inwardOtherIntra,
        },
        nongst: {
          inter: this.state.inwardNongstInter,
          intra: this.state.inwardNongstIntra,
        },
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/saveinward", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.backToGSTR3BPage();
        } else {
        }
      }
    );
  };

  createinterlatefee = () => {
    const postObject = {
      interestlatefee: {
        id: this.state.id,
        intr_details: {
          iamt: this.state.ltfeeIntrdetailIamt,
          camt: this.state.ltfeeIntrdetailCamt,
          samt: this.state.ltfeeIntrdetailSamt,
          csamt: this.state.ltfeeIntrdetailCsamt,
        },
        latefee_details: {
          camt: this.state.ItfeelatefeeCamt,
          samt: this.state.ltfeelatefeeSamt,
        },
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/saveintlatefee", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.backToGSTR3BPage();
        } else {
        }
      }
    );
  };

  createGSTR3Backend = () => {
    let retPeriod = this.state.month + this.state.year;
    const postObject = {
      gstr3b: {
        ret_period: retPeriod,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let userData = output.data;
          this.props.history.push({
            pathname: "/gstr3list/",
            //state: { userId: userData.id,action:'view' },
          });
        } else {
        }
      }
    );
  };

  // goToOutwardSupplyDetails() {
  //   this.props.history.push({
  //     pathname: "/outward_unreg_details/view/",
  //     state: { gstrbId: this.state.id, action: "/outrcmsupdetailsView" },
  //   });
  // }
  getOutwardSupply() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToOutwardSupplyDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>
            3.1 Tax on outward and reverse charge inward supplies
          </h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Integrated Tax</h6>
              <span>&#x20b9;{this.state.sup_detailsIamt}</span>
            </Col>
            <Col md={6}>
              <h6>Central Tax</h6>
              <span>&#x20b9;{this.state.sup_detailsCamt}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col md={6}>
              <h6>State/UT Tax</h6>
              <span>&#x20b9;{this.state.sup_detailsSamt}</span>
            </Col>
            <Col md={6}>
              <h6>CESS</h6>
              <span>&#x20b9;{this.state.sup_detailsCsamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  goToInterstateSupplyDetails() {
    this.props.history.push({
      pathname: "/outward_unreg_details/view/",
      state: { gstrbId: this.state.id, action: "outwardsupunregView" },
    });
  }
  getInterStateSupply() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToInterstateSupplyDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>3.2 Inter-state supplies</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Taxable Value</h6>
              <span>&#x20b9;{this.state.inter_supTxval}</span>
            </Col>

            <Col md={6}>
              <h6>Integrated Value</h6>
              <span>&#x20b9;{this.state.inter_supIamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  goToITCDetails() {
    this.props.history.push({
      pathname: "/eligible_itc/view/",
      state: { gstrbId: this.state.id, action: "eligibleitcView" },
    });
  }
  getITCDetails() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToInterstateSupplyDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>4. Eligible ITC</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Integrated Tax</h6>
              <span>&#x20b9;{this.state.itc_elgIamt}</span>
            </Col>
            <Col md={6}>
              <h6>Central Tax</h6>
              <span>&#x20b9;{this.state.itc_elgCamt}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col md={6}>
              <h6>State/UT Tax</h6>
              <span>&#x20b9;{this.state.itc_elgSamt}</span>
            </Col>
            <Col md={6}>
              <h6>CESS</h6>
              <span>&#x20b9;{this.state.itc_elgCsamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  goToOutwardSupplyDetails() {
    this.props.history.push({
      pathname: "/xmpt_non_gst/view/",
      state: { gstrbId: this.state.id, action: "inwardSupplyView" },
    });
  }
  getOutwardSupplyDetails() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToInterstateSupplyDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>
            5. Exempt,nil and Non GST inward Supplies
          </h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Inter-state Supplies</h6>
              <span>&#x20b9;{this.state.inward_supInter}</span>
            </Col>
            <Col md={6}>
              <h6>Intra-state Supplies</h6>
              <span>&#x20b9;{this.state.inward_supIntra}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  backToGSTR3BPage() {
    this.props.history.push({
      pathname: "/gstr3bview",
      state: {
        gstrbId: this.state.id,
        action: "view",
        breadCrumb: this.oldBreadCrumb,
      },
    });
  }
  goToLateFeesDetails() {
    this.props.history.push({
      pathname: "/intrlatefee/view/",
      state: { gstrbId: this.state.id, action: "intrlatefeeView" },
    });
  }
  getLateFees() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToLateFeesDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>5.1 Interest and Late fee</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Integrated Tax</h6>
              <span>&#x20b9;{this.state.intr_ltfeeIamt}</span>
            </Col>
            <Col md={6}>
              <h6>Central Tax</h6>
              <span>&#x20b9;{this.state.intr_ltfeeCamt}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col md={6}>
              <h6>State/UT Tax</h6>
              <span>&#x20b9;{this.state.intr_ltfeeSamt}</span>
            </Col>
            <Col md={6}>
              <h6>CESS</h6>
              <span>&#x20b9;{this.state.intr_ltfeeCsamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.menuWithBreadScrumRef = React.createRef();

    if (this.state.action === "lateFeesView") {
      return this.intrltefee();
    }
    if (this.state.action === "inwardSupplyView") {
      return this.inwardSupply();
    }
    if (this.state.action === "outwardSupplyView") {
      return this.outwardSupplyDetails();
    }
    if (this.state.action === "eligibleitcView") {
      return this.eligibleitc();
    }
    if (this.state.action === "interStateSupplyView") {
      return this.interStateSupply();
    } else {
      return <div />;
    }
  }

  intrltefee() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Interest and Late Fee"}
          history={this.props.history}
          name="Interest and Late Fee"
        />
        <Card style={{ marginTop: "1em" }}>
          <CardHeader style={{ padding: 0 }}>
            <Row
              md={12}
              style={{
                backgroundColor: "#1B7189",
                color: "#fff",
              }}
            >
              <Col style={{ textAlign: "center" }}>
                <h4 style={{ padding: "0.5em", color: "white" }}>
                  5.1 Interest and Late Fee
                </h4>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} style={{ padding: "0.5em" }}>
              <Col md={12}>
                <Table style={{}}>
                  <TableHead
                    style={{ background: "#f1eeeb", fontWeight: "600" }}
                  >
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          fontWeight: "600",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        Integrated Tax(&#x20b9;)
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        Central Tax(&#x20b9;)
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        State/UT Tax(&#x20b9;)
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        CESS(&#x20b9;)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Interest
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="ltfeeIntrdetailIamt"
                          value={this.state.ltfeeIntrdetailIamt}
                          name="ltfeeIntrdetailIamt"
                          autoComplete="ltfeeIntrdetailIamt"
                          type="text"
                          onChange={this.handleltfeeIntrdetailIamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailIamt} */}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="ltfeeIntrdetailCamt"
                          value={this.state.ltfeeIntrdetailCamt}
                          name="ltfeeIntrdetailCamt"
                          autoComplete="ltfeeIntrdetailCamt"
                          type="text"
                          onChange={this.handleItfeeIntrdetailCamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailCamt} */}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="ltfeeIntrdetailSamt"
                          value={this.state.ltfeeIntrdetailSamt}
                          name="ltfeeIntrdetailSamt"
                          autoComplete="ltfeeIntrdetailSamt"
                          type="text"
                          onChange={this.handleltfeeIntrdetailSamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="ltfeeIntrdetailCsamt"
                          value={this.state.ltfeeIntrdetailCsamt}
                          name="ltfeeIntrdetailCsamt"
                          autoComplete="ltfeeIntrdetailCsamt"
                          type="text"
                          onChange={this.handlelltfeeIntrdetailCsamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailCsamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        AdvaLate Feence
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          disabled
                          id="ItfeelatefeeCamt"
                          value={this.state.ItfeelatefeeCamt}
                          name="ItfeelatefeeCamt"
                          autoComplete="ItfeelatefeeCamt"
                          type="text"
                          onChange={this.handleItfeelatefeeCamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailCamt} */}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          disabled
                          id="ltfeelatefeeSamt"
                          value={this.state.ltfeelatefeeSamt}
                          name="ltfeelatefeeSamt"
                          autoComplete="ltfeelatefeeSamt"
                          type="text"
                          onChange={this.handleltfeelatefeeSamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        {" "}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SaveButton onClick={(e) => this.createinterlatefee()} />
              <CancelButton onClick={(e) => this.backToGSTR3BPage()} />
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
  inwardSupply() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Inward Supplies"}
          history={this.props.history}
          name="Inward Supplies"
        />
        <Card style={{ marginTop: "1em" }}>
          <CardHeader style={{ padding: 0 }}>
            <Row
              md={12}
              style={{
                backgroundColor: "#1B7189",
                color: "#fff",
              }}
            >
              <Col style={{ textAlign: "center" }}>
                <h4 style={{ padding: "0.5em", color: "white" }}>
                  5. Value of exempt,nil-rated and non-GST inward supplies
                </h4>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} style={{ padding: "0.5em" }}>
              <Col md={12}>
                <Table style={{}}>
                  <TableHead style={{ background: "#f1eeeb" }}>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <strong>Nature of Supplies</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          textAlign: "right",
                        }}
                      >
                        <strong>Inter-state Supplies(&#x20b9;)</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          textAlign: "right",
                        }}
                      >
                        <strong> Intra-state Supplies(&#x20b9;)</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        From a supplier composition scheme,Exempt and Nil Rated
                        Supply
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardOtherInter"
                          value={this.state.inwardOtherInter}
                          name="inwardOtherInter"
                          autoComplete="inwardOtherInter"
                          type="text"
                          onChange={this.handleInwardOtherInterChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailIamt} */}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardOtherIntra"
                          value={this.state.inwardOtherIntra}
                          name="inwardOtherIntra"
                          autoComplete="inwardOtherIntra"
                          type="text"
                          onChange={this.handleInwardOtherIntraChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Non GST supply
                      </TableCell>

                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardNongstInter"
                          value={this.state.inwardNongstInter}
                          name="inwardNongstInter"
                          autoComplete="inwardNongstInter"
                          type="text"
                          onChange={this.handleInwardNongstInterChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailCamt} */}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardNongstIntra"
                          value={this.state.inwardNongstIntra}
                          name="inwardNongstIntra"
                          autoComplete="inwardNongstIntra"
                          type="text"
                          onChange={this.handleInwardNongstIntraChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SaveButton onClick={(e) => this.createExemptData()} />
              <CancelButton onClick={(e) => this.backToGSTR3BPage()} />
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }

  eligibleitc() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Eligible ITC"}
          history={this.props.history}
          name="Eligible ITC"
        />
        <Card style={{ marginTop: "1em" }}>
          <CardHeader style={{ padding: 0 }}>
            <Row
              md={12}
              style={{
                backgroundColor: "#1B7189",
                color: "#fff",
              }}
            >
              <Col style={{ textAlign: "center" }}>
                <h4 style={{ padding: "0.5em", color: "white" }}>
                  4. Eligible ITC
                </h4>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} style={{ padding: "0.5em" }}>
              <Col md={12}>
                <Table style={{}}>
                  <TableHead style={{ background: "#f1eeeb" }}>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          fontWeight: "600",
                        }}
                      >
                        Details Supplies
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          textAlign: "right",
                          fontWeight: "600",
                        }}
                      >
                        Integrated Tax(&#x20b9;)
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          textAlign: "right",
                          fontWeight: "600",
                        }}
                      >
                        Central Tax(&#x20b9;)
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          textAlign: "right",
                          fontWeight: "600",
                        }}
                      >
                        State/UT Tax(&#x20b9;)
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",

                          textAlign: "right",
                          fontWeight: "600",
                        }}
                      >
                        CESS(&#x20b9;)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <strong>
                          (A) ITC Available (whether in full or part)
                        </strong>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          (1) Import of Goods
                        </span>
                      </TableCell>

                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="importGoodIntval"
                          value={this.state.importGoodIntval}
                          name="importGoodIntval"
                          autoComplete="importGoodIntval"
                          type="text"
                          onChange={this.handleImportGoodIntvalChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="importGoodCess"
                          value={this.state.importGoodCess}
                          name="importGoodCess"
                          autoComplete="importGoodCess"
                          type="text"
                          onChange={this.handleImportGoodCessChange.bind(this)}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          (2) Import of Services
                        </span>
                      </TableCell>

                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="importServiceIntval"
                          value={this.state.importServiceIntval}
                          name="importServiceIntval"
                          autoComplete="importServiceIntval"
                          type="text"
                          onChange={this.handleImportServiceIntvalChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="importServiceCess"
                          value={this.state.importServiceCess}
                          name="importServiceCess"
                          autoComplete="importServiceCess"
                          type="text"
                          onChange={this.handleImportServiceCessChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          (3) Inward Supplies Liable to reverse Charge (Other
                          than 1 & 2 above)
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardRevIntval"
                          value={this.state.inwardRevIntval}
                          name="inwardRevIntval"
                          autoComplete="inwardRevIntval"
                          type="text"
                          onChange={this.handleInwardRevIntvalChange.bind(this)}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardRevCamt"
                          value={this.state.inwardRevCamt}
                          name="inwardRevCamt"
                          autoComplete="inwardRevCamt"
                          type="text"
                          onChange={this.handleInwardRevCamtChange.bind(this)}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardRevSamt"
                          value={this.state.inwardRevSamt}
                          name="inwardRevSamt"
                          autoComplete="inwardRevSamt"
                          type="text"
                          onChange={this.handleInwardRevSamtChange.bind(this)}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardRevCess"
                          value={this.state.inwardRevCess}
                          name="inwardRevCess"
                          autoComplete="inwardRevCess"
                          type="text"
                          onChange={this.handleInwardRevCessChange.bind(this)}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          (4) Inward Supplies From ISD
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardSuppISDIntval"
                          value={this.state.inwardSuppISDIntval}
                          name="inwardSuppISDIntval"
                          autoComplete="inwardSuppISDIntval"
                          type="text"
                          onChange={this.handleInwardSuppISDIntvalChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardSuppISDCamt"
                          value={this.state.inwardSuppISDCamt}
                          name="inwardSuppISDCamt"
                          autoComplete="inwardSuppISDCamt"
                          type="text"
                          onChange={this.handleInwardSuppISDCamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardSuppISDSamt"
                          value={this.state.inwardSuppISDSamt}
                          name="inwardSuppISDSamt"
                          autoComplete="inwardSuppISDSamt"
                          type="text"
                          onChange={this.handleInwardSuppISDSamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="inwardSuppISDCess"
                          value={this.state.inwardSuppISDCess}
                          name="inwardSuppISDCess"
                          autoComplete="inwardSuppISDCess"
                          type="text"
                          onChange={this.handleInwardSuppISDCessChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          (5) All Other ITC{" "}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="otherItcIntval"
                          value={this.state.otherItcIntval}
                          name="otherItcIntval"
                          autoComplete="otherItcIntval"
                          type="text"
                          onChange={this.handleOtherItcIntvalChange.bind(this)}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="otherItcCamt"
                          value={this.state.otherItcCamt}
                          name="otherItcCamt"
                          autoComplete="otherItcCamt"
                          type="text"
                          onChange={this.handleOtherItcCamtChange.bind(this)}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="otherItcSamt"
                          value={this.state.otherItcSamt}
                          name="otherItcSamt"
                          autoComplete="otherItcSamt"
                          type="text"
                          onChange={this.handleOtherItcSamtChange.bind(this)}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="otherItcCess"
                          value={this.state.otherItcCess}
                          name="otherItcCess"
                          autoComplete="otherItcCess"
                          type="text"
                          onChange={this.handleOtherItcCessChange.bind(this)}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <strong>(B) ITC Reversed</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      ></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          (1)as per Rule 42 & 43 of CGST / SGST rules
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedSgstIntval"
                          value={this.state.itcReversedSgstIntval}
                          name="itcReversedSgstIntval"
                          autoComplete="itcReversedSgstIntval"
                          type="text"
                          onChange={this.handleItcReversedSgstIntvalChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedSgstCamt"
                          value={this.state.itcReversedSgstCamt}
                          name="itcReversedSgstCamt"
                          autoComplete="itcReversedSgstCamt"
                          type="text"
                          onChange={this.handleItcReversedSgstCamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedSgstSamt"
                          value={this.state.itcReversedSgstSamt}
                          name="itcReversedSgstSamt"
                          autoComplete="itcReversedSgstSamt"
                          type="text"
                          onChange={this.handleItcReversedSgstSamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedSgstCess"
                          value={this.state.itcReversedSgstCess}
                          name="itcReversedSgstCess"
                          autoComplete="itcReversedSgstCess"
                          type="text"
                          onChange={this.handleItcReversedSgstCessChange.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>(2)Others </span>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedOtherIntval"
                          value={this.state.itcReversedOtherIntval}
                          name="itcReversedOtherIntval"
                          autoComplete="itcReversedOtherIntval"
                          type="text"
                          onChange={this.handleItcReversedOtherIntvalChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedOtherCamt"
                          value={this.state.itcReversedOtherCamt}
                          name="itcReversedOtherCamt"
                          autoComplete="itcReversedOtherCamt"
                          type="text"
                          onChange={this.handleItcReversedOtherCamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedOtherSamt"
                          value={this.state.itcReversedOtherSamt}
                          name="itcReversedOtherSamt"
                          autoComplete="itcReversedOtherSamt"
                          type="text"
                          onChange={this.handleItcReversedOtherSamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          id="itcReversedOtherCess"
                          value={this.state.itcReversedOtherCess}
                          name="itcReversedOtherCess"
                          autoComplete="itcReversedOtherCess"
                          type="text"
                          onChange={this.handleItcReversedOtherCessChangee.bind(
                            this
                          )}
                          className="textField"
                        />
                        {/* {this.state.ltfeeIntrdetailSamt} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <strong>(C)Net ITC - Available (A)-(B)</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          disabled
                          id="netItcReversedIntval"
                          value={this.state.netItcReversedIntval}
                          name="netItcReversedIntval"
                          autoComplete="netItcReversedIntval"
                          type="text"
                          onChange={this.handleNetItcReversedIntvalChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          disabled
                          id="netItcReversedCamt"
                          value={this.state.netItcReversedCamt}
                          name="netItcReversedCamt"
                          autoComplete="netItcReversedCamt"
                          type="text"
                          onChange={this.handleNetItcReversedCamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          disabled
                          id="netItcReversedSamt"
                          value={this.state.netItcReversedSamt}
                          name="netItcReversedSamt"
                          autoComplete="netItcReversedSamt"
                          type="text"
                          onChange={this.handleNetItcReversedSamtChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "0.5px solid #d4d0d0",
                          fontFamily: "sans-serif",
                        }}
                      >
                        {" "}
                        <TextField
                          inputProps={{ min: 0, style: { textAlign: "right" } }}
                          fullWidth
                          disabled
                          id="netItcReversedCess"
                          value={this.state.netItcReversedCess}
                          name="netItcReversedCess"
                          autoComplete="netItcReversedCess"
                          type="text"
                          onChange={this.handleNetItcReversedCessChange.bind(
                            this
                          )}
                          className="textField"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SaveButton onClick={(e) => this.createEligibleitcdata()} />
              <CancelButton onClick={(e) => this.backToGSTR3BPage()} />
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
  interStateSupply() {
    const itemRow = this.state.interStateSuptaxlist.map((item, i) => {
      return (
        <TableRow>
          <TableCell
            style={{
              width: "5%",
              border: "0.5px solid #d4d0d0",
              fontFamily: "sans-serif",
            }}
          >
            {i + 1}
          </TableCell>
          <TableCell
            style={{
              width: "35%",
              border: "0.5px solid #d4d0d0",
              fontFamily: "sans-serif",
            }}
          >
            <Autocomplete
              fullWidth
              ListboxProps={{
                className: "myCustomList"
              }}
              id="pos"
              options={this.stateListArray ? this.stateListArray : []}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              onChange={(event, value) => {
                this.handleChange(event, value, i);
              }}
              renderOption={(option, state) => (
                <label
                  style={{
                    padding: 0,
                    margin: 0,
                    wordWrap: "break-word",
                    color: "#000",
                  }}
                >
                  {option.name}
                </label>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  value={item.pos ? item.pos.name : ""}
                  label=""
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </TableCell>

          <TableCell
            style={{
              width: "20%",
              border: "0.5px solid #d4d0d0",
              fontFamily: "sans-serif",
            }}
          >
            <TextField
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              required
              value={item.txval ? item.txval : ""}
              onChange={(event) => {
                this.handleTaxValueChange(event, i);
              }}
              name="txval"
              autoComplete="txval"
              className="textField"
            />
          </TableCell>
          <TableCell
            style={{
              width: "20%",
              border: "0.5px solid #d4d0d0",
              fontFamily: "sans-serif",
            }}
          >
            <TextField
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              required
              value={item.iamt || ""}
              onChange={(event) => {
                this.handleIntegratedTaxValueChange(event, i);
              }}
              name="iamt"
              autoComplete="iamt"
              className="textField"
            />
          </TableCell>

          <TableCell
            style={{
              width: "20%",
              border: "0.5px solid #d4d0d0",
              fontFamily: "sans-serif",
            }}
          >
            <RemoveIconButton onClick={(e) => this.removeItemRow(i)} />
            {/* <em class="fa fa-times-circle" style={{ color: '#1b7189' }} onClick={(e) => this.removeItemRow(i)}></em> */}
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Interest State Supplies"}
          history={this.props.history}
          name="Interest State Supplies"
        />
        <Card style={{ marginTop: "1em" }}>
          <ValidatorForm
            // style={{ width: '100%', color: '#000',  marginTop: '1em' }}
            ref="form"
            onSubmit={this.createIntersupp.bind(this)}
          >
            <CardHeader style={{ padding: 0 }}>
              <Row
                md={12}
                style={{
                  backgroundColor: "#1B7189",
                  color: "#fff",
                }}
              >
                <Col style={{ textAlign: "center" }}>
                  <h4 style={{ padding: "0.5em", color: "white" }}>
                    3.2 of the Supplies shown in 3.1(a),details of Inter-state
                    supplies made to unregistered person,composition taxable
                    person and UIN holder
                  </h4>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ padding: "0.5em" }}>
                <Col md={12}>
                  <Table style={{}}>
                    <TableHead style={{ background: "#f1eeeb" }}>
                      <TableRow>
                        <TableCell
                          style={{
                            width: "5%",
                            border: "0.5px solid #d4d0d0",
                            fontFamily: "sans-serif",

                            fontWeight: "600",
                          }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          style={{
                            width: "35%",
                            border: "0.5px solid #d4d0d0",
                            fontFamily: "sans-serif",

                            fontWeight: "600",
                          }}
                        >
                          Place Of Supply(State/UT)
                        </TableCell>
                        <TableCell
                          style={{
                            width: "25%",
                            border: "0.5px solid #d4d0d0",
                            fontFamily: "sans-serif",

                            textAlign: "right",
                            fontWeight: "600",
                          }}
                        >
                          Total Taxable Value(&#x20b9;)
                        </TableCell>
                        <TableCell
                          style={{
                            width: "25%",
                            border: "0.5px solid #d4d0d0",
                            fontFamily: "sans-serif",

                            textAlign: "right",
                            fontWeight: "600",
                          }}
                        >
                          Amount of Integrated Tax(&#x20b9;)
                        </TableCell>
                        <TableCell
                          style={{
                            width: "10%",
                            border: "0.5px solid #d4d0d0",
                            fontFamily: "sans-serif",

                            textAlign: "right",
                            fontWeight: "600",
                          }}
                        >
                          <AddIconButton
                            onClick={(e) => this.addGstr3bUnregsupp()}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{itemRow}</TableBody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
            <CardFooter style={{ width: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <SaveButton type="submit" />
                <CancelButton onClick={(e) => this.backToGSTR3BPage()} />
              </div>
            </CardFooter>
          </ValidatorForm>
        </Card>
      </div>
    );
  }
  outwardSupplyDetails() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Outward Supplies"}
          history={this.props.history}
          name="Outward Supplies"
        />
        <Card style={{ marginTop: "1em" }}>
          <CardHeader style={{ padding: 0 }}>
            <Row
              md={12}
              style={{
                backgroundColor: "#1B7189",
                color: "#fff",
              }}
            >
              <Col style={{ textAlign: "center" }}>
                <h4 style={{ padding: "0.5em", color: "white" }}>
                  3.1 Details of Outward Supplies and Inward Supplies Liable to
                  Reverse Charge
                </h4>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} style={{ padding: "0.5em" }}>
              <Table style={{}}>
                <TableHead style={{ background: "#f1eeeb" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                        fontWeight: "600",
                      }}
                    >
                      Nature of Supplies
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                        fontWeight: "600",
                      }}
                    >
                      Total Taxable Value(&#x20b9;)
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                        fontWeight: "600",
                      }}
                    >
                      Integrated Tax(&#x20b9;)
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                        fontWeight: "600",
                      }}
                    >
                      Central Tax(&#x20b9;)
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                        fontWeight: "600",
                      }}
                    >
                      State/UT Tax(&#x20b9;)
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                        fontWeight: "600",
                      }}
                    >
                      CESS(&#x20b9;)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      (a) Outward taxable supplies(other than zero rated,nil
                      rated and exempted)
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supdetTxval"
                        value={this.state.supdetTxval}
                        name="supdetTxval"
                        autoComplete="supdetTxval"
                        type="text"
                        onChange={this.handleSupdetTxvalChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supdetIamt"
                        value={this.state.supdetIamt}
                        name="supdetIamt"
                        autoComplete="supdetIamt"
                        type="text"
                        onChange={this.handleSupdetIamtChange.bind(this)}
                        className="textField"
                      />
                      {/* {this.state.ltfeeIntrdetailIamt} */}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supdetCamt"
                        value={this.state.supdetCamt}
                        name="supdetCamt"
                        autoComplete="supdetCamt"
                        type="text"
                        onChange={this.handleSupdetCamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supdetSamt"
                        value={this.state.supdetSamt}
                        name="supdetSamt"
                        autoComplete="supdetSamt"
                        type="text"
                        onChange={this.handleSupdetSamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supdetCsamt"
                        value={this.state.supdetCsamt}
                        name="supdetCsamt"
                        autoComplete="supdetCsamt"
                        type="text"
                        onChange={this.handleSupdetCsamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      (b) Outward taxable supplies(zero rated)
                    </TableCell>

                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supzeroTxval"
                        value={this.state.supzeroTxval}
                        name="supzeroTxval"
                        autoComplete="supzeroTxval"
                        type="text"
                        onChange={this.handleSupzeroTxvalChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supzeroIamt"
                        value={this.state.supzeroIamt}
                        name="supzeroIamt"
                        autoComplete="supzeroIamt"
                        type="text"
                        onChange={this.handleSupzeroIamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",

                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supzeroCsamt"
                        value={this.state.supzeroCsamt}
                        name="supzeroCsamt"
                        autoComplete="supzeroCsamt"
                        type="text"
                        onChange={this.handleSupzeroCsamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      (c) Other outward supplies (Nil rated,exepmted)
                    </TableCell>

                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supNilexmpTxval"
                        value={this.state.supNilexmpTxval}
                        name="supNilexmpTxval"
                        autoComplete="supNilexmpTxval"
                        type="text"
                        onChange={this.handleSupNilexmpTxvalChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      (d) inward supplies ( liable to reverse charge)
                    </TableCell>

                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="suprevTxval"
                        value={this.state.suprevTxval}
                        name="suprevTxval"
                        autoComplete="suprevTxval"
                        type="text"
                        onChange={this.handleSuprevTxvalChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="suprevIamt"
                        value={this.state.suprevIamt}
                        name="suprevIamt"
                        autoComplete="suprevIamt"
                        type="text"
                        onChange={this.handleSuprevIamtChange.bind(this)}
                        className="textField"
                      />
                      {/* {this.state.ltfeeIntrdetailIamt} */}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="suprevCamt"
                        value={this.state.suprevCamt}
                        name="suprevCamt"
                        autoComplete="suprevCamt"
                        type="text"
                        onChange={this.handleSuprevCamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="suprevSamt"
                        value={this.state.suprevSamt}
                        name="suprevSamt"
                        autoComplete="suprevSamt"
                        type="text"
                        onChange={this.handleSuprevSamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="suprevCsamt"
                        value={this.state.suprevCsamt}
                        name="suprevCsamt"
                        autoComplete="suprevCsamt"
                        type="text"
                        onChange={this.handleSuprevCsamtChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "25%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      (e)Non-GST outward supplies
                    </TableCell>

                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        fullWidth
                        id="supnongstTxval"
                        value={this.state.supnongstTxval}
                        name="supnongstTxval"
                        autoComplete="supnongstTxval"
                        type="text"
                        onChange={this.handleSupnongstTxvalChange.bind(this)}
                        className="textField"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        width: "15%",
                        border: "0.5px solid #d4d0d0",
                        fontFamily: "sans-serif",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SaveButton
                type="submit"
                onClick={this.saveOutwardReg.bind(this)}
              />
              <CancelButton onClick={(e) => this.backToGSTR3BPage()} />
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
  addGstr3bUnregsupp() {
    this.state.interStateSuptaxlist.push(this.state.interStateSuptaxObj);
    this.setState({
      interStateSuptaxlist: this.state.interStateSuptaxlist,
    });
  }

  removeItemRow(i) {
    this.state.interStateSuptaxlist.splice(i, 1);
    this.setState({
      interStateSuptaxlist: this.state.interStateSuptaxlist,
    });
  }

  handleChange(event, value, i) {
    let interStateSuptaxlist = this.state.interStateSuptaxlist;
    if (!interStateSuptaxlist[i]) {
      interStateSuptaxlist[i] = {};
    }
    interStateSuptaxlist[i].pos = value;

    this.setState({ interStateSuptaxlist: interStateSuptaxlist });
  }

  handleTaxValueChange(event, i) {
    let interStateSuptaxlist = this.state.interStateSuptaxlist;
    if (!interStateSuptaxlist[i]) {
      interStateSuptaxlist[i] = {};
    }
    interStateSuptaxlist[i] = {
      ...interStateSuptaxlist[i],
      txval: event.target.value,
    };
    this.setState({ interStateSuptaxlist: interStateSuptaxlist });
  }
  handleIntegratedTaxValueChange(event, i) {
    let interStateSuptaxlist = this.state.interStateSuptaxlist;
    if (!interStateSuptaxlist[i]) {
      interStateSuptaxlist[i] = {};
    }
    interStateSuptaxlist[i] = {
      ...interStateSuptaxlist[i],
      iamt: event.target.value,
    };
    this.setState({ interStateSuptaxlist: interStateSuptaxlist });
  }
}
export default GSTRBDetails;
