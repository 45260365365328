import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { Component } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import EditIconButton from "../buttons/EditIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import SalesInvoiceNumberRow from "./NumberFormatRow";
import "./SalesInvoiceNumberFormat.scss";

export class SalesInvoiceNumberFormat extends Component {
  documentFormatRowRefList = [];

  constructor(props) {
    super(props);
    this.state = {
      documentFormatList: [],
      suxfixTypeList: [],
      sepratorList: [],
      render: false,
    };
    this.getSalesDocumentFormatList();
  }

  getSalesDocumentFormatList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService(
      "/docformatms/getAllSales",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let salesFormat = output.data;
        this.setState({
          suxfixTypeList: salesFormat.suxfixTypeList,
          sepratorList: salesFormat.sepratorList,
          documentFormatList: salesFormat.documentFormatList,
          render: true,
        });
      } else {
      }
    });
  }

  saveSalesInvoiceNumberFormat() {
    let docNumberFormatList = [];
    this.documentFormatRowRefList.forEach((item, index) => {
      let currentItem = item.current.state;
      if (currentItem) {
        let formatRowObject = {
          id: currentItem.id,
          prefix: currentItem.prefix,
          prefixseprator: currentItem.prefixseprator,
          suffixseprator: currentItem.suffixseprator,
          resetifsuffixchange: currentItem.resetifsuffixchange,
          startingnumber: currentItem.startingnumber,
          sufixtype: currentItem.sufixtype,
          documenttype: currentItem.documenttype,
        };
        docNumberFormatList.push(formatRowObject);
      }
    });

    const header = store.getState().header.header;
    const postObject = {
      header: header,
      docNumberFormatList: docNumberFormatList,
    };
    FetchServerData.callPostService("/docformatms/updateAll", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);

          let salesFormat = output.data;
          this.setState({
            suxfixTypeList: salesFormat.suxfixTypeList,
            sepratorList: salesFormat.sepratorList,
            documentFormatList: salesFormat.documentFormatList,
            render: true,
            action: "view",
          });
        } else {
          PtsAlert.error(output.message);

        }
      }
    );
  }
  editSalesInvoiceNumberFormat() {
    for (let i = 0; i < this.documentFormatRowRefList.length; i++) {
      if (
        this.documentFormatRowRefList[i] &&
        this.documentFormatRowRefList[i].current
      ) {
        this.documentFormatRowRefList[i].current.setState({ action: "edit" });
      }
    }
    this.setState({ action: "edit" });
  }

  cancelEdit() {
    for (let i = 0; i < this.documentFormatRowRefList.length; i++) {
      if (
        this.documentFormatRowRefList[i] &&
        this.documentFormatRowRefList[i].current
      ) {
        this.documentFormatRowRefList[i].current.setState({ action: "view" });
      }
    }
    this.setState({ action: "view" });
  }
  render() {
    if (this.state.action === "edit") {
      return this.editSalesInvoiceFormat();
    } else {
      return this.viewSalesInvoiceFormat();
    }
  }
  editSalesInvoiceFormat() {
    let numFormatList = [];
    this.documentFormatRowRefList = [];
    this.state.documentFormatList.forEach((item, index) => {
      let ref = React.createRef();
      this.documentFormatRowRefList[index] = ref;
      numFormatList.push(
        <SalesInvoiceNumberRow
          ref={this.documentFormatRowRefList[index]}
          data={item}
          sepratorList={this.state.sepratorList}
          suxfixTypeList={this.state.suxfixTypeList}
          action="edit"
        />
      );
    });

    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col md={12} style={{ margin: "auto" }}>
              <span>
                <strong>Invoice Number Format</strong>
              </span>
            </Col>
          </Row>
        </CardHeader>
        <CardBody md={12} style={{ overflowX: "auto" }}>
          <TableContainer component={Paper} style={{ marginTop: "1em" }}>
            <Table aria-label="a dense table" style={{}}>
              <TableHead>
                <TableRow style={{ background: "#878788" }}>
                  <TableCell
                    style={{
                      color: "#fff",
                      border: "0.5px solid #dedddd",
                    }}
                  >
                    Document Type
                  </TableCell>
                  <TableCell
                    style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                  >
                    Prefix
                  </TableCell>
                  <TableCell
                    style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                  >
                    Separator
                  </TableCell>
                  <TableCell
                    style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                  >
                    Suffix
                  </TableCell>
                  <TableCell
                    style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                  >
                    Separator
                  </TableCell>
                  <TableCell
                    style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                  >
                    Start Number
                  </TableCell>
                  <TableCell
                    style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                  >
                    Reset Number
                  </TableCell>
                  <TableCell
                    style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                  >
                    Number Preview
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{numFormatList}</TableBody>
            </Table>
          </TableContainer>
        </CardBody>
        <CardFooter>
          <div style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<SaveIcon />}
              onClick={this.saveSalesInvoiceNumberFormat.bind(this)}
            >
              Update
            </Button>

            <Button
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<CloseIcon />}
              onClick={(e) => this.cancelEdit()}
            >
              Cancel
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
  viewSalesInvoiceFormat() {
    if (this.state.render === false) {
      return <div />;
    }
    let numFormatList = [];
    this.documentFormatRowRefList = [];
    this.state.documentFormatList.forEach((item, index) => {
      let ref = React.createRef();
      this.documentFormatRowRefList[index] = ref;
      numFormatList.push(
        <SalesInvoiceNumberRow
          ref={this.documentFormatRowRefList[index]}
          data={item}
          sepratorList={this.state.sepratorList}
          suxfixTypeList={this.state.suxfixTypeList}
          action="view"
        />
      );
    });

    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col md={6} style={{ margin: "auto" }}>
              <span>
                <strong>Invoice Number Format</strong>
              </span>
            </Col>
            <Col md={6} style={{ marginTop: "0.5em" }}>
              <div style={{ float: "right" }}>
                <EditIconButton
                  onClick={this.editSalesInvoiceNumberFormat.bind(this)}
                  caption="Edit Number Format"
                />
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody md={12} style={{ overflowX: "auto" }}>
          {/* <TableContainer component={Paper} style={{ padding: "1em" }}> */}
          <Table aria-label="a dense table" style={{ margin: "1em" }}>
            <TableHead>
              <TableRow style={{ background: "#878788" }}>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Document Type
                </TableCell>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Prefix
                </TableCell>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Separator
                </TableCell>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Suffix
                </TableCell>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Separator
                </TableCell>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Start Number
                </TableCell>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Reset Number
                </TableCell>
                <TableCell
                  style={{ color: "#fff", border: "0.5px solid #dedddd" }}
                >
                  Number Preview
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{numFormatList}</TableBody>
          </Table>
          {/* </TableContainer> */}
        </CardBody>
      </Card>
    );
  }
}

export default SalesInvoiceNumberFormat;
