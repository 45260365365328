import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import AddIconButton from "../../buttons/AddIconButton";

export class GstR1List extends Component {
  gstr1StatusList = [
    {
      id: 0,
      status: "All",
      description: "All",
    },
    {
      id: 1,
      status: "Created",
      description: "Created",
    },
    // {
    //   id: 2,
    //   status: "Updated",
    //   description: "Updated",
    // },
    { id: 2, status: "Saved", description: "Saved" },
    { id: 3, status: "Submitted", description: "Submitted" },
    { id: 4, status: "Filed", description: "Filed" },
  ];
  // gstr1StatusList = store.getState().appData.gstr1StatusList;
  breadCrumb = [
    { pageid: "/filing", uiname: "Filing", cssclass: "fa fa-files-o" },
    { pageid: "/gstr1list", uiname: "GSTR-1 List" },
  ];
  title = "GSTR-1 List";
  constructor(props) {
    super(props);
    // this.gstr1StatusList.unshift({ id: 0, status: "ALL", description: "All" });
    for (let i = 0; i < this.gstr1StatusList.length; i++) {
      if (
        this.gstr1StatusList[i] &&
        Utils.equalsIgnoreCase(this.gstr1StatusList[i].status, "Deleted")
      ) {
        this.gstr1StatusList.splice(i, 1);
      }
    }
    // if (this.props.location && this.props.location.pathname) {
    //   let menu = Utils.getMenu(this.props.location.pathname);
    //   if (menu && menu.uiname && menu.uiname.length > 0) {
    //     this.title = menu.uiname;
    //   }
    //   this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    // }
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      value: 0,
      gstr1List: [],
      fp: "",
      createdDate: "",
      status: "",
      index: 0,
      id: "",
      render: false,
    };
    this.getGstR1ListData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getGstR1ListData() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/gstr1/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let GSTR1Data = output.data;
          //let  GSTR1Data1 = output.data[0].status.status;
          // let GSTR1Data1 = output.data.fp;
          this.setState({
            gstr1List: GSTR1Data,
            render: true,
            //     id:GSTR1Data.id,
            //     fp:GSTR1Data.fp,
            // createdDate:GSTR1Data.fp,
            // status:GSTR1Data.status.status
          });
        } else {
        }
      }
    );
  }

  addGstr1 = (e) => {
    this.props.history.push({
      pathname: "/gstr1create",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };
  toGstr1Details = (e, data) => {
    //localStorage.setItem('customerId', JSON.stringify(customerId));
    this.props.history.push({
      pathname: "/gstr1view",
      state: {
        gstr1Id: data.id,
        fperiod: data.fp,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };
  getTablesWithStatus(status) {
    let filteredData = this.state.gstr1List.filter(function (e) {
      return Utils.equalsIgnoreCase(e.status.status, status);
    });
    if (Utils.equalsIgnoreCase(status, "All")) {
      filteredData = this.state.gstr1List;
    }
    let actionList = [];
    // if (Utils.hasPrivilege('CREATE', this.state.privileges)) {
    actionList.push({
      icon: () => (
        <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
      ),
      tooltip: "Add GSTR-1",
      isFreeAction: true,
      onClick: (event) => this.addGstr1(),
    });
    // } else if (Utils.hasPrivilege('READ', this.state.privileges)) {
    // }
    return (
      <Row md={12} style={{ padding: "1px" }}>
        <div style={{ width: "100%" }}>
          <MaterialTable
            icons={this.tableIcons}
            localization={{
              body: {
                emptyDataSourceMessage: Utils.communizeTableText(),
              },
            }}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },
              {
                title: "Created On",
                render: (rowData) =>
                  Utils.getUtcToLocalDateTime(rowData.createdon),
                customSort: (a, b) => {
                  return (
                    new Date(a.createdon).getTime() -
                    new Date(b.createdon).getTime()
                  );
                },
                align: "left",
                width: "20%",
              },
              {
                title: "Financial Period",
                field: "financialPeriod",
                align: "left",
                width: "20%",
              },
              {
                title: "Status",
                render: (rowData) =>
                  rowData.status ? rowData.status?.description : "",
                align: "left",
                width: "20%",
              },
            ]}
            data={filteredData}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 10,
              paging: filteredData.length > 10 ? filteredData : "",
              pageSizeOptions: Utils.materialTablePageSize(),
              headerStyle: {
                backgroundColor: "#1b7189",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: {},
            }}
            style={{
              boder: "1px solid black",
            }}
            actions={actionList}
            onRowClick={(e, rowData) => this.toGstr1Details(e, rowData)}
          />
        </div>
      </Row>
    );
  }
  tabSelected() { }
  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setGstr1Tab(index));
    this.setState({ selectedTabIndex: index });
  }
  render() {
    if (this.state.render === false) {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
      );
    }

    const tabList = this.gstr1StatusList.map((gstr1Status, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(gstr1Status, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{gstr1Status.description}</span>
        </Tab>
      );
    });

    const tabPanelList = this.gstr1StatusList.map((tab, keyIndex) => {
      return (
        <TabPanel key={keyIndex}>
          {this.getTablesWithStatus(tab.status)}
        </TabPanel>
      );
    });

    let selectedIndex = store.getState().appData.gstr1SelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }
    // const classes = useStyles();
    // const { classes } = this.props;
    // const { value } = this.state;
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row md={12} style={{ marginTop: "2em" }}>
          <Tabs
            onSelect={this.tabSelected.bind(this)}
            selectedIndex={selectedIndex}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList style={{ color: "#1b7189", outline: "none", margin: "0" }}>
              {tabList}
            </TabList>
            {tabPanelList}
          </Tabs>
        </Row>
      </>
    );
  }
}
GstR1List.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default GstR1List;
