import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Box from "@mui/material/Box";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import FetchServerData from "../../../provider/FetchServerData";
import { TextValidator } from "react-material-ui-form-validator";
//const loader = Loaders();
import Utils from "../../../provider/Utils";
import registrationDataAction from "../../../redux/registrationData/registrationDataAction";
import store from "../../../redux/store";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./PersonalRegistrationDetails.scss";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PasswordRequirementsTooltip from "../passwordRequirementsTooltip/passwordRequirementsTooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import debounce from "lodash.debounce";
import { NavLink } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Checkbox from "@material-ui/core/Checkbox";
import logo from "../../../assets/images/logo3.png";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";

// const styles = (theme) => ({
//   field: {
//     marginLeft: theme.spacing.unit,
//     marginRight: theme.spacing.unit,
//     height: "30px !important",
//   },
// });

export class PersonalRegistrationDetails extends Component {
  header = store.getState().header.header;
  registrationInfo = store.getState().registrationInfo.registrationInfo;
  salutationList = [
    { id: 1, name: "Mr" },
    { id: 2, name: "Mrs" },
    { id: 3, name: "Miss" },
  ];
  genderList = [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ];
  stateListArray = [];
  constructor(props) {
    super(props);
    // let errorMsg = "";
    let salutation = { id: 1, name: "Mr" };
    let personalinfo =
      this.registrationInfo && this.registrationInfo.personalinfo
        ? JSON.parse(this.registrationInfo.personalinfo)
        : {};
    if (personalinfo && personalinfo.salutation) {
      for (let i = 0; i < this.salutationList.length; i++) {
        if (
          Utils.equalsIgnoreCase(
            this.salutationList[i].name,
            personalinfo.salutation
          )
        ) {
          salutation = this.salutationList[i];
        }
      }
    }
    this.state = {
      showSuccessAlert: false,
      showFailAlert: false,
      value: 0,
      name: "",
      errorMessage: "",
      errorMessageColor: "#000",
      fname:
        personalinfo && personalinfo.firstName ? personalinfo.firstName : "",
      mname:
        personalinfo && personalinfo.middleName ? personalinfo.middleName : "",
      lname: personalinfo && personalinfo.lastName ? personalinfo.lastName : "",
      email: personalinfo && personalinfo.email ? personalinfo.email : "",
      phone: personalinfo && personalinfo.mobile ? personalinfo.mobile : "",
      userName: personalinfo && personalinfo.uid ? personalinfo.uid : "",
      panNo: personalinfo && personalinfo.panNo ? personalinfo.panNo : "",
      salutation: salutation,
      address: personalinfo ? personalinfo.addressvo : "",
      password: "",
      confirmPassword: "",
      designation: personalinfo ? personalinfo.designation : "",
      showPassword: false,
      showConfirmPassword: false,
      passwordHelperText: "",
      confirmPasswordHelperText: "",
      error: {
        one: true,
        two: true,
        three: true,
        four: true,
        five: true,
      },
      passwordFocussed: false,
      activeStep: true,
      activeStep1: false,
      isDisabled: true,
      completed: {},
      newCompleted: "",
      termsConditionModal: false,
      privacyPolicyModal: false,
      countryList: [],
      country: null,
      state: null,
    };
  }

  componentDidMount() {
    const postObject = {
      header: {},
    };
    FetchServerData.callPostServiceWithoutHeader(
      "/staticms/getList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let countryListData = output.data;
        let countryListArry = countryListData.countryList;
        let defaultCountry =
          countryListArry[
            countryListArry.findIndex(
              (i) => i.code.toLowerCase() === "ind".toLowerCase()
            )
          ];
        this.setState({
          render: true,
          countryList: countryListArry,
          country: defaultCountry,
        });
        this.handleCountryChange(defaultCountry);
      } else {
      }
    });
  }
  // componentDidMount() {
  //   const postObject = {
  //     header: {
  //       appclient: 'W',
  //       userid: '',
  //       sessionid: '',
  //       tid: null,
  //       bu: null,
  //       createdDate: '2021-01-25',
  //       timezone: 'Asia/Calcutta',
  //       timezoneoffset: -330,
  //     },
  //   };
  //   FetchServerData.callPostServiceWithoutHeader('/staticms/getall', postObject).then(output => {
  //     if (output.status === 'SUCCESS') {
  //       let countryListData = output.data;
  //       let countryListArry = countryListData.countryList;
  //       this.setState({
  //         countryList: countryListArry,
  //       });
  //     } else {
  //     }
  //   });
  // }

  handleGstnOnBlur(e) {
    let gstn = this.state.gstn;
    if (gstn.length > 0) {
      if (null === gstn || gstn?.length <= 0) {
        document.getElementById("gstn").focus();
        PtsAlert.error("Enter GSTN");
        return false;
      } else if (gstn?.length < 15) {
        document.getElementById("gstn").focus();
        PtsAlert.error("GSTN Must be 15 Characters long");
        return false;
      } else {
        let gstnPattern =
          "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
        let patternArray = gstn?.match(gstnPattern);
        if (patternArray === null) {
          PtsAlert.error(
            "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
          );
          return false;
        } else {
          let panNo = gstn?.substr(2, 10);
          this.setState({ pan: panNo });
        }
      }
    }
  }

  getGstn() {
    if (!this.state.gstn) {
      PtsAlert.error("Enter GSTN");
      return;
    }
    const postObject = {
      gstin: this.state.gstn,
      header: {
        sessionid: "a1234v500G909u2332",
        email: "taxonomyhub@prototsolutions.com",
        tid: 1,
      },
    };
    FetchServerData.callPostServiceWithoutHeader(
      "/publicapi/publicSearchTxPayer",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let gstnDetails = output.data;
        PtsAlert.success(output.message);
        this.setState({
          name: gstnDetails.legalname,
          state: gstnDetails.address?.state,
          address: gstnDetails.address,
          line: gstnDetails.address?.line1,
          pincode: gstnDetails.address?.pin,
          city: gstnDetails.address?.city,
        });
      } else {
      }
    });
  }
  // handleGstnChange(e) {
  //   let val = e.target.value.toUpperCase();
  //   this.setState({ gstn: val });
  // }
  handleGstnChange(e) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    this.setState({ gstn: input.value.toUpperCase() }, () =>
      input.setSelectionRange(start, end)
    );
    // this.setState({ gstn: e.target.value });
  }
  handleRegistrationStatusChange(e) {
    this.setState({ regiStatus: e.target.value });
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }
  handlePanChange(e) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    this.setState({ pan: input.value.toUpperCase() }, () =>
      input.setSelectionRange(start, end)
    );
  }
  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
  }
  handleLineChange(e) {
    this.setState({ line: e.target.value });
  }

  handleLine2Change(e) {
    this.setState({ line2: e.target.value });
  }
  handleCityChange(e) {
    this.setState({ city: e.target.value });
  }
  handlePinCodeChange(e) {
    // this.setState({ pincode: e.target.value });

    let zipcode = e.target.value;
    if (!zipcode || zipcode.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ pincode: zipcode }));
    }
  }

  handleCountryChange(selectedCountry) {
    if (null === selectedCountry || selectedCountry === "") {
      this.stateListArray = [];
      this.setState({
        stateClear: !this.state.stateClear,
      });
      return;
    }
    this.state.country = selectedCountry;
    this.setState({
      country: selectedCountry,
    });
    const postObject = {
      countryVo: {
        id: selectedCountry?.id,
        code: selectedCountry.code,
      },
      header: {
        email: "taxonomyhub@prototsolutions.com",
      },
    };
    FetchServerData.callPostServiceWithoutHeader(
      "/staticms/getstates",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let stateList = output.data;
          this.stateListArray = stateList;
          this.setState({
            render: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleGenderChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      gender: selectedType,
    });
  }

  handleSalutationChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }

    if (selectedType.name === "Miss") {
      this.setState({
        salutation: selectedType,
        gender: this.genderList[1],
      });
    } else {
      this.setState({
        salutation: selectedType,
        gender: this.genderList[0],
      });
    }
  }
  handleFirstNameChange(e) {
    this.setState({ fname: e.target.value });
  }

  handleMiddleNameChange(e) {
    this.setState({ mname: e.target.value });
  }

  handleLastNameChange(e) {
    this.setState({ lname: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleDesignationChange(e) {
    this.setState({ designation: e.target.value });
  }
  handleUsernameChange(e) {
    this.setState({ userName: e.target.value });
  }

  // handlePasswordChange(e) {
  //   this.setState({ password: e.target.value });
  // }
  toggleFocusNewPassword = () => {
    this.setState((prevState) => {
      return { passwordFocussed: !prevState.passwordFocussed };
    });
  };

  // handleConfPasswordChange(e) {
  //   this.setState({ confPassword: e.target.value });
  // }
  formValidation(e) {
    e.preventDefault();
    let fName = this.state.fname;
    let lName = this.state.lname;
    let phone = this.state.phone;
    let email = this.state.email;
    let password = this.state.password;
    let confirmPassword = this.state.confirmPassword;
    // if (null === email || email.length <= 0) {
    //     document.getElementById('email').focus();
    //  PtsAlert.error('Enter Email');
    //   return false;
    // } else
    if ("" === fName || fName.length <= 0) {
      document.getElementById("fname").focus();
      PtsAlert.error("Enter First Name");
      return false;
    } else if ("" === lName || lName.length <= 0) {
      document.getElementById("lname").focus();
      PtsAlert.error("Enter Last Name");
      return false;
    } else if ("" === phone || phone.length <= 0) {
      document.getElementById("phone").focus();
      PtsAlert.error("Enter phone");
      return false;
    } else if ("" === email || email.length <= 0) {
      document.getElementById("email").focus();
      PtsAlert.error("Enter email");
      return false;
    } else if ("" === password || password.length <= 0) {
      document.getElementById("password").focus();
      PtsAlert.error("Enter password");
      return false;
    } else if ("" === confirmPassword || confirmPassword.length <= 0) {
      document.getElementById("confirmPassword").focus();
      PtsAlert.error("Enter confirm password");
      return false;
    } else {
      // this.checkPasswdVal(
      //   password,
      //   confirmPassword,
      //   "password",
      //   "confirmPassword"
      // );
      this.addUserRegistrationDetails();
    }
  }

  checkPasswdVal(password, confirmPass, passwordId, confirmPassId) {
    if (undefined === password || "" === password) {
      PtsAlert.error("Enter Password");
      // addClass("#" + fieldId1);
      document.getElementById(passwordId).focus();
      return true;
    }
    if (false === this.checkPasswordLength(password)) {
      return true;
    } else {
      // removeClass("#" + fieldId1);
      document.getElementById(passwordId).blur();
    }
    if (undefined === confirmPass || "" === confirmPass) {
      PtsAlert.error("Enter Confirm Password ");
      // addClass("#" + fieldId2);
      document.getElementById(confirmPassId).focus();
      return true;
    } else {
      // removeClass("#" + fieldId2);
      document.getElementById(confirmPassId).blur();
    }
    if (password !== confirmPass) {
      PtsAlert.error("Password Should be matched");
      return true;
    }
    return false;
  }

  checkPasswordLength(input) {
    if (input.length < 8) {
      PtsAlert.error("Password must be 8 Characters Long");
      return false;
    }
  }

  addUserRegistrationDetails() {
    // var InputObjCmp = {
    //   registration: {
    //     orginfo: null,
    //     personalinfo: {
    //       firstName: this.state.fname,
    //       middleName: this.state.mname,
    //       lastName: this.state.lname,
    //       panNo: null,
    //       designation: this.state.designation,
    //       uid: this.state.userName,
    //       credential: this.state.password,
    //       email: this.state.email,
    //       mobile: this.state.phone,
    //       adhar: this.state.adhar,
    //       salutation: this.state.salutation ? this.state.salutation.name : '',
    //       gender: this.state.gender ? this.state.gender.name : '',
    //       dob: this.state.dob,
    //       addressvo: null,
    //       appusertype: {
    //         name: 'Corporate',
    //       },
    //     },
    //     productOfferinginfo: null,
    //     paymentHistoryinfo: null,
    //     gstn: null,
    //     email: this.state.email,
    //   },
    // };

    let obj = {
      registration: {
        firstName: this.state.fname,
        // middleName: this.state.mname,
        lastName: this.state.lname,
        phone: this.state.phone,
        email: this.state.email,
        designation: this.state.designation,
        salutation: this.state.salutation ? this.state.salutation.name : "",
        gender: this.state.gender ? this.state.gender.name : "",
        username: this.state.userName,
        credential: this.state.password,
        confirmpassword: this.state.password,
        orgName: this.state.name,
        tradeName: "",
        orgWebsite: this.state.website,
        orgPanno: this.state.pan,
        orgGstn: this.state.gstn,
        orgAddrLine: this.state.line,
        // orgAddrline2: this.state.line2,
        orgAddrCity: this.state.city,
        orgAddrPin: this.state.pincode,
        state: {
          name: this.state.state.name,
          code: this.state.state.code,
          id: this.state.state.id,
          countryvo: {
            name: this.state.country.name,
            code: this.state.country.code,
          },
        },
      },
      header: {
        appclient: "W",
        userid: "",
        sessionid: "",
        tid: null,
        bu: null,
        createdDate: "2021-01-31",
        timezone: "Asia/Calcutta",
        timezoneoffset: -330,
      },
    };

    FetchServerData.callPostServiceWithoutHeader("/regms/create", obj)
      .then((response) => {
        let output = response.data;
        if (response.status === "SUCCESS") {
          // localStorage.setItem('nextInfo', JSON.stringify(output.data));
          let updatedOutput = JSON.parse(JSON.stringify(output));

          store.dispatch(
            registrationDataAction.setRegistrationInfo(updatedOutput)
          );

          this.props.history.push({
            pathname: "/confirmationEmail",
            state: { email: this.state.email },
          });
        } else {
        }
      })
      .catch((error) => {});
    // FetchServerData.callPostService('/registrationstate/updatepersonalinfo', InputObjCmp).then((output) => {
    //   if (output.status === 'SUCCESS') {
    //     this.props.history.push('/regorgdetails');
    //   } else {
    //   }
    // });
  }

  debouncedValidatePassword = debounce(
    (password) => this.validatePassword(password),
    1000
  );
  validatePassword = (password) => {
    const { confirmPassword } = this.state;
    let one = false,
      two = false,
      three = false,
      four = false,
      five = false,
      confirmPasswordHelperText = "";

    if (password.length < 8 || password.length > 16) {
      one = true;
    }
    if (!password.match(/[A-Z]/)) {
      two = true;
    }
    if (!password.match(/[a-z]/)) {
      three = true;
    }
    if (!password.match(/[\d]/)) {
      four = true;
    }
    if (!password.match(/[\W]/)) {
      five = true;
    }

    if (password !== confirmPassword) {
      confirmPasswordHelperText = "Password & confirm password must match";
    }

    if (one || two || three || four || five) {
      this.setState({
        error: { one: one, two: two, three: three, four: four, five: five },
        confirmPasswordHelperText: confirmPasswordHelperText,
        passwordHelperText: "Password requirements not met",
      });
      return;
    }

    this.setState({
      disable: false || password !== confirmPassword,
      error: {},
      confirmPasswordHelperText: confirmPasswordHelperText,
      passwordHelperText: "",
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "password") {
      this.debouncedValidatePassword(value);
    } else if (name === "confirmPassword") {
      this.debouncedValidatePassword(this.state.password);
    }
  };
  backToHome(e) {
    this.props.history.push({
      pathname: "/home",
    });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  termsConditionToggle = () => {
    this.setState({
      open: false,
      termsConditionModal: !this.state.termsConditionModal,
    });
  };

  openTermsCondition() {
    this.setState({
      termsConditionModal: true,
    });
  }

  privacyPolicyToggle = () => {
    this.setState({
      privacyPolicyModal: !this.state.privacyPolicyModal,
    });
  };

  openPrivacyPolicy() {
    this.setState({
      privacyPolicyModal: true,
    });
  }

  handleNext(e) {
    e.preventDefault();
    // if (this.state.orgGstn) {
    //   if (!Utils.checkGstnLength(this.state.orgGstn)) {
    //     return;
    //   }
    // }
    // if (!Utils.checkPanLength(this.state.orgPanNo)) {
    //   return;
    // }
    this.setState({ activeStep: false, activeStep1: true });
  }

  handleBack = () => {
    this.setState({ activeStep: true, activeStep1: false });
  };

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

  handleClickCheckBox = (e) => {
    if (e.target.checked === true) {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };
  render() {
    // const { classes } = this.props;
    // const { value } = this.state;
    // const classes = useStyles();
    const { fieldProps } = this.props;
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div className="d-flex col-12 col-lg-5 col-xl-5 align-items-center leftBackground ">
              <div className="mx-auto">
                <div
                  className="logoSignupBackground"
                  style={{ textAlign: "center" }}
                >
                  {/* <img
                    src={logo}
                    className="img-fluid logoSignupImage"
                    alt=""
                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                  /> */}
                  <img src={logo} alt="" className="rsglogoImage" />
                </div>
                <div className="align-items-center">
                  <h2 className="logoHeding">Taxonomy Hub</h2>
                  {/* <span className="logoText">
                    One Stop Solution for Business Financials along with GST
                    Compliance
                  </span> */}
                </div>
              </div>
            </div>
            <div className="d-flex col-12 col-lg-7 col-xl-7 align-items-center authentication-bg">
              <div className="w-px-800 mx-auto">
                {this.state.activeStep === true && (
                  <>
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.handleNext.bind(this)}
                    >
                      <div className=" mx-auto">
                        <div className="signupHeding-1">
                          <h3>
                            <b>Organization Details</b>
                          </h3>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                GSTN<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              id="gstn"
                              type="text"
                              placeholder="GSTN"
                              // required
                              fullWidth={true}
                              variant="outlined"
                              // inputProps={{ style: {  } }}
                              // InputLabelProps={{ style: {  }, shrink: true }}
                              onChange={this.handleGstnChange.bind(this)}
                              onBlur={this.handleGstnOnBlur.bind(this)}
                              value={this.state.gstn}
                              name="gstn"
                              autoComplete="gstn"
                              // margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="orggstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Name<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              required
                              fullWidth
                              // margin="normal"
                              variant="outlined"
                              id="name"
                              placeholder="Name"
                              value={this.state.name}
                              onChange={this.handleNameChange.bind(this)}
                              name="name"
                              autoComplete="name"
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                PAN<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              required
                              fullWidth
                              id="pan"
                              // margin="normal"
                              variant="outlined"
                              placeholder="PAN"
                              value={this.state.pan}
                              onChange={this.handlePanChange.bind(this)}
                              validators={["required"]}
                              errorMessages={["PAN is Required"]}
                              name="pan"
                              autoComplete="pan"
                              className="textField"
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>Website</b>
                            </label>
                            <TextField
                              // margin="normal"
                              variant="outlined"
                              // required
                              fullWidth
                              id="website"
                              onChange={this.handleWebsiteChange.bind(this)}
                              placeholder="Website"
                              name="website"
                              autoComplete="website"
                              value={this.state.website}
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-12">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Address<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              required
                              fullWidth
                              // margin="normal"
                              variant="outlined"
                              id="line"
                              placeholder="Address"
                              value={this.state.line}
                              onChange={this.handleLineChange.bind(this)}
                              name="line"
                              autoComplete="line"
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Country<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <Autocomplete
                              id="country"
                              className="regCountry"
                              ListboxProps={{ className: "myCustomList" }}
                              options={this.state.countryList}
                              value={this.state.country}
                              defaultValue={this.state.country}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleCountryChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  className="regCountry"
                                  {...params}
                                  required
                                  variant="outlined"
                                  placeholder="Country"
                                />
                              )}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                State<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <Autocomplete
                              id="state"
                              ListboxProps={{ className: "myCustomList" }}
                              options={this.stateListArray}
                              key={this.state.stateClear}
                              value={this.state.state}
                              defaultValue={this.state.state}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleStateChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  className="regCountry"
                                  {...params}
                                  required
                                  variant="outlined"
                                  placeholder="State"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                City<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              required
                              fullWidth
                              // margin="normal"
                              variant="outlined"
                              id="city"
                              placeholder="City"
                              value={this.state.city}
                              onChange={this.handleCityChange.bind(this)}
                              name="city"
                              autoComplete="city"
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>ZipCode</b>
                            </label>
                            <TextField
                              fullWidth
                              // margin="normal"
                              variant="outlined"
                              id="ZipCode"
                              placeholder="ZipCode"
                              value={this.state.pincode}
                              onChange={this.handlePinCodeChange.bind(this)}
                              name="pincode"
                              autoComplete="pincode"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex">
                        <div className="col-6 text-start pt-4">
                          <span>Already have an account?</span>&nbsp;
                          <NavLink to="/login">
                            <span>Sign In</span>
                          </NavLink>
                        </div>
                        <div className="col-6 pt-4 text-end">
                          <button className="nextSignUp" type="submit">
                            Next
                          </button>
                        </div>
                      </div>
                      <div className="text-start">
                        <NavLink to="/">
                          <ArrowBackIosNewIcon />
                          Back Home
                        </NavLink>
                      </div>
                    </ValidatorForm>
                  </>
                )}
                {this.state.activeStep1 === true && (
                  <>
                    <ValidatorForm
                      ref="form"
                      // id="formAuthentication"
                      className="mb-3"
                      autoComplete="none"
                      onSubmit={this.formValidation.bind(this)}
                    >
                      <div className="mx-auto">
                        <div className="signupHeding">
                          <h3>
                            {" "}
                            <b>Admin User Details</b>
                          </h3>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                First Name<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              {...fieldProps}
                              required
                              fullWidth
                              variant="outlined"
                              id="fname"
                              placeholder="First Name"
                              type="text"
                              value={this.state.fname}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              onChange={this.handleFirstNameChange.bind(this)}
                              name="fname"
                              autoComplete="fname"
                              className="textField"
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Last Name<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              id="lname"
                              placeholder="Last Name"
                              value={this.state.lname}
                              onChange={this.handleLastNameChange.bind(this)}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              name="lname"
                              autoComplete="lname"
                              className="textField"
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Email<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              required
                              id="email"
                              placeholder="Email"
                              value={this.state.email}
                              name="email"
                              autoComplete="email"
                              type="email"
                              onChange={this.handleEmailChange.bind(this)}
                              className="textField"
                              // style={{ margin: "10px" }}
                              validators={["required", "isEmail"]}
                              errorMessages={[
                                "This field is required",
                                "Email id is not valid",
                              ]}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Phone Number<span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              id="phone"
                              placeholder="Phone Number"
                              value={this.state.phone}
                              onChange={this.handlePhoneChange.bind(this)}
                              name="phone"
                              autoComplete="phone"
                              className="textField"
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>Designation</b>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="designation"
                              placeholder="Designation "
                              value={this.state.designation}
                              onChange={this.handleDesignationChange.bind(this)}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              name="designation"
                              autoComplete="designation"
                              className="textField"
                            />
                          </div>
                          {/* <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Username
                              </b>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="userName"
                              placeholder="Username"
                              value={this.state.userName}
                              onChange={this.handleUsernameChange.bind(this)}
                              name="userName"
                              autoComplete="userName"
                              className="textField"
                            />
                          </div> */}
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Password<span className="asterisk"> *</span>
                                {this.state.passwordHelperText ? (
                                  <PasswordRequirementsTooltip
                                    error={this.state.error}
                                    open={this.state.passwordFocussed}
                                  />
                                ) : (
                                  ""
                                )}
                              </b>
                            </label>
                            <TextField
                              variant="outlined"
                              // margin="normal"
                              required
                              fullWidth
                              value={this.state.password}
                              name="password"
                              placeholder="Password "
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              id="password"
                              autoComplete="new-password"
                              onFocus={this.toggleFocusNewPassword}
                              onBlur={this.toggleFocusNewPassword}
                              helperText={this.state.passwordHelperText}
                              error={this.state.passwordHelperText !== ""}
                              onChange={this.handleChange.bind(this)}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword.bind(
                                        this
                                      )}
                                    >
                                      {this.state.showPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {/* {this.state.passwordHelperText ? (
                              <PasswordRequirementsTooltip
                                error={this.state.error}
                                open={this.state.passwordFocussed}
                              />
                            ) : (
                              ""
                            )} */}
                          </div>
                          <div className="col-sm-6">
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              <b>
                                Confirm Password
                                <span className="asterisk"> *</span>
                              </b>
                            </label>
                            <TextField
                              variant="outlined"
                              // margin="normal"
                              required
                              fullWidth
                              value={this.state.confirmPassword}
                              name="confirmPassword"
                              placeholder="Confirm Password "
                              type={
                                this.state.showConfirmPassword
                                  ? "text"
                                  : "password"
                              }
                              id="confirmPassword"
                              autoComplete="new-password"
                              helperText={this.state.confirmPasswordHelperText}
                              error={
                                this.state.confirmPasswordHelperText !== ""
                              }
                              onChange={this.handleChange.bind(this)}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowConfirmPassword.bind(
                                        this
                                      )}
                                    >
                                      {this.state.showConfirmPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 row d-flex ps-3 mt-4">
                        <div className="form-check" id="checkBox">
                          {/* <input className="form-check-input" type="checkbox" id="terms-conditions" name="terms" /> */}
                          <Checkbox
                            checked={this.state.checkbox}
                            onChange={this.handleClickCheckBox}
                            className="form-check-input"
                            id="terms-conditions"
                            name="terms"
                            required
                          />
                          I agree to &nbsp;
                          <a
                            onClick={(e) => this.openTermsCondition()}
                            style={{ color: "#007bff", cursor: "pointer" }}
                          >
                            Terms & Conditions
                          </a>
                          &nbsp; and &nbsp;
                          <a
                            onClick={(e) => this.openPrivacyPolicy()}
                            style={{ color: "#007bff", cursor: "pointer" }}
                          >
                            Privacy Policy
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex">
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {this.state.activeStep1 === true ? (
                            <div className="col-6 text-start">
                              <button
                                color="inherit"
                                className="backSignUp"
                                onClick={this.handleBack}
                                sx={{ mr: 1 }}
                              >
                                Back
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          <Box sx={{ flex: "1 1 auto" }} />
                          {this.state.activeStep1 === true ? (
                            <div className="col-6 text-end">
                              <button
                                className="submitSignup"
                                style={{
                                  opacity: this.state.isDisabled ? "0.5" : "",
                                  backgroundColor: "#0B6F9D",
                                }}
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          ) : (
                            " "
                          )}
                        </Box>
                      </div>

                      <div className="text-start pt-4">
                        <span>Already have an account?</span>&nbsp;
                        <NavLink to="/login">
                          <span>Sign In</span>
                        </NavLink>
                      </div>
                    </ValidatorForm>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.termsConditionModal}
          toggle={this.termsConditionToggle}
          size="xl"
          fade={false}
          style={{
            width: "100%",
            opacity: 1,
          }}
        >
          <Row style={{ marginTop: "1em" }}>
            <Col md={11}></Col>
            <Col md={1}>
              <Fab
                aria-label="add"
                style={{
                  float: "left",
                  fontSize: "small",
                  height: "15px",
                  width: "35px",
                  background: "#76767d",
                  cursor: "pointer",
                  color: "white",
                  backgroundImage:
                    "-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);",
                  border: "2px solid #edf0f1",
                }}
              >
                <CloseIcon onClick={(e) => this.termsConditionToggle()} />
              </Fab>
            </Col>
          </Row>
          <ModalBody>
            <Row>
              <Col md={12}>
                <TermsConditions />
              </Col>
            </Row>
          </ModalBody>
        </Modal>{" "}
        <Modal
          isOpen={this.state.privacyPolicyModal}
          toggle={this.privacyPolicyToggle}
          size="xl"
          fade={false}
          style={{
            width: "100%",
            opacity: 1,
          }}
        >
          <Row style={{ marginTop: "1em" }}>
            <Col md={11}></Col>
            <Col md={1}>
              <Fab
                aria-label="add"
                style={{
                  float: "left",
                  fontSize: "small",
                  height: "15px",
                  width: "35px",
                  background: "#76767d",
                  cursor: "pointer",
                  color: "white",
                  backgroundImage:
                    "-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);",
                  border: "2px solid #edf0f1",
                }}
              >
                <CloseIcon onClick={(e) => this.privacyPolicyToggle()} />
              </Fab>
            </Col>
          </Row>
          <ModalBody>
            <Row>
              <Col md={12}>
                <PrivacyPolicy history={this.props.history} />
              </Col>
            </Row>
          </ModalBody>
        </Modal>{" "}
      </>
    );
  }
}

export default PersonalRegistrationDetails;
