import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
class PtsConfirmDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: props.title,
      text: props.text,
      confirmCallback: props.confirmCallback,
      notConfirmCallback: props.notConfirmCallback,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirmYes = () => {
    if (this.state.confirmCallback) {
      this.state.confirmCallback();
    } else if (this.props.confirmCallback) {
      this.props.confirmCallback();
    }
    this.setState({ open: false });
  };
  handleConfirmNo = () => {
    this.handleClose();
  };
  render() {
    return (
      <Dialog className='deleteDialog' open={this.state.open} onClose={this.handleClose.bind(this)} onHide={this.close}>
        <DialogTitle id="alert-dialog-title" style={{ background: '#3b7080' }}>
          <span style={{ fontSize: 'large', color: '#fff' }}>{this.state.title}</span>
        </DialogTitle>
        <DialogContent>
          <label id="alert-dialog-description" style={{ color: 'black' }}>
            {this.state.text}
          </label>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
            startIcon={<SaveIcon />}
            onClick={this.handleConfirmYes.bind(this)}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
            startIcon={<CloseIcon />}
            onClick={this.handleConfirmNo.bind(this)}
          >
            Cancel
          </Button>

          {/* <Button onClick={this.handleConfirmYes.bind(this)} color="primary">
            Yes
          </Button>
          <Button onClick={this.handleConfirmNo.bind(this)} color="primary" autoFocus>
            No
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  }
}

export default PtsConfirmDialogue;
