import { GET_ADMIN_HEADER, SET_ADMIN_HEADER } from './AdminHeaderTypes';

const initialState = {
  adminHeader: {},
};

const AdminHeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_HEADER:
      return {
        ...state,
      };
    case SET_ADMIN_HEADER:
      return {
        ...state,
        adminHeader: action.payload,
      };
    default:
      return state;
  }
};

export default AdminHeaderReducer;
