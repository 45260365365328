import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import React, { Component } from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { CardBody, Col, Row } from "reactstrap";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import "./Expense.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default class ExpensePrintPreview extends Component {
  /* Data Used from Store */
  header = store.getState().header.header;
  currencyList = store.getState().staticData.currencyList;
  countryList = store.getState().staticData.countryList;
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  org = store.getState().appData.settings.org;
  disclaimer = store.getState().setting.general.disclaimer;
  maxItems = 15;
  printComponentRef = null;
  duplicateTriplicateList = ["Select", "Original", "Duplicate", "Triplicate"];
  invoiceHeadingList = ["TAX INVOICE", "PROFORMA INVOICE"];
  breadCrumb = [];
  constructor(props) {
    super(props);
    let expense = props.expense;
    let isHistory = props.source && props.source === "history" ? true : false;
    this.imageDwlRef = React.createRef();
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      expense = props.history.location.state.expense;
      this.state = expense;
      this.customerList = props.history.location.state.customerList;
      this.productList = props.history.location.state.productList;
      this.custHsnCodeList = props.history.location.state.custHsnCodeList;
      this.businessUnitList = props.history.location.state.businessUnitList;
      this.breadCrumb = props.history.location.state.breadCrumb;
      isHistory = props.history.location.state.isHistory
        ? props.history.location.state.isHistory
        : false;
    } else {
      this.setState(expense);
    }
  }

  changeDuplicateTypes(type) {
    this.setState({ duplicateType: type });
  }

  printContent() {
    let printContents = document.getElementById("printform").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
  }

  goToExpenseViewPage() {
    this.props.history.push({
      pathname: "/expenseView",
      state: {
        expenseId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  emailSalesVoucher() { }

  doCalculations(item) {
    let quantity = Utils.getFloatValue(item.quantity);
    let rate = Utils.getFloatValue(item.rate);
    if (parseFloat(quantity) === 0.0 || parseFloat(rate) === 0.0) {
      this.setState({
        taxable: 0.0,
        cgst: 0.0,
        sgst: 0.0,
        igst: 0.0,
        total: 0.0,
      });
      return;
    }

    let valueOfSupply = parseFloat(quantity) * parseFloat(rate);
    let discount = Utils.getFloatValue(item.discount);
    let discountAmount = 0;
    if (discount > 0 && valueOfSupply > 0) {
      discountAmount = (parseFloat(valueOfSupply) * parseFloat(discount)) / 100;
    }

    let taxable = parseFloat(valueOfSupply) - parseFloat(discountAmount);
    let cess = Utils.getFloatValue(item.cess);

    let cessAmount = 0.0;
    if (cess > 0 && taxable > 0) {
      cessAmount = (parseFloat(taxable) * parseFloat(cess)) / 100;
    }

    let taxrate = Utils.getFloatValue(item.taxrate.rate);
    let cgst = 0.0;
    let sgst = 0.0;
    let igst = 0.0;
    let isIgstApplicable = item.isIgstApplicable;
    if (isIgstApplicable) {
      igst = (parseFloat(taxrate) / 100) * parseFloat(taxable);
      igst = igst.toFixed(2);
    } else {
      let newRate = parseFloat(taxrate) / 2;
      cgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      cgst = cgst.toFixed(2);
      sgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      sgst = sgst.toFixed(2);
    }
    let total =
      parseFloat(taxable) +
      parseFloat(cgst) +
      parseFloat(sgst) +
      parseFloat(igst) +
      parseFloat(cessAmount);
    total = parseFloat(total).toFixed(2);
    item.taxable = parseFloat(taxable).toFixed(2);
    item.cgst = parseFloat(cgst).toFixed(2);
    item.sgst = parseFloat(sgst).toFixed(2);
    item.igst = parseFloat(igst).toFixed(2);
    item.cess = parseFloat(cess).toFixed(2);
    item.total = parseFloat(total).toFixed(2);
    item.netValue = valueOfSupply;
    return item;
  }
  getItem(item, srNo) {
    // item = this.doCalculations(item);
    return (
      <TableRow key={srNo}>
        <TableCell
          style={{
            width: "5%",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
          }}
        >
          {srNo}
        </TableCell>
        <TableCell
          style={{
            width: "5%",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
          }}
        >
          {item.expensecategory ? item.expensecategory.type : ""}
        </TableCell>
        <TableCell
          style={{
            width: "5%",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
          }}
        >
          {item.note}
        </TableCell>
        <TableCell
          style={{
            width: "5%",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
            textAlign: "right",
          }}
        >
          {item.amount}
        </TableCell>
      </TableRow>
    );
  }
  getEmptyItem(srNo) {
    return (
      <TableRow>
        <TableCell
          style={{
            height: "2em",
            width: "10%",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
          }}
        ></TableCell>
        <TableCell
          style={{
            width: "30%",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
          }}
        ></TableCell>
        <TableCell
          style={{
            width: "30%",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
          }}
        ></TableCell>
        <TableCell
          style={{
            width: "30%",
            textAlign: "right",
            padding: "0.5em",
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: "0.5px solid black",
          }}
        ></TableCell>
      </TableRow>
    );
  }
  getHeader() {
    return (
      <TableHead>
        <TableRow style={{ background: "#ececec" }}>
          <TableCell
            style={{
              width: "10%",
              padding: "0.5em",
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
              borderBottom: "0.5px solid black",
            }}
          >
            <strong>#</strong>
          </TableCell>
          <TableCell
            style={{
              width: "30%",
              padding: "0.5em",
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
              borderBottom: "0.5px solid black",
            }}
          >
            <strong>Category</strong>
          </TableCell>
          <TableCell
            style={{
              width: "30%",
              padding: "0.5em",
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
              borderBottom: "0.5px solid black",
            }}
          >
            <strong>Note</strong>
          </TableCell>
          <TableCell
            style={{
              width: "30%",
              textAlign: "right",
              padding: "0.5em",
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
              borderBottom: "0.5px solid black",
            }}
          >
            <strong>Amount</strong>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
  downloadImage() {
    let fileName = this.state.expnumber;
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    if (this.imageDwlRef) {
      exportComponentAsJPEG(this.imageDwlRef, {
        fileName: fileName,
      });
    }
  }
  render() {
    let expenseItems = [];
    this.state.expenseitems.forEach((element, index) => {
      expenseItems.push(this.getItem(element, index + 1));
    });

    let occupiedRows = 0;
    for (let i = 0; i < this.state.expenseitems.length; i++) {
      occupiedRows += Utils.getRowLength(
        this.state.expenseitems[i]?.note.length
      );
    }
    let remainingItems = this.maxItems - occupiedRows;
    for (let i = 0; i < remainingItems; i++) {
      let srNo = this.state.expenseitems.length + i + 1;
      expenseItems.push(this.getEmptyItem(srNo));
    }
    this.printComponentRef = React.createRef();
    let logoData = Utils.getLogo();
    return (
      <div style={{ display: "grid", marginTop: "2em" }}>
        <Row sm={12} style={{ padding: "5px" }}>
          <Col sm={6} style={{ padding: "5px", textAlign: "start" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.goToExpenseViewPage(e)}
            >
              Back
            </Button>
          </Col>
          <Col sm={6} style={{ padding: "5px", textAlign: "end" }}>
            <ReactToPrint
              trigger={
                () => (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<PrintIcon />}
                  // onClick={(e) => this.printContent(e)}
                  >
                    Print
                  </Button>
                )
                // <Button>Print this out!</Button>
              }
              content={() => this.printComponentRef}
            />

            <Button
              variant="contained"
              style={{
                background: "#9a7031",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<GetAppIcon />}
              onClick={(e) => this.downloadImage(e)}
            >
              Download
            </Button>

            {/* <Button
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<EmailIcon />}
              onClick={(e) => this.emailSalesVoucher(e)}
            >
              Email
            </Button> */}
          </Col>
        </Row>

        <Card
          sm={12}
          id="printform"
          ref={(el) => (this.printComponentRef = el)}
          style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
        >
          <div ref={this.imageDwlRef}>
            <CardBody style={{ color: "#000" }}>
              <Row
                sm={12}
                style={{
                  margin: "0",
                  padding: "5px",
                  display: "flex",
                  borderLeft: "1px solid gray",
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                }}
              >
                <Col sm={2}>
                  {Utils.isNotNullAndEmpty(logoData) && (
                    <img
                      src={logoData}
                      alt=""
                      style={{ height: "3em", width: "3em", padding: "0.1em" }}
                    />
                  )}
                </Col>
                <Col sm={8}>
                  <Row>
                    <strong style={{ width: "100%", textAlign: "center" }}>
                      {Utils.getOrgName()}
                    </strong>
                  </Row>
                  <Row>
                    <span style={{ width: "100%", textAlign: "center" }}>
                      {Utils.getAddressAsText(this.org?.addressvo)}
                    </span>
                  </Row>
                </Col>
                <Col sm={2}>
                  <span style={{ width: "100%", textAlign: "center" }}>
                    {this.state.duplicateTriplicate}
                  </span>
                </Col>
              </Row>
              <Row
                sm={12}
                style={{
                  margin: "0",
                  padding: "5px",
                  display: "flex",
                  borderLeft: "1px solid gray",
                  borderTop: "1px solid gray",
                  borderBottom: "1px solid gray",
                  borderRight: "1px solid gray",
                }}
              >
                <Col
                  sm={12}
                  style={{ fontSize: "x-large", textAlign: "center" }}
                >
                  <strong>Expense&nbsp;Voucher</strong>
                </Col>
              </Row>

              <Row
                sm={12}
                style={{ margin: "0", padding: "0", display: "flex" }}
              >
                <Col
                  sm={6}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderLeft: "1px solid gray",
                    borderRight: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",

                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Expense Number</strong>
                        </label>
                      </Col>
                      <Col sm={8} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.expnumber}
                        </label>
                      </Col>
                    </Row>

                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Expense Date</strong>
                        </label>
                      </Col>
                      <Col sm={8} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {Utils.ptsDateFormat(this.state.expdate)}
                        </label>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Remark</strong>
                        </label>
                      </Col>
                      <Col sm={8} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.note}
                        </label>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Business Unit</strong>
                        </label>
                      </Col>
                      <Col sm={8} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.bu ? this.state.bu.name : ""}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col
                  sm={6}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderRight: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div style={{ width: "100%", margin: "0", padding: "0" }}>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Created On</strong>
                        </label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {/* {this.state.createdon} */}
                          {Utils.ptsDateFormat(this.state.createdon)}
                        </label>
                      </Col>
                    </Row>

                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Is Asset</strong>
                        </label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.isAsset && this.state.isAsset === true
                            ? "Yes"
                            : "No"}
                        </label>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Is Recurring</strong>
                        </label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.isRecurring &&
                            this.state.isRecurring === true
                            ? "Yes"
                            : "No"}
                        </label>
                      </Col>
                    </Row>
                    {this.state.supplier && (
                      <Row
                        sm={12}
                        style={{
                          margin: "0",
                          padding: "0",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                          <label
                            style={{
                              color: "#000",
                              margin: "0",
                              padding: "0",
                              textAlign: "left",
                            }}
                          >
                            <strong>Supplier</strong>
                          </label>
                        </Col>
                        <Col sm={6} style={{ margin: "0", padding: "0" }}>
                          <label
                            style={{
                              color: "#000",
                              margin: "0",
                              padding: "0",
                              textAlign: "left",
                            }}
                          >
                            {this.state.supplier}
                          </label>
                        </Col>
                      </Row>
                    )}
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <label
                          style={{
                            color: "#000",

                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Currency</strong>
                        </label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.currency ? this.state.currency.code : ""}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row sm={12} style={{ padding: "0px", margin: "0" }}>
                <TableContainer className="p-0">
                  <Table style={{ color: "black" }}>
                    {this.getHeader()}
                    <TableBody>{expenseItems}</TableBody>
                  </Table>
                </TableContainer>
              </Row>

              <Row sm={12} style={{ padding: "0px", margin: "0" }}>
                <Col
                  sm={7}
                  style={{
                    borderLeft: "0.5px solid black",
                    borderBottom: "1px solid black",
                  }}
                ></Col>
                <Col sm={5} style={{ margin: "0", padding: "0" }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            verticalAlign: "top",
                            width: "40%",
                            textAlign: "right",
                            // border: "0.5px solid black",
                            borderLeft: "0.5px solid black",
                            borderRight: "0.5px solid black",
                            borderTop: "0.5px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <span>Total Amount</span>
                        </TableCell>
                        <TableCell
                          style={{
                            width: "60%",
                            textAlign: "right",
                            borderLeft: "0.5px solid black",
                            borderRight: "0.5px solid black",
                            borderTop: "0.5px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <span>
                            {this.state.currency !== null
                              ? this.state.currency.symbol
                              : ""}{" "}
                            {this.state.amount}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            verticalAlign: "top",
                            width: "40%",
                            textAlign: "right",
                            borderLeft: "0.5px solid black",
                            borderRight: "0.5px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <span>In Words</span>
                        </TableCell>
                        <TableCell
                          style={{
                            width: "60%",
                            textAlign: "right",
                            borderLeft: "0.5px solid black",
                            borderRight: "0.5px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <span>{this.state.amountinwords}</span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Col>
              </Row>
              <Row
                sm={12}
                style={{ margin: "0", padding: "0", display: "flex" }}
              >
                <Col
                  sm={8}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderLeft: "0.5px solid gray",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                  }}
                >
                  <div
                    style={{ width: "100%", margin: "0", paddingLeft: "5px" }}
                  >
                    <label
                      style={{
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>
                        <u>Disclaimer</u>
                      </strong>
                    </label>
                    <Row>
                      <Col sm={12}>
                        {this.disclaimer
                          ? this.disclaimer.invoicedisclaimer
                          : ""}
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "0",
                      textAlign: "center",
                    }}
                  >
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        display: "flex",
                        textAlign: "center",
                      }}
                    >
                      <Col sm={12} style={{ margin: "0", padding: "0" }}>
                        <strong style={{ width: "100%", textAlign: "center" }}>
                          For {Utils.getOrgName()}
                        </strong>
                      </Col>
                    </Row>
                    <Row style={{ minHeight: "6em" }}></Row>
                    <Row
                      sm={12}
                      style={{ margin: "0", padding: "0", display: "flex" }}
                    >
                      <Col sm={12} style={{ margin: "0", padding: "0" }}>
                        <span>Authorized Signatory</span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </div>
        </Card>
      </div>
    );
  }
}
