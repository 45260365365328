import React, { Component } from 'react';
import { Row } from 'reactstrap';

import './Import.scss';
import MenuWithBreadScrum from '../menuBox/MenuWithBreadScrum';
import store from '../../../redux/store';
import Utils from '../../../provider/Utils';
import UiUtils from '../../../provider/UiUtils';

export default class Import extends Component {
  breadCrumb = [
    { pageid: "/import", uiname: "Import", cssclass: "fa fa-upload" },
  ];
  title = 'Imports';
  constructor(props) {
    super(props);
    if (this.props.location && this.props.location.pathname) {
      let menu = Utils.getMenu(this.props.location.pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      // this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    // let allMenus = store.getState().userMenus.allMenus;
    let activeMenu = store.getState().appData.activeMenu;

    let importMenuArray = Utils.getSubMenus(activeMenu);

    this.state = {
      importMenuArray: importMenuArray,
      render: true,
    };
  }

  render() {
    if (this.state.render === false) {
      return '';
    }

    return (
      <>
        <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <div style={{ justifyContent: 'center', margin: '0 auto' }}>
          <Row md={12} style={{ paddingTop: '3em', margin: '0 auto', width: '100%' }}>
            {UiUtils.getUiMenuArray(this.state.importMenuArray, this.props.history, this.state.color)}
          </Row>
        </div>
      </>
    );
  }
}
