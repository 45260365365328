import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import React, { Component } from "react";
import { Card } from "@material-ui/core";
import { exportComponentAsJPEG } from "react-component-export-image";
import { CardBody, Col, Row } from "reactstrap";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
// import "./PurchaseVoucher.scss";
export default class PurchaseLedgerPrintPreview extends Component {
	/* Data Used from Store */
	header = store.getState().header.header;
	currencyList = store.getState().staticData.currencyList;
	countryList = store.getState().staticData.countryList;
	defaultCountry = store.getState().appData.settings.defaultCountry;
	defaultCurrency = store.getState().appData.settings.defaultCurrency;
	org = store.getState().appData.settings.org;
	maxItems = 15;
	printComponentRef = null;
	// duplicateTriplicateList = ['Select', 'Original', 'Duplicate', 'Triplicate'];

	constructor(props) {
		super(props);
		this.imageDwlRef = React.createRef();
		let outwardLedgerList = [];
		let selectedBusinessUnit = {};
		let fromDate = "";
		let toDate = "";
		/* Set Data From history */
		if (
			props.history &&
			props.history.location &&
			props.history.location.state
		) {
			outwardLedgerList = props.history.location.state.outwardLedgerList;
			fromDate = props.history.location.state.fromDate;
			toDate = props.history.location.state.toDate;
			selectedBusinessUnit = props.history.location.state.selectedBusinessUnit;
		}
		this.state = {
			outwardLedgerList: outwardLedgerList,
			fromDate: fromDate,
			toDate: toDate,
			selectedBusinessUnit: selectedBusinessUnit,
			action: "view",
			render: true,
		}
	}


	printContent() {
		let printContents = document.getElementById("printform").innerHTML;
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();

		document.body.innerHTML = originalContents;
		this.setState({ render: true });
		document.location.reload(true);
	}

	goToInwardLedgerListPage() {
		this.props.history.push({
			pathname: "/ledgerOutward",
		});
	}

	downloadImage() {
		let fileName = this.state.invnumber;
		fileName = fileName.toString().split("/").join("");
		fileName = fileName.toString().split("\\").join("");
		if (this.imageDwlRef) {
			exportComponentAsJPEG(this.imageDwlRef, {
				fileName: fileName,
			});
		}
	}
	render() {
		this.printComponentRef = React.createRef();
		let logoData = Utils.getLogo();

		return (
			<div style={{ display: "grid" }}>
				<Row md={12} style={{ padding: "0.5em" }}>
					<Col md={6} style={{ textAlign: "start" }}>
						<Button
							variant="contained"
							size="small"
							style={{
								background: "#717373",
								color: "#fff",
								marginRight: "1rem",
							}}
							startIcon={<ArrowBackIcon />}
							onClick={(e) => this.goToInwardLedgerListPage(e)}
						>
							Back
						</Button>
					</Col>
					<Col md={6} style={{ padding: "5px", textAlign: "end" }}>
						<ReactToPrint
							trigger={() => (
								<Button
									variant="contained"
									size="small"
									style={{
										background: "#1b7189",
										color: "#fff",
										marginRight: "1rem",
									}}
									startIcon={<PrintIcon />}
								// onClick={(e) => this.printContent(e)}
								>
									Print
								</Button>
							)}
							content={() => this.printComponentRef}
						/>

						<Button
							variant="contained"
							size="small"
							style={{
								background: "#9a7031",
								color: "#fff",
								marginRight: "1rem",
							}}
							startIcon={<GetAppIcon />}
							onClick={() => exportComponentAsJPEG(this.imageDwlRef)}
						>
							Download
						</Button>
					</Col>
				</Row>
				<Card
					ref={(el) => (this.printComponentRef = el)}
					md={12}
					id="printform"
					// ref={this.printComponentRef}
					// className="a4size"
					style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
				>
					<div ref={this.imageDwlRef}>
						<CardBody style={{ color: "#000" }}>
							<Row
								md={12}
								style={{
									margin: "0",
									padding: "5px",
									display: "flex",
									borderLeft: "1px solid gray",
									borderTop: "1px solid gray",
									borderRight: "1px solid gray",
								}}
							>
								<Col md={2}>
									{Utils.isNotNullAndEmpty(logoData) && (
										<img
											src={logoData}
											alt=""
											style={{ height: "3em", width: "3em", padding: "0.1em" }}
										/>
									)}
								</Col>
								<Col md={8}>
									<Row>
										<strong style={{ width: "100%", textAlign: "center" }}>
											{Utils.getOrgName()}
										</strong>
									</Row>
									<Row>
										<span style={{ width: "100%", textAlign: "center" }}>
											{Utils.getAddressAsText(
												this.org ? this.org.addressvo : {}
											)}
										</span>
									</Row>
								</Col>
								<Col md={2}>
									<span style={{ width: "100%", textAlign: "center" }}>
										{/* {this.state.duplicateTriplicate} */}
									</span>
								</Col>
							</Row>
							<Row
								md={12}
								style={{
									margin: "0",
									padding: "5px",
									display: "flex",
									borderLeft: "1px solid gray",
									borderTop: "1px solid gray",
									borderRight: "1px solid gray",
								}}
							>
								<Col md={4}>
									<Row md={12}>
										<Col md={6} style={{ textAlign: "right" }}>
											<strong>Business Unit :</strong>
										</Col>
										<Col md={6}>
											<span>{this.state.selectedBusinessUnit ? this.state.selectedBusinessUnit.name : ""}</span>
										</Col>
									</Row>
								</Col>
								<Col md={4}>
									<Row md={12}>
										<Col md={6} style={{ textAlign: "right" }}>
											<strong>From Date:</strong>
										</Col>
										<Col md={6}>
											<span>{this.state.fromDate ? Utils.ptsDateFormat(this.state.fromDate) : ""}</span>
										</Col>
									</Row>
								</Col>
								<Col md={4}>
									<Row md={12}>
										<Col md={6} style={{ textAlign: "right" }}>
											<strong>To Date:</strong>
										</Col>
										<Col md={6}>
											<span>{this.state.toDate ? Utils.ptsDateFormat(this.state.toDate) : ""}</span>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row
								md={12}
								style={{
									margin: "0",
									padding: "5px",
									display: "flex",
									borderLeft: "0.5px solid gray",
									borderTop: "0.5px solid gray",
									borderBottom: "0.5px solid gray",
									borderRight: "0.5px solid gray",
								}}
							>
								<Col
									md={12}
									style={{ fontSize: "x-large", textAlign: "center" }}
								>
									<strong>Purchase Ledger</strong>
								</Col>
							</Row>

							<Row
								md={12}
								style={{
									margin: "0",
									padding: "0",
									borderRight: "1px solid gray",
									borderLeft: "1px solid gray",
								}}
							>
								<Table style={{ margin: 0, padding: 0 }}>
									<TableHead>
										<TableRow style={{
											// backgroundColor: "#1b7189",
											fontWeight: "bold",
											// color: "#fff",
										}}>
											<TableCell style={{ fontWeight: "bold", }} > # </TableCell>
											<TableCell style={{ fontWeight: "bold", width: "105px" }} > Date </TableCell>
											<TableCell style={{ wordBreak: "break-word", fontWeight: "bold", }}> Invoice Number</TableCell>
											<TableCell style={{ fontWeight: "bold" }}> Type </TableCell>
											<TableCell style={{ fontWeight: "bold" }}> Status </TableCell>
											<TableCell style={{ wordBreak: "break-word", fontWeight: "bold" }}> Supplier Name </TableCell>
											<TableCell style={{ textAlign: "right", fontWeight: "bold" }}> GST</TableCell>
											<TableCell style={{ textAlign: "right", fontWeight: "bold" }}> Taxable </TableCell>
											<TableCell style={{ textAlign: "right", fontWeight: "bold" }}> Total </TableCell>
											<TableCell style={{ textAlign: "right", fontWeight: "bold" }}> Ex Rate </TableCell>
											<TableCell style={{ textAlign: "right", fontWeight: "bold" }}> Credit </TableCell>
											<TableCell style={{ textAlign: "right", fontWeight: "bold" }}> Debit </TableCell>
											<TableCell style={{ textAlign: "right", wordBreak: "break-word", fontWeight: "bold" }}> Balance Amount </TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.outwardLedgerList.map((row, index) => {
											return (
												<TableRow key={index + 1}>
													<TableCell style={{ width: "60px" }}>{index + 1} </TableCell>
													<TableCell>{row.invoicedate} </TableCell>
													<TableCell  >{row.invnumber} </TableCell>
													<TableCell style={{ width: "120px", wordBreak: "break-word" }}>{row.invoicetypeDescription} </TableCell>
													<TableCell style={{ wordBreak: "break-word", width: "100px", }}>{row.status} </TableCell>
													<TableCell style={{ wordBreak: "break-word" }}>{row.supplierName} </TableCell>
													<TableCell style={{ textAlign: "right" }}>{row.currencySymbol + " " + Utils.getParseFloatValue(row.totalgst)} </TableCell>
													<TableCell style={{ textAlign: "right" }}>{row.currencySymbol + " " + Utils.getParseFloatValue(row.totaltaxable)} </TableCell>
													<TableCell style={{ textAlign: "right" }}>{row.currencySymbol + " " + Utils.getParseFloatValue(row.totalamount)} </TableCell>
													<TableCell style={{ textAlign: "right" }}>{row.baseCurrencySymbol + " " + Utils.getParseFloatValue(row.exchangerate)} </TableCell>
													<TableCell style={{ textAlign: "right" }}>{row.baseCurrencySymbol + " " + Utils.getParseFloatValue(row.creditAmount)} </TableCell>
													<TableCell style={{ textAlign: "right" }}>{row.baseCurrencySymbol + " " + Utils.getParseFloatValue(row.debitAmount)} </TableCell>
													<TableCell style={{ textAlign: "right" }}>{row.baseCurrencySymbol + " " + Utils.getParseFloatValue(row.balanceAmount)} </TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</Row>
						</CardBody>
					</div>
				</Card>
			</div>
		);
	}
}
