import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./ForgotPassword.scss";
import logo from "../../../assets/images/logo3.png";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import FetchServerData from "../../../provider/FetchServerData";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    let email = "";
    let tenantList = [];
    let render = false;
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      email = props.history.location.state.email;
      tenantList = props.history.location.state.tenantList;
      render = props.history.location.state.render;
    }
    this.state = {
      email: email,
      tenantList: tenantList,
      tenantId: tenantList[0],
      render: render,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleCompanyChange = (selectedValue) => {
    this.setState({ tenantId: selectedValue });
  };

  forgotPasswordBackend() {
    let header = {
      email: this.state.email,
      tid: this.state.tenantId.id,
      appclient: "W",
    };
    const postObject = {
      header: header,
      // appuser: {
      //   email: this.state.email,
      // },
    };

    FetchServerData.callPostService("/userms/forgetPassword", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.history.push({
            pathname: "/resetPassword",
            state: {
              email: this.state.email,
              tenantId: this.state.tenantId,
              tenantList: this.state.tenantList,
            },
          });
        } else {
          if (output.status === "FAIL") {
            PtsAlert.error("Please enter Registered Email ID");
          }
        }
      })
      .catch((error) => { });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <div className="authentication-wrapper authentication-cover">
            <div className="authentication-inner row m-0">
              <div
                className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground "
                style={{ background: "#1a7088", textAlign: "center" }}
              >
                <div className="mx-auto">
                  {/* <div className="logoBackground"> */}
                  <img
                    src={logo}
                    className="img-fluid logoImage"
                    // id="logoStyle"
                    alt=""
                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                  />
                  {/* </div> */}
                  <h2 className="logoHeding">
                    <b>Taxonomy Hub</b>
                  </h2>
                  {/* <span className="logoText">
                    One Stop Solution for Business Financials along with GST
                    Compliance
                  </span> */}
                </div>
              </div>
              <div
                className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4"
                style={{ background: "#fff" }}
              >
                <div className="w-px-400 mx-auto">
                  <Row>
                    <Col md={12} className="forgotPasswordSubTitle">
                      <h3>
                        <b>Forgot Password?</b>
                      </h3>
                      <p>
                        We will send you an email that will allow you to reset
                        your password.
                      </p>
                    </Col>
                  </Row>
                  <ValidatorForm
                    ref="form"
                    id="formAuthentication"
                    className="mb-3"
                    onSubmit={this.forgotPasswordBackend.bind(this)}
                  >
                    <div className="mb-4">
                      <label
                        className="form-label bold"
                        htmlFor="collapsible-companyName"
                      >
                        EMAIL ID <span className="asterisk">*</span>
                      </label>
                      <TextValidator
                        id="email"
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        placeholder="Enter Email ID"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleEmailChange.bind(this)}
                        autoComplete="off"
                        validators={["required", "isEmail"]}
                        errormessages={[
                          "This field is required",
                          "Email id is not valid",
                        ]}
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="form-label bold"
                        htmlFor="collapsible-companyName"
                      >
                        Company name
                        <span className="asterisk">*</span>
                      </label>
                      <Autocomplete
                        id="tenantList"
                        ListboxProps={{
                          className: "myCustomList"
                        }}
                        options={this.state.tenantList}
                        value={this.state.tenantId}
                        defaultValue={this.state.tenantId}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleCompanyChange(value);
                        }}
                        inputprops={{ style: { textTransform: "capitalize" } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Company"
                            variant="outlined"
                            required
                            margin="normal"
                            className="regCountry"
                          />
                        )}
                      />
                    </div>
                    <button className="goButton">Send Verification Mail</button>
                  </ValidatorForm>
                  <div style={{ textAlign: "left", padding: "1em 0em" }}>
                    <NavLink to="/login">
                      <ArrowBackIosNewIcon />
                      Back Sign In
                    </NavLink>
                    &nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default ForgotPassword;
