import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Col, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import AdminFetchServerData from "../../../provider/AdminFetchServerData";
import logo from "../../../assets/images/th.png";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

class AdminForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      render: false,
    };
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  forgotPasswordBackend() {
    const postObject = {
      header: {},
      appuser: {
        email: this.state.email,
      }
    };

    AdminFetchServerData.callPostService('/adminpanelAppuserms/forgetPassword', postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.history.push({
            pathname: "/ApResetPassword",
            state: {
              email: this.state.email,
            },
          });
        } else {
          if (output.status === 'FAIL') {
            PtsAlert.error('Please enter Registered Email ID');
          }
        }
      })
      .catch((error) => { console.log("error", error) });
  }
  render() {
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center apLeftBackground">
              <div className="mx-auto ">
                {/* <div className="aplogoBackground"> */}
                <img
                  src={logo}
                  className="img-fluid apLogoImage"
                  alt=""
                  data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                  data-app-light-img="illustrations/girl-with-laptop-light.png"
                />
                {/* </div> */}
                <div className="align-items-center">
                  <h2 className="logoHeding">Taxonomy Hub</h2>
                </div>
              </div>
            </div>
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                <div className="adminSigninHeding text-start ps-2">Admin Panel</div>
                <Row>
                  <Col md={12} className="forgotPasswordSubTitle mt-2">
                    <h3>Forgot Password?</h3>
                    <p>
                      We will send you an email that will allow you to reset
                      your password.
                    </p>
                  </Col>
                </Row>
                <ValidatorForm
                  ref="form"
                  id="formAuthentication"
                  className="mb-3"
                  onSubmit={this.forgotPasswordBackend.bind(this)}
                >
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label bold">
                      EMAIL ID <span className="asterisk">*</span>
                    </label>
                    <TextField
                      id="email"
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      placeholder="Enter Email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleEmailChange.bind(this)}
                      autoComplete="off"
                      validators={["required", "isEmail"]}
                      errormessages={[
                        "This field is required",
                        "Email id is not valid",
                      ]}
                    />
                  </div>
                  <button className="siginButton d-grid w-100">
                    Send Verification Mail
                  </button>
                </ValidatorForm>
                <div style={{ textAlign: 'left', padding: '1em 0em' }}>
                  <NavLink to="/ApLogin">
                    <ArrowBackIosNewIcon />
                    Back Sign In
                  </NavLink>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AdminForgotPassword;