import React, { Component } from "react";
import { matchPath } from "react-router-dom";
import { Collapse, Nav, Navbar } from "reactstrap";
import ptsLogo from "../../../assets/images/ptsLogo.png"
import Utils from "../../../provider/Utils";

export class AdminHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }
    toggle = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    getPageTitle = () => {
        let name;
        this.props.routes.map((prop) => {
            if (
                matchPath(this.props.location.pathname, {
                    path: prop.path,
                    exact: true,
                    strict: false,
                })
            ) {
                name = prop.name;
            }
            return null;
        });
        return name;
    };

    render() {
        return (
            <section id="navbar">
                <nav
                    className="layout-navbar navbar navbar-expand-lg fixed-top navbar-detached align-items-center bg-navbar-theme minMaxNavbar"
                    style={{
                        margin: "0px",
                        float: "right",
                        width: this.props.isSidebarCollapsed ? "85%" : "95%",
                        position: "fixed",
                        marginLeft: this.props.isSidebarCollapsed ? "19em" : "7em",
                    }}
                    id="layout-navbar"
                >
                    {/* <div className="container"> */}
                    <div className="col-md-6 text-start">
                        <div className="row align-items-start ">
                            {/* <span
                                className="app-brand-logo demo"
                                style={{ marginLeft: "0.8em", padding: "10px 12px" }}
                            >

                            </span> */}
                            <span
                                style={{
                                    textTransform: "capitalize",
                                    fontSize: "23px",
                                    fontFamily: "Poppins",
                                    color: "#fff",
                                    marginTop: "0.2em",
                                    fontWeight: 600,
                                }}
                            >
                                <img
                                    src={ptsLogo}
                                    className="img-fluid"
                                    alt=""
                                    style={{
                                        marginTop: "-10px",
                                        width: "40px",
                                    }}
                                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                                />
                                Proto Technology Solutions
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6 me-2" style={{ float: "right" }}>
                        <Collapse
                            isOpen={this.state.isOpen}
                            navbar
                            style={{ float: "right" }}
                        >
                            <Nav
                                navbar
                            >
                                {this.props.children}
                            </Nav>
                        </Collapse>
                    </div>
                    {/* </div> */}
                </nav>
            </section>
        );
    }
}
export default AdminHeader;

const SkipToContentLink = ({ focusId }) => {
    return (
        <a href={`#${focusId}`} tabIndex="1" className="skip-to-content">
            Skip to Content
        </a>
    );
};
