import { TextField } from "@material-ui/core";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../../assets/images/logo3.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import PtsAlert from "../../ptsAlert/PtsAlert";
import FetchServerData from "../../../../provider/FetchServerData";

export class ResetPasswordViaEmail extends Component {
  constructor(props) {
    super(props);
    let str = window.location.href;
    let splitted = str.split("?");
    let splitForTid = str.split("&");
    let urlEmail = splitted[1].substring(6, splitted[1].indexOf("&ver"));
    let urlverificationCode = splitForTid[1].substring(17, splitted[1].indexOf("&Tid"));;
    let urlTid = splitForTid[2].substring(4, splitted[1].indexOf("&"));

    this.state = {
      newPassword: "",
      confirmPassword: "",
      email: urlEmail,
      tid: urlTid,
      verificationCode: urlverificationCode,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  }
  handleClickShowNewPassword() {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  }
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }
  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value });
  }
  handlePasswordConfirmChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  resetPasswordViaEmailLinkBackend() {
    if (this.state.newPassword !== this.state.confirmPassword) {
      return;
    }
    const postObject = {
      header: { tid: this.state.tid },
      appuserVo: {
        email: this.state.email,
        credential: this.state.newPassword,
        temppassword: this.state.verificationCode,
      },
    };
    FetchServerData.callPostService("/userms/resetPassword", postObject).then(
      (response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.history.push({
            pathname: "/login",
          });
        } else {
        }
      }
    );
  }

  render() {
    const error = this.state.newPassword !== this.state.confirmPassword;
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div
              className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground "
              style={{ background: "#1a7088", textAlign: "center" }}
            >
              <div className="mx-auto">
                {/* <div className="logoBackground"> */}
                <img
                  src={logo}
                  className="img-fluid logoImage"
                  // id="logoStyle"
                  alt=""
                  data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                  data-app-light-img="illustrations/girl-with-laptop-light.png"
                />
                {/* </div> */}
                <h2 className="logoHeding">
                  <b>Taxonomy Hub</b>
                </h2>
                {/* <span className="logoText">
                  One Stop Solution for Business Financials along with GST
                  Compliance
                </span> */}
              </div>
            </div>
            <div
              className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4"
              style={{ background: "#fff" }}
            >
              <div className="w-px-400 mx-auto">
                <ValidatorForm
                  ref="form"
                  id="formAuthentication"
                  className="mb-3"
                  onSubmit={this.resetPasswordViaEmailLinkBackend.bind(this)}
                >
                  <div className="text-start">
                    <h3>Reset Password?</h3>
                    <p>
                      We will send you an email that will allow you to reset
                      your password.
                    </p>
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      NEW PASSWORD <span className="asterisk">*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="newpassword"
                      placeholder="Enter New Password *"
                      type={this.state.showNewPassword ? "text" : "password"}
                      id="new-password"
                      autoComplete="current-newpassword"
                      value={this.state.newPassword}
                      onChange={this.handleNewPasswordChange.bind(this)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle new password visibility"
                              onClick={this.handleClickShowNewPassword.bind(
                                this
                              )}
                            >
                              {this.state.showNewPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <label
                      htmlFor="new-password"
                      className="form-label"
                      id="signupFormLabel"
                    >
                      Confirm Password <span className="asterisk">*</span>
                    </label>
                    <TextField
                      id="confirmPassword"
                      className="form-control"
                      variant="outlined"
                      placeholder="Confirm Password;"
                      name="confirmPassword"
                      aria-describedby="alignment-confirm password2"
                      type={
                        this.state.showConfirmPassword ? "text" : "password"
                      }
                      value={this.state.confirmPassword}
                      onChange={this.handlePasswordConfirmChange.bind(this)}
                      helperText={
                        error ? "Password & confirm password must match" : ""
                      }
                      error={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowConfirmPassword.bind(
                                this
                              )}
                            >
                              {this.state.showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <button className="goButton"> Reset Password </button>
                </ValidatorForm>
                <div className="d-flex flex-row pt-3">
                  <NavLink className="pt-1" to="/login">
                    <ArrowBackIosNewIcon />
                    Back Sign In
                  </NavLink>
                  <p className="ms-5 ps-4">
                    <span>Do not have an account?</span>&nbsp;
                    <NavLink to="/personalDetailsReg">
                      <span>Sign Up</span>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ResetPasswordViaEmail;
