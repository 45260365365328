import React from "react";
import store from "../redux/store";
import moment from "moment";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { createMuiTheme } from "@material-ui/core";

const getInternalChildMenus = (childMenu) => {
  return childMenu.domainObjects;
};

export const getSubMenus = (parentMenuID, selectedTabIndex) => {
  let allMenus = store.getState().userMenus.allMenus;
  let subMenuArray = [];
  for (let i = 0; i < allMenus.length; i++) {
    // let menu = allMenus[i];
    let menu = allMenus[i];
    if (menu.childMenus) {
      for (let j = 0; j < menu.childMenus.length; j++) {
        const element = menu.childMenus[j];
        if (element.childMenus && element.childMenus.length > 0) {
          if (j === selectedTabIndex) {
            subMenuArray = this.getInternalChildMenus(element);
          }
        } else {
          if (element.parentMenuId && element.parentMenuId === parentMenuID) {
            if (menu.pageid) {
              menu.pageid = menu.pageid.split("#/").join("/");
            }
            subMenuArray.push(element);
          }
        }
      }
    }
  }
  return subMenuArray;
};

export const getTopMenus = () => {
  let topMenuArray = store.getState().userMenus.topMenus;
  for (let i = 0; i < topMenuArray.length; i++) {
    if (topMenuArray[i].pageid) {
      topMenuArray[i].pageid = topMenuArray[i].pageid.split("#/").join("/");
    }
  }
  return topMenuArray;
};

export const rectifyPageID = (menu) => {
  if (menu && menu.pageId) {
    menu.pageid = menu.pageid.split("#/").join("/");
    if (menu.parentMenu) {
      menu.parentMenu = rectifyPageID(menu.parentMenu);
    } else {
      return menu;
    }
  }
  return menu;
};

export const getTopMenuWithPageID = (pageId) => {
  let topMenuArray = store.getState().userMenus.topMenus;
  for (let i = 0; i < topMenuArray.length; i++) {
    let menu = topMenuArray[i];
    if (menu.pageid) {
      menu.pageid = menu.pageid.split("#/").join("/");
    }

    if (
      menu &&
      menu.pageid &&
      equalsIgnoreCase(menu.pageid.toString(), pageId.toString())
    ) {
      return menu;
    }
  }
  return null;
};
export const getMenu = (pageId) => {
  let allMenus = store.getState().userMenus.allMenus;
  for (let i = 0; i < allMenus.length; i++) {
    let menu = allMenus[i];
    if (menu.pageid) {
      menu.pageid = menu.pageid.split("#/").join("/");
    }

    if (
      menu &&
      menu.pageid &&
      equalsIgnoreCase(menu.pageid.toString(), pageId.toString())
    ) {
      return menu;
    }
  }
  // path = path.toString() + '</div>';
  return null;
};

export const getMenuHierarchy = (pageId) => {
  let allMenus = store.getState().userMenus.allMenus;
  let menuArray = [];
  for (let i = 0; i < allMenus.length; i++) {
    let menu = allMenus[i];
    if (menu.pageid) {
      menu.pageid = menu.pageid.split("#/").join("/");
      if (menu.pageid === "tbgstr1") {
        menu.pageid = "/tbgstr1";
      } else if (menu.pageid === "tbgstr3") {
        menu.pageid = "/tbgstr1";
      }
    }

    if (
      menu &&
      menu.pageid &&
      equalsIgnoreCase(menu.pageid.toString(), pageId.toString())
    ) {
      if (menu.parentMenu) {
        let parentMenu = menu.parentMenu;
        let grandParent = parentMenu.parentMenu;
        if (grandParent) {
          grandParent.pageid = grandParent.pageid.split("#/").join("/");
          if (grandParent.pageid === "tbgstr1") {
            grandParent.pageid = "/tbgstr1";
          } else if (menu.pageid === "tbgstr3") {
            menu.pageid = "/tbgstr1";
          } else {
            menuArray.push({
              cssclass: grandParent.cssclass,
              id: grandParent.id,
              menuid: grandParent.menuid,
              uiname: grandParent.uiname,
              pageid: grandParent.pageid,
            });
          }
        }

        parentMenu.pageid = parentMenu.pageid.split("#/").join("/");
        if (parentMenu.pageid === "tbgstr1") {
          parentMenu.pageid = "/tbgstr1";
        } else if (menu.pageid === "tbgstr3") {
          menu.pageid = "/tbgstr1";
        } else {
          menuArray.push({
            cssclass: parentMenu.cssclass,
            id: parentMenu.id,
            menuid: parentMenu.menuid,
            uiname: parentMenu.uiname,
            pageid: parentMenu.pageid,
          });
        }
      }
      menuArray.push({
        cssclass: menu.cssclass,
        id: menu.id,
        menuid: menu.menuid,
        uiname: menu.uiname,
        pageid: menu.pageid,
      });
      return menuArray;
    }
  }
  // path = path.toString() + '</div>';
  return menuArray;
};
export const getAmountInWords = (amt, currency) => {
  // return new Promise(function (accept, reject) {
  let currName = "Rupees";
  let currSubunit = "Paisa";
  if (currency) {
    currName = currency.unit;
    currSubunit = currency.subunit;
  }
  let a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen ",
  ];
  let b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  let number = parseFloat(amt).toFixed(2).split(".");

  let num = parseInt(number[0], 10);
  let digit = parseInt(number[1], 10);
  if (num.toString().length > 11) return "overflow";
  let n = ("000000000" + num)
    .substr(-11)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  let d = ("00" + digit).substr(-2).match(/^(\d{2})$/);
  if (!n) return;
  let str = "";
  str +=
    n[1] !== "00"
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Arab "
      : "";
  str +=
    n[2] !== "00"
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Crore "
      : "";
  str +=
    n[3] !== "00"
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Lakh "
      : "";
  str +=
    n[4] !== "00"
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Thousand "
      : "";
  str +=
    n[5] !== "0"
      ? (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "Hundred "
      : "";
  if (num === 1) {
    str +=
      n[6] !== "00"
        ? (a[Number(n[6])] || b[n[6][0]] + " " + a[n[6][1]]) + currName + " "
        : "";
  } else {
    str +=
      n[6] !== "00"
        ? (a[Number(n[6])] || b[n[6][0]] + " " + a[n[6][1]]) + currName + "s "
        : "" + currName + "s ";
  }
  str +=
    d[1] !== "00"
      ? (str !== "" ? "and " : "") +
      (a[Number(d[1])] || b[d[1][0]] + " " + a[d[1][1]]) +
      currSubunit +
      " "
      : "Only!";

  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
  // });
};

export const hasPrivilege = (privilegeName, privilegeList) => {
  if (isNull(privilegeList) || isNull(privilegeName)) {
    return false;
  }
  for (let i = 0; i < privilegeList.length; i++) {
    if (equalsIgnoreCase(privilegeName, privilegeList[i])) {
      return true;
    }
  }

  return false;
};

export const isSupportedCurrency = (input) => {
  let supportedArray = [
    "CAD",
    "HKD",
    "ISK",
    "PHP",
    "DKK",
    "HUF",
    "CZK",
    "GBP",
    "RON",
    "SEK",
    "IDR",
    "INR",
    "BRL",
    "RUB",
    "HRK",
    "JPY",
    "THB",
    "CHF",
    "EUR",
    "MYR",
    "BGN",
    "TRY",
    "CNY",
    "NOK",
    "NZD",
    "ZAR",
    "USD",
    "MXN",
    "SGD",
    "AUD",
    "ILS",
    "KRW",
    "PLN",
  ];
  for (let i = 0; i < supportedArray.length; i++) {
    if (input === supportedArray[i]) {
      return true;
    }
  }
  return false;
};

export const equalsIgnoreCase = (string1, string2) => {
  if (!string1 || !string2) {
    return false;
  }
  if (
    string1.toString().trim().toUpperCase() ===
    string2.toString().trim().toUpperCase()
  ) {
    return true;
  }

  return false;
};

export const isNull = (input) => {
  if (!input) {
    return true;
  }
  return false;
};
export const isNullOrEmpty = (input) => {
  if (input === 0) {
    return false;
  }
  if (!input || input === "") {
    return true;
  }
  return false;
};

export const isNotNull = (input) => {
  if (!input) {
    return false;
  }
  return true;
};

export const isNotNullAndEmpty = (input) => {
  if (!input || input.toString().trim() === "") {
    return false;
  }
  return true;
};

export const isIgstApplicable = (customerVo) => {
  let orgAddress = store.getState().appData.settings.org?.addressvo;

  if (isNull(orgAddress) || isNull(orgAddress.statevo)) {
    return false;
  }

  if (!customerVo) {
    return false;
  }

  let custAddress = customerVo.addressvo;

  /*Check Customer Address in address and addressvo */
  if (isNull(custAddress)) {
    custAddress = custAddress.address;
  }

  if (isNull(custAddress) || isNull(custAddress.statevo)) {
    return false;
  }

  let customerState = custAddress.statevo;
  let orgState = orgAddress.statevo;

  if (equalsIgnoreCase(customerState.code, orgState.code)) {
    return false;
  } else {
    return true;
  }
};

export const isExchangeRateApplicableForCustomer = (
  customer,
  defaultCountry
) => {
  if (
    isNull(customer) ||
    isNull(customer.addressvo) ||
    isNull(customer.addressvo.statevo) ||
    isNull(customer.addressvo.statevo.countryvo) ||
    isNull(defaultCountry)
  ) {
    return false;
  }
  let country = customer.addressvo.statevo.countryvo;
  if (equalsIgnoreCase(country.code, defaultCountry.code)) {
    return false;
  } else {
    return true;
  }
};

export const isExchangeRateApplicableForSupplier = (
  supplier,
  defaultCountry
) => {
  if (
    isNull(supplier) ||
    isNull(supplier.addressvo) ||
    isNull(supplier.addressvo.statevo) ||
    isNull(supplier.addressvo.statevo.countryvo) ||
    isNull(defaultCountry)
  ) {
    return false;
  }
  let country = supplier.addressvo.statevo.countryvo;
  if (equalsIgnoreCase(country.code, defaultCountry.code)) {
    return false;
  } else {
    return true;
  }
};

export const isExchangeRateApplicableForCountry = (country, defaultCountry) => {
  if (isNull(country) || isNull(defaultCountry)) {
    return false;
  }

  if (equalsIgnoreCase(country.code, defaultCountry.code)) {
    return false;
  } else {
    return true;
  }
};

export const isExchangeRateApplicableForCurrency = (
  currency,
  defaultCurrency
) => {
  if (isNull(currency) || isNull(defaultCurrency)) {
    return false;
  }

  if (equalsIgnoreCase(currency.isocode, defaultCurrency.isocode)) {
    return false;
  } else {
    return true;
  }
};

export const getFloatValue = (input) => {
  if (!input || "" === input) {
    return 0.0;
  }
  return parseFloat(input).toFixed(2);
};

export const getParseFloatValue = (input) => {
  if (input === "") {
    return 0.00;
  }
  return parseFloat(input).toFixed(2);
};

export const getRoundValue = (input) => {
  if (!input || "" === input) {
    return 0.0;
  }
  return Math.round(input);
};

export const getMonth = (monthInNumber) => {
  monthInNumber = Number(monthInNumber);
  let months = {
    1: "JAN",
    2: "FEB",
    3: "MAR",
    4: "APR",
    5: "MAY",
    6: "JUN",
    7: "JUL",
    8: "AUG",
    9: "SEP",
    10: "OCT",
    11: "NOV",
    12: "DEC",
  };
  return months[monthInNumber];
};

export const getMonthNumber = (monthInNumber) => {
  // monthInNumber = Number(monthInNumber);
  let months = {
    JAN: "01",
    FEB: "02",
    MAR: "03",
    APR: "04",
    MAY: "05",
    JUN: "06",
    JUL: "07",
    AUG: "08",
    SEP: "09",
    OCT: "10",
    NOV: "11",
    DEC: "12",
  };
  return months[monthInNumber];
};

export const getMonthList = () => {
  return [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
};

export const quarterList = () => {
  return ["APR - JUN", "JUL-SEP", "OCT-DEC", "JAN-MAR"];
};

export const getQuarterList = () => {
  let arr = [];
  arr.push({ month: "APR", quarter: "APR-JUN" });
  arr.push({ month: "JUL", quarter: "JUL-SEP" });
  arr.push({ month: "OCT", quarter: "OCT-DEC" });
  arr.push({ month: "JAN", quarter: "JAN-MAR" });
  return arr;
};

export const getQuarterForMonth = (month) => {
  if (
    equalsIgnoreCase("APR", month) ||
    equalsIgnoreCase("MAY", month) ||
    equalsIgnoreCase("JUN", month)
  ) {
    return { month: "APR", quarter: "APR-JUN" };
  }
  if (
    equalsIgnoreCase("JUL", month) ||
    equalsIgnoreCase("AUG", month) ||
    equalsIgnoreCase("SEP", month)
  ) {
    return { month: "JUL", quarter: "JUL-SEP" };
  }
  if (
    equalsIgnoreCase("OCT", month) ||
    equalsIgnoreCase("NOV", month) ||
    equalsIgnoreCase("DEC", month)
  ) {
    return { month: "OCT", quarter: "OCT-DEC" };
  }
  if (
    equalsIgnoreCase("JAN", month) ||
    equalsIgnoreCase("FEB", month) ||
    equalsIgnoreCase("MAR", month)
  ) {
    return { month: "JAN", quarter: "JAN-MAR" };
  }
};

export const getLogo = () => {
  let imageData = "data:image/png;base64,";
  if (store.getState().appData.logoData) {
    imageData = imageData + store.getState().appData.logoData;
  }
  return imageData;
};

export const getOrgName = () => {
  let org = store.getState().appData.settings?.org;
  let orgName = "";
  if (org && org.usetnforinvoicing === true && org.tradeName) {
    orgName = org.tradeName;
  } else if (org) {
    orgName = org.name;
  }
  return orgName;
};

export const getOrgCountry = () => {
  let org = store.getState().appData.settings.org;
  if (org && org.address && org.address.state) {
    return org.address.state.country;
  } else if (org && org.address && org.address.statevo) {
    return org.address.statevo.countryvo;
  } else if (org && org.addressvo && org.addressvo.statevo) {
    return org.addressvo.statevo.countryvo;
  }
  return null;
};

export const getAddressAsText = (address) => {
  if (isNull(address)) {
    return "";
  }
  let finalAddress = "";
  let addressLine1 = address.line1;
  if (addressLine1 && addressLine1.length > 0) {
    finalAddress = addressLine1.toString().trim() + ", ";
  }
  let addressLine2 = address.line2;
  if (addressLine2 && addressLine2.length > 0) {
    finalAddress = finalAddress + addressLine2.toString().trim() + ", ";
  }
  let city = address.city;
  if (city && city.length > 0) {
    finalAddress = finalAddress + city.toString().trim() + ", ";
  }

  let state = address.statevo;
  if (state && state.name && state.name.toString().length > 0) {
    finalAddress = finalAddress + state.name.toString().trim() + ", ";
    if (
      state.countryvo &&
      state.countryvo.name &&
      state.countryvo.name.toString().length > 0
    ) {
      finalAddress =
        finalAddress + state.countryvo.name.toString().trim() + ", ";
    }
  } else {
    state = address.state;
    if (state && state.name && state.name.toString().length > 0) {
      finalAddress = finalAddress + state.name.toString().trim() + ", ";
      if (
        state.countryvo &&
        state.countryvo.name &&
        state.countryvo.name.toString().length > 0
      ) {
        finalAddress =
          finalAddress + state.countryvo.name.toString().trim() + ", ";
      }
    }
  }

  let pin = address.pin;
  if (pin && pin.length > 0) {
    finalAddress = finalAddress + pin.toString().trim();
  } else if (address.pincode && address.pincode > 0) {
    finalAddress = finalAddress + address.pincode.toString().trim();
  }

  return finalAddress;
};

export const getPercentAmount = (percent, amount) => {
  if (percent && amount && percent > 0 && amount > 0) {
    let val = parseFloat(percent) * parseFloat(amount);
    val = parseFloat(val) / 100;
    return parseFloat(val);
  } else {
    return 0.0;
  }
};

export const base64ToImage = (base64Data) => {
  let logoData = store.getState().appData.logoData;
  if (logoData) {
    return (
      <img
        src={"data:image/png;base64," + logoData}
        style={{ maxHeight: "2em" }}
        alt="logo"
      />
    );
  }
};

// export const ptsDateFormat = (date) => {
//   if (date) {
//     return moment(date).format("DD/MM/YYYY");
//   } else {
//     return " ";
//   }
// };

export const toCamelCaseUL = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const ptsDateFormat = (dateTime) => {
  if (dateTime === null) {
    return "";
  }
  let date = new Date(dateTime);

  let localDateTimeFormate = moment(date).format("DD MMM YYYY");

  return localDateTimeFormate;
};

const getUtcToLocalDateTime = (dateTime) => {
  if (dateTime === null) {
    return "";
  }
  let date = new Date(dateTime);

  let localDateTimeFormate = moment(date).format("MMM DD, YYYY  - hh:mm a");

  return localDateTimeFormate;
};

export const getRolePermission = (privilegeList) => {
  let allPermission = false;
  let createPermission = false;
  let readPermission = false;
  let updatePermission = false;
  let deletePermission = false;
  if (privilegeList.length > 0) {
    for (let i = 0; i < privilegeList.length; i++) {
      switch (privilegeList[i].toLowerCase()) {
        case "*":
          allPermission = true;
          createPermission = true;
          readPermission = true;
          updatePermission = true;
          deletePermission = true;
          break;
        case "create":
          createPermission = true;
          readPermission = true;
          break;
        case "update":
          updatePermission = true;
          readPermission = true;
          break;
        case "delete":
          deletePermission = true;
          readPermission = true;
          break;
        default:
          break;
      }
    }
  }
  return [
    allPermission,
    createPermission,
    readPermission,
    updatePermission,
    deletePermission,
  ];
};

export const communizeTableText = () => {
  let emptyRecordText = "";

  emptyRecordText = "Please click  +  to get started";
  return emptyRecordText;
};

export const materialTheme = () =>
  createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#1B7189",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "white",
          color: "#008181",
        },
      },
    },
  });
export const getRowLength = (characterLength) => {
  let totalCharacterPerLine = 25;

  let totalRow = Math.floor(characterLength / totalCharacterPerLine);
  let remainder = characterLength % totalCharacterPerLine;
  if (remainder > 0) {
    totalRow++;
  }

  return totalRow;
};
export const getViewDocuments = (fileExtension) => {
  return (
    <Badge color="primary" overlap="rectangular">
      {(fileExtension === "pdf" ||
        fileExtension === "png" ||
        fileExtension === "doc" ||
        fileExtension === "jpeg") &&
        <ReceiptLongIcon
          className="message"
          style={{ fontSize: "25px", alignItems: "center", color: "#1b7189" }}
        />
      }
      {(fileExtension === "pptx") &&
        <i className="fa fa-file-powerpoint-o ms-1" aria-hidden="true"
          style={{ fontSize: "22px", alignItems: "center", color: "#dc4c4c" }}
        ></i>
      }
      {(fileExtension === "docx") &&
        <i className="fa fa-file-word-o ms-1" aria-hidden="true"
          style={{ fontSize: "22px", alignItems: "center", color: "#589cb4" }}
        ></i>
      }
      {(fileExtension === "xlsx") &&
        <i className="fa fa-file-excel-o ms-1" aria-hidden="true"
          style={{ fontSize: "22px", alignItems: "center", color: "green" }}
        ></i>
      }
      {(fileExtension === "zip") &&
        <i className="fa fa-file-archive-o ms-1" aria-hidden="true"
          style={{ fontSize: "22px", alignItems: "center", backgroundColor: "#FFE9A2" }}
        ></i>
      }
    </Badge>
  )
};
export const materialTablePageSize = () => {
  let pageSize = [5, 10, 20, 50, 100];

  return pageSize;
};

export const indianNumberSystemForLable = (val) => {
  let negval;
  if (val < 0) {
    negval = val;
    if (negval <= -10000000) {
      negval = (negval / 10000000).toFixed(0) + " Cr";
    } else if (negval <= -100000) {
      negval = (negval / 100000).toFixed(0) + " Lac";
    } else if (negval <= -1000) {
      negval = (negval / 1000).toFixed(0) + " K";
    }
  } else {
    negval = val;
    if (negval >= 10000000) {
      negval = (negval / 10000000).toFixed(0) + " Cr";
    } else if (negval >= 100000) {
      negval = (negval / 100000).toFixed(0) + " Lac";
    } else if (negval >= 1000) {
      negval = (negval / 1000).toFixed(0) + " K";
    }
  }
  return negval;
};

export const internationalNumberSystemForLable = (val) => {
  let negval;
  if (val < 0) {
    negval = val;
    if (negval <= -100000000000) {
      negval = (negval / 100000000000).toFixed(0) + " B";
    } else if (negval <= -1000000) {
      negval = (negval / 1000000).toFixed(0) + " M";
    } else if (negval <= -1000) {
      negval = (negval / 1000).toFixed(0) + " TH";
    }
  } else {
    negval = val;
    if (negval >= 100000000000) {
      negval = (negval / 100000000000).toFixed(0) + "  B";
    } else if (negval >= 1000000) {
      negval = (negval / 1000000).toFixed(0) + " M";
    } else if (negval >= 1000) {
      negval = (negval / 1000).toFixed(0) + " TH";
    }
  }
  return negval;
};
export const indianNumberSystemForTooltip = (val) => {
  let negval;
  if (val < 0) {
    negval = val;
    if (negval <= -10000000) {
      negval = (negval / 10000000).toFixed(0) + " Cr";
    } else if (negval <= -100000) {
      negval = (negval / 100000).toFixed(0) + " Lac";
    } else if (negval <= -1000) {
      negval = (negval / 1000).toFixed(0) + " K";
    }
  } else {
    negval = val;
    if (negval >= 10000000) {
      negval = (negval / 10000000).toFixed(0) + " Cr";
    } else if (negval >= 100000) {
      negval = (negval / 100000).toFixed(0) + " Lac";
    } else if (negval >= 1000) {
      negval = (negval / 1000).toFixed(0) + " K";
    }
  }
  return negval;
};

export const internationalNumberSystemForTooltip = (val) => {
  let negval;
  if (val < 0) {
    negval = val;
    if (negval <= -100000000000) {
      negval = (negval / 100000000000).toFixed(0) + " B";
    } else if (negval <= -1000000) {
      negval = (negval / 1000000).toFixed(0) + " M";
    } else if (negval <= -1000) {
      negval = (negval / 1000).toFixed(0) + " TH";
    }
  } else {
    negval = val;
    if (negval >= 100000000000) {
      negval = (negval / 100000000000).toFixed(0) + "  B";
    } else if (negval >= 1000000) {
      negval = (negval / 1000000).toFixed(0) + " M";
    } else if (negval >= 1000) {
      negval = (negval / 1000).toFixed(0) + " TH";
    }
  }
  return negval;
};
export default {
  base64ToImage,
  getPercentAmount,
  getMenu,
  getTopMenuWithPageID,
  ptsDateFormat,
  getAddressAsText,
  getOrgName,
  getOrgCountry,
  getLogo,
  getFloatValue,
  getParseFloatValue,
  isIgstApplicable,
  getMenuHierarchy,
  getSubMenus,
  getTopMenus,
  getAmountInWords,
  hasPrivilege,
  isSupportedCurrency,
  equalsIgnoreCase,
  isNull,
  isNullOrEmpty,
  isNotNull,
  isNotNullAndEmpty,
  getMonth,
  getMonthNumber,
  getMonthList,
  getQuarterList,
  quarterList,
  getQuarterForMonth,
  isExchangeRateApplicableForCountry,
  isExchangeRateApplicableForCurrency,
  isExchangeRateApplicableForCustomer,
  isExchangeRateApplicableForSupplier,
  toCamelCaseUL,
  getUtcToLocalDateTime,
  getRolePermission,
  communizeTableText,
  materialTheme,
  getRowLength,
  materialTablePageSize,
  indianNumberSystemForLable,
  internationalNumberSystemForLable,
  indianNumberSystemForTooltip,
  internationalNumberSystemForTooltip,
  getRoundValue,
  getViewDocuments,
};
