import { GET_ORGANIZATION, SET_ORGANIZATION } from './OrganizationDataTypes';

export const getOrganization = () => {
    return {
        type: GET_ORGANIZATION,
    };
};

export const setOrganization = data => {
    return {
        type: SET_ORGANIZATION,
        payload: data,
    };
};
const OrganizationDataAction = {
    getOrganization,
    setOrganization,
}
export default OrganizationDataAction;
