import { SET_MASTER, GET_MASTER } from './masterDataTypes';
const initialState = {
  masterData: {},
  // customerList: {},
  // supplierList: {},

}

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MASTER:
      return {
        ...state,
      };
    case SET_MASTER:
      return {
        ...state,
        masterData: action.payload,
        // customerList: action.payload.customerList,
        // supplierList: action.payload.supplierList,
      };
    default:
      return state;
  }
};

export default masterDataReducer;
