import React, { Component } from "react";
import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import store from "../../../../redux/store";
import FetchServerData from "../../../../provider/FetchServerData";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
import Utils from "../../../../provider/Utils";
import BackIconButton from "../../buttons/BackIconButton";
class ExpenseSupplier extends Component {
  header = store.getState().header.header;
  countryListArray = store.getState().staticData.countryList;

  constructor(props) {
    super(props);
    let expenseSupplierId = props.expenseSupplierId;
    let action = props.action;
    let pageId = props.pageId;
    let expenseData = props.expenseData;
    let expenseitems = [];
    let amount = 0;
    let amountinwords = "";
    let defaultCountry =
      this.countryListArray[
        this.countryListArray.findIndex(
          (i) => i.code.toLowerCase() === "ind".toLowerCase()
        )
      ];
    if (
      !expenseSupplierId &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      expenseSupplierId = props.history.location.state.expenseSupplierId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      expenseitems = props.history.location.state.expenseitems;
      amountinwords = props.history.location.state.amountinwords;
      amount = props.history.location.state.amount;
      expenseData = props.history.location.state.expenseData;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }
    let canRender = false;
    if (expenseSupplierId && expenseSupplierId > 0) {
      this.getExpenseSupplierDetails(expenseSupplierId);
    } else {
      canRender = true;
    }
    this.state = {
      expenseSupplierId: expenseSupplierId,
      expenseData: expenseData,
      action: action,
      pageId: pageId,
      expenseitems: expenseitems,
      amount: amount,
      amountinwords: amountinwords,
      supplierName: "",
      gstn: "",
      country: defaultCountry,
      pageSize: 10,
      initialStep: 0,
      render: canRender,
    };
  }
  getExpenseSupplierDetails(expenseSupplierId) {
    const header = store.getState().header.header;
    const postObject = {
      expenseSupplier: {
        id: expenseSupplierId,
      },
      header: header,
    };
    FetchServerData.callPostService("/expenseSupplierms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let expenseSupplierDetails = output.data.expenseSupplier;
          let privileges = output.data.privileges;
          this.setState({
            supplierName: expenseSupplierDetails.name,
            country: expenseSupplierDetails.country,
            privileges: privileges,
            render: true,
          });
          this.getMenuIconsAsPerStatusAndPermission();
        } else {
        }
      }
    );
  }

  handleSupplierChange(e) {
    this.setState({
      supplierName: e.target.value,
    });
  }

  handleCountryChange(selectedCountry) {
    this.setState({
      country: selectedCountry,
    });
  }

  createSupplierInBackend = () => {
    const postObject = {
      expenseSupplier: {
        name: this.state.supplierName,
        country: this.state.country,
      },
      header: this.header,
    };

    FetchServerData.callPostService(
      "/expenseSupplierms/create",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseSupplier = output.data.expenseSupplier;
        let expenseSupplierMasterState = {
          expenseSupplierId: expenseSupplier.id,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
        };
        store.dispatch(
          ApplicationStateAction.setApplicationState({
            expenseSupplierMasterState,
          })
        );
        {
          this.state.pageId === "expense"
            ? this.props.history.push({
                pathname: "/expenseCreate",
                state: {
                  action: "create",
                  pageId: "expenseSupplier",
                  expense: expenseSupplier,
                  expenseData: this.state.expenseData,
                  expenseitems: this.state.expenseitems,
                  amount: this.state.amount,
                  amountinwords: this.state.amountinwords,
                },
              })
            : this.props.history.push({
                pathname: "/exsupplierview",
                state: {
                  expenseSupplierId: expenseSupplier.id,
                  action: "view",
                  breadCrumb: this.breadCrumb,
                },
              });
        }
      } else {
      }
    });
  };
  cancelView() {
    this.props.history.push({ pathname: "/expensesupplier" });
  }

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];
    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }

    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"];
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"];
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteExpensSupplierDetails.bind(
          this,
          this.state.expenseSupplierId
        );
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        text: "Do you want to delete?",
      });
    }
  }

  editButtonClicked() {
    this.props.history.push({
      pathname: "/exsupplieredit",
      state: {
        expenseSupplierId: this.state.expenseSupplierId,
        action: "edit",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  updateSupplierInBackend = () => {
    const postObject = {
      expenseSupplier: {
        id: this.state.expenseSupplierId,
        name: this.state.supplierName,
        country: this.state.country,
      },
      header: this.header,
    };

    FetchServerData.callPostService(
      "/expenseSupplierms/update",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseSupplierDetails = output.data.expenseSupplier;
        this.props.history.push({
          pathname: "/exsupplierview",
          state: {
            expenseSupplierId: expenseSupplierDetails.id,
            action: "view",
            breadCrumb: this.breadCrumb,
          },
        });
      } else {
      }
    });
  };

  deleteExpensSupplierDetails = (id) => {
    const postObject = {
      expenseSupplier: {
        id: id,
      },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/expenseSupplierms/delete",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let supplier = output.data;
        this.props.history.push({
          pathname: "/expensesupplier",
          state: { supplierId: supplier.id, action: "view" },
        });
      } else {
      }
    });
  };

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.menuWithBreadScrumRef = React.createRef();
    if (this.state.action === "create") {
      return this.createExpenseSupplier();
    }
    if (this.state.action === "edit") {
      return this.editExpenseSupplier();
    } else {
      return this.viewExpenseSupplier();
    }
  }

  createExpenseSupplier() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Create Expense Supplier"
          history={this.props.history}
          name="Create Expense Supplier"
          domainObject="Create Supplier"
        />
        <div style={{ padding: "1em", width: "100%" }} className="supplier_gst">
          <ValidatorForm
            ref="form"
            onSubmit={this.createSupplierInBackend.bind(this)}
          >
            <Row>
              <Col
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1em",
                }}
              >
                <Card style={{ flexGrow: "1" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Expense Supplier Details</strong>
                    </label>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          Supplier Name<span className="asterisk">*</span>
                        </label>
                        <TextField
                          variant="outlined"
                          required
                          InputLabelProps={{ shrink: true, style: {} }}
                          fullWidth
                          id="supplier"
                          type="text"
                          placeholder="Supplier Name"
                          onChange={this.handleSupplierChange.bind(this)}
                          value={this.state.supplierName}
                          name="supplier"
                        />
                      </Col>
                      {/* <Col md={4} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          GSTN<span className="asterisk">*</span>
                        </label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="gstn"
                          type="text"
                          placeholder="GSTN"
                          validators={["required"]}
                          errorMessages={["GSTN is required"]}
                          onChange={this.handleGstnChange.bind(this)}
                          value={this.state.gstn}
                          name="gstn"
                        />
                      </Col> */}
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Country<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          size="small"
                          id="country"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          options={this.countryListArray}
                          value={this.state.country}
                          defaultValue={this.state.select}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCountryChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Country *"
                              variant="outlined"
                              required
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row md={12}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      style={{ backgroundColor: "#1a7088", color: "#fff" }}
                      variant="contained"
                    >
                      Create
                    </Button>
                    &nbsp; &nbsp;
                    <NavLink
                      style={{ backgroundColor: "#777777", color: "#fff" }}
                      to={
                        this.state.pageId === "expense"
                          ? {
                              pathname: "/expenseCreate/",
                              state: {
                                action: "create",
                                pageId: "expenseSupplier",
                                expense: null,
                                expenseData: this.state.expenseData,
                                expenseitems: this.state.expenseitems,
                                amount: this.state.amount,
                                amountinwords: this.state.amountinwords,
                              },
                            }
                          : {
                              pathname: "/expensesupplier/",
                              state: {
                                breadCrumb: this.breadCrumb,
                              },
                            }
                      }
                      activeClassName="selectedLink"
                      strict
                    >
                      <Button
                        style={{ backgroundColor: "#777777", color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </NavLink>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }
  viewExpenseSupplier() {
    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="View Expense Supplier"
          history={this.props.history}
          name="View Expense Supplier"
          domainObject="View Expense Supplier"
          docTitle={this.state.name}
          backCallback={this.cancelView.bind(this)}
        />
        <Row md={12} style={{ margin: "auto" }}>
          <Col
            md={12}
            style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}
          >
            <Card style={{ padding: "1em" }}>
              <CardHeader className="cardHeaderStyle">
                <label className="cardHeaderTitle">
                  <strong>Expense Supplier Details</strong>
                </label>
              </CardHeader>
              {/* <hr></hr> */}
              <CardBody>
                <Row>
                  <Col md={4}>
                    <Row style={{ padding: "5px" }}>
                      <Col md={5}>
                        <label style={{ color: "#000" }}>
                          <strong>Supplier Name</strong>
                        </label>
                      </Col>
                      <Col md={7} style={{ color: "#000" }}>
                        {this.state.supplierName}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Row style={{ padding: "5px" }}>
                      <Col md={5}>
                        <label style={{ color: "#000" }}>
                          <strong>Country</strong>
                        </label>
                      </Col>
                      <Col md={7} style={{ color: "#000" }}>
                        {this.state.country?.name}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Row style={{ padding: "5px" }}>
                      <Col md={5}>
                        <label style={{ color: "#000" }}>
                          <strong>Currency</strong>
                        </label>
                      </Col>
                      <Col md={7} style={{ color: "#000" }}>
                        {`${this.state.country?.currency?.name} (${this.state.country?.currency?.symbol})`}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row md={12}>
          <Col>
            <CardFooter style={{ width: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <BackIconButton
                  variant="contained"
                  size="small"
                  style={{
                    background: "#717373",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  onClick={(e) => this.cancelView()}
                />
              </div>
            </CardFooter>
          </Col>
        </Row>
      </>
    );
  }
  editExpenseSupplier() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Edit Expense Supplier"
          history={this.props.history}
          name="Edit Expense Supplier"
          domainObject="Expense Supplier"
        />
        <div style={{ padding: "1em", width: "100%" }} className="supplier_gst">
          <ValidatorForm
            ref="form"
            onSubmit={this.updateSupplierInBackend.bind(this)}
          >
            <Row>
              <Col
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1em",
                }}
              >
                <Card style={{ flexGrow: "1" }}>
                  <CardHeader className="cardHeaderStyle">
                    <label className="cardHeaderTitle">
                      <strong>Expense Supplier Details</strong>
                    </label>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ alignItems: "flex-end", padding: "5px" }}>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          Supplier Name<span className="asterisk">*</span>
                        </label>
                        <TextField
                          variant="outlined"
                          required
                          InputLabelProps={{ shrink: true, style: {} }}
                          fullWidth
                          id="supplier"
                          type="text"
                          placeholder="Supplier Name"
                          onChange={this.handleSupplierChange.bind(this)}
                          value={this.state.supplierName}
                          name="supplier"
                        />
                      </Col>
                      <Col md={6} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Country<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          size="small"
                          id="country"
                          ListboxProps={{
                            className: "myCustomList",
                          }}
                          options={this.countryListArray}
                          value={this.state.country}
                          defaultValue={this.state.select}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCountryChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Country *"
                              variant="outlined"
                              required
                              className="regCountry"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row md={12}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      style={{ backgroundColor: "#1a7088", color: "#fff" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                    &nbsp; &nbsp;
                    <NavLink
                      style={{ backgroundColor: "#777777", color: "#fff" }}
                      to={{
                        pathname: "/expensesupplier/",
                        state: {
                          breadCrumb: this.breadCrumb,
                        },
                      }}
                      activeClassName="selectedLink"
                      strict
                    >
                      <Button
                        style={{ backgroundColor: "#777777", color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </NavLink>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }
}
export default ExpenseSupplier;
