import Tooltip from "@material-ui/core/Tooltip";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
export class PrintIconButton extends Component {
  constructor(props) {
    super(props);
    // let domainObject = props.domainObject ? props.domainObject : '';
    this.state = {
      domainObject: props.onClick,
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    // let tooltip = 'Print' + this.state.domainObject ? this.state.domainObject : '';
    return (
      <Tooltip title="Print">
        {/* <PrintRoundedIcon
            style={{
              borderRadius: "25%",
              background: "#867e7e",
              color: "white",
              padding: "2px",
              fontSize: "x-large",
            }}
          /> */}
        <Button
          className="add"
          onClick={this.onClick.bind(this)}
          // style={{
          //   background: "#246577",
          //   color: "white",
          //   fontSize: "x-large",
          //   textTransform: "capitalize",
          //   cursor: "pointer",
          //   fontSize: "x-larger",
          //   float: "right",
          //   // margin: "0.2em 0.5em 0.3em 0em",
          //   marginRight: "1rem",
          //   marginBottom: "1em",
          // }}
          style={{
            background: "#246577",
            color: "#fff",
            marginRight: "1rem",
            marginBottom: "1em",
            textTransform: "capitalize",
            float: "right",
            padding: "5px",
          }}
          startIcon={<PrintRoundedIcon />}
        >
          Print
        </Button>
      </Tooltip>
    );
  }
}
export default PrintIconButton;
