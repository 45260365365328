import React, { Component } from "react";
import { Buffer } from "buffer";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import store from "../../../../redux/store";
import FetchServerData from "../../../../provider/FetchServerData";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
// import EmailIcon from "@material-ui/icons/Email";
// import GetAppIcon from "@material-ui/icons/GetApp";
import { exportComponentAsJPEG } from "react-component-export-image";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { Col, Row, Card, CardBody } from "reactstrap";
import Utils from "../../../../provider/Utils";
import UiUtils from "../../../../provider/UiUtils";
import PtsAlert from "../../ptsAlert/PtsAlert";
export default class AllCustomers extends Component {
  header = store.getState().header.header;
  breadCrumb = [];
  title = "Customer List";
  org = store.getState().appData.settings.org;
  constructor(props) {
    super(props);
    this.imageDwlRef = React.createRef();
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      this.breadCrumb = props.history.location.state.breadCrumb;
    }
    this.state = {
      url: "",
      pdfdata: "",
      contentType: "application/pdf",
      render: false,
      allCustomerList: [],
    };

    this.getSupplierReportList();
  }

  getSupplierReportList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostServiceForPDFReportData(
      "/reports/getCustList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let allCustomerList = output.data.customerList;
        this.setState({ render: true, allCustomerList: allCustomerList });
      } else {
      }
    });
  }

  goToBack() {
    this.props.history.push({
      pathname: "/reports/",
    });
  }
  downloadImage() {
    let fileName = "Customer List";
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    if (this.imageDwlRef) {
      exportComponentAsJPEG(this.imageDwlRef, {
        fileName: fileName,
      });
    }
  }

  excelDownloadCustomerList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService(
      "/customerms/exportToExcel",
      postObject
    ).then((output) => {
      let CustomerExcelData = output.data;
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        const byteArray = new Buffer(
          CustomerExcelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );
        const outputFilename = CustomerExcelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
      }
    });
  }

  render() {
    let logoData = Utils.getLogo();
    this.printComponentRef = React.createRef();
    return (
      <>
        <MenuWithBreadScrum
          name={this.title}
          addToBreadCrumb={this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
        />
        <Row md={12} style={{ padding: "0.5em" }}>
          <Col md={6} style={{ textAlign: "start" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.goToBack(e)}
            >
              Back
            </Button>
          </Col>
          <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  startIcon={<PrintIcon />}
                  // onClick={(e) => this.printContent(e)}
                >
                  Print
                </Button>
              )}
              content={() => this.printComponentRef}
            />
            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#9a7031",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<GetAppIcon />}
              // onClick={() => exportComponentAsJPEG(this.imageDwlRef)}
              onClick={(e) => this.downloadImage(e)}
            >
              Download
            </Button> */}
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#5ac489",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={
                <i className="fa fa-download" style={{ fontSize: "16px" }}></i>
              }
              onClick={(e) => this.excelDownloadCustomerList()}
            >
              Download Excel
            </Button>
            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<EmailIcon />}
              // onClick={(e) => this.emailSalesVoucher(e)}
            >
              Email
            </Button> */}
          </Col>
        </Row>
        <div className="row" ref={(el) => (this.printComponentRef = el)}>
          <Card
            md={12}
            id="printform"
            // ref={this.printComponentRef}
            ref={(el) => (this.printComponentRef = el)}
            // className="a4size"
            style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
          >
            <div ref={this.imageDwlRef}>
              <CardBody style={{ color: "#000" }}>
                <Row
                  md={12}
                  style={{
                    margin: "0",
                    padding: "5px",
                    display: "flex",
                    borderLeft: "1px solid gray",
                    borderTop: "1px solid gray",
                    borderRight: "1px solid gray",
                  }}
                >
                  <Col md={2}>
                    {Utils.isNotNullAndEmpty(logoData) && (
                      <img
                        src={logoData}
                        alt=""
                        style={{
                          height: "3em",
                          width: "3em",
                          padding: "0.1em",
                        }}
                      />
                    )}
                  </Col>
                  <Col md={8}>
                    <Row>
                      <strong style={{ width: "100%", textAlign: "center" }}>
                        {Utils.getOrgName()}
                      </strong>
                    </Row>
                    <Row>
                      <span style={{ width: "100%", textAlign: "center" }}>
                        {Utils.getAddressAsText(this.org?.addressvo)}
                      </span>
                    </Row>
                  </Col>
                  <Col md={2}>
                    <span style={{ width: "100%", textAlign: "center" }}>
                      {/* {this.state.duplicateTriplicate} */}
                    </span>
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{
                    margin: "0",
                    padding: "5px",
                    display: "flex",
                    borderLeft: "1px solid gray",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                    borderRight: "1px solid gray",
                  }}
                >
                  <Col
                    md={12}
                    style={{ fontSize: "x-large", textAlign: "center" }}
                  >
                    <strong>
                      Customer List
                      {/* {this.state.invoiceheading} */}
                    </strong>
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{
                    margin: "0",
                    padding: "0",
                    borderRight: "1px solid gray",
                    borderLeft: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                  id="supplierReport"
                >
                  <MaterialTable
                    className="supplierReport"
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      {
                        title: "#",
                        render: (rowData) => rowData.tableData.id + 1,
                        align: "left",
                        filtering: false,
                        cellStyle: {
                          width: "5%",
                        },
                      },
                      {
                        title: "Name",
                        field: "name",
                        filtering: false,
                        cellStyle: {
                          width: "25%",
                          wordBreak: "break-word",
                        },
                      },
                      {
                        title: "Address",
                        // field: "address",
                        render: (rowData) => (
                          <>
                            <p>{rowData.address}</p>{" "}
                            {rowData.email ? (
                              <p>
                                {rowData.email
                                  ? "Email-" + " " + rowData.email
                                  : ""}
                              </p>
                            ) : (
                              <></>
                            )}
                          </>
                        ),
                        filtering: false,
                        cellStyle: {
                          width: "45%",
                        },
                      },
                      {
                        title: "GSTN",
                        field: "gstn",
                        filtering: false,
                        cellStyle: {
                          width: "15%",
                        },
                      },
                      {
                        title: "Phone",
                        field: "phone",
                        filtering: false,
                        cellStyle: {
                          width: "15%",
                        },
                      },
                      // {
                      //   title: "Email",
                      //   field: "email",
                      //   filtering: false,
                      //   cellStyle: {
                      //     width: "20%",
                      //   },
                      // },
                    ]}
                    data={this.state.allCustomerList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      search: false,
                      paging: false,
                      toolbar: false,
                      headerStyle: {
                        backgroundColor: "#ececec",
                        fontWeight: "bold",

                        color: "#000",
                        align: "left",
                        textAlign: "left",
                      },

                      rowStyle: (rowData) => ({
                        height: "3em",
                        // textTransform: "capitalize",
                      }),
                    }}
                    actions={[]}
                  />
                </Row>
              </CardBody>
            </div>
          </Card>
        </div>
      </>
    );
  }
}
