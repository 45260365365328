import React from "react";
// import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ApplicationLayout from "./layouts/ApplicationLayout";
import LoginLayout from "./layouts/LoginLayout";
import { WebsiteLayout } from "./layouts/WebsiteLayout";
import Dashboard from "./pts/components/dashboard/Dashboard";
import Home from "./pts/components/home/Home";
import { Login } from "./pts/components/login/Login";
import PersonalRegistrationDetails from "./pts/components/registration/PersonalRegistrationDetails";
import RegistrationTandC from "./pts/components/registration/RegistrationTandC";
import "./App.css";
import "./pts/scss/styles.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./redux/store";
import { Provider } from "react-redux";
// import LoginWithEmail from "./pts/components/login/LoginWithEmail";
import ConfirmationEmail from "./pts/components/registration/confirmationEmail/ConfirmationEmail";
import VerifyOtp from "./pts/components/registration/verifyOtp/VerifyOtp";
import { ForgotPasswordEmail } from "./pts/components/forgotPassword/ForgotPasswordEmail";
import { ForgotPassword } from "./pts/components/forgotPassword/ForgotPassword";
import { ResetPassword } from "./pts/components/forgotPassword/resetPassword/ResetPassword";
import { ResetPasswordViaEmail } from "./pts/components/forgotPassword/resetPassword/ResetPasswordViaEmail";
import TermsConditions from "./pts/components/home/termsConditions/TermsConditions";
import PrivacyPolicy from "./pts/components/home/privacyPolicy/PrivacyPolicy";
import AdminLogin from "./pts/adminPanel/adminLogin/AdminLogin";
import AdminDashboard from "./pts/adminPanel/adminDashboard/AdminDashboard";
import AdminForgotPassword from "./pts/adminPanel/adminForgotPassword/AdminForgotPassword";
import AdminResetPassword from "./pts/adminPanel/adminForgotPassword/adminResetPassword/AdminResetPassword";
import AdminResetPasswordViaEmail from "./pts/adminPanel/adminForgotPassword/adminResetPassword/AdminResetPasswordViaEmail";
import AdminLayout from "./layouts/adminLayout/AdminLayout";
import Master from "./pts/components/master/Master";
import ProductServiceList from "./pts/components/master/productServices/ProductServiceList";
import ProductServices from "./pts/components/master/productServices/ProductServices";
import { ServiceAccountingCode } from "./pts/components/master/serviceAccountingCodes/ServiceAccountingCode";
import { GoodsHsnCode } from "./pts/components/master/goodsHsnCode/GoodsHsnCode";
import Customer from "./pts/components/master/customer/Customer";
import CustomerList from "./pts/components/master/customer/CustomerList";
import SupplierList from "./pts/components/master/supplier/SupplierList";
import Supplier from "./pts/components/master/supplier/Supplier";
import SalesVoucherList from "./pts/components/sales/salesVoucher/SalesVoucherList";
import Sales from "./pts/components/sales/Sales";
import SalesVoucher from "./pts/components/sales/salesVoucher/SalesVoucher";
import SalesVoucherPrintPreview from "./pts/components/sales/salesVoucher/SalesVoucherPrintPreview";
import AdvanceVoucherList from "./pts/components/sales/advanceVoucher/AdvanceVoucherList";
import AdvanceVoucher from "./pts/components/sales/advanceVoucher/AdvanceVoucher";
import AdvanceVoucherPrintPreview from "./pts/components/sales/advanceVoucher/AdvanceVoucherPrintPreview";
import DebitNoteList from "./pts/components/sales/debitNote/DebitNoteList";
import DebitNote from "./pts/components/sales/debitNote/DebitNote";
import DebitNotePrintPreview from "./pts/components/sales/debitNote/DebitNotePrintPreview";
import CreditNoteList from "./pts/components/sales/creditNote/CreditNoteList";
import CreditNote from "./pts/components/sales/creditNote/CreditNote";
import CreditNotePrintPreview from "./pts/components/sales/creditNote/CreditNotePrintPreview";
import RefundVoucherList from "./pts/components/sales/refundVoucher/RefundVoucherList";
import RefundVoucher from "./pts/components/sales/refundVoucher/RefundVoucher";
import RefundVoucherPrintPreview from "./pts/components/sales/refundVoucher/RefundVoucherPrintPreview";
import Purchase from "./pts/components/purchase/Purchase";
import PurchaseOrderList from "./pts/components/purchase/purchaseOrder/PurchaseOrderList";
import PurchaseOrder from "./pts/components/purchase/purchaseOrder/PurchaseOrder";
import PurchaseVoucherList from "./pts/components/purchase/purchaseVoucher/PurchaseVoucherList";
import PurchaseVoucher from "./pts/components/purchase/purchaseVoucher/PurchaseVoucher";
import PurchaseVoucherPrintPreview from "./pts/components/purchase/purchaseVoucher/PurchaseVoucherPrintPreview";
import PurchaseAdvanceVoucher from "./pts/components/purchase/advanceVoucher/PurchaseAdvanceVoucher";
import PurchaseAdvanceVoucherPrintPreview from "./pts/components/purchase/advanceVoucher/PurchaseAdvanceVoucherPrintPreview";
import PurchaseAdvanceVoucherList from "./pts/components/purchase/advanceVoucher/PurchaseAdvanceVoucherList";
import PurchaseCreditNoteList from "./pts/components/purchase/creditNote/PurchaseCreditNoteList";
import PurchaseCreditNote from "./pts/components/purchase/creditNote/PurchaseCreditNote";
import PurchaseCreditNotePrintPreview from "./pts/components/purchase/creditNote/PurchaseCreditNotePrintPreview";
import PurchaseDebitNote from "./pts/components/purchase/debitNote/PurchaseDebitNote";
import PurchaseDebitNotePrintPreview from "./pts/components/purchase/debitNote/PurchaseDebitNotePrintPreview";
import PurchaseDebitNoteList from "./pts/components/purchase/debitNote/PurchaseDebitNoteList";
import PurchaseRefundList from "./pts/components/purchase/refund/PurchaseRefundList";
import PurchaseRefund from "./pts/components/purchase/refund/PurchaseRefund";
import PurchaseRefundPrintPreview from "./pts/components/purchase/refund/PurchaseRefundPrintPreview";
import RcmVoucherList from "./pts/components/purchase/rcmVoucher/RcmVoucherList";
import RcmVoucher from "./pts/components/purchase/rcmVoucher/RcmVoucher";
import RcmVoucherPrintPreview from "./pts/components/purchase/rcmVoucher/RcmVoucherPrintPreview";
import GoodsServiceImportVoucherList from "./pts/components/purchase/goodsServiceImportVoucher/GoodsServiceImportVoucherList";
import GoodsServiceImportVoucher from "./pts/components/purchase/goodsServiceImportVoucher/GoodsServiceImportVoucher";
import RcmPaymentVoucherList from "./pts/components/purchase/rcmPaymentVoucher/RcmPaymentVoucherList";
import RcmPaymentVoucher from "./pts/components/purchase/rcmPaymentVoucher/RcmPaymentVoucher";
import RcmPaymentVoucherPrintPreview from "./pts/components/purchase/rcmPaymentVoucher/RcmPaymentVoucherPrintPreview";
import ExpenseList from "./pts/components/expense/ExpenseList";
import Expense from "./pts/components/expense/ExpenseItem";
import ExpensePrintPreview from "./pts/components/expense/ExpensePrintPreview";
import SalesHistory from "./pts/components/history/SalesHistory/SalesHistory";
import PurchaseHistory from "./pts/components/history/purchaseHistory/PurchaseHistory";
import ExpenseHistory from "./pts/components/history/expenseHistory/ExpenseHistory";
import { UserManagement } from "./pts/components/userManagement/UserManagement";
import User from "./pts/components/userManagement/User";
import FinancialAnaylsis from "./pts/components/financialAnalysis/FinancialAnalysis";
import MonthlyFinancial from "./pts/components/financialAnalysis/monthlyFinancial/MonthlyFinancial";
import AnnualFinancial from "./pts/components/financialAnalysis/annualFinancial/AnnualFinacial";
import QuarterlyFinancial from "./pts/components/financialAnalysis/quarterlyFinancial/QuarterlyFinancial";
import Filing from "./pts/components/filing/Filing";
import FilingHistory from "./pts/components/filing/general/FilingHistory";
import GstUserList from "./pts/components/filing/general/GstUserList";
import GstUser from "./pts/components/filing/general/GstUser";
import GstR1List from "./pts/components/filing/gstr1/GstR1List";
import GstR1 from "./pts/components/filing/gstr1/GstR1";
import GSTRBList from "./pts/components/filing/gstr3/GstRBList";
import GSTRB from "./pts/components/filing/gstr3/GstRB";
import GSTRBDetails from "./pts/components/filing/gstr3/GSTRBDetails";
import Reports from "./pts/components/reports/Reports";
import AllCustomers from "./pts/components/reports/sales/AllCustomers";
import CustomerWiseReport from "./pts/components/reports/sales/CustomerWiseReport";
import CustomerWiseInvoice from "./pts/components/reports/sales/CustomerWiseInvoice";
import AllSuppliers from "./pts/components/reports/purchase/AllSuppliers";
import SupplierWiseReport from "./pts/components/reports/purchase/SupplierWiseReport";
import SupplierWiseInvoice from "./pts/components/reports/purchase/SupplierWiseInvoice";
import NonGstReport from "./pts/components/reports/expense/NonGstReport";
import QuaterlyReport from "./pts/components/reports/finance/QuaterlyReport";
import MonthlyReport from "./pts/components/reports/finance/MonthlyReport";
import YearlyReport from "./pts/components/reports/finance/YearlyReport";
import Import from "./pts/components/import/Import";
import ImportSupplierList from "./pts/components/import/supplier/ImportSupplierList";
import ImportSupplier from "./pts/components/import/supplier/ImportSupplier";
import ImportCustomerList from "./pts/components/import/customerImport/ImportCustomerList";
import ImportCustomer from "./pts/components/import/customerImport/ImportCustomer";
import ImportSalesInvoicesList from "./pts/components/import/sales/ImportSalesInvoicesList";
import ImportPurchaseVoucher from "./pts/components/import/purchaseImport/ImportPurchaseVoucher";
import ImportPurchaseInvoicesList from "./pts/components/import/purchaseImport/ImportPurchaseInvoicesList";
import ImportSalesVoucher from "./pts/components/import/sales/ImportSalesVoucher";
import ImportProductServicesList from "./pts/components/import/productServices/ImportProductServicesList";
import ImportProductServices from "./pts/components/import/productServices/ImportProductServices";
import Settings from "./pts/components/settings/Settings";
import GeneralSettings from "./pts/components/settings/GeneralSettings";
import AdminRegistrationList from "./pts/adminPanel/adminRegistration/AdminRegistrationList";
import AdminAppUserList from "./pts/adminPanel/adminAppUser/AdminAppUserList";
import AdminAppUser from "./pts/adminPanel/adminAppUser/AdminAppUser";
import AdminProfileManagementList from "./pts/adminPanel/adminProfileManagement/AdminProfileManagementList";
import AdminProfileManagement from "./pts/adminPanel/adminProfileManagement/AdminProfileManagement";
import { Profile } from "./pts/components/profile/Profile";
import EmailTemplateList from "./pts/adminPanel/emailTemplate/EmailTemplateList";
import EmailTemplate from "./pts/adminPanel/emailTemplate/EmailTemplate";
import AdminProfile from "./pts/adminPanel/adminProfile/AdminProfile";
import AdminRegistration from "./pts/adminPanel/adminRegistration/AdminRegistration";
import SupplierInvoice from "./pts/components/master/supplier/SupplierInvoice";
import PtsAboutUs from "./pts/components/ptsAboutUs/PtsAboutUs";
import PurchaseOrderPrintPreview from "./pts/components/purchase/purchaseOrder/PurchaseOrderPrintPreview";
import PrivacyPolicyTemplateList from "./pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplateList";
import PrivacyPolicyTemplate from "./pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplate";
import TermsConditionTemplate from "./pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplate";
import TermsConditionTemplateList from "./pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplateList";
import TermsAndCondition from "./pts/components/termsAndCondition/TermsAndCondition";
import InwardLedger from "./pts/components/ledgerManagement/inwardLedger/InwardLedger";
import LedgerManagement from "./pts/components/ledgerManagement/LedgerManagement";
import OutwardLedger from "./pts/components/ledgerManagement/outwardLedger/OutwardLedger";
import DocumentReceipt from "./pts/components/documentReceipt/DocumentReceipt";
import InwardLedgerPrintPreview from "./pts/components/ledgerManagement/inwardLedger/InwardLedgerPrintPreview";
import PurchaseLedgerPrintPreview from "./pts/components/ledgerManagement/outwardLedger/PurchaseLedgerPrintPreview";
import ExpenseLedgerPrintPriview from "./pts/components/ledgerManagement/outwardLedger/ExpenseLedgerPrintPriview";
import { AdminServiceAccountingCodeList } from "./pts/adminPanel/adminServiceAccountingCode/AdminServiceAccountingCodeList";
import { AdminGoodHsnCodeList } from "./pts/adminPanel/adminGoodHsnCodes/AdminGoodHsnCodeList";
import AdminGoodHsnCode from "./pts/adminPanel/adminGoodHsnCodes/AdminGoodHsnCode";
import AdminServiceAccountingCode from "./pts/adminPanel/adminServiceAccountingCode/AdminServiceAccountingCode";
import VerifyOtpViaEmail from "./pts/components/registration/verifyOtp/VerifyOtpViaEmail.js";
import ProductList from "./pts/components/reports/product/ProductList.js";
import ProductWiseSalesReport from "./pts/components/reports/product/ProductWiseSalesReport.js";
import ProductWisePurchseReport from "./pts/components/reports/product/ProductWisePurchseReport.js";
import ExpenseSupplierList from "./pts/components/master/expenseSupplier/ExpenseSupplierList.js";
import ExpenseSupplier from "./pts/components/master/expenseSupplier/ExpenseSupplier.js";
// import CardDetails from "./pts/components/registration/CardDetails";
// import RegistrationOrganization from "./pts/components/registration/RegistrationOrganization";
// import { Subscription } from "./pts/components/registration/Subscription";

// import "./pts/scss/styles.scss";
// import store from "./redux/store";

export default function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer
          style={{
            alignItems: "center",
            fontSize: "small",
            width: "auto",
            maxHeight: "1em",
          }}
          position="top-right"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <ToastContainer
        style={{
          alignItems: "center",
          fontSize: "small",
          width: "auto",
          maxHeight: "1em",
        }}
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
        {/* <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <LoginLayoutRoute path="/login" component={Login} />
          <WebsiteLayout path="/regtandc" component={RegistrationTandC} />
          <WebsiteLayout
            path="/personalDetailsReg"
            component={PersonalRegistrationDetails}
          />
          <ApplicationLayout path="/dashboard" component={Dashboard} />
          <Route component={ApplicationLayout} />

          <WebsiteLayout path="/home" component={Home} />
        </Switch>
      </BrowserRouter> */}
        {/* // </Provider> */}
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <LoginLayout path="/login" component={Login} />
            {/* <LoginLayout path="/login" component={LoginWithEmail} /> */}
            <LoginLayout
              path="/confirmationEmail"
              component={ConfirmationEmail}
            />
            <LoginLayout path="/verifyOtp" component={VerifyOtp} />
            <LoginLayout
              path="/verifyOtpViaEmail"
              component={VerifyOtpViaEmail}
            />
            <LoginLayout
              path="/forgotPasswordEmail"
              component={ForgotPasswordEmail}
            />
            <LoginLayout path="/forgotPassword" component={ForgotPassword} />
            <LoginLayout path="/resetPassword" component={ResetPassword} />
            <LoginLayout
              path="/resetPasswordViaEmail"
              component={ResetPasswordViaEmail}
            />
            <LoginLayout path="/ApLogin" component={AdminLogin} />
            <LoginLayout
              path="/ApForgotPassword"
              component={AdminForgotPassword}
            />
            <LoginLayout
              path="/ApResetPassword"
              component={AdminResetPassword}
            />
            <LoginLayout
              path="/ApResetPasswordViaEmail"
              component={AdminResetPasswordViaEmail}
            />
            {/* <LoginLayout path="/carddetails" component={CardDetails} /> */}
            <WebsiteLayout path="/home" component={Home} />
            <WebsiteLayout path="/regtandc" component={RegistrationTandC} />
            <WebsiteLayout
              path="/personalDetailsReg"
              component={PersonalRegistrationDetails}
            />
            <WebsiteLayout path="/termsConditions" element={TermsConditions} />
            <WebsiteLayout path="/privacy" element={PrivacyPolicy} />
            <WebsiteLayout path="/aboutUs" component={PtsAboutUs} />
            <WebsiteLayout path="/viewReceipt" component={DocumentReceipt} />
            <ApplicationLayout
              path="/termsCondition"
              element={TermsAndCondition}
            />
            <ApplicationLayout path="/dashboard" component={Dashboard} />
            <ApplicationLayout path="/master" component={Master} />
            <ApplicationLayout path="/cust" component={CustomerList} />
            <ApplicationLayout path="/customer" component={Customer} />
            <ApplicationLayout path="/customerview" component={Customer} />
            <ApplicationLayout path="/customeredit" component={Customer} />
            <ApplicationLayout path="/supp" component={SupplierList} />
            <ApplicationLayout path="/supplier" component={Supplier} />
            <ApplicationLayout path="/supplierview" component={Supplier} />
            <ApplicationLayout path="/supplieredit" component={Supplier} />
            <ApplicationLayout
              path="/supplierinvoiceview"
              component={SupplierInvoice}
            />
            <ApplicationLayout
              path="/services"
              component={ServiceAccountingCode}
            />
            <ApplicationLayout path="/goods" component={GoodsHsnCode} />
            <ApplicationLayout
              path="/prodserv"
              component={ProductServiceList}
            />
            <ApplicationLayout path="/product" component={ProductServices} />
            <ApplicationLayout
              path="/productview"
              component={ProductServices}
            />
            <ApplicationLayout
              path="/productedit"
              component={ProductServices}
            />
            <ApplicationLayout
              path="/expensesupplier"
              component={ExpenseSupplierList}
            />
            <ApplicationLayout path="/exsupplier" component={ExpenseSupplier} />
            <ApplicationLayout
              path="/exsupplierview"
              component={ExpenseSupplier}
            />
            <ApplicationLayout
              path="/exsupplieredit"
              component={ExpenseSupplier}
            />
            <ApplicationLayout path="/sales" component={Sales} />
            <ApplicationLayout path="/sa" component={SalesVoucherList} />
            <ApplicationLayout
              path="/salesVoucherCreate"
              component={SalesVoucher}
            />
            <ApplicationLayout path="/salesVoucher" component={SalesVoucher} />
            <ApplicationLayout
              path="/salesVoucherEdit"
              component={SalesVoucher}
            />
            <ApplicationLayout
              path="/salesVoucherPrint"
              component={SalesVoucherPrintPreview}
            />
            <ApplicationLayout path="/re" component={AdvanceVoucherList} />
            <ApplicationLayout
              path="/receiptCreate"
              component={AdvanceVoucher}
            />
            <ApplicationLayout path="/receipt" component={AdvanceVoucher} />
            <ApplicationLayout path="/receiptEdit" component={AdvanceVoucher} />
            <ApplicationLayout
              path="/receiptPrint"
              component={AdvanceVoucherPrintPreview}
            />
            <ApplicationLayout path="/dn" component={DebitNoteList} />
            <ApplicationLayout path="/debitNoteCreate" component={DebitNote} />
            <ApplicationLayout path="/debitNote" component={DebitNote} />
            <ApplicationLayout path="/debitNoteEdit" component={DebitNote} />
            <ApplicationLayout
              path="/debitNotePrint"
              component={DebitNotePrintPreview}
            />
            <ApplicationLayout path="/cn" component={CreditNoteList} />
            <ApplicationLayout
              path="/creditNoteCreate"
              component={CreditNote}
            />
            <ApplicationLayout path="/creditNote" component={CreditNote} />
            <ApplicationLayout path="/creditNoteEdit" component={CreditNote} />
            <ApplicationLayout
              path="/creditNotePrint"
              component={CreditNotePrintPreview}
            />
            <ApplicationLayout path="/rf" component={RefundVoucherList} />
            <ApplicationLayout
              path="/refundVoucherCreate"
              component={RefundVoucher}
            />
            <ApplicationLayout
              path="/refundVoucher"
              component={RefundVoucher}
            />
            <ApplicationLayout
              path="/refundVoucherEdit"
              component={RefundVoucher}
            />
            <ApplicationLayout
              path="/refundVoucherPrint"
              component={RefundVoucherPrintPreview}
            />
            <ApplicationLayout path="/purchase" component={Purchase} />
            <ApplicationLayout path="/po" component={PurchaseOrderList} />
            <ApplicationLayout
              path="/purchaseOrderCreate"
              component={PurchaseOrder}
            />
            <ApplicationLayout
              path="/purchaseOrder"
              component={PurchaseOrder}
            />
            <ApplicationLayout
              path="/purchaseOrderEdit"
              component={PurchaseOrder}
            />
            <ApplicationLayout
              path="/purchaseOrderPrint"
              component={PurchaseOrderPrintPreview}
            />
            <ApplicationLayout
              path="/purchasesa"
              component={PurchaseVoucherList}
            />
            <ApplicationLayout
              path="/purchaseVoucher"
              component={PurchaseVoucher}
            />
            <ApplicationLayout
              path="/purchaseVoucherCreate"
              component={PurchaseVoucher}
            />
            <ApplicationLayout
              path="/purchaseVoucherEdit"
              component={PurchaseVoucher}
            />
            <ApplicationLayout
              path="/purchaseVoucherPrint"
              component={PurchaseVoucherPrintPreview}
            />
            <ApplicationLayout
              path="/purchasere"
              component={PurchaseAdvanceVoucherList}
            />
            <ApplicationLayout
              path="/purchaseReceipt"
              component={PurchaseAdvanceVoucher}
            />
            <ApplicationLayout
              path="/purchaseReceiptCreate"
              component={PurchaseAdvanceVoucher}
            />
            <ApplicationLayout
              path="/purchaseReceiptEdit"
              component={PurchaseAdvanceVoucher}
            />
            <ApplicationLayout
              path="/purchaseReceiptPrint"
              component={PurchaseAdvanceVoucherPrintPreview}
            />
            <ApplicationLayout
              path="/purchasecn"
              component={PurchaseCreditNoteList}
            />
            <ApplicationLayout
              path="/purchaseCreditNote"
              component={PurchaseCreditNote}
            />
            <ApplicationLayout
              path="/purchaseCreditNoteCreate"
              component={PurchaseCreditNote}
            />
            <ApplicationLayout
              path="/purchaseCreditNoteEdit"
              component={PurchaseCreditNote}
            />
            <ApplicationLayout
              path="/purchaseCreditNotePrint"
              component={PurchaseCreditNotePrintPreview}
            />
            <ApplicationLayout
              path="/purchasedn"
              component={PurchaseDebitNoteList}
            />
            <ApplicationLayout
              path="/purchaseDebitNote"
              component={PurchaseDebitNote}
            />
            <ApplicationLayout
              path="/purchaseDebitNoteCreate"
              component={PurchaseDebitNote}
            />
            <ApplicationLayout
              path="/purchaseDebitNoteEdit"
              component={PurchaseDebitNote}
            />
            <ApplicationLayout
              path="/purchaseDebitNotePrint"
              component={PurchaseDebitNotePrintPreview}
            />
            <ApplicationLayout
              path="/purchaserf"
              component={PurchaseRefundList}
            />
            <ApplicationLayout
              path="/purchaseRefund"
              component={PurchaseRefund}
            />
            <ApplicationLayout
              path="/purchaseRefundCreate"
              component={PurchaseRefund}
            />
            <ApplicationLayout
              path="/purchaseRefundEdit"
              component={PurchaseRefund}
            />
            <ApplicationLayout
              path="/purchaseRefundPrint"
              component={PurchaseRefundPrintPreview}
            />
            <ApplicationLayout path="/rcm" component={RcmVoucherList} />
            <ApplicationLayout path="/rcmVoucher" component={RcmVoucher} />
            <ApplicationLayout
              path="/rcmVoucherCreate"
              component={RcmVoucher}
            />
            <ApplicationLayout path="/rcmVoucherEdit" component={RcmVoucher} />
            <ApplicationLayout
              path="/rcmVoucherPrint"
              component={RcmVoucherPrintPreview}
            />
            <ApplicationLayout
              path="/gsimprcm"
              component={GoodsServiceImportVoucherList}
            />
            <ApplicationLayout
              path="/gsimprcmCreate"
              component={GoodsServiceImportVoucher}
            />
            <ApplicationLayout
              path="/gsimprcmView"
              component={GoodsServiceImportVoucher}
            />
            <ApplicationLayout
              path="/gsimprcmEdit"
              component={GoodsServiceImportVoucher}
            />
            <ApplicationLayout path="/pv" component={RcmPaymentVoucherList} />
            <ApplicationLayout
              path="/rcmPaymentVoucher"
              component={RcmPaymentVoucher}
            />
            <ApplicationLayout
              path="/rcmPaymentVoucherCreate"
              component={RcmPaymentVoucher}
            />
            <ApplicationLayout
              path="/rcmPaymentVoucherEdit"
              component={RcmPaymentVoucher}
            />
            <ApplicationLayout
              path="/rcmPaymentVoucherPrint"
              component={RcmPaymentVoucherPrintPreview}
            />
            <ApplicationLayout path="/expense" component={ExpenseList} />
            <ApplicationLayout path="/expenseCreate" component={Expense} />
            <ApplicationLayout path="/expenseView" component={Expense} />
            <ApplicationLayout path="/expenseEdit" component={Expense} />
            <ApplicationLayout
              path="/expensePrint"
              component={ExpensePrintPreview}
            />
            <ApplicationLayout path="/history" component={History} />
            <ApplicationLayout
              path="/sales_invoicesearch"
              component={SalesHistory}
            />
            <ApplicationLayout
              path="/purchase_invoicesearch"
              component={PurchaseHistory}
            />
            <ApplicationLayout
              path="/expense_invoicesearch"
              component={ExpenseHistory}
            />
            <ApplicationLayout path="/user" component={UserManagement} />
            <ApplicationLayout path="/usercreate" component={User} />
            <ApplicationLayout path="/userview" component={User} />
            <ApplicationLayout path="/useredit" component={User} />
            <ApplicationLayout
              path="/financialdetails"
              component={FinancialAnaylsis}
            />
            <ApplicationLayout
              path="/monthlyfin_details"
              component={MonthlyFinancial}
            />
            <ApplicationLayout
              path="/yearlyfin_details"
              component={AnnualFinancial}
            />
            <ApplicationLayout
              path="/quaterlyfin_details"
              component={QuarterlyFinancial}
            />
            <ApplicationLayout path="/filing" component={Filing} />
            <ApplicationLayout path="/filinghisory" component={FilingHistory} />
            <ApplicationLayout path="/gstr1usermgmt" component={GstUserList} />
            <ApplicationLayout path="/gstr1usercreate" component={GstUser} />
            <ApplicationLayout path="/gstr1userview" component={GstUser} />
            <ApplicationLayout path="/gstr1useredit" component={GstUser} />
            <ApplicationLayout path="/gstr1list" component={GstR1List} />
            <ApplicationLayout path="/gstr1create" component={GstR1} />
            <ApplicationLayout path="/gstr1view" component={GstR1} />
            <ApplicationLayout path="/gstr3list" component={GSTRBList} />
            <ApplicationLayout path="/gstr3bcreate" component={GSTRB} />
            <ApplicationLayout path="/gstr3bview" component={GSTRB} />
            <ApplicationLayout path="/gstr3bDetails" component={GSTRBDetails} />
            <ApplicationLayout path="/reports" component={Reports} />
            <ApplicationLayout path="/custlist_rpt" component={AllCustomers} />
            <ApplicationLayout
              path="/custwise_rpt"
              component={CustomerWiseReport}
            />
            <ApplicationLayout
              path="/custwise_inv_rpt"
              component={CustomerWiseInvoice}
            />
            <ApplicationLayout path="/supplist_rpt" component={AllSuppliers} />
            <ApplicationLayout
              path="/suppwise_rpt"
              component={SupplierWiseReport}
            />
            <ApplicationLayout
              path="/suppwise_inv_rpt"
              component={SupplierWiseInvoice}
            />
            <ApplicationLayout path="/expense_rpt" component={NonGstReport} />
            <ApplicationLayout
              path="/qtrfinancial_rpt"
              component={QuaterlyReport}
            />
            <ApplicationLayout
              path="/mnthfinancial_rpt"
              component={MonthlyReport}
            />
            <ApplicationLayout path="/product_rpt" component={YearlyReport} />
            <ApplicationLayout
              path="/yearfinancial_rpt"
              component={ProductList}
            />
            <ApplicationLayout
              path="/productwise_sales_rpt"
              component={ProductWiseSalesReport}
            />
            <ApplicationLayout
              path="/productwise_inv_rpt"
              component={ProductWisePurchseReport}
            />
            <ApplicationLayout path="/import" component={Import} />
            <ApplicationLayout
              path="/custImport"
              component={ImportCustomerList}
            />
            <ApplicationLayout
              path="/importCustomer"
              component={ImportCustomer}
            />
            <ApplicationLayout
              path="/importCustomerView"
              component={ImportCustomer}
            />
            <ApplicationLayout
              path="/importCustomerEdit"
              component={ImportCustomer}
            />
            <ApplicationLayout
              path="/suppImport"
              component={ImportSupplierList}
            />
            <ApplicationLayout
              path="/importSupplier"
              component={ImportSupplier}
            />
            <ApplicationLayout
              path="/importSupplierEdit"
              component={ImportSupplier}
            />
            <ApplicationLayout
              path="/importSupplierView"
              component={ImportSupplier}
            />
            <ApplicationLayout
              path="/sa/imp/"
              component={ImportSalesInvoicesList}
            />
            <ApplicationLayout
              path="/importSalesVoucher"
              component={ImportSalesVoucher}
            />
            <ApplicationLayout
              path="/importSalesVoucherEdit"
              component={ImportSalesVoucher}
            />
            <ApplicationLayout
              path="/purchaseImport"
              component={ImportPurchaseInvoicesList}
            />
            <ApplicationLayout
              path="/importPurchaseVoucher"
              component={ImportPurchaseVoucher}
            />
            <ApplicationLayout
              path="/importPurchaseVoucherEdit"
              component={ImportPurchaseVoucher}
            />
            <ApplicationLayout
              path="/prod/imp"
              component={ImportProductServicesList}
            />
            <ApplicationLayout
              path="/importProductService"
              component={ImportProductServices}
            />
            <ApplicationLayout
              path="/importProductServiceView"
              component={ImportProductServices}
            />
            <ApplicationLayout
              path="/importProductServiceEdit"
              component={ImportProductServices}
            />
            <ApplicationLayout path="/settings" component={Settings} />
            <ApplicationLayout
              path="/generalSettings"
              component={GeneralSettings}
            />
            <ApplicationLayout path="/admindetails" component={Profile} />
            <ApplicationLayout
              path="/ledgerMgmt"
              component={LedgerManagement}
            />
            <ApplicationLayout path="/ledgerInward" component={InwardLedger} />
            <ApplicationLayout
              path="/ledgerInwardPrint"
              component={InwardLedgerPrintPreview}
            />
            <ApplicationLayout
              path="/ledgerOutward"
              component={OutwardLedger}
            />
            <ApplicationLayout
              path="/purchaseledgerPrint"
              component={PurchaseLedgerPrintPreview}
            />
            <ApplicationLayout
              path="/expenseledgerPrint"
              component={ExpenseLedgerPrintPriview}
            />
            {/* <Route component={ApplicationLayout} /> */}
            <AdminLayout path="/APDashboard" component={AdminDashboard} />
            <AdminLayout
              path="/registrationList"
              component={AdminRegistrationList}
            />
            <AdminLayout
              path="/viewRegistration"
              component={AdminRegistration}
            />
            <AdminLayout path="/appUserList" component={AdminAppUserList} />
            <AdminLayout path="/createAppUser" component={AdminAppUser} />
            <AdminLayout path="/viewAppUser" component={AdminAppUser} />
            <AdminLayout path="/editAppUser" component={AdminAppUser} />
            <AdminLayout
              path="/apSettings"
              component={AdminProfileManagementList}
            />
            <AdminLayout
              path="/apCreateProfileMgmnt"
              component={AdminProfileManagement}
            />
            <AdminLayout
              path="/apViewProfileMgmnt"
              component={AdminProfileManagement}
            />
            <AdminLayout
              path="/apEditProfileMgmnt"
              component={AdminProfileManagement}
            />
            <AdminLayout
              path="/emailTemplateList"
              component={EmailTemplateList}
            />
            <AdminLayout path="/viewEmailTemplate" component={EmailTemplate} />
            <AdminLayout path="/editEmailTemplate" component={EmailTemplate} />
            <AdminLayout path="/ApProfile" component={AdminProfile} />
            {/* <Route component={AdminLayout} /> */}
            <AdminLayout
              path="/addTermsCondition"
              component={TermsConditionTemplate}
            />
            <AdminLayout
              path="/viewTermsCondition"
              component={TermsConditionTemplate}
            />
            <AdminLayout
              path="/editTermsCondition"
              component={TermsConditionTemplate}
            />
            <AdminLayout
              path="/aptermsConditionsList"
              component={TermsConditionTemplateList}
            />
            <AdminLayout
              path="/privacyPolicyList"
              component={PrivacyPolicyTemplateList}
            />
            <AdminLayout
              path="/addPrivacyPolicy"
              component={PrivacyPolicyTemplate}
            />
            <AdminLayout
              path="/viewPrivacyPolicy"
              component={PrivacyPolicyTemplate}
            />
            <AdminLayout
              path="/editPrivacyPolicy"
              component={PrivacyPolicyTemplate}
            />
            <AdminLayout
              path="/sacHsnList"
              component={AdminServiceAccountingCodeList}
            />
            <AdminLayout
              path="/createSacHsn"
              component={AdminServiceAccountingCode}
            />
            <AdminLayout
              path="/viewSacHsn"
              component={AdminServiceAccountingCode}
            />
            <AdminLayout
              path="/editSacHsn"
              component={AdminServiceAccountingCode}
            />
            <AdminLayout
              path="/goodsHsnList"
              component={AdminGoodHsnCodeList}
            />
            <AdminLayout path="/createGoodsHsn" component={AdminGoodHsnCode} />
            <AdminLayout path="/viewGoodsHsn" component={AdminGoodHsnCode} />
            <AdminLayout path="/editGoodsHsn" component={AdminGoodHsnCode} />
            <Route path="/home" />
          </Switch>
        </BrowserRouter>{" "}
      </Provider>
    </>
  );
}
