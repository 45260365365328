import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { NavLink } from "react-router-dom";
import AdminFetchServerData from "../../../provider/AdminFetchServerData";
import logo from "../../../assets/images/th.png";
import store from "../../../redux/store";
import "./AdminLogin.css";
import AdminAppUserDataAction from '../../../redux/adminPanel/appUserData/AdminAppUserDataAction';
import AdminHeaderAction from '../../../redux/adminPanel/header/AdminHeaderAction';
class AdminLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			showPassword: false,
			showConfirmPassword: false,
			render: false,
		};
	}

	handleClickShowPassword() {
		this.setState({ showPassword: !this.state.showPassword });
	}
	handleEmailChange(e) {
		this.setState({ email: e.target.value });
	}
	handlePasswordChange(e) {
		this.setState({ password: e.target.value });
	}
	processLoginSuccess(output_data) {
		let headerObj = output_data.header;
		store.dispatch(AdminHeaderAction.setAdminHeader(headerObj));
		let menus = output_data.appuser.profile.menus.sort((a, b) => a.displaysequence - b.displaysequence);
		for (let i = 0; i < menus.length; i++) {
			const menu = menus[i];
			if (menu.isactive) {
				this.props.history.push(menu.pageid);
				break;
			}
		}
	}

	userLoginInBackend() {
		const postObject = {
			header: { email: this.state.email, appclient: "W" },
			appuser: {
				email: this.state.email,
				credential: this.state.password,
			},
		};
		AdminFetchServerData.callPostService('/adminpanelAppuserms/login', postObject)
			.then((response) => {
				let output = response;
				if (output.status === 'SUCCESS') {
					store.dispatch(AdminAppUserDataAction.setAdminUser(output.data.appuser));
					this.processLoginSuccess(output.data);
				} else {
				}
			})
			.catch((error) => { });
	}
	render() {
		return (
			<>
				<div className="authentication-wrapper authentication-cover">
					<div className="authentication-inner row">
						{/* /Left Text */}
						<div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center apLeftBackground">
							<div className="mx-auto ">
								{/* <div className="aplogoBackground"> */}
								<img
									src={logo}
									className="apLogoImage"
									alt=""
									data-app-dark-img="illustrations/girl-with-laptop-dark.png"
									data-app-light-img="illustrations/girl-with-laptop-light.png"
								/>
								{/* </div> */}
								<div className="align-items-center">
									<h2 className="logoHeding">Taxonomy Hub</h2>
								</div>
							</div>
						</div>
						{/* /Left Text */}
						{/* Register */}
						<div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
							<div className="w-px-400 mx-auto">
								<div className="adminSigninHeding text-start ps-2">Admin Panel</div>
								<h3 className='text-start pt-2'>Sign In</h3>
								<ValidatorForm
									ref="form"
									id="formAuthentication"
									className="mb-3"
									onSubmit={this.userLoginInBackend.bind(this)}
								>
									<div className="mb-3">
										<label
											htmlFor="email"
											className="form-label bold"
										// id="signupFormLabel"
										>
											EMAIL ID <span className="asterisk">*</span>
										</label>
										<TextField
											id="email"
											fullWidth
											required
											margin="normal"
											variant="outlined"
											placeholder="Enter Email *"
											type="email"
											value={this.state.email}
											onChange={this.handleEmailChange.bind(this)}
											autoComplete="off"
											validators={["required", "isEmail"]}
											errormessages={[
												"This field is required",
												"Email id is not valid",
											]}
										/>
									</div>
									<div className="mb-3 form-password-toggle">
										<label
											htmlFor="password"
											className="form-label bold"
										// id="signupFormLabel"
										>
											PASSWORD <span className="asterisk">*</span>
										</label>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											value={this.state.password}
											name="password"
											placeholder="Enter Password *"
											type={this.state.showPassword ? "text" : "password"}
											id="password"
											autoComplete="current-password"
											onChange={this.handlePasswordChange.bind(this)}
											InputProps={{
												// <-- This is where the toggle button is added.
												endAdornment: (
													<InputAdornment
														position="end"
													>
														<IconButton
															aria-label="toggle password visibility"
															onClick={this.handleClickShowPassword.bind(
																this
															)}
														>
															{this.state.showPassword ? (
																<VisibilityIcon />
															) : (
																<VisibilityOffIcon />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</div>
									<NavLink
										to="/ApForgotPassword"
										style={{ float: "right", marginBottom: "10px" }}
									>
										<span>Forgot Password?</span>
									</NavLink>
									<button type="submit" className="siginButton d-grid w-100">
										Sign In
									</button>
								</ValidatorForm>
								{/* <div className="d-flex flex-row">
											<NavLink to="/signinEmail">
												<ArrowBackIosNewIcon />
												Back Sign In
											</NavLink>
											<p className="ms-5 ps-4 text-end">
												<span>Do not have an account?</span>&nbsp;
												<NavLink to="/signup">
													<span>Sign Up</span>
												</NavLink>
											</p>
										</div> */}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default AdminLogin;
