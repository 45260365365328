import { GET_REGISTRATIONINFO, SET_REGISTRATIONINFO } from './registrationDataTypes';

export const getRegistrationInfo = () => {
  return {
    type: GET_REGISTRATIONINFO,
  };
};

export const setRegistrationInfo = data => {
  return {
    type: SET_REGISTRATIONINFO,
    payload: data,
  };
};
const registrationDataAction = {
  getRegistrationInfo,
  setRegistrationInfo,
}
export default registrationDataAction;
