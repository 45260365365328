import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import AddIconButton from "../buttons/AddIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import FileAttachment from "./FileAttachment";
import "./FileAttachment.scss";
export class FileAttachmentList extends Component {
  fileAttachmentUploadRef = [];
  constructor(props) {
    super(props);
    let fileAttachment = props.data;
    if (fileAttachment) {
      this.state = {
        history: props.history,
        title: props.title,
        aid: props.aid,
        documentTypeList: props.documentTypeList,
        fileList: null === props.data ? [] : props.data,
        uploadAttachmentServiceUrl: props.uploadAttachmentServiceUrl,
        deleteAttachmentServiceUrl: props.deleteAttachmentServiceUrl,
        onDocumentAdd: props.onDocumentAdd,
        onDocumentDelete: props.onDocumentDelete,
        isReceipt: fileAttachment.length > 0 ? true : false,
        srcType: props.srcType ? props.srcType : "",
      };
    } else {
      this.state = {
        title: "File Attachment",
        documentTypeList: props.documentTypeList,
        isReceipt: false,
        fileList: [],
        srcType: props.srcType ? props.srcType : "",
      };
    }
  }

  addFile(e) {
    this.state.fileList.push({
      filename: "",
      url: "",
      id: "",
      expensecategory: null,
      expenseCategoryList: this.state.expenseCategoryList,
      operation: "add",
    });
    this.setState({ fileList: this.state.fileList });
  }
  onCancelAdd(fileAttachment, index) {
    let docList = [];
    for (let i = 0; i < this.state.fileList.length; i++) {
      if (i !== index) {
        docList.push(this.state.fileList[i]);
      }
    }
    this.setState({ fileList: docList, action: "view" });
  }
  // onFileUpload = (e) => {
  //   // Create an object of formData
  //   // e.prventDefault();

  //   //console.log('===>' + JSON.stringify(this.state.selectedFile));
  //   if (!this.state.selectedFile.name || this.state.selectedFile.name === '') {
  //     PtsAlert.error('Please select file to upload');
  //     return;
  //   }
  //   let header = store.getState().header.header;
  //   const formData = new FormData();

  //   // Update the formData object
  //   formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
  //   formData.append('sid', header.sessionid);
  //   formData.append('tid', header.tid);
  //   formData.append('uid', header.userid);
  //   formData.append('appclient', header.appclient);
  //   // Details of the uploaded file
  //   //console.log(this.state.selectedFile);

  //   // Request made to the backend api
  //   // Send formData object
  //   // Axios.post('/bulkimport/customer', formData);
  //   FetchServerData.callPostFileUpload('/bulkimport/customer', formData).then((output) => {
  //     if (output.status === 'SUCCESS') {
  //       this.fileUploadRef.current.value = '';

  //       this.getCustemerData();
  //     }
  //   });
  // };
  onDocumentAdd(formData, index) {
    FetchServerData.callPostFileUpload(
      this.state.uploadAttachmentServiceUrl,
      formData
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let filedata = output.data;
        if (filedata.length > 0) {
          filedata = filedata[0];
        }
        let doc = {
          id: filedata.id,
          filename: filedata.filename,
          url: filedata.url,
          documenttype: this.state.documenttype,
        };
        let docList = [];
        for (let i = 0; i < this.state.fileList.length; i++) {
          if (i === index) {
            docList.push(doc);
          } else {
            docList.push(this.state.fileList[i]);
          }
        }
        this.setState({
          fileList: docList,
          action: "view",
          isReceipt: docList.length > 0 ? true : false,
        });
      }
    });
  }

  onDocumentDelete(postObject, index) {
    FetchServerData.callPostService(
      this.state.deleteAttachmentServiceUrl,
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let docList = [];
        for (let i = 0; i < this.state.fileList.length; i++) {
          if (i !== index) {
            docList.push(this.state.fileList[i]);
          }
        }
        this.setState({
          fileList: docList,
          action: "view",
          isReceipt: docList.length > 0 ? true : false,
        });
      }
    });
  }
  getTableHeader() {
    return (
      <TableHead>
        <TableRow style={{ background: "#ececec" }}>
          <TableCell
            style={{
              width: "5%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong>#</strong>
          </TableCell>
          <TableCell
            style={{
              width: "30%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong>Name</strong>
          </TableCell>
          <TableCell
            style={{
              width: "30%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong>Type</strong>
          </TableCell>
          <TableCell
            style={{
              width: "30%",
              fontSize: "small",
              border: "0.5px solid #cacaca",
            }}
          >
            <strong>Action</strong>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
  render() {
    let receiptList = [];
    let operation = "";
    this.fileAttachmentUploadRef = [];

    this.state.fileList.forEach((element, index) => {
      this.fileAttachmentUploadRef[index] = React.createRef();
    });
    if (this.state.fileList) {
      this.state.fileList.forEach((element, index) => {
        if (element.operation && element.operation === "add") {
          operation = "create";
          receiptList.push(
            <FileAttachment
              key={index}
              ref={this.fileAttachmentUploadRef[index]}
              data={element}
              action="create"
              aid={this.state.aid}
              srcType={this.props.srcType}
              index={index}
              history={this.props.history}
              domainObject={this.props.domainObject}
              onDocumentAdd={this.onDocumentAdd.bind(this)}
              onCancelAdd={this.onCancelAdd.bind(this)}
              onDocumentDelete={this.onDocumentDelete.bind(this)}
              documentTypeList={this.state.documentTypeList}
            />
          );
        } else {
          operation = "view";
          receiptList.push(
            <FileAttachment
              key={index}
              data={element}
              aid={this.state.aid}
              action="view"
              index={index}
              title={this.state.title}
              history={this.props.history}
              srcType={this.props.srcType}
              domainObject={this.props.domainObject}
              onDocumentAdd={this.onDocumentAdd.bind(this)}
              onDocumentDelete={this.onDocumentDelete.bind(this)}
              onCancelAdd={this.onCancelAdd.bind(this)}
              documentTypeList={this.state.documentTypeList}
            />
          );
        }
      });
    }
    return (
      <Col
        md={this.state.isReceipt ? 8 : 4}
        style={{ margin: "0", padding: "0" }}
      >
        <Card>
          <Row
            md={12}
            style={{
              border: "0.5px solid #cacaca",
              fontSize: "small",
              margin: "0",
              padding: "5px",
              color: "#000",
              background: "#ececec",
            }}
          >
            <Col md={6}>
              <span style={{ fontSize: "15px" }}>
                <strong>{this.state.title}</strong>
              </span>
            </Col>
            {(null === receiptList || receiptList.length <= 0) && (
              <Col md={6}>
                <AddIconButton onClick={this.addFile.bind(this)} />
              </Col>
            )}
          </Row>
          {operation === "create" && receiptList.length > 0 && (
            <Row
              md={12}
              style={{
                fontSize: "small",
                margin: "0",
                padding: "0",
                color: "#000",
              }}
            >
              {/* <TableContainer> */}
              <Table
                size="small"
                style={{
                  color: "black",
                  fontSize: "small",
                  border: "0.5px solid #cacaca",
                }}
              >
                <TableBody style={{ padding: "5px" }}>{receiptList}</TableBody>
              </Table>
              {/* </TableContainer> */}
            </Row>
          )}

          {operation === "view" && receiptList.length > 0 && (
            // <TableContainer>
            <Table size="small" style={{ color: "black", fontSize: "small" }}>
              {this.getTableHeader()}
              <TableBody>{receiptList}</TableBody>
            </Table>
            // </TableContainer>
          )}
        </Card>
      </Col>
    );
  }
}

export default FileAttachmentList;
