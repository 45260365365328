import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ReactApexCharts from "react-apexcharts";
import MaterialTable from "material-table";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EmailIcon from "@material-ui/icons/Email";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GetAppIcon from "@material-ui/icons/GetApp";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Switch, createMuiTheme } from "@material-ui/core";
import { Menu } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { exportComponentAsJPEG } from "react-component-export-image";
import React, { Component } from "react";
import FetchServerData from "../../../../provider/FetchServerData";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { Col, Row, Card, CardBody } from "reactstrap";
import Utils from "../../../../provider/Utils";
import UiUtils from "../../../../provider/UiUtils";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
export default class ProductWiseSalesReport extends Component {
  org = store.getState().appData.settings.org;
  financialsYearArray = store.getState().appData.fyList;
  customerList = store.getState().master.masterData.customers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  breadCrumb = [];
  title = "Product wise Sales Report";
  constructor(props) {
    super(props);
    this.imageDwlRef = React.createRef();
    let allCustomers = {
      name: "All",
    };
    const customerList = [allCustomers, ...this.customerList];

    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    this.state = {
      startDate: null,
      endDate: null,
      selectedBusinessUnit: this.businessUnitList[0],
      customerList: customerList,
      customer: customerList[0],
      productSalesInvoiceList: [],
      financialsYear: null,
      defaultFinancialYear: null,
      url: "",
      pdfdata: "",
      contentType: "application/pdf",
      isProductInvoiceReport: false,
      swichCheck: false,
      error: false,
      errorMassage: false,
    };

    this.getProductWiseSalesDeatils();
  }

  updateFinancialYear(e, year) {
    this.setState({ financialsYear: year });
  }

  handleStartDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.endDate);
    if (this.state.endDate === null && date === null) {
      this.setState({
        startDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (date === null) {
      this.setState({
        startDate: date,
        financialsYear: null,
        error: false,
        errorMassage: true,
      });
    } else if (fromDate > toDate) {
      this.setState({
        startDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        startDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleEndDateChange(date) {
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(date);
    if (this.state.startDate === null && date === null) {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (this.state.startDate === null) {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: true,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        endDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }
  handleCustomerChange(e, selectedType) {
    this.setState({
      customer: selectedType,
    });
  }

  updateBusinessUnit(e, name) {
    this.setState({ selectedBusinessUnit: name });
  }

  handleSwichCheckChange(e) {
    this.setState({ swichCheck: e.target.checked });
  }

  getProductWiseSalesDeatils() {
    // e.preventDefault();
    const header = store.getState().header.header;
    const postObject = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      financialYear: this.state.financialsYear,
      bu: {
        id: this.state.selectedBusinessUnit?.id,
        code: this.state.selectedBusinessUnit?.code,
      },
      header: header,
    };
    if (this.state.customer && this.state.customer.id) {
      postObject.customer = { id: this.state.customer.id };
    }
    FetchServerData.callPostServiceForPDFReportData(
      "/reports/getProductWiseSales",
      postObject
    ).then((output) => {
      let ProductWiseSalesDetails = output.data;
      let productSalesInvoiceList = output.data?.productList;
      for (let i = 0; i < productSalesInvoiceList?.length; i++) {
        productSalesInvoiceList[i].invoicedate = Utils.ptsDateFormat(
          productSalesInvoiceList[i].invoicedate
        );
      }

      let productLabels = [];
      let salesValue = [];
      for (let i = 0; i < productSalesInvoiceList?.length; i++) {
        let date = productSalesInvoiceList[i].name;
        productLabels.push(date);
        salesValue.push(productSalesInvoiceList[i].salesValue);
      }
      this.setState({
        render: true,
        open: false,
        defaultStartDate: ProductWiseSalesDetails?.fromDate,
        defaultEndDate: ProductWiseSalesDetails?.toDate,
        defaultFinancialYear: ProductWiseSalesDetails?.financialYear,
        startDate: ProductWiseSalesDetails.fromDate,
        endDate: ProductWiseSalesDetails.toDate,
        productSalesInvoiceList: productSalesInvoiceList,
        isProductInvoiceReport: true,
        swichCheck: this.state.financialsYear === null ? true : false,
        productSeries: [
          {
            name: "Sales Invoice Amount",
            type: "bar",
            data: salesValue,
            marginTop: "10px",
          },
        ],
        productOptions: {
          chart: {
            type: "bar",
            height: 430,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          legend: {
            position: "top",
          },
          colors: [
            "#2E93fA",
            "#FF9800",
            "rgb(0, 227, 150)",
            "#ff3e1d",
            "rgb(135, 139, 182)",
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "25%",
              dataLabels: {
                position: "top",
              },
            },
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            hideOverlappingLabels: false,
            enabled: false,
            // offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: productLabels,
            labels: {
              style: {
                colors: "#247BA0",
              },
            },
            title: {
              text: "Products",
              style: {
                color: "#247BA0",
                fontSize: "14px",
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#247BA0",
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#247BA0",
              },
              title: {
                text: "Sales Invoice Amount",
                style: {
                  color: "#247BA0",
                  fontSize: "14px",
                },
              },
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                },
                style: {
                  colors: "#247BA0",
                },
              },
            },
          ],
        },
      });
    });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/reports/",
      state: {},
    });
  }

  goToBack() {
    this.props.history.push({
      pathname: "/reports/",
    });
  }

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  downloadImage() {
    let fileName = "Customer Wise Sales Invoice";
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    if (this.imageDwlRef) {
      exportComponentAsJPEG(this.imageDwlRef, {
        fileName: fileName,
      });
    }
  }

  filterRecords(e) {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }

    this.setState({
      customer: this.state.customer,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      financialsYear: this.state.financialsYear,
    });
    this.getProductWiseSalesDeatils();
  }

  resetFilter() {
    this.state.customer = this.state.customerList[0];
    this.state.selectedBusinessUnit = this.businessUnitList[0];
    this.state.startDate = null;
    this.state.endDate = null;
    this.state.financialsYear = null;
    this.state.error = false;
    this.state.errorMassage = false;
    this.getProductWiseSalesDeatils();
  }

  render() {
    let logoData = Utils.getLogo();
    this.printComponentRef = React.createRef();

    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          name={this.title}
          addToBreadCrumb={this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          backCallback={this.cancelView.bind(this)}
        />

        {this.state.isProductInvoiceReport && (
          <>
            <Row md={12} style={{ padding: "0.5em" }}>
              <Col md={6} style={{ padding: "5px", textAlign: "start" }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    background: "#717373",
                    color: "#fff",
                    marginLeft: "0.5rem",
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={(e) => this.goToBack(e)}
                >
                  Back
                </Button>
              </Col>
              <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        background: "#1b7189",
                        color: "#fff",
                        marginRight: "1rem",
                      }}
                      startIcon={<PrintIcon />}
                      // onClick={(e) => this.printContent(e)}
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.printComponentRef}
                />
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    background: "#9a7031",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  startIcon={<GetAppIcon />}
                  // onClick={() => exportComponentAsJPEG(this.imageDwlRef)}
                  onClick={(e) => this.downloadImage(e)}
                >
                  Download
                </Button>
                {/* <Button
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    // marginRight: "1rem",
                  }}
                  startIcon={<EmailIcon />}
                  // onClick={(e) => this.emailSalesVoucher(e)}
                >
                  Email
                </Button> */}
              </Col>
            </Row>
            <div className="row mb-4">
              <div className="col-8 align-items-start text-start">
                <span className="ms-2">
                  {this.state.customer !== null ||
                  this.state.customer !== null ||
                  this.state.isAsset !== false ? (
                    <span>
                      {this.state.customer && this.state.customer !== null ? (
                        <span>
                          <b>Customer : </b>
                          {this.state.customer?.name + ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.selectedBusinessUnit &&
                      this.state.selectedBusinessUnit !== null ? (
                        <span>
                          <b>Business Unit : </b>
                          {this.state.selectedBusinessUnit.name + ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.defaultFinancialYear &&
                      this.state.defaultFinancialYear !== null ? (
                        <span>
                          <b>Financial Year : </b>
                          {this.state.defaultFinancialYear + ", "}
                        </span>
                      ) : (
                        ""
                      )}{" "}
                      &nbsp;
                      {this.state.defaultStartDate &&
                      this.state.defaultStartDate !== null ? (
                        <span>
                          <b>Start Date : </b>
                          {Utils.ptsDateFormat(this.state.defaultStartDate) +
                            ", "}
                        </span>
                      ) : (
                        ""
                      )}
                      &nbsp;
                      {this.state.defaultEndDate &&
                      this.state.defaultEndDate !== null ? (
                        <span>
                          <b>End Date : </b>
                          {Utils.ptsDateFormat(this.state.defaultEndDate) +
                            ", "}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div
                className="col-4 align-items-end text-end"
                style={{ padding: "0 25px" }}
              >
                <span
                  className="text-end me-1"
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={this.handleAccountDropdownOpen}
                >
                  <FilterAltIcon
                    style={{ fontSize: "28px", color: "black" }}
                    aria-controls={
                      this.state.open ? "dropdown-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? "true" : undefined}
                  />
                  Filter
                </span>
                {this.state.isFirstTimeLoading ? (
                  ""
                ) : (
                  <span
                    className="me-1"
                    style={{
                      fontSize: "17px",
                      fontWeight: "500",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={this.resetFilter.bind(this)}
                  >
                    {"  "} &nbsp;&nbsp;
                    <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                  </span>
                )}
              </div>
            </div>
            <Menu
              anchorEl={this.state.anchorEl}
              id="dropdown-menu"
              className="filterDropdownMenu"
              open={this.state.open}
              onClose={this.handleAccountDropdownClose}
              // onClick={this.handleAccountDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 20,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div
                className="dropdown-item"
                style={{ cursor: "pointer", width: "400px", height: "100%" }}
              >
                <div className="row">
                  <h5 className="bold">Filter</h5>
                  <div className="col-md-12" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Customer
                    </label>
                    <Autocomplete
                      fullWidth
                      variant="outlined"
                      id="customerList"
                      ListboxProps={{ className: "myCustomList" }}
                      options={this.customerList}
                      value={this.state.customer}
                      defaultValue={this.state.customer}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Select Customer"
                      onChange={(event, value) => {
                        this.handleCustomerChange(event, value);
                      }}
                      style={{ background: "#fff", borderRadius: "5px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className="regCountry"
                          placeholder="Customer"
                          InputLabelProps={{
                            fontSize: "small !important",
                            style: { fontSize: "small !important" },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12 mt-2" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Business Unit
                    </label>
                    <Autocomplete
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      margin="normal"
                      className="expenseAutoComplete"
                      id="businessUnit"
                      style={{ background: "#fff", borderRadius: "5px" }}
                      options={this.businessUnitList}
                      value={this.state.selectedBusinessUnit}
                      defaultValue={this.state.selectedBusinessUnit}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.updateBusinessUnit(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Business Unit"
                          {...params}
                          style={{}}
                          variant="outlined"
                          className="regCountry"
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12 mt-2" id="assetFormLabel">
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0px",
                        padding: "0",
                      }}
                      control={
                        <Switch
                          variant="outlined"
                          // id="isAsset"
                          checked={this.state.swichCheck}
                          value={this.state.swichCheck}
                          onChange={this.handleSwichCheckChange.bind(this)}
                          style={{ background: "none", margin: "8px" }}
                        />
                      }
                      label={<span>Custom search </span>}
                    />
                  </div>
                  {!this.state.swichCheck && (
                    <div className="col-md-12 mt-2" id="assetFormLabel">
                      <label className="form-label ">Financial Year</label>
                      <br />
                      <Autocomplete
                        fullWidth
                        variant="outlined"
                        id="supplierList"
                        ListboxProps={{ className: "myCustomList" }}
                        options={this.financialsYearArray}
                        value={this.state.financialsYear}
                        defaultValue={this.state.financialsYear}
                        getOptionLabel={(option) => option}
                        style={{ background: "#fff", borderRadius: "5px" }}
                        onChange={(event, value) => {
                          this.updateFinancialYear(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Financial Year"
                            className="regCountry"
                            InputLabelProps={{
                              fontSize: "small !important",
                              style: { fontSize: "small !important" },
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {this.state.swichCheck && (
                    <>
                      <div className="col-md-6 mt-2" id="assetFormLabel">
                        <label
                          className="form-label "
                          // htmlFor="collapsible-companyName"
                        >
                          Start Date
                        </label>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.startDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleStartDateChange.bind(this)}
                              helperText={
                                this.state.errorMassage
                                  ? "Start Date is Required"
                                  : ""
                              }
                              error={this.state.errorMassage}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-6 mt-2" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          End Date
                        </label>{" "}
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.endDate}
                              // variant="inline"
                              margin="normal"
                              placeholder="DD-MM-YYYY"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="dd-MM-yyyy"
                              onChange={this.handleEndDateChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>
                      {this.state.error ? (
                        <lable className="errorMassageShow">
                          End Date should be after Start Date
                        </lable>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <div className="col-md-12 mt-4 mb-2">
                    <button
                      className="fileterButton"
                      // onClick={this.onFilterClick.bind(this)}
                      onClick={(e) => this.filterRecords(e)}
                    >
                      <i className="fa-solid fa-filter"></i>
                      &nbsp; Filter
                    </button>
                    &nbsp; &nbsp;
                    <button
                      className="resetButton"
                      onClick={this.resetFilter.bind(this)}
                    >
                      <i className="fa fa-refresh"></i>
                      &nbsp; Reset
                    </button>
                  </div>
                </div>
              </div>
            </Menu>
            <div ref={(el) => (this.printComponentRef = el)}>
              <div className="row mt-2 mb-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-12 row d-flex align-items-end overflow-auto">
                        <h5 className="text-start pb-1">
                          Product vs. Sales Invoice Amount
                        </h5>
                        <div
                          className="text-start overflow-auto "
                          id="chart"
                          style={{ padding: "1em", overflowX: "auto" }}
                        >
                          {this.state.productOptions !== null &&
                          this.state.productSeries !== null ? (
                            <ReactApexCharts
                              options={this.state.productOptions}
                              series={this.state.productSeries}
                              type="bar"
                              height={450}
                              // width={200 * Math.max(this.state.customerWiseChartData.length)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Card
                md={12}
                id="printform"
                // ref={this.printComponentRef}
                // className="a4size"
                style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
              >
                <div ref={this.imageDwlRef}>
                  <CardBody style={{ color: "#000" }}>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "5px",
                        display: "flex",
                        borderLeft: "1px solid gray",
                        borderTop: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Col md={2}>
                        {Utils.isNotNullAndEmpty(logoData) && (
                          <img
                            src={logoData}
                            alt=""
                            style={{
                              height: "3em",
                              width: "3em",
                              padding: "0.1em",
                            }}
                          />
                        )}
                      </Col>
                      <Col md={8}>
                        <Row>
                          <strong
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            {this.state.customer?.name}
                          </strong>
                        </Row>
                        <Row>
                          <span style={{ width: "100%", textAlign: "center" }}>
                            {Utils.getAddressAsText(this.org?.addressvo)}
                          </span>
                        </Row>
                      </Col>
                      <Col md={2}>
                        <span style={{ width: "100%", textAlign: "center" }}>
                          {/* {this.state.duplicateTriplicate} */}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "5px",
                        display: "flex",
                        borderLeft: "1px solid gray",
                        borderTop: "1px solid gray",
                        borderBottom: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Col
                        md={12}
                        style={{ fontSize: "x-large", textAlign: "center" }}
                      >
                        <strong>Product wise Sales Report</strong>
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderRight: "1px solid gray",
                        borderLeft: "1px solid gray",
                        borderBottom: "1px solid gray",
                      }}
                      id="supplierReport"
                    >
                      <MaterialTable
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        columns={[
                          {
                            title: "#",
                            render: (rowData) => rowData.tableData.id + 1,
                            cellStyle: {
                              width: "5%",
                            },
                          },
                          {
                            title: "Name",
                            field: "name",
                            align: "left",
                            width: "20%",
                          },
                          {
                            title: "Code",
                            field: "code",
                            align: "left",
                            width: "20%",
                          },
                          {
                            title: "Type",
                            field: "producttype",
                            align: "left",
                            width: "15%",
                          },
                          {
                            title: "Sales",
                            field: "salesValue",
                            align: "Right",
                            width: "15%",
                            headerStyle: {
                              textAlign: "Right",
                            },
                            cellStyle: {
                              align: "Right",
                            },
                          },
                          {
                            title: "Refunds",
                            field: "outwardSalesValue",
                            align: "Right",
                            width: "15%",
                            headerStyle: {
                              textAlign: "Right",
                            },
                            cellStyle: {
                              align: "Right",
                            },
                          },
                          {
                            title: "Status",
                            render: (rowData) =>
                              rowData.isactive ? "Active" : "InActive",
                            align: "left",
                            width: "15%",
                          },
                        ]}
                        data={this.state.productSalesInvoiceList}
                        options={{
                          sorting: true,
                          actionsColumnIndex: -1,
                          search: false,
                          paging: false,
                          toolbar: false,
                          headerStyle: {
                            backgroundColor: "#ececec",
                            fontWeight: "bold",

                            color: "#000",
                            align: "left",
                            textAlign: "left",
                          },

                          rowStyle: (rowData) => ({
                            height: "3em",
                            // textTransform: "capitalize",
                          }),
                        }}
                        actions={[]}
                      />
                    </Row>
                  </CardBody>
                </div>
              </Card>
            </div>
          </>
        )}
      </div>
    );
  }
}
