import TextField from "@material-ui/core/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import MaterialTable from "material-table";
import React, { Component } from "react";
// import { TabPanel } from '@material-ui/lab';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
export class GoodsServiceImportVoucherList extends Component {
  header = store.getState().header.header;
  purchaseGSIVoucherState =
    store.getState().applicationState.purchaseGSIVoucherState;
  supplierList = store.getState().master.masterData.suppliers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  purchaseGSIVoucherStateDetails = {};
  breadCrumb = [
    {
      pageid: "/purchase",
      uiname: "Purchase",
      cssclass: "fa fa-cart-arrow-down",
    },
    { pageid: "/gsimprcm", uiname: " Goods/Service Import List" },
  ];
  title = "Goods/Service Import List";
  constructor(props) {
    super(props);

    this.tableIcons = UiUtils.getMaterialTableIcons();
    if (this.purchaseGSIVoucherState) {
      this.purchaseGSIVoucherStateDetails = Object.getOwnPropertyNames(
        this.purchaseGSIVoucherState
      );
    }
    if (this.purchaseGSIVoucherStateDetails.length > 0) {
      this.state = {
        selectedTabIndex: 0,
        invoiceId: this.purchaseGSIVoucherState.invoiceId,
        pageSize: this.purchaseGSIVoucherState.pageSize,
        initialPage: this.purchaseGSIVoucherState.initialPage,
        selectedBusinessUnit: null,
        selectedSupplier: null,
        selectedPurchaseCreditNoteCategory: null,
        invoiceList: [],
        businessUnits: [],
        privileges: [],
        invoiceStatusList: [],
        invoiceCategoryList: [],
        render: false,
        open: false,
        isPaging: false,
        isCapitalAsset: false,
        isFirstTimeLoading: true,
      };
    } else {
      this.state = {
        selectedTabIndex: 0,
        initialPage: 0,
        pageSize: 10,
        selectedBusinessUnit: null,
        selectedSupplier: null,
        selectedPurchaseCreditNoteCategory: null,
        invoiceList: [],
        businessUnits: [],
        privileges: [],
        invoiceStatusList: [],
        invoiceCategoryList: [],
        render: false,
        open: false,
        isPaging: false,
        isCapitalAsset: false,
        isFirstTimeLoading: true,
      };
    }
    this.getGoodsServiceImportVoucherList("", "", false, true);
  }

  getGoodsServiceImportVoucherList(bucode, supid, isCapitalAsset, reset) {
    const postObject = {
      header: this.header,
      bucode: bucode,
      supid: supid,
      isAsset: isCapitalAsset,
    };

    FetchServerData.callPostService("/pimpms/getlist", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let invoiceList = output.data.invoiceList;
          let privileges = output.data.privileges;
          let invoiceStatusList = output.data.invoiceStatusList;
          // store.dispatch(appDataAction.setUsersBusinessUnits(businessUnits));
          // store.dispatch(appDataAction.setPurchaseCreditNoteCategories(invoiceCategoryList));
          let isPaging = false;
          if (invoiceList.length > 10) {
            isPaging = true;
          }
          for (let i = 0; i < invoiceList.length; i++) {
            invoiceList[i].invoicedate = Utils.ptsDateFormat(
              invoiceList[i].invoicedate
            );
          }
          this.setState({
            invoiceList: invoiceList,
            // businessUnits: businessUnits,
            // invoiceCategoryList: invoiceCategoryList,
            isFirstTimeLoading: reset ? true : false,
            selectedBusinessUnit: reset
              ? null
              : this.state.selectedBusinessUnit,
            selectedSupplier: reset ? null : this.state.selectedSupplier,
            isPaging: isPaging,
            privileges: privileges,
            isCapitalAsset: isCapitalAsset,
            invoiceStatusList: invoiceStatusList,
            open: false,
            render: true,
          });
        } else {
        }
      }
    );
  }

  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setPurchaseCreditNoteTab(index));
    this.setState({ selectedTabIndex: index });
  }
  addPurchaseCreditNote = (e) => {
    this.props.history.push({
      pathname: "/gsimprcmCreate",
      state: {
        action: "create",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  toPurchaseCreditNoteDetails = (e, data) => {
    let purchaseGSIVoucherState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ purchaseGSIVoucherState })
    );
    this.props.history.push({
      pathname: "/gsimprcmView",
      state: {
        invoiceId: data.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  getTablesWithStatus(status) {
    let filteredData = this.state.invoiceList.filter(function (e) {
      return e.status === status;
    });
    if (status === "All") {
      filteredData = this.state.invoiceList;
    }

    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Credit Note",
        isFreeAction: true,
        onClick: (event) => this.addPurchaseCreditNote(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <Row md={12} style={{ padding: "1px" }}>
        <div style={{ width: "100%", padding: "0em 1em" }}>
          <MaterialTable
            icons={this.tableIcons}
            localization={{
              body: {
                emptyDataSourceMessage: Utils.communizeTableText(),
              },
            }}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },
              {
                title: "Date",
                field: "invoicedate",
                type: "date",
                align: "left",
                width: "10%",
              },
              {
                title: "Invoice Number",
                field: "invnumber",
                align: "left",
                width: "15%",
              },
              {
                title: "Supplier",
                field: "suppliername",
                align: "left",
                width: "20%",
              },
              {
                title: "Taxable Amount",
                render: (rowData) =>
                  rowData.currencysymbol +
                  " " +
                  parseFloat(rowData.totaltaxable).toFixed(2),
                align: "right",
                width: "15%",
                customSort: (a, b) => {
                  return (
                    parseFloat(a.totaltaxable) - parseFloat(b.totaltaxable)
                  );
                },
                customFilterAndSearch: (term, rowData) =>
                  parseFloat(rowData.totaltaxable) === parseFloat(term),
              },
              {
                title: "Invoice Amount",
                render: (rowData) =>
                  rowData.currencysymbol +
                  " " +
                  parseFloat(rowData.totalamount).toFixed(2),
                align: "right",
                width: "15%",
                customSort: (a, b) => {
                  return parseFloat(a.totalamount) - parseFloat(b.totalamount);
                },
                customFilterAndSearch: (term, rowData) =>
                  parseFloat(rowData.totalamount) === parseFloat(term),
              },
              {
                title: "Status",
                field: "statusdescription",
                align: "center",
                width: "15%",
              },
            ]}
            data={filteredData}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              paging: this.state.isPaging,
              pageSizeOptions: Utils.materialTablePageSize(),
              pageSize: this.state.pageSize,
              initialPage: this.state.initialPage,
              headerStyle: {
                backgroundColor: "#1b7189",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            style={{
              boder: "1px solid black",
            }}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
            actions={actionList}
            onRowClick={(e, rowData) =>
              this.toPurchaseCreditNoteDetails(e, rowData)
            }
          />
        </div>
      </Row>
    );
  }
  updateBusinessUnit(e, bu) {
    this.setState({ selectedBusinessUnit: bu });
  }

  updateSupplier(e, supplier) {
    this.setState({ selectedSupplier: supplier });
  }

  handleCapitalAssetChange(e) {
    this.setState({ isCapitalAsset: e.target.checked });
  }

  updatePurchaseCreditNoteCategory(e, name) {
    this.setState({ selectedPurchaseCreditNoteCategory: name });
  }
  filterRecords(e) {
    let bucode = this.state.selectedBusinessUnit
      ? this.state.selectedBusinessUnit.code
      : "";
    let supid = this.state.selectedSupplier
      ? this.state.selectedSupplier.id
      : "";
    let isCapitalAsset = this.state.isCapitalAsset;
    this.getGoodsServiceImportVoucherList(bucode, supid, isCapitalAsset);
  }

  resetFilter() {
    let bucode = "";
    let supid = "";
    let isCapitalAsset = false;
    this.getGoodsServiceImportVoucherList(bucode, supid, isCapitalAsset, true);
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  cancelView() {
    this.props.history.push({ pathname: "/purchase/" });
  }
  render() {
    if (this.state.render === false) {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
      );
    }

    const tabList = this.state.invoiceStatusList.map((expStatus, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(expStatus, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{expStatus.uiname}</span>
        </Tab>
      );
    });

    const tabPanelList = this.state.invoiceStatusList.map((tab, keyIndex) => {
      return (
        <TabPanel key={keyIndex}>
          {this.getTablesWithStatus(tab.status)}
        </TabPanel>
      );
    });

    let selectedIndex = store.getState().appData.purchaseCNSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        {/* <Row md={12} style={{ margin: "1em" }}>
          <Col md={2}>
            <Autocomplete
              fullWidth
              variant="outlined"
              id="supplierList"
              options={this.supplierList}
              value={this.state.selectedSupplier}
              defaultValue={this.state.selectedSupplier}
              getOptionLabel={(option) => option.name}
              renderOption={(option, state) => (
                <label
                  style={{
                    padding: 0,
                    margin: 0,
                    wordWrap: "break-word",
                    color: "#000",
                  }}
                >
                  {" "}
                  {option.name}{" "}
                </label>
              )}
              onChange={(event, value) => {
                this.updateSupplier(event, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier"
                  InputLabelProps={{ style: {} }}
                />
              )}
            />
          </Col>
          <Col md={2}>
            <Autocomplete
              fullWidth
              variant="outlined"
              id="businessUnit"
              options={this.businessUnitList}
              value={this.state.selectedBusinessUnit}
              defaultValue={this.state.selectedBusinessUnit}
              getOptionLabel={(option) => option.name}
              renderOption={(option, state) => (
                <label
                  style={{
                    padding: 0,
                    margin: 0,
                    wordWrap: "break-word",
                    color: "#000",
                  }}
                >
                  {" "}
                  {option.name}{" "}
                </label>
              )}
              onChange={(event, value) => {
                this.updateBusinessUnit(event, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Business Unit"
                  InputLabelProps={{ style: {} }}
                />
              )}
            />
          </Col>

          <Col md={1}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<FilterListIcon />}
              onClick={(e) => this.filterRecords(e)}
            ></Button>
          </Col>
        </Row> */}

        <div className="row align-items-end text-end">
          <div className="col me-4">
            <span>
              {this.state.selectedSupplier !== null || this.state.selectedBusinessUnit !== null
                || this.state.isCapitalAsset !== false ? (
                <span>
                  {this.state.selectedSupplier &&
                    this.state.selectedSupplier !== null ? (
                    <span>
                      <b>Supplier : </b>
                      {this.state.selectedSupplier?.name + " , "}
                    </span>
                  ) : (
                    ""
                  )}
                  &nbsp;
                  {this.state.selectedBusinessUnit &&
                    this.state.selectedBusinessUnit !== null ? (
                    <span>
                      <b> Business Unit : </b>
                      {this.state.selectedBusinessUnit?.name + " , "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.isCapitalAsset &&
                    this.state.isCapitalAsset !== false ? (
                    <span>
                      <b> Is Capital Asset : </b>
                      {this.state.isCapitalAsset ? "Yes" : "No"}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                ""
              )}
            </span>
            &nbsp;&nbsp;
            <span
              className="text-end"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>
            {"  "} &nbsp;&nbsp;
            {this.state.isFirstTimeLoading ? (
              ""
            ) : (
              <span
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.resetFilter.bind(this)}
              >
                <i class="fa fa-refresh" aria-hidden="true"></i> Reset
              </span>
            )}
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Filter</h5>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Supplier
                </label>
                <Autocomplete
                  fullWidth
                  ListboxProps={{
                    className: "myCustomList"
                  }}
                  variant="outlined"
                  id="supplierList"
                  options={this.supplierList}
                  value={this.state.selectedSupplier}
                  defaultValue={this.state.selectedSupplier}
                  getOptionLabel={(option) => option.name}
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateSupplier(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Supplier"
                      className="regCountry"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Business Unit
                </label>
                <Autocomplete
                  fullWidth
                  ListboxProps={{
                    className: "myCustomList"
                  }}
                  variant="outlined"
                  id="businessUnit"
                  options={this.businessUnitList}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Select Business Unit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="regCountry"
                      placeholder="Business Unit"
                      variant="outlined"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                  }}
                  control={
                    <Checkbox
                      variant="outlined"
                      id="isAsset"
                      checked={this.state.isCapitalAsset}
                      value={this.state.isCapitalAsset}
                      onChange={this.handleCapitalAssetChange.bind(this)}
                      style={{ background: "none" }}
                    />
                  }
                  label={<span style={{}}>Is Capital Asset?</span>}
                />
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  // onClick={this.onFilterClick.bind(this)}
                  onClick={(e) => this.filterRecords(e)}
                >
                  <i className="fa-solid fa-filter"></i>
                  &nbsp; Filter
                </button>
                &nbsp; &nbsp;
                <button
                  className="resetButton"
                  onClick={this.resetFilter.bind(this)}
                >
                  <i className="fa fa-refresh"></i>
                  &nbsp; Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>
        <Row md={12}>
          <Col md={12} className="settingTabRow">
            <Tabs
              selectedIndex={selectedIndex}
              md={12}
              style={{
                width: "100%",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
              className="settingTab"
            >
              <TabList
                style={{
                  minWidth: "5em",
                  color: "#1b7189",
                  outline: "none",
                  margin: "0",
                }}
              >
                {tabList}
              </TabList>
              {tabPanelList}
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}
export default GoodsServiceImportVoucherList;
