import TextField from "@material-ui/core/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import store from "../../../redux/store";
import Address from "../address/Address";
import CancelButton from "../buttons/CancelButton";
import EditIconButton from "../buttons/EditIconButton";
import SaveButton from "../buttons/SaveButton";
// import UploadButton from "../buttons/UploadButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Organization.scss";
import OrganizationDataAction from "../../../redux/organizationData/OrganizationDataAction";
// import SettingDataAction from "../../../redux/settings/SettingDataAction";

export class Organization extends Component {
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  logoData = store.getState().appData.logoData
  classes = {
    root: {
      flexGrow: 1,
    },
    paper: {
      margin: "0.5em",
      padding: 20,
      height: "100%",
      textAlign: "center",
      // color: theme.palette.text.secondary,
    },
  };
  constructor(props) {
    super(props);
    let organization = props.data;
    if (organization !== null) {
      this.state = {
        id: null === organization.id ? "" : organization.id,
        name: null === organization.name ? "" : organization.name,
        tradeName:
          null === organization.tradeName ? "" : organization.tradeName,
        phone: null === organization.phone ? "" : organization.phone,
        email: null === organization.email ? "" : organization.email,
        faxNumber:
          null === organization.faxNumber ? "" : organization.faxNumber,
        gstn: null === organization.gstn ? "" : organization.gstn,
        panNo: null === organization.panNo ? "" : organization.panNo,
        website: null === organization.website ? "" : organization.website,
        logo: null === organization.logo ? "" : organization.logo,
        address: null === organization.address ? "" : organization.address,
        usetnforinvoicing:
          null === organization.usetnforinvoicing
            ? ""
            : organization.usetnforinvoicing,
        action: props.action,
        render: true,
      };
    } else {
      this.state = {
        id: "",
        name: "",
        tradeName: "",
        phone: "",
        email: "",
        faxNumber: "",
        gstn: "",
        panNo: "",
        website: "",
        logo: "",
        usetnforinvoicing: false,
        address: {},
        action: props.action,
        render: true,
      };
    }
  }

  handleTradeNameChange(e) {
    this.setState({ tradeName: e.target.value });
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handlePhoneNumberChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleFaxNumberChange(e) {
    this.setState({ faxNumber: e.target.value });
  }
  handleGstnChange(e) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    this.setState({ gstn: input.value.toUpperCase() }, () =>
      input.setSelectionRange(start, end)
    );
    // this.setState({ gstn: e.target.value });
  }
  handleGstnOnBlur(e) {
    let gstnValue = e.target.value.toUpperCase();
    this.setState({
      gstn: gstnValue,
    });
  }
  handlePanNumberChange(e) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    this.setState({ panNo: input.value.toUpperCase() }, () =>
      input.setSelectionRange(start, end)
    );
    // this.setState({ panNo: e.target.value });
  }
  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }
  handleLooChange(e) {
    this.setState({ logo: e.target.value });
  }

  handleUseTradeNameForInvoicingChange(e) {
    this.setState({ usetnforinvoicing: !this.state.usetnforinvoicing });
  }

  updateOrganizationInBackend(e) {
    e.preventDefault();
    if (this.addressRef && this.addressRef.current) {
      if (false === this.addressRef.current.validateAddress()) {
        return;
      }
    }
    this.state.address = this.addressRef.current.state;
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      orgVo: {
        id: this.state.id,
        name: this.state.name,
        tradeName: this.state.tradeName,
        phone: this.state.phone,
        gstn: this.state.gstn,
        panNo: this.state.panNo,
        email: this.state.email,
        website: this.state.website,
        usetnforinvoicing: this.state.usetnforinvoicing,
        addressvo: {
          id: this.state.address.id,
          line1: this.state.address.line1,
          line2: this.state.address.line2,
          city: this.state.address.city,
          pin: this.state.address.pincode,
          statevo: {
            id: this.state.address.state.id,
            name: this.state.address.state.name,
            code: this.state.address.state.code,
            snum: this.state.address.state.snum,
            countryvo: {
              id: this.state.address.country.id,
              name: this.state.address.country.name,
              code: this.state.address.country.code,
            },
          },
        },
      },
      header: header,
    };
    FetchServerData.callPostService("/orgms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let orgData = output.data;
          store.dispatch(OrganizationDataAction.setOrganization(output.data));
          // store.dispatch(SettingDataAction.setSettings(orgData));
          this.setState({
            id: orgData.id,
            name: orgData.name,
            tradeName: orgData.tradeName,
            phone: orgData.phone,
            email: orgData.email,
            faxNumber: orgData.faxNumber,
            panNo: orgData.panNo,
            website: orgData.website,
            logo: orgData.logo,
            usetnforinvoicing: orgData.usetnforinvoicing,
            address: orgData.addressvo,
            action: "view",
            render: true,
          });

          this.props.onChange(orgData);
        } else {
        }
      }
    );
  }

  cancel() {
    /*Go to View Page */
    this.state.render = false;
    this.setState({ action: "view", render: true });
  }

  setUpdateOrganization(e) {
    this.setState({ action: "edit" });
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }

  onFileChange = (event) => {
    // Update the state
    let currentFile = null;
    if (event.target.files[0]) {
      currentFile = URL.createObjectURL(event.target.files[0]);
    }
    this.setState({
      selectedFile: event.target.files[0],
      currentFile: currentFile,
    });
  };
  onLogoUpload = (e) => {
    // Create an object of formData
    // e.prventDefault();
    if (!this.state.selectedFile.name || this.state.selectedFile.name === "") {
      PtsAlert.error("Please select file to upload");
      return;
    }
    let header = store.getState().header.header;
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("sid", header.sessionid);
    formData.append("tid", header.tid);
    formData.append("uid", header.email);
    formData.append("appclient", header.appclient);
    // Details of the uploaded file

    FetchServerData.callPostFileUpload("/logoupload/save", formData).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.fileUploadRef.current.value = "";
          let fileUrl = output.data;
          let postObject = { header: header, url: fileUrl };
          /* Download File and Save It to Store */
          FetchServerData.callPostFileDownload("/filems/get", postObject)
            .then((output) => {
              if (!Utils.isNull(output.data) && "" !== output.data) {
                output.data = output.data.entity;
              }
              store.dispatch(appDataAction.setLogo(output.data));
              this.setState({ render: true, currentFile: null });
            })
            .catch((error) => { });
        }
      }
    );
  };
  render() {
    this.addressRef = React.createRef();
    this.fileUploadRef = React.createRef();
    if (this.state.action === "edit") {
      return this.editOrganization();
    } else {
      return this.viewOrganization();
    }
  }

  viewOrganization() {
    return (
      <div style={{ width: "100%", padding: "0em 1em" }}>
        <Card
          md={12}
          style={{
            width: "100%",
            border: "0.5px solid #1b7189",
            boxShadow: "2px 2px 2px lightgrey",
          }}
        >
          <CardHeader
            style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col md={6} style={{ margin: "auto" }}>
                <span>
                  <strong>Organization Details</strong>
                </span>
              </Col>
              <Col md={6} style={{ marginTop: "0.5em" }}>
                <EditIconButton
                  onClick={this.setUpdateOrganization.bind(this)}
                  caption="Edit Organization"
                ></EditIconButton>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} style={{ marginTop: "1em" }}>
              <Col md={6}>
                <Row className="addressRow">
                  <Col md={6}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Name
                    </label>
                  </Col>
                  <Col md={6}>
                    <label style={{ color: "#000" }}>{this.state.name}</label>
                  </Col>
                </Row>
                <Row className="addressRow">
                  <Col md={6}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Trade Name
                    </label>
                  </Col>
                  <Col md={6}>
                    <label style={{ color: "#000" }}>
                      {this.state.tradeName}
                    </label>
                  </Col>
                </Row>
                <Row className="addressRow">
                  <Col md={6}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      GSTN No
                    </label>
                  </Col>
                  <Col md={6}>
                    <label style={{ color: "#000" }}>{this.state.gstn}</label>
                  </Col>
                </Row>
                <Row className="addressRow">
                  <Col md={6}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      PAN No
                    </label>
                  </Col>
                  <Col md={6}>
                    <label style={{ color: "#000" }}>{this.state.panNo}</label>
                  </Col>
                </Row>
                <Row className="addressRow">
                  <Col md={6}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Use Trade Name for Invoicing?
                    </label>
                  </Col>
                  <Col md={6} className="text-start" style={{ color: "#000" }}>
                    {this.state.usetnforinvoicing ? "Yes" : "No"}
                  </Col>
                </Row>
                <Row className="addressRow">
                  <Col md={6}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Phone Number
                    </label>
                  </Col>
                  <Col md={6}>
                    <label style={{ color: "#000" }}>{this.state.phone}</label>
                  </Col>
                </Row>
                <Row className="addressRow">
                  <Col md={6}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Website
                    </label>
                  </Col>
                  <Col md={6}>
                    <label style={{ color: "#000" }}>
                      {this.state.website}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Address
                  ref={this.addressRef}
                  title="Address"
                  data={this.state.address}
                  action="view"
                />
              </Col>
            </Row>
            <hr></hr>
            <Row
              md={12}
              style={{
                alignItems: "flex-end",
                paddingBottom: "1em",
                // paddingTop: "1em",
                margin: "1em 0em 0em 0.5em",
                // background: "antiquewhite",
              }}
            >
              <label style={{ color: "#000", fontWeight: "600" }}>Logo</label>
              <Col md={6}>
                <ValidatorForm
                  ref="form"
                  style={{ display: "flex", alignItems: "flex-end" }}
                  onSubmit={this.onLogoUpload.bind(this)}
                >
                  <TextField
                    required
                    type="file"
                    id="file"
                    className="UploadLog"
                    label="Select Image File to Upload"
                    ref={this.fileUploadRef}
                    onChange={this.onFileChange.bind(this)}
                    InputLabelProps={{
                      shrink: true,
                      style: {},
                    }}
                  />
                  {/* <UploadButton type="submit" /> */}

                  {this.state.currentFile ? (
                    <>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{
                          background: "#1b7189",
                          color: "#fff",
                          marginRight: "1rem",
                        }}
                        startIcon={<CloudUploadIcon />}
                      // onClick={this.onClick.bind(this)}
                      >
                        Upload
                      </Button>
                      <div>
                        <img
                          style={{
                            height: "7em",
                            width: "7em",
                            padding: "0.1em",
                          }}
                          alt=""
                          src={
                            this.state.currentFile ? this.state.currentFile : ""
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <Button
                      disabled
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: "#1b7189",
                        color: "#fff",
                        marginRight: "1rem",
                        opacity: "0.4",
                      }}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload
                    </Button>
                  )}
                </ValidatorForm>
              </Col>
              {this.logoData.length > 0 &&
                <Col md={6}>
                  <img
                    src={Utils.getLogo()}
                    alt=""
                    style={{ padding: "0.1em", height: "80px", width: "80px" }}
                  />
                </Col>}
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }

  editOrganization() {
    return (
      <div style={{ width: "100%", padding: "0em 1em" }}>
        <Card
          md={12}
          style={{
            width: "100%",
            border: "0.5px solid #1b7189",
            boxShadow: "2px 2px 2px lightgrey",
            // margin: "0em 1em",
          }}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.updateOrganizationInBackend.bind(this)}
          >
            <CardHeader
              style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
            >
              <Row
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "5px",
                  padding: "1em",
                }}
              >
                <Col md={12}>
                  <span>
                    <strong>Edit Organization</strong>
                  </span>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row style={{ marginTop: "1em" }}>
                <Col md={6} style={{ marginTop: "30px" }}>
                  <Row style={{ padding: "5px" }}>
                    <Col md={6}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Name
                      </label>
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="name"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.handleNameChange.bind(this)}
                        name="name"
                        autoComplete="name"
                        InputlabelProps={{ shrink: true }}
                      />
                    </Col>
                    <Col md={6}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Trade Name
                      </label>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="tradename"
                        placeholder="Trade Name"
                        value={this.state.tradeName}
                        onChange={this.handleTradeNameChange.bind(this)}
                        name="tradename"
                        autoComplete="tradename"
                        InputlabelProps={{ shrink: true }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "5px" }}>
                    <Col md={6}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        GSTN No
                      </label>
                      <TextField
                        fullWidth
                        required
                        disabled
                        variant="outlined"
                        id="gstnNumber"
                        placeholder="GSTN Number"
                        value={this.state.gstn}
                        onChange={this.handleGstnChange.bind(this)}
                        // onBlur={this.handleGstnOnBlur.bind(this)}
                        name="phoneNugstnNumbermber"
                        autoComplete="gstnNumber"
                        InputlabelProps={{ shrink: true }}
                      />
                    </Col>
                    <Col md={6}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        PAN No
                      </label>
                      <TextField
                        fullWidth
                        required
                        disabled
                        variant="outlined"
                        id="panNumber"
                        placeholder="panNo"
                        value={this.state.panNo}
                        onChange={this.handlePanNumberChange.bind(this)}
                        name="panNumber"
                        autoComplete="panNumber"
                        InputlabelProps={{ shrink: true }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "5px" }}>
                    <Col md={6}>
                      <label for="useTradeNameForInvoicing">
                        Use Trade Name for Invoicing?
                      </label>
                      {/* </Col> */}
                      {/* <Col md={3}> */}
                      {this.state.usetnforinvoicing === true && (
                        <ToggleButton
                          className="ToggleButtonActive"
                          onClick={this.handleUseTradeNameForInvoicingChange.bind(
                            this
                          )}
                        >
                          Yes
                        </ToggleButton>
                      )}
                      {this.state.usetnforinvoicing === false && (
                        <ToggleButton
                          className="ToggleButtonInActive"
                          onClick={this.handleUseTradeNameForInvoicingChange.bind(
                            this
                          )}
                        >
                          No
                        </ToggleButton>
                      )}
                    </Col>
                    <Col md={6}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Phone Number
                      </label>
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        value={this.state.phone}
                        onChange={this.handlePhoneNumberChange.bind(this)}
                        name="phoneNumber"
                        autoComplete="phoneNumber"
                        InputlabelProps={{ shrink: true }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "5px" }}>
                    <Col md={12}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Website
                      </label>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="website"
                        placeholder="Website"
                        value={this.state.website}
                        onChange={this.handleWebsiteChange.bind(this)}
                        name="phoneNumber"
                        autoComplete="phoneNumber"
                        InputlabelProps={{ shrink: true }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Address
                    ref={this.addressRef}
                    title="Address"
                    data={this.state.address}
                    action="edit"
                    onChange={this.handleAddressChange.bind(this)}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div style={{ textAlign: "center" }}>
                <SaveButton type="submit" />
                <CancelButton onClick={(e) => this.cancel()} />
              </div>
            </CardFooter>
          </ValidatorForm>
        </Card>
      </div>
    );
  }
}

export default Organization;
