import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DoneIcon from "@mui/icons-material/Done";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RefreshIcon from "@material-ui/icons/Refresh";
import RepeatIcon from "@material-ui/icons/Repeat";
import { Autocomplete, InputAdornment } from "@mui/material";
import { format } from "date-fns";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import FetchServerData from "../../../../provider/FetchServerData";
import PurchaseUtils from "../../../../provider/PurchaseUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import Address from "../../address/Address";
import AuditNoteList from "../../auditNote/AuditNoteList";
import AddIconButton from "../../buttons/AddIconButton";
import CancelButton from "../../buttons/CancelButton";
import CreateButton from "../../buttons/CreateButton";
import SaveButton from "../../buttons/SaveButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import PurchaseInvoiceDialogue from "../PurchaseInvoiceDialogue";
import "./PurchaseOrder.scss";
import PurchaseOrderItem from "./PurchaseOrderItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";

export default class PurchaseOrder extends Component {
  /* References Used */
  itemReferenceList = [];
  billingAddressRef = null;
  shippingAddressRef = null;
  auditNoteListRef = null;
  maxDate = new Date().getMonth() + 1;
  minDate = new Date().getFullYear() - 1;
  supplierAdvanceVoucherList = [];
  /* Data Used from Store */
  header = store.getState().header.header;
  currencyList = store.getState().staticData.currencyList;
  countryList = store.getState().staticData.countryList;
  defaultCountry = store.getState().organization.organization.defaultCountry;
  defaultCurrency = store.getState().organization.organization.defaultCurrency;
  issalesthrecom = store.getState().setting.general.issalesthrecom;
  sezInvoiceTypeList = store.getState().appData.sezInvoiceTypeList;
  supplierList = PurchaseUtils.getNonRCMSuppliers(
    store.getState().master.masterData.suppliers
  );
  exportInvoiceTypeList = store.getState().appData.expInvoiceTypeList;
  businessUnitList = store.getState().master.masterData.businessUnits;
  etinInfoList = store.getState().master.masterData.entinList;
  uploadAttachmentServiceUrl = "/purchasedocms/uploaddocument";
  deleteAttachmentServiceUrl = "/purchasedocms/delete";
  /* Data Passed from Parent Component */
  privilegeList = [];
  /* URL for Payment Transaction Component */
  addPaymentTransactionServiceUrl = "/purchasePaymentTxMS/create";
  updatePaymentTransactionServiceUrl = "/purchasePaymentTxMS/update";
  deletePaymentTransactionServiceUrl = "/purchasePaymentTxMS/delete";
  breadCrumb = [];
  title = "Purchase Voucher Details";
  constructor(props) {
    super(props);
    let invoiceId = props.invoiceId;
    let invnumber = props.invnumber;
    let action = props.action;
    let isHistory = props.source && props.source === "history" ? true : false;
    let isDuplicate = null;
    let inputInvoice = null;
    /* Set Data From history */
    if (
      Utils.isNull(invoiceId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      invoiceId = props.history.location.state.invoiceId;
      isDuplicate = props.history.location.state.isDuplicate;
      inputInvoice = props.history.location.state.inputInvoice;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
      isHistory = props.history.location.state.isHistory
        ? props.history.location.state.isHistory
        : false;
    }

    /* Set Empty PurchaseVoucher Data */
    let emptyInvoice = PurchaseUtils.getEmptyInvoice();
    emptyInvoice.id = invoiceId;
    emptyInvoice.action = action;
    emptyInvoice.render = false;
    emptyInvoice.invoicetype = {
      type: "SA",
    };
    this.state = emptyInvoice;
    this.state.isHistory = isHistory;
    /*If Create Operation then Show Form Directly */
    if ("create" === action) {
      if (isDuplicate && isDuplicate === true && inputInvoice) {
        this.state = inputInvoice;
        this.state.action = action;
        this.state.id = null;
        this.state.year = "";
        this.state.month = "";
        this.state.invnumber = "";
        this.state.invoicedate = null;
        this.state.gstdate = null;
        this.state.payment = null;
        this.state.referencingInvoices = [];
        this.state.status = null;
        this.state.paymentTransactions = [];
        this.state.auditnotes = [];
        this.state.refinvoice = null;
        this.state.showAdvanceVoucher = false;
        this.state.pageSize = 10;
        this.state.initialPage = 0;
      } else {
        let year = "";
        let month = "";
        if (Utils.isNotNullAndEmpty(emptyInvoice.invoicedate)) {
          let date = new Date(emptyInvoice.invoicedate, "yyyy-MM-dd");
          year = date.split("-")[0];
          month = Utils.getMonth(date.split("-")[1]);
        }
        this.state.year = year;
        this.state.month = month;
        this.state.year = year;
        this.state.month = month;
        this.state.billtoaddress =
          store.getState().appData.settings.org?.addressvo;

        let item = PurchaseUtils.createEmptyInvoiceItem();
        this.state.invoiceitems = [];
        this.state.invoiceitems.push(item);
        this.state.pageSize = 10;
        this.state.initialPage = 0;
        this.state.bu = this.businessUnitList[0];
        // this.itemReferenceList.push(React.createRef());
        // this.setState({ render: true, year: year, month: month });
      }
      this.state.render = true;
    } else {
      /* For Edit and View get data */
      // this.setState({ render: false });

      this.state.billtoaddress =
        store.getState().appData.settings.org?.addressvo;

      this.state.render = false;
      if (Utils.isNotNullAndEmpty(invnumber)) {
        this.getPurchaseVoucherByNumberFromBackend(invnumber);
      }
      if (Utils.isNotNullAndEmpty(invoiceId)) {
        this.getPurchaseVoucherFromBackend(invoiceId);
      }
    }
  }

  /* ToolBar Handlers */
  printButtonClicked() {
    this.props.history.push({
      pathname: "/purchaseOrderPrint",
      state: {
        invoice: this.state,
        action: "view",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  editButtonClicked() {
    this.props.history.push({
      pathname: "/purchaseOrderEdit",
      state: {
        invoiceId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
    // this.setState({ action: 'edit' });
  }
  deleteButtonClicked() {
    this.deletePurchaseVoucherInBackend();
  }

  createSimilarButtonClicked() {
    this.props.history.push({
      pathname: "/purchaseOrderCreate",
      state: {
        action: "create",
        isDuplicate: true,
        inputInvoice: this.state,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  /* Set Invoice Data to Component State */
  setResponseToState(invoice) {
    if (Utils.isNull(invoice.privileges)) {
      invoice.privileges = this.state.privileges;
    }
    this.setState({
      doctypes: invoice.doctypes,
      invoiceDocuments: invoice.invoiceDocuments,
      auditnotes: invoice.auditnotes,
      bankaccount: invoice.bankaccount,
      barcode: invoice.barcode,
      barcodeImage: invoice.barcodeImage,
      billtoaddress: invoice.billtoaddress,
      shiptoaddress: invoice.shiptoaddress,
      bu: invoice.bu,
      cdnreason: invoice.cdnreason,
      cess: invoice.cess,
      cesspercent: invoice.cesspercent,
      cgst: invoice.cgst,
      createdon: invoice.createdon,
      currency: invoice.currency,
      supplier: invoice.supplier,
      description: invoice.description,
      electronicreference: invoice.electronicreference,
      etin: { etin: invoice.etin },
      exchangerate: invoice.exchangerate,
      expinvoicetype: invoice.expinvoicetype,
      freez: invoice.freez,
      fright: invoice.fright,
      gstdate: invoice.gstdate,
      id: invoice.id,
      igst: invoice.igst,
      invnumber: invoice.invnumber,
      invoicedate: invoice.invoicedate,
      // invoicesource: invoice.invoicesource,
      invoicesource: {
        source: "USERCREATED",
      },
      invoiceStatusList: invoice.invoiceStatusList,
      capitalasset: invoice.capitalasset,
      isbillingshippingsame: invoice.isbillingshippingsame,
      isimported: invoice.isimported,
      isexport: invoice.isexport,
      ismanualrefinv: invoice.ismanualrefinv,
      isnilrated: invoice.isnilrated,
      ispregst: invoice.ispregst,
      isrcmsale: invoice.isrcmsale,
      isRecurring: invoice.isRecurring,
      isroundup: invoice.isroundup,
      issez: invoice.issez,
      isSystemGenerated: invoice.isSystemGenerated,
      loadingpackaging: invoice.loadingpackaging,
      manualrefinv: invoice.manualrefinv,
      miscellaneous: invoice.miscellaneous,
      month: invoice.month,
      payment: invoice.payment,
      paymentTransations: invoice.paymentTransations,
      privileges: invoice.privileges,
      purchasedate: invoice.purchasedate,
      purchaseorder: invoice.invnumber,
      referencedInvoice: invoice.referencedInvoice,
      referenceinvnumber: invoice.referenceinvnumber,
      referencingInvoices: invoice.referencingInvoices,
      refinvoice: invoice,
      salesthrecom: invoice.salesthrecom,
      satype: invoice.satype,
      sezinvoicetype: invoice.sezinvoicetype,
      sgst: invoice.sgst,
      sharewithcust: invoice.sharewithcust,
      supplytype: invoice.supplytype,
      status: invoice.status,
      totaladvance: invoice.totaladvance,
      totalamount: invoice.totalamount,
      totalgst: invoice.totalgst,
      totalinwords: invoice.totalinwords,
      totaltaxable: invoice.totaltaxable,
      totaltaxableinwords: invoice.totaltaxableinwords,
      utgst: invoice.utgst,
      year: invoice.year,
      invoiceitems: invoice.invoiceitems,
      render: true,
    });
    this.getMenuIconsAsPerStatusAndPermission();
    if (this.auditNoteListRef && this.auditNoteListRef.current) {
      this.auditNoteListRef.current.setState({
        auditNoteList: this.state.auditnotes,
      });
    }
  }

  /*Handlers for each Element on Component */
  setMonthYear(invdate) {
    let date = new Date(invdate);
    let year = date.getFullYear();
    let month = Utils.getMonth(date.getMonth() + 1);
    this.setState({ month: month, year: year });
  }
  handlePurchaseVoucherDateChange(date) {
    let invdate = format(new Date(date), "yyyy-MM-dd");
    this.setMonthYear(invdate);
    this.setState({ invoicedate: date, gstdate: date });
  }
  handleGstDateChange(date) {
    this.setState({ gstdate: date });
  }
  handlePurchaseVoucherDateBlurChange(e) {
    this.setExchangeRate(this.state.currency);
  }
  handleMiscellaneousChange(e) {
    this.setState({ miscellaneous: e.target.value });
  }
  handleMiscellaneousBlurChange(e) {
    this.doCalculations();
  }
  handleAssetChange(e) {
    this.setState({ capitalasset: e.target.checked });
  }
  handlePurchaseVoucherNumberChange(e) {
    this.setState({ invnumber: e.target.value });
  }
  handlePurchaseVoucherparseFloatOnBlur(e) {
    this.setState({ invnumber: e.target.value });
  }
  handleSupplierChange(e) {
    let supplier = {};
    if (this.state.supplier) {
      supplier = this.state.supplier;
    } else {
      supplier.country = this.defaultCountry;
    }
    supplier.name = e.target.value;

    this.setState({ supplier: supplier });
  }

  handleIsNillRatedChange(e) {
    this.setState({ isnilrated: e.target.checked });
  }

  handleBillingShippingSame(e) {
    if (e.target.checked) {
      this.setState({ isbillingshippingsame: "YES" });
    } else {
      this.setState({ isbillingshippingsame: "NO" });
    }
  }

  handleIsSupplierChange(e) {
    let supplier = this.state.supplier;
    if (e.target.checked === false) {
      supplier = null;
    }
    this.setState({ isSupplier: e.target.checked, supplier: supplier });
  }
  handleIsRecurringChange(e) {
    this.setState({ isRecurring: e.target.checked });
  }

  handleRemarkChange(e) {
    this.setState({ description: e.target.value });
  }

  handleExchangeRateChange(e) {
    this.setState({ exchangerate: e.target.value });
  }

  handleIsAdvanceReceivedChange(e) {
    if (
      Utils.isNull(this.state.supplier) ||
      Utils.isNull(this.state.supplier.id)
    ) {
      PtsAlert.error("Please Select Supplier");
      document.getElementById("supplier").focus();
      // this.setState({ isAdvanceReceived: e.target.checked });
      return;
    }
    if (e.target.checked === true) {
      /*Get Advance Voucher for Customer*/
      let postObject = {
        supplier: {
          id: this.state.supplier.id,
        },
        header: this.header,
      };
      this.setState({ isAdvanceReceived: e.target.checked });
      FetchServerData.callPostService(
        "/supplierms/getsupreceipts",
        postObject
      ).then((output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          this.supplierAdvanceVoucherList = output.data.invoices;
          this.setState({ render: true });
        } else {
        }
      });
    } else {
      this.supplierAdvanceVoucherList = [];
      this.setState({ isAdvanceReceived: e.target.checked, refinvoice: null });
    }
  }
  addAuditNote(e) {
    this.setState({ auditNote: e.target.value });
  }
  updateSupplier(e, supplier) {
    let orgAddress = store.getState().appData.settings.org?.addressvo;

    if (Utils.isNotNull(supplier.addressvo)) {
      this.setState({ supplier: supplier, billtoaddress: orgAddress });
      if (
        Utils.isNotNull(supplier.addressvo.statevo) &&
        Utils.isNotNull(supplier.addressvo.statevo.countryvo)
      ) {
        this.setState({
          currency: supplier.addressvo.statevo.countryvo.currency,
        });
        this.setExchangeRateForSupplier(supplier);
      }
    } else {
      this.setState({ supplier: supplier });
    }
  }
  updateAdvanceVoucher(e, av) {
    this.setState({ advanceNumber: av });
  }
  updateBusinessUnit(e, bu) {
    this.setState({ bu: bu });
  }

  setExchangeRate(currency) {
    if (Utils.isNull(currency)) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCurrency(currency, this.defaultCurrency)
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let base = this.defaultCurrency?.isocode;
    let invoiceDate = this.state.invoicedate;
    if (
      !Utils.isNotNullAndEmpty(currency) ||
      !Utils.isNotNullAndEmpty(invoiceDate)
    ) {
      return;
    }
    if (!Utils.isSupportedCurrency(currency.isocode)) {
      PtsAlert.warn('Currency "' + currency.name + '" Not Supported');
      return;
    }

    let postObject = {
      targetCurrency: currency.isocode,
      currencyRate: {
        basecurrency: base,
        date: invoiceDate,
      },
      header: this.header,
    };

    FetchServerData.callPostService(
      "/exchangeratems/getExchangeRate",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        // let rates = JSON.parse(output.data.rates);
        let rate = output.data;
        if (Utils.isNotNull(rate.value)) {
          let value = rate.value;
          let exchangeRate = 1 / parseFloat(value);
          this.setState({ exchangerate: exchangeRate });
          return;
        } else {
          this.setState({ exchangerate: "" });
        }
      } else {
      }
    });
  }
  setExchangeRateForSupplier(supplier) {
    if (
      Utils.isNull(supplier) ||
      Utils.isNull(supplier.addressvo) ||
      Utils.isNull(supplier.addressvo.statevo) ||
      Utils.isNull(supplier.addressvo.statevo.countryvo)
    ) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCountry(
        supplier.addressvo.statevo.countryvo,
        this.defaultCountry
      )
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let currency = supplier.addressvo.statevo.countryvo.currency;
    this.setExchangeRate(currency);
  }

  updateExportType(e, expType) {
    this.setState({ expinvoicetype: expType });
  }

  updateCurrency(e, currency) {
    this.state.currency = currency;
    if (currency.isocode === this.defaultCurrency?.isocode) {
    } else {
      this.setExchangeRate(currency);
    }
    this.setState({ currency: currency });
    this.doCalculations();
  }
  onPaymentChange(paymentList) {
    // this.state.paymentTransations = paymentList;
    this.setState({ paymentTransations: paymentList });
    // this.setState({ paymentTransations: paymentList });
  }
  handleIsSEZChange(e) {
    if (e.target.checked) {
      this.setState({ issez: "Yes" });
    } else {
      this.setState({ issez: "No" });
    }
  }
  updateSezInvoiceType(e, seztype) {
    this.setState({ sezinvoicetype: seztype });
  }
  handleSalesThroughECOMChange(e) {
    this.setState({ salesthrecom: e.target.checked, etin: null });
  }
  handleEtinChange(e, selected) {
    this.setState({ etin: selected });
  }
  getETINDetails(e) {
    let etin = this.state.etin;
    if (this.validateGSTN(etin, "etin", "ETIN")) {
      /*Call Service to Get ETIN Details */
    } else {
    }
  }
  validateGSTN(gstn, elementname, elementlabel) {
    if (null === gstn || gstn.length <= 0) {
      document.getElementById(elementname).focus();
      PtsAlert.error("Enter " + elementlabel);
      return false;
    } else if (gstn.length < 15) {
      document.getElementById(elementname).focus();
      PtsAlert.error(elementlabel + " Must be 15 Characters long");
      return false;
    } else {
      let gstnPattern =
        "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
      let patternArray = gstn.match(gstnPattern);
      if (patternArray === null) {
        PtsAlert.error(
          "Enter Valid " +
            elementlabel +
            " No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
        );
        return false;
      }
      return true;
    }
  }
  /* Backend Call to Populate Advance Voucher */
  populateAdavnceVoucher(e) {
    if (
      Utils.isNull(this.state.advanceNumber) ||
      Utils.isNull(this.state.advanceNumber.id)
    ) {
      return;
    }
    const postObject = {
      invoice: { id: this.state.advanceNumber.id },
      header: this.header,
    };
    FetchServerData.callPostService("/prems/get", postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let invoice = output.data;
        let newItemList = [];
        this.setState({ invoiceitems: [] });
        for (let i = 0; i < invoice.invoiceitems.length; i++) {
          let item = invoice.invoiceitems[i];
          item.id = null;
          item.uom = null;
          item.quantity = 0;
          item.taxable = 0;
          item.taxrate.rate = 0;
          item.rate = 0;
          item.total = 0;
          item.igst = 0;
          item.sgst = 0;
          item.cgst = 0;
          item.cess = 0;
          item.operation = "add";
          newItemList.push(item);
          // this.itemReferenceList.push(React.createRef());
        }
        this.setState({
          refinvoice: invoice,
          invoiceitems: newItemList,
          createdon: invoice.createdon,
          referenceinvnumber: invoice.invnumber,
          billtoaddress: invoice.billtoaddress,
          shiptoaddress: invoice.shiptoaddress,
          showAdvanceVoucher: false,
        });
        this.doCalculations();
      } else {
      }
    });
  }
  /* PurchaseVoucher Item Functionality */
  addPurchaseVoucherItem() {
    let items = this.state.invoiceitems;

    let item = PurchaseUtils.createEmptyInvoiceItem();
    items.push(item);
    // this.itemReferenceList.push(React.createRef());
    this.setState({ invoiceitems: items });
  }

  doCalculations() {
    let totalAmount = 0.0;
    let totalTaxable = 0.0;
    let totalCgst = 0.0;
    let totalSgst = 0.0;
    let totalIgst = 0.0;
    let totalCess = 0.0;
    for (let i = 0; i < this.itemReferenceList.length; i++) {
      if (this.itemReferenceList[i] && this.itemReferenceList[i].current) {
        let taxable = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.taxable
        );
        let cgst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.cgst
        );
        let sgst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.sgst
        );
        let igst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.igst
        );
        let cess = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.cess
        );
        let total = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.total
        );
        if (cess > 0) {
          let calculatedCess = (parseFloat(taxable) * parseFloat(cess)) / 100;
          totalCess = parseFloat(totalCess) + parseFloat(calculatedCess);
        }
        totalTaxable = parseFloat(totalTaxable) + parseFloat(taxable);
        totalCgst = parseFloat(totalCgst) + parseFloat(cgst);
        totalSgst = parseFloat(totalSgst) + parseFloat(sgst);
        totalIgst = parseFloat(totalIgst) + parseFloat(igst);
        total =
          parseFloat(taxable) +
          parseFloat(cgst) +
          parseFloat(sgst) +
          parseFloat(igst) +
          parseFloat(totalCess);
        totalAmount = parseFloat(totalAmount) + parseFloat(total);
      }
    }
    let miscellaneous = Utils.getFloatValue(this.state.miscellaneous);
    totalAmount = parseFloat(totalAmount) + parseFloat(miscellaneous);

    // Utils.getAmountInWords(totalAmount, this.state.currency).then((output) => {
    //   this.setState({ totalinwords: output });
    // });
    let amountInWords = Utils.getAmountInWords(
      totalAmount,
      this.state.currency
    );
    this.setState({
      totaltaxable: parseFloat(totalTaxable).toFixed(2),
      sgst: parseFloat(totalSgst).toFixed(2),
      cgst: parseFloat(totalCgst).toFixed(2),
      igst: parseFloat(totalIgst).toFixed(2),
      cess: parseFloat(totalCess).toFixed(2),
      totalamount: parseFloat(totalAmount).toFixed(2),
      totalinwords: amountInWords,
    });
  }

  handlePODateChange(date) {
    // let podate = format(new Date(e.target.value), "yyyy-MM");
    this.setState({ purchasedate: date });
  }
  invoiceItemChanged(item) {
    this.doCalculations();
  }

  invoiceItemDeleted(item) {
    let items = [];
    let itemToTrash = -999;
    for (let i = 0; i < this.state.invoiceitems.length; i++) {
      let invItem = this.state.invoiceitems[i];
      if (Utils.equalsIgnoreCase(this.state.action, "create")) {
        if (item.isDelete === true && item.index === i) {
          itemToTrash = i;
          continue;
        } else {
          invItem =
            this.itemReferenceList[i] && this.itemReferenceList[i].current
              ? this.itemReferenceList[i].current.state
              : invItem;
          items.push(invItem);
        }
      } else {
        if (item.isDelete === true && item.index === i) {
          if (Utils.equalsIgnoreCase(item.operation, "add")) {
            itemToTrash = i;
            continue;
          }
          invItem.operation = "delete";
        } else {
          invItem =
            this.itemReferenceList[i] && this.itemReferenceList[i].current
              ? this.itemReferenceList[i].current.state
              : invItem;
        }
        items.push(invItem);
      }
    }

    for (let i = 0; i < this.state.invoiceitems.length; i++) {
      if (i === itemToTrash) {
      }
    }

    let randomKey = Math.random();
    this.setState({ refreshKey: randomKey, invoiceitems: items });
  }

  handleClickShowAdvanceVoucher() {
    this.setState({
      showAdvanceVoucher: this.state.showAdvanceVoucher,
      open: true,
    });
  }
  handleClose() {
    this.setState({
      open: false,
      showAdvanceVoucher: !this.state.showAdvanceVoucher,
    });
  }
  createAddressObject(address) {
    let addressvo = {
      id: address.id,
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      pin: address.pincode,
      statevo: {
        id: address.state.id,
        name: address.state.name,
        code: address.state.code,
        countryvo: {
          id: address.country.id,
          name: address.country.name,
          code: address.country.code,
        },
      },
    };

    return addressvo;
  }
  /* Validator */
  validatePurchaseVoucher(e) {
    e.preventDefault();
    /* Validate Items */
    for (let k = 0; k < this.state.invoiceitems.length; k++) {
      if (this.itemReferenceList[k] && this.itemReferenceList[k].current) {
        /* Set latest Values to Object */
        let currentItem = PurchaseUtils.createInvoiceItem(
          this.itemReferenceList[k].current.state
        );
        this.state.invoiceitems[k] = currentItem;
        if (!this.itemReferenceList[k].current.validateItem()) {
          return false;
        }
      }
    }
    let isBillingShippingSame = this.state.isbillingshippingsame;
    if (Utils.equalsIgnoreCase(isBillingShippingSame, "No")) {
      if (this.shippingAddressRef && this.shippingAddressRef.current) {
        /*Validate Shipping Address */
        if (!this.shippingAddressRef.current.validateAddress()) {
          return false;
        } else {
          let shippingAddress = this.createAddressObject(
            this.shippingAddressRef.current.state
          );
          this.state.shiptoaddress = shippingAddress;
        }
      } else {
        PtsAlert.error("Shipping Address: not provided");
        return;
      }
    }

    if (
      undefined === this.state.invoicedate ||
      null === this.state.invoicedate ||
      new Date() === this.state.invoicedate
    ) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Purchase Voucher Date is required");
      return false;
    } else {
      let currentDate = new Date();
      let invDate = new Date(this.state.invoicedate);
      let Difference_In_Time = currentDate.getTime() - invDate.getTime();
      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 540) {
        PtsAlert.error("Purchase Voucher Date is too old");
        return false;
      }

      Difference_In_Time = invDate.getTime() - currentDate.getTime();
      // To calculate the no. of days between two dates
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 31) {
        PtsAlert.error("Future Purchase Voucher Date");
        return false;
      }
    }

    if (
      undefined === this.state.gstdate ||
      null === this.state.gstdate ||
      new Date() === this.state.gstdate
    ) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Purchase Voucher GST Date is required");
      return false;
    }
    if (
      undefined === this.state.invnumber ||
      null === this.state.invnumber ||
      "" === this.state.invnumber
    ) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Purchase Voucher Number is required");
      return false;
    }

    if (undefined === this.state.bu || null === this.state.bu) {
      PtsAlert.error("Please Select Business Unit ");
      return false;
    }
    return true;
  }
  /* CallBack Functions for Dynamic Buttons created Based on PurchaseVoucher Status */
  requestVerification(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/pworkflow/requestverification",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        PtsAlert.success(
          "Verification Requested for PurchaseVoucher " + this.state.invnumber
        );
        // let invoice = output.data;
        // this.setResponseToState(invoice);
        this.props.history.push({
          pathname: "/po/",
        });
      } else {
      }
    });
  }

  rejectVerification(e) {
    let rejectionNote = this.state.auditNote ? this.state.auditNote : "";
    rejectionNote = rejectionNote.toString().trim();
    let auditNotes = [];
    if (rejectionNote.length > 0) {
      auditNotes.push({
        note: rejectionNote,
        createddate: new Date(),
      });
    } else {
    }

    let postObject = {
      invoice: { id: this.state.id, auditnotes: auditNotes },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/pworkflow/rejectverification",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        // let invoice = output.data;
        // invoice.privileges = this.state.privileges;
        PtsAlert.success(
          "Verification Rejected On PurchaseVoucher " + this.state.invnumber
        );
        // this.setResponseToState(invoice);
        this.props.history.push({
          pathname: "/po/",
        });
      } else {
      }
    });
  }

  verify(e) {
    let verificationNote = this.state.auditNote ? this.state.auditNote : "";
    verificationNote = verificationNote.toString().trim();
    let auditNotes = [];
    if (verificationNote.length > 0) {
      auditNotes.push({
        note: verificationNote,
        createddate: new Date(),
      });
    } else {
    }

    let postObject = {
      invoice: { id: this.state.id, auditnotes: auditNotes },
      header: this.header,
    };
    FetchServerData.callPostService("/pworkflow/verify", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "PurchaseVoucher " + this.state.invnumber + " Verified"
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);
          this.props.history.push({
            pathname: "/po/",
          });
        } else {
        }
      }
    );
  }
  revertVerification(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/pworkflow/revertverification",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        PtsAlert.success(
          "Verification Reverted On PurchaseVoucher " +
            this.state.invnumber +
            " "
        );
        // let invoice = output.data;
        // invoice.privileges = this.state.privileges;
        // this.setResponseToState(invoice);
        this.props.history.push({
          pathname: "/po/",
        });
      } else {
      }
    });
  }
  modify(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/pworkflow/modify", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "PurchaseVoucher " + this.state.invnumber + " Set for Modification"
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);
          this.props.history.push({
            pathname: "/po/",
          });
        } else {
        }
      }
    );
  }

  convertToPurchaseVoucher(e) {
    this.props.history.push({
      pathname: "/purchaseVoucherCreate",
      state: {
        action: "create",
        isDuplicate: true,
        inputInvoice: this.state,
        breadCrumb: this.breadCrumb,
        pageId: "purchaseOrder",
      },
    });
  }

  revertCancel(e) {
    let postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/pworkflow/revertcancel", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "PurchaseVoucher " + this.state.invnumber + " Set for Modification"
          );
          // let invoice = output.data;
          // invoice.privileges = this.state.privileges;
          // this.setResponseToState(invoice);
          this.props.history.push({
            pathname: "/po/",
          });
        } else {
        }
      }
    );
  }

  /* Backend Calls to CREATE/GET/UPDATE/DELETE PurchaseVoucher */
  deletePurchaseVoucherInBackend() {
    const postObject = {
      po: { id: this.state.id },
      header: this.header,
    };

    FetchServerData.callPostService("/poms/delete", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("PurchaseVoucher Deleted ");
          this.props.history.push({
            pathname: "/po",
            state: { invoiceId: this.state.id, action: "view" },
          });
        } else {
        }
      }
    );
  }

  getPurchaseVoucherByNumberFromBackend(invnum) {
    const postObject = {
      invoice: { invnumber: invnum },
      header: this.header,
    };
    FetchServerData.callPostService("/psams/getinvbynum", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          for (let i = 0; i < invoice.invoiceitems.length; i++) {
            // let ref = React.createRef();
            // this.itemReferenceList.push(ref);
            invoice.invoiceitems[i].operation = "update";
          }
          this.privilegeList = invoice.privileges;
          // this.shippingAddressRef = React.createRef;
          // this.billingAddressRef = React.createRef;
          this.setResponseToState(invoice);
        } else {
        }
      }
    );
  }
  getPurchaseVoucherFromBackend(id) {
    const postObject = {
      po: { id: id },
      header: this.header,
    };
    FetchServerData.callPostService("/poms/get", postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let invoice = output.data;
        if (Utils.isNotNull(invoice.refinvoice)) {
          // let isAdvanceReceived = true;
          let advanceNumber = {
            id: invoice.refinvoice.id,
            invoiceNumber: invoice.refinvoice.invnumber,
          };
          this.setState({
            isAdvanceReceived: true,
            advanceNumber: advanceNumber,
          });
        }
        for (let i = 0; i < invoice.invoiceitems.length; i++) {
          // let ref = React.createRef();
          // this.itemReferenceList.push(ref);
          invoice.invoiceitems[i].operation = "update";
        }
        this.privilegeList = invoice.privileges;
        // this.shippingAddressRef = React.createRef;
        // this.billingAddressRef = React.createRef;
        this.setResponseToState(invoice);
      } else {
      }
    });
  }

  updatePurchaseVoucherInBackend(e) {
    if (!this.validatePurchaseVoucher(e)) {
      return;
    }
    let invoiceData = {};
    invoiceData = PurchaseUtils.setInvoice(invoiceData, this.state);
    for (let i = 0; i < invoiceData.invoiceitems.length; i++) {
      const element = invoiceData.invoiceitems[i];
      if (element.quantity <= 0) {
        PtsAlert.error(
          `Quantity for ${element.name} should be greater then zero`
        );
        return;
      }
    }
    const postObject = {
      po: invoiceData,
      header: this.header,
    };

    FetchServerData.callPostService("/poms/update", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("Updated Successfully");
          // let invoice = output.data;
          // for (let i = 0; i < invoice.invoiceitems.length; i++) {
          //   let ref = React.createRef();
          //   this.itemReferenceList.push(ref);
          //   invoice.invoiceitems[i].operation = 'update';
          // }
          // this.setResponseToState(invoice);

          // this.setState({ render: true, action: 'view' });
          this.props.history.push({
            pathname: "/purchaseOrder",
            state: {
              invoiceId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  createPurchaseVoucherInBackend(e) {
    e.preventDefault();
    if (!this.validatePurchaseVoucher(e)) {
      return;
    }
    let invoiceData = {};
    invoiceData = PurchaseUtils.setInvoice(invoiceData, this.state);
    for (let i = 0; i < invoiceData.invoiceitems.length; i++) {
      const element = invoiceData.invoiceitems[i];
      if (element.quantity <= 0) {
        PtsAlert.error(
          `Quantity for ${element.name} should be greater then zero`
        );
        return;
      }
    }
    const postObject = {
      po: invoiceData,
      header: this.header,
    };

    FetchServerData.callPostService("/poms/create", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          for (let i = 0; i < invoice.invoiceitems.length; i++) {
            // let ref = React.createRef();
            // this.itemReferenceList.push(ref);
            invoice.invoiceitems[i].operation = "update";
          }
          this.setResponseToState(invoice);
          // this.setState({ render: true, action: 'view' });
          let purchaseOrderState = {
            invoiceId: invoice.id,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(
            ApplicationStateAction.setApplicationState({ purchaseOrderState })
          );
          this.props.history.push({
            pathname: "/purchaseOrder",
            state: {
              invoiceId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  /* Go Back Functionality Implementation */
  cancelEdit(e) {
    // this.setState({ action: 'view', render: false });
    // this.getPurchaseVoucherFromBackend(this.state.id);
    // this.setState({ action: 'view' });
    this.props.history.push({
      pathname: "/purchaseOrder",
      state: {
        invoiceId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  cancelView(e) {
    if (this.state.isHistory && this.state.isHistory === true) {
      this.props.history.push({
        pathname: "/purchase_invoicesearch/",
      });
    } else {
      this.props.history.push({
        pathname: "/po/",
        state: {},
      });
    }
  }

  generateInvoiceNumber(e) {
    if (this.state.invoicedate) {
    } else {
      PtsAlert.error("Please Select Invoice Date");
      return;
    }
    let invDate = moment(this.state.invoicedate).format("YYYY-MM-DD");

    let documentFormat = {
      forDate: invDate,
      documenttype: {
        type: "PO",
      },
    };
    let postObject = {
      documentNumber: documentFormat,
      header: this.header,
    };
    FetchServerData.callPostService(
      "/docnumms/generatePurchase",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let newNumber = output.data.genratedNumber;
        this.setState({ invnumber: newNumber });
      } else {
      }
    });
  }
  backToHistory(e) {
    this.props.history.push({
      pathname: "/purchase_invoicesearch/",
    });
  }
  /* Get Bttons Based on Invoice Status and Permissions*/
  getButtonsAsPerStatusAndPermission() {
    let status = this.state.status ? this.state.status?.status : "";
    // let privilegeList = this.privilegeList;
    if ("CREATED" === status.toUpperCase()) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.state.currency?.isocode !== this.defaultCurrency?.isocode && (
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              onClick={(e) => this.showReferenceINRInvoice(e)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              &nbsp; {`View In ${this.defaultCurrency?.isocode}`}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            size="small"
            style={{ background: "#9a7031", color: "#fff", margin: "0.5em" }}
            // startIcon={<CancelIcon />}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.convertToPurchaseVoucher(e)}
          >
            Create Purchase Voucher
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            style={{ background: "#717373", color: "#fff", margin: "0.5em" }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.state.currency?.isocode !== this.defaultCurrency?.isocode && (
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              onClick={(e) => this.showReferenceINRInvoice(e)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              &nbsp; {`View In ${this.defaultCurrency?.isocode}`}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            size="small"
            style={{ background: "#717373", color: "#fff", margin: "0.5em" }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    }
  }

  /* Get Menu Buttons based on Invoice Status and Permissions */
  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];
    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }
    if (Utils.equalsIgnoreCase(this.state.status?.status, "CREATED")) {
      if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
        buttonList = [...buttonList, "EDIT"];
      }
      if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
        buttonList = [...buttonList, "DELETE"];
      }
      if (Utils.hasPrivilege("READ", this.state.privileges)) {
        buttonList = [...buttonList, "PRINT"];
      }
    } else if (Utils.equalsIgnoreCase(this.state.status?.status, "PVCREATED")) {
      buttonList = ["PRINT"];
    } else if (
      Utils.equalsIgnoreCase(this.state.status?.status, "VERIFICATIONREJECTED")
    ) {
      buttonList = ["PRINT"];
    } else {
      buttonList = ["PRINT"];
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      let printCallback = null;
      let createSimilarCallback = null;

      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      if (buttonList.includes("PRINT")) {
        printCallback = this.printButtonClicked.bind(this);
        // createSimilarCallback = this.createSimilarButtonClicked.bind(this);
      }
      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        printCallback: printCallback,
        createSimilarCallback: createSimilarCallback,
      });
    }
  }

  showReferenceINRInvoice() {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current &&
      this.state.invnumber
    ) {
      this.referenceInvoiceDialogueRef.current.showViewInINROrderInvoice(
        this.state.id
      );
    }
  }

  showReferenceInvoice() {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current &&
      this.state.referenceinvnumber
    ) {
      this.referenceInvoiceDialogueRef.current.showAdvanceInvoice(
        this.state.referenceinvnumber,
        this.state.supplier.id
      );
    }
  }
  getReferencingInvoices() {
    if (
      this.state.referencingInvoices &&
      this.state.referencingInvoices.length > 0
    ) {
      let refInvList = [];
      for (let i = 0; i < this.state.referencingInvoices.length; i++) {
        let inv = this.state.referencingInvoices[i];
        if (inv) {
          refInvList.push(
            <>
              <Link
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (
                    this.referenceInvoiceDialogueRef &&
                    this.referenceInvoiceDialogueRef.current
                  ) {
                    this.referenceInvoiceDialogueRef.current.showInvoice(
                      inv.invoiceNumber
                    );
                  }
                }}
              >
                <strong>{inv.invoiceNumber ? inv.invoiceNumber : ""}</strong>
              </Link>
              <span>,&nbsp;</span>
            </>
          );
        }
      }
      return refInvList;
    } else {
      return "";
    }
  }
  getHeader() {
    return (
      <Row md={12} style={{ padding: "5px", margin: "0" }}>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          <span style={{ float: "left" }}>#</span>
          <span style={{ float: "right" }}>SAC/HSN</span>
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          Item Details
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          UOM
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Qty
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Unit Price
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Gross Value
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Discount
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Net Value
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          CESS %
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Tax Rate %
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          GST
        </Col>

        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Total
        </Col>
      </Row>
    );
  }
  // handleRoundUpCheckBox = (e) => {
  //   let totalRoundValue = 0.0;
  //   if (e.target.checked === true) {
  //     totalRoundValue = Utils.getRoundValue(this.state.totalamount);
  //   } else {
  //     totalRoundValue = this.state.totalamount;
  //   }
  //   this.setState({
  //     isroundup: e.target.checked,
  //     totalamount: totalRoundValue,
  //   });
  // };
  handleRoundUpCheckBox = (e) => {
    // let totalRoundValue = this.state.totalamount;

    if (e.target.checked === true) {
      this.state.totalamount = Utils.getRoundValue(this.state.totalamount);
    } else {
      this.doCalculations();
      // totalRoundValue = this.state.totalamount;
    }
    this.setState({
      isroundup: e.target.checked,
      // totalamount: totalRoundValue,
    });
  };

  onCopy() {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);

    this.setState({
      copied: true,
    });
  }
  
  /*Main Render Function */
  render() {
    if (this.state.render === false) {
      return "";
    }
    /* Create References for Calculations */
    this.billingAddressRef = React.createRef();
    this.shippingAddressRef = React.createRef();
    this.menuWithBreadScrumRef = React.createRef();
    this.auditNoteListRef = React.createRef();
    this.referenceInvoiceDialogueRef = React.createRef();
    this.state.invoiceitems &&
      this.state.invoiceitems.forEach((element, index) => {
        this.itemReferenceList[index] = React.createRef();
      });
    if (this.state.action === "create") {
      return this.createPurchaseVoucher();
    } else if (this.state.action === "edit") {
      return this.editPurchaseVoucher();
    } else {
      return this.viewPurchaseVoucher();
    }
  }

  createPurchaseVoucher() {
    let invoiceItemsList = [];
    /* Create List of PurchaseVoucher Item */
    let isIgstApplicable = Utils.isIgstApplicable(this.state.supplier);
    let itemLoc = 1;
    this.state.invoiceitems &&
      this.state.invoiceitems.forEach((element, index) => {
        if ("delete" !== element.operation) {
          element.itemsrno = itemLoc++;
          let itemkey = index + "-" + this.state.refreshKey;
          invoiceItemsList.push(
            <PurchaseOrderItem
              ref={this.itemReferenceList[index]}
              backgroundColor={
                index % 2
                  ? { background: "#EFF4F7" }
                  : { background: "#F7F7F7" }
              }
              key={itemkey}
              isIgstApplicable={isIgstApplicable}
              data={element}
              srno={index}
              invoicedata={this.state.invoiceitems}
              iteamIndex={index}
              action="edit"
              productList={this.productList}
              custHsnCodeList={this.custHsnCodeList}
              onAdd={this.addPurchaseVoucherItem.bind(this)}
              doCalculations={this.doCalculations.bind(this)}
              onDelete={this.invoiceItemDeleted.bind(this)}
              onChange={this.invoiceItemChanged.bind(this)}
            />
          );
        }
      });
    let billingAddress = (
      <Address
        ref={this.billingAddressRef}
        title="Billing Address"
        data={this.state.billtoaddress}
        action="view"
      />
    );

    let shippingAddress = "";
    if (Utils.equalsIgnoreCase(this.state.isbillingshippingsame, "No")) {
      shippingAddress = (
        <Address
          ref={this.shippingAddressRef}
          title="Shipping Address"
          data={this.state.shiptoaddress}
          action="create"
        />
      );
    }

    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = false;
    if (this.state.supplier && this.state.supplier.addressvo) {
      let address = this.state.supplier.addressvo;
      if (
        (isExchangeRateMandator = Utils.isExchangeRateApplicableForCountry(
          address.statevo.countryvo,
          this.defaultCountry
        ))
      ) {
        this.state.exchangeRate = 1;
        isExchangeRateMandator = true;
      }
    }

    return (
      <div style={{ display: "grid" }}>
        <PurchaseInvoiceDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
          pageId="PurchaseVoucherItem"
        />

        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Create Purchase Order"
          history={this.props.history}
          name="Create Purchase Order"
        />

        <ValidatorForm
          style={{
            width: "100%",
            color: "#000",
            padding: "0.5em",
            marginTop: "1em",
          }}
          ref="form"
          onSubmit={this.createPurchaseVoucherInBackend.bind(this)}
        >
          <Card md={12}>
            <CardHeader style={{ margin: "0px", padding: "0.5em" }}>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Supplier<span className="asterisk"> *</span>
                  </label>
                  {/* <Autocomplete
                    disableClearable
                    id="supplier"
                    size="small"
                    options={
                      undefined === this.supplierList ||
                      null === this.supplierList
                        ? []
                        : this.supplierList
                    }
                    value={this.state.supplier}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => (
                      <label
                        style={{
                          padding: 0,
                          margin: 0,
                          wordWrap: "break-word",
                          color: "#000",
                        }}
                      >
                        {option.name}
                      </label>
                    )}
                    onChange={(event, value) => {
                      this.updateSupplier(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Supplier"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  /> */}
                  <Autocomplete
                    disableClearable
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    id="supplier"
                    size="small"
                    options={
                      undefined === this.supplierList ||
                      null === this.supplierList
                        ? []
                        : this.supplierList
                    }
                    value={this.state.supplier}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateSupplier(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Supplier"
                        style={{ background: "#fff", borderRadius: "5px" }}
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    PO Date<span className="asterisk"> *</span>
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        required
                        value={
                          this.state.invoicedate ? this.state.invoicedate : null
                        }
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="invoicedate"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        onChange={this.handlePurchaseVoucherDateChange.bind(
                          this
                        )}
                        onBlur={this.handlePurchaseVoucherDateBlurChange.bind(
                          this
                        )}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  {/* <div style={{ display: "flex" }}> */}
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    PO Number<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    required
                    fullWidth={true}
                    variant="outlined"
                    id="invnumber"
                    type="text"
                    placeholder="PO Number"
                    validators={["required"]}
                    onChange={this.handlePurchaseVoucherNumberChange.bind(this)}
                    value={this.state.invnumber ? this.state.invnumber : ""}
                    name="invnumber"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RefreshIcon
                            className="gstnBtn"
                            onClick={(e) => this.generateInvoiceNumber()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <span
                      style={{
                        background: "#2471A3",
                        padding: "0.8em",
                        cursor: "pointer",
                      }}
                    >
                      <RefreshIcon
                        style={{ color: "#fff", fontWeight: "bold" }}
                        onClick={(e) => this.generateInvoiceNumber()}
                      ></RefreshIcon>
                    </span> */}
                  {/* </div> */}
                </Col>
                {/* <Col md={2} style={{ padding: '5px', textAlign: 'left' }}>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      required
                      fullWidth={true}
                      variant="outlined"
                      inputProps={{ style: {} }}
                      InputLabelProps={{ style: {}, shrink: true }}
                      id="invnumber"
                      type="text"
                       placeholder="Voucher Number"
                      validators={['required']}
                      onChange={this.handlePurchaseVoucherNumberChange.bind(this)}
                      value={this.state.invnumber}
                      name="invnumber"
                    />

                  </div>
                </Col>*/}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Remark
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    inputProps={{ style: {} }}
                    InputLabelProps={{ style: {}, shrink: true }}
                    id="invoicenote"
                    type="text"
                    placeholder="Remark"
                    validators={["required"]}
                    onChange={this.handleRemarkChange.bind(this)}
                    // onBlur={this.handleNoteOnBlur.bind(this)}
                    value={this.state.description}
                    name="invoicenote"
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Business Unit<span className="asterisk"> *</span>
                  </label>
                  {/* <Autocomplete
                    disableClearable
                    id="businessUnit"
                    size="small"
                    options={this.businessUnitList ? this.businessUnitList : []}
                    value={this.state.bu}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => (
                      <label
                        style={{
                          padding: 0,
                          margin: 0,
                          wordWrap: "break-word",
                          color: "#000",
                        }}
                      >
                        {option.name}
                      </label>
                    )}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Business Unit"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  /> */}

                  <Autocomplete
                    disableClearable
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    id="businessUnit"
                    size="small"
                    options={this.businessUnitList ? this.businessUnitList : []}
                    value={this.state.bu}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Business Unit"
                        style={{ background: "#fff", borderRadius: "5px" }}
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isnilrated}
                        value={this.state.isnilrated}
                        onChange={this.handleIsNillRatedChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Nil Rated?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                {isExchangeRateMandator === true && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label className="form-label ">
                        Currency<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="currency"
                        size="small"
                        options={this.currencyList ? this.currencyList : []}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, state) => (
                          <label
                            style={{
                              padding: 0,
                              margin: 0,
                              wordWrap: "break-word",
                              color: "#000",
                            }}
                          >
                            {option.name}
                          </label>
                        )}
                        onChange={(event, value) => {
                          this.updateCurrency(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            required
                            variant="outlined"
                            {...params}
                            style={{}}
                            className="regCountry"
                            placeholder="Currency"
                          />
                        )}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Exchange Rate<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        inputProps={{ style: {} }}
                        InputLabelProps={{ style: {}, shrink: true }}
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        placeholder="Exchange Rate"
                        onChange={this.handleExchangeRateChange.bind(this)}
                        // onBlur={this.handleGSTNOnBlur.bind(this)}
                        value={this.state.exchangerate}
                        name="exchangeRate"
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Export Type<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        style={{ width: "100%", textAlign: "center" }}
                        id="expinvoicetype"
                        size="small"
                        options={
                          this.exportInvoiceTypeList
                            ? this.exportInvoiceTypeList
                            : []
                        }
                        value={this.state.expinvoicetype}
                        defaultValue={this.state.expinvoicetype}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          this.updateExportType(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            style={{}}
                            variant="outlined"
                            className="regCountry"
                            placeholder="Export Type"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSEZDeemedExport"
                        checked={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        value={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        onChange={this.handleIsSEZChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>SEZ/Deemed Export?</span>}
                  />
                </Col>

                {this.issalesthrecom && (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          id="isSalesThroughECOM"
                          checked={
                            this.state.salesthrecom &&
                            this.state.salesthrecom === true
                              ? true
                              : false
                          }
                          value={
                            this.state.salesthrecom &&
                            this.state.salesthrecom === true
                              ? true
                              : false
                          }
                          onChange={this.handleSalesThroughECOMChange.bind(
                            this
                          )}
                          style={{ background: "none" }}
                        />
                      }
                      label={<span style={{}}>Sales Through ECOM?</span>}
                    />
                  </Col>
                )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.capitalasset}
                        value={this.state.capitalasset}
                        onChange={this.handleAssetChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Capital Asset?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                {Utils.equalsIgnoreCase(this.state.issez, "Yes") ? (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      SEZ Category<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      disableClearable
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      id="sezCategory"
                      size="small"
                      options={this.sezInvoiceTypeList}
                      value={this.state.sezinvoicetype}
                      defaultValue={this.state.sezinvoicetype}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, value) => {
                        this.updateSezInvoiceType(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth={true}
                          {...params}
                          variant="outlined"
                          className="regCountry"
                          placeholder="SEZ Category"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Col>
                ) : (
                  <Col md={2}></Col>
                )}
                {this.state.salesthrecom &&
                  this.state.salesthrecom === true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        ETIN<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="etin"
                        size="small"
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        options={this.etinInfoList}
                        value={this.state.etin ? this.state.etin : null}
                        defaultValue={this.state.etin}
                        getOptionLabel={(option) => option.etin}
                        onChange={(event, value) => {
                          this.handleEtinChange(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth={true}
                            {...params}
                            variant="outlined"
                            className="regCountry"
                            placeholder="ETIN"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                  )}
              </Row>
              <Row
                md={12}
                style={{ alignItems: "center", margin: "0", padding: "0" }}
              >
                <Col md={8}></Col>
                <Col
                  md={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Checkbox
                    variant="outlined"
                    id="isBillingShippingSame"
                    label="Is Nill Rated"
                    checked={
                      Utils.equalsIgnoreCase(
                        this.state.isbillingshippingsame,
                        "Yes"
                      )
                        ? true
                        : false
                    }
                    onChange={this.handleBillingShippingSame.bind(this)}
                    style={{ color: "gray", background: "none" }}
                  />
                  <span style={{ color: "black" }}>Billing Shipping Same?</span>
                </Col>
              </Row>
              {this.state.supplier && (
                <Row
                  md={12}
                  style={{
                    // background: "#fff7ec",
                    margin: "0",
                    padding: "0",
                    borderRadius: "1em",
                  }}
                >
                  <Col
                    md={4}
                    style={{
                      border: "1px solid white",
                    }}
                  >
                    <Row md={12}>
                      <label
                        style={{
                          fontWeight: "600",
                          color: "#1b7189",
                          fontSize: "16px",
                        }}
                      >
                        <b> Supplier Details</b>
                      </label>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Name</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",

                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.name}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Address</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",

                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {Utils.getAddressAsText(this.state.supplier.addressvo)}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Type</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.suppliertypevo.description}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>GSTN</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.gstn}
                        {Utils.isNotNullAndEmpty(this.state.supplier.gstn) && (
                            <>
                            {!this.state.copied ? (
                              <Tooltip title="Copy" aria-label="add">
                                <IconButton className="ms-4">
                                  <CopyToClipboard
                                    text={this.state.supplier.gstn}
                                    onCopy={this.onCopy.bind(this)}
                                  >
                                    <FileCopyIcon
                                      style={{
                                        color: "gray",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </CopyToClipboard>
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Copied" aria-label="add">
                                <IconButton className="ms-4">
                                  <DoneIcon
                                    style={{
                                      color: "green",
                                      fontSize: "20px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>PAN</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.pan}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Currency</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.currency ? this.state.currency.name : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={4}
                    style={{
                      border: "1px solid white",
                    }}
                  >
                    {billingAddress}
                  </Col>
                  <Col
                    md={4}
                    style={{
                      border: "1px solid white",
                    }}
                  >
                    {shippingAddress}
                  </Col>
                </Row>
              )}
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ margin: "0" }}>
                <Col
                  md={11}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                  }}
                >
                  Purchase Order Item Details
                </Col>
                <Col
                  md={1}
                  style={{
                    borderRight: "1px solid #eceaea",
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  <AddIconButton
                    onClick={this.addPurchaseVoucherItem.bind(this)}
                  />
                </Col>
              </Row>
              {invoiceItemsList}

              <Row
                md={12}
                style={{ padding: "0px", margin: "0", marginTop: "1em" }}
              >
                <Col md={8} style={{ margin: "0", padding: "1em" }}></Col>
                <Col md={4} style={{ margin: "0", padding: "0" }}>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Value Of Supply</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {this.state.totaltaxable}
                    </Col>
                  </Row>
                  {Utils.isIgstApplicable(this.state.supplier) === false && (
                    <>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>CGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.cgst)}
                        </Col>
                      </Row>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>SGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.sgst)}
                        </Col>
                      </Row>
                    </>
                  )}
                  {Utils.isIgstApplicable(this.state.supplier) === true && (
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>IGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.igst)}
                      </Col>
                    </Row>
                  )}

                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>CESS</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.cess)}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Misc.(If Any)</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <TextField
                        className="ms-2"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        required
                        type="text"
                        // variant="outlined"
                        id="miscellaneous"
                        onChange={this.handleMiscellaneousChange.bind(this)}
                        onBlur={this.handleMiscellaneousBlurChange.bind(this)}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        value={this.state.miscellaneous}
                      />
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {/* <strong>Total Amount</strong> */}
                      <Row>
                        <Col
                          md={5}
                          style={{ paddingRight: "0em", textAlign: "right" }}
                        >
                          <strong>Total Amount</strong>
                        </Col>

                        <Col md={7}>
                          <FormControlLabel
                            style={{
                              color: "#000",
                              width: "100%",
                              margin: "0",
                              padding: "0",
                            }}
                            label={<span style={{}}>Round Up</span>}
                            control={
                              <Checkbox
                                variant="outlined"
                                id="shareWithCustomer"
                                checked={this.state.isroundup}
                                value={this.state.isroundup}
                                onChange={this.handleRoundUpCheckBox.bind(this)}
                                style={{ background: "none" }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {/* {Utils.getFloatValue(this.state.totalamount)} */}
                      {this.state.isroundup === true
                        ? Utils.getRoundValue(this.state.totalamount)
                        : Utils.getFloatValue(this.state.totalamount)}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={12}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.totalinwords}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
            {/* <Modal
              isOpen={this.state.menuList}
              toggle={this.handleClickShowAdvanceVoucher}
              fade={false}
              style={{
                alignContent: "center",
                width: "100%",
                marginLeft: "0px",
                marginTop: "80px",
                opacity: 1,
              }}
            >
              <ModalBody style={{ color: "#fff" }}>
                <Row>
                  ABCD
                </Row>
              </ModalBody>
            </Modal> */}
          </Card>
          <CardFooter>
            <Row md={12}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <CreateButton type="submit" />
                <CancelButton onClick={(e) => this.cancelView()} />
              </div>
            </Row>
          </CardFooter>
        </ValidatorForm>
      </div>
    );
  }

  editPurchaseVoucher() {
    let invoiceItemsList = [];
    /* Create List of PurchaseVoucher Item */
    let isIgstApplicable = Utils.isIgstApplicable(this.state.supplier);
    let itemLoc = 1;
    this.state.invoiceitems &&
      this.state.invoiceitems.forEach((element, index) => {
        if ("delete" !== element.operation) {
          element.itemsrno = itemLoc++;
          let itemkey = index + "-" + this.state.refreshKey;
          invoiceItemsList.push(
            <PurchaseOrderItem
              ref={this.itemReferenceList[index]}
              backgroundColor={
                index % 2 ? { background: "#FFF" } : { background: "#FFF" }
              }
              key={itemkey}
              isIgstApplicable={isIgstApplicable}
              data={element}
              srno={index}
              invoicedata={this.state.invoiceitems}
              iteamIndex={index}
              action="edit"
              productList={this.productList}
              custHsnCodeList={this.custHsnCodeList}
              onAdd={this.addPurchaseVoucherItem.bind(this)}
              doCalculations={this.doCalculations.bind(this)}
              onDelete={this.invoiceItemDeleted.bind(this)}
              onChange={this.invoiceItemChanged.bind(this)}
            />
          );
        }
      });

    let billingAddress = (
      <Address
        ref={this.billingAddressRef}
        title="Billing Address"
        data={this.state.billtoaddress}
        action="view"
      />
    );

    let shippingAddress = "";
    if (Utils.equalsIgnoreCase(this.state.isbillingshippingsame, "No")) {
      shippingAddress = (
        <Address
          ref={this.shippingAddressRef}
          title="Shipping Address"
          data={this.state.shiptoaddress}
          action="create"
        />
      );
    }

    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = false;
    if (this.state.supplier && this.state.supplier.addressvo) {
      let address = this.state.supplier.addressvo;
      if (
        (isExchangeRateMandator = Utils.isExchangeRateApplicableForCountry(
          address.statevo.countryvo,
          this.defaultCountry
        ))
      ) {
        this.state.exchangeRate = 1;
        isExchangeRateMandator = true;
      }
    }

    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Edit Purchase Order"
          history={this.props.history}
          name="Edit Purchase Order"
        />

        <ValidatorForm
          style={{
            width: "100%",
            color: "#000",
            padding: "0.5em",
            marginTop: "1em",
          }}
          ref="form"
          onSubmit={this.updatePurchaseVoucherInBackend.bind(this)}
        >
          <Card md={12}>
            <CardHeader style={{ margin: "0px", padding: "0.5em" }}>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Supplier<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    disableClearable
                    disabled
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    id="supplier"
                    size="small"
                    options={
                      undefined === this.supplierList ||
                      null === this.supplierList
                        ? []
                        : this.supplierList
                    }
                    value={this.state.supplier}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateSupplier(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Supplier"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {/* <span style={{  }}>{this.state.supplier.name}</span> */}
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    PO Date<span className="asterisk"> *</span>
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        required
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="invoicedate"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        value={
                          this.state.invoicedate ? this.state.invoicedate : null
                        }
                        onChange={this.handlePurchaseVoucherDateChange.bind(
                          this
                        )}
                        onBlur={this.handlePurchaseVoucherDateBlurChange.bind(
                          this
                        )}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    PO Number<span className="asterisk"> *</span>
                  </label>
                  {/* <div style={{ display: "flex" }}> */}
                  <TextField
                    required
                    fullWidth={true}
                    variant="outlined"
                    id="invnumber"
                    type="text"
                    placeholder="PO Number"
                    validators={["required"]}
                    onChange={this.handlePurchaseVoucherNumberChange.bind(this)}
                    value={this.state.invnumber ? this.state.invnumber : ""}
                    name="invnumber"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RefreshIcon
                            className="gstnBtn"
                            onClick={(e) => this.generateInvoiceNumber()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <span
                      style={{
                        background: "#2471A3",
                        padding: "0.8em",
                        cursor: "pointer",
                      }}
                    >
                      <RefreshIcon
                        style={{ color: "#fff", fontWeight: "bold" }}
                        onClick={(e) => this.generateInvoiceNumber()}
                      ></RefreshIcon>
                    </span> */}
                  {/* </div> */}
                </Col>
                {/* <Col md={2} style={{ padding: '5px', textAlign: 'left' }}>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      required
                      fullWidth={true}
                      variant="outlined"
                      inputProps={{ style: {} }}
                      InputLabelProps={{ style: {}, shrink: true }}
                      id="invnumber"
                      type="text"
                       placeholder="Voucher Number"
                      validators={['required']}
                      onChange={this.handlePurchaseVoucherNumberChange.bind(this)}
                      value={this.state.invnumber}
                      name="invnumber"
                    />

                  </div>
                </Col> */}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Remark
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    inputProps={{ style: {} }}
                    InputLabelProps={{ style: {}, shrink: true }}
                    id="invoicenote"
                    type="text"
                    placeholder="Remark"
                    validators={["required"]}
                    onChange={this.handleRemarkChange.bind(this)}
                    // onBlur={this.handleNoteOnBlur.bind(this)}
                    value={this.state.description}
                    name="invoicenote"
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Business Unit<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    disableClearable
                    id="businessUnit"
                    size="small"
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    options={this.businessUnitList ? this.businessUnitList : []}
                    value={this.state.bu}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Business Unit"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isnilrated}
                        value={this.state.isnilrated}
                        onChange={this.handleIsNillRatedChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Nil Rated?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                {isExchangeRateMandator === true && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Currency<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="currency"
                        size="small"
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        options={this.currencyList ? this.currencyList : []}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.updateCurrency(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            required
                            {...params}
                            variant="outlined"
                            style={{}}
                            className="regCountry"
                            placeholder="Currency"
                          />
                        )}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Exchange Rate<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        inputProps={{ style: {} }}
                        InputLabelProps={{ style: {}, shrink: true }}
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        placeholder="Exchange Rate"
                        onChange={this.handleExchangeRateChange.bind(this)}
                        // onBlur={this.handleGSTNOnBlur.bind(this)}
                        value={this.state.exchangerate}
                        name="exchangeRate"
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Export Type<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        style={{ width: "100%", textAlign: "center" }}
                        id="expinvoicetype"
                        size="small"
                        ListboxProps={{ className: "myCustomList" }}
                        options={
                          this.exportInvoiceTypeList
                            ? this.exportInvoiceTypeList
                            : []
                        }
                        value={this.state.expinvoicetype}
                        defaultValue={this.state.expinvoicetype}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          this.updateExportType(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            style={{}}
                            variant="outlined"
                            className="regCountry"
                            placeholder="Export Type"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSEZDeemedExport"
                        checked={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        value={
                          this.state.issez &&
                          Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        onChange={this.handleIsSEZChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>SEZ/Deemed Export?</span>}
                  />
                </Col>
                {this.issalesthrecom && (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          id="isSalesThroughECOM"
                          checked={
                            this.state.salesthrecom &&
                            this.state.salesthrecom === true
                              ? true
                              : false
                          }
                          value={
                            this.state.salesthrecom &&
                            this.state.salesthrecom === true
                              ? true
                              : false
                          }
                          onChange={this.handleSalesThroughECOMChange.bind(
                            this
                          )}
                          style={{ background: "none" }}
                        />
                      }
                      label={<span style={{}}>Sales Through ECOM?</span>}
                    />
                  </Col>
                )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.capitalasset}
                        value={this.state.capitalasset}
                        onChange={this.handleAssetChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Capital Asset?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                {Utils.equalsIgnoreCase(this.state.issez, "Yes") ? (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      SEZ Category<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      disableClearable
                      ListboxProps={{ className: "myCustomList" }}
                      id="sezCategory"
                      size="small"
                      options={this.sezInvoiceTypeList}
                      value={this.state.sezinvoicetype}
                      defaultValue={this.state.sezinvoicetype}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, value) => {
                        this.updateSezInvoiceType(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth={true}
                          {...params}
                          variant="outlined"
                          className="regCountry"
                          placeholder="SEZ Category"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Col>
                ) : (
                  <Col md={2}></Col>
                )}
                {this.state.salesthrecom &&
                  this.state.salesthrecom === true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        ETIN<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="etin"
                        size="small"
                        ListboxProps={{ className: "myCustomList" }}
                        options={this.etinInfoList}
                        value={this.state.etin ? this.state.etin : null}
                        defaultValue={this.state.etin}
                        getOptionLabel={(option) => option.etin}
                        onChange={(event, value) => {
                          this.handleEtinChange(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth={true}
                            {...params}
                            variant="outlined"
                            className="regCountry"
                            placeholder="ETIN"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                  )}
              </Row>
              <Row
                md={12}
                style={{ alignItems: "center", margin: "0", padding: "0" }}
              >
                <Col md={8}></Col>
                <Col
                  md={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Checkbox
                    variant="outlined"
                    id="isBillingShippingSame"
                    label="Is Nill Rated"
                    checked={
                      Utils.equalsIgnoreCase(
                        this.state.isbillingshippingsame,
                        "Yes"
                      )
                        ? true
                        : false
                    }
                    onChange={this.handleBillingShippingSame.bind(this)}
                    style={{ color: "gray", background: "none" }}
                  />
                  <span style={{ color: "black" }}>Billing Shipping Same?</span>
                </Col>
              </Row>
              {this.state.supplier && (
                <Row
                  md={12}
                  style={{
                    // background: "#fff7ec",
                    margin: "0",
                    padding: "0",
                    borderRadius: "1em",
                  }}
                >
                  <Col
                    md={4}
                    style={{
                      border: "1px solid white",
                    }}
                  >
                    <Row md={12}>
                      <label
                        style={{
                          fontWeight: "600",
                          color: "#1b7189",
                          fontSize: "16px",
                        }}
                      >
                        <b> Supplier Details</b>
                      </label>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Name</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.name}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Address</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {Utils.getAddressAsText(this.state.supplier.addressvo)}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Type</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.suppliertypevo.description}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>GSTN</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.gstn}
                        {Utils.isNotNullAndEmpty(this.state.supplier.gstn) && (
                           <>
                           {!this.state.copied ? (
                             <Tooltip title="Copy" aria-label="add">
                               <IconButton className="ms-4">
                                 <CopyToClipboard
                                   text={this.state.supplier.gstn}
                                   onCopy={this.onCopy.bind(this)}
                                 >
                                   <FileCopyIcon
                                     style={{
                                       color: "gray",
                                       fontSize: "20px",
                                     }}
                                   />
                                 </CopyToClipboard>
                               </IconButton>
                             </Tooltip>
                           ) : (
                             <Tooltip title="Copied" aria-label="add">
                               <IconButton className="ms-4">
                                 <DoneIcon
                                   style={{
                                     color: "green",
                                     fontSize: "20px",
                                   }}
                                 />
                               </IconButton>
                             </Tooltip>
                           )}
                         </>
                        )}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>PAN</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.pan}
                      </Col>
                    </Row>
                    <Row md={12} style={{ margin: 0, padding: 0 }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Currency</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.currency ? this.state.currency.name : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={4}
                    style={{
                      border: "1px solid white",
                    }}
                  >
                    {billingAddress}
                  </Col>
                  <Col
                    md={4}
                    style={{
                      border: "1px solid white",
                    }}
                  >
                    {shippingAddress}
                  </Col>
                </Row>
              )}
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ margin: "0" }}>
                <Col
                  md={11}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                  }}
                >
                  Purchase Order Item Details
                </Col>
                <Col
                  md={1}
                  style={{
                    borderRight: "1px solid #eceaea",
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  <AddIconButton
                    onClick={this.addPurchaseVoucherItem.bind(this)}
                  />
                </Col>
              </Row>
              {invoiceItemsList}

              <Row
                md={12}
                style={{ padding: "0px", margin: "0", marginTop: "1em" }}
              >
                <Col md={8} style={{ margin: "0", padding: "1em" }}></Col>
                <Col md={4} style={{ margin: "0", padding: "0" }}>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Value Of Supply</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {this.state.totaltaxable}
                    </Col>
                  </Row>
                  {Utils.isIgstApplicable(this.state.supplier) === false && (
                    <>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>CGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.cgst)}
                        </Col>
                      </Row>
                      <Row md={12} style={{ padding: "5px", margin: "0" }}>
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>SGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.sgst)}
                        </Col>
                      </Row>
                    </>
                  )}
                  {Utils.isIgstApplicable(this.state.supplier) === true && (
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>IGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.igst)}
                      </Col>
                    </Row>
                  )}

                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>CESS</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.cess)}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Misc.(If Any)</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        required
                        type="text"
                        // variant="outlined"
                        id="miscellaneous"
                        onChange={this.handleMiscellaneousChange.bind(this)}
                        onBlur={this.handleMiscellaneousBlurChange.bind(this)}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        value={this.state.miscellaneous}
                      />
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {/* <strong>Total Amount</strong> */}
                      <Row>
                        <Col
                          md={5}
                          style={{ paddingRight: "0em", textAlign: "right" }}
                        >
                          <strong>Total Amount</strong>
                        </Col>

                        <Col md={7}>
                          <FormControlLabel
                            style={{
                              color: "#000",
                              width: "100%",
                              margin: "0",
                              padding: "0",
                            }}
                            label={<span style={{}}>Round Up</span>}
                            control={
                              <Checkbox
                                variant="outlined"
                                id="shareWithCustomer"
                                checked={this.state.isroundup}
                                value={this.state.isroundup}
                                onChange={this.handleRoundUpCheckBox.bind(this)}
                                style={{ background: "none" }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {/* {Utils.getFloatValue(this.state.totalamount)} */}
                      {this.state.isroundup === true
                        ? Utils.getRoundValue(this.state.totalamount)
                        : Utils.getFloatValue(this.state.totalamount)}
                    </Col>
                  </Row>
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={12}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.totalinwords}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CardFooter>
            <Row md={12}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <SaveButton type="submit" />
                <CancelButton onClick={(e) => this.cancelEdit()} />
              </div>
            </Row>
          </CardFooter>
        </ValidatorForm>
      </div>
    );
  }

  viewPurchaseVoucher() {
    let invoiceItemsList = [];
    let isIgstApplicable = Utils.isIgstApplicable(this.state.supplier);
    this.state.invoiceitems &&
      this.state.invoiceitems.forEach((element, index) => {
        invoiceItemsList.push(
          <PurchaseOrderItem
            backgroundColor={
              index % 2 ? { background: "#FFF" } : { background: "#FFF" }
            }
            key={index}
            isIgstApplicable={isIgstApplicable}
            data={element}
            srno={index + 1}
            invoicedata={this.state.invoiceitems}
            iteamIndex={index}
            action="view"
            productList={this.productList}
            custHsnCodeList={this.custHsnCodeList}
            onAdd={this.addPurchaseVoucherItem.bind(this)}
            doCalculations={this.doCalculations.bind(this)}
            onDelete={this.invoiceItemDeleted.bind(this)}
            onChange={this.invoiceItemChanged.bind(this)}
          />
        );
      });
    let menuWithBreadScrum = (
      <MenuWithBreadScrum
        ref={this.menuWithBreadScrumRef}
        breadCrumb={this.breadCrumb}
        history={this.props.history}
        addToBreadCrumb="View Purchase Order"
        name="View Purchase Order"
        domainObject="PurchaseOrder"
        docTitle={this.state.invnumber}
        backCallback={this.cancelView.bind(this)}
        editCallback={this.editButtonClicked.bind(this)}
        deleteCallback={this.deleteButtonClicked.bind(this)}
      />
    );

    // let etin = this.state.etin;
    return (
      <div style={{ display: "grid" }}>
        <PurchaseInvoiceDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
          pageId="PurchaseVoucherItem"
        />

        {menuWithBreadScrum}

        <Card md={12} style={{ margin: "1em" }}>
          <CardHeader>
            <Row md={12} style={{ display: "flex", padding: "10px" }}>
              <Col
                md={12}
                style={{ padding: "5px", marginLeft: "10px", display: "flex" }}
              >
                <div className="row pb-1">
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "600",
                      color: "#1b7189",
                    }}
                  >
                    <lable
                      className="ms-1"
                      style={{
                        color: "rgb(0, 0, 0)",
                        textAlign: "left",
                        fontWeight: "650",
                      }}
                    >
                      Purchase Order
                    </lable>{" "}
                    &nbsp;
                    {this.state.invnumber}
                  </span>
                </div>
              </Col>
              <Col md={4} style={{ display: "flex", padding: "5px" }}>
                <div style={{ width: "100%" }}>
                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Name</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.name}
                    </p>
                  </Row>

                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Address</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",

                        // margin: "0",

                        textAlign: "left",
                      }}
                    >
                      {Utils.getAddressAsText(this.state.supplier.addressvo)}
                    </p>
                  </Row>

                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Type</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.suppliertypevo.description}
                    </p>
                  </Row>
                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>GSTN</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.gstn}
                      {Utils.isNotNullAndEmpty(this.state.supplier.gstn) && (
                        <>
                        {!this.state.copied ? (
                          <Tooltip title="Copy" aria-label="add">
                            <IconButton className="ms-4">
                              <CopyToClipboard
                                text={this.state.supplier.gstn}
                                onCopy={this.onCopy.bind(this)}
                              >
                                <FileCopyIcon
                                  style={{
                                    color: "gray",
                                    fontSize: "20px",
                                  }}
                                />
                              </CopyToClipboard>
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Copied" aria-label="add">
                            <IconButton className="ms-4">
                              <DoneIcon
                                style={{
                                  color: "green",
                                  fontSize: "20px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                      )}
                    </p>
                  </Row>
                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PAN</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.pan}
                    </p>
                  </Row>
                  {/* <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Sales Type</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.name}
                    </p>
                  </Row> */}
                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Business Unit</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.bu.name}
                    </p>
                  </Row>
                  {this.state.exchangerate && this.state.exchangerate > 1 && (
                    <Row style={{ margin: "1px", padding: 0, width: "100%" }}>
                      <label
                        style={{
                          color: "#000",
                          width: "40%",
                          float: "left",
                          margin: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Currency Rate</strong>
                      </label>
                      <p
                        style={{
                          width: "60%",
                          textAlign: "left",
                        }}
                      >
                        {this.state.exchangerate}
                      </p>
                    </Row>
                  )}
                </div>
              </Col>
              <Col md={4} style={{ margin: "0", display: "flex" }}>
                <div style={{ width: "100%", padding: "5px" }}>
                  <Row
                    md={12}
                    style={{
                      width: "100%",
                      height: "50%",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Address
                      ref={this.billingAddressRef}
                      title="Billing Address"
                      data={this.state.billtoaddress}
                      action="view"
                    />
                  </Row>
                  <Row
                    md={12}
                    style={{
                      width: "100%",
                      height: "50%",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Address
                      ref={this.shippingAddressRef}
                      title="Shipping Address"
                      data={this.state.shiptoaddress}
                      action="view"
                    />
                  </Row>
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "0", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%", padding: "5px" }}>
                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Created On</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* { this.state.createdon ? format(new Date(), 'yyyy-MM-dd') : ''} */}
                      {Utils.ptsDateFormat(this.state.createdon)}
                    </p>
                  </Row>
                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PO Date</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoicedate} */}
                      {Utils.ptsDateFormat(this.state.invoicedate)}
                    </p>
                  </Row>

                  {(this.state.isexport === true ||
                    Utils.equalsIgnoreCase(this.state.isexport, "true")) && (
                    <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                      <label
                        style={{
                          color: "#000",
                          width: "40%",
                          float: "left",
                          margin: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Export</strong>
                      </label>
                      <p
                        style={{
                          width: "60%",
                          // color: "#000",
                          // margin: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </p>
                    </Row>
                  )}
                  {this.state.isnilrated === true && (
                    <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                      <label
                        style={{
                          color: "#000",
                          width: "40%",
                          float: "left",
                          margin: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Nil Rated</strong>
                      </label>
                      <p
                        style={{
                          width: "60%",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </p>
                    </Row>
                  )}
                  {(this.state.capitalasset === true ||
                    Utils.equalsIgnoreCase(
                      this.state.capitalasset,
                      "true"
                    )) && (
                    <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                      <label
                        style={{
                          color: "#000",
                          width: "40%",
                          float: "left",
                          margin: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Capital Asset</strong>
                      </label>
                      <p
                        style={{
                          width: "60%",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </p>
                    </Row>
                  )}

                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Status</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.status ? this.state.status?.description : ""}
                    </p>
                  </Row>

                  {this.state.salesthrecom &&
                    this.state.salesthrecom === true && (
                      <>
                        <Row
                          style={{ width: "100%", margin: "1px", padding: 0 }}
                        >
                          <label
                            style={{
                              color: "#000",
                              width: "40%",
                              float: "left",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            <strong>Sales Thr. ECOM </strong>
                          </label>
                          <p
                            style={{
                              width: "60%",
                              // color: "#000",
                              // margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Yes
                          </p>
                        </Row>

                        <Row
                          style={{ width: "100%", margin: "1px", padding: 0 }}
                        >
                          <label
                            style={{
                              color: "#000",
                              width: "40%",
                              float: "left",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            <strong>ETIN </strong>
                          </label>
                          <p
                            style={{
                              width: "60%",
                              // color: "#000",
                              // margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {this.state.etin ? this.state.etin?.etin : ""}
                          </p>
                        </Row>
                      </>
                    )}
                  <Row style={{ width: "100%", margin: "1px", padding: 0 }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        float: "left",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Remark</strong>
                    </label>
                    <p
                      style={{
                        width: "60%",
                        // color: "#000",
                        // margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {undefined !== this.state.description
                        ? this.state.description
                        : ""}
                    </p>
                  </Row>
                  {/* {Utils.isNotNullAndEmpty(this.state.refinvoice) && (
                    <Row style={{ width: "100%", margin: 0, padding: 0 }}>
                      <label
                        style={{
                          color: "#000",
                          width: "40%",
                          float: "left",
                          margin: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Advance Voucher</strong>
                      </label>
                      <p
                        style={{
                          width: "60%",
                          textAlign: "left",
                        }}
                      >
                        {this.state.referenceinvnumber && (
                          <Link
                            style={{ cursor: "pointer", color: "#0d6efd" }}
                            onClick={this.showReferenceInvoice.bind(this)}
                            className="text-underline-hover"
                          >
                            <strong>
                              {this.state.referenceinvnumber
                                ? this.state.referenceinvnumber
                                : ""}
                            </strong>
                          </Link>
                        )}
                      </p>
                    </Row>
                  )} */}
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <table>
              <thead>
                <tr md={12} style={{ padding: "0px", margin: "0" }}>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    <span style={{ float: "left" }}>#</span>
                    <span style={{ float: "right" }}>SAC/HSN</span>
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "20%",
                    }}
                  >
                    Item Details
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    UOM
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "3%",
                    }}
                  >
                    Qty
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "8%",
                    }}
                  >
                    Unit Price
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Gross Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Discount %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Net Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "5%",
                    }}
                  >
                    CESS %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    Tax Rate %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    GST
                  </td>

                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Total
                  </td>
                </tr>
              </thead>
              {invoiceItemsList}
            </table>
            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <Col md={8} style={{ margin: "0", padding: "1em" }}>
                {Utils.equalsIgnoreCase(
                  "VERIFICATIONREQUESTED",
                  this.state.status ? this.state.status?.status : ""
                ) && (
                  <Row
                    md={12}
                    style={{ flexDirection: "column", marginBottom: "1em" }}
                  >
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: 0,
                        paddingBottom: "6px",
                        textAlign: "left",
                      }}
                    >
                      <strong>Audit Note</strong>
                    </label>
                    <TextareaAutosize
                      variant="outlined"
                      fullWidth
                      id="auditnote"
                      type="text"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "0.5px solid #D5D8DC",
                        backgroundColor: "#FFFEE3",
                      }}
                      minRows={2}
                      value={this.state.auditNote ? this.state.auditNote : " "}
                      onChange={this.addAuditNote.bind(this)}
                      autoComplete="auditnote"
                    />
                  </Row>
                )}

                <Row md={12} style={{ flexDirection: "column" }}>
                  <AuditNoteList
                    ref={this.auditNoteListRef}
                    data={this.state.auditnotes}
                    aid={this.state.id}
                    title="Audit Notes"
                    history={this.props.history}
                    addPaymentTransactionServiceUrl={
                      this.addPaymentTransactionServiceUrl
                    }
                    updatePaymentTransactionServiceUrl={
                      this.updatePaymentTransactionServiceUrl
                    }
                    deletePaymentTransactionServiceUrl={
                      this.deletePaymentTransactionServiceUrl
                    }
                  />
                </Row>
              </Col>
              <Col md={4} style={{ margin: "0", padding: "0" }}>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Value Of Supply</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.totaltaxable)}
                  </Col>
                </Row>
                {Utils.isIgstApplicable(this.state.supplier) === false && (
                  <>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>CGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.cgst)}
                      </Col>
                    </Row>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>SGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.sgst)}
                      </Col>
                    </Row>
                  </>
                )}

                {Utils.isIgstApplicable(this.state.supplier) === true && (
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>IGST</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.igst)}
                    </Col>
                  </Row>
                )}

                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>CESS</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.cess)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Misc.(If Any)</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.miscellaneous)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Total Amount</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {/* {Utils.getFloatValue(this.state.totalamount)} */}
                    {this.state.isroundup === true
                      ? Utils.getRoundValue(this.state.totalamount)
                      : Utils.getFloatValue(this.state.totalamount)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={12}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.totalinwords}
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row md={12} style={{ padding: '5px', margin: '0', marginTop: '1em', padding: '0' }}>
              <Col md={4} style={{ margin: '0', padding: '0' }}>
                <FileAttachmentList
                  data={this.state.invoiceDocuments}
                  aid={this.state.id}
                  title="Purchase Receipt"
                  domainObject="Purchase Receipt"
                  history={this.props.history}
                  documentTypeList={this.state.doctypes}
                  uploadAttachmentServiceUrl={this.uploadAttachmentServiceUrl}
                  deleteAttachmentServiceUrl={this.deleteAttachmentServiceUrl}
                />
              </Col>
            </Row>
            <Row md={12} style={{ padding: '0px', margin: '0', marginTop: '1em' }}>
              <Col md={7} style={{ margin: '0', padding: '0' }}>
                <PaymentTransactionList
                  data={this.state.paymentTransations}
                  aid={this.state.id}
                  title="Payments"
                  type="Invoice"
                  history={this.props.history}
                  onPaymentChange={this.onPaymentChange.bind(this)}
                  addPaymentTransactionServiceUrl={this.addPaymentTransactionServiceUrl}
                  updatePaymentTransactionServiceUrl={this.updatePaymentTransactionServiceUrl}
                  deletePaymentTransactionServiceUrl={this.deletePaymentTransactionServiceUrl}
                />
              </Col>
            </Row> */}
          </CardBody>
        </Card>
        <CardFooter>
          <Row md={12}>{this.getButtonsAsPerStatusAndPermission()}</Row>
        </CardFooter>
      </div>
    );
  }
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#1B7189",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
