import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import RepeatIcon from "@material-ui/icons/Repeat";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React, { Component } from "react";
import { Autocomplete } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
// import { NavLink } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import store from "../../../../redux/store";
import CancelButton from "../../buttons/CancelButton";
import CreateButton from "../../buttons/CreateButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
// import './GstRB.scss';
import Utils from "../../../../provider/Utils";
import PtsAlert from "../../ptsAlert/PtsAlert";
import DeleteIconButton from "../../buttons/DeleteIconButton";
export class GSTRB extends Component {
  header = store.getState().header.header;
  stateListArray = store.getState().staticData.stateList;
  yearList = store.getState().appData.fyList;
  gstr1Credentials = store.getState().appData.gstr1Credentials;
  monthList = Utils.getMonthList();
  title = "GSTR-3B";
  constructor(props) {
    super(props);

    if (this.gstr1Credentials && this.gstr1Credentials.length > 0) {
      this.fileFrequency = this.gstr1Credentials[0].filefrequency.frequency;
    }

    let gstrbId = props.gstrbId;
    let action = props.action;

    if (
      (!gstrbId || action === "create") &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      gstrbId = props.history.location.state.gstrbId;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    let canRender = false;
    if (gstrbId && gstrbId > 0) {
      this.getGstrbDetails(gstrbId);
    } else {
      canRender = true;
    }
    this.state = {
      id: gstrbId,
      quarterMonth: null,
      month: null,
      year: "",
      createdDate: "",
      period: {},
      action: action,
      render: canRender,
      status: "",
      returnPeriod: "",
      sup_detailsIamt: "",
      sup_detailsCamt: "",
      sup_detailsSamt: "",
      sup_detailsCsamt: "",
      inter_supIamt: "",
      inter_supTxval: "",
      itc_elgIamt: "",
      itc_elgCamt: "",
      itc_elgSamt: "",
      itc_elgCsamt: "",
      inward_supInter: "",
      inward_supIntra: "",
      intr_ltfeeIamt: "",
      intr_ltfeeCamt: "",
      intr_ltfeeSamt: "",
      intr_ltfeeCsamt: "",
      ltfeeIntrdetailIamt: "",
      ltfeeIntrdetailCamt: "",
      ltfeeIntrdetailSamt: "",
      ltfeeIntrdetailCsamt: "",
      ltfeelatefeeIamt: "",
      ItfeelatefeeCamt: "",
      ltfeelatefeeSamt: "",
      ltfeelatefeeCsamt: "",
      inwardOtherInter: "",
      inwardOtherIntra: "",
      inwardNongstInter: "",
      inwardNongstIntra: "",
      supdetIamt: "",
      supdetTxval: "",
      supdetCamt: "",
      supdetSamt: "",
      supdetCsamt: "",
      supzeroTxval: "",
      supzeroIamt: "",
      supzeroCamt: "",
      supzeroSamt: "",
      supzeroCsamt: "",
      supNilexmpTxval: "",
      suprevTxval: "",
      suprevIamt: "",
      suprevCamt: "",
      suprevSamt: "",
      suprevCsamt: "",
      supnongstTxval: "",
      importGoodIntval: "",
      importGoodCess: "",
      importServiceIntval: "",
      importServiceCess: "",
      inwardRevIntval: "",
      inwardRevCess: "",
      inwardRevCamt: "",
      inwardSuppISDIntval: "",
      inwardSuppISDCamt: "",
      inwardSuppISDCess: "",
      otherItcIntval: "",
      otherItcCamt: "",
      otherItcSamt: "",
      otherItcCess: "",
      itcReversedSgstIntval: "",
      itcReversedSgstCamt: "",
      itcReversedSgstSamt: "",
      itcReversedSgstCess: "",
      itcReversedOtherIntval: "",
      itcReversedOtherCamt: "",
      itcReversedOtherSamt: "",
      itcReversedOtherCess: "",
      netItcReversedIntval: "",
      netItcReversedCamt: "",
      netItcReversedSamt: "",
      netItcReversedCess: "",
      state: "",
      interStateSuptaxlist: [
        {
          pos: "",
          txval: "",
          iamt: "",
        },
      ],
      interStateSuptaxObj: {
        pos: "",
        txval: "",
        iamt: "",
      },
      fileFrequency: this.fileFrequency,
      gstOtp: null,
      isGstOTP: false,
    };
    // this.getPeriodDetails();
    this.getQuarterList();
  }

  getQuarterList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService(
      "/orgfinance/getQuarterList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let quarterList = output.data;
        this.setState({
          // render: true,
          quarterList: quarterList,
        });
      }
    });
  }

  handleYearChange(e, selected) {
    this.setState({ year: selected });
  }

  handleQuarterlyFinancialsChange(value) {
    this.setState({ quarterMonth: value });
  }

  handleMonthlyGSTR3Change(value) {
    this.setState({ month: value });
  }

  getGstrbDetails = (gstrbId) => {
    const postObject = {
      gstr3b: {
        id: gstrbId,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/refresh", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let gstrbData = output.data;
          let supnongstxval = gstrbData.sup_details.osup_nongst;
          let suppNongstvalue;
          if (supnongstxval === null) {
            suppNongstvalue = 0;
          }
          this.setState({
            createdon: gstrbData.createdon,
            status: gstrbData.status,
            fperiod: gstrbData.fp,
            id: gstrbData.id,
            returnPeriod: gstrbData.financialPeriod,
            createdDate: gstrbData.createdon,
            sup_detailsIamt: gstrbData.sup_details.osup_det.iamt,
            sup_detailsCamt: gstrbData.sup_details.osup_det.camt,
            sup_detailsSamt: gstrbData.sup_details.osup_det.samt,
            sup_detailsCsamt: gstrbData.sup_details.osup_det.csamt,
            inter_supIamt: gstrbData.sup_details.total.iamt,
            inter_supTxval: gstrbData.sup_details.total.txval,
            itc_elgIamt: gstrbData.itc_elg.netitc.iamt,
            itc_elgCamt: gstrbData.itc_elg.netitc.camt,
            itc_elgSamt: gstrbData.itc_elg.netitc.samt,
            itc_elgCsamt: gstrbData.itc_elg.netitc.csamt,
            inward_supInter: gstrbData.inward_sup.total.inter,
            inward_supIntra: gstrbData.inward_sup.total.intra,
            intr_ltfeeIamt: gstrbData.intr_ltfee.total.iamt,
            intr_ltfeeCamt: gstrbData.intr_ltfee.total.camt,
            intr_ltfeeSamt: gstrbData.intr_ltfee.total.samt,
            intr_ltfeeCsamt: gstrbData.intr_ltfee.total.csamt,
            ltfeeIntrdetailIamt: gstrbData.intr_ltfee.intr_details.iamt,
            ltfeeIntrdetailCamt: gstrbData.intr_ltfee.intr_details.camt,
            ltfeeIntrdetailSamt: gstrbData.intr_ltfee.intr_details.samt,
            ltfeeIntrdetailCsamt: gstrbData.intr_ltfee.intr_details.csamt,
            ltfeelatefeeIamt: gstrbData.intr_ltfee.latefee_details.iamt,
            ItfeelatefeeCamt: gstrbData.intr_ltfee.latefee_details.camt,
            ltfeelatefeeSamt: gstrbData.intr_ltfee.latefee_details.samt,
            ltfeelatefeeCsamt: gstrbData.intr_ltfee.latefee_details.csamt,
            inwardOtherInter: gstrbData.inward_sup.others.inter,
            inwardOtherIntra: gstrbData.inward_sup.others.intra,
            inwardNongstInter: gstrbData.inward_sup.nongst.inter,
            inwardNongstIntra: gstrbData.inward_sup.nongst.intra,
            supdetTxval: gstrbData.sup_details.osup_det.txval,
            supdetIamt: gstrbData.sup_details.osup_det.iamt,
            supdetCamt: gstrbData.sup_details.osup_det.camt,
            supdetSamt: gstrbData.sup_details.osup_det.samt,
            supdetCsamt: gstrbData.sup_details.osup_det.csamt,
            supzeroTxval: gstrbData.sup_details.osup_zero.txval,
            supzeroIamt: gstrbData.sup_details.osup_zero.iamt,
            supzeroCamt: gstrbData.sup_details.osup_zero.camt,
            supzeroSamt: gstrbData.sup_details.osup_zero.samt,
            supzeroCsamt: gstrbData.sup_details.osup_zero.csamt,
            supNilexmpTxval: gstrbData.sup_details.osup_nil_exmp.txval,
            suprevTxval: gstrbData.sup_details.isup_rev.txval,
            suprevIamt: gstrbData.sup_details.isup_rev.iamt,
            suprevCamt: gstrbData.sup_details.isup_rev.camt,
            suprevSamt: gstrbData.sup_details.isup_rev.samt,
            suprevCsamt: gstrbData.sup_details.isup_rev.csamt,
            supnongstTxval: suppNongstvalue,
            importGoodIntval: gstrbData.itc_elg.itcavailable.importgoods.iamt,
            importGoodCess: gstrbData.itc_elg.itcavailable.importgoods.csamt,
            importServiceIntval:
              gstrbData.itc_elg.itcavailable.importservice.iamt,
            importServiceCess:
              gstrbData.itc_elg.itcavailable.importservice.csamt,
            inwardRevIntval: gstrbData.itc_elg.itcavailable.rcmitc.iamt,
            inwardRevCess: gstrbData.itc_elg.itcavailable.rcmitc.csamt,
            inwardRevCamt: gstrbData.itc_elg.itcavailable.rcmitc.camt,
            inwardRevSamt: gstrbData.itc_elg.itcavailable.rcmitc.samt,
            inwardSuppISDIntval: gstrbData.itc_elg.itcavailable.isditc.iamt,
            inwardSuppISDCamt: gstrbData.itc_elg.itcavailable.isditc.camt,
            inwardSuppISDSamt: gstrbData.itc_elg.itcavailable.isditc.samt,
            inwardSuppISDCess: gstrbData.itc_elg.itcavailable.isditc.csamt,
            otherItcIntval: gstrbData.itc_elg.itcavailable.allitc.iamt,
            otherItcCamt: gstrbData.itc_elg.itcavailable.allitc.camt,
            otherItcSamt: gstrbData.itc_elg.itcavailable.allitc.samt,
            otherItcCess: gstrbData.itc_elg.itcavailable.allitc.csamt,
            netItcReversedIntval: gstrbData.itc_elg.netitc.iamt,
            netItcReversedCamt: gstrbData.itc_elg.netitc.camt,
            netItcReversedSamt: gstrbData.itc_elg.netitc.samt,
            netItcReversedCess: gstrbData.itc_elg.netitc.csamt,
            // privileges: gstrbData.privileges,
            render: true,
          });
        } else {
        }
      }
    );
  };

  refreshGSTR3() {
    const postObject = {
      gstr3b: {
        id: this.state.id,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/refresh", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let refreshGSTR3 = output.data;
        } else {
        }
      }
    );
  }

  createGSTR3Backend = () => {
    // let retPeriod = this.state.month + this.state.year;
    const postObject = {
      header: this.header,
      quarter: this.state.quarterMonth,
      fyear: this.state.year,
      month: this.state.month,
    };

    FetchServerData.callPostService("/gstr3b/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let userData = output.data;
          this.props.history.push({
            pathname: "/gstr3list/",
            //state: { userId: userData.id,action:'view' },
          });
        } else {
        }
      }
    );
  };

  goToOutwardSupplyDetails() {
    let gstr3bData = this.state;
    gstr3bData.action = "outwardSupplyView";

    this.props.history.push({
      pathname: "/gstr3bDetails/",
      state: {
        action: "outwardSupplyView",
        gstr3bData: gstr3bData,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  getOutwardSupply() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToOutwardSupplyDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>
            3.1 Tax on outward and reverse charge inward supplies
          </h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Integrated Tax</h6>
              <span>&#x20b9;{this.state.sup_detailsIamt}</span>
            </Col>
            <Col md={6}>
              <h6>Central Tax</h6>
              <span>&#x20b9;{this.state.sup_detailsCamt}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col md={6}>
              <h6>State/UT Tax</h6>
              <span>&#x20b9;{this.state.sup_detailsSamt}</span>
            </Col>
            <Col md={6}>
              <h6>CESS</h6>
              <span>&#x20b9;{this.state.sup_detailsCsamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  goToInterstateSupplyDetails() {
    let gstr3bData = this.state;
    gstr3bData.action = "interStateSupplyView";
    this.props.history.push({
      pathname: "/gstr3bDetails/",
      state: {
        gstrbId: this.state.id,
        gstr3bData: gstr3bData,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  getInterStateSupply() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToInterstateSupplyDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>3.2 Inter-state supplies</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Taxable Value</h6>
              <span>&#x20b9;{this.state.inter_supTxval}</span>
            </Col>

            <Col md={6}>
              <h6>Integrated Value</h6>
              <span>&#x20b9;{this.state.inter_supIamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  goToITCDetails() {
    let gstr3bData = this.state;
    gstr3bData.action = "eligibleitcView";
    this.props.history.push({
      pathname: "/gstr3bDetails/",
      state: {
        gstrbId: this.state.id,
        gstr3bData: gstr3bData,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  getITCDetails() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToITCDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>4. Eligible ITC</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Integrated Tax</h6>
              <span>&#x20b9;{this.state.itc_elgIamt}</span>
            </Col>
            <Col md={6}>
              <h6>Central Tax</h6>
              <span>&#x20b9;{this.state.itc_elgCamt}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col md={6}>
              <h6>State/UT Tax</h6>
              <span>&#x20b9;{this.state.itc_elgSamt}</span>
            </Col>
            <Col md={6}>
              <h6>CESS</h6>
              <span>&#x20b9;{this.state.itc_elgCsamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  goToInwardSupplyDetails() {
    let gstr3bData = this.state;
    gstr3bData.action = "inwardSupplyView";
    this.props.history.push({
      pathname: "/gstr3bDetails/",
      state: {
        gstrbId: this.state.id,
        gstr3bData: gstr3bData,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  getInwardSupply() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToInwardSupplyDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>
            5. Exempt,nil and Non GST inward Supplies
          </h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Inter-state Supplies</h6>
              <span>&#x20b9;{this.state.inward_supInter}</span>
            </Col>
            <Col md={6}>
              <h6>Intra-state Supplies</h6>
              <span>&#x20b9;{this.state.inward_supIntra}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  goToLateFeesDetails() {
    let gstr3bData = this.state;
    gstr3bData.action = "lateFeesView";
    this.props.history.push({
      pathname: "/gstr3bDetails/",
      state: {
        gstrbId: this.state.id,
        gstr3bData: gstr3bData,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  getLateFees() {
    return (
      <Card
        style={{ cursor: "pointer", background: "#f2f5f9", height: "100%" }}
        onClick={this.goToLateFeesDetails.bind(this)}
      >
        <CardHeader className="gstrbHeader">
          <h5 style={{ color: "white" }}>5.1 Interest and Late fee</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h6>Integrated Tax</h6>
              <span>&#x20b9;{this.state.intr_ltfeeIamt}</span>
            </Col>
            <Col md={6}>
              <h6>Central Tax</h6>
              <span>&#x20b9;{this.state.intr_ltfeeCamt}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Col md={6}>
              <h6>State/UT Tax</h6>
              <span>&#x20b9;{this.state.intr_ltfeeSamt}</span>
            </Col>
            <Col md={6}>
              <h6>CESS</h6>
              <span>&#x20b9;{this.state.intr_ltfeeCsamt}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  cancelView(e) {
    this.props.history.push({
      pathname: "/gstr3list/",
      state: { action: "view", breadCrumb: this.breadCrumb },
    });
  }

  deleteGSTR3BInBackend() {
    const postObject = {
      gstr3b: {
        id: this.state.id,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr3b/delete", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("GSTR3B Deleted");
          this.props.history.push("/gstr3list");
        } else {
        }
      }
    );
  }

  saveToGst() {
    const postObject = {
      header: this.header,
      gstr3b: {
        id: this.state.id,
        fp: this.state.fperiod,
      },
      otp: this.state.gstOtp,
    };

    FetchServerData.callPostService("/connector3b/save", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let gstr3bData = output.data;
          let gstr3bVoData = output.data.gstr3bVo;
          let supnongstxval = gstr3bVoData.sup_details?.osup_nongst;
          let isGstOTP = false;
          let suppNongstvalue;
          if (supnongstxval === null) {
            suppNongstvalue = 0;
          }
          if (gstr3bData.saveStatusResponse?.action === "OTPREQUESTED") {
            isGstOTP = true;
          }

          this.setState({
            createdon: gstr3bVoData.createdon,
            status: gstr3bVoData.status,
            fperiod: gstr3bVoData.fp,
            id: gstr3bVoData.id,
            returnPeriod: gstr3bVoData.financialPeriod,
            createdDate: gstr3bVoData.createdon,
            sup_detailsIamt: gstr3bVoData.sup_details.osup_det.iamt,
            sup_detailsCamt: gstr3bVoData.sup_details.osup_det.camt,
            sup_detailsSamt: gstr3bVoData.sup_details.osup_det.samt,
            sup_detailsCsamt: gstr3bVoData.sup_details.osup_det.csamt,
            inter_supIamt: gstr3bVoData.sup_details.total.iamt,
            inter_supTxval: gstr3bVoData.sup_details.total.txval,
            itc_elgIamt: gstr3bVoData.itc_elg.netitc.iamt,
            itc_elgCamt: gstr3bVoData.itc_elg.netitc.camt,
            itc_elgSamt: gstr3bVoData.itc_elg.netitc.samt,
            itc_elgCsamt: gstr3bVoData.itc_elg.netitc.csamt,
            inward_supInter: gstr3bVoData.inward_sup.total.inter,
            inward_supIntra: gstr3bVoData.inward_sup.total.intra,
            intr_ltfeeIamt: gstr3bVoData.intr_ltfee.total.iamt,
            intr_ltfeeCamt: gstr3bVoData.intr_ltfee.total.camt,
            intr_ltfeeSamt: gstr3bVoData.intr_ltfee.total.samt,
            intr_ltfeeCsamt: gstr3bVoData.intr_ltfee.total.csamt,
            ltfeeIntrdetailIamt: gstr3bVoData.intr_ltfee.intr_details.iamt,
            ltfeeIntrdetailCamt: gstr3bVoData.intr_ltfee.intr_details.camt,
            ltfeeIntrdetailSamt: gstr3bVoData.intr_ltfee.intr_details.samt,
            ltfeeIntrdetailCsamt: gstr3bVoData.intr_ltfee.intr_details.csamt,
            ltfeelatefeeIamt: gstr3bVoData.intr_ltfee.latefee_details.iamt,
            ItfeelatefeeCamt: gstr3bVoData.intr_ltfee.latefee_details.camt,
            ltfeelatefeeSamt: gstr3bVoData.intr_ltfee.latefee_details.samt,
            ltfeelatefeeCsamt: gstr3bVoData.intr_ltfee.latefee_details.csamt,
            inwardOtherInter: gstr3bVoData.inward_sup.others.inter,
            inwardOtherIntra: gstr3bVoData.inward_sup.others.intra,
            inwardNongstInter: gstr3bVoData.inward_sup.nongst.inter,
            inwardNongstIntra: gstr3bVoData.inward_sup.nongst.intra,
            supdetTxval: gstr3bVoData.sup_details.osup_det.txval,
            supdetIamt: gstr3bVoData.sup_details.osup_det.iamt,
            supdetCamt: gstr3bVoData.sup_details.osup_det.camt,
            supdetSamt: gstr3bVoData.sup_details.osup_det.samt,
            supdetCsamt: gstr3bVoData.sup_details.osup_det.csamt,
            supzeroTxval: gstr3bVoData.sup_details.osup_zero.txval,
            supzeroIamt: gstr3bVoData.sup_details.osup_zero.iamt,
            supzeroCamt: gstr3bVoData.sup_details.osup_zero.camt,
            supzeroSamt: gstr3bVoData.sup_details.osup_zero.samt,
            supzeroCsamt: gstr3bVoData.sup_details.osup_zero.csamt,
            supNilexmpTxval: gstr3bVoData.sup_details.osup_nil_exmp.txval,
            suprevTxval: gstr3bVoData.sup_details.isup_rev.txval,
            suprevIamt: gstr3bVoData.sup_details.isup_rev.iamt,
            suprevCamt: gstr3bVoData.sup_details.isup_rev.camt,
            suprevSamt: gstr3bVoData.sup_details.isup_rev.samt,
            suprevCsamt: gstr3bVoData.sup_details.isup_rev.csamt,
            supnongstTxval: suppNongstvalue,
            importGoodIntval:
              gstr3bVoData.itc_elg.itcavailable.importgoods.iamt,
            importGoodCess: gstr3bVoData.itc_elg.itcavailable.importgoods.csamt,
            importServiceIntval:
              gstr3bVoData.itc_elg.itcavailable.importservice.iamt,
            importServiceCess:
              gstr3bVoData.itc_elg.itcavailable.importservice.csamt,
            inwardRevIntval: gstr3bVoData.itc_elg.itcavailable.rcmitc.iamt,
            inwardRevCess: gstr3bVoData.itc_elg.itcavailable.rcmitc.csamt,
            inwardRevCamt: gstr3bVoData.itc_elg.itcavailable.rcmitc.camt,
            inwardRevSamt: gstr3bVoData.itc_elg.itcavailable.rcmitc.samt,
            inwardSuppISDIntval: gstr3bVoData.itc_elg.itcavailable.isditc.iamt,
            inwardSuppISDCamt: gstr3bVoData.itc_elg.itcavailable.isditc.camt,
            inwardSuppISDSamt: gstr3bVoData.itc_elg.itcavailable.isditc.samt,
            inwardSuppISDCess: gstr3bVoData.itc_elg.itcavailable.isditc.csamt,
            otherItcIntval: gstr3bVoData.itc_elg.itcavailable.allitc.iamt,
            otherItcCamt: gstr3bVoData.itc_elg.itcavailable.allitc.camt,
            otherItcSamt: gstr3bVoData.itc_elg.itcavailable.allitc.samt,
            otherItcCess: gstr3bVoData.itc_elg.itcavailable.allitc.csamt,
            netItcReversedIntval: gstr3bVoData.itc_elg.netitc.iamt,
            netItcReversedCamt: gstr3bVoData.itc_elg.netitc.camt,
            netItcReversedSamt: gstr3bVoData.itc_elg.netitc.samt,
            netItcReversedCess: gstr3bVoData.itc_elg.netitc.csamt,
            isGstOTP: isGstOTP,
            render: true,
          });
        } else {
        }
      }
    );
  }

  getSaveStatus() {
    const postObject = {
      header: this.header,
      gstr3b: {
        id: this.state.id,
        fp: this.state.fperiod,
      },
      otp: null,
    };

    FetchServerData.callPostService(
      "/connector3b/getSaveStatus",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let gstr3bVoData = output.data.gstr3bVo;
        let supnongstxval = gstr3bVoData.sup_details.osup_nongst;
        let suppNongstvalue;
        if (supnongstxval === null) {
          suppNongstvalue = 0;
        }
        this.setState({
          createdon: gstr3bVoData.createdon,
          status: gstr3bVoData.status,
          fperiod: gstr3bVoData.fp,
          id: gstr3bVoData.id,
          returnPeriod: gstr3bVoData.financialPeriod,
          createdDate: gstr3bVoData.createdon,
          sup_detailsIamt: gstr3bVoData.sup_details.osup_det.iamt,
          sup_detailsCamt: gstr3bVoData.sup_details.osup_det.camt,
          sup_detailsSamt: gstr3bVoData.sup_details.osup_det.samt,
          sup_detailsCsamt: gstr3bVoData.sup_details.osup_det.csamt,
          inter_supIamt: gstr3bVoData.sup_details.total.iamt,
          inter_supTxval: gstr3bVoData.sup_details.total.txval,
          itc_elgIamt: gstr3bVoData.itc_elg.netitc.iamt,
          itc_elgCamt: gstr3bVoData.itc_elg.netitc.camt,
          itc_elgSamt: gstr3bVoData.itc_elg.netitc.samt,
          itc_elgCsamt: gstr3bVoData.itc_elg.netitc.csamt,
          inward_supInter: gstr3bVoData.inward_sup.total.inter,
          inward_supIntra: gstr3bVoData.inward_sup.total.intra,
          intr_ltfeeIamt: gstr3bVoData.intr_ltfee.total.iamt,
          intr_ltfeeCamt: gstr3bVoData.intr_ltfee.total.camt,
          intr_ltfeeSamt: gstr3bVoData.intr_ltfee.total.samt,
          intr_ltfeeCsamt: gstr3bVoData.intr_ltfee.total.csamt,
          ltfeeIntrdetailIamt: gstr3bVoData.intr_ltfee.intr_details.iamt,
          ltfeeIntrdetailCamt: gstr3bVoData.intr_ltfee.intr_details.camt,
          ltfeeIntrdetailSamt: gstr3bVoData.intr_ltfee.intr_details.samt,
          ltfeeIntrdetailCsamt: gstr3bVoData.intr_ltfee.intr_details.csamt,
          ltfeelatefeeIamt: gstr3bVoData.intr_ltfee.latefee_details.iamt,
          ItfeelatefeeCamt: gstr3bVoData.intr_ltfee.latefee_details.camt,
          ltfeelatefeeSamt: gstr3bVoData.intr_ltfee.latefee_details.samt,
          ltfeelatefeeCsamt: gstr3bVoData.intr_ltfee.latefee_details.csamt,
          inwardOtherInter: gstr3bVoData.inward_sup.others.inter,
          inwardOtherIntra: gstr3bVoData.inward_sup.others.intra,
          inwardNongstInter: gstr3bVoData.inward_sup.nongst.inter,
          inwardNongstIntra: gstr3bVoData.inward_sup.nongst.intra,
          supdetTxval: gstr3bVoData.sup_details.osup_det.txval,
          supdetIamt: gstr3bVoData.sup_details.osup_det.iamt,
          supdetCamt: gstr3bVoData.sup_details.osup_det.camt,
          supdetSamt: gstr3bVoData.sup_details.osup_det.samt,
          supdetCsamt: gstr3bVoData.sup_details.osup_det.csamt,
          supzeroTxval: gstr3bVoData.sup_details.osup_zero.txval,
          supzeroIamt: gstr3bVoData.sup_details.osup_zero.iamt,
          supzeroCamt: gstr3bVoData.sup_details.osup_zero.camt,
          supzeroSamt: gstr3bVoData.sup_details.osup_zero.samt,
          supzeroCsamt: gstr3bVoData.sup_details.osup_zero.csamt,
          supNilexmpTxval: gstr3bVoData.sup_details.osup_nil_exmp.txval,
          suprevTxval: gstr3bVoData.sup_details.isup_rev.txval,
          suprevIamt: gstr3bVoData.sup_details.isup_rev.iamt,
          suprevCamt: gstr3bVoData.sup_details.isup_rev.camt,
          suprevSamt: gstr3bVoData.sup_details.isup_rev.samt,
          suprevCsamt: gstr3bVoData.sup_details.isup_rev.csamt,
          supnongstTxval: suppNongstvalue,
          importGoodIntval: gstr3bVoData.itc_elg.itcavailable.importgoods.iamt,
          importGoodCess: gstr3bVoData.itc_elg.itcavailable.importgoods.csamt,
          importServiceIntval:
            gstr3bVoData.itc_elg.itcavailable.importservice.iamt,
          importServiceCess:
            gstr3bVoData.itc_elg.itcavailable.importservice.csamt,
          inwardRevIntval: gstr3bVoData.itc_elg.itcavailable.rcmitc.iamt,
          inwardRevCess: gstr3bVoData.itc_elg.itcavailable.rcmitc.csamt,
          inwardRevCamt: gstr3bVoData.itc_elg.itcavailable.rcmitc.camt,
          inwardRevSamt: gstr3bVoData.itc_elg.itcavailable.rcmitc.samt,
          inwardSuppISDIntval: gstr3bVoData.itc_elg.itcavailable.isditc.iamt,
          inwardSuppISDCamt: gstr3bVoData.itc_elg.itcavailable.isditc.camt,
          inwardSuppISDSamt: gstr3bVoData.itc_elg.itcavailable.isditc.samt,
          inwardSuppISDCess: gstr3bVoData.itc_elg.itcavailable.isditc.csamt,
          otherItcIntval: gstr3bVoData.itc_elg.itcavailable.allitc.iamt,
          otherItcCamt: gstr3bVoData.itc_elg.itcavailable.allitc.camt,
          otherItcSamt: gstr3bVoData.itc_elg.itcavailable.allitc.samt,
          otherItcCess: gstr3bVoData.itc_elg.itcavailable.allitc.csamt,
          netItcReversedIntval: gstr3bVoData.itc_elg.netitc.iamt,
          netItcReversedCamt: gstr3bVoData.itc_elg.netitc.camt,
          netItcReversedSamt: gstr3bVoData.itc_elg.netitc.samt,
          netItcReversedCess: gstr3bVoData.itc_elg.netitc.csamt,
          render: true,
        });
      } else {
      }
    });
  }

  getButtonsAsPerStatusAndPermission() {
    let status = this.state.status ? this.state.status.status : "";
    // let privilegeList = this.privilegeList;
    if (
      Utils.equalsIgnoreCase("CREATED", status) ||
      Utils.equalsIgnoreCase("UPDATED", status)
    ) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.saveToGst(e)}
          >
            Save To GST
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("SAVEREQUESTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.getSaveStatus(e)}
          >
            Get Save Status
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("SAVED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            // onClick={(e) => this.getSaveSummary(e)}
          >
            Get Save Summary
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            // onClick={(e) => this.submitToGst(e)}
          >
            Submit To GST
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("SUBMITREQUESTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            // onClick={(e) => this.getSubmitSaveSummary(e)}
          >
            Get Submit Summary
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    }
  }

  gstOTPToggle() {
    this.setState({
      isGstOTP: false,
    });
  }

  handleGstOTPChange(e) {
    this.setState({
      gstOtp: e.target.value,
    });
  }
  cancelGstr1Otp() {
    this.setState({
      gstOtp: null,
      isGstOTP: false,
    });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.menuWithBreadScrumRef = React.createRef();
    if (this.state.action === "create") {
      return this.createGSTRB();
    } else {
      return this.viewGSTRB();
    }
  }

  createGSTRB() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Create " + this.title}
          history={this.props.history}
          name="Create GSTR-3B"
          // docTitle={this.title}
        />

        <ValidatorForm
          style={{ width: "100%", color: "#000", marginTop: "1em" }}
          ref="form"
          onSubmit={this.createGSTR3Backend.bind(this)}
        >
          <Card className="userCard" style={{ height: "14rem" }}>
            <CardBody>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Year<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    fullWidth
                    id="year"
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    options={this.yearList ? this.yearList : []}
                    value={this.state.year}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => {
                      this.handleYearChange(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        placeholder="Year"
                        className="regCountry"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
                {this.state.fileFrequency === "Quarterly" && (
                  <Col md={6} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Select Quarter<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="month"
                      options={
                        null === this.state.quarterList
                          ? []
                          : this.state.quarterList
                      }
                      value={this.state.quarterMonth}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        this.handleQuarterlyFinancialsChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Select Quarter"
                          InputLabelProps={{
                            shrink: true,
                            style: {},
                          }}
                        />
                      )}
                    />
                  </Col>
                )}
                {this.state.fileFrequency === "Monthly" && (
                  <Col md={6} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Select Month<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="month"
                      options={null === this.monthList ? [] : this.monthList}
                      value={this.state.month}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        this.handleMonthlyGSTR3Change(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Select Month"
                          InputLabelProps={{
                            shrink: true,
                            style: {},
                          }}
                        />
                      )}
                    />
                  </Col>
                )}
              </Row>
            </CardBody>

            <CardFooter style={{ width: "100%" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <CreateButton type="submit" />
                <CancelButton onClick={(e) => this.cancelView()} />
              </div>
            </CardFooter>
          </Card>
        </ValidatorForm>
      </div>
    );
  }

  viewGSTRB() {
    console.log(this.state.isGstOTP);
    return (
      <>
        <div style={{ display: "grid" }}>
          <MenuWithBreadScrum
            ref={this.menuWithBreadScrumRef}
            breadCrumb={this.breadCrumb}
            addToBreadCrumb={"View " + this.title}
            history={this.props.history}
            name="View GSTR-3B"
            // docTitle={this.title}
          />
          <Row md={12} style={{ padding: "0.5em" }}>
            <Col md={6} style={{ padding: "5px", textAlign: "start" }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginLeft: "0.5rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            </Col>
            <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
              {(this.state.status?.status === "Created" ||
                this.state.status?.status === "Update") && (
                <DeleteIconButton
                  onClick={this.deleteGSTR3BInBackend.bind(this)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Card md={12} style={{ width: "100%" }}>
              <CardHeader>
                <Row style={{ padding: "0.5em" }}>
                  <Col md={3}>
                    <label style={{ color: "#000" }}>
                      <strong>Financial Period :</strong>
                    </label>
                    <span style={{ color: "#000" }}>
                      {this.state.returnPeriod}
                    </span>
                  </Col>
                  <Col md={3}>
                    <label style={{ color: "#000" }}>
                      <strong>Created Date :</strong>
                    </label>
                    <span style={{ color: "#000" }}>
                      {Utils.ptsDateFormat(this.state.createdon)}
                    </span>
                  </Col>
                  <Col md={3}>
                    <label style={{ color: "#000" }}>
                      <strong>Status:</strong>
                    </label>
                    <span style={{ color: "#000" }}>
                      {this.state.status ? this.state.status.status : ""}
                    </span>
                  </Col>
                  {/* <Col md={3}>
                  <div style={{ float: "right" }}>
                    <RefreshButton
                      onClick={this.refreshGSTR3.bind(this)}
                      style={{ float: "right" }}
                    />
                  </div>
                </Col> */}
                </Row>
              </CardHeader>

              <CardBody>
                <Row md={12}>
                  <Col md={4}>{this.getOutwardSupply()}</Col>

                  <Col md={4}>{this.getInterStateSupply()}</Col>

                  <Col md={4}>{this.getITCDetails()}</Col>
                </Row>

                <Row md={12} style={{ marginTop: "2em" }}>
                  <Col md={4}>{this.getInwardSupply()}</Col>

                  <Col md={4}>{this.getLateFees()}</Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row md={12} style={{ marginTop: "1em" }}>
            <Col>
              {/* <CardFooter style={{ width: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  style={{ backgroundColor: "#1a7088", color: "#fff" }}
                  variant="contained"
                  onClick={(e) => this.formValidation(e)}
                >
                  SAVE TO GSTN
                </Button>
                &nbsp; &nbsp;
                <Button
                  type="submit"
                  style={{ backgroundColor: "#1a7088", color: "#fff" }}
                  variant="contained"
                  onClick={(e) => this.formValidation(e)}
                >
                  GST SAVE STATUS
                </Button>
                &nbsp; &nbsp;
                <Button
                  type="submit"
                  style={{ backgroundColor: "#1a7088", color: "#fff" }}
                  variant="contained"
                  onClick={(e) => this.formValidation(e)}
                >
                  SUBMIT TO GSTN
                </Button>
                &nbsp; &nbsp;
                <NavLink
                  style={{ backgroundColor: "#777777", color: "#fff" }}
                  to="/gstr3list/"
                  activeClassName="selectedLink"
                  strict
                >
                  <Button
                    type="button"
                    variant="contained"
                    style={{ backgroundColor: "#717373", color: "#fff" }}
                  >
                    Back
                  </Button>
                </NavLink>
              </div>
            </CardFooter> */}
              <CardFooter>
                {this.getButtonsAsPerStatusAndPermission()}
              </CardFooter>
            </Col>
          </Row>
        </div>
        <Dialog open={this.state.isGstOTP} style={{ padding: "1em" }}>
          <DialogContent style={{ marginTop: "1em", width: "350px" }}>
            <Row>
              <Col md={12} style={{ textAlign: "center" }}>
                <h4
                  style={{
                    color: "#162c50",
                  }}
                >
                  {" "}
                  Enter OTP Code
                </h4>{" "}
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  type="number"
                  placeholder="Enter OTP Code"
                  validators={["required"]}
                  onChange={this.handleGstOTPChange.bind(this)}
                  value={this.state.gstOtp}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions
            style={{
              padding: "1.5em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                // float: 'right',
                background: "#1B7189",
                color: "#fff",
              }}
              onClick={this.saveToGst.bind(this)}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              size="small"
              style={{
                // float: 'right',
                background: "#717373",
                color: "#fff",
              }}
              onClick={this.cancelGstr1Otp.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
export default GSTRB;
