import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from "@material-ui/icons/Save";
import React, { Component } from "react";

export class SaveButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      type: props.type,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <Tooltip title="Save">
        <Button
          type={this.state.type ? this.state.type : ""}
          variant="contained"
          size="small"
          style={{ background: "#1b7189", color: "#fff", marginRight: "1rem" }}
          startIcon={<SaveIcon />}
          onClick={this.onClick.bind(this)}
        >
          Save
        </Button>
      </Tooltip>
    );
  }
}
export default SaveButton;
