import TextField from "@material-ui/core/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import "react-quill/dist/quill.bubble.css";
import { Card, CardBody, Col, Row } from "reactstrap";
import AdminFetchServerData from "../../../provider/AdminFetchServerData";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import BackButton from "../../components/buttons/BackButton";
import SaveButton from "../../components/buttons/SaveButton";
import PtsAlert from "../../components/ptsAlert/PtsAlert";
import AdminMenuWithBreadScrum from "../adminMenu/AdminMenuWithBreadScrum";
import Quill from "../terms&ConditionTemplate/quill/Quill";
import Tooltip from "../terms&ConditionTemplate/tooltip/Tooltip";
import CancelIconButton from "../../components/buttons/CancelButton";

class PrivacyPolicyTemplate extends Component {
  title = "Privacy Policy Template Details";
  breadCrumb = [
    { pageid: "/privacyPolicyList", uiname: "Privacy Policy Template List" },
    { pageid: "", uiname: "Privacy Policy Template Details" },
  ];
  header = store.getState().adminHeader.adminHeader;

  // adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  constructor(props) {
    super(props);
    let privacyPolicyTemplateId = props.privacyPolicyTemplateId;
    let action = props.action;
    if (
      !privacyPolicyTemplateId &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      privacyPolicyTemplateId =
        props.history.location.state.privacyPolicyTemplateId;
      action = props.history.location.state.action;
    }
    if (null !== privacyPolicyTemplateId && privacyPolicyTemplateId > 0) {
      this.getTemplateDetails(privacyPolicyTemplateId);
    }

    this.state = {
      action: action,
      message: "",
      version: "",
      anchorEl: null,
      id: privacyPolicyTemplateId,
      variableList: [],
      description: "",
      viewEmailUid: "",
      copied: false,
      render: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      isStatus: true,
      createdByName: "",
      updatedByName: "",
    };
  }
  getTemplateDetails(privacyPolicyTemplateId) {
    let postObject = {
      header: this.header,
      privacyPolicy: { id: privacyPolicyTemplateId },
    };
    AdminFetchServerData.callPostService("/apPrivacyPolicy/get", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          let templateDetails = output.data.privacyPolicy;

          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];

          this.setState({
            render: true,
            id: templateDetails.id,
            message: templateDetails.data,
            isStatus: templateDetails.isactive,
            version: templateDetails.version,
            createdByName: templateDetails.createdbyname,
            updatedByName: templateDetails.updatedbyname,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        }
      })
      .catch((error) => { });
  }

  updateTermsConditionTemplateInBackend() {
    let postObject = {
      header: this.header,

      privacyPolicy: {
        data: this.state.message,
        isactive: this.state.isStatus,
        version: this.state.version,
        id: this.state.id,
      },
    };
    AdminFetchServerData.callPostService("/apPrivacyPolicy/update", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let templateDetails = output.data.privacyPolicy;
          this.props.history.push({
            pathname: "/viewPrivacyPolicy",
            state: {
              action: "view",
              title: "Privacy Policy Template Details",
              privacyPolicyTemplateId: templateDetails.id,
            },
          });
        }
      })
      .catch((error) => { });
  }

  deletePrivacyPolicyToBackend = () => {
    const postObject = {
      header: this.header,
      privacyPolicy: {
        id: this.state.id,
      },
    };

    AdminFetchServerData.callPostService("/apPrivacyPolicy/delete", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.props.history.push({
            pathname: "/privacyPolicyList",
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  clearTemplate(e) {
    this.setState({
      message: "",
      version: "",
    });
  }

  handleMessageChange(value) {
    this.setState({ message: value });
  }

  handleClose(event) {
    this.setState({ anchorEl: null });
  }
  cancelEdit() {
    this.props.history.push({
      pathname: "/viewPrivacyPolicy",
      state: {
        action: "view",
        title: "Privacy Policy Template Details",
        privacyPolicyTemplateId: this.state.id,
        pageId: "termsConditionList",
      },
    });
  }

  cancelCreate() {
    this.props.history.push({
      pathname: "/privacyPolicyList",
    });
  }

  viewCancel() {
    this.props.history.push({
      pathname: "/privacyPolicyList",
    });
  }

  editPrivacyPolicy() {
    // let adminFccPageState = {
    //   id: this.state.id,
    //   action: "edit",
    //   title: "Email Template Details",
    //   pageId: "termsConditionList",
    // };
    // store.dispatch(
    //   adminFccPageStateAction.setAdminFccPageState(adminFccPageState)
    // );
    this.props.history.push({
      pathname: "/editPrivacyPolicy",
      state: {
        action: "edit",
        title: "Privacy Policy Template Details",
        privacyPolicyTemplateId: this.state.id,
      },
    });
  }
  handleVersionChange(e) {
    this.setState({
      version: e.target.value,
    });
  }

  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  addPrivacyPolicyTemplateInBackend() {
    let postObject = {
      header: this.header,

      privacyPolicy: {
        data: this.state.message,
        isactive: this.state.isStatus,
        version: this.state.version,
      },
    };
    AdminFetchServerData.callPostService("/apPrivacyPolicy/create", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let templateDetails = output.data.privacyPolicy;
          this.props.history.push({
            pathname: "/viewPrivacyPolicy",
            state: {
              action: "view",
              title: "Privacy Policy Template Details",
              privacyPolicyTemplateId: templateDetails.id,
            },
          });
        }
      })
      .catch((error) => { });
  }
  render() {
    if (this.state.action === "create") {
      return this.createPrivacyPolicyTemplate();
    } else if (this.state.action === "edit") {
      return this.editPrivacyPolicyTemplate();
    } else {
      return this.viewPrivacyPolicyTemplate();
    }
  }
  createPrivacyPolicyTemplate() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row style={{ position: "relative", margin: "1em" }}>
          <Col md={12} style={{ position: "relative", padding: "5px" }}>
            <ValidatorForm
              ref="form"
              style={{ width: "100%" }}
              onSubmit={this.addPrivacyPolicyTemplateInBackend.bind(this)}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">
                            Version <span className="asterisk">*</span>
                          </label>
                        </Col>
                        <Col md={6} style={{ margin: "-0.8em" }}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            required
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter Version"
                            autoComplete="off"
                            margin="normal"
                            value={this.state.version}
                            onChange={this.handleVersionChange.bind(this)}
                          // inputProps={{
                          //   style: { textTransform: 'capitalize' },
                          // }}
                          />
                        </Col>
                        <Col md={4}></Col>
                      </Row>

                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">Body</label>
                        </Col>
                        <Col md={10} style={{ marginTop: "2em" }}>
                          {this.state.variableList.length > 0 && (
                            <p style={{ marginLeft: "0.2em" }}>
                              The following variables has a significance role in
                              composing the email. Please do not change it.
                            </p>
                          )}

                          <Quill
                            text={this.state.message}
                            onChange={this.handleMessageChange.bind(this)}
                            className="templateMessageBox"
                            theme="snow"
                            readOnly={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

                <div style={{ textAlign: "center", padding: "0.5em" }}>
                  <SaveButton type="submit" />
                  &nbsp;
                  <CancelIconButton onClick={(e) => this.cancelCreate()} />
                </div>
              </Card>
            </ValidatorForm>
          </Col>
        </Row>
      </>
    );
  }
  viewPrivacyPolicyTemplate() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            backCallback={this.viewCancel.bind(this)}
            name={this.title}
            editCallback={this.editPrivacyPolicy.bind(this)}
            deleteCallback={this.deletePrivacyPolicyToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <Row style={{ position: "relative", margin: "1em" }}>
            <Col
              md={12}
              className="sponsorDetailColumn"
              style={{ position: "relative", padding: "5px" }}
            >
              <Card style={{ height: "100%" }}>
                <CardBody>
                  <Row>
                    <Col md={3} className="labelColumn">
                      <Row>
                        <Col md={4}>
                          <label className="bold">Version:</label>
                        </Col>
                        <Col md={8} style={{ textAlign: "left" }}>
                          {this.state.version}
                        </Col>{" "}
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={4} style={{ margin: "auto" }}>
                          <label className="bold"> Status</label>
                        </Col>
                        <Col md={8} style={{ textAlign: "left" }}>
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: "#27AE60",
                                    color: "#fff",
                                    height: "25px",
                                    textTransform: "none",
                                  }}
                                  disabled
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: "#c7c7c7",
                                    color: "#000",
                                    height: "25px",
                                    textTransform: "none",
                                  }}
                                  disabled
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={5}>
                          <label className="bold">Created By:</label>
                        </Col>
                        <Col md={7} style={{ textAlign: "left" }}>
                          {this.state.createdByName
                            ? this.state.createdByName
                            : ""}
                        </Col>{" "}
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={6}>
                          <label className="bold">Updated By:</label>
                        </Col>
                        <Col md={6} style={{ textAlign: "left" }}>
                          {this.state.updatedByName
                            ? this.state.updatedByName
                            : ""}
                        </Col>{" "}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label> Body:</label>
                    </Col>
                    <Col md={10}>
                      {" "}
                      {this.state.variableList.length > 0 && (
                        <p style={{ marginLeft: "0.2em" }}>
                          The following variables has a significance role in
                          composing the email. Please do not change it.
                        </p>
                      )}
                      <Row style={{ marginTop: "1em" }}>
                        <Col md={12}>
                          <div style={{ border: "1px solid black" }}>
                            <Quill
                              text={this.state.message}
                              onChange={this.handleMessageChange.bind(this)}
                              className="templateMessageBox"
                              theme="bubble"
                              readOnly={true}
                            />
                          </div>
                          {/* <div dangerouslySetInnerHTML={{ __html: this.state.message }} className="emailBody" /> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ textAlign: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              style={{ background: "#717373", color: "#fff", margin: "0.5em" }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.viewCancel(e)}
            >
              Back
            </Button>
          </div>
        </>
      );
    }
  }
  editPrivacyPolicyTemplate() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row style={{ position: "relative", margin: "1em" }}>
          <Col md={12} style={{ position: "relative", padding: "5px" }}>
            <ValidatorForm
              ref="form"
              style={{ width: "100%" }}
              onSubmit={this.updateTermsConditionTemplateInBackend.bind(this)}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">
                            Version <span className="asterisk">*</span>
                          </label>
                        </Col>
                        <Col md={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            required
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter Version"
                            autoComplete="off"
                            margin="normal"
                            value={this.state.version}
                            onChange={this.handleVersionChange.bind(this)}
                          />
                        </Col>
                        <Col md={4} style={{ marginTop: "auto" }}>
                          <Row>
                            <Col md={3} style={{ margin: "auto" }}>
                              <label> Status</label>
                            </Col>
                            <Col md={9} className="statusMember">
                              {this.state.isStatus === true && (
                                <Tooltip title="Please click EDIT to change status">
                                  <span>
                                    <ToggleButton
                                      value="Active"
                                      className="ToggleButtonActive"
                                      onChange={this.handleStatusChange.bind(
                                        this
                                      )}
                                      style={{
                                        background: "#27AE60",
                                        color: "#fff",
                                        height: "25px",
                                        textTransform: "none",
                                      }}
                                    >
                                      Active
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                              {this.state.isStatus === false && (
                                <Tooltip title="Please click EDIT to change status">
                                  <span>
                                    <ToggleButton
                                      value="Inactive"
                                      className="ToggleButtonInActive"
                                      onChange={this.handleStatusChange.bind(
                                        this
                                      )}
                                      style={{
                                        background: "#c7c7c7",
                                        color: "#000",
                                        height: "25px",
                                        textTransform: "none",
                                      }}
                                    >
                                      Inactive
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">Body</label>
                        </Col>
                        <Col md={10} style={{ marginTop: "2em" }}>
                          {this.state.variableList.length > 0 && (
                            <p style={{ marginLeft: "0.2em" }}>
                              The following variables has a significance role in
                              composing the email. Please do not change it.
                            </p>
                          )}

                          <Quill
                            text={this.state.message}
                            onChange={this.handleMessageChange.bind(this)}
                            className="templateMessageBox"
                            theme="snow"
                            readOnly={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

                <div style={{ textAlign: "center", padding: "0.5em" }}>
                  <SaveButton type="submit" />
                  &nbsp;
                  <CancelIconButton onClick={(e) => this.cancelEdit()} />
                </div>
              </Card>
            </ValidatorForm>
          </Col>
        </Row>
      </>
    );
  }
}

export default PrivacyPolicyTemplate;
