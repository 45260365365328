import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { NavLink, withRouter } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import "./ImportProductServices.scss";

export class ImportProductServices extends Component {
  taxRateList = store.getState().staticData.taxRateList;
  uomList = store.getState().staticData.uomList;
  productTypeList = store.getState().appData.productTypeList;
  title = "Product/Service Import";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let productId = props.productId;
    let action = props.action;
    /* Set Data From history */

    if (
      Utils.isNull(productId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      productId = props.history.location.state.productId;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    let canRender = true;
    if (productId && productId > 0) {
      this.getProductDetails(productId);
    } else {
      canRender = true;
    }
    this.state = {
      id: productId,
      productName: "",
      productCode: "",
      unitPrice: "",
      sacHsnCode: "",
      sacHsnDescription: "",
      productType: null,
      taxRate: null,
      uom: null,
      privileges: [],
      sacHsnCodeList: [],
      custSacHsnCode: null,
      action: action,
      render: canRender,
      sacHsnCodeReadonly: false,
      sacHsnDescriptionReadonly: true,
    };

    this.getSacHsnCodeList();
  }

  getProductDetails = (productId) => {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      productid: productId,
    };
    FetchServerData.callPostService("/spimpexp/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let product = output.data;
          this.setState({
            id: product.id,
            productName: product.name,
            productCode: product.code,
            productType: product.producttype,
            uom: product.uom,
            taxRate: product.taxrate,
            unitPrice: product.rate,
            privileges: product.privileges,
            custSacHsnCode: product.custhsncode,
            sacHsnDescription: product.custhsncode.description,
            render: true,
          });
          this.getMenuIconsAsPerStatusAndPermission();
        } else {
        }
      }
    );
  };

  handleNameChange(e) {
    this.setState({ productName: e.target.value });
  }

  handleProductCodeChange(e) {
    this.setState({ productCode: e.target.value });
  }

  handleUnitPriceChange(e) {
    this.setState({ unitPrice: e.target.value });
  }

  handleProductTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      productType: selectedType,
    });

    if (selectedType.id === 1) {
      this.setState({
        sacHsnCodeReadonly: true,
        sacHsnDescriptionReadonly: false,
      });
    } else {
      this.setState({
        sacHsnCodeReadonly: false,
        sacHsnDescriptionReadonly: true,
      });
    }
  }

  handletaxRateChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      taxRate: selectedType,
    });
  }

  handleUomChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      uom: selectedType,
    });
  }

  handleSacHsnCodeChange(selectedSacHsnCode) {
    if (null === selectedSacHsnCode || selectedSacHsnCode === "") {
      return;
    }
    this.setState({
      custSacHsnCode: selectedSacHsnCode,
      sacHsnCode: selectedSacHsnCode.code,
      sacHsnDescription: selectedSacHsnCode.description,
    });
  }
  handleSacHsnDescriptionChange(e) {
    this.setState({ sacHsnDescription: e.target.value });
  }

  getSacHsnCodeList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/custhsnms/getCustCodes", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let sacHsnCodeListArray = output.data;
          this.setState({
            sacHsnCodeList: sacHsnCodeListArray,
          });
        } else {
        }
      }
    );
  }

  createSupplierInBackend = () => {
    const header = store.getState().header.header;
    const postObject = {
      product: {
        name: this.state.productName,
        code: this.state.productCode,
        hsncode: this.state.sacHsnCode,
        rate: this.state.unitPrice,
        taxrate: this.state.taxRate,
        producttype: this.state.productType,
        custhsncode: this.state.custSacHsnCode,
        uom: this.state.uom,
      },
      header: header,
    };

    FetchServerData.callPostService("/productmgmt/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let product = output.data;
          this.props.history.push({
            pathname: "/importProductServiceView",
            state: {
              productId: product.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  updateProductInBackend = () => {
    const header = store.getState().header.header;
    const postObject = {
      product: {
        id: this.state.id,
        name: this.state.productName,
        code: this.state.productCode,
        hsncode: this.state.custSacHsnCode.code,
        rate: this.state.unitPrice,
        taxrate: this.state.taxRate,
        producttype: this.state.productType,
        custhsncode: this.state.custSacHsnCode,
        uom: this.state.uom,
      },
      header: header,
    };

    FetchServerData.callPostService("/productmgmt/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let product = output.data;
          this.props.history.push({
            pathname: "/importProductServiceView",
            state: {
              productId: product.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  editProductHandle() {
    this.props.history.push({
      pathname: "/importProductServiceEdit",
      productId: this.state.id,
      action: "edit",
      state: { customerId: this.state.id, action: "edit" },
    });
  }

  deleteButtonClicked = () => {
    const header = store.getState().header.header;
    const postObject = {
      product: {
        id: this.state.id,
      },
      header: header,
    };

    FetchServerData.callPostService("/productmgmt/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let product = output.data;
          this.props.history.push({
            pathname: "/prod/imp",
          });
        } else {
        }
      }
    );
  };

  editButtonClicked() {
    this.props.history.push({
      pathname: "/importProductServiceEdit",
      state: {
        productId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];
    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }

    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"];
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"];
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
      });
    }
  }

  cancelEdit() {
    this.props.history.push({
      pathname: "/importProductServiceView",
      state: {
        productId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.menuWithBreadScrumRef = React.createRef();
    if (this.state.action === "create") {
      return this.createProduct();
    }
    if (this.state.action === "edit") {
      return this.editProduct();
    } else {
      return this.viewproduct();
    }
  }

  editProduct() {
    return (
      <>
        <MenuWithBreadScrum
          name={"Edit " + this.title}
          addToBreadCrumb={"Edit " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
        />
        <Row md={12}>
          <Col md={12}>
            <ValidatorForm ref="form">
              <Row style={{ padding: "3em" }}>
                <Col md={12}>
                  <Card>
                    <CardHeader className="productHeading">
                      <b>Product Name</b>
                    </CardHeader>
                    <CardBody>
                      <Row className="productRow">
                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Customer Type
                          </label>
                          <TextField
                            fullWidth
                            id="productName"
                            type="text"
                            placeholder="Product Name"
                            validators={["required"]}
                            onChange={this.handleNameChange.bind(this)}
                            name="productName"
                            errorMessages={["Product Name is Required"]}
                            autoComplete="name"
                            className="textField"
                            value={this.state.productName}
                            variant="outlined"
                            // style={{ margin: "1em" }}
                          />
                          <label
                            className="form-label mt-2"
                            htmlFor="collapsible-companyName"
                          >
                            Product Code
                          </label>
                          <TextField
                            fullWidth
                            id="productCode"
                            type="text"
                            placeholder="Product Code"
                            validators={["required"]}
                            errorMessages={["Product Code is required"]}
                            value={this.state.productCode}
                            onChange={this.handleProductCodeChange.bind(this)}
                            autoComplete="productCode"
                            className="textField"
                            variant="outlined"
                            // style={{ margin: "1em" }}
                          />
                          <label
                            className="form-label mt-2"
                            htmlFor="collapsible-companyName"
                          >
                            Product Type
                          </label>
                          <Autocomplete
                            id="productType"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            fullWidth
                            options={
                              null === this.productTypeList
                                ? []
                                : this.productTypeList
                            }
                            value={this.state.productType}
                            getOptionLabel={(option) => option.type}
                            // style={{ margin: "1em" }}
                            onChange={(event, value) => {
                              this.handleProductTypeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Product Type"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                          <label
                            className="form-label mt-2"
                            htmlFor="collapsible-companyName"
                          >
                            Tax Rate
                          </label>
                          <Autocomplete
                            id="taxRate"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            fullWidth
                            options={
                              null === this.taxRateList ? [] : this.taxRateList
                            }
                            value={this.state.taxRate}
                            getOptionLabel={(option) => "" + option.rate}
                            // style={{ margin: "1em" }}
                            onChange={(event, value) => {
                              this.handletaxRateChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Tax Rate"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>
                        <Col md={6}>
                          <label
                            className="form-label mt-2"
                            htmlFor="collapsible-companyName"
                          >
                            Unit Price
                          </label>
                          <TextField
                            fullWidth
                            id="unitPrice"
                            type="text"
                            placeholder="Unit Price"
                            onChange={this.handleUnitPriceChange.bind(this)}
                            name="unitPrice"
                            validators={["required"]}
                            errorMessages={["Unit Price Name is Required"]}
                            autoComplete="unitPrice"
                            className="textField"
                            value={this.state.unitPrice}
                            variant="outlined"
                          />
                          <label
                            className="form-label mt-2"
                            htmlFor="collapsible-companyName"
                          >
                            UOM
                          </label>
                          <Autocomplete
                            id="uom"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            fullWidth
                            options={null === this.uomList ? [] : this.uomList}
                            value={this.state.uom}
                            getOptionLabel={(option) => option.nameCode}
                            onChange={(event, value) => {
                              this.handleUomChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="UOM"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                          <label
                            className="form-label mt-2"
                            htmlFor="collapsible-companyName"
                          >
                            SAC/HSN
                          </label>
                          <Autocomplete
                            id="sacHsnCode"
                            fullWidth
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            options={this.state.sacHsnCodeList}
                            variant="outlined"
                            value={this.state.custSacHsnCode}
                            disabled={this.state.sacHsnCodeReadonly}
                            getOptionLabel={(option) => option.code}
                            onChange={(event, value) => {
                              this.handleSacHsnCodeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="SAC/HSN"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                          <label
                            className="form-label mt-2"
                            htmlFor="collapsible-companyName"
                          >
                            SAC / HSN Description
                          </label>
                          <TextField
                            fullWidth
                            id="sacHsnDescription"
                            type="text"
                            variant="outlined"
                            disabled={this.state.sacHsnDescriptionReadonly}
                            placeholder="SAC / HSN Description"
                            value={this.state.sacHsnDescription}
                            onChange={this.handleSacHsnDescriptionChange.bind(
                              this
                            )}
                            name="sacHsnDescription"
                            autoComplete="sacHsnDescription"
                            className="textField"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ValidatorForm>

            <Row md={12} style={{ marginTop: "2em" }}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      id="submitButton"
                      variant="contained"
                      onClick={(e) => this.updateProductInBackend()}
                    >
                      Submit
                    </Button>
                    &nbsp;
                    <Button
                      style={{ backgroundColor: "#777777", color: "#fff" }}
                      onClick={(e) => this.cancelEdit()}
                    >
                      Cancle
                    </Button>
                    {/* <NavLink
                      id="cancleNavlink"
                      to={{
                        pathname: "/importProductServiceView",
                        state: { productId: this.state.id, action: "view" },
                      }}
                      activeClassName="selectedLink"
                      strict
                    >
                      <Button id="cancleButton">Cancel</Button>
                    </NavLink> */}
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  viewproduct() {
    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          name={"View " + this.title}
          addToBreadCrumb={"View " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          // editCallback={this.editButtonClicked.bind(this)}
          // deleteCallback={this.deleteButtonClicked.bind(this)}
        />
        <Row md={12}>
          <Col md={12}>
            <Card>
              <CardHeader className="productHeading">
                <b>
                  {null === this.props.title
                    ? "Product Details"
                    : this.props.title}
                </b>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000", fontWeight: "bold" }}>
                          Product Name
                        </label>
                      </Col>
                      <Col md={6}>{this.state.productName}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000", fontWeight: "bold" }}>
                          Product Code{" "}
                        </label>
                      </Col>
                      <Col md={6}>{this.state.productCode}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000", fontWeight: "bold" }}>
                          Product Type
                        </label>
                      </Col>
                      <Col md={4}>
                        {null === this.state.productType
                          ? ""
                          : this.state.productType.type}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000", fontWeight: "bold" }}>
                          UOM
                        </label>
                      </Col>
                      <Col md={6}>
                        {null === this.state.uom ? "" : this.state.uom.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000", fontWeight: "bold" }}>
                          Tax Rate
                        </label>
                      </Col>
                      <Col md={6}>
                        {null === this.state.taxRate
                          ? ""
                          : this.state.taxRate.rate}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000", fontWeight: "bold" }}>
                          Unit Price
                        </label>
                      </Col>
                      <Col md={6}>{this.state.unitPrice}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <label style={{ color: "#000", fontWeight: "bold" }}>
                          SAC / HSN Code and Description
                        </label>
                      </Col>
                      <Col md={6}>
                        {null === this.state.custSacHsnCode
                          ? ""
                          : this.state.custSacHsnCode.code +
                            "-" +
                            this.state.custSacHsnCode.description}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row md={12} style={{ marginTop: "2em" }}>
          <Col>
            <CardFooter style={{ width: "100%" }}>
              <div style={{ textAlign: "center" }}>
                &nbsp;
                <NavLink
                  style={{ backgroundColor: "#777777", color: "#fff" }}
                  to="/prod/imp"
                  activeClassName="selectedLink"
                  strict
                >
                  <Button
                    type="button"
                    variant="contained"
                    style={{ backgroundColor: "#1a7088", color: "#fff" }}
                  >
                    Back
                  </Button>
                </NavLink>
              </div>
            </CardFooter>
          </Col>
        </Row>
      </>
    );
  }

  createProduct() {
    return (
      <>
        <MenuWithBreadScrum
          name={"Create " + this.title}
          addToBreadCrumb={"Create " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
        />
        <Row md={12}>
          <Col md={12}>
            <ValidatorForm ref="form">
              <Row style={{ padding: "3em" }}>
                <Col md={12}>
                  <Card className="productCard">
                    {/* <CardHeader className="customerHeading">
                                <b>Customer Details</b>
                            </CardHeader>                   */}
                    <CardBody>
                      <Row className="productRow">
                        <Col md={6}>
                          <TextField
                            fullWidth
                            id="productName"
                            type="text"
                            placeholder="Product Name"
                            validators={["required"]}
                            onChange={this.handleNameChange.bind(this)}
                            name="productName"
                            errorMessages={["Product Name is Required"]}
                            autoComplete="name"
                            className="textField"
                            value={this.state.productName}
                            variant="outlined"
                          />

                          <TextField
                            fullWidth
                            id="productCode"
                            type="text"
                            placeholder="Product Code"
                            validators={["required"]}
                            errorMessages={["Product Code is required"]}
                            value={this.state.productCode}
                            onChange={this.handleProductCodeChange.bind(this)}
                            autoComplete="productCode"
                            className="textField"
                            variant="outlined"
                          />
                          <Autocomplete
                            id="productType"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            options={
                              null === this.productTypeList
                                ? []
                                : this.productTypeList
                            }
                            value={this.state.productType}
                            getOptionLabel={(option) => option.type}
                            onChange={(event, value) => {
                              this.handleProductTypeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Product Type"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                          <Autocomplete
                            id="taxRate"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            options={
                              null === this.taxRateList ? [] : this.taxRateList
                            }
                            value={this.state.taxRate}
                            getOptionLabel={(option) => "" + option.rate}
                            onChange={(event, value) => {
                              this.handletaxRateChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Tax Rate"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>
                        <Col md={6}>
                          <TextField
                            fullWidth
                            id="unitPrice"
                            type="text"
                            placeholder="Unit Price"
                            onChange={this.handleUnitPriceChange.bind(this)}
                            name="unitPrice"
                            validators={["required"]}
                            errorMessages={["Unit Price Name is Required"]}
                            autoComplete="unitPrice"
                            className="textField"
                            value={this.state.unitPrice}
                            variant="outlined"
                          />

                          <Autocomplete
                            id="uom"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            options={null === this.uomList ? [] : this.uomList}
                            value={this.state.uom}
                            getOptionLabel={(option) => option.nameCode}
                            onChange={(event, value) => {
                              this.handleUomChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="UOM"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />

                          <Autocomplete
                            id="sacHsnCode"
                            ListboxProps={{
                              className: "myCustomList",
                            }}
                            options={this.state.sacHsnCodeList}
                            value={this.state.custSacHsnCode}
                            disabled={this.state.sacHsnCodeReadonly}
                            getOptionLabel={(option) => option.code}
                            onChange={(event, value) => {
                              this.handleSacHsnCodeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="SAC/HSN"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />

                          <TextField
                            fullWidth
                            id="sacHsnDescription"
                            type="text"
                            disabled={this.state.sacHsnDescriptionReadonly}
                            placeholder="SAC / HSN Description"
                            value={this.state.sacHsnDescription}
                            onChange={this.handleSacHsnDescriptionChange.bind(
                              this
                            )}
                            name="sacHsnDescription"
                            autoComplete="sacHsnDescription"
                            className="textField"
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ValidatorForm>

            <Row md={12} style={{ marginTop: "2em" }}>
              <Col>
                <CardFooter style={{ width: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      //   style={{ backgroundColor: '#1a7088', color: '#fff' }}
                      variant="contained"
                      onClick={(e) => this.createSupplierInBackend()}
                      id="submitButton"
                    >
                      Submit
                    </Button>
                    &nbsp;
                    {/* <Button
                          style={{ backgroundColor: '#777777', color: '#fff' }}
                          onClick={e => this.cancel()}
                        >
                          Cancle
                        </Button> */}
                    <NavLink
                      id="cancleNavlink"
                      to="/prod/imp"
                      activeClassName="selectedLink"
                      strict
                    >
                      <Button id="cancleButton">Cancel</Button>
                    </NavLink>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(ImportProductServices);
