import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
// import "../layouts/ApplicationLayout.scss";
import handleKeyAccessibility, {
  handleClickAccessibility,
} from "../pts/helpers/handleTabAccessibility";
import routes from "../views";

const MOBILE_SIZE = 992;
export class WebsiteLayout extends Component {
  constructor(props) {
    super(props);
    //this.user = localStorage.getItem('user');
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      showSuccessAlert: false,
      showFailAlert: false,
      showSuccessLoader: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    } else {
      this.setState({ sidebarCollapsed: false, isMobile: false });
    }
  };
  componentDidUpdate(prev) {
    if (
      this.state.isMobile &&
      prev.location.pathname !== this.props.location.pathname
    ) {
      this.toggleSideCollapse();
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("keydown", handleKeyAccessibility);
    document.addEventListener("click", handleClickAccessibility);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  toggleSideCollapse = () => {
    this.setState((prevState) => ({
      sidebarCollapsed: !prevState.sidebarCollapsed,
    }));
  };
  closeChat = () => {
    this.setState({ showChat1: false });
  };
  handleSpanClick = () => {
    // localStorage.clear();
    this.props.history.push("/login");
  };
  logoutHandler = (e) => {
    // console.log('Logout Handler called');

    // store.clean();
    this.props.history.push("/login");
  };
  termsAndConditionsHandler = (e) => {
    this.props.history.push("/tandc");
  };

  profileHandler = (e) => {
    this.props.history.push("/admindetails");
  };
  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = !sidebarCollapsed
      ? "side-menu-collapsed"
      : "";
    return (
      <Switch>
        {routes.map((page, key) => (
          <Route path={page.path} component={page.component} key={key} />
        ))}
        <Redirect to="/home" />
      </Switch>
    );
  }
}
export default withRouter(WebsiteLayout);
