import { combineReducers } from "redux";
import headerReducer from "./header/headerReducer";
import staticDataReducer from "./staticData/staticDataReducer";
import appDataReducer from "./appData/appDataReducer";
import userMenusReducer from "./userMenus/userMenusReducer";
import appUserReducer from "./appUser/appUserReducer";
import masterDataReducer from "./masterData/masterDataReducer";
import registrationDataReducer from "./registrationData/registrationDataReducer";
import ApplicationStateReducer from "./applicationState/ApplicationStateReducer";
import UserProfileReducer from "./userProfile/UserProfileReducer";
import AdminAppUserDataReducer from "./adminPanel/appUserData/AdminAppUserDataReducer";
import AdminHeaderReducer from "./adminPanel/header/AdminHeaderReducer";
import AppUserRoleReducer from "./adminPanel/appUserRole/AppUserRoleReducer";
import OrganizationDataReducer from "./organizationData/OrganizationDataReducer";
import SettingDataReducer from "./settings/SettingDataReducer";
import BreadCrumbDetailsReducer from "./breadCrumbDetails/BreadCrumbDetailsReducer";
const appReducer = combineReducers({
  staticData: staticDataReducer,
  header: headerReducer,
  appData: appDataReducer,
  userMenus: userMenusReducer,
  appUser: appUserReducer,
  master: masterDataReducer,
  registrationInfo: registrationDataReducer,
  applicationState: ApplicationStateReducer,
  userProfile: UserProfileReducer,
  adminUserDetails: AdminAppUserDataReducer,
  adminHeader: AdminHeaderReducer,
  appUserRole: AppUserRoleReducer,
  organization: OrganizationDataReducer,
  setting: SettingDataReducer,
  breadCrumbDetails: BreadCrumbDetailsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_ACTION") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
