import Tooltip from "@material-ui/core/Tooltip";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
export class CreateSimilarIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainObject: props.onClick,
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          marginRight: "0.5em",
          float: "right",
        }}
      >
        <Tooltip title="Create Similar">
          <Button
            className="add"
            onClick={this.onClick.bind(this)}
            style={{
              background: "#246577",
              color: "white",
              fontSize: "x-larger",
              textTransform: "capitalize",
              cursor: "pointer",
              float: "right",
              padding: "4px 8px",
            }}
            startIcon={<ControlPointDuplicateIcon />}
          >
            Create Similar
          </Button>
        </Tooltip>
      </span>
    );
  }
}
export default CreateSimilarIconButton;
