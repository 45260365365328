import React from "react";
import { Row, Col } from "reactstrap";

export class TermsConditions extends React.Component {
	constructor() {
		super();
		this.state = {
			render: false,
		};
	}

	printContent() {
		let printContents = document.getElementById("printform").innerHTML;
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();

		document.body.innerHTML = originalContents;
		this.setState({ render: true });
		document.location.reload(true);
	}
	render() {
		return (
			<div
				id="termsConditionSection"
				style={{
					height: "90vh",
					overflowY: "scroll",
					padding: "0px 50px 30px 50px",
					background: "#fff",
				}}
			>
				<div
					style={{
						border: "2px solid lightgray",
						marginTop: " 10px",
						padding: "1em",
					}}
				>
					<section id="printform" className="contact">
						<Row>
							<Col md={12}>
								<h3 style={{ color: "#000", textAlign: "center" }}>
									<b>
										<u>Terms & Conditions</u>
									</b>
								</h3>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em", fontWeight: "400" }}>
							<Col md={12} style={{ textAlign: "left" }}>
								<p>
									The product Taxonomy Hub, and its contents and services solely belong to Proto Technology Solutions.
									These terms govern your access and usage of Taxonomy Hub and its supportive backend operations.
								</p>
								<p>
									THIS PRODUCT IS NEITHER A FREEWARE NOR SHAREWARE.
									IT IS A COMMERCIALLY LICENSED PRODUCT. IT MUST BE
									PURCHASED FROM THE LICENSOR FOR USE BY ANY INDIVIDUAL OR PARTY.
								</p>
								<p>
									By using the services, you agree to abide by the rules and regulations
									mentioned in the Terms of Service. If you make use of Taxonomy Hub as a
									representative from an organization, you are agreeing and should make sure
									that you keep that organization aware of these terms.
								</p>
								<br />
								<p>
									<b>
										The use of this website is subject to the following terms of
										use:
									</b>
								</p>
								<ul className="termsList">
									<li>
										<p>
											Taxonomy Hub’s services may be changed or updated as a part of refinement
											process and changes of such kind in the service will be included in the Terms
											of Service as and when they are done. It is your responsibility to check
											this Agreement periodically for changes.
										</p>
									</li>
									<li>
										<p>The services and the software are provided “as is”. </p>
									</li>
									<li>
										<p>
											Taxonomy Hub does not give any assurance that the website will be free of errors
											and that the access to the product (Taxonomy Hub) will be continuous or uninterrupted.
											Though all efforts will be made to provide the uninterrupted services.
										</p>
									</li>
									<li>
										<p>
											The software may under no circumstances be distributed to
											any third party or individual.
										</p>
									</li>
									<li>
										<p>
											The software may be used to serve multiple users of your
											organisation.
										</p>
									</li>
									<li>
										<p>
											The software may not be resold by any means without
											expressed permission from Proto Technology Solutions.
										</p>
									</li>{" "}
									<li>
										<p>
											Your complete legal name, valid email address and other
											required information are a must for the successful
											completion of the signup process.
										</p>
									</li>
									<li>
										<p>
											The responsibility of maintaining the privacy and security of your account
											lies with you. Taxonomy Hub will not be held liable for harm, damage
											and loss that results due to your failure to protect your account
											login credentials, including password.
										</p>
									</li>
									<li>
										<p>
											For all your account related issues, updates and system updates
											Taxonomy Hub will be contacting you via email.
										</p>
									</li>
									<li>
										<p>
											You are responsible for all contents posted, updated and activities
											performed under your account.
										</p>
									</li>
									<li>
										<p>
											Unauthorised use of this website may give rise to a claim for damages
											and/or be a criminal offence.
										</p>
									</li>
								</ul>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		);
	}
}

export default TermsConditions;
