import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { ValidatorForm } from "react-material-ui-form-validator";
import { NavLink } from "react-router-dom";
import { CardBody, CardFooter, Col, Row } from "reactstrap";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FetchServerData from "../../../../provider/FetchServerData";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import Utils from "../../../../provider/Utils";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
// import './ProductServices.scss';

export class ProductServices extends Component {
  taxRateList = store.getState().staticData.taxRateList;
  uomList = store.getState().staticData.uomList;
  productTypeList = store.getState().appData.productTypeList;
  menuWithBreadScrumRef = null;
  breadCrumb = [];
  title = "Product/Service";
  constructor(props) {
    super(props);
    let productId = props.productId;
    let action = props.action;
    if (
      !productId &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      productId = props.history.location.state.productId;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    let canRender = false;
    if (productId && productId > 0) {
      this.getProductDetails(productId);
    } else {
      canRender = true;
    }
    this.state = {
      id: productId,
      productName: "",
      productCode: "",
      unitPrice: "",
      sacHsnCode: "",
      sacHsnDescription: "",
      productType: null,
      taxRate: null,
      uom: null,
      sacHsnCodeList: [],
      privileges: [],
      custSacHsnCode: null,
      action: action,
      render: canRender,
      isActive: false,
      sacHsnCodeReadonly: false,
      sacHsnDescriptionReadonly: true,
      pageSize: 10,
      initialPage: 0,
    };

    this.getSacHsnCodeList();
    // this.getProductDetails(productId);
  }

  getProductDetails = (productId) => {
    const header = store.getState().header.header;
    const postObject = {
      product: {
        id: productId,
      },
      header: header,
    };

    FetchServerData.callPostService("/productmgmt/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let product = output.data;
          this.setState({
            id: product.id,
            productName: product.name,
            productCode: product.code,
            productType: product.producttype,
            uom: product.uom,
            taxRate: product.taxrate,
            unitPrice: product.rate,
            custSacHsnCode: product.custhsncode ? product.custhsncode : null,
            sacHsnDescription:
              product.custhsncode && product.custhsncode.description
                ? product.custhsncode.description
                : "",
            privileges: product.privileges,
            isActive: product.isActive,
            render: true,
          });
          this.getMenuIconsAsPerStatusAndPermission();
        } else {
        }
      }
    );
  };

  handleNameChange(e) {
    this.setState({ productName: e.target.value });
  }

  handleProductCodeChange(e) {
    this.setState({ productCode: e.target.value });
  }

  handleUnitPriceChange(e) {
    this.setState({ unitPrice: e.target.value });
  }

  handleProductTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      productType: selectedType,
    });

    if (selectedType.id === 1) {
      this.setState({
        sacHsnCodeReadonly: true,
        sacHsnDescriptionReadonly: false,
      });
    } else {
      this.setState({
        sacHsnCodeReadonly: false,
        sacHsnDescriptionReadonly: true,
      });
    }
  }

  handletaxRateChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      taxRate: selectedType,
    });
  }

  handleUomChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      uom: selectedType,
    });
  }

  handleSacHsnCodeChange(selectedSacHsnCode) {
    if (null === selectedSacHsnCode || selectedSacHsnCode === "") {
      return;
    }
    this.setState({
      custSacHsnCode: selectedSacHsnCode,
      sacHsnCode: selectedSacHsnCode.code,
      sacHsnDescription: selectedSacHsnCode.description,
    });
  }
  handleSacHsnDescriptionChange(e) {
    this.setState({ sacHsnDescription: e.target.value });
  }

  getSacHsnCodeList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/custhsnms/getCustCodes", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let sacHsnCodeListArray = output.data;
          this.setState({
            sacHsnCodeList: sacHsnCodeListArray,
          });
        } else {
        }
      }
    );
  }

  createProductInBackend = (e) => {
    e.preventDefault();
    const header = store.getState().header.header;
    const postObject = {
      product: {
        name: this.state.productName,
        code: this.state.productCode,
        hsncode: this.state.sacHsnCode,
        rate: this.state.unitPrice,
        taxrate: this.state.taxRate,
        producttype: this.state.productType,
        custhsncode: this.state.custSacHsnCode,
        uom: this.state.uom,
      },
      header: header,
    };

    FetchServerData.callPostService("/productmgmt/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let product = output.data;
          let productServicesMasterState = {
            invoiceId: product.id,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(
            ApplicationStateAction.setApplicationState({
              productServicesMasterState,
            })
          );
          this.props.history.push({
            pathname: "/productview",
            state: {
              productId: product.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  updateProductInBackend = (e) => {
    e.preventDefault();
    const header = store.getState().header.header;
    const postObject = {
      product: {
        id: this.state.id,
        name: this.state.productName,
        code: this.state.productCode,
        hsncode: this.state.custSacHsnCode.code,
        rate: this.state.unitPrice,
        taxrate: this.state.taxRate,
        producttype: this.state.productType,
        custhsncode: this.state.custSacHsnCode,
        uom: this.state.uom,
        isActive: this.state.isActive,
      },
      header: header,
    };

    FetchServerData.callPostService("/productmgmt/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let product = output.data;
          this.props.history.push({
            pathname: "/productview",
            state: {
              productId: product.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  };

  deleteButtonClicked = () => {
    const header = store.getState().header.header;
    const postObject = {
      product: {
        id: this.state.id,
      },
      header: header,
    };

    FetchServerData.callPostService("/productmgmt/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let product = output.data;
          this.props.history.push({
            pathname: "/prodserv",
          });
        } else {
        }
      }
    );
  };

  editButtonClicked() {
    this.props.history.push({
      pathname: "/productedit",
      state: {
        productId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];
    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }

    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"];
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"];
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
      });
    }
  }
  handleProductStatusChange() {
    this.setState({
      isActive: !this.state.isActive,
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }

    this.menuWithBreadScrumRef = React.createRef();
    if (this.state.action === "create") {
      return this.createProduct();
    }
    if (this.state.action === "edit") {
      return this.editProduct();
    } else {
      return this.viewProduct();
    }
  }

  editProduct() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          addToBreadCrumb="Edit Product/Service"
          name="Edit Product/Service"
        />
        <div style={{ padding: "1em", width: "100%" }} className="product_gst">
          <ValidatorForm
            ref="form"
            onSubmit={this.updateProductInBackend.bind(this)}
          >
            <Row md={12}>
              <Col md={6}>
                <Card style={{ height: "100%" }}>
                  <CardBody>
                    <Col md={12}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Product Name<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        required
                        variant="outlined"
                        // InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="productName"
                        type="text"
                        placeholder="Product Name"
                        value={this.state.productName}
                        onChange={this.handleNameChange.bind(this)}
                        name="productName"
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Product Code<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        required
                        variant="outlined"
                        // InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="productCode"
                        type="text"
                        placeholder="Product Code"
                        errorMessages={["Product Code is required"]}
                        value={this.state.productCode}
                        onChange={this.handleProductCodeChange.bind(this)}
                        autoComplete="productCode"
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Product Type<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="productType"
                        options={
                          null === this.productTypeList
                            ? []
                            : this.productTypeList
                        }
                        value={this.state.productType}
                        getOptionLabel={(option) => option.type}
                        onChange={(event, value) => {
                          this.handleProductTypeChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="Product Type"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        UOM<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="uom"
                        options={null === this.uomList ? [] : this.uomList}
                        value={this.state.uom}
                        getOptionLabel={(option) => option.nameCode}
                        onChange={(event, value) => {
                          this.handleUomChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="UOM"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Unit Price<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        required
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="unitPrice"
                        type="text"
                        placeholder="Unit Price"
                        onChange={this.handleUnitPriceChange.bind(this)}
                        name="unitPrice"
                        errorMessages={["Unit Price Name is Required"]}
                        autoComplete="unitPrice"
                        value={this.state.unitPrice}
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card style={{ height: "100%", position: "relative" }}>
                  <CardBody>
                    <Col md={12}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Tax Rate<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="taxRate"
                        options={
                          null === this.taxRateList ? [] : this.taxRateList
                        }
                        value={this.state.taxRate}
                        getOptionLabel={(option) => "" + option.rate + "%"}
                        onChange={(event, value) => {
                          this.handletaxRateChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="Tax Rate"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>

                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        SAC/HSN<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="sacHsnCode"
                        options={this.state.sacHsnCodeList}
                        value={this.state.custSacHsnCode}
                        disabled={this.state.sacHsnCodeReadonly}
                        getOptionLabel={(option) => option.code}
                        onChange={(event, value) => {
                          this.handleSacHsnCodeChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="SAC/HSN"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        SAC / HSN Description
                      </label>
                      <TextareaAutosize
                        aria-label="minimum height"
                        id="sacHsnDescription"
                        name="sacHsnDescription"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        style={{
                          width: "100%",
                          padding: "10px",
                          border: "0.5px solid #D5D8DC",
                        }}
                        variant="outlined"
                        value={this.state.sacHsnDescription}
                        onChange={this.handleSacHsnDescriptionChange.bind(this)}
                        disabled={this.state.sacHsnDescriptionReadonly}
                        minRows={4}
                        placeholder="Description *"
                        required
                      />
                    </Col>

                    <Col md={6} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Status:
                      </label>
                      {this.state.isActive ? (
                        <ToggleButton
                          className="ToggleButtonActive"
                          onChange={this.handleProductStatusChange.bind(this)}
                        >
                          Active
                        </ToggleButton>
                      ) : (
                        <ToggleButton
                          className="ToggleButtonInActive"
                          onChange={this.handleProductStatusChange.bind(this)}
                        >
                          InActive
                        </ToggleButton>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row md={12} style={{ padding: "2em" }}>
              <CardFooter style={{ width: "100%" }}>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    id="submitButton"
                    variant="contained"
                    // onClick={(e) => this.updateProductInBackend()}
                  >
                    Save
                  </Button>
                  &nbsp;
                  {/* <Button
                          style={{ backgroundColor: '#777777', color: '#fff' }}
                          onClick={e => this.cancel()}
                        >
                          Cancle
                        </Button> */}
                  <NavLink
                    id="cancleNavlink"
                    to={{
                      pathname: "/productview/",
                      state: { productId: this.state.id, action: "view" },
                    }}
                    activeClassName="selectedLink"
                    strict
                  >
                    <Button id="cancleButton">Cancel</Button>
                  </NavLink>
                </div>
              </CardFooter>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }
  cancelView() {
    this.props.history.push({ pathname: "/prodserv/" });
  }
  viewProduct() {
    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name="View Product/Service"
          addToBreadCrumb="View Product/Service"
          // editCallback={this.editButtonClicked.bind(this)}
          // deleteCallback={this.deleteButtonClicked.bind(this)}
          domainObject="Product/Service"
          docTitle={this.state.productName}
          backCallback={this.cancelView.bind(this)}
        />
        <div style={{ padding: "1em", width: "100%" }} className="product_gst">
          <Row md={12}>
            <Col
              md={6}
              style={{
                display: "flex",
                flexGrow: "1",
                flexDirection: "column",
              }}
            >
              <Card
                style={{ padding: "1em", height: "100%", position: "relative" }}
              >
                <CardBody>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Product Name</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.productType ? this.state.productName : ""}
                    </Col>
                  </Row>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Product Type</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.productType
                        ? this.state.productType.type
                        : ""}
                    </Col>
                  </Row>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Product Code</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.productCode}
                    </Col>
                  </Row>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>UOM</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.uom ? this.state.uom.name : ""}
                    </Col>
                  </Row>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Unit Price</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.unitPrice}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} style={{ display: "flex", flexGrow: "1" }}>
              <Card
                style={{ padding: "1em", height: "100%", position: "relative" }}
              >
                <CardBody>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>SAC/HSN Code</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.custSacHsnCode
                        ? this.state.custSacHsnCode.code
                        : ""}
                    </Col>
                  </Row>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>SAC/HSN Description</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.custSacHsnCode
                        ? this.state.custSacHsnCode.description
                        : ""}
                    </Col>
                  </Row>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Tax Rate</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.taxRate ? this.state.taxRate.rate + "%" : ""}
                    </Col>
                  </Row>
                  <Row style={{ padding: "0.5em" }}>
                    <Col md={4}>
                      <label style={{ color: "#000" }}>
                        <strong>Status</strong>
                      </label>
                    </Col>
                    <Col md={8} style={{ color: "#000" }}>
                      {this.state.isActive ? "Active" : "InActive"}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row md={12} style={{ marginTop: "2em" }}>
            <Col>
              <CardFooter style={{ width: "100%" }}>
                <div style={{ textAlign: "center" }}>
                  &nbsp;
                  <NavLink
                    style={{ backgroundColor: "#777777", color: "#fff" }}
                    to="/prodserv/"
                    activeClassName="selectedLink"
                    strict
                  >
                    <Button
                      type="button"
                      variant="contained"
                      style={{ backgroundColor: "#717373", color: "#fff" }}
                    >
                      Back
                    </Button>
                  </NavLink>
                </div>
              </CardFooter>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  createProduct() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb.slice(0, 2)}
          addToBreadCrumb="Create Product/Service"
          history={this.props.history}
          name="Create Product/Service"
        />
        <div style={{ padding: "1em", width: "100%" }} className="product_gst">
          <ValidatorForm
            ref="form"
            onSubmit={this.createProductInBackend.bind(this)}
          >
            <Row md={12}>
              <Col md={6}>
                <Card style={{ height: "100%" }}>
                  <CardBody>
                    <Col md={12}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Product Name<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        required
                        variant="outlined"
                        // InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="productName"
                        type="text"
                        placeholder="Product Name"
                        value={this.state.productName}
                        onChange={this.handleNameChange.bind(this)}
                        name="productName"
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Product Code<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        required
                        variant="outlined"
                        // InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="productCode"
                        type="text"
                        placeholder="Product Code"
                        errorMessages={["Product Code is required"]}
                        value={this.state.productCode}
                        onChange={this.handleProductCodeChange.bind(this)}
                        autoComplete="productCode"
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Product Type<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="productType"
                        options={
                          null === this.productTypeList
                            ? []
                            : this.productTypeList
                        }
                        value={this.state.productType}
                        getOptionLabel={(option) => option.type}
                        onChange={(event, value) => {
                          this.handleProductTypeChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="Product Type"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        UOM<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="uom"
                        options={null === this.uomList ? [] : this.uomList}
                        value={this.state.uom}
                        getOptionLabel={(option) => option.nameCode}
                        onChange={(event, value) => {
                          this.handleUomChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="UOM"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Unit Price<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        required
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="unitPrice"
                        type="text"
                        placeholder="Unit Price"
                        onChange={this.handleUnitPriceChange.bind(this)}
                        name="unitPrice"
                        errorMessages={["Unit Price Name is Required"]}
                        autoComplete="unitPrice"
                        value={this.state.unitPrice}
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card style={{ height: "100%", position: "relative" }}>
                  <CardBody>
                    <Col md={12}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Tax Rate<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="taxRate"
                        options={
                          null === this.taxRateList ? [] : this.taxRateList
                        }
                        value={this.state.taxRate}
                        getOptionLabel={(option) => "" + option.rate + "%"}
                        onChange={(event, value) => {
                          this.handletaxRateChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="Tax Rate"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>

                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        SAC/HSN<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        fullWidth
                        ListboxProps={{
                          className: "myCustomList",
                        }}
                        id="sacHsnCode"
                        options={this.state.sacHsnCodeList}
                        value={this.state.custSacHsnCode}
                        disabled={this.state.sacHsnCodeReadonly}
                        getOptionLabel={(option) => option.code}
                        onChange={(event, value) => {
                          this.handleSacHsnCodeChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="outlined"
                            fullWidth
                            {...params}
                            className="regCountry"
                            placeholder="SAC/HSN"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        SAC / HSN Description
                      </label>
                      <TextareaAutosize
                        aria-label="minimum height"
                        id="sacHsnDescription"
                        name="sacHsnDescription"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        style={{
                          width: "100%",
                          padding: "10px",
                          border: "0.5px solid #D5D8DC",
                        }}
                        variant="outlined"
                        value={this.state.sacHsnDescription}
                        onChange={this.handleSacHsnDescriptionChange.bind(this)}
                        disabled={this.state.sacHsnDescriptionReadonly}
                        minRows={4}
                        placeholder="Description *"
                        required
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row md={12} style={{ padding: "2em" }}>
              <CardFooter style={{ width: "100%" }}>
                <div style={{ textAlign: "center" }}>
                  <Button type="submit" id="submitButton" variant="contained">
                    Create
                  </Button>
                  &nbsp;
                  <NavLink
                    id="cancleNavlink"
                    to={{
                      pathname: "/prodserv/",
                      state: { productId: this.state.id, action: "view" },
                    }}
                    activeClassName="selectedLink"
                    strict
                  >
                    <Button id="cancleButton">Cancel</Button>
                  </NavLink>
                </div>
              </CardFooter>
            </Row>
          </ValidatorForm>
        </div>
      </>
    );
  }

  // createProduct() {
  //   return (
  //     <>
  //       <MenuWithBreadScrum
  //         breadCrumb={this.breadCrumb}
  //         addToBreadCrumb={this.title}
  //         history={this.props.history}
  //         name="Update Product"
  //       />

  //       <Row md={12}>
  //         <Col md={12}>
  //           <ValidatorForm ref="form">
  //             <Row style={{ padding: '3em' }}>
  //               <Col md={12}>
  //                 <Card className="productCard">
  //                   {/* <CardHeader className="customerHeading">
  //                               <b>Customer Details</b>
  //                           </CardHeader>                   */}
  //                   <CardBody>
  //                     <Row className="productRow">
  //                       <Col md={6}>
  //                         <TextField
  //                           variant="outlined"
  //                           InputLabelProps={{ shrink: true, }}
  //                           fullWidth
  //                           id="productName"
  //                           type="text"
  //                           label="Product Name"
  //
  //                           value={this.state.productName}
  //                           onChange={this.handleNameChange.bind(this)}
  //                           name="productName"
  //
  //                           errorMessages={['Product Name is Required']}
  //
  //
  //                           value={this.state.productName}
  //                         />

  //                         <TextField
  //                           variant="outlined"
  //                           InputLabelProps={{ shrink: true, }}
  //                           fullWidth
  //                           id="productCode"
  //                           type="text"
  //                           label="Product Code"
  //
  //                           errorMessages={['Product Code is required']}
  //                           value={this.state.productCode}
  //                           onChange={this.handleProductCodeChange.bind(this)}
  //                           autoComplete="productCode"
  //
  //                         />
  //                         <Autocomplete
  //                           id="productType"
  //                           options={null === this.productTypeList ? [] : this.productTypeList}
  //                           value={this.state.productType}
  //                           getOptionLabel={(option) => option.type}
  //                           onChange={(event, value) => {
  //                             //console.log(value);
  //                             this.handleProductTypeChange(value);
  //                           }}
  //                           renderInput={(params) => (
  //                             <TextField
  //                               variant="outlined"
  //                               InputLabelProps={{ shrink: true, }}
  //                               {...params}
  //                               label="Product Type"
  //                             />
  //                           )}
  //                         />
  //                         <Autocomplete
  //                           id="taxRate"
  //                           options={null === this.taxRateList ? [] : this.taxRateList}
  //                           value={this.state.taxRate}
  //                           getOptionLabel={(option) => '' + option.rate}
  //                           onChange={(event, value) => {
  //                             //console.log(value);
  //                             this.handletaxRateChange(value);
  //                           }}
  //                           renderInput={(params) => (
  //                             <TextField
  //                               variant="outlined"
  //                               InputLabelProps={{ shrink: true, }}
  //                               {...params}
  //                               label="Tax Rate"
  //                             />
  //                           )}
  //                         />
  //                       </Col>
  //                       <Col md={6}>
  //                         <TextField
  //                           variant="outlined"
  //                           InputLabelProps={{ shrink: true, }}
  //                           fullWidth
  //                           id="unitPrice"
  //                           type="text"
  //                           label="Unit Price"
  //                           value={this.state.unitPrice}
  //                           onChange={this.handleUnitPriceChange.bind(this)}
  //                           name="unitPrice"
  //
  //                           errorMessages={['Unit Price Name is Required']}
  //                           autoComplete="unitPrice"
  //
  //                           value={this.state.unitPrice}
  //                         />

  //                         <Autocomplete
  //                           id="uom"
  //                           options={null === this.uomList ? [] : this.uomList}
  //                           value={this.state.uom}
  //                           getOptionLabel={(option) => option.nameCode}
  //                           onChange={(event, value) => {
  //                             //console.log(value);
  //                             this.handleUomChange(value);
  //                           }}
  //                           renderInput={(params) => (
  //                             <TextField
  //                               variant="outlined"
  //                               InputLabelProps={{ shrink: true, }}
  //                               {...params}
  //                               label="UOM"
  //                             />
  //                           )}
  //                         />

  //                         <Autocomplete
  //                           id="sacHsnCode"
  //                           options={this.state.sacHsnCodeList}
  //                           value={this.state.custSacHsnCode}
  //                           disabled={this.state.sacHsnCodeReadonly}
  //                           getOptionLabel={(option) => option.code}
  //                           onChange={(event, value) => {
  //                             //console.log(value);
  //                             this.handleSacHsnCodeChange(value);
  //                           }}
  //                           renderInput={(params) => (
  //                             <TextField
  //                               variant="outlined"
  //                               InputLabelProps={{ shrink: true, }}
  //                               {...params}
  //                               label="SAC/HSN Code"
  //                             />
  //                           )}
  //                         />

  //                         <TextField
  //                           variant="outlined"
  //                           InputLabelProps={{ shrink: true, }}
  //                           fullWidth
  //                           id="sacHsnDescription"
  //                           type="text"
  //                           disabled={this.state.sacHsnDescriptionReadonly}
  //                           label="SAC / HSN Description"
  //                           value={this.state.sacHsnDescription}
  //                           onChange={this.handleSacHsnDescriptionChange.bind(this)}
  //                           name="sacHsnDescription"
  //                           autoComplete="sacHsnDescription"
  //
  //                         />
  //                       </Col>
  //                     </Row>
  //                   </CardBody>
  //                 </Card>
  //               </Col>
  //             </Row>
  //           </ValidatorForm>

  //           <Row md={12} style={{ marginTop: '2em' }}>
  //             <Col>
  //               <CardFooter style={{ width: '100%' }}>
  //                 <div style={{ textAlign: 'center' }}>
  //                   <Button
  //                     type="submit"
  //                     //   style={{ backgroundColor: '#1a7088', color: '#fff' }}
  //                     variant="contained"
  //                     onClick={(e) => this.createProductInBackend()}
  //                     id="submitButton"
  //                   >
  //                     Submit
  //                   </Button>
  //                   &nbsp;
  //                   {/* <Button
  //                         style={{ backgroundColor: '#777777', color: '#fff' }}
  //                         onClick={e => this.cancel()}
  //                       >
  //                         Cancle
  //                       </Button> */}
  //                   <NavLink id="cancleNavlink" to="/prodserv/" activeClassName="selectedLink" strict>
  //                     <Button id="cancleButton">Cancel</Button>
  //                   </NavLink>
  //                 </div>
  //               </CardFooter>
  //             </Col>
  //           </Row>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // }
}
export default ProductServices;
