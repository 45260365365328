import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import BackIconButton from "../../components/buttons/BackIconButton";
import CreateSimilarIconButton from "../../components/buttons/CreateSimilarIconButton";
import DeleteIconButton from "../../components/buttons/DeleteIconButton";
import EditIconButton from "../../components/buttons/EditIconButton";
import PrintIconButton from "../../components/buttons/PrintIconButton";

export class AdminMenuWithBreadScrum extends Component {
	constructor(props) {
		super(props);
		this.state = {
			domainObject: this.props.domainObject,
			name: this.props.name,
			breadCrumb: this.props.breadCrumb,
			docTitle: this.props.docTitle,
			editCallback: this.props.editCallback,
			deleteCallback: this.props.deleteCallback,
			printCallback: this.props.printCallback,
			createSimilarCallback: this.props.createSimilarCallback,
			breadScrum: this.props.breadScrum,
			addToBreadCrumb: this.props.addToBreadCrumb,
			backCallback: this.props.backCallback,
		};
	}

	goToPage(element) {
		if (element.pageid) {
			if (this.props.history) {
				this.props.history.push({
					pathname: element.pageid,
				});
			}
		}
	}
	getBreadCrumb() {
		if (this.state.breadCrumb) {
			let array = [];
			this.props.breadCrumb.forEach((element, index) => {
				array.push(
					<Link
						key={index}
						style={{ color: "#000", cursor: "pointer" }}
						onClick={(e) => this.goToPage(element)}
					>
						<span>
							<i
								className={element.cssclass}
								style={{ color: "#c16767", fontSize: "large" }}
							></i>
						</span>
						&nbsp;{element.uiname}
					</Link>
				);
			});
			if (this.state.addToBreadCrumb && this.state.addToBreadCrumb.length > 0) {
				array.push(
					<span key="999" style={{ color: "#000" }}>
						&nbsp;{this.state.addToBreadCrumb}
					</span>
				);
			}
			let home = { pageid: "/APDashboard", uiname: "Home" };
			return (
				<div style={{ float: "right", display: "flex" }}>
					<Breadcrumbs separator="›">
						<Link
							onClick={(e) => this.goToPage(home)}
							style={{ color: "#000", cursor: "pointer" }}
						>
							<HomeIcon style={{ color: "#c16767", fontSize: "large" }} />
							&nbsp;{home.uiname}
						</Link>
						{array}
					</Breadcrumbs>
				</div>
			);
		} else {
			return <div />;
		}
	}
	render() {
		this.state.name = this.props.name;
		let editIcon = null;
		if (this.state.editCallback && this.state.editCallback) {
			editIcon = (
				<EditIconButton
					domainObject={this.state.domainObject}
					onClick={this.state.editCallback}
				/>
			);
		}
		let deleteIcon = null;
		if (this.state.deleteCallback && this.state.deleteCallback) {
			deleteIcon = (
				<DeleteIconButton
					domainObject={this.state.domainObject}
					onClick={this.state.deleteCallback}
				/>
			);
		}
		let printIcon = null;
		if (this.state.printCallback && this.state.printCallback) {
			printIcon = (
				<PrintIconButton
					domainObject={this.state.domainObject}
					onClick={this.state.printCallback}
				/>
			);
		}

		let createSimilarIcon = null;
		if (this.state.createSimilarCallback && this.state.createSimilarCallback) {
			createSimilarIcon = (
				<CreateSimilarIconButton
					domainObject={this.state.domainObject}
					onClick={this.state.createSimilarCallback}
				/>
			);
		}
		let backIcon = null;
		if (this.state.backCallback && null !== this.state.backCallback) {
			backIcon = (
				<BackIconButton
					domainObject={this.state.domainObject}
					onClick={this.state.backCallback}
				/>
			);
		}

		let iconToolBar = null;
		let docTitle = "";
		if (editIcon || deleteIcon || printIcon) {
			if (this.state.docTitle) {
				docTitle = (
					<h5>
						<b>{this.state.docTitle} </b>
					</h5>
				);
			}

			iconToolBar = (
				<>
					<Row
						md={12}
						style={{
							font: "scan",
							margin: "0.8em 1em 0.2em 1em",
							// padding: "1em",
							// padding: '1em',
							// marginTop: '1em',
							// background: "#fff",
							// background: "#dfdfdf",
							// border: '1px solid #e0b477 ',
							// boxShadow: '3px 3px 3px #e0b477 inset',
						}}
					>
						<Col
							md={6}
							style={{
								float: "left",
								alignSelf: "center",
								fontWeight: "bold",
								marginLeft: "-0.9em",
							}}
						>
							{null !== backIcon && backIcon}
						</Col>
						<Col md={6} style={{ float: "right", margin: "0" }}>
							{deleteIcon && deleteIcon}
							{editIcon && editIcon}
							{printIcon && printIcon}
							{createSimilarIcon && createSimilarIcon}
							{/* {backIcon && backIcon} */}
						</Col>
					</Row>
				</>
			);
		}
		return (
			<div>
				<Row
					md={12}
					style={{
						margin: "auto",
						// marginTop: '-14px',
						minHeight: "3em",
						alignItems: "flex-end",
						// boxShadow: "0px 0px 15px #524f4f",
						// background: "#fff",
						padding: "0px",
						// marginLeft: "1em",
					}}
				>
					<Col md={4}>
						{/* {null !== backIcon && backIcon} */}
						<h5>
							<b>{this.state.name}</b>
						</h5>
					</Col>
					<Col md={8} style={{ margin: "auto" }}>
						{this.getBreadCrumb()}
					</Col>
				</Row>
				{/* </CardHeader> */}
				{iconToolBar && iconToolBar}
				{/* <Row
          style={{
            font: "scan",
            padding: "0.5em 1em",
            // padding: '1em',
            // marginTop: '1em',
            // background: "#fff",
            // background: "#dfdfdf",
            // border: '1px solid #e0b477 ',
            // boxShadow: '3px 3px 3px #e0b477 inset',
          }}
        >
          <Col
            md={12}
            style={{ float: "left", fontSize: "large", alignSelf: "center" }}
          >
            {docTitle}
          </Col>
        </Row> */}
				{/* // </Card> */}
			</div>
		);
	}
}

export default AdminMenuWithBreadScrum;
