import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import productImportFile from "../../../../assets/importTemplate/Product_Import.xlsx";
export class ImportProductServicesList extends Component {
  breadCrumb = [
    { pageid: "/import", uiname: "Import", cssclass: "fa fa-upload" },
    { pageid: "/prod/imp", uiname: "Product/Service Import List" },
  ];
  title = "Product/Service Import";
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      selectedFile: { name: "" },
      productListArray: [],
      isSelectAll: false,
      initialPage: 0,
      pageSize: 10,
    };
    this.getProductData();
  }

  getProductData() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/spimpexp/getall", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let productList = output.data.productList;
          let isPaging = false;
          if (productList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            productListArray: productList,
            isPaging: isPaging,
          });
        }
      }
    );
    // return Axios.post('/spimpexp/getlist', postObject)
    //   .then((response) => {
    //     let output_data = response.data;
    //     if (output_data.status === 'SUCCESS') {
    //       let productList = output_data.data.productList;

    //       this.setState({
    //         productListArray: productList,
    //       });
    //     } else {
    //     }
    //   })
    //   .catch((error) => {});
  }
  addProductServices = (e) => {
    this.props.history.push({
      pathname: "/importProductService",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = (e) => {
    // Create an object of formData
    // e.prventDefault();
    if (!this.state.selectedFile.name || this.state.selectedFile.name === "") {
      PtsAlert.error("Please select file to upload");
      return;
    }
    let header = store.getState().header.header;
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("sid", header.sessionid);
    formData.append("tid", header.tid);
    formData.append("uid", header.email);
    formData.append("appclient", header.appclient);
    // Details of the uploaded file

    // Request made to the backend api
    // Send formData object
    // Axios.post('/bulkimport/supplier', formData);
    FetchServerData.callPostFileUpload(
      "/bulkimport/saleProduct",
      formData
    ).then((output) => {
      if (output.status === "SUCCESS") {
        // this.fileUploadRef.current.value = '';

        this.getProductData();
      }
    });
  };

  selectAllProducts = (e) => {
    let selectedItems = this.state.productListArray;
    for (let i = 0; i < this.state.productListArray.length; i++) {
      selectedItems[i].isSelected = e.target.checked;
    }
    this.setState({
      productListArray: selectedItems,
      isSelectAll: e.target.checked,
    });
  };

  rowItemSelected(suppid, e) {
    let selectedItems = this.state.productListArray;
    for (let i = 0; i < selectedItems.length; i++) {
      if (suppid === selectedItems[i].id) {
        if (e.target.checked) {
          selectedItems[i].isSelected = true;
        } else {
          selectedItems[i].isSelected = false;
        }
      }
    }
    this.setState({ productListArray: selectedItems });
  }

  bukImportProductServices(e) {
    let importProductList = [];
    for (let i = 0; i < this.state.productListArray.length; i++) {
      if (this.state.productListArray[i].tableData?.checked === true) {
        importProductList.push(this.state.productListArray[i].id);
      }
    }
    if (!importProductList.length > 0) {
      return PtsAlert.error("Please Select Product");
    }
    let header = store.getState().header.header;
    const postObject = {
      header: header,
      productids: importProductList,
    };
    FetchServerData.callPostService("/spimpexp/bulkImp", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ selectedItems: [], isSelectAll: false });
          this.getProductData();
        }
      }
    );
  }

  bulkOverrideProductServices(e) {
    let importProductList = [];
    for (let i = 0; i < this.state.productListArray.length; i++) {
      if (this.state.productListArray[i].tableData?.checked === true) {
        importProductList.push(this.state.productListArray[i].id);
      }
    }
    if (!importProductList.length > 0) {
      return PtsAlert.error("Please Select Product");
    }
    let header = store.getState().header.header;
    const postObject = {
      header: header,
      productids: importProductList,
    };
    FetchServerData.callPostService("/spimpexp/bulkoverride", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ selectedItems: [], isSelectAll: false });
          this.getProductData();
        }
      }
    );
  }

  bulkDeleteProductServices(e) {
    let importProductList = [];
    for (let i = 0; i < this.state.productListArray.length; i++) {
      if (this.state.productListArray[i].tableData?.checked === true) {
        importProductList.push(this.state.productListArray[i].id);
      }
    }
    if (!importProductList.length > 0) {
      return PtsAlert.error("Please Select Product");
    }
    let header = store.getState().header.header;
    const postObject = {
      header: header,
      productids: importProductList,
    };
    FetchServerData.callPostService(
      "/spimpexp/deleteImpProducts",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.setState({ selectedItems: [], isSelectAll: false });
        this.getProductData();
      }
    });
  }

  toProductDetails = (e, data) => {
    let productId = data.id;
    //localStorage.setItem('customerId', JSON.stringify(customerId));
    this.props.history.push({
      pathname: "/importProductServiceView",
      state: {
        productId: productId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };
  cancelView(e) {
    this.props.history.push({
      pathname: "/import/",
      state: {},
    });
  }
  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        {/* <div
          style={{
            display: "grid",
            backgroundColor: "#d6dde6",
            width: "100%",
            marginTop: "1em",
            padding: "1em",
          }}
        > */}
        <Row md={12} style={{ padding: "1em" }}>
          <ValidatorForm
            style={{ width: "100%" }}
            ref="form"
            onSubmit={this.onFileUpload.bind(this)}
          >
            <Card md={12} style={{ width: "100%" }}>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={4}>
                  <label style={{}}>
                    Choose File To Import (
                    <span style={{ color: "red" }}>Excel file only</span>)
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ float: "right" }}>
                    <a
                      href={productImportFile}
                      id="dwnlink"
                      download="ProductServices_Import.xlsx"
                      // class="pull-right"
                    >
                      <strong>Download Sample Excel Format</strong>
                    </a>
                  </label>
                </Col>
              </Row>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={12}>
                  <div
                    style={{
                      border: "1px solid gray",
                      padding: "1em",
                      marginTop: "0.5em",
                    }}
                  >
                    {/* <TextField
                        required
                        type="file"
                        id="file"
                        label="Select File to Upload"
                        ref={this.fileUploadRef}
                        onChange={this.onFileChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: "small !important" },
                        }}
                      /> */}

                    <input
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      ref={this.fileUploadRef}
                      onChange={this.onFileChange.bind(this)}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={{ textAlign: "center", padding: "1em" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<SaveIcon />}
                    // onClick={(e) => this.onFileUpload()}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </Card>
          </ValidatorForm>
        </Row>
        <Row md={12} style={{ padding: "5px" }}>
          <div style={{ width: "100%", padding: "1em" }}>
            <MaterialTable
              icons={this.tableIcons}
              title="Imported Product List"
              onRowClick={(event, rowData) => {
                this.toProductDetails(event, rowData);
                event.stopPropagation();
              }}
              columns={[
                // {
                //   title: " ",
                //   render: (rowData) => {
                //     return (
                //       <Checkbox
                //         checked={
                //           !rowData.isSelected || false === rowData.isSelected
                //             ? false
                //             : rowData.isSelected
                //         }
                //         color="primary"
                //         inputProps={{
                //           "aria-label": "checkbox with default color",
                //         }}
                //         id={rowData.tableData.id}
                //         onClick={(e) => this.rowItemSelected(e, rowData.id)}
                //       ></Checkbox>
                //     );
                //   },
                //   width: "5%",
                // },
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Name",
                  field: "name",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "Code",
                  field: "code",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "Type",
                  field: "producttype.type",
                  align: "left",
                  width: "15%",
                },
              ]}
              onSelectionChange={(rows) => {
                this.rowItemSelected(rows);
              }}
              data={this.state.productListArray}
              options={{
                selection: true,
                showTextRowsSelected: false,
                sorting: true,
                actionsColumnIndex: -1,
                paging: this.state.isPaging,
                pageSizeOptions: Utils.materialTablePageSize(),
                headerStyle: {
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: (rowData) => {
                  if (rowData.isexistinmain && rowData.isexistinmain === true) {
                    return { backgroundColor: "#ecc0a0" };
                  }
                  // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
                  // return { backgroundColor: '#fff' };
                },
              }}
              style={{
                boder: "1px solid black",
              }}
              actions={
                [
                  // {
                  //   icon: () => (
                  //     <Checkbox
                  //       checked={this.state.isSelectAll}
                  //       color="primary"
                  //       inputProps={{
                  //         "aria-label": "checkbox with default color",
                  //       }}
                  //     ></Checkbox>
                  //   ),
                  //   tooltip: "SelectAll",
                  //   isFreeAction: true,
                  //   onClick: (event) => this.selectAllProducts(event),
                  // },
                  // {
                  //   icon: () => <Visibility />,
                  //   tooltip: "View Customer",
                  //   onClick: (e, rowData) => this.toProductDetails(e, rowData),
                  // },
                ]
              }
              // onRowClick={(e, rowData) => this.toProductDetails(e, rowData)}
            />
          </div>
        </Row>
        <Row md={12}>
          <Col md={12} style={{ textAlign: "center", alignItems: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bukImportProductServices(e)}
            >
              Bulk Import
            </Button>
            {/* <Button
              type="submit"
              variant="contained"

              style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bulkOverrideProductServices(e)}
            >
              Bulk Override
            </Button> */}
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<DeleteIcon />}
              onClick={(e) => this.bulkDeleteProductServices(e)}
            >
              Bulk Delete
            </Button>
          </Col>
        </Row>
        {/* </div> */}
      </>
    );
  }
}
export default ImportProductServicesList;
