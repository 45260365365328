import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import InputLabel from "@material-ui/core/InputLabel";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import { CardBody, Col, Label, Row } from "reactstrap";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import ReactToPrint from "react-to-print";
export default class AdvanceVoucherPrintPreview extends Component {
  /* Data Used from Store */
  header = store.getState().header.header;
  currencyList = store.getState().staticData.currencyList;
  countryList = store.getState().staticData.countryList;
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  disclaimer = store.getState().setting.general.disclaimer;
  org = store.getState().appData.settings.org;
  maxItems = 11;
  printComponentRef = React.createRef();
  duplicateTriplicateList = ["Select", "Original", "Duplicate", "Triplicate"];
  breadCrumb = [];
  constructor(props) {
    super(props);
    let invoice = props.invoice;
    let isHistory = props.source && props.source === "history" ? true : false;
    this.imageDwlRef = React.createRef();
    if (
      !invoice &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      invoice = props.history.location.state.invoice;
      this.customerList = props.history.location.state.customerList;
      this.productList = props.history.location.state.productList;
      this.custHsnCodeList = props.history.location.state.custHsnCodeList;
      this.businessUnitList = props.history.location.state.businessUnitList;
      this.breadCrumb = props.history.location.state.breadCrumb;
      isHistory = props.history.location.state.isHistory
        ? props.history.location.state.isHistory
        : false;
    }
    this.state = invoice;
    this.state.isHistory = isHistory;
    this.setState({ render: true, action: "view" });
  }

  changeDuplicateTypes(type) {
    this.setState({ duplicateType: type });
  }

  render() {
    return this.viewAdvanceVoucher();
  }

  getBillToAddress() {
    let billingAddress = Utils.getAddressAsText(this.state.billtoaddress);
    return (
      <div style={{ borderBottom: "1px solid gray", height: "50%" }}>
        <Row sm={12} style={{ margin: "0", paddingLeft: "5px" }}>
          <Label
            style={{
              color: "#000",

              margin: "0",
              padding: "0",
              textAlign: "left",
            }}
          >
            <strong>
              <u>Billing</u>
            </strong>
          </Label>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "5px" }}>
          <span style={{ color: "#000" }}>{billingAddress}</span>
        </Row>
      </div>
    );
  }
  getShipToAddress() {
    let shippingAddress = Utils.getAddressAsText(this.state.shiptoaddress);
    return (
      <div style={{ height: "50%" }}>
        <Row sm={12} style={{ margin: "0", paddingLeft: "5px" }}>
          <Label
            style={{
              color: "#000",

              margin: "0",
              padding: "0",
              textAlign: "left",
            }}
          >
            <strong>
              <u>Shipping</u>
            </strong>
          </Label>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "5px" }}>
          <span style={{ color: "#000" }}>{shippingAddress}</span>
        </Row>
      </div>
    );
  }

  getBankDetails() {
    let bank = this.state.bankaccount;
    return (
      <div>
        <Row sm={12} style={{ margin: "0", paddingLeft: "5px" }}>
          <Label
            style={{
              color: "#000",

              margin: "0",
              padding: "0",
              textAlign: "left",
            }}
          >
            <strong>
              <u>Bank Details</u>
            </strong>
          </Label>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "0" }}>
          <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                padding: "0",
                textAlign: "left",
              }}
            >
              <span>A/C Holder</span>
            </Label>
          </Col>
          <Col sm={6} style={{ margin: "0", padding: "0" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                paddingRight: "5px",
                textAlign: "left",
              }}
            >
              {bank.accountholder}
            </Label>
          </Col>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "0" }}>
          <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                padding: "0",
                textAlign: "left",
              }}
            >
              <span>A/C No.</span>
            </Label>
          </Col>
          <Col sm={6} style={{ margin: "0", padding: "0" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                paddingRight: "5px",
                textAlign: "left",
              }}
            >
              {bank.accountnum}
            </Label>
          </Col>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "0" }}>
          <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                padding: "0",
                textAlign: "left",
              }}
            >
              <span>Bank Name</span>
            </Label>
          </Col>
          <Col sm={6} style={{ margin: "0", padding: "0" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                paddingRight: "5px",
                textAlign: "left",
              }}
            >
              {bank.bankname}
            </Label>
          </Col>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "0" }}>
          <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
            <Label
              style={{
                color: "#000",
                margin: "0",
                padding: "0",
                textAlign: "left",
              }}
            >
              <span>Branch</span>
            </Label>
          </Col>
          <Col sm={6} style={{ margin: "0", padding: "0" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                paddingRight: "5px",
                textAlign: "left",
              }}
            >
              {bank.address}
            </Label>
          </Col>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "0" }}>
          <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
            <Label
              style={{
                color: "#000",
                margin: "0",
                padding: "0",
                textAlign: "left",
              }}
            >
              <span>IFSC</span>
            </Label>
          </Col>
          <Col sm={6} style={{ margin: "0", padding: "0" }}>
            <Label
              style={{
                color: "#000",
                margin: "0",
                paddingRight: "5px",
                textAlign: "left",
              }}
            >
              {bank.ifsc}
            </Label>
          </Col>
        </Row>
        <Row sm={12} style={{ margin: "0", padding: "0" }}>
          <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
            <Label
              style={{
                color: "#000",
                margin: "0",
                padding: "0",
                textAlign: "left",
              }}
            >
              <span>Swift Code</span>
            </Label>
          </Col>
          <Col sm={6} style={{ margin: "0", padding: "0" }}>
            <Label
              style={{
                color: "#000",

                margin: "0",
                paddingRight: "5px",
                textAlign: "left",
              }}
            >
              {bank.swiftcode}
            </Label>
          </Col>
        </Row>
      </div>
    );
  }

  getEmptyAdvanceVoucherItem(srNo) {
    return (
      <Row
        sm={12}
        style={{
          minHeight: "2em",
          padding: "0px",
          margin: "0",
          fontSize: "smaller",
        }}
        key={srNo}
      >
        <Col
          sm={1}
          style={{
            borderLeft: "0.5px solid gray",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
          }}
        ></Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
          }}
        ></Col>
        <Col
          sm={4}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
          }}
        ></Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        ></Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        ></Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        ></Col>
        {Utils.isIgstApplicable(this.state.customerVo) === false && (
          <>
            <Col
              sm={1}
              style={{
                borderRight: "0.5px solid gray",
                borderBottom: "0.5px solid gray",
                padding: "0.5em",
                textAlign: "right",
              }}
            ></Col>
            <Col
              sm={1}
              style={{
                borderRight: "0.5px solid gray",
                borderBottom: "0.5px solid gray",
                padding: "0.5em",
                textAlign: "right",
              }}
            ></Col>
          </>
        )}
        {Utils.isIgstApplicable(this.state.customerVo) === true && (
          <Col
            sm={2}
            style={{
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              textAlign: "right",
            }}
          ></Col>
        )}

        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        ></Col>
      </Row>
    );
  }

  getAdvanceVoucherItem(item, srNo) {
    return (
      <Row
        sm={12}
        style={{
          padding: "0px",
          margin: "0",
          fontSize: "smaller",
        }}
      >
        <Col
          sm={1}
          style={{
            borderLeft: "0.5px solid gray",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
          }}
        >
          {srNo}
        </Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
          }}
        >
          {item.custhsncode.code}
        </Col>
        <Col
          sm={4}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            wordBreak: "break-all",
          }}
        >
          {item.name}
        </Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          {Utils.getFloatValue(item.taxable)}
        </Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          {Utils.getFloatValue(item.cess)}
        </Col>
        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          {item.taxrate.rate}
        </Col>
        {Utils.isIgstApplicable(this.state.customerVo) === false && (
          <>
            <Col
              sm={1}
              style={{
                borderRight: "0.5px solid gray",
                borderBottom: "0.5px solid gray",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              {Utils.getFloatValue(item.cgst)}
            </Col>
            <Col
              sm={1}
              style={{
                borderRight: "0.5px solid gray",
                borderBottom: "0.5px solid gray",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              {Utils.getFloatValue(item.sgst)}
            </Col>
          </>
        )}
        {Utils.isIgstApplicable(this.state.customerVo) === true && (
          <Col
            sm={2}
            style={{
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {Utils.getFloatValue(item.igst)}
          </Col>
        )}

        <Col
          sm={1}
          style={{
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          {Utils.getFloatValue(item.total)}
        </Col>
      </Row>
    );
  }

  printContent() {
    let printContents = document.getElementById("printform").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
  }

  downloadImage() {
    let fileName = this.state.invnumber;
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    exportComponentAsPNG(this.imageDwlRef, {
      fileName: fileName,
      // pdfOptions: { width: "21", height: "29.7", unit: "cm", format: "a4" },
    });
  }

  goToAdavnceVoucherViewPage() {
    this.props.history.push({
      pathname: "/receipt",
      state: {
        invoiceId: this.state.id,
        action: "view",
        customerList: this.customerList,
        productList: this.productList,
        businessUnitList: this.businessUnitList,
        custHsnCodeList: this.custHsnCodeList,
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  emailAdvanceVoucher() {}
  updateDuplicateTriplicate(e, input) {
    if (input === "Select") {
      input = "";
    }
    this.setState({ duplicateTriplicate: input });
  }
  viewAdvanceVoucher() {
    let invoiceItemsList = [];
    this.state.invoiceItems.forEach((element, index) => {
      let srNo = index + 1;
      invoiceItemsList.push(this.getAdvanceVoucherItem(element, srNo));
    });
    let occupiedRows = 0;

    for (let i = 0; i < this.state.invoiceItems.length; i++) {
      occupiedRows += Utils.getRowLength(
        this.state.invoiceItems[i]?.name.length
      );
    }
    let remainingItems = this.maxItems - occupiedRows;
    for (let i = 0; i < remainingItems; i++) {
      let srNo = this.state.invoiceItems.length + i + 1;
      invoiceItemsList.push(this.getEmptyAdvanceVoucherItem(srNo));
    }
    this.printComponentRef = React.createRef();
    // let options = {
    //   orientation: "Portrait ",
    //   unit: "cm",
    //   // format: [21, 20.7],
    // };
    let logoData = Utils.getLogo();
    return (
      <div style={{ display: "grid", marginTop: "2em" }}>
        <Row sm={12} style={{ padding: "5px" }}>
          <Col sm={2} style={{ padding: "5px", textAlign: "left" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginLeft: "0.5rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.goToAdavnceVoucherViewPage(e)}
            >
              Back
            </Button>
          </Col>

          <Col sm={5} style={{ padding: "5px" }}>
            <Row sm={12}>
              <Col sm={6} style={{ marginTop: "auto", marginBottom: "auto" }}>
                <InputLabel style={{ float: "right", color: "#000" }}>
                  <strong>
                    Original/Duplicate<span className="asterisk"> *</span>
                  </strong>
                </InputLabel>
              </Col>
              <Col sm={6} style={{ padding: "5px" }}>
                <Autocomplete
                  id="duplicateTriplicate"
                  size="small"
                  ListboxProps={{ className: "myCustomList" }}
                  style={{ background: "#fff", borderRadius: "5px" }}
                  disableClearable
                  options={this.duplicateTriplicateList}
                  value={
                    Utils.isNotNullAndEmpty(this.state.duplicateTriplicate)
                      ? "Select"
                      : this.state.duplicateTriplicate
                  }
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    this.updateDuplicateTriplicate(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth={true}
                      style={{ borderRadius: "5px" }}
                      placeholder="Original/Duplicate"
                      className="regCountry"
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={5} style={{ textAlign: "right" }}>
            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<PrintIcon />}
              onClick={(e) => this.printContent(e)}
            >
              Print
            </Button> */}
            <ReactToPrint
              trigger={
                () => (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<PrintIcon />}
                    // onClick={(e) => this.printContent(e)}
                  >
                    Print
                  </Button>
                )
                // <Button>Print this out!</Button>
              }
              content={() => this.printComponentRef}
            />
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#9a7031",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<GetAppIcon />}
              onClick={(e) => this.downloadImage(e)}
            >
              Download
            </Button>

            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<EmailIcon />}
              onClick={(e) => this.emailAdvanceVoucher(e)}
            >
              Email
            </Button> */}
          </Col>
        </Row>
        <Card
          sm={12}
          id="printform"
          ref={(el) => (this.printComponentRef = el)}
          style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
        >
          <div ref={this.imageDwlRef}>
            <CardBody style={{ color: "#000" }}>
              <Row
                sm={12}
                style={{
                  margin: "0",
                  padding: "5px",
                  display: "flex",
                  borderLeft: "1px solid gray",
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                }}
              >
                <Col sm={2}>
                  {Utils.isNotNullAndEmpty(logoData) && (
                    <img
                      src={logoData}
                      alt=""
                      style={{ height: "3em", width: "3em", padding: "0.1em" }}
                    />
                  )}
                </Col>
                <Col sm={8}>
                  <Row>
                    <strong style={{ width: "100%", textAlign: "center" }}>
                      {Utils.getOrgName()}
                    </strong>
                  </Row>
                  <Row>
                    <span style={{ width: "100%", textAlign: "center" }}>
                      {Utils.getAddressAsText(this.org?.addressvo)}
                    </span>
                  </Row>
                </Col>
                <Col sm={2}>
                  <span style={{ width: "100%", textAlign: "center" }}>
                    {this.state.duplicateTriplicate}
                  </span>
                </Col>
              </Row>
              <Row
                sm={12}
                style={{
                  margin: "0",
                  padding: "5px",
                  display: "flex",
                  borderLeft: "1px solid gray",
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                }}
              >
                <Col sm={4}>
                  <Row sm={12}>
                    <Col sm={6} style={{ textAlign: "right" }}>
                      <strong>GSTN:</strong>
                    </Col>
                    <Col sm={6}>
                      <span>{this.org?.gstn}</span>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <Row sm={12}>
                    <Col sm={6} style={{ textAlign: "right" }}>
                      <strong>PAN:</strong>
                    </Col>
                    <Col sm={6}>
                      <span>{this.org?.panNo}</span>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <Row sm={12}>
                    <Col sm={6} style={{ textAlign: "right" }}>
                      <strong>Contact:</strong>
                    </Col>
                    <Col sm={6}>
                      <span>{this.org?.phone}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                sm={12}
                style={{
                  margin: "0",
                  padding: "5px",
                  display: "flex",
                  borderLeft: "1px solid gray",
                  borderTop: "1px solid gray",
                  borderBottom: "1px solid gray",
                  borderRight: "1px solid gray",
                }}
              >
                <Col
                  sm={12}
                  style={{ fontSize: "x-large", textAlign: "center" }}
                >
                  <strong>Advance Receipt</strong>
                </Col>
              </Row>

              <Row
                sm={12}
                style={{ margin: "0", padding: "0", display: "flex" }}
              >
                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderLeft: "1px solid gray",
                    borderRight: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4}>
                        <Label
                          style={{
                            color: "#000",

                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Customer</strong>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo?.name}
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>GSTN</strong>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo?.gstn}
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>PAN</strong>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo?.pan}
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={4}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Email</strong>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo?.email
                            ? this.state.customerVo?.email
                            : ""}
                        </Label>
                      </Col>
                    </Row>
                    <Row sm={12} style={{ margin: "0", padding: "0" }}>
                      <Col sm={4}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Phone</strong>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.customerVo?.phone
                            ? this.state.customerVo?.phone
                            : ""}
                        </Label>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderRight: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    {this.getBillToAddress()}
                    {this.getShipToAddress()}
                  </div>
                </Col>
                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderRight: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div style={{ width: "100%", margin: "0", padding: "0" }}>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Voucher Number</strong>
                        </Label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.invnumber}
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Voucher Date</strong>
                        </Label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {Utils.ptsDateFormat(this.state.invoicedate)}
                        </Label>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row
                sm={12}
                style={{
                  padding: "0px",
                  margin: "0",
                  fontSize: "smaller",
                  // borderTop: "1px solid gray",
                }}
              >
                <Col
                  sm={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    borderLeft: "0.5px solid gray",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                    padding: "0.5em",
                  }}
                >
                  #
                </Col>
                <Col
                  sm={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                    padding: "0.5em",
                  }}
                >
                  SAC/HSN
                </Col>
                <Col
                  sm={4}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                    padding: "0.5em",
                  }}
                >
                  Product Description
                </Col>
                <Col
                  sm={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  Advance
                </Col>
                <Col
                  sm={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  CESS (%)
                </Col>
                <Col
                  sm={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  Tax Rate (%)
                </Col>
                {Utils.isIgstApplicable(this.state.customerVo) !== true && (
                  <>
                    <Col
                      sm={1}
                      style={{
                        background: "#ececec",
                        color: "#000",
                        fontWeight: "bold",
                        borderRight: "0.5px solid gray",
                        borderBottom: "0.5px solid gray",
                        padding: "0.5em",
                        textAlign: "right",
                      }}
                    >
                      CGST
                    </Col>
                    <Col
                      sm={1}
                      style={{
                        background: "#ececec",
                        color: "#000",
                        fontWeight: "bold",
                        borderRight: "0.5px solid gray",
                        borderBottom: "0.5px solid gray",
                        padding: "0.5em",
                        textAlign: "right",
                      }}
                    >
                      SGST
                    </Col>
                  </>
                )}

                {Utils.isIgstApplicable(this.state.customerVo) !== false && (
                  <Col
                    sm={2}
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      borderRight: "0.5px solid gray",
                      borderBottom: "0.5px solid gray",
                      padding: "0.5em",
                      textAlign: "right",
                    }}
                  >
                    IGST
                  </Col>
                )}

                <Col
                  sm={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  Total
                </Col>
              </Row>
              {invoiceItemsList}

              <Row
                sm={12}
                style={{ margin: "0", padding: "0", display: "flex" }}
              >
                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderLeft: "0.5px solid gray",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      margin: "0",
                      paddingLeft: "5px",
                    }}
                  >
                    <Label
                      style={{
                        color: "#000",

                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>
                        <u>Terms & Conditions</u>
                      </strong>
                    </Label>
                    <Row>
                      <Col sm={12}>
                        {this.state.customerVo
                          ? this.state.customerVo.invoicetandc
                          : ""}
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                  }}
                >
                  <div style={{ width: "100%" }}>{this.getBankDetails()}</div>
                </Col>
                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                  }}
                >
                  <div style={{ width: "100%", margin: "0", padding: "0" }}>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <span>Value Of Supply</span>
                        </Label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            paddingRight: "5px",
                            textAlign: "left",
                            float: "right",
                          }}
                        >
                          {this.state.currency.symbol !== null
                            ? this.state.currency.symbol
                            : ""}
                          {Utils.getFloatValue(this.state.totaltaxable)}
                        </Label>
                      </Col>
                    </Row>

                    {Utils.isIgstApplicable(this.state.customerVo) ===
                      false && (
                      <>
                        <Row
                          sm={12}
                          style={{
                            margin: "0",
                            padding: "0",
                            borderBottom: "1px solid gray",
                          }}
                        >
                          <Col
                            sm={6}
                            style={{ margin: "0", paddingLeft: "5px" }}
                          >
                            <Label
                              style={{
                                color: "#000",
                                margin: "0",
                                padding: "0",
                                textAlign: "left",
                              }}
                            >
                              <span>CGST</span>
                            </Label>
                          </Col>
                          <Col sm={6} style={{ margin: "0", padding: "0" }}>
                            <Label
                              style={{
                                color: "#000",
                                margin: "0",
                                paddingRight: "5px",
                                textAlign: "left",
                                float: "right",
                              }}
                            >
                              {this.state.currency.symbol !== null
                                ? this.state.currency.symbol
                                : ""}
                              {Utils.getFloatValue(this.state.cgst)}
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          sm={12}
                          style={{
                            margin: "0",
                            padding: "0",
                            borderBottom: "1px solid gray",
                          }}
                        >
                          <Col
                            sm={6}
                            style={{ margin: "0", paddingLeft: "5px" }}
                          >
                            <Label
                              style={{
                                color: "#000",
                                margin: "0",
                                padding: "0",
                                textAlign: "left",
                              }}

                            >
                              <span>SGST</span>
                            </Label>
                          </Col>
                          <Col sm={6} style={{ margin: "0", padding: "0" }}>
                            <Label
                              style={{
                                color: "#000",
                                margin: "0",
                                paddingRight: "5px",
                                textAlign: "left",
                                float: "right",
                              }}
                            >
                              {this.state.currency.symbol !== null
                                ? this.state.currency.symbol
                                : ""}
                              {Utils.getFloatValue(this.state.sgst)}
                            </Label>
                          </Col>
                        </Row>
                      </>
                    )}

                    {Utils.isIgstApplicable(this.state.customerVo) === true && (
                      <Row
                        sm={12}
                        style={{
                          margin: "0",
                          padding: "0",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                          <Label
                            style={{
                              color: "#000",
                              margin: "0",
                              padding: "0",
                              textAlign: "left",
                            }}
                          >
                            <span>IGST</span>
                          </Label>
                        </Col>
                        <Col sm={6} style={{ margin: "0", padding: "0" }}>
                          <Label
                            style={{
                              color: "#000",
                              margin: "0",
                              paddingRight: "5px",
                              textAlign: "left",
                              float: "right",
                            }}
                          >
                            {this.state.currency.symbol !== null
                              ? this.state.currency.symbol
                              : ""}
                            {Utils.getFloatValue(this.state.igst)}
                          </Label>
                        </Col>
                      </Row>
                    )}

                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <span>CESS</span>
                        </Label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            paddingRight: "5px",
                            textAlign: "left",
                            float: "right",
                          }}
                        >
                          {this.state.currency.symbol !== null
                            ? this.state.currency.symbol
                            : ""}
                          {Utils.getFloatValue(this.state.cess)}
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <span>Misc.(If Any)</span>
                        </Label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            paddingRight: "5px",
                            textAlign: "left",
                            float: "right",
                          }}
                        >
                          {this.state.currency.symbol !== null
                            ? this.state.currency.symbol
                            : ""}
                          {Utils.getFloatValue(this.state.miscellaneous)}
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Col sm={6} style={{ margin: "0", paddingLeft: "5px" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <span>Total</span>
                        </Label>
                      </Col>
                      <Col sm={6} style={{ margin: "0", padding: "0" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            paddingRight: "5px",
                            textAlign: "left",
                            float: "right",
                          }}
                        >
                          {this.state.currency.symbol !== null
                            ? this.state.currency.symbol
                            : ""}
                          {Utils.getFloatValue(this.state.totalamount)}
                        </Label>
                      </Col>
                    </Row>
                    <Row sm={12} style={{ margin: "0", padding: "0" }}>
                      <Col sm={12} style={{ margin: "0", paddingLeft: "5px" }}>
                        <Label
                          style={{
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong> In Words : </strong>
                          {this.state.totalinwords}
                        </Label>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row
                sm={12}
                style={{ margin: "0", padding: "0", display: "flex" }}
              >
                <Col
                  sm={8}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderLeft: "0.5px solid gray",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                  }}
                >
                  <div
                    style={{ width: "100%", margin: "0", paddingLeft: "5px" }}
                  >
                    <Label
                      style={{
                        color: "#000",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>
                        <u>Disclaimer</u>
                      </strong>
                    </Label>
                    <Row>
                      <Col sm={12}>
                        {this.disclaimer
                          ? this.disclaimer.invoicedisclaimer
                          : ""}
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col
                  sm={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    borderRight: "0.5px solid gray",
                    borderBottom: "0.5px solid gray",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "0",
                      textAlign: "center",
                    }}
                  >
                    <Row
                      sm={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        display: "flex",
                        textAlign: "center",
                      }}
                    >
                      <Col sm={12} style={{ margin: "0", padding: "0" }}>
                        <strong style={{ width: "100%", textAlign: "center" }}>
                          For {Utils.getOrgName()}
                        </strong>
                      </Col>
                    </Row>
                    <Row style={{ minHeight: "6em" }}></Row>
                    <Row
                      sm={12}
                      style={{ margin: "0", padding: "0", display: "flex" }}
                    >
                      <Col sm={12} style={{ margin: "0", padding: "0" }}>
                        <span>Authorized Signatory</span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </div>
        </Card>
      </div>
    );
  }
}
