import TextField from "@material-ui/core/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import React, { Component } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import BackButton from "../buttons/BackButton";
import CancelButton from "../buttons/CancelButton";
import CreateButton from "../buttons/CreateButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import EditIconButton from "../buttons/EditIconButton";
import SaveButton from "../buttons/SaveButton";
import ExpenseCategoryList from "./ExpenseCategoryList";
export class ExpenseCategory extends Component {
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;

  constructor(props) {
    super(props);
    let expenseCategory = props.data;
    if (expenseCategory) {
      this.state = {
        id: null === expenseCategory.id ? "" : expenseCategory.id,
        type: null === expenseCategory.type ? "" : expenseCategory.type,
        description:
          null === expenseCategory.description
            ? ""
            : expenseCategory.description,
        isinbuilt:
          null === expenseCategory.isinbuilt
            ? false
            : expenseCategory.isinbuilt,
        onChange: this.props.onChange,
        action: this.props.action,
      };
    } else {
      this.state = {
        id: "",
        type: "",
        description: "",
        isinbuilt: false,
        expenseCategorys: props.expenseCategorys,
        onChange: this.props.onChange,
        action: this.props.action,
      };
    }
  }

  handleTypeChange(e) {
    this.setState({ type: e.target.value });
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  saveExpenseCategory() {
  }
  createExpenseCategoryInBackend(e) {
    e.preventDefault();
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      category: {
        id: this.state.id,
        type: this.state.type,
        description: this.state.description,
        isinbuilt: this.state.isinbuilt,
      },
      header: header,
    };
    FetchServerData.callPostService("/expensecatms/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let expenseCategoryData = output.data;
          let newData = {
            id: expenseCategoryData.id,
            type: expenseCategoryData.type,
            description: expenseCategoryData.description,
            isinbuilt: expenseCategoryData.isinbuilt,
            action: "show_list",
            render: true,
            isAdd: true,
          };
          this.props.onChange(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }

  updateExpenseCategoryInBackend(e) {
    e.preventDefault();
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      category: {
        id: this.state.id,
        type: this.state.type,
        description: this.state.description,
        isinbuilt: this.state.isinbuilt,
      },
      header: header,
    };
    FetchServerData.callPostService("/expensecatms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let expenseCategoryData = output.data;
          let newData = {
            id: expenseCategoryData.id,
            type: expenseCategoryData.type,
            description: expenseCategoryData.description,
            isinbuilt: expenseCategoryData.isinbuilt,
            action: "show_list",
            render: true,
            isUpdate: true,
          };
          this.props.onChange(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }

  deleteExpenseCategory() {
    /* Go Back to List Page */
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      category: {
        id: this.state.id,
      },
      header: header,
    };
    FetchServerData.callPostService("/expensecatms/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let expenseCategoryData = output.data;
          let newData = {
            id: this.state.id,
            type: this.state.type,
            description: this.state.description,
            isinbuilt: this.state.isinbuilt,
            action: "show_list",
            render: true,
            isDelete: true,
          };
          this.props.onChange(newData);
          this.setState({ action: "show_list" });
        } else {
        }
      }
    );
  }

  modifyExpenseCategory() {
    /* Go Back to List Page */
    this.setState({ action: "edit" });
  }
  cancel() {
    /* Go Back to List Page */
    this.setState({ action: "show_list" });
  }

  showExpenseCategory() {
    /* Go Back to List Page */
    this.setState({ action: "view" });
  }

  render() {
    if (this.state.action === "show_list") {
      return (
        <ExpenseCategoryList
          data={this.state.expenseCategorys}
          onChange={this.state.onChange}
          action="view"
        />
      );
    } else if (this.state.action === "create") {
      return this.createExpenseCategory();
    } else if (this.state.action === "edit") {
      return this.editExpenseCategory();
    } else {
      return this.viewExpenseCategory();
    }
  }

  viewExpenseCategory() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col>
              <span>
                <strong>Expense Category Details</strong>
              </span>
            </Col>
            <Col>
              <div style={{ float: "right" }}>
                <DeleteIconButton
                  domainObject="Expense Category"
                  onClick={this.deleteExpenseCategory.bind(this)}
                  caption="Delete Business Unit"
                />
                <EditIconButton
                  onClick={this.modifyExpenseCategory.bind(this)}
                  caption="Edit Business Unit"
                ></EditIconButton>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Row style={{ padding: "5px" }}>
            <Col md={3}>
              <label style={{ color: "#000", fontWeight: "600" }}>Type</label>
            </Col>
            <Col md={9}>
              <label style={{ color: "#000" }} id="type">
                {this.state.type}
              </label>
            </Col>
          </Row>
          <Row style={{ padding: "5px" }}>
            <Col md={3}>
              <label style={{ color: "#000", fontWeight: "600" }}>
                Description
              </label>
            </Col>
            <Col md={9}>
              <label style={{ color: "#000" }} id="description">
                {this.state.description}
              </label>
            </Col>
          </Row>
          <Row style={{ padding: "5px", color: "#000", }}>
            <Col md={3}>
              <label style={{ color: "#000", fontWeight: "600" }}>
                Is InBuilt?
              </label>
            </Col>
            <Col md={9}>
              {/* {this.state.isinbuilt === true && (
                <ToggleButton className="ToggleButtonActive">Yes</ToggleButton>
              )}
              {this.state.isinbuilt === false && (
                <ToggleButton className="ToggleButtonInActive">No</ToggleButton>
              )} */}
              {this.state.isinbuilt ? "Yes" : "No"}
            </Col>
          </Row>
        </CardBody>
        <CardFooter style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <BackButton onClick={this.cancel.bind(this)} />
          </div>
        </CardFooter>
      </Card>
    );
  }
  createExpenseCategory() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <form onSubmit={this.createExpenseCategoryInBackend.bind(this)}>
          <CardHeader
            style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col>
                <span>
                  <strong>Create Expense Category</strong>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <CardBody style={{ overflowX: "auto" }}>
            <Row style={{ marginTop: "1em" }}>
              <Col md={6}>
                <Row style={{ padding: "5px" }}>
                  <Col md={12}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Type<span className="asterisk"> *</span>
                    </label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id="type"
                      placeholder="Type"
                      value={this.state.type}
                      onChange={this.handleTypeChange.bind(this)}
                      //onBlur={() => {this.props.onChange(this.state)}}
                      name="type"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>
                </Row>
                <Row style={{ padding: "5px", color: "#000", }} className="mt-4">
                  <Col md={3}>
                    <label for="isdefault" style={{ color: "#000", fontWeight: "600" }}>Is InBuilt?</label>
                  </Col>
                  <Col md={3}>
                    {/* {this.state.isinbuilt === true && (
                      <ToggleButton className="ToggleButtonActive">
                        Yes
                      </ToggleButton>
                    )}
                    {this.state.isinbuilt === false && (
                      <ToggleButton className="ToggleButtonInActive">
                        No
                      </ToggleButton>
                    )} */}
                    {this.state.isinbuilt ? "Yes" : "No"}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Description
                </label>
                <TextareaAutosize
                  variant="outlined"
                  fullWidth
                  id="description"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "0.5px solid #D5D8DC",
                    color: "#000"
                  }}
                  minRows={2}
                  value={this.state.description}
                  onChange={this.handleDescriptionChange.bind(this)}
                  autoComplete="description"
                  placeholder="Description"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <CreateButton type="submit" />
              <CancelButton onClick={(e) => this.cancel()} />
            </div>
          </CardFooter>
        </form>
      </Card>
    );
  }

  editExpenseCategory() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <form onSubmit={this.updateExpenseCategoryInBackend.bind(this)}>
          <CardHeader
            style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col>
                <span>
                  <strong>Edit Expense Category</strong>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row style={{ padding: "5px" }}>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Type<span className="asterisk"> *</span>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  id="type"
                  placeholder="Type"
                  value={this.state.type}
                  onChange={this.handleTypeChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="type"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={6}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Description
                </label>
                {/* <TextField
                  fullWidth
                  variant="outlined"
                  id="description"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={this.handleDescriptionChange.bind(this)}
                  //onBlur={() => {this.props.onChange(this.state)}}
                  name="description"
                  InputLabelProps={{ shrink: true }}
                /> */}
                <TextareaAutosize
                  variant="outlined"
                  fullWidth
                  id="description"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "0.5px solid #D5D8DC",
                    color: "#000"
                  }}
                  minRows={2}
                  value={this.state.description}
                  onChange={this.handleDescriptionChange.bind(this)}
                  autoComplete="description"
                  placeholder="Description"
                />
              </Col>
            </Row>

            <Row style={{ padding: "5px", color: "#000", }}>
              <Col md={3}>
                <label style={{ color: "#000", fontWeight: "600" }}>
                  Is InBuilt?
                </label>
              </Col>
              <Col md={3}>
                {/* {this.state.isinbuilt === true && (
                  <ToggleButton
                    style={{
                      backgroundColor: "green",
                      color: "#fff",
                      maxWidth: "5rem",
                      maxHeight: "3rem",
                      margin: "0.5rem",
                    }}
                  >
                    Yes
                  </ToggleButton>
                )}
                {this.state.isinbuilt === false && (
                  <ToggleButton
                    style={{
                      backgroundColor: "white",
                      color: "#000",
                      maxWidth: "5rem",
                      maxHeight: "3rem",
                      margin: "0.5rem",
                    }}
                  >
                    No
                  </ToggleButton>
                )} */}
                {this.state.isinbuilt ? "Yes" : "No"}
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SaveButton type="submit" />
              <CancelButton onClick={(e) => this.showExpenseCategory()} />
            </div>
          </CardFooter>
        </form>
      </Card>
    );
  }
}

export default ExpenseCategory;
