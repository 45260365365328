import Icon from "@material-ui/core/Icon";
import React, { Component } from "react";
import { Card, Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
import "./DashboardMenu.scss";
export class DashboardMenu extends Component {
  constructor(props) {
    super(props);

    let menudata = props.data;
    this.state = {
      id: menudata.id,
      menuid: menudata.menuid,
      icon: menudata.icon,
      pageid: menudata.pageid,
      cssclass: menudata.cssclass,
      intro: menudata.intro,
      step: menudata.step,
      displaysequence: menudata.displaysequence,
      uiname: menudata.uiname,
      count: menudata.count === null ? 0 : menudata.count,
      bgColor: menudata.bgColor,
      bgColorDark: menudata.bgColorDark,
      bgColorClass: menudata.bgColorClass,
      bgColorDarkClass: menudata.bgColorDarkClass,
      history: props.history,
      pageUrl: menudata.pageUrl,
      action: "",
    };
  }

  openMenuPage() {
    if (this.state.pageid && this.state.pageid.length > 0) {
      this.props.history.push({ pathname: this.state.pageid });
    }
  }

  render() {
    return (
      // <TableCell style={{ width: '20%', fontSize: 'small' }}>
      <NavLink to={this.state.pageUrl}>
        <Card
          onClick={this.openMenuPage.bind(this)}
          style={{
            fontSize: "small",
            cursor: "pointer",
            // margin: '0.5em',
            // padding: '0.5em',
            // width: "-webkit-fill-available",
            // maxWidth: '15em',
            height: "auto",
            // maxHeight: '5em',
            // overflow: 'flex',
            backgroundColor: this.state.bgColor,
            minWidth: "228px",
            // position: 'relative',
            display: "flex",
            // border: "1px solid beige",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
          className={this.state.bgColorClass}
          id="cardCol"
        >
          <Row md={12} style={{ display: "flex", padding: 0, margin: 0 }}>
            <Col
              md={4}
              style={{ padding: "11px" }}
              className={this.state.bgColorDarkClass}
            >
              <Icon
                className={this.state.cssclass}
                style={{ color: "#fff", fontSize: "xx-large", margin: "auto" }}
              />
            </Col>
            <Col md={8} style={{ padding: "5px" }}>
              <span
                style={{
                  width: "100%",
                  color: "#fff",
                  fontSize: "17px",
                  textAlign: "center",
                }}
              >
                {this.state.uiname} <br></br>
              </span>

              <span
                style={{
                  width: "100%",
                  color: "#fff",
                  fontSize: "17px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {this.state.count}
              </span>
            </Col>
          </Row>
        </Card>
      </NavLink>
      // </TableCell>
    );
  }
}
export default DashboardMenu;
