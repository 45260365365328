export const GET_APP_DATA = 'GET_APP_DATA';
export const SET_APP_DATA = 'SET_APP_DATA';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_USER_BU = 'SET_USER_BU';
export const SET_EXPENSE_CATEGORIES = 'SET_EXPENSE_CATEGORIES';
export const SET_LOGO = 'SET_LOGO';
export const SET_SALES_RECEIPT_TAB = 'SET_SALES_RECEIPT_TAB';
export const SET_SALES_INVOICE_TAB = 'SET_SALES_INVOICE_TAB';
export const SET_SALES_CN_TAB = 'SET_SALES_CN_TAB';
export const SET_SALES_DN_TAB = 'SET_SALES_DN_TAB';
export const SET_SALES_REFUND_TAB = 'SET_SALES_REFUND_TAB';
export const SET_PURCHASE_RECEIPT_TAB = 'SET_PURCHASE_RECEIPT_TAB';
export const SET_PURCHASE_INVOICE_TAB = 'SET_PURCHASE_INVOICE_TAB';
export const SET_PURCHASE_CN_TAB = 'SET_PURCHASE_CN_TAB';
export const SET_PURCHASE_DN_TAB = 'SET_PURCHASE_DN_TAB';
export const SET_PURCHASE_REFUND_TAB = 'SET_PURCHASE_REFUND_TAB';
export const SET_PURCHASE_RCM_TAB = 'SET_PURCHASE_RCM_TAB';
export const SET_PURCHASE_RCMPAYMENT_TAB = 'SET_PURCHASE_RCMPAYMENT_TAB';
export const SET_FILING_TAB = 'SET_FILING_TAB';
export const SET_REPORT_TAB = 'SET_REPORT_TAB';
export const SET_HISTORY_EXP_HEADER_TAB = 'SET_HISTORY_EXP_HEADER_TAB';
export const SET_HISTORY_EXP_TAB = 'SET_HISTORY_EXP_TAB';
export const SET_HISTORY_EXPENSE = 'SET_HISTORY_EXPENSE';
export const SET_HISTORY_SALES_HEADER_TAB = 'SET_HISTORY_SALES_HEADER_TAB';
export const SET_HISTORY_SALES_TAB = 'SET_HISTORY_SALES_TAB';
export const SET_HISTORY_SALES = 'SET_HISTORY_SALES';
export const SET_HISTORY_PURCHASE_HEADER_TAB = 'SET_HISTORY_PURCHASE_HEADER_TAB';
export const SET_HISTORY_PURCHASE_TAB = 'SET_HISTORY_PURCHASE_TAB';
export const SET_HISTORY_PURCHASE = 'SET_HISTORY_PURCHASE';
export const SET_IMPORT_SALES_TAB = 'SET_IMPORT_SALES_TAB';
export const SET_IMPORT_PURCHASE_TAB = 'SET_IMPORT_PURCHASE_TAB';
export const SET_SETTINGS_TAB = 'SET_SETTINGS_TAB';
export const SET_GSTR1_TAB = 'SET_GSTR1_TAB';
export const SET_GSTR3B_TAB = 'SET_GSTR3B_TAB';
export const SET_SETTINGS = 'SET_SETTINGS';
