import React, { Component } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { serviceData } from "../../../../data/servicedata";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import "./ServiceAccountingCode.scss";
import SaveButton from "../../buttons/SaveButton";
import Pagination from "../../pagination/Pagination";

export class ServiceAccountingCode extends Component {
  breadCrumb = [
    { pageid: "/master", uiname: "Master", cssclass: "fa fa-book" },
    { pageid: "/services", uiname: "SAC Master List" },
  ];
  title = "SAC Master List";
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      customerList: "",
      searchText: "",
      pageSize: 10,
      pageNo: 0,
      showPerPage: 0,
      totalRecords: 0,
      customerSacCodesArray: new Map(),
      sacCodesArray: serviceData,
      checkedSacCode: false,
      selectedSacCodes: [],
      SACList: [],
      deletedSacCode: [],
      render: false,
    };
    this.getCustomerSAC();
  }
  componentDidMount() {
    this.onPaginationChange({
      buttonIndex: this.state.pageNo,
      showPerPage: this.state.pageSize,
    });
  }

  selectedSac(e, rowData) {
    const { checked } = e.target;
    let selectedSacCodes = this.state.selectedSacCodes;
    if (checked === true) {
      let sacObj = {
        description: rowData.description,
        code: rowData.code,
        id: rowData.id,
      };
      this.state.customerSacCodesArray.set(sacObj.code, sacObj);
      selectedSacCodes.push(sacObj);
      this.setState({
        selectedSacCodes: selectedSacCodes,
      });
    } else {
      if (this.state.customerSacCodesArray.has(rowData.code)) {
        this.state.customerSacCodesArray.delete(rowData.code);
        let deletedSacCode = [...this.state.deletedSacCode, rowData.code];
        this.setState({
          deletedSacCode: deletedSacCode,
        });
      }
    }
  }

  getCustomerSAC = () => {
    const header = store.getState().header;
    FetchServerData.callPostService("/custhsnms/getCustServices", header).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let custSACList = output.data;
          for (let i = 0; i < custSACList.length; i++) {
            this.state.customerSacCodesArray.set(
              custSACList[i].code,
              custSACList[i]
            );
          }
          this.setState({
            // customerSacCodesArray: custSACList,
            render: true,
          });
        } else {
        }
      }
    );
  };

  getServiceData = (pageNo, pageSize) => {
    const header = store.getState().header;
    const postObject = {
      header: header,
      page: {
        searchText: this.state.searchText,
        pageNo: pageNo,
        pageSize: pageSize,
      },
    };
    FetchServerData.callPostService("/hsns/getServiceCodes", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let page = output.data.page;
          let serviceCodeList = output.data.serviceCodeList;
          // PtsAlert.success(output.message);
          this.setState({
            SACList: serviceCodeList,
            searchText: page.searchText,
            pageNo: page.pageNo,
            totalPages: page.totalPages,
            totalRecords: page.total,
            showPerPage: page.pageSize,
            render: true,
          });
        } else {
        }
      }
    );
  };

  saveSacCodes = () => {
    let sacCodesArray = [];
    for (let i = 0; i < this.state.selectedSacCodes.length; i++) {
      sacCodesArray.push(this.state.selectedSacCodes[i].code);
    }
    let header = store.getState().header.header;
    let inputObject = {
      addcodes: sacCodesArray,
      deletecodes: this.state.deletedSacCode,
      type: "SERVICE",
      header: header,
    };

    FetchServerData.callPostService(
      "/custhsnms/saveCustCodes",
      inputObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        // let serviceCodeList = output.data;
      } else {
      }
    });
  };
  cancelView() {
    this.props.history.push({ pathname: "/master/" });
  }

  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex =
      typeof counter !== "undefined" ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0;
    }
    this.setState({
      pageNo: currentPageIndex,
    });
    this.getServiceData(currentPageIndex, pageSize);
  }

  onSearchClick() {
    this.getServiceData(this.state.pageNo, this.state.pageSize);
  }

  onSearchChange(e) {
    this.setState({ searchText: e.target.value });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    const selectedSacCodes = [];
    this.state.customerSacCodesArray.forEach((v, k) => {
      let htmlData = (
        <li key={k} className="list-group-item">
          <span>
            <b>{v.code}</b>
            {" - " + v.description}
          </span>
        </li>
      );
      selectedSacCodes.push(htmlData);
    });
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <div md={12} style={{ width: "100%", height: "100%", padding: "1em" }}>
          <div className="row">
            <Col md={8} style={{ flexDirection: "column", display: "flex" }}>
              <div className="card">
                <div className="row justify-content-between p-3">
                  <div className="d-flex col-12 col-lg-4 col-xl-4">
                    <div className="col-12 text-start totalResults">
                      <span>Total Results: {this.state.totalRecords}</span>
                    </div>
                  </div>
                  <div className="d-flex col-12 col-lg-8 col-xl-8">
                    <div className="col-12 text-end">
                      <input
                        type="search"
                        id="gsearch"
                        placeholder="Search"
                        name="gsearch"
                        value={this.state.searchText}
                        onChange={this.onSearchChange.bind(this)}
                      />
                      <button
                        className="gsearchButton"
                        onClick={this.onSearchClick.bind(this)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table text-start table-hover">
                    <thead className="tableThead">
                      <tr>
                        <th scope="col fw-bold ">#</th>
                        <th scope="col fw-bold">Group</th>
                        <th scope="col fw-bold">Codes</th>
                        <th scope="col fw-bold">SAC Description</th>
                        <th scope="col fw-bold">Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.SACList?.map((rowData, index) => (
                        <tr
                          className="hoverTable"
                          key={index}
                        // onClick={this.toAssetCycleDetails.bind(this, rowData)}
                        // style={{ backgroundColor: rowData.id === this.state.assetCycleId || this.state.assetCycleIds.includes(rowData.assetid) ? '#FEF5E7' : '#fff', cursor: "pointer" }}
                        >
                          <td style={{ width: "6px" }}>
                            {this.state.pageNo * this.state.showPerPage +
                              index +
                              1}
                          </td>
                          <td>{rowData.group?.description}</td>
                          <td>{rowData.code}</td>
                          <td>{rowData.description}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={this.state.customerSacCodesArray.has(
                                rowData.code
                              )}
                              onChange={(e) =>
                                this.selectedSac(e, rowData, index)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {this.state.totalRecords === 0 && (
                  <div className="row text-center p-2">
                    <h5>Data not Available</h5>
                  </div>
                )}
                {this.state.totalRecords > 10 && (
                  <div className="row justify-content-between">
                    <div className="d-flex col-12 col-lg-3 col-xl-3">
                      <div
                        className="pageSize col-12 text-start ms-4"
                        style={{ marginTop: "30px" }}
                      >
                        Page {this.state.pageNo + 1} of {this.state.totalPages}
                      </div>
                    </div>
                    <div className="d-flex col-12 col-lg-9 col-xl-9">
                      <div className="col-12 text-end">
                        <Pagination
                          onPaginationChange={this.onPaginationChange.bind(
                            this
                          )}
                          showPerPage={this.state.showPerPage}
                          total={this.state.totalRecords}
                          pageNo={this.state.pageNo}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col
              md={4}
              style={{ flexDirection: "column", flex: "1", display: "flex" }}
            >
              <Card style={{ flexGrow: "1", margin: "0", padding: "0" }}>
                <CardHeader
                  className="btnCardHeader"
                  style={{ padding: "1em" }}
                >
                  {/* <Button
                    color="success"
                    size="sm"
                    onClick={(e) => this.saveSacCodes(e)}
                  >
                    <i className="fa fa-floppy-o"></i>&nbsp;SAVE
                  </Button>{" "} */}
                  <SaveButton
                    type="submit"
                    onClick={(e) => this.saveSacCodes(e)}
                  />
                </CardHeader>
                <hr></hr>
                <CardBody className="sacCard">
                  <Col md={12}>
                    <ul className="ulStyle">{selectedSacCodes}</ul>
                    {/* <ul className="ulStyle">
                      {
                        this.state.customerSacCodesArray?.map(
                          (selectedSac, keyIndex) => {
                            return (
                              <li key={keyIndex} className="list-group-item">
                                <span>{selectedSac.code + "-" + selectedSac.description}</span>
                              </li>
                            );
                          }
                        )
                      }
                    </ul> */}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </>
    );
  }
}
