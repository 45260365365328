import { CLEAR_ADMIN_USER_DATA, GET_ADMIN_USER_DATA, SET_ADMIN_USER_DATA } from './AdminAppUserDataTypes';

const initialState = {
    adminUserDetails: {},
};

const AdminAppUserDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_USER_DATA:
            return {
                ...state,
            };
        case SET_ADMIN_USER_DATA:
            return {
                ...state,

                adminUserDetails: action.payload,
            };

        case CLEAR_ADMIN_USER_DATA:
            return {
                ...state,
                adminUserDetails: null,
            };
        default:
            return state;
    }
};

export default AdminAppUserDataReducer;
