import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
export class PaymentCancelButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onClick: props.onClick,
            caption: props.caption,
            hideToolTip: props.hideToolTip,
        };
    }

    onClick() {
        if (this.state.onClick) {
            this.state.onClick();
        }
    }
    render() {
        return (
            <span
                style={{
                    cursor: "pointer",
                    fontSize: "x-larger",
                    marginRight: "0.5em",
                    float: "right",
                }}
            >
                {this.state.hideToolTip ?
                    <Button
                        className="add"
                        size="small"
                        onClick={this.onClick.bind(this)}
                        style={{
                            background: "#867e7e",
                            color: "white",
                            fontSize: "x-larger",
                            textTransform: "capitalize",
                            cursor: "pointer",
                            float: "right",
                            width: "85px",
                            // margin: "0.2em 0.5em 0.3em 0em",
                            marginRight: "1rem",
                            marginBottom: "1em",
                        }}
                        startIcon={<CloseIcon />}
                    >
                        Cancel
                    </Button>
                    :
                    <Tooltip title="Cancel">
                        <Button
                            className="add"
                            size="small"
                            onClick={this.onClick.bind(this)}
                            style={{
                                background: "#867e7e",
                                color: "white",
                                fontSize: "x-larger",
                                textTransform: "capitalize",
                                cursor: "pointer",
                                float: "right",
                                width: "85px",
                                // margin: "0.2em 0.5em 0.3em 0em",
                                // marginRight: "1rem",
                                marginBottom: "1em",
                            }}
                            startIcon={<CloseIcon />}
                        >
                            Cancel
                        </Button>
                    </Tooltip>
                }
            </span>
        );
    }
}
export default PaymentCancelButton;
