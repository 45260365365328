import { GET_SETTINGS, SET_SETTINGS } from './SettingDataTypes';

export const getSettings = () => {
    return {
        type: GET_SETTINGS,
    };
};

export const setSettings = data => {
    return {
        type: SET_SETTINGS,
        payload: data,
    };
};

const SettingDataAction = {
    getSettings, setSettings
}
export default SettingDataAction;
