import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import { format } from "date-fns";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import store from "../../../redux/store";
import DeleteIconButton from "../buttons/DeleteIconButton";
import EditIconButton from "../buttons/EditIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import "./PaymentTransaction.scss";
import Utils from "../../../provider/Utils";
import PaymentSaveButton from "../buttons/PaymentSaveButton";
import PaymentCancelButton from "../buttons/PaymentCancelButton";

export class PaymentTransaction extends Component {
  header = store.getState().header.header;
  paymentMethodList = store.getState().appData.paymentMethodList;
  paymentStatusList = store.getState().appData.paymentStatusList;
  bankaccountList = store.getState().master.masterData.bankaccounts;
  currencyList = store.getState().staticData.currencyList;

  originalState = null;
  constructor(props) {
    super(props);

    let paymentTransaction = props.data;
    console.log(paymentTransaction.paymentdate);
    if (paymentTransaction) {
      this.state = {
        aid: props.aid,
        index: props.index + 1,
        id: null === paymentTransaction.id ? "" : paymentTransaction.id,
        note: paymentTransaction.note,
        amount: paymentTransaction.amount,
        paymentdate: paymentTransaction.paymentdate,
        ismoneyreceived: paymentTransaction.ismoneyreceived,
        paymentstatus: paymentTransaction.paymentStatus,
        paymentmethod: paymentTransaction.paymentmethod,
        bankAccount: paymentTransaction.bankAccount,
        currency: paymentTransaction.currency,
        onPaymentDelete: props.onPaymentDelete,
        onPaymentAdd: props.onPaymentAdd,
        onPaymentUpdate: props.onPaymentUpdate,
        operation: paymentTransaction.operation,
        action: this.props.action,
      };
    } else {
      this.state = {
        id: "",
        index: 0,
        note: "",
        amount: "",
        paymentdate: null + format(new Date(), "dd-MM-yyyy"),
        ismoneyreceived: "",
        paymentstatus: null,
        paymentmethod: null,
        currency: null,
        bankAccount: null,
        action: this.props.action,
      };
    }
    this.originalState = this.state;
  }

  deleteTransaction() {
    const postObject = {
      id: this.state.id,
      aid: this.state.aid,
      header: this.header,
    };

    if (this.state.onPaymentDelete) {
      let index = this.state.index - 1;
      this.state.onPaymentDelete(postObject, index);
    }
  }

  addTransaction() {
    if (!this.state.paymentdate) {
      PtsAlert.error("Please Select Payment Date");
      return;
    }
    // if (!this.state.note || "" === this.state.note) {
    //   PtsAlert.error("Please Enter Reference Details");
    //   return;
    // }

    if (!this.state.paymentmethod || null === this.state.paymentmethod.method) {
      PtsAlert.error("Please Select Payment Method");
      return;
    }

    if (
      !this.state.amount ||
      "" === this.state.amount ||
      this.state.amount <= 0
    ) {
      PtsAlert.error("Please Enter Payment Amount");
      return;
    }
    if (!this.state.paymentstatus) {
      PtsAlert.error("Please Select Payment Status");
      return;
    }

    if (this.state.onPaymentAdd) {
      let index = this.state.index - 1;
      this.state.onPaymentAdd(this.state, index);
      this.setState({ action: "view" });
    }
  }

  updateTransaction() {
    if (!this.state.paymentdate) {
      PtsAlert.error("Please Select Payment Date");
      return;
    }
    // if (!this.state.note || "" === this.state.note) {
    //   PtsAlert.error("Please Enter Reference Details");
    //   return;
    // }

    if (!this.state.paymentmethod || null === this.state.paymentmethod.method) {
      PtsAlert.error("Please Select Payment Method");
      return;
    }

    if (
      !this.state.amount ||
      "" === this.state.amount ||
      this.state.amount <= 0
    ) {
      PtsAlert.error("Please Enter Payment Amount");
      return;
    }
    if (!null === this.state.paymentstatus) {
      PtsAlert.error("Please Select Payment Status");
      return;
    }

    if (this.state.onPaymentAdd) {
      let index = this.state.index - 1;
      this.state.onPaymentUpdate(this.state, index);
      this.setState({ action: "view" });
    }
  }
  cancelAddDocument(e) {
    if (this.state.onCancelAdd) {
      this.state.onCancelAdd(this.state);
    }
  }
  handleTransactionDateChange(date) {
    this.setState({ paymentdate: date });
  }
  handleAmountChange(e) {
    let amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ amount: amount }));
    }
  }
  handleNoteChange(e) {
    this.setState({ note: e.target.value });
  }
  updatePaymentMethod(e, method) {
    this.setState({ paymentmethod: method });
  }

  updateBankAccount(e, method) {
    this.setState({ bankAccount: method });
  }

  updateCurrency(e, currency) {
    this.setState({ currency: currency });
  }

  updatePaymentStatus(e, status) {
    this.setState({ paymentstatus: status });
  }
  editPayment() {
    this.setState({ action: "edit" });
  }
  cancelEdit() {
    // let newState = this.originalState;
    // newState.action = "view";
    this.setState({ action: "view" });
  }
  render() {
    if (this.state.action === "create") {
      return this.createPaymentTransaction();
    }
    if (this.state.action === "edit") {
      return this.editPaymentTransaction();
    } else {
      return this.viewPaymentTransaction();
    }
  }

  viewPaymentTransaction() {
    let currency = undefined === this.state.currency?.symbol ||
      null === this.state.currency?.symbol
      ? ""
      : this.state.currency?.symbol;

    return (
      <TableRow className="paymentMethodCell" style={{ background: "#eff4f7" }}>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <span style={{ fontSize: "small" }}>{this.state.index}</span>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <span style={{ fontSize: "small" }}> {Utils.ptsDateFormat(this.state.paymentdate)}</span>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <span style={{ fontSize: "small" }}>{this.state.note}</span>
        </TableCell>

        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <span style={{ fontSize: "small" }}>
            {this.state.paymentmethod
              ? this.state.paymentmethod.description
              : ""}
          </span>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <span style={{ fontSize: "small" }}>
            {this.state.bankAccount
              ? this.state.bankAccount.bankname
              : ""}
          </span>
        </TableCell>
        <TableCell
          style={{ textAlign: "right", border: "0.5px solid #ece6e6" }}
        >
          <span style={{ fontSize: "small" }}>
            {this.state.amount ? currency + " " + parseFloat(this.state.amount).toFixed(2) : 0.0}
          </span>
        </TableCell>
        <TableCell
          style={{ textAlign: "left", border: "0.5px solid #ece6e6" }}
        >
          <span style={{ fontSize: "small" }}>
            {this.state.currency?.name}
          </span>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <span style={{ fontSize: "small" }}>
            {this.state.paymentstatus ? this.state.paymentstatus.status : ""}
          </span>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <Row>
            <Col md={12} style={{ display: "inline-flex" }}>
              <EditIconButton onClick={this.editPayment.bind(this)} />
              <DeleteIconButton
                onClick={this.deleteTransaction.bind(this)}
                domainObject="Payment Transaction"
              />
            </Col>
          </Row>
        </TableCell>
      </TableRow>
    );
  }
  createPaymentTransaction() {
    return (
      <TableRow className="paymentMethodCell">
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <span style={{ fontSize: "small" }}>{this.state.index}</span>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          {/* <TextField
            required
            fullWidth={true}
            type="date"
            id={"paymentdate" + this.state.index}
            onChange={this.handleTransactionDateChange.bind(this)}
            value={this.state.paymentdate}
          /> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={materialTheme}>
              <DatePicker
                required
                margin="normal"
                placeholder="DD-MM-YYYY"
                fullWidth
                id="invoicedate"
                format="dd-MM-yyyy"
                value={this.state.paymentdate ? this.state.paymentdate : null}
                onChange={this.handleTransactionDateChange.bind(this)}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <TextField
            fullWidth={true}
            inputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{ style: { fontSize: "small" } }}
            id={"note" + this.state.index}
            type="text"
            validators={["required"]}
            onChange={this.handleNoteChange.bind(this)}
            value={this.state.note}
            name="note"
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <Autocomplete
            disableClearable
            ListboxProps={{
              className: "myCustomList"
            }}
            id={"method" + this.state.index}
            size="small"
            options={this.paymentMethodList}
            value={this.state.paymentmethod}
            defaultValue={this.state.paymentmethod}
            getOptionLabel={(option) => option.description}
            onChange={(event, value) => {
              this.updatePaymentMethod(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important" }}
                required
                fullWidth={true}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <Autocomplete
            disableClearable
            ListboxProps={{
              className: "myCustomList"
            }}
            id={"method" + this.state.index}
            size="small"
            options={this.bankaccountList}
            value={this.state.bankAccount}
            defaultValue={this.state.bankAccount}
            getOptionLabel={(option) => option.bankname}
            onChange={(event, value) => {
              this.updateBankAccount(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important" }}
                required
                fullWidth={true}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <TextField
            required
            fullWidth={true}
            inputProps={{ min: 0, style: { textAlign: "right" } }}
            id={"amount" + this.state.index}
            type="text"
            validators={["required"]}
            onChange={this.handleAmountChange.bind(this)}
            onFocus={event => { event.target.select() }}
            value={this.state.amount}
            name="amount"
          />
        </TableCell>
        <TableCell md={2} style={{ border: "0.5px solid #ece6e6" }}>
          <Autocomplete
            id={"method" + this.state.index}
            size="small"
            ListboxProps={{
              className: "myCustomList"
            }}
            options={this.currencyList}
            value={this.state.currency}
            defaultValue={this.state.currency}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              this.updateCurrency(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important", width: "150px" }}
                required
                fullWidth={true}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <Autocomplete
            disableClearable
            id={"status" + this.state.index}
            size="small"
            ListboxProps={{
              className: "myCustomList"
            }}
            options={this.paymentStatusList}
            value={this.state.paymentstatus}
            defaultValue={this.state.paymentstatus}
            getOptionLabel={(option) => option.status}
            onChange={(event, value) => {
              this.updatePaymentStatus(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important" }}
                required
                fullWidth={true}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "15%" }}>
          <Row md={12}>
            <Col md={12} style={{ display: "inline-flex" }}>
              <PaymentSaveButton onClick={this.addTransaction.bind(this)} />
              <PaymentCancelButton
                onClick={this.deleteTransaction.bind(this)}
                domainObject="Payment Transaction"
              />
            </Col>
          </Row>
        </TableCell>
      </TableRow>
    );
  }

  editPaymentTransaction() {
    return (
      <TableRow className="paymentMethodCell">
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "5%" }}>
          <span style={{ fontSize: "small" }}>{this.state.index}</span>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "10%" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={materialTheme}>
              <DatePicker
                required
                margin="normal"
                placeholder="DD-MM-YYYY"
                fullWidth
                id="invoicedate"
                format="dd-MM-yyyy"
                value={this.state.paymentdate ? this.state.paymentdate : null}
                onChange={this.handleTransactionDateChange.bind(this)}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "10%" }}>
          <TextField
            required
            fullWidth={true}
            inputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{ style: { fontSize: "small" } }}
            id={"note" + this.state.index}
            type="text"
            validators={["required"]}
            onChange={this.handleNoteChange.bind(this)}
            value={this.state.note}
            name="note"
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "10%" }}>
          <Autocomplete
            disableClearable
            id={"method" + this.state.index}
            size="small"
            ListboxProps={{
              className: "myCustomList"
            }}
            options={this.paymentMethodList}
            value={this.state.paymentmethod}
            defaultValue={this.state.paymentmethod}
            getOptionLabel={(option) => option.description}
            onChange={(event, value) => {
              this.updatePaymentMethod(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important" }}
                required
                fullWidth={true}
                InputLabelProps={{ shrink: true, style: { fontSize: "small" } }}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <Autocomplete
            disableClearable
            id={"method" + this.state.index}
            size="small"
            ListboxProps={{
              className: "myCustomList"
            }}
            options={this.bankaccountList}
            value={this.state.bankAccount}
            defaultValue={this.state.bankAccount}
            getOptionLabel={(option) => option.bankname}
            onChange={(event, value) => {
              this.updateBankAccount(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important" }}
                required
                fullWidth={true}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "10%" }}>
          <TextField
            required
            fullWidth={true}
            inputProps={{ min: 0, style: { textAlign: "right" } }}
            id={"amount" + this.state.index}
            type="text"
            validators={["required"]}
            onChange={this.handleAmountChange.bind(this)}
            onFocus={event => { event.target.select() }}
            value={this.state.amount}
            name="amount"
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6" }}>
          <Autocomplete
            disableClearable
            id={"method" + this.state.index}
            size="small"
            ListboxProps={{
              className: "myCustomList"
            }}
            options={this.currencyList}
            value={this.state.currency}
            defaultValue={this.state.currency}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              this.updateCurrency(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important", width: "150px" }}
                required
                fullWidth={true}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "10%" }}>
          <Autocomplete
            disableClearable
            id={"status" + this.state.index}
            size="small"
            ListboxProps={{
              className: "myCustomList"
            }}
            options={this.paymentStatusList}
            value={this.state.paymentstatus}
            defaultValue={this.state.paymentstatus}
            getOptionLabel={(option) => option.status}
            onChange={(event, value) => {
              this.updatePaymentStatus(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ fontSize: "small !important" }}
                required
                fullWidth={true}
              />
            )}
          />
        </TableCell>
        <TableCell style={{ border: "0.5px solid #ece6e6", width: "15%" }}>
          <Row>
            <Col md={12} style={{ display: "inline-flex" }}>
              <PaymentSaveButton onClick={this.updateTransaction.bind(this)} />
              <PaymentCancelButton
                onClick={this.cancelEdit.bind(this)}
                domainObject="Payment Transaction"
              />
            </Col>
          </Row>
        </TableCell >
      </TableRow >
    );
  }
}

export default PaymentTransaction;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#1B7189',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});