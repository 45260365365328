export const serviceData = [
  {
    id: 1,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Construction services of buildings',
    groupcode: '99541',
    service: 'Construction services of single dwelling or multi dewlling or multi-storied residential buildings',
    servicecode: '995411',
    taxrate: 28.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 2,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Construction services of buildings',
    groupcode: '99541',
    service:
      'Construction services of other residential buildings such as old age homes, homeless shelters, hostels etc',
    servicecode: '995412',
    taxrate: 12.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: true,
    cessPercent: 20.0,
    cesstype: {
      type: 'Percentage',
      description: 'Percent',
      id: 1,
    },
  },
  {
    id: 3,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Construction services of buildings',
    groupcode: '99541',
    service:
      'Construction services of industrial buildings such as buildings used for production activities (used for assembly line activities), workshops, storage buildings and other similar industrial buildings',
    servicecode: '995413',
    taxrate: 18.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: true,
    cessPercent: 15.0,
    cesstype: {
      type: 'Percentage',
      description: 'Percent',
      id: 1,
    },
  },
  {
    id: 4,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Construction services of buildings',
    groupcode: '99541',
    service:
      'Construction services of commercial buildings such as office buildings, exhibition & marriage halls, malls, hotels, restaurants, airports, rail or road terminals, parking garages, petrol and service stations, theatres and other similar buildings.',
    servicecode: '995414',
    taxrate: 18.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 5,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Construction services of buildings',
    groupcode: '99541',
    service:
      'Construction services of other non-residential buildings such as educational institutions, hospitals, clinics including vertinary clinics, religious establishments, courts, prisons, museums and other similar buildings',
    servicecode: '995415',
    taxrate: 0.0,
    isnilrated: true,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 6,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Construction services of buildings',
    groupcode: '99541',
    service: 'Construction Services of other buildings n.e.c',
    servicecode: '995416',
    taxrate: 12.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 7,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Construction services of buildings',
    groupcode: '99541',
    service:
      'Services involving Repair, alterations, additions, replacements, renovation, maintenance or remodelling of the buildings covered above.',
    servicecode: '995419',
    taxrate: 18.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: true,
    cessPercent: 0.0,
    cesstype: {
      type: 'Manual',
      description: 'Manual',
      id: 2,
    },
  },
  {
    id: 8,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service:
      'General construction services of highways, streets, roads, railways and airfield runways, bridges and tunnels',
    servicecode: '995421',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 9,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service:
      'General construction services of harbours, waterways, dams, water mains and lines, irrigation and other waterworks',
    servicecode: '995422',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 10,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service:
      'General construction services of long-distance underground/overland/submarine pipelines, communication and electric power lines (cables); pumping stations and related works; transformer stations and related works.',
    servicecode: '995423',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 11,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service:
      'General construction services of local water & sewage pipelines, electricity and communication cables & related works',
    servicecode: '995424',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 12,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service: 'General construction services of mines and industrial plants',
    servicecode: '995425',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 13,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service: 'General Construction services of Power Plants and its related infrastructure',
    servicecode: '995426',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 14,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service: 'General construction services of outdoor sport and recreation facilities',
    servicecode: '995427',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 15,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service: 'General construction services of other civil engineering works n.e.c.',
    servicecode: '995428',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 16,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'General construction services of civil engineering works',
    groupcode: '99542',
    service:
      'Services involving Repair, alterations, additions, replacements, renovation, maintenance or remodelling of the constructions covered above.',
    servicecode: '995429',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 17,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Site preparation services',
    groupcode: '99543',
    service: 'Demolition services',
    servicecode: '995431',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 18,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Site preparation services',
    groupcode: '99543',
    service:
      'Site formation and clearance services including preparation services to make sites ready for subsequent construction work, test drilling & boring & core extraction, digging of trenches.',
    servicecode: '995432',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 19,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Site preparation services',
    groupcode: '99543',
    service: 'Excavating and earthmoving services',
    servicecode: '995433',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 20,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Site preparation services',
    groupcode: '99543',
    service: 'Water well drilling services and septic system installation services',
    servicecode: '995434',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 21,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Site preparation services',
    groupcode: '99543',
    service: 'Other site preparation services n.e.c',
    servicecode: '995435',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 22,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Site preparation services',
    groupcode: '99543',
    service:
      'Services involving Repair, alterations, additions, replacements, maintenance of the constructions covered above.',
    servicecode: '995439',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 23,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Assembly and erection of prefabricated constructions',
    groupcode: '99544',
    service: 'Installation, assembly and erection services of prefabricated buildings',
    servicecode: '995441',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 24,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Assembly and erection of prefabricated constructions',
    groupcode: '99544',
    service: 'Installation, assembly and erection services of other prefabricated structures and constructions',
    servicecode: '995442',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 25,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Assembly and erection of prefabricated constructions',
    groupcode: '99544',
    service:
      'Installation services of all types of street furniture (e.g., bus shelters, benches, telephone booths, public toilets, etc.)',
    servicecode: '995443',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 26,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Assembly and erection of prefabricated constructions',
    groupcode: '99544',
    service: 'Other assembly and erection services n.e.c.',
    servicecode: '995444',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 27,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Assembly and erection of prefabricated constructions',
    groupcode: '99544',
    service:
      'Services involving Repair, alterations, additions, replacements, maintenance of the constructions covered above.',
    servicecode: '995449',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 28,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Pile driving and foundation services',
    servicecode: '995451',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 29,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Building framing & Roof Framing services',
    servicecode: '995452',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 30,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Roofing and waterproofing services',
    servicecode: '995453',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 31,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Concrete services',
    servicecode: '995454',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 32,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Structural steel erection services',
    servicecode: '995455',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 33,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Masonry services',
    servicecode: '995456',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 34,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Scaffolding services',
    servicecode: '995457',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 35,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service: 'Other special trade construction services n.e.c.',
    servicecode: '995458',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 36,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Special trade construction services',
    groupcode: '99545',
    service:
      'Services involving Repair, alterations, additions, replacements, maintenance of the constructions covered above.',
    servicecode: '995459',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 37,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service:
      'Electrical installation services including Electrical wiring & fitting services, fire alarm installation services, burglar alarm system installation services.',
    servicecode: '995461',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 38,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service: 'Water plumbing and drain laying services',
    servicecode: '995462',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 39,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service: 'Heating, ventilation and air conditioning equipment installation services',
    servicecode: '995463',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 40,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service: 'Gas fitting installation services',
    servicecode: '995464',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 41,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service: 'Insulation services',
    servicecode: '995465',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 42,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service: 'Lift and escalator installation services',
    servicecode: '995466',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 43,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service: 'Other installation services n.e.c.',
    servicecode: '995468',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 44,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Installation services',
    groupcode: '99546',
    service:
      'Services involving Repair, alterations, additions, replacements, maintenance of the installations covered above.',
    servicecode: '995469',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 45,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Glazing services',
    servicecode: '995471',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 46,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Plastering services',
    servicecode: '995472',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 47,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Painting services',
    servicecode: '995473',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 48,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Floor and wall tiling services',
    servicecode: '995474',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 49,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Other floor laying, wall covering and wall papering services',
    servicecode: '995475',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 50,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Joinery and carpentry services',
    servicecode: '995476',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 51,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Fencing and railing services',
    servicecode: '995477',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 52,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service: 'Other building completion and finishing services n.e.c.',
    servicecode: '995478',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 53,
    heading: 'Construction Services',
    headingcode: '9954',
    group: 'Building completion and finishing services',
    groupcode: '99547',
    service:
      'Services involving Repair, alterations, additions, replacements, maintenance of the completion/finishing works covered above.',
    servicecode: '995479',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 54,
    heading: 'Services in wholesale trade',
    headingcode: '9961',
    group: '',
    groupcode: '99611',
    service: 'Services provided for a fee/commission or contract basis on wholesale trade',
    servicecode: '996111',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 55,
    heading: 'Services in retail trade',
    headingcode: '9962',
    group: '',
    groupcode: '99621',
    service: 'Services provided for a fee/commission or contract basis on retail trade',
    servicecode: '996211',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 56,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Accommodation services',
    groupcode: '99631',
    service: 'Room or unit accommodation services provided by Hotels, INN, Guest House, Club etc',
    servicecode: '996311',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 57,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Accommodation services',
    groupcode: '99631',
    service: 'Camp site services',
    servicecode: '996312',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 58,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Accommodation services',
    groupcode: '99631',
    service: 'Recreational and vacation camp services',
    servicecode: '996313',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 59,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Other accommodation services',
    groupcode: '99632',
    service: 'Room or unit accommodation services for students in student residences',
    servicecode: '996321',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 60,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Other accommodation services',
    groupcode: '99632',
    service: 'Room or unit accommodation services provided by Hostels, Camps, Paying Guest etc',
    servicecode: '996322',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 61,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Other accommodation services',
    groupcode: '99632',
    service: 'Other room or unit accommodation services n.e.c.',
    servicecode: '996329',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 62,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service:
      'Services provided by Restaurants, Cafes and similar eating facilities including takeaway services, Room services and door delivery of food.',
    servicecode: '996331',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 63,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service:
      'Services provided by Hotels, INN, Guest House, Club etc including Room services, takeaway services and door delivery of food.',
    servicecode: '996332',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 64,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service: 'Services provided in Canteen and other similar establishments',
    servicecode: '996333',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 65,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service: 'Catering Services in Exhibition halls, Events, Marriage Halls and other outdoor/indoor functions.',
    servicecode: '996334',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 66,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service: 'Catering services in trains, flights etc.',
    servicecode: '996335',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 67,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service:
      'Preparation and/or supply services of food, edible preparations, alchoholic & non-alchocholic beverages to airlines and other transportation operators',
    servicecode: '996336',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 68,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service: 'Other contract food services',
    servicecode: '996337',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 69,
    heading: 'Accommodation, Food and beverage services',
    headingcode: '9963',
    group: 'Food, edible preparations, alchoholic & non-alchocholic beverages serving services',
    groupcode: '99633',
    service: 'Other food, edible preparations, alchoholic & non-alchocholic beverages serving services n.e.c.',
    servicecode: '996339',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 70,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Local transport and sightseeing transportation services of passengers',
    groupcode: '99641',
    service:
      'Local land transport services of passengers by railways, metro, monorail, bus, tramway, autos, three wheelers, scooters and other motor vehicles',
    servicecode: '996411',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 71,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Local transport and sightseeing transportation services of passengers',
    groupcode: '99641',
    service: 'Taxi services including radio taxi & other similar services;',
    servicecode: '996412',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 72,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Local transport and sightseeing transportation services of passengers',
    groupcode: '99641',
    service: 'Non-scheduled local bus and coach charter services',
    servicecode: '996413',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 73,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Local transport and sightseeing transportation services of passengers',
    groupcode: '99641',
    service: 'Other land transportation services of passengers.',
    servicecode: '996414',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 74,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Local transport and sightseeing transportation services of passengers',
    groupcode: '99641',
    service: 'Local water transport services of passengers by ferries, cruises etc',
    servicecode: '996415',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 75,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Local transport and sightseeing transportation services of passengers',
    groupcode: '99641',
    service: 'Sightseeing transportation services by rail, land, water & air',
    servicecode: '996416',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 76,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Local transport and sightseeing transportation services of passengers',
    groupcode: '99641',
    service: 'Other local transportation services of passengers n.e.c.',
    servicecode: '996419',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 77,
    heading: 'Passenger transport services',
    headingcode: '9964',
    group: 'Long-distance transport services of passengers',
    groupcode: '99642',
    service: 'Long-distance transport services of passengers through Rail network by Railways, Metro etc',
    servicecode: '996421',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 78,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Long-distance transport services of passengers',
    groupcode: '99643',
    service:
      'Long-distance transport services of passengers through Road by Bus, Car, non-scheduled long distance bus and coach services, stage carriage etc',
    servicecode: '996422',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 79,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Land transport services of Goods',
    groupcode: '99651',
    service:
      'Road transport services of Goods including letters, parcels, live animals, household & office furniture, containers etc by refrigerator vehicles, trucks, trailers, man or animal drawn vehicles or any other vehicles.',
    servicecode: '996511',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 80,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Land transport services of Goods',
    groupcode: '99651',
    service:
      'Railway transport services of Goods including letters, parcels, live animals, household & office furniture, intermodal containers, bulk cargo etc',
    servicecode: '996512',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 81,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Land transport services of Goods',
    groupcode: '99651',
    service: 'Transport services of petroleum & natural gas, water, sewerage and other goods via pipeline',
    servicecode: '996513',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 82,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Land transport services of Goods',
    groupcode: '99651',
    service: 'Other land transport services of goods n.e.c.',
    servicecode: '996519',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 83,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Water transport services of goods',
    groupcode: '99652',
    service:
      'Coastal and transoceanic (overseas) water transport services of goods by refrigerator vessels, tankers, bulk cargo vessels, container ships etc',
    servicecode: '996521',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 84,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Water transport services of goods',
    groupcode: '99652',
    service: 'Inland water transport services of goods by refrigerator vessels, tankers and other vessels.',
    servicecode: '996522',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 85,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Air and space transport services of goods',
    groupcode: '99653',
    service: 'Air transport services of letters & parcels and other goods',
    servicecode: '996531',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 86,
    heading: 'Passenger transport services',
    headingcode: '9965',
    group: 'Air and space transport services of goods',
    groupcode: '99653',
    service: 'Space transport services of freight',
    servicecode: '996532',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 87,
    heading: 'Passenger transport services',
    headingcode: '9966',
    group: 'Long-distance transport services of passengers',
    groupcode: '99644',
    service: 'Taxi services including radio taxi & other similar services',
    servicecode: '996423',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 88,
    heading: 'Passenger transport services',
    headingcode: '9966',
    group: 'Rental services of transport vehicles with or without operators',
    groupcode: '99660',
    service:
      'Rental services of road vehicles including buses, coaches, cars, trucks and other motor vehicles, with or without operator',
    servicecode: '996601',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 89,
    heading: 'Passenger transport services',
    headingcode: '9966',
    group: 'Rental services of transport vehicles with or without operators',
    groupcode: '99660',
    service:
      'Rental services of water vessels including passenger vessels, freight vessels etc with or without operator',
    servicecode: '996602',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 90,
    heading: 'Passenger transport services',
    headingcode: '9966',
    group: 'Rental services of transport vehicles with or without operators',
    groupcode: '99660',
    service:
      'Rental services of aircraft including passenger aircrafts, freight aircrafts etc with or without operator',
    servicecode: '996603',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 91,
    heading: 'Passenger transport services',
    headingcode: '9966',
    group: 'Rental services of transport vehicles with or without operators',
    groupcode: '99660',
    service: 'Rental services of other transport vehicles n.e.c. with or without operator',
    servicecode: '996609',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 92,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Long-distance transport services of passengers',
    groupcode: '99645',
    service: 'Coastal and transoceanic (overseas) water transport services of passengers by Ferries, Cruise Ships etc',
    servicecode: '996424',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 93,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Cargo handling services',
    groupcode: '99671',
    service: 'Container handling services',
    servicecode: '996711',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 94,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Cargo handling services',
    groupcode: '99671',
    service: 'Customs House Agent services',
    servicecode: '996712',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 95,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Cargo handling services',
    groupcode: '99671',
    service: 'Clearing and forwarding services',
    servicecode: '996713',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 96,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Cargo handling services',
    groupcode: '99671',
    service: 'Other cargo and baggage handling services',
    servicecode: '996719',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 97,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Storage and warehousing services',
    groupcode: '99672',
    service: 'Refrigerated storage services',
    servicecode: '996721',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 98,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Storage and warehousing services',
    groupcode: '99672',
    service: 'Bulk liquid or gas storage services',
    servicecode: '996722',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 99,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Storage and warehousing services',
    groupcode: '99672',
    service: 'Other storage and warehousing services',
    servicecode: '996729',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 100,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for railway transport',
    groupcode: '99673',
    service: 'Railway pushing or towing services',
    servicecode: '996731',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 101,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for railway transport',
    groupcode: '99673',
    service: 'Other supporting services for railway transport n.e.c.',
    servicecode: '996739',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 102,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for road transport',
    groupcode: '99674',
    service: 'Bus station services',
    servicecode: '996741',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 103,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for road transport',
    groupcode: '99674',
    service:
      'Operation services of National Highways, State Highways, Expressways, Roads & streets; bridges and tunnel operation services.',
    servicecode: '996742',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 104,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for road transport',
    groupcode: '99674',
    service: 'Parking lot services',
    servicecode: '996743',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 105,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for road transport',
    groupcode: '99674',
    service: 'Towing services for commercial and private vehicles',
    servicecode: '996744',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 106,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for road transport',
    groupcode: '99674',
    service: 'Other supporting services for road transport n.e.c.',
    servicecode: '996749',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 107,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for water transport (coastal, transoceanic and inland waterways)',
    groupcode: '99675',
    service:
      'Port and waterway operation services (excl. cargo handling) such as operation services of ports, docks, light houses, light ships etc',
    servicecode: '996751',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 108,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for water transport (coastal, transoceanic and inland waterways)',
    groupcode: '99675',
    service: 'Pilotage and berthing services',
    servicecode: '996752',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 109,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for water transport (coastal, transoceanic and inland waterways)',
    groupcode: '99675',
    service: 'Vessel salvage and refloating services',
    servicecode: '996753',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 110,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for water transport (coastal, transoceanic and inland waterways)',
    groupcode: '99675',
    service: 'Other supporting services for water transport n.e.c.',
    servicecode: '996759',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 111,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for air or space transport',
    groupcode: '99676',
    service: 'Airport operation services (excl. cargo handling)',
    servicecode: '996761',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 112,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for air or space transport',
    groupcode: '99676',
    service: 'Air traffic control services',
    servicecode: '996762',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 113,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for air or space transport',
    groupcode: '99676',
    service: 'Other supporting services for air transport',
    servicecode: '996763',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 114,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Supporting services for air or space transport',
    groupcode: '99676',
    service: 'Supporting services for space transport',
    servicecode: '996764',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 115,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Other supporting transport services',
    groupcode: '99679',
    service: 'Goods transport agency services for road transport',
    servicecode: '996791',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 116,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Other supporting transport services',
    groupcode: '99679',
    service: 'Goods transport agency services for other modes of transport',
    servicecode: '996792',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 117,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Other supporting transport services',
    groupcode: '99679',
    service: 'Other goods transport services',
    servicecode: '996793',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 118,
    heading: 'Passenger transport services',
    headingcode: '9967',
    group: 'Other supporting transport services',
    groupcode: '99679',
    service: 'Other supporting transport services n.e.c',
    servicecode: '996799',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 119,
    heading: 'Passenger transport services',
    headingcode: '9968',
    group: 'Long-distance transport services of passengers',
    groupcode: '99646',
    service: 'Domestic/International Scheduled Air transport services of passengers',
    servicecode: '996425',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 120,
    heading: 'Passenger transport services',
    headingcode: '9968',
    group: 'Postal and courier services',
    groupcode: '99681',
    service: 'Postal services including post office counter services, mail box rental services.',
    servicecode: '996811',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 121,
    heading: 'Passenger transport services',
    headingcode: '9968',
    group: 'Postal and courier services',
    groupcode: '99681',
    service: 'Courier services',
    servicecode: '996812',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 122,
    heading: 'Passenger transport services',
    headingcode: '9968',
    group: 'Postal and courier services',
    groupcode: '99681',
    service: 'Local delivery services',
    servicecode: '996813',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 123,
    heading: 'Passenger transport services',
    headingcode: '9968',
    group: 'Postal and courier services',
    groupcode: '99681',
    service: 'Other Delivery Services n.e.c',
    servicecode: '996819',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 124,
    heading: 'Passenger transport services',
    headingcode: '9969',
    group: 'Long-distance transport services of passengers',
    groupcode: '99647',
    service: 'Domestic/international non-scheduled air transport services of Passengers',
    servicecode: '996426',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 125,
    heading: 'Passenger transport services',
    headingcode: '9969',
    group: 'Electricity and gas distribution services',
    groupcode: '99691',
    service: 'Electricity transmission services',
    servicecode: '996911',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 126,
    heading: 'Passenger transport services',
    headingcode: '9969',
    group: 'Electricity and gas distribution services',
    groupcode: '99691',
    service: 'Electricity distribution services',
    servicecode: '996912',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 127,
    heading: 'Passenger transport services',
    headingcode: '9969',
    group: 'Electricity and gas distribution services',
    groupcode: '99691',
    service: 'Gas distribution services',
    servicecode: '996913',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 128,
    heading: 'Passenger transport services',
    headingcode: '9969',
    group: 'Water distribution and other services',
    groupcode: '99692',
    service: 'Water distribution services',
    servicecode: '996921',
    taxrate: 0.0,
    isnilrated: true,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 129,
    heading: 'Passenger transport services',
    headingcode: '9969',
    group: 'Water distribution and other services',
    groupcode: '99692',
    service: 'Services involving distribution of steam, hot water and air conditioning supply etc.',
    servicecode: '996922',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 130,
    heading: 'Passenger transport services',
    headingcode: '9969',
    group: 'Water distribution and other services',
    groupcode: '99692',
    service: 'Other similar services.',
    servicecode: '996929',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 131,
    heading: 'Passenger transport services',
    headingcode: '9970',
    group: 'Long-distance transport services of passengers',
    groupcode: '99648',
    service: 'Space transport services of passengers',
    servicecode: '996427',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 132,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Long-distance transport services of passengers',
    groupcode: '99649',
    service: 'Other long-distance transportation services of passengers n.e.c.',
    servicecode: '996429',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 133,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Financial services (except investment banking, insurance services and pension services)',
    groupcode: '99711',
    service: 'Central banking services',
    servicecode: '997111',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 134,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Financial services (except investment banking, insurance services and pension services)',
    groupcode: '99711',
    service: 'Deposit services',
    servicecode: '997112',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 135,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Financial services (except investment banking, insurance services and pension services)',
    groupcode: '99711',
    service: 'Credit-granting services including stand-by commitment, guarantees & securities',
    servicecode: '997113',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 136,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Financial services (except investment banking, insurance services and pension services)',
    groupcode: '99711',
    service: 'Financial leasing services',
    servicecode: '997114',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 137,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Financial services (except investment banking, insurance services and pension services)',
    groupcode: '99711',
    service: 'Other financial services (except investment banking, insurance services and pension services)',
    servicecode: '997119',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 138,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Investment banking services',
    groupcode: '99712',
    service: 'Investment banking services',
    servicecode: '997120',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 139,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'pension services',
    servicecode: '997131',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 140,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'Life insurance services  (excluding reinsurance services)',
    servicecode: '997132',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 141,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'Accident and health insurance services',
    servicecode: '997133',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 142,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'Motor vehicle insurance services',
    servicecode: '997134',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 143,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'Marine, aviation, and other transport insurance services',
    servicecode: '997135',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 144,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'Freight insurance services & Travel insurance services',
    servicecode: '997136',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 145,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'Other property insurance services',
    servicecode: '997137',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 146,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Insurance and pension services (excluding reinsurance services)',
    groupcode: '99713',
    service: 'Other non-life insurance services (excluding reinsurance services)',
    servicecode: '997139',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 147,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'Life reinsurance services',
    servicecode: '997141',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 148,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'Accident and health reinsurance services',
    servicecode: '997142',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 149,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'Motor vehicle reinsurance services',
    servicecode: '997143',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 150,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'Marine, aviation and other transport reinsurance ser',
    servicecode: '997144',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 151,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'services',
    servicecode: '997145',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 152,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'Freight reinsurance services',
    servicecode: '997146',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 153,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'Other property reinsurance services',
    servicecode: '997147',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 154,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Reinsurance services',
    groupcode: '99714',
    service: 'Other non-life reinsurance services',
    servicecode: '997149',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 155,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service:
      'Services related to investment banking such as mergers & acquisition services, corporate finance & venture capital services',
    servicecode: '997151',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 156,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Brokerage and related securities and commodities services including commodity exchange services',
    servicecode: '997152',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 157,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Portfolio management services except pension funds',
    servicecode: '997153',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 158,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Trust and custody services',
    servicecode: '997154',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 159,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Services related to the administration of financial markets',
    servicecode: '997155',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 160,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Financial consultancy services',
    servicecode: '997156',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 161,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Foreign exchange services',
    servicecode: '997157',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 162,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Financial transactions processing and clearing house services',
    servicecode: '997158',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 163,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxiliary to financial services (other than to insurance and pensions)',
    groupcode: '99715',
    service: 'Other services auxiliary to financial services',
    servicecode: '997159',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 164,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxillary to insurance and pensions',
    groupcode: '99716',
    service: 'Insurance brokerage and agency services',
    servicecode: '997161',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 165,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxillary to insurance and pensions',
    groupcode: '99716',
    service: 'Insurance claims adjustment services',
    servicecode: '997162',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 166,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxillary to insurance and pensions',
    groupcode: '99716',
    service: 'Actuarial services',
    servicecode: '997163',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 167,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxillary to insurance and pensions',
    groupcode: '99716',
    service: 'Pension fund management services',
    servicecode: '997164',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 168,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services auxillary to insurance and pensions',
    groupcode: '99716',
    service: 'Other services auxiliary to insurance and pensions',
    servicecode: '997169',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 169,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services of holding financial assets',
    groupcode: '99717',
    service: 'Services of holding equity of subsidiary companies',
    servicecode: '997171',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 170,
    heading: 'Passenger transport services',
    headingcode: '9971',
    group: 'Services of holding financial assets',
    groupcode: '99717',
    service: 'Services of holding securities and other assets of trusts and funds and similar financial entities',
    servicecode: '997172',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 171,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services involving owned or leased property',
    groupcode: '99721',
    service: 'Rental or leasing services involving own or leased residential property',
    servicecode: '997211',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 172,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services involving owned or leased property',
    groupcode: '99721',
    service: 'Rental or leasing services involving own or leased non-residential property',
    servicecode: '997212',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 173,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services involving owned or leased property',
    groupcode: '99721',
    service: 'Trade services of buildings',
    servicecode: '997213',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 174,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services involving owned or leased property',
    groupcode: '99721',
    service: 'Trade services of time-share properties',
    servicecode: '997214',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 175,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services involving owned or leased property',
    groupcode: '99721',
    service: 'Trade services of vacant and subdivided land',
    servicecode: '997215',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 176,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services on a fee/commission basis or contract basis',
    groupcode: '99722',
    service: 'Property management services on a fee/commission basis or contract basis',
    servicecode: '997221',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 177,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services on a fee/commission basis or contract basis',
    groupcode: '99722',
    service: 'Building sales on a fee/commission basis or contract basis',
    servicecode: '997222',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 178,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services on a fee/commission basis or contract basis',
    groupcode: '99722',
    service: 'Land sales on a fee/commission basis or contract basis',
    servicecode: '997223',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 179,
    heading: 'Real estate services',
    headingcode: '9972',
    group: 'Real estate services on a fee/commission basis or contract basis',
    groupcode: '99722',
    service: 'Real estate appraisal services on a fee/commission basis or contract basis',
    servicecode: '997224',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 180,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning machinery and equipment with or without operator',
    groupcode: '99731',
    service:
      'Leasing or rental services concerning transport equipments including containers, with or without operator',
    servicecode: '997311',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 181,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning machinery and equipment with or without operator',
    groupcode: '99731',
    service: 'Leasing or rental services concerning agricultural machinery and equipment with or without operator',
    servicecode: '997312',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 182,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning machinery and equipment with or without operator',
    groupcode: '99731',
    service: 'Leasing or rental services concerning construction machinery and equipment with or without operator',
    servicecode: '997313',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 183,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning machinery and equipment with or without operator',
    groupcode: '99731',
    service:
      'Leasing or rental services concerning office machinery and equipment (except computers) with or without operator',
    servicecode: '997314',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 184,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning machinery and equipment with or without operator',
    groupcode: '99731',
    service: 'Leasing or rental services concerning computers with or without operators',
    servicecode: '997315',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 185,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning machinery and equipment with or without operator',
    groupcode: '99731',
    service: 'Leasing or rental services concerning telecommunications equipment with or without operator',
    servicecode: '997316',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 186,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning machinery and equipment with or without operator',
    groupcode: '99731',
    service: 'Leasing or rental services concerning other machinery and equipments with or without operator',
    servicecode: '997319',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 187,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service:
      'Leasing or rental services concerning televisions, radios, video cassette recorders, projectors, audio systems and related equipment and accessories (Home entertainment equipment )',
    servicecode: '997321',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 188,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service: 'Leasing or rental services concerning video tapes and disks (Home entertainment equipment )',
    servicecode: '997322',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 189,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service: 'Leasing or rental services concerning furniture and other household appliances',
    servicecode: '997323',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 190,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service: 'Leasing or rental services concerning pleasure and leisure equipment.',
    servicecode: '997324',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 191,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service: 'Leasing or rental services concerning household linen.',
    servicecode: '997325',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 192,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service: 'Leasing or rental services concerning textiles, clothing and footwear.',
    servicecode: '997326',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 193,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service: 'Leasing or rental services concerning do-it-yourself machinery and equipment',
    servicecode: '997327',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 194,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Leasing or rental services concerning other goods',
    groupcode: '99732',
    service: 'Leasing or rental services concerning other goods',
    servicecode: '997329',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 195,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for the right to use computer software and databases.',
    servicecode: '997331',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 196,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service:
      'Licensing services for the right to broadcast and show original films, sound recordings, radio and television programme etc.',
    servicecode: '997332',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 197,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for the right to reproduce original art works',
    servicecode: '997333',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 198,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for the right to reprint and copy manuscripts, books, journals and periodicals.',
    servicecode: '997334',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 199,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for the right to use R&D products',
    servicecode: '997335',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 200,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for the right to use trademarks and franchises',
    servicecode: '997336',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 201,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for the right to use minerals including its exploration and evaluation',
    servicecode: '997337',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 202,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for right to use other natural resources including telecommunication spectrum',
    servicecode: '997338',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 203,
    heading: 'Leasing or rental services with or without operator',
    headingcode: '9973',
    group: 'Licensing services for the right to use intellectual property and similar products',
    groupcode: '99733',
    service: 'Licensing services for the right to use other intellectual property products and other rescources n.e.c',
    servicecode: '997339',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 204,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and experimental development services in natural sciences and engineering.',
    groupcode: '99811',
    service: 'Research and experimental development services in natural sciences',
    servicecode: '998111',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 205,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and experimental development services in natural sciences and engineering.',
    groupcode: '99811',
    service: 'Research and experimental development services in engineering and technology',
    servicecode: '998112',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 206,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and experimental development services in natural sciences and engineering.',
    groupcode: '99811',
    service: 'Research and experimental development services in medical sciences and pharmacy.',
    servicecode: '998113',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 207,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and experimental development services in natural sciences and engineering.',
    groupcode: '99811',
    service: 'Research and experimental development services in agricultural sciences.',
    servicecode: '998114',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 208,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and experimental development services in social sciences and humanities.',
    groupcode: '99812',
    service: 'Research and experimental development services in social sciences.',
    servicecode: '998121',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 209,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and experimental development services in social sciences and humanities.',
    groupcode: '99812',
    service: 'Research and experimental development services in humanities',
    servicecode: '998122',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 210,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Interdisciplinary research services.',
    groupcode: '99813',
    service: 'Interdisciplinary research and experimental development services.',
    servicecode: '998130',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 211,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and development originals',
    groupcode: '99814',
    service: 'Research and development originals in pharmaceuticals',
    servicecode: '998141',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 212,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and development originals',
    groupcode: '99814',
    service: 'Research and development originals in agriculture',
    servicecode: '998142',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 213,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and development originals',
    groupcode: '99814',
    service: 'Research and development originals in biotechnology',
    servicecode: '998143',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 214,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and development originals',
    groupcode: '99814',
    service: 'Research and development originals in computer related sciences',
    servicecode: '998144',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 215,
    heading: 'Research and development services',
    headingcode: '9981',
    group: 'Research and development originals',
    groupcode: '99814',
    service: 'Research and development originals in other fields n.e.c.',
    servicecode: '998145',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 216,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Legal Services',
    groupcode: '99821',
    service: 'Legal advisory and representation services concerning criminal law.',
    servicecode: '998211',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 217,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Legal Services',
    groupcode: '99821',
    service: 'Legal advisory and representation services concerning other fields of law.',
    servicecode: '998212',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 218,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Legal Services',
    groupcode: '99821',
    service:
      'Legal documentation and certification services concerning patents, copyrights and other intellectual property rights.',
    servicecode: '998213',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 219,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Legal Services',
    groupcode: '99821',
    service: 'Legal documentation and certification services concerning other documents.',
    servicecode: '998214',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 220,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Legal Services',
    groupcode: '99821',
    service: 'Arbitration and conciliation services',
    servicecode: '998215',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 221,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Legal Services',
    groupcode: '99821',
    service: 'Other legal services n.e.c.',
    servicecode: '998216',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 222,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Accounting, auditing and bookkeeping services',
    groupcode: '99822',
    service: 'Financial auditing services',
    servicecode: '998221',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 223,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Accounting, auditing and bookkeeping services',
    groupcode: '99822',
    service: 'Accounting and bookkeeping services',
    servicecode: '998222',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 224,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Accounting, auditing and bookkeeping services',
    groupcode: '99822',
    service: 'Payroll services',
    servicecode: '998223',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 225,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Accounting, auditing and bookkeeping services',
    groupcode: '99822',
    service: 'Other similar services n.e.c',
    servicecode: '998224',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 226,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Tax consultancy and preparation services',
    groupcode: '99823',
    service: 'Corporate tax consulting and preparation services',
    servicecode: '998231',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 227,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Tax consultancy and preparation services',
    groupcode: '99823',
    service: 'Individual tax preparation and planning services',
    servicecode: '998232',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 228,
    heading: 'Legal and accounting services',
    headingcode: '9982',
    group: 'Insolvency and receivership services',
    groupcode: '99824',
    service: 'Insolvency and receivership services',
    servicecode: '998240',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 229,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Management consulting and management services; information technology services.',
    groupcode: '99831',
    service:
      'Management consulting and management services including financial, strategic, human resources, marketing, operations and supply chain management.',
    servicecode: '998311',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 230,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Management consulting and management services; information technology services.',
    groupcode: '99831',
    service: 'Business consulting services including pubic relations services',
    servicecode: '998312',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 231,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Management consulting and management services; information technology services.',
    groupcode: '99831',
    service: 'Information technology (IT) consulting and support services',
    servicecode: '998313',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 232,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Management consulting and management services; information technology services.',
    groupcode: '99831',
    service: 'Information technology (IT) design and development services',
    servicecode: '998314',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 233,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Management consulting and management services; information technology services.',
    groupcode: '99831',
    service: 'Hosting and information technology (IT) infrastructure provisioning services',
    servicecode: '998315',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 234,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Management consulting and management services; information technology services.',
    groupcode: '99831',
    service: 'IT infrastructure and network management services',
    servicecode: '998316',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 235,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Management consulting and management services; information technology services.',
    groupcode: '99831',
    service: 'Other information technology services n.e.c',
    servicecode: '998319',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 236,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Architectural advisory services',
    servicecode: '998321',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 237,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Architectural services for residential building projects',
    servicecode: '998322',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 238,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Architectural services for non-residential building projects',
    servicecode: '998323',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 239,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Historical restoration architectural services',
    servicecode: '998324',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 240,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Urban planning services',
    servicecode: '998325',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 241,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Rural land planning services',
    servicecode: '998326',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 242,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Project site master planning services',
    servicecode: '998327',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 243,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Architectural services, urban and land planning and landscape architectural services',
    groupcode: '99832',
    service: 'Landscape architectural services and advisory services',
    servicecode: '998328',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 244,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Engineering advisory services',
    servicecode: '998331',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 245,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Engineering services for building projects',
    servicecode: '998332',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 246,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Engineering services for industrial and manufacturing projects',
    servicecode: '998333',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 247,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Engineering services for transportation projects',
    servicecode: '998334',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 248,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Engineering services for power projects',
    servicecode: '998335',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 249,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Engineering services for telecommunications and broadcasting projects',
    servicecode: '998336',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 250,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service:
      'Engineering services for waste management projects (hazardous and non-hazardous), for water, sewerage and drainage projects.',
    servicecode: '998337',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 251,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Engineering services for other projects n.e.c.',
    servicecode: '998338',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 252,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Engineering Services',
    groupcode: '99833',
    service: 'Project management services for construction projects',
    servicecode: '998339',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 253,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Geological and geophysical consulting services',
    servicecode: '998341',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 254,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Subsurface surveying services',
    servicecode: '998342',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 255,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Mineral exploration and evaluation',
    servicecode: '998343',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 256,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Surface surveying and map-making services',
    servicecode: '998344',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 257,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Weather forecasting and meteorological services',
    servicecode: '998345',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 258,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Technical testing and analysis services',
    servicecode: '998346',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 259,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Certification of ships, aircraft, dams, etc.',
    servicecode: '998347',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 260,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Certification and authentication of works of art',
    servicecode: '998348',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 261,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Scientific and other technical services',
    groupcode: '99834',
    service: 'Other technical and scientific services n.e.c.',
    servicecode: '998349',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 262,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Veterinary services',
    groupcode: '99835',
    service: 'Veterinary services for pet animals',
    servicecode: '998351',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 263,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Veterinary services',
    groupcode: '99835',
    service: 'Veterinary services for livestock',
    servicecode: '998352',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 264,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Veterinary services',
    groupcode: '99835',
    service: 'Other veterinary services n.e.c.',
    servicecode: '998359',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 265,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Advertising services and provision of advertising space or time.',
    groupcode: '99836',
    service: 'Advertising Services',
    servicecode: '998361',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 266,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Advertising services and provision of advertising space or time.',
    groupcode: '99836',
    service: 'Purchase or sale of advertising space or time, on commission',
    servicecode: '998362',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 267,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Advertising services and provision of advertising space or time.',
    groupcode: '99836',
    service: 'Sale of advertising space in print media (except on commission)',
    servicecode: '998363',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 268,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Advertising services and provision of advertising space or time.',
    groupcode: '99836',
    service: 'Sale of TV and radio advertising time',
    servicecode: '998364',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 269,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Advertising services and provision of advertising space or time.',
    groupcode: '99836',
    service: 'Sale of Internet advertising space',
    servicecode: '998365',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 270,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Advertising services and provision of advertising space or time.',
    groupcode: '99836',
    service: 'Sale of other advertising space or time (except on commission)',
    servicecode: '998366',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 271,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Market research and public opinion polling services',
    groupcode: '99837',
    service: 'Market research services',
    servicecode: '998371',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 272,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Market research and public opinion polling services',
    groupcode: '99837',
    service: 'Public opinion polling services',
    servicecode: '998372',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 273,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Photography & Videography and their processing services',
    groupcode: '99838',
    service: 'Portrait photography services',
    servicecode: '998381',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 274,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Photography & Videography and their processing services',
    groupcode: '99838',
    service: 'Advertising and related photography services',
    servicecode: '998382',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 275,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Photography & Videography and their processing services',
    groupcode: '99838',
    service: 'Event photography and event videography services',
    servicecode: '998383',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 276,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Photography & Videography and their processing services',
    groupcode: '99838',
    service: 'Specialty photography services',
    servicecode: '998384',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 277,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Photography & Videography and their processing services',
    groupcode: '99838',
    service: 'Restoration and retouching services of photography',
    servicecode: '998385',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 278,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Photography & Videography and their processing services',
    groupcode: '99838',
    service: 'Photographic & videographic processing services',
    servicecode: '998386',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 279,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Photography & Videography and their processing services',
    groupcode: '99838',
    service: 'Other Photography & Videography and their processing services n.e.c.',
    servicecode: '998387',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 280,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service:
      'Specialty design services including interior design, fashion design, industrial design and other specialty design services',
    servicecode: '998391',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 281,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service: 'Design originals',
    servicecode: '998392',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 282,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service: 'Scientific and technical consulting services',
    servicecode: '998393',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 283,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service: 'Original compilations of facts/information',
    servicecode: '998394',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 284,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service: 'Translation and interpretation services',
    servicecode: '998395',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 285,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service: 'Trademarks and franchises',
    servicecode: '998396',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 286,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service: 'Sponsorship Services & Brand Promotion Services',
    servicecode: '998397',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 287,
    heading: 'Other professional, technical and business services',
    headingcode: '9983',
    group: 'Other professional, technical and business services.',
    groupcode: '99839',
    service: 'Other professional, technical and business services n.e.c.',
    servicecode: '998399',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 288,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Telephony and other telecommunications services',
    groupcode: '99841',
    service: 'Carrier services',
    servicecode: '998411',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 289,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Telephony and other telecommunications services',
    groupcode: '99841',
    service: 'Fixed telephony services',
    servicecode: '998412',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 290,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Telephony and other telecommunications services',
    groupcode: '99841',
    service: 'Mobile telecommunications services',
    servicecode: '998413',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 291,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Telephony and other telecommunications services',
    groupcode: '99841',
    service: 'Private network services',
    servicecode: '998414',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 292,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Telephony and other telecommunications services',
    groupcode: '99841',
    service: 'Data transmission services',
    servicecode: '998415',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 293,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Telephony and other telecommunications services',
    groupcode: '99841',
    service: 'Other telecommunications services including Fax services, Telex services n.e.c.',
    servicecode: '998419',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 294,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Internet telecommunications services',
    groupcode: '99842',
    service: 'Internet backbone services',
    servicecode: '998421',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 295,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Internet telecommunications services',
    groupcode: '99842',
    service: 'Internet access services in wired and wireless mode.',
    servicecode: '998422',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 296,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Internet telecommunications services',
    groupcode: '99842',
    service: 'Fax, telephony over the Internet',
    servicecode: '998423',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 297,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Internet telecommunications services',
    groupcode: '99842',
    service: 'Audio conferencing and video conferencing over the Internet',
    servicecode: '998424',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 298,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Internet telecommunications services',
    groupcode: '99842',
    service: 'Other Internet telecommunications services n.e.c.',
    servicecode: '998429',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 299,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'On-line content services',
    groupcode: '99843',
    service: 'On-line text based information such as online books, newpapers, periodicals, directories etc',
    servicecode: '998431',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 300,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'On-line content services',
    groupcode: '99843',
    service: 'On-line audio content',
    servicecode: '998432',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 301,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'On-line content services',
    groupcode: '99843',
    service: 'On-line video content',
    servicecode: '998433',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 302,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'On-line content services',
    groupcode: '99843',
    service: 'Software downloads',
    servicecode: '998434',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 303,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'On-line content services',
    groupcode: '99843',
    service: 'Other on-line contents n.e.c.',
    servicecode: '998439',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 304,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'News agency services',
    groupcode: '99844',
    service: 'News agency services to newspapers and periodicals',
    servicecode: '998441',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 305,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'News agency services',
    groupcode: '99844',
    service: 'Services of independent journalists and press photographers',
    servicecode: '998442',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 306,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'News agency services',
    groupcode: '99844',
    service: 'News agency services to audiovisual media',
    servicecode: '998443',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 307,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Library and archive services',
    groupcode: '99845',
    service: 'Library services',
    servicecode: '998451',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 308,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Library and archive services',
    groupcode: '99845',
    service: 'Operation services of public archives including digital archives',
    servicecode: '998452',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 309,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Library and archive services',
    groupcode: '99845',
    service: 'Operation services of historical archives including digital archives',
    servicecode: '998453',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 310,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Broadcasting, programming and programme distribution services',
    groupcode: '99846',
    service: 'Radio broadcast originals',
    servicecode: '998461',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 311,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Broadcasting, programming and programme distribution services',
    groupcode: '99846',
    service: 'Television broadcast originals',
    servicecode: '998462',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 312,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Broadcasting, programming and programme distribution services',
    groupcode: '99846',
    service: 'Radio channel programmes',
    servicecode: '998463',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 313,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Broadcasting, programming and programme distribution services',
    groupcode: '99846',
    service: 'Television channel programmes',
    servicecode: '998464',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 314,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Broadcasting, programming and programme distribution services',
    groupcode: '99846',
    service: 'Broadcasting services',
    servicecode: '998465',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 315,
    heading: 'Telecommunications, broadcasting and information supply services',
    headingcode: '9984',
    group: 'Broadcasting, programming and programme distribution services',
    groupcode: '99846',
    service: 'Home programme distribution services',
    servicecode: '998466',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 316,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Executive/retained personnel search services',
    servicecode: '998511',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 317,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Permanent placement services, other than executive search services',
    servicecode: '998512',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 318,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Contract staffing services',
    servicecode: '998513',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 319,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Temporary staffing services',
    servicecode: '998514',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 320,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Long-term staffing (pay rolling) services',
    servicecode: '998515',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 321,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Temporary staffing-to-permanent placement services',
    servicecode: '998516',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 322,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Co-employment staffing services',
    servicecode: '998517',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 323,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Employment services including personnel search/referral service & labour supply service',
    groupcode: '99851',
    service: 'Other employment & labour supply services n.e.c',
    servicecode: '998519',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 324,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Investigation services',
    servicecode: '998521',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 325,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Security consulting services',
    servicecode: '998522',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 326,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Security systems services',
    servicecode: '998523',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 327,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Armoured car services',
    servicecode: '998524',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 328,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Guard services',
    servicecode: '998525',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 329,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Training of guard dogs',
    servicecode: '998526',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 330,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Polygraph services',
    servicecode: '998527',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 331,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Fingerprinting services',
    servicecode: '998528',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 332,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Investigation and security services',
    groupcode: '99852',
    service: 'Other security services n.e.c.',
    servicecode: '998529',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 333,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Disinfecting and exterminating services',
    servicecode: '998531',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 334,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Window cleaning services',
    servicecode: '998532',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 335,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'General cleaning services',
    servicecode: '998533',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 336,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Specialized cleaning services for reservoirs and tanks',
    servicecode: '998534',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 337,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Sterilization of objects or premises (operating rooms)',
    servicecode: '998535',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 338,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Furnace and chimney cleaning services',
    servicecode: '998536',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 339,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Exterior cleaning of buildings of all types',
    servicecode: '998537',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 340,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Cleaning of transportation equipment',
    servicecode: '998538',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 341,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Cleaning services',
    groupcode: '99853',
    service: 'Other cleaning services n.e.c.',
    servicecode: '998539',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 342,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Packaging services',
    groupcode: '99854',
    service: 'Packaging services of goods for others',
    servicecode: '998540',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 343,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Packaging services',
    groupcode: '99854',
    service: 'Parcel packing and gift wrapping',
    servicecode: '998541',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 344,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Packaging services',
    groupcode: '99854',
    service: 'Coin and currency packing services',
    servicecode: '998542',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 345,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Packaging services',
    groupcode: '99854',
    service: 'Other packaging services n.e.c',
    servicecode: '998549',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 346,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service: 'Reservation services for transportation',
    servicecode: '998551',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 347,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service: 'Reservation services for accommodation, cruises and package tours',
    servicecode: '998552',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 348,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service: 'Reservation services for convention centres, congress centres and exhibition halls',
    servicecode: '998553',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 349,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service:
      'Reservation services for event tickets, cinema halls, entertainment and recreational services and other reservation services',
    servicecode: '998554',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 350,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service: 'Tour operator services',
    servicecode: '998555',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 351,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service: 'Tourist guide services',
    servicecode: '998556',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 352,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service: 'Tourism promotion and visitor information services',
    servicecode: '998557',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 353,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Travel arrangement, tour operator and related services',
    groupcode: '99855',
    service: 'Other travel arrangement and related services n.e.c',
    servicecode: '998559',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 354,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Credit reporting & rating services',
    servicecode: '998591',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 355,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Collection agency services',
    servicecode: '998592',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 356,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Telephone-based support services',
    servicecode: '998593',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 357,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Combined office administrative services',
    servicecode: '998594',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 358,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service:
      'Specialized office support services such as duplicating services, mailing services, document preparation etc',
    servicecode: '998595',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 359,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Events, Exhibitions, Conventions and trade shows organisation and assistance services',
    servicecode: '998596',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 360,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Landscape care and maintenance services',
    servicecode: '998597',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 361,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Other information services n.e.c.',
    servicecode: '998598',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 362,
    heading: 'Support services',
    headingcode: '9985',
    group: 'Other support services',
    groupcode: '99859',
    service: 'Other support services n.e.c.',
    servicecode: '998599',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 363,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to agriculture, hunting, forestry and fishing',
    groupcode: '99861',
    service: 'Support services to crop production',
    servicecode: '998611',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 364,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to agriculture, hunting, forestry and fishing',
    groupcode: '99861',
    service: 'Animal husbandry services',
    servicecode: '998612',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 365,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to agriculture, hunting, forestry and fishing',
    groupcode: '99861',
    service: 'Support services to hunting',
    servicecode: '998613',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 366,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to agriculture, hunting, forestry and fishing',
    groupcode: '99861',
    service: 'Support services to forestry and logging',
    servicecode: '998614',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 367,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to agriculture, hunting, forestry and fishing',
    groupcode: '99861',
    service: 'Support services to fishing',
    servicecode: '998615',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 368,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to agriculture, hunting, forestry and fishing',
    groupcode: '99861',
    service: 'Other support services to agriculture, hunting, forestry and fishing',
    servicecode: '998619',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 369,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to mining',
    groupcode: '99862',
    service: 'Support services to oil and gas extraction',
    servicecode: '998621',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 370,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to mining',
    groupcode: '99862',
    service: 'Support services to other mining n.e.c.',
    servicecode: '998622',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 371,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to electricity, gas and water distribution',
    groupcode: '99863',
    service: 'Support services to electricity transmission and distribution',
    servicecode: '998631',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 372,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to electricity, gas and water distribution',
    groupcode: '99863',
    service: 'Support services to gas distribution',
    servicecode: '998632',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 373,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to electricity, gas and water distribution',
    groupcode: '99863',
    service: 'Support services to water distribution',
    servicecode: '998633',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 374,
    heading: 'Support services to agriculture, hunting, forestry, fishing, mining and utilities.',
    headingcode: '9986',
    group: 'Support services to electricity, gas and water distribution',
    groupcode: '99863',
    service: 'Support services to Distribution services of steam, hot water and air-conditioning supply',
    servicecode: '998634',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 375,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of fabricated metal products, except machinery and equipment.',
    servicecode: '998711',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 376,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of office and accounting machinery',
    servicecode: '998712',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 377,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of computers and peripheral equipment',
    servicecode: '998713',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 378,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of transport machinery and equipment',
    servicecode: '998714',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 379,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of electrical household appliances',
    servicecode: '998715',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 380,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of telecommunication equipments and apparatus',
    servicecode: '998716',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 381,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of commercial and industrial machinery.',
    servicecode: '998717',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 382,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of elevators and escalators',
    servicecode: '998718',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 383,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Maintenance and repair services of fabricated metal products, machinery and equipment',
    groupcode: '99871',
    service: 'Maintenance and repair services of other machinery and equipments',
    servicecode: '998719',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 384,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Repair services of footwear and leather goods',
    servicecode: '998721',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 385,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Repair services of watches, clocks and jewellery',
    servicecode: '998722',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 386,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Repair services of garments and household textiles',
    servicecode: '998723',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 387,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Repair services of furniture',
    servicecode: '998724',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 388,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Repair services of bicycles',
    servicecode: '998725',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 389,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Maintenance and repair services of musical instruments',
    servicecode: '998726',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 390,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Repair services for photographic equipment and cameras',
    servicecode: '998727',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 391,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Repair services of other goods',
    groupcode: '99872',
    service: 'Maintenance and repair services of other goods n.e.c.',
    servicecode: '998729',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 392,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Installation services (other than construction)',
    groupcode: '99873',
    service: 'Installation services of fabricated metal products, except machinery and equipment.',
    servicecode: '998731',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 393,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Installation services (other than construction)',
    groupcode: '99873',
    service: 'Installation services of industrial, manufacturing and service industry machinery and equipment.',
    servicecode: '998732',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 394,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Installation services (other than construction)',
    groupcode: '99873',
    service: 'Installation services of office and accounting machinery and computers',
    servicecode: '998733',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 395,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Installation services (other than construction)',
    groupcode: '99873',
    service: 'Installation services of radio, television and communications equipment and apparatus.',
    servicecode: '998734',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 396,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Installation services (other than construction)',
    groupcode: '99873',
    service:
      'Installation services of professional medical machinery and equipment, and precision and optical instruments.',
    servicecode: '998735',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 397,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Installation services (other than construction)',
    groupcode: '99873',
    service: 'Installation services of electrical machinery and apparatus n.e.c.',
    servicecode: '998736',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 398,
    heading: 'Maintenance, repair and installation (except construction) services',
    headingcode: '9987',
    group: 'Installation services (other than construction)',
    groupcode: '99873',
    service: 'Installation services of other goods n.e.c.',
    servicecode: '998739',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 399,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Meat processing services',
    servicecode: '998811',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 400,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Fish processing services',
    servicecode: '998812',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 401,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Fruit and vegetables processing services',
    servicecode: '998813',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 402,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Vegetable and animal oil and fat manufacturing services',
    servicecode: '998814',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 403,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Dairy product manufacturing services',
    servicecode: '998815',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 404,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Other food product manufacturing services',
    servicecode: '998816',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 405,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Prepared animal feeds manufacturing services',
    servicecode: '998817',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 406,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Beverage manufacturing services',
    servicecode: '998818',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 407,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Food, beverage and tobacco manufacturing services',
    groupcode: '99881',
    service: 'Tobacco manufacturing services n.e.c.',
    servicecode: '998819',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 408,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Textile, wearing apparel and leather manufacturing services',
    groupcode: '99882',
    service: 'Textile manufacturing services',
    servicecode: '998821',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 409,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Textile, wearing apparel and leather manufacturing services',
    groupcode: '99882',
    service: 'Wearing apparel manufacturing services',
    servicecode: '998822',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 410,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Textile, wearing apparel and leather manufacturing services',
    groupcode: '99882',
    service: 'Leather and leather product manufacturing services',
    servicecode: '998823',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 411,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Wood and paper manufacturing services',
    groupcode: '99883',
    service: 'Wood and wood product manufacturing services',
    servicecode: '998831',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 412,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Wood and paper manufacturing services',
    groupcode: '99883',
    service: 'Paper and paper product manufacturing services',
    servicecode: '998832',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 413,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Petroleum, chemical and pharmaceutical product manufacturing services',
    groupcode: '99884',
    service: 'Coke and refined petroleum product manufacturing services',
    servicecode: '998841',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 414,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Petroleum, chemical and pharmaceutical product manufacturing services',
    groupcode: '99884',
    service: 'Chemical product manufacturing services',
    servicecode: '998842',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 415,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Petroleum, chemical and pharmaceutical product manufacturing services',
    groupcode: '99884',
    service: 'Pharmaceutical product manufacturing services',
    servicecode: '998843',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 416,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Rubber, plastic and other non-metallic mineral product manufacturing service',
    groupcode: '99885',
    service: 'Rubber and plastic product manufacturing services',
    servicecode: '998851',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 417,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Rubber, plastic and other non-metallic mineral product manufacturing service',
    groupcode: '99885',
    service: 'Plastic product manufacturing services',
    servicecode: '998852',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 418,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Rubber, plastic and other non-metallic mineral product manufacturing service',
    groupcode: '99885',
    service: 'Other non-metallic mineral product manufacturing services',
    servicecode: '998853',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 419,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Basic metal manufacturing services',
    groupcode: '99886',
    service: 'Basic metal manufacturing services',
    servicecode: '998860',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 420,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Fabricated metal product, machinery and equipment manufacturing services',
    groupcode: '99887',
    service: 'Structural metal product, tank, reservoir and steam generator manufacturing services',
    servicecode: '998871',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 421,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Fabricated metal product, machinery and equipment manufacturing services',
    groupcode: '99887',
    service: 'Weapon and ammunition manufacturing services',
    servicecode: '998872',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 422,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Fabricated metal product, machinery and equipment manufacturing services',
    groupcode: '99887',
    service: 'Other fabricated metal product manufacturing and metal treatment services',
    servicecode: '998873',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 423,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Fabricated metal product, machinery and equipment manufacturing services',
    groupcode: '99887',
    service: 'Computer, electronic and optical product manufacturing services',
    servicecode: '998874',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 424,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Fabricated metal product, machinery and equipment manufacturing services',
    groupcode: '99887',
    service: 'Electrical equipment manufacturing services',
    servicecode: '998875',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 425,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Fabricated metal product, machinery and equipment manufacturing services',
    groupcode: '99887',
    service: 'General-purpose machinery manufacturing services n.e.c.',
    servicecode: '998876',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 426,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Fabricated metal product, machinery and equipment manufacturing services',
    groupcode: '99887',
    service: 'Special-purpose machinery manufacturing services',
    servicecode: '998877',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 427,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Transport equipment manufacturing services',
    groupcode: '99888',
    service: 'Motor vehicle and trailer manufacturing services',
    servicecode: '998881',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 428,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Transport equipment manufacturing services',
    groupcode: '99888',
    service: 'Other transport equipment manufacturing services',
    servicecode: '998882',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 429,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Furniture manufacturing services',
    servicecode: '998891',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 430,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Jewellery manufacturing services',
    servicecode: '998892',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 431,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Imitation jewellery manufacturing services',
    servicecode: '998893',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 432,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Musical instrument manufacturing services',
    servicecode: '998894',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 433,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Sports goods manufacturing services',
    servicecode: '998895',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 434,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Game and toy manufacturing services',
    servicecode: '998896',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 435,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Medical and dental instrument and supply manufacturing services',
    servicecode: '998897',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 436,
    heading: 'Manufacturing services on physical inputs (goods) owned by others',
    headingcode: '9988',
    group: 'Other manufacturing services',
    groupcode: '99889',
    service: 'Other manufacturing services n.e.c.',
    servicecode: '998898',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 437,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Publishing, printing and reproduction services',
    groupcode: '99891',
    service: 'Publishing, on a fee or contract basis',
    servicecode: '998911',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 438,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Publishing, printing and reproduction services',
    groupcode: '99891',
    service: 'Printing and reproduction services of recorded media, on a fee or contract basis',
    servicecode: '998912',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 439,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Moulding, pressing, stamping, extruding and similar plastic manufacturing services',
    groupcode: '99892',
    service: 'Moulding, pressing, stamping, extruding and similar plastic manufacturing services',
    servicecode: '998920',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 440,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Casting, forging, stamping and similar metal manufacturing services',
    groupcode: '99893',
    service: 'Iron and steel casting services',
    servicecode: '998931',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 441,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Casting, forging, stamping and similar metal manufacturing services',
    groupcode: '99893',
    service: 'Non-ferrous metal casting services',
    servicecode: '998932',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 442,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Casting, forging, stamping and similar metal manufacturing services',
    groupcode: '99893',
    service: 'Metal forging, pressing, stamping, roll forming and powder metallurgy services',
    servicecode: '998933',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 443,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Materials recovery (recycling) services, on a fee or contract basis',
    groupcode: '99894',
    service: 'Metal waste and scrap recovery (recycling) services, on a fee or contract basis',
    servicecode: '998941',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 444,
    heading:
      'Other manufacturing services; publishing, printing and reproduction services; materials recovery services',
    headingcode: '9989',
    group: 'Materials recovery (recycling) services, on a fee or contract basis',
    groupcode: '99894',
    service: 'Non-metal waste and scrap recovery (recycling) services, on a fee or contract basis',
    servicecode: '998942',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 445,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services of the government',
    groupcode: '99911',
    service: 'Overall Government public services',
    servicecode: '999111',
    taxrate: 12.0,
    isnilrated: false,
    usemodifiedrate: true,
    isCessApplicable: true,
    cessPercent: 10.0,
    cesstype: {
      type: 'Percentage',
      description: 'Percent',
      id: 1,
    },
  },
  {
    id: 446,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services of the government',
    groupcode: '99911',
    service:
      'Public administrative services related to the provision of educational, health care, cultural and other social services, excluding social security service.',
    servicecode: '999112',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 447,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services of the government',
    groupcode: '99911',
    service: 'Public administrative services related to the more efficient operation of business.',
    servicecode: '999113',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 448,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services of the government',
    groupcode: '99911',
    service: 'Other administrative services of the government n.e.c.',
    servicecode: '999119',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 449,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Public administrative services related to external affairs, diplomatic and consular services abroad.',
    servicecode: '999121',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 450,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Services related to foreign economic aid',
    servicecode: '999122',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 451,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Services related to foreign military aid',
    servicecode: '999123',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 452,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Military defence services',
    servicecode: '999124',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 453,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Civil defence services',
    servicecode: '999125',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 454,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Police and fire protection services',
    servicecode: '999126',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 455,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Public administrative services related to law courts',
    servicecode: '999127',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 456,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Administrative services related to the detention or rehabilitation of criminals.',
    servicecode: '999128',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 457,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Public administrative services provided to the community as a whole',
    groupcode: '99912',
    service: 'Public administrative services related to other public order and safety affairs n.e.c.',
    servicecode: '999129',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 458,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services related to compulsory social security schemes.',
    groupcode: '99913',
    service: 'Administrative services related to sickness, maternity or temporary disablement benefit schemes.',
    servicecode: '999131',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 459,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services related to compulsory social security schemes.',
    groupcode: '99913',
    service:
      "Administrative services related to government employee pension schemes; old-age disability or survivors' benefit schemes, other than for government employees",
    servicecode: '999132',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 460,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services related to compulsory social security schemes.',
    groupcode: '99913',
    service: 'Administrative services related to unemployment compensation benefit schemes',
    servicecode: '999133',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 461,
    heading:
      'Public administration and other services provided to the community as a whole; compulsory social security services',
    headingcode: '9991',
    group: 'Administrative services related to compulsory social security schemes.',
    groupcode: '99913',
    service: 'Administrative services related to family and child allowance programmes',
    servicecode: '999134',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 462,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Pre-primary education services',
    groupcode: '99921',
    service: 'Pre-primary education services',
    servicecode: '999210',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 463,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Primary education services',
    groupcode: '99922',
    service: 'Primary education services',
    servicecode: '999220',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 464,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Secondary Education Services',
    groupcode: '99923',
    service: 'Secondary education services, general',
    servicecode: '999231',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 465,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Secondary Education Services',
    groupcode: '99923',
    service: 'Secondary education services, technical and vocational.',
    servicecode: '999232',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 466,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Higher education services',
    groupcode: '99924',
    service: 'Higher education services, general',
    servicecode: '999241',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 467,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Higher education services',
    groupcode: '99924',
    service: 'Higher education services, technical',
    servicecode: '999242',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 468,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Higher education services',
    groupcode: '99924',
    service: 'Higher education services, vocational',
    servicecode: '999243',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 469,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Higher education services',
    groupcode: '99924',
    service: 'Other higher education services',
    servicecode: '999249',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 470,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Specialised education services',
    groupcode: '99925',
    service: 'Specialised education services',
    servicecode: '999259',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 471,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Other education & training services and educational support services',
    groupcode: '99929',
    service: 'Cultural education services',
    servicecode: '999291',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 472,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Other education & training services and educational support services',
    groupcode: '99929',
    service: 'Sports and recreation education services',
    servicecode: '999292',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 473,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Other education & training services and educational support services',
    groupcode: '99929',
    service: 'Commercial training and coaching services',
    servicecode: '999293',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 474,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Other education & training services and educational support services',
    groupcode: '99929',
    service: 'Other education and training services n.e.c.',
    servicecode: '999294',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 475,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Other education & training services and educational support services',
    groupcode: '99929',
    service: 'services involving conduct of examination for admission to educational institutions',
    servicecode: '999295',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 476,
    heading: 'Education services',
    headingcode: '9992',
    group: 'Other education & training services and educational support services',
    groupcode: '99929',
    service: 'Other Educational support services',
    servicecode: '999299',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 477,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Inpatient services',
    servicecode: '999311',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 478,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Medical and dental services',
    servicecode: '999312',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 479,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Childbirth and related services',
    servicecode: '999313',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 480,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Nursing and Physiotherapeutic services',
    servicecode: '999314',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 481,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Ambulance services',
    servicecode: '999315',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 482,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Medical Laboratory and Diagnostic-imaging services',
    servicecode: '999316',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 483,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Blood, sperm and organ bank services',
    servicecode: '999317',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 484,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Human health services',
    groupcode: '99931',
    service: 'Other human health services including homeopathy, unani, ayurveda, naturopathy, acupuncture etc.',
    servicecode: '999319',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 485,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Residential care services for the elderly and disabled',
    groupcode: '99932',
    service: 'Residential health-care services other than by hospitals',
    servicecode: '999321',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 486,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Residential care services for the elderly and disabled',
    groupcode: '99932',
    service: 'Residential care services for the elderly and persons with disabilities',
    servicecode: '999322',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 487,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services with accommodation',
    groupcode: '99933',
    service:
      'Residential care services for children suffering from mental retardation, mental health illnesses or substance abuse',
    servicecode: '999331',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 488,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services with accommodation',
    groupcode: '99933',
    service: 'Other social services with accommodation for children',
    servicecode: '999332',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 489,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services with accommodation',
    groupcode: '99933',
    service:
      'Residential care services for adults suffering from mental retardation, mental health illnesses or substance abuse',
    servicecode: '999333',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 490,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services with accommodation',
    groupcode: '99933',
    service: 'Other social services with accommodation for adults',
    servicecode: '999334',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 491,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Social services without accommodation for the elderly and disabled',
    groupcode: '99934',
    service: 'Vocational rehabilitation services',
    servicecode: '999341',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 492,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Social services without accommodation for the elderly and disabled',
    groupcode: '99934',
    service: 'Other social services without accommodation for the elderly and disabled n.e.c.',
    servicecode: '999349',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 493,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services without accommodation',
    groupcode: '99935',
    service: 'Child day-care services',
    servicecode: '999351',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 494,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services without accommodation',
    groupcode: '99935',
    service: 'Guidance and counseling services n.e.c. related to children',
    servicecode: '999352',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 495,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services without accommodation',
    groupcode: '99935',
    service: 'Welfare services without accommodation',
    servicecode: '999353',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 496,
    heading: 'Human health and social care services',
    headingcode: '9993',
    group: 'Other social services without accommodation',
    groupcode: '99935',
    service: 'Other social services without accommodation n.e.c.',
    servicecode: '999359',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 497,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Sewerage, sewage treatment and septic tank cleaning services',
    groupcode: '99941',
    service: 'Sewerage and sewage treatment services',
    servicecode: '999411',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 498,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Sewerage, sewage treatment and septic tank cleaning services',
    groupcode: '99941',
    service: 'Septic tank emptying and cleaning services',
    servicecode: '999412',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 499,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Waste collection services',
    groupcode: '99942',
    service: 'Collection services of hazardous waste',
    servicecode: '999421',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 500,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Waste collection services',
    groupcode: '99942',
    service: 'Collection services of non-hazardous recyclable materials',
    servicecode: '999422',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 501,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Waste collection services',
    groupcode: '99942',
    service: 'General waste collection services, residential',
    servicecode: '999423',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 502,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Waste collection services',
    groupcode: '99942',
    service: 'General waste collection services, other n.e.c.',
    servicecode: '999424',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 503,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Waste treatment and disposal services',
    groupcode: '99943',
    service: 'Waste preparation, consolidation and storage services',
    servicecode: '999431',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 504,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Waste treatment and disposal services',
    groupcode: '99943',
    service: 'Hazardous waste treatment and disposal services',
    servicecode: '999432',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 505,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Waste treatment and disposal services',
    groupcode: '99943',
    service: 'Non-hazardous waste treatment and disposal services',
    servicecode: '999433',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 506,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Remediation services',
    groupcode: '99944',
    service: 'Site remediation and clean-up services',
    servicecode: '999441',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 507,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Remediation services',
    groupcode: '99944',
    service: 'Containment, control and monitoring services and other site remediation services',
    servicecode: '999442',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 508,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Remediation services',
    groupcode: '99944',
    service: 'Building remediation services',
    servicecode: '999443',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 509,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Remediation services',
    groupcode: '99944',
    service: 'Other remediation services n.e.c.',
    servicecode: '999449',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 510,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Sanitation and similar services',
    groupcode: '99945',
    service: 'Sweeping and snow removal services',
    servicecode: '999451',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 511,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Sanitation and similar services',
    groupcode: '99945',
    service: 'Other sanitation services n.e.c.',
    servicecode: '999459',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 512,
    heading: 'Sewage and waste collection, treatment and disposal and other environmental protection services',
    headingcode: '9994',
    group: 'Others',
    groupcode: '99949',
    service: 'Other environmental protection services n.e.c.',
    servicecode: '999490',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 513,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by business, employers and professional organizations Services',
    groupcode: '99951',
    service: 'Services furnished by business and employers organizations',
    servicecode: '999511',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 514,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: '',
    groupcode: '',
    service: 'Services furnished by professional organizations',
    servicecode: '999512',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 515,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: '',
    groupcode: '',
    service: 'Botanical, zoological and nature reserve services',
    servicecode: '999642',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 516,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by trade unions',
    groupcode: '99952',
    service: 'Services furnished by trade unions',
    servicecode: '999520',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 517,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Religious services',
    servicecode: '999591',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 518,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Services furnished by political organizations',
    servicecode: '999592',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 519,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Services furnished by human rights organizations',
    servicecode: '999593',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 520,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Cultural and recreational associations',
    servicecode: '999594',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 521,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Services furnished by environmental advocacy groups',
    servicecode: '999595',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 522,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Services provided by youth associations',
    servicecode: '999596',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 523,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Other civic and social organizations',
    servicecode: '999597',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 524,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Home owners associations',
    servicecode: '999598',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 525,
    heading: 'Services of membership organizations',
    headingcode: '9995',
    group: 'Services furnished by other membership organizations',
    groupcode: '99959',
    service: 'Services provided by other membership organizations n.e.c.',
    servicecode: '999599',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 526,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Audiovisual and related services',
    groupcode: '99961',
    service: 'Sound recording services',
    servicecode: '999611',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 527,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Audiovisual and related services',
    groupcode: '99961',
    service: 'Motion picture, videotape, television and radio programme production services',
    servicecode: '999612',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 528,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Audiovisual and related services',
    groupcode: '99961',
    service: 'Audiovisual post-production services',
    servicecode: '999613',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 529,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Audiovisual and related services',
    groupcode: '99961',
    service: 'Motion picture, videotape and television programme distribution services',
    servicecode: '999614',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 530,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Audiovisual and related services',
    groupcode: '99961',
    service: 'Motion picture projection services',
    servicecode: '999615',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 531,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Performing arts and other live entertainment event presentation and promotion services',
    groupcode: '99962',
    service: 'Performing arts event promotion and organization services',
    servicecode: '999621',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 532,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Performing arts and other live entertainment event presentation and promotion services',
    groupcode: '99962',
    service: 'Performing arts event production and presentation services',
    servicecode: '999622',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 533,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Performing arts and other live entertainment event presentation and promotion services',
    groupcode: '99962',
    service: 'Performing arts facility operation services',
    servicecode: '999623',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 534,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Performing arts and other live entertainment event presentation and promotion services',
    groupcode: '99962',
    service: 'Other performing arts and live entertainment services n.e.c.',
    servicecode: '999629',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 535,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Services of performing and other artists',
    groupcode: '99963',
    service:
      'Services of performing artists including actors, readers, musicians, singers, dancers, TV personalities, independent models etc',
    servicecode: '999631',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 536,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Services of performing and other artists',
    groupcode: '99963',
    service: 'Services of authors, composers, sculptors and other artists, except performing artists',
    servicecode: '999632',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 537,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Services of performing and other artists',
    groupcode: '99963',
    service: 'Original works of authors, composers and other artists except performing artists, painters and sculptors',
    servicecode: '999633',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 538,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Museum and preservation services',
    groupcode: '99964',
    service: 'Museum and preservation services of historical sites and buildings',
    servicecode: '999641',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 539,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Sports and recreational sports services',
    groupcode: '99965',
    service: 'Sports and recreational sports event promotion and organization services',
    servicecode: '999651',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 540,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Sports and recreational sports services',
    groupcode: '99965',
    service: 'Sports and recreational sports facility operation services',
    servicecode: '999652',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 541,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Sports and recreational sports services',
    groupcode: '99965',
    service: 'Other sports and recreational sports services n.e.c.',
    servicecode: '999659',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 542,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Services of athletes and related support services',
    groupcode: '99966',
    service: 'Services of athletes',
    servicecode: '999661',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 543,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Services of athletes and related support services',
    groupcode: '99966',
    service: 'Support services related to sports and recreation',
    servicecode: '999662',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 544,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Other amusement and recreational services',
    groupcode: '99969',
    service: 'Amusement park and similar attraction services',
    servicecode: '999691',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 545,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Other amusement and recreational services',
    groupcode: '99969',
    service: 'Gambling and betting services including similar online services',
    servicecode: '999692',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 546,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Other amusement and recreational services',
    groupcode: '99969',
    service: 'Coin-operated amusement machine services',
    servicecode: '999693',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 547,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Other amusement and recreational services',
    groupcode: '99969',
    service: 'Lottery services',
    servicecode: '999694',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 548,
    heading: 'Recreational, cultural and sporting services',
    headingcode: '9996',
    group: 'Other amusement and recreational services',
    groupcode: '99969',
    service: 'Other recreation and amusement services n.e.c.',
    servicecode: '999699',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 549,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Washing, cleaning and dyeing services',
    groupcode: '99971',
    service: 'Coin-operated laundry services',
    servicecode: '999711',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 550,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Washing, cleaning and dyeing services',
    groupcode: '99971',
    service: 'Dry-cleaning services (including fur product cleaning services)',
    servicecode: '999712',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 551,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Washing, cleaning and dyeing services',
    groupcode: '99971',
    service: 'Other textile cleaning services',
    servicecode: '999713',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 552,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Washing, cleaning and dyeing services',
    groupcode: '99971',
    service: 'Pressing services',
    servicecode: '999714',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 553,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Washing, cleaning and dyeing services',
    groupcode: '99971',
    service: 'Dyeing and colouring services',
    servicecode: '999715',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 554,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Washing, cleaning and dyeing services',
    groupcode: '99971',
    service: 'Other washing, cleaning and dyeing services n.e.c',
    servicecode: '999719',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 555,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Beauty and physical well-being services',
    groupcode: '99972',
    service: 'Hairdressing and barbers services',
    servicecode: '999721',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 556,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Beauty and physical well-being services',
    groupcode: '99972',
    service: 'Cosmetic treatment (including cosmetic/plastic surgery), manicuring and pedicuring services',
    servicecode: '999722',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 557,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Beauty and physical well-being services',
    groupcode: '99972',
    service: 'Physical well-being services including health club & fitness centre',
    servicecode: '999723',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 558,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Beauty and physical well-being services',
    groupcode: '99972',
    service: 'Other beauty treatment services n.e.c.',
    servicecode: '999729',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 559,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Funeral, cremation and undertaking services',
    groupcode: '99973',
    service: 'Cemeteries and cremation services',
    servicecode: '999731',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 560,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Funeral, cremation and undertaking services',
    groupcode: '99973',
    service: 'Undertaking services',
    servicecode: '999732',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 561,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Other miscellaneous services',
    groupcode: '99979',
    service: 'Services involving commercial use or exploitation of any event',
    servicecode: '999791',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 562,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Other miscellaneous services',
    groupcode: '99979',
    service: 'Agreeing to do an act',
    servicecode: '999792',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 563,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Other miscellaneous services',
    groupcode: '99979',
    service: 'Agreeing to refrain from doing an act',
    servicecode: '999793',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 564,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Other miscellaneous services',
    groupcode: '99979',
    service: 'Agreeing to tolerate an act',
    servicecode: '999794',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 565,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Other miscellaneous services',
    groupcode: '99979',
    service: 'Conduct of religious ceremonies/rituals by persons',
    servicecode: '999795',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 566,
    heading: 'Other services',
    headingcode: '9997',
    group: 'Other miscellaneous services',
    groupcode: '99979',
    service: 'Other services n.e.c.',
    servicecode: '999799',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 567,
    heading: 'Domestic services',
    headingcode: '9998',
    group: 'Domestic services',
    groupcode: '99980',
    service: 'Domestic services both part time & full time',
    servicecode: '999800',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 568,
    heading: 'Domestic services',
    headingcode: '9998',
    group: 'Services provided by extraterritorial organizations and bodies.',
    groupcode: '9999',
    service: 'Services provided by extraterritorial organizations and bodies',
    servicecode: '999900',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
  {
    id: 569,
    heading: null,
    headingcode: null,
    group: null,
    groupcode: null,
    service: 'Pre GST Invoice',
    servicecode: '000000',
    taxrate: 0.0,
    isnilrated: false,
    usemodifiedrate: false,
    isCessApplicable: false,
    cessPercent: 0.0,
    cesstype: null,
  },
];

export default serviceData;
