import React, { Component } from "react";
import { Row } from "reactstrap";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import FetchServerData from "../../../provider/FetchServerData";
import masterDataAction from "../../../redux/masterData/masterDataAction";
export default class LedgerManagement extends Component {
    header = store.getState().header.header;
    breadCrumb = [{ pageid: "/ledgerMgmt", uiname: "Ledger Management", cssclass: "menu-icon tf-icons fas fa-book" }];
    title = "Ledger Management";
    constructor(props) {
        super(props);
        if (this.props.location && this.props.location.pathname) {
            let menu = Utils.getMenu(this.props.location.pathname);
            if (menu && menu.uiname && menu.uiname.length > 0) {
                this.title = menu.uiname;
            }
        }
        let activeMenu = store.getState().appData.activeMenu;
        let menuArray = Utils.getSubMenus(activeMenu);
        this.state = {
            menuArray: menuArray,
            render: true,
        };
    }
    componentDidMount() {
        const postObject = {
            header: this.header,
        };
        FetchServerData.callPostService("/masterms/getMasterData", postObject).then(
            (output) => {
                if (output.status === "SUCCESS") {
                    let masterData = output.data;
                    store.dispatch(masterDataAction.setMasterData(masterData));
                    this.setState({ render: true });
                }
            }
        );
    }

    render() {
        if (this.state.render === false) {
            return "";
        } else {
            return (
                <>
                    <MenuWithBreadScrum
                        breadCrumb={this.breadCrumb}
                        history={this.props.history}
                        name={this.title}
                    />
                    <div style={{ justifyContent: "center", margin: "0 auto" }}>
                        <Row
                            md={12}
                            style={{ paddingTop: "3em", margin: "0 auto", width: "100%" }}
                        >
                            {UiUtils.getUiMenuArray(
                                this.state.menuArray,
                                this.props.history,
                                this.state.color,
                                this.breadCrumb
                            )}
                        </Row>
                    </div>
                </>
            );
        }
    }
}
