import React, { Component } from "react";
import { Row } from "reactstrap";
import UiUtils from "../../../provider/UiUtils";
// import './FinancialAnalysis.scss';
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";

export default class FinancialAnaylsis extends Component {
  breadCrumb = [];
  title = "Financial Analysis";
  constructor(props) {
    super(props);
    if (this.props.location && this.props.location.pathname) {
      let menu = Utils.getMenu(this.props.location.pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    let activeMenu = store.getState().appData.activeMenu;
    let menuArray = Utils.getSubMenus(activeMenu);

    this.state = {
      menuArray: menuArray,
      render: true,
    };
  }

  componentDidMount() { }
  render() {
    if (Utils.isNull(this.breadCrumb) || this.breadCrumb.length <= 0) {
      return;
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />

        <div style={{ justifyContent: "center", margin: "0 auto" }}>
          <Row
            md={12}
            style={{ paddingTop: "3em", margin: "0 auto", width: "100%" }}
          >
            {UiUtils.getUiMenuArray(
              this.state.menuArray,
              this.props.history,
              this.state.color,
              this.breadCrumb
            )}
          </Row>
        </div>
      </>
    );
  }
}
