import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import RemoveIconButton from "../../buttons/RemoveIconButton";
import PtsAlert from "../../ptsAlert/PtsAlert";

export default class RefundVoucherItem extends Component {
  formRef = React.createRef();
  expenseCategoryList = store.getState().appData.expenseCategoryList;
  taxRateList = store.getState().staticData.taxRateList;
  uomList = store.getState().staticData.uomList;
  products = store.getState().master.masterData.products;
  // custHsnCodeList = store.getState().master.masterData.custhsncodes;

  constructor(props) {
    super(props);
    let action = props.action;
    let item = props.data;
    let productList = [];
    for (let i = 0; i < this.products.length; i++) {
      const element = this.products[i];
      console.log(element);
      if (element.producttype?.type !== "Purchase") {
        productList.push(element);
      }
    }

    this.state = {
      isIgstApplicable: props.isIgstApplicable,
      rowBackgroundColor: props.backgroundColor.background,
      itemsrno: item.itemsrno,
      index: props.srno,
      id: item.id,
      description: item.description,
      name: item.name,
      quantity: item.quantity,
      productcode: item.productcode,
      rate: item.rate,
      total: item.total,
      uuid: item.uuid,
      igst: item.igst,
      sgst: item.sgst,
      cgst: item.cgst,
      utgst: item.utgst,
      discount: item.discount,
      taxable: item.taxable,
      nontaxable: item.nontaxable,
      refinvitemamount: item.refinvitemamount,
      uom: item.uom,
      suprcmhsncode: item.suprcmhsncode,
      refinvitem: item.refinvitem,
      invoiceitemnumber: item.invoiceitemnumbe,
      cess: item.cess,
      taxrate: Utils.isNull(item.taxrate)
        ? this.getZeroTaxRate()
        : item.taxrate,
      custhsncode: item.custhsncode,
      operation: item.operation,
      version: item.version,
      onDelete: props.onDelete,
      onChange: props.onChange,
      doCalculations: props.doCalculations,
      action: action,
      render: true,
      isDelete: false,
      productList: productList,
    };
  }

  getZeroTaxRate() {
    for (let i = 0; i < this.taxRateList.length; i++) {
      if (parseFloat(this.taxRateList[i].rate) === 0) {
        return this.taxRateList[i];
      }
    }
    return null;
  }

  getFirstCustHsnCode() {
    if (this.custHsnCodeList.length > 0) {
      return this.custHsnCodeList[0];
    }
    return null;
  }
  updateRefundVoucherItemCategory(e, name) {
    this.setState({ expensecategory: name });
  }

  doCalculations() {
    let quantity = Utils.getFloatValue(this.state.quantity);
    if (quantity === 0.0) {
      return;
    }

    let rate = Utils.getFloatValue(this.state.rate);
    if (rate === 0.0) {
      return;
    }

    let valueOfSupply = parseFloat(quantity) * parseFloat(rate);
    let discount = Utils.getFloatValue(this.state.discount);
    let discountAmount = 0;
    if (discount > 0) {
      discountAmount = (parseFloat(valueOfSupply) * parseFloat(discount)) / 100;
    }

    let taxable = parseFloat(valueOfSupply) - parseFloat(discountAmount);
    if (taxable === 0.0) {
      return;
    }
    let cess = Utils.getFloatValue(this.state.cess);
    let cessAmount = 0.0;
    if (cess > 0) {
      cessAmount = (parseFloat(taxable) * parseFloat(cess)) / 100;
    }
    let taxrate = Utils.getFloatValue(this.state.taxrate.rate);
    let cgst = 0.0;
    let sgst = 0.0;
    let igst = 0.0;
    let isIgstApplicable = this.state.isIgstApplicable;
    if (isIgstApplicable) {
      igst = (parseFloat(taxrate) * parseFloat(taxable)) / 100;
      igst = igst.toFixed(2);
    } else {
      let newRate = parseFloat(taxrate) / 2;
      cgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      cgst = cgst.toFixed(2);
      sgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      sgst = sgst.toFixed(2);
    }
    let total =
      parseFloat(taxable) +
      parseFloat(cgst) +
      parseFloat(sgst) +
      parseFloat(igst) +
      parseFloat(cessAmount);
    total = parseFloat(total).toFixed(2);

    this.setState({
      taxable: parseFloat(taxable).toFixed(2),
      cgst: parseFloat(cgst).toFixed(2),
      sgst: parseFloat(sgst).toFixed(2),
      igst: parseFloat(igst).toFixed(2),
      total: parseFloat(total).toFixed(2),
    });

    if (this.state.doCalculations) {
      this.state.taxable = parseFloat(taxable).toFixed(2);
      this.state.cgst = parseFloat(cgst).toFixed(2);
      this.state.sgst = parseFloat(sgst).toFixed(2);
      this.state.igst = parseFloat(igst).toFixed(2);
      this.state.cess = parseFloat(cess).toFixed(2);
      this.state.total = parseFloat(total).toFixed(2);
      this.state.doCalculations();
    }
  }
  handleNameChange(e, product) {
    let val = e.target.value;
    for (let i = 0; i < this.state.productList.length; i++) {
      let product = this.state.productList[i];
      let prodDetails = product.name + "-" + product.code;
      if (Utils.equalsIgnoreCase(val, prodDetails)) {
        let hsnCode = product.custhsncode;
        let taxrate = product.taxrate;
        let uom = product.uom;
        let quantity = 1;
        let discount = 0;
        let cess = 0;
        let rate = product.rate;
        this.state.quantity = quantity;
        this.state.cess = cess;
        this.state.uom = uom;
        this.state.taxable = rate;
        this.state.custhsncode = hsnCode;
        this.state.taxrate = taxrate;
        this.state.rate = rate;
        this.setState({
          uom: uom,
          name: product.name,
          productcode: product.code,
          rate: rate,
          cess: cess,
          quantity: quantity,
          discount: discount,
          custhsncode: hsnCode,
          taxrate: taxrate,
          taxable: rate,
        });
        this.doCalculations();
        return;
      }
    }
    this.setState({ name: val });
  }

  handleTaxableAmtChange(e) {
    let taxableAmt = e.target.value;
    if (!taxableAmt || taxableAmt.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ taxable: taxableAmt }));
    }
  }
  handleTaxableAmtBlurChange(e) {
    this.doCalculations();
  }
  handleQuantityChange(e) {
    let qty = e.target.value;
    if (!qty || qty.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ quantity: qty }));
    }
  }
  handleQuantityBlurChange(e) {
    this.doCalculations();
  }

  handleRateChange(e) {
    let rate = e.target.value;
    if (!rate || rate.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ rate: rate }));
    }
  }
  handleRateBlurChange(e) {
    this.doCalculations();
  }

  handleCessChange(e) {
    let cess = e.target.value;
    if (!cess || cess.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ cess: cess }));
    }
  }
  handleCessBlurChange(e) {
    this.doCalculations();
  }
  handleDiscountChange(e) {
    let discount = e.target.value;
    if (!discount || discount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ discount: discount }));
    }
  }
  handleDiscountBlurChange(e) {
    this.doCalculations();
  }
  handleAmountChange(e) {
    let amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ amount: amount }));
    }
  }
  handleAmountBlurChange(e) {
    let val = Number(e.target.value).toFixed(2);
    this.state.amount = val;
    this.setState({ amount: val });
    if (this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleSgstChange(e) {
    let sgst = e.target.value;
    if (!sgst || sgst.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ sgst: sgst }));
    }
  }

  handleCgstChange(e) {
    let cgst = e.target.value;
    if (!cgst || cgst.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ cgst: cgst }));
    }
  }

  handleIgstChange(e) {
    let igst = e.target.value;
    if (!igst || igst.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ igst: igst }));
    }
  }

  deleteRefundVoucherItem(e) {
    if (this.state.onDelete) {
      this.state.isDelete = true;
      this.state.onDelete(this.state);
    }
  }

  validateItem() {
    let custhsncode = this.state.custhsncode;
    let name = this.state.name;
    let taxable = this.state.taxable;
    let cess = this.state.cess;
    let taxrate = this.state.taxrate;
    // let amount = this.state.amount;
    if (Utils.isNullOrEmpty(custhsncode)) {
      document.getElementById("custhsncode" + this.state.index).focus();
      PtsAlert.error("Please Select Item SAC/HSN Code ");
      return false;
    }
    if (Utils.isNullOrEmpty(name)) {
      document.getElementById("name" + this.state.index).focus();
      PtsAlert.error("Please Enter Item Description");
      return false;
    }
    if (Utils.isNullOrEmpty(taxable)) {
      document.getElementById("taxable" + this.state.index).focus();
      PtsAlert.error("Please Enter Taxable Amount");
      return false;
    }
    if (Utils.isNullOrEmpty(cess)) {
      document.getElementById("cess" + this.state.index).focus();
      PtsAlert.error("Please Enter CESS Percentage");
      return false;
    }
    if (Utils.isNullOrEmpty(taxrate)) {
      document.getElementById("taxrate" + this.state.index).focus();
      PtsAlert.error("Please Select Tax Rate ");
      return false;
    }
    return true;
  }

  updateCustHsnCode(e, custhsn) {
    this.setState({ custhsncode: custhsn });
  }

  updateTaxRate(e, taxrate) {
    this.state.taxrate = taxrate;
    this.setState({ taxrate: taxrate });
    this.doCalculations();
  }
  updateUOM(e, uom) {
    this.setState({ uom: uom });
  }
  render() {
    if (this.state.render === false) {
      return "";
    }
    this.state.isIgstApplicable = this.props.isIgstApplicable;
    if (this.state.action === "edit") {
      return this.editRefundVoucherItem();
    } else {
      return this.viewRefundVoucherItem();
    }
  }

  editRefundVoucherItem() {
    return (
      // <ValidatorForm ref={this.formRef}>
      <div
        style={{
          border: "1px solid #e4dede",
          background: this.state.rowBackgroundColor,
        }}
      >
        <Row>
          <Col md={6} style={{ display: "block" }}>
            <span style={{ padding: "0.5em", height: "100% !important" }}>
              {this.state.itemsrno}
            </span>
          </Col>
          <Col md={6} style={{ display: "block" }}>
            <RemoveIconButton
              onClick={this.deleteRefundVoucherItem.bind(this)}
            />
          </Col>
        </Row>
        <Row
          md={12}
          style={{
            margin: "5px",
            padding: "0",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Col md={4} style={{ display: "block", alignItems: "center" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Item Details<span className="asterisk"> *</span>
            </label>
            <input
              style={{
                width: "100%",
                padding: "0.6em",
                marginTop: "-4px",
                // marginLeft: "5px"
              }}
              type="text"
              placeholder="Item Details"
              id={"name" + this.state.index}
              list="data"
              value={this.state.name}
              onChange={(event, value) => {
                this.handleNameChange(event, value);
              }}
            />
            <datalist id="data">
              {this.state.productList.map((item, key) => (
                <option key={item.id} value={item.name + "-" + item.code} />
              ))}
            </datalist>
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              SAC/HSN<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              required
              disableClearable
              ListboxProps={{ className: "myCustomList" }}
              // style={{ marginRight: "-0.5em" }}
              id={"custhsncode" + this.state.index}
              options={this.props.custHsnCodeList}
              value={this.state.custhsncode ? this.state.custhsncode : null}
              getOptionLabel={(option) => option.code}
              onChange={(event, value) => {
                this.updateCustHsnCode(event, value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    style={{}}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    placeholder="SAC/HSN"
                    className="regCountry"
                  />
                );
              }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              UOM<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              required
              disableClearable
              ListboxProps={{ className: "myCustomList" }}
              // style={{ margin: "0.5em", marginRight: "-0.5em" }}
              id={"uom" + this.state.index}
              options={this.uomList}
              value={this.state.uom ? this.state.uom : null}
              getOptionLabel={(option) => option.nameCode}
              onChange={(event, value) => {
                this.updateUOM(event, value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="regCountry"
                    placeholder="UOM"
                    style={{ textAlign: "right" }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                );
              }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Qty<span className="asterisk"> *</span>
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              required
              variant="outlined"
              placeholder="QTY"
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              id={"quantity" + this.state.index}
              type="number"
              onChange={this.handleQuantityChange.bind(this)}
              onBlur={this.handleQuantityBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.quantity}
              name={"quantity" + this.state.index}
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Unit Price<span className="asterisk"> *</span>
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              required
              variant="outlined"
              placeholder="Unit Price"
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              id={"rate" + this.state.index}
              type="text"
              onChange={this.handleRateChange.bind(this)}
              onBlur={this.handleRateBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.rate}
              name={"rate" + this.state.index}
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Value Of Supply
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              disabled
              className="disableFiled"
              variant="outlined"
              placeholder="Value Of Supply"
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              id={"supplyValue" + this.state.index}
              type="text"
              value={
                parseFloat(this.state.quantity) *
                parseFloat(this.state.rate).toFixed(2)
              }
              name={"supplyValue" + this.state.index}
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Discount (%)
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              variant="outlined"
              placeholder="Discount (%)"
              id={"discount" + this.state.discount}
              type="text"
              onChange={this.handleDiscountChange.bind(this)}
              onBlur={this.handleDiscountBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.discount}
              name={"discount" + this.state.discount}
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Net Value Of Supply
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              variant="outlined"
              placeholder="Net Value Of Supply"
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              id={"taxable" + this.state.index}
              type="text"
              onChange={this.handleTaxableAmtChange.bind(this)}
              onBlur={this.handleTaxableAmtBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.taxable}
              name="taxable"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              CESS %
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              variant="outlined"
              placeholder="CESS %"
              id={"cess" + this.state.cess}
              type="text"
              onChange={this.handleCessChange.bind(this)}
              onBlur={this.handleCessBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.cess}
              name="cess"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Tax Rate %<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              required
              disableClearable
              placeholder="Tax Rate %"
              ListboxProps={{ className: "myCustomList" }}
              // style={{ margin: "0.5em", marginRight: "-0.5em" }}
              id={"taxrate" + this.state.index}
              options={this.taxRateList}
              value={this.state.taxrate}
              getOptionLabel={(option) => option.rate + "%"}
              onChange={(event, value) => {
                this.updateTaxRate(event, value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="regCountry"
                    placeholder="Tax Rate %"
                    style={{ textAlign: "right" }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                );
              }}
            />
          </Col>

          {this.state.isIgstApplicable === false && (
            <>
              <Col md={2} style={{ display: "block" }}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  CGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{
                    min: 0,
                    style: { textAlign: "right", border: "0" },
                  }}
                  fullWidth
                  disabled
                  className="disableFiled"
                  variant="outlined"
                  placeholder="CGST"
                  id={"cgst" + this.state.index}
                  type="text"
                  onChange={this.handleCgstChange.bind(this)}
                  // value={this.state.cgst}
                  value={
                    isNaN(parseFloat(this.state.cgst).toFixed(2))
                      ? 0
                      : parseFloat(this.state.cgst).toFixed(2)
                  }
                  name="cgst"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={2} style={{ display: "block" }}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  SGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  disabled
                  className="disableFiled"
                  variant="outlined"
                  placeholder="SGST"
                  id={"sgst" + this.state.index}
                  type="text"
                  onChange={this.handleSgstChange.bind(this)}
                  // value={this.state.sgst}
                  value={
                    isNaN(parseFloat(this.state.sgst).toFixed(2))
                      ? 0
                      : parseFloat(this.state.sgst).toFixed(2)
                  }
                  name="sgst"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={2} style={{ alignSelf: "flex-end" }}>
                <div style={{ alignItems: "center" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Total
                  </label>
                  <TextField
                    // style={{ margin: "0.5em" }}
                    inputProps={{ min: 0, style: { textAlign: "right" } }}
                    fullWidth
                    variant="outlined"
                    placeholder="Total"
                    disabled
                    className="disableFiled"
                    id={"total" + this.state.index}
                    type="text"
                    onChange={this.handleAmountChange.bind(this)}
                    onBlur={this.handleAmountBlurChange.bind(this)}
                    // value={this.state.total}
                    value={
                      isNaN(parseFloat(this.state.total).toFixed(2))
                        ? parseFloat(this.state.rate).toFixed(2)
                        : parseFloat(this.state.total).toFixed(2)
                    }
                    name="total"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Col>
            </>
          )}
          {this.state.isIgstApplicable === true && (
            <>
              <Col Col md={2} style={{ display: "block" }}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  IGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  disabled
                  className="disableFiled"
                  variant="outlined"
                  placeholder="IGST"
                  id={"igst" + this.state.index}
                  type="text"
                  onChange={this.handleIgstChange.bind(this)}
                  // value={this.state.igst}
                  value={
                    isNaN(parseFloat(this.state.igst).toFixed(2))
                      ? 0
                      : parseFloat(this.state.igst).toFixed(2)
                  }
                  name="igst"
                  InputLabelProps={{ shrink: true }}
                />
              </Col>
              <Col md={2} style={{ alignSelf: "flex-end" }}>
                <div style={{ alignItems: "center" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Total
                  </label>
                  <TextField
                    // style={{ margin: "0.5em" }}
                    inputProps={{ min: 0, style: { textAlign: "right" } }}
                    fullWidth
                    variant="outlined"
                    placeholder="Total"
                    disabled
                    className="disableFiled"
                    id={"total" + this.state.index}
                    type="text"
                    onChange={this.handleAmountChange.bind(this)}
                    onBlur={this.handleAmountBlurChange.bind(this)}
                    value={
                      isNaN(parseFloat(this.state.total).toFixed(2))
                        ? parseFloat(this.state.rate).toFixed(2)
                        : parseFloat(this.state.total).toFixed(2)
                    }
                    name="total"
                    InputLabelProps={{ shrink: true }}
                  />
                  {/* <a onClick={this.deleteRefundVoucherItem.bind(this)} style={{ cursor: 'pointer', float: 'right' }}>
                      <i class="fa fa-remove fa-lg" style={{ color: '#cc5050' }}></i>
                    </a> */}
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row
          md={12}
          style={{
            margin: "5px",
            padding: "0",
            alignItems: "center",
            display: "flex",
          }}
        ></Row>
      </div>
      // </ValidatorForm>
    );
  }

  viewRefundVoucherItem() {
    return (
      <>
        {/* <Row
          md={12}
          style={{
            padding: "5px",
            margin: "0",
            // background: this.state.rowBackgroundColor,
          }}
        >
          <Col
            md={1}
            style={{ border: "0.5px solid #ece6e6", padding: "0.5em" }}
          >
            <span style={{ float: "left" }}>{this.state.index}</span>
            <span style={{ float: "right" }}>
              {this.state.custhsncode.code}
            </span>
          </Col>
          <Col
            md={1}
            style={{ border: "0.5px solid #ece6e6", padding: "0.5em" }}
          >
            {this.state.name}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {this.state.uom.name}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {this.state.quantity}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {this.state.rate}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {Utils.getFloatValue(
              parseFloat(this.state.quantity) * parseFloat(this.state.rate)
            )}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {Utils.getFloatValue(this.state.discount)}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {Utils.getFloatValue(this.state.taxable)}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {Utils.getFloatValue(this.state.cess)}
          </Col>
          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {this.state.taxrate.rate}
          </Col>
          {this.state.isIgstApplicable === false && (
            <>
              <Col
                md={1}
                style={{
                  border: "0.5px solid #ece6e6",
                  padding: "0.5em",
                  textAlign: "right",
                }}
              >
                {parseFloat(this.state.cgst) + parseFloat(this.state.sgst)}
              </Col>
            </>
          )}
          {this.state.isIgstApplicable === true && (
            <Col
              md={1}
              style={{
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              {Utils.getFloatValue(this.state.igst)}
            </Col>
          )}

          <Col
            md={1}
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
            }}
          >
            {Utils.getFloatValue(this.state.total)}
          </Col>
        </Row> */}
        <tr
          style={{
            padding: "5px",
            margin: "0",
            // background: this.state.rowBackgroundColor,
          }}
        >
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              width: "10%",
            }}
          >
            <span style={{ float: "left" }}>{this.state.index}</span>
            <span style={{ float: "right" }}>
              {this.state.custhsncode.code}
            </span>
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              width: "20%",
            }}
          >
            {this.state.name}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "10%",
            }}
          >
            {this.state.uom.name}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "3%",
            }}
          >
            {this.state.quantity}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "8%",
            }}
          >
            {this.state.rate}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "10%",
            }}
          >
            {Utils.getFloatValue(
              parseFloat(this.state.quantity) * parseFloat(this.state.rate)
            )}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "10%",
            }}
          >
            {Utils.getFloatValue(this.state.discount)}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "10%",
            }}
          >
            {Utils.getFloatValue(this.state.taxable)}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "10%",
            }}
          >
            {Utils.getFloatValue(this.state.cess)}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "10%",
            }}
          >
            {this.state.taxrate.rate}
          </td>
          {this.state.isIgstApplicable === false && (
            <>
              <td
                style={{
                  border: "0.5px solid #ece6e6",
                  padding: "0.5em",
                  textAlign: "right",
                  width: "10%",
                }}
              >
                {parseFloat(this.state.cgst) + parseFloat(this.state.sgst)}
              </td>
            </>
          )}
          {this.state.isIgstApplicable === true && (
            <td
              style={{
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
                width: "10%",
              }}
            >
              {Utils.getFloatValue(this.state.igst)}
            </td>
          )}

          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "10%",
            }}
          >
            {Utils.getFloatValue(this.state.total)}
          </td>
        </tr>
      </>
    );
  }
}
