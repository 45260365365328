import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import store from "../../../redux/store";
import RemoveIconButton from "../buttons/RemoveIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Expense.scss";
import { Col, Row } from "reactstrap";
import AddIconButton from "../buttons/AddIconButton";
export default class Expense extends Component {
  expenseCategoryList = store.getState().appData.expenseCategoryList;
  constructor(props) {
    super(props);

    let action = props.action;

    this.state = {
      id: props.data.id,
      note: props.data.note,
      operation: props.data.operation,
      amount: props.data.amount,
      expensecategory: props.data.expensecategory,
      index: props.srno,
      expenseCategoryList: this.expenseCategoryList,
      onDelete: props.onDelete,
      onChange: props.onChange,
      action: action,
      render: true,
      itemsrno: props.data.itemsrno,
      isDelete: false,
      onExpenseItemAdd: props.onAdd,
    };
  }

  updateExpenseCategory(e, name) {
    this.setState({ expensecategory: name });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    this.setState({ note: val });
  }
  handleDescriptionBlurChange(e) {
    let val = e.target.value;
    this.setState({ note: val });
  }
  handleAmountChange(e) {
    let amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ amount: amount }));
    }
  }
  handleAmountBlurChange(e) {
    let val = Number(e.target.value).toFixed(2);
    this.state.amount = val;
    this.setState({ amount: val });
    if (this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  // deleteExpenseItem(e) {
  //   if (this.state.onDelete) {
  //     this.state.onDelete(this.state);
  //   }
  // }
  deleteExpenseItem(e) {
    if (this.state.onDelete) {
      this.state.isDelete = true;
      this.state.onDelete(this.state);
    }
  }

  validateItem() {
    let category = this.state.expensecategory;
    // let note = this.state.note;
    let amount = this.state.amount;
    if (!category) {
      document.getElementById("expenseCategory" + this.state.index).focus();
      PtsAlert.error("Please Select Category ");
      return false;
    }
    if (!amount || "" === amount) {
      document.getElementById("amount" + this.state.index).focus();
      PtsAlert.error("Please Enter Amount");
      return false;
    }
    return true;
  }

  addExpenseItem(e) {
    if (
      undefined !== this.state.onExpenseItemAdd &&
      null !== this.state.onExpenseItemAdd
    ) {
      this.state.onExpenseItemAdd(this.state);
    }
  }
  render() {
    if (this.state.render === false) {
      return "";
    }

    if (this.state.action === "edit") {
      return this.editExpense();
    } else {
      return this.viewExpense();
    }
  }

  editExpense() {
    return (
      <div
        style={{
          border: "1px solid #e4dede",
          background: this.state.rowBackgroundColor,
        }}
      >
        <Row>
          <Col md={6} style={{ display: "block" }}>
            <span style={{ padding: "0.5em", height: "100% !important" }}>
              <b>{this.state.itemsrno}</b>
            </span>
          </Col>
          <Col md={6}>
            <RemoveIconButton onClick={this.deleteExpenseItem.bind(this)} />
            &nbsp;&nbsp;
            {this.props.iteamIndex === this.props.expenseItems.length - 1 && (
              <AddIconButton onClick={this.addExpenseItem.bind(this)} />
            )}
            &nbsp;&nbsp;
          </Col>
        </Row>
        <Row
          md={12}
          style={{
            margin: 0,
            padding: "1em 0.5em",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <Col md={2}>{this.state.itemsrno}</Col> */}
          <Col md={4} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Category<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              ListboxProps={{
                className: "myCustomList",
                style: { maxHeight: 200, overflow: "auto" },
              }}
              required
              disableClearable
              id={"expenseCategory" + this.state.index}
              label=" "
              options={this.state.expenseCategoryList}
              value={this.state.expensecategory}
              getOptionLabel={(option) => option.type}
              onChange={(event, value) => {
                this.updateExpenseCategory(event, value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    className="regCountry"
                  />
                );
              }}
            />
          </Col>
          <Col md={4} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Note<span className="asterisk"> *</span>
            </label>
            <TextField
              variant="outlined"
              fullWidth
              id={"note" + this.state.index}
              type="text"
              onChange={this.handleDescriptionChange.bind(this)}
              onBlur={this.handleDescriptionBlurChange.bind(this)}
              value={this.state.note}
              name="note"
            />
          </Col>
          <Col
            md={4}
            style={{ display: "block" }}
          // style={{
          //   width: "30%",
          //   border: "0.5px solid #cacaca",
          //   textAlign: "right",
          // }}
          >
            <label className="form-label " htmlFor="collapsible-companyName">
              Amount<span className="asterisk"> *</span>
            </label>
            <TextField
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              variant="outlined"
              required
              id={"amount" + this.state.index}
              type="text"
              onChange={this.handleAmountChange.bind(this)}
              onBlur={this.handleAmountBlurChange.bind(this)}
              value={this.state.amount}
              name="amount"
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </Col>
          {/* <Col
            md={2}
            style={{
              // width: "30%",
              border: "0.5px solid #cacaca",
              textAlign: "left",
            }}
          >
            <DeleteIconButton onClick={this.deleteExpenseItem.bind(this)} />
          </Col> */}
        </Row>
      </div>
    );
  }

  viewExpense() {
    return (
      <>
        <tr
          style={{
            // padding: "5px",
            margin: "0",
            // background: this.state.rowBackgroundColor,
          }}
        >
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              width: "10%",
              textAlign: "left",
            }}
          >
            <span style={{ float: "left" }}>{this.state.index}</span>
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              width: "10%",
              textAlign: "left",
            }}
          >
            <span style={{ float: "left" }}>
              {this.state.expensecategory
                ? this.state.expensecategory.type
                : ""}
            </span>
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              // width: "20%",
              textAlign: "left",
            }}
          >
            {this.state.note}
          </td>
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              // width: "20%",
              textAlign: "right",
            }}
          >
            {this.state.amount}
          </td>
        </tr>
      </>
    );
  }
}
