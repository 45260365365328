import React, { Component } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import "./Profile.scss";
export class Profile extends Component {
  constructor(props) {
    super(props);
    this.subMenuArray = this.props.menu;
    this.state = {
      fname: "",
      mname: "",
      lname: "",
      salutation: "",
      description: "",
      phone: "",
      email: "",
      gender: "",
      designation: "",
      profile: "",
      businessUnit: {},
      appuserType: "",
      registrationType: "",
      accountType: "",
      userName: "",
      CustomerId: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      pinCode: "",
      organizationName: "",
      gstn: "",
      pan: "",
      organizationPhone: "",
      website: "",
      regLine1: "",
      regLine2: "",
      regCity: "",
      regState: "",
      regCountry: "",
      regPinCode: "",
    };
    this.getProfileData();
  }
  getProfileData() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/userms/getProfile", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let profileData = output.data.appuserVo;
          let profileDetails = output.data;
          this.setState({
            // salutation: profileData.salutation,
            profile: profileData.profile?.name,
            fname: profileData.firstName,
            mname: profileData.middleName,
            lname: profileData.lastName,
            email: profileData.email,
            phone: profileData.mobile,
            gender: profileData.gender,
            designation: profileData.designation,
            businessUnit: profileDetails.businessUnit,
            appuserType: profileData.appusertype?.name,
            CustomerId: profileData.tenentvo?.id,
            organizationName: profileDetails.organization?.name,
            gstn: profileDetails.organization?.gstn,
            pan: profileDetails.organization?.panNo,
            organizationPhone: profileDetails.organization?.phone,
            orgaddress: profileDetails.organization?.addressvo,
            website: profileDetails.organization?.website,
            regLine1: profileDetails.organization?.addressvo?.line1,
            regLine2: profileDetails.organization?.addressvo?.line2,
            regCity: profileDetails.organization?.addressvo?.city,
            regState: profileDetails.organization?.addressvo?.statevo?.name,
            regCountry:
              profileDetails.organization?.addressvo?.statevo?.countryvo?.name,
            regPinCode: profileDetails.organization?.addressvo?.pin,
          });
        }
      }
    );
  }
  render() {
    return (
      <>
        <MenuWithBreadScrum name="User Profile" />

        <Row style={{ margin: "1em" }}>
          <Col md={6}>
            <Card
              style={{
                height: "100%",
                boxShadow:
                  "rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 3px 1px 5px 0px, rgb(0 0 0 / 20%) 2px 3px 1px 0px",
              }}
            >
              <CardHeader>
                <strong>User Details</strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Name
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {(this.state.salutation ? this.state.salutation : "") +
                      " " +
                      (this.state.fname ? this.state.fname : "") +
                      " " +
                      (this.state.mname ? this.state.mname : "") +
                      " " +
                      (this.state.lname ? this.state.lname : "")}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Mobile
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.phone}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Email
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.email}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Designation
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.designation}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Profile
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.profile}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Business Unit
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.businessUnit?.name}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={6}>
            <Card
              style={{
                height: "100%",
                boxShadow:
                  "rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 3px 1px 5px 0px, rgb(0 0 0 / 20%) 2px 3px 1px 0px",
              }}
            >
              <CardHeader>
                <strong>Organization Details</strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Name
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.organizationName}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      GSTN
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.gstn}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      PAN
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.pan}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Phone No.
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.organizationPhone}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Website
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {this.state.website}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "600" }}>
                      Registered Address
                    </label>
                  </Col>
                  <Col md={8} style={{}}>
                    {Utils.getAddressAsText(this.state.orgaddress)}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
