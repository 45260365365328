import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RepeatIcon from "@material-ui/icons/Repeat";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Autocomplete, InputAdornment } from "@mui/material";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import FetchServerData from "../../../provider/FetchServerData";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import FileAttachmentList from "../attachments/FileAttachmentList";
import AddIconButton from "../buttons/AddIconButton";
import CancelButton from "../buttons/CancelButton";
import CreateButton from "../buttons/CreateButton";
import SaveButton from "../buttons/SaveButton";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import PaymentTransactionList from "../payments/PaymentTransactionList";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Expense.scss";
import ExpenseItem from "./ExpenseItem";
import ApplicationStateAction from "../../../redux/applicationState/ApplicationStateAction";
import ExpenseDialogue from "./ExpenseDialogue";
export default class Expense extends Component {
  itemReferenceList = [];
  maxDate = new Date().getMonth() + 1;
  minDate = new Date().getFullYear() - 1;
  businessUnits = store.getState().master.masterData.businessUnits;
  expenseSupplierList = store.getState().master.masterData.expenseSuppliers;
  currencyList = store.getState().staticData.currencyList;
  countryList = store.getState().staticData.countryList;
  defaultCountry = store.getState().organization.organization.defaultCountry;
  defaultCurrency = store.getState().organization.organization.defaultCurrency;
  header = store.getState().header.header;
  uploadAttachmentServiceUrl = "/expensedocms/uploadDocument";
  deleteAttachmentServiceUrl = "/expensedocms/delete";
  addPaymentTransactionServiceUrl = "/exppaymenttxms/create";
  updatePaymentTransactionServiceUrl = "/exppaymenttxms/update";
  deletePaymentTransactionServiceUrl = "/exppaymenttxms/delete";
  title = "Expense";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let expenseId = props.expenseId;
    let action = props.action;
    let pageId = props.pageId;
    let isHistory = props.source && props.source === "history" ? true : false;
    let isDuplicate = null;
    let inputExpense = null;
    let expense = null;
    let expenseData = props.expenseData;
    let expenseitems = [];
    let amount = 0;
    let amountinwords = "";
    let addAnotherExpense = false;
    // let expenseSupplierList = [];
    /* Set Data From history */

    if (
      Utils.isNull(expenseId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      expenseId = props.history.location.state.expenseId;
      isDuplicate = props.history.location.state.isDuplicate;
      inputExpense = props.history.location.state.inputExpense;
      action = props.history.location.state.action;
      expense = props.history.location.state.expense;
      expenseData = props.history.location.state.expenseData;
      pageId = props.history.location.state.pageId;
      expenseitems = props.history.location.state.expenseitems;
      amountinwords = props.history.location.state.amountinwords;
      amount = props.history.location.state.amount;
      addAnotherExpense = props.history.location.state?.addAnotherExpense;
      this.breadCrumb = props.history.location.state.breadCrumb;
      isHistory = props.history.location.state.isHistory
        ? props.history.location.state.isHistory
        : false;
    }

    let render = false;
    if (action === "create") {
      render = true;
    }

    this.expenseSupplierList.unshift({
      name: "Add New Supplier",
      id: "Add New Supplier",
    });
    let expenseSupplierListArray = this.expenseSupplierList.reduce(
      (unique, o) => {
        if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      },
      []
    );

    if (pageId !== "expenseSupplier") {
      this.state = {
        id: expenseId,
        pageId: pageId,
        purpose: "",
        expdate: "",
        createdon: null,
        expnumber: "",
        billnumber: "",
        exchangerate: 1,
        amount: 0,
        initialPage: 0,
        pageSize: 10,
        amountinwords: "",
        paymentTransations: [],
        bankaccount: null,
        isAsset: false,
        isSupplier: false,
        isSystemGenerated: false,
        isRecurring: false,
        expstatus: null,
        payment: {},
        currency: null,
        expenseitems: [],
        expenseDocuments: [],
        documentTypes: [],
        privileges: [],
        bu: this.businessUnits[0],
        expenseSupplierList: expenseSupplierListArray,
        supplier: null,
        expenseCurrency: "",
        currencyid: "",
        currencySymbol: "",
        country: "",
        countryid: "",
        action: action,
        render: render,
        isHistory: isHistory,
        addAnotherExpense: addAnotherExpense,
      };

      if (action === "create") {
        if (isDuplicate && isDuplicate === true && inputExpense) {
          /*Create Duplicate Invoice Functionality */
          this.state = inputExpense;
          this.state.action = action;
          this.state.id = null;
          this.state.expdate = null;
          this.state.expnumber = null;
          this.state.initialPage = 0;
          this.state.pageSize = 10;
          this.state.bu = this.businessUnits[0];
          this.state.expenseDocuments = [];
          this.state.paymentTransations = [];
        } else {
          this.addExpenseItem();
        }
        render = true;
      } else {
        this.getExpenseData(this.state.id);
      }
    } else {
      this.state = {
        id: expenseId,
        pageId: pageId,
        purpose: expenseData?.purpose,
        expdate: expenseData?.expdate,
        createdon: null,
        expnumber: expenseData?.expnumber,
        billnumber: expenseData?.billnumber,
        exchangerate: 1,
        initialPage: 0,
        pageSize: 10,
        paymentTransations: [],
        bankaccount: null,
        isAsset: expenseData.isAsset,
        isRecurring: expenseData.isRecurring,
        isSupplier: false,
        isSystemGenerated: false,
        expstatus: null,
        payment: {},
        currency: null,
        expenseitems: expenseitems,
        expenseDocuments: [],
        documentTypes: [],
        privileges: [],
        bu: expenseData.businessUnits,
        expenseSupplierList: expenseSupplierListArray,
        supplier: expense !== null ? expense : null,
        expenseCurrency:
          expense !== null ? expense.country?.currency?.name : "",
        currencyid: expense !== null ? expense.country?.currency?.id : "",
        currencySymbol:
          expense !== null ? expense.country?.currency?.symbol : "",
        country: expense !== null ? expense?.country?.name : "",
        countryid: expense !== null ? expense?.country.id : "",
        action: action,
        render: render,
        isHistory: isHistory,
        amount: amount,
        amountinwords: amountinwords,
        addAnotherExpense: addAnotherExpense,
      };
      this.doCalculations();
    }
  }

  editButtonClicked() {
    this.props.history.push({
      pathname: "/expenseEdit",
      state: {
        expenseId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }

  deleteButtonClicked() {
    this.deleteExpense();
  }

  printButtonClicked() {
    this.props.history.push({
      pathname: "/expensePrint",
      state: {
        expense: this.state,
        action: "edit",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  createSimilarButtonClicked() {
    this.props.history.push({
      pathname: "/expenseCreate",
      state: {
        action: "create",
        isDuplicate: true,
        inputExpense: this.state,
        isHistory: this.state.isHistory,
        breadCrumb: this.breadCrumb,
      },
    });
  }
  deleteExpense() {
    const postObject = {
      expense: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/expensems/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success("Expense Deleted");
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/expense_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/expense/",
              state: { expenseId: this.state.id, action: "view" },
            });
          }
        } else {
        }
      }
    );
  }

  setResponseToState(expense) {
    this.state.render = false;
    this.setState({
      id: expense.id,
      isAsset: expense.isAsset,
      isSupplier: expense.isSupplier,
      isSystemGenerated: expense.isSystemGenerated,
      isRecurring: expense.isRecurring,
      supplier: expense.supplier,
      purpose: expense.purpose,
      expdate: expense.expdate,
      expnumber: expense.expnumber,
      billnumber: expense.billnumber,
      exchangerate: expense.exchangerate,
      amount: expense.amount,
      createdon: expense.createdon,
      amountinwords: expense.amountinwords,
      bu: expense.bu,
      bankaccount: expense.bankaccount,
      expstatus: expense.expstatus,
      payment: expense.payment,
      country: expense.supplier?.country.name,
      countryid: expense.supplier?.country.id,
      expenseCurrency: expense.currency?.name,
      currencySymbol: expense.currency?.symbol,
      currencyid: expense.currency?.id,
      currency: expense.currency,
      paymentTransations: expense.paymentTransactions,
      expenseitems: expense.expenseitems,
      expenseDocuments: expense.expenseDocuments,
      documentTypes: expense.documentTypes,
      privileges: expense.privileges,
      render: true,
    });
    this.state.expstatus = expense.expstatus;
    this.getMenuIconsAsPerStatusAndPermission();
  }

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = ["EDIT", "DELETE", "PRINT"];

    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }
    if (Utils.equalsIgnoreCase(this.state.expstatus?.status, "CREATED")) {
      buttonList = ["EDIT", "DELETE"];
    } else if (
      Utils.equalsIgnoreCase(
        this.state.expstatus?.status,
        "VERIFICATIONREQUESTED"
      )
    ) {
      buttonList = ["PRINT"];
    } else if (
      Utils.equalsIgnoreCase(
        this.state.expstatus?.status,
        "VERIFICATIONREJECTED"
      )
    ) {
      buttonList = ["PRINT"];
    } else {
      buttonList = ["PRINT"];
    }

    if (
      Utils.hasPrivilege("UPDATE", this.state.privileges) &&
      Utils.hasPrivilege("DELETE", this.state.privileges)
    ) {
    } else if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      for (let i = 0; i < buttonList.length; i++) {
        if (buttonList[i] === "DELETE") {
          buttonList.splice(i, 1);
        }
      }
    } else if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      for (let i = 0; i < buttonList.length; i++) {
        if (buttonList[i] === "UPDATE") {
          buttonList.splice(i, 1);
        }
      }
    }
    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      let printCallback = null;
      let createSimilarCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      if (buttonList.includes("PRINT")) {
        printCallback = this.printButtonClicked.bind(this);
        createSimilarCallback = this.createSimilarButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        printCallback: printCallback,
        createSimilarCallback: createSimilarCallback,
      });
    }
  }
  getExpenseData(id) {
    const postObject = {
      expense: { id: id },
      header: this.header,
    };

    FetchServerData.callPostService("/expensems/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let expense = output.data;
          for (let i = 0; i < expense.expenseitems.length; i++) {
            let ref = React.createRef();
            this.itemReferenceList.push(ref);
            /*Set Default Operation as Update */
            expense.expenseitems[i].operation = "update";
          }
          this.setResponseToState(expense);
        } else {
        }
      }
    );
  }

  handleExpenseDateChange(date) {
    this.setState({ expdate: date });
  }
  // handleExpenseDateBlurChange(date) {
  //   this.setExchangeRate(this.state.currency);
  // }
  handleExpenseNumberChange(e) {
    this.setState({ expnumber: e.target.value });
  }
  handleExpenseNumberOnBlur(e) {
    this.setState({ expnumber: e.target.value });
  }

  handleBillNumberChange(e) {
    this.setState({ billnumber: e.target.value });
  }

  generateExpenseNumber(e) {
    if (this.state.expdate) {
    } else {
      PtsAlert.error("Please Select Invoice Date");
      return;
    }
    let expdate = moment(this.state.expdate).format("YYYY-MM-DD");

    let documentFormat = {
      forDate: expdate,
      documenttype: {
        type: "EXP",
      },
    };
    let postObject = {
      documentNumber: documentFormat,
      header: this.header,
    };
    FetchServerData.callPostService(
      "/docnumms/generateExpense",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let newNumber = output.data.genratedNumber;
        this.setState({ expnumber: newNumber });
      } else {
      }
    });
  }

  handleSupplierChange(e, selectedValue) {
    let expenseitems = [];
    let totalAmount = Number(0.0);
    for (let i = 0; i < this.itemReferenceList?.length; i++) {
      if (this.itemReferenceList[i].current) {
        this.state.expenseitems[i].note =
          this.itemReferenceList[i].current.state.note;
        this.state.expenseitems[i].amount =
          this.itemReferenceList[i].current.state?.amount;
        this.state.expenseitems[i].expensecategory =
          this.itemReferenceList[i].current.state?.expensecategory;

        let amt = Number(
          this.itemReferenceList[i].current.state.amount
        ).toFixed(2);
        totalAmount = Number(totalAmount) + Number(amt);
      }
    }
    totalAmount = Number(totalAmount).toFixed(2);
    let amtInWords = Utils.getAmountInWords(totalAmount, this.state.currency);

    if (selectedValue !== null) {
      if (selectedValue.name === "Add New Supplier") {
        let expenseData = {
          expdate: this.state.expdate,
          expnumber: this.state.expnumber,
          billnumber: this.state.billnumber,
          purpose: this.state.purpose,
          businessUnits: this.state.bu,
          expenseitems: this.state.expenseitems,
          isAsset: this.state.isAsset,
          isRecurring: this.state.isRecurring,
        };
        this.props.history.push({
          pathname: "/exsupplier",
          state: {
            action: "create",
            title: "Add New Supplier",
            pageId: "expense",
            supplierId: "",
            amount: totalAmount,
            amountinwords: amtInWords,
            expenseData: expenseData,
            expenseitems: this.state.expenseitems,
          },
        });
      } else {
        this.setState({
          supplier: selectedValue,
          expenseCurrency: selectedValue?.currencyName,
          currencyid: selectedValue?.currencyid,
          currencySymbol: selectedValue?.currencySymbol,
          country: selectedValue?.countryName,
          countryid: selectedValue?.countryid,
        });
      }
    }
    // this.setState({
    //   supplier: selectedValue,
    //   expenseCurrency: selectedValue?.currencyName,
    //   currencyid: selectedValue?.currencyid,
    //   currencySymbol: selectedValue?.currencySymbol,
    //   country: selectedValue?.countryName,
    //   countryid: selectedValue?.countryid,
    // });
  }
  // handleGSTNChange(e) {
  //   let supplier = {};
  //   if (this.state.supplier) {
  //     supplier = this.state.supplier;
  //   } else {
  //     supplier.country = this.defaultCountry;
  //   }
  //   supplier.gstn = e.target.value;
  //   this.setState({ supplier: supplier });
  // }

  handleGSTNChange(e) {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    let supplier = {};
    if (this.state.supplier) {
      supplier = this.state.supplier;
    } else {
      supplier.country = this.defaultCountry;
    }
    supplier.gstn = input.value.toUpperCase();
    this.setState({ supplier: supplier }, () =>
      input.setSelectionRange(start, end)
    );
    // this.setState({ gstn: e.target.value });
  }

  handleGSTNOnBlur(e) {
    let gstn = this.state.supgstn;
    if (gstn && gstn.length > 0) {
      if (gstn.length < 15) {
        document.getElementById("supplierGstn").focus();
        PtsAlert.error("GSTN Must be 15 Characters long");
        return false;
      } else {
        let gstnPattern =
          "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
        let patternArray = gstn.match(gstnPattern);
        if (patternArray === null) {
          document.getElementById("supplierGstn").focus();
          PtsAlert.error(
            "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
          );
          return false;
        }
      }
    }
  }
  handleIsAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }
  handleIsSupplierChange(e) {
    let supplier = this.state.supplier;
    if (e.target.checked === false) {
      supplier = null;
    }
    this.setState({ isSupplier: e.target.checked, supplier: supplier });
  }
  handleIsRecurringChange(e) {
    this.setState({ isRecurring: e.target.checked });
  }
  // updateSupplierCountry(e, country) {
  //   let currency = country.currency;
  //   this.state.currency = currency;

  //   let supplier = {};
  //   if (this.state.supplier) {
  //     supplier = this.state.supplier;
  //   }
  //   supplier.country = country;
  //   this.setState({ supplier: supplier, currency: currency });
  //   this.doCalculations();
  // }
  updateSupplierCountry(e) {
    // let currency = country.currency;
    // this.state.currency = currency;

    // let supplier = {};
    // if (this.state.supplier) {
    //   supplier = this.state.supplier;
    // }
    // supplier.country = country;
    this.setState({ country: e.target.value });
    this.doCalculations();
  }
  handleNoteChange(e) {
    this.setState({ purpose: e.target.value });
  }
  handleNoteOnBlur(e) {
    this.setState({ purpose: e.target.value });
  }
  handleExchangeRateChange(e) {
    this.setState({ exchangerate: e.target.value });
  }

  updateBusinessUnit(bu) {
    this.setState({ bu: bu });
  }

  setExchangeRate(currency) {
    if (this.state.currency.isocode === this.defaultCurrency.isocode) {
      this.setState({ exchangeRate: 1 });
      return;
    }

    let base = this.defaultCurrency.isocode;
    let expDate = this.state.expdate;
    if (!Utils.isSupportedCurrency(currency.isocode)) {
      PtsAlert.warn('Currency "' + currency.name + '" Not Supported');
      return;
    }
    let postObject = {
      targetCurrency: currency.isocode,
      currencyRate: {
        basecurrency: base,
        date: expDate,
      },
      header: this.header,
    };

    FetchServerData.callPostService(
      "/exchangeratems/getExchangeRate",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        // let rates = JSON.parse(output.data.rates);
        let rate = output.data;
        if (rate.currency && rate.value) {
          let value = rate.value;
          let exchangeRate = 1 / parseFloat(value);
          this.setState({ exchangerate: exchangeRate });
          return;
        } else {
          this.setState({ exchangerate: "" });
        }
      } else {
      }
    });
  }
  updateCurrency(e) {
    // this.state.currency = currency;
    // if (currency.isocode === this.defaultCurrency.isocode) {
    // } else {
    //   this.setExchangeRate(currency);
    // }
    this.setState({ expenseCurrency: e.target.value });
    this.doCalculations();
  }

  addExpenseItem() {
    let items = this.state.expenseitems;
    let item = { operation: "add", amount: 0.0 };
    items?.push(item);
    this.itemReferenceList.push(React.createRef());

    this.setState({ expenseitems: items });
  }

  doCalculations() {
    let totalAmount = Number(0.0);
    for (let i = 0; i < this.itemReferenceList.length; i++) {
      if (this.itemReferenceList[i].current) {
        let amt = Number(
          this.itemReferenceList[i].current.state.amount
        ).toFixed(2);
        totalAmount = Number(totalAmount) + Number(amt);
      }
    }
    totalAmount = Number(totalAmount).toFixed(2);
    let amtInWords = Utils.getAmountInWords(totalAmount, this.state.currency);
    this.setState({ amount: totalAmount, amountinwords: amtInWords });
  }
  expenseItemChanged(item) {
    this.doCalculations();
  }

  expenseItemDeleted(item) {
    let items = [];
    let itemToTrash = -999;
    for (let i = 0; i < this.state.expenseitems.length; i++) {
      let invItem = this.state.expenseitems[i];
      if (Utils.equalsIgnoreCase(this.state.action, "create")) {
        if (item.isDelete === true && item.index === i) {
          itemToTrash = i;
          continue;
        } else {
          invItem =
            this.itemReferenceList[i] && this.itemReferenceList[i].current
              ? this.itemReferenceList[i].current.state
              : invItem;
          items.push(invItem);
        }
      } else {
        if (item.isDelete === true && item.index === i) {
          if (Utils.equalsIgnoreCase(item.operation, "add")) {
            itemToTrash = i;
            continue;
          }
          invItem.operation = "delete";
        } else {
          invItem =
            this.itemReferenceList[i] && this.itemReferenceList[i].current
              ? this.itemReferenceList[i].current.state
              : invItem;
        }
        items.push(invItem);
      }
    }

    for (let i = 0; i < this.state.expenseitems.length; i++) {
      if (i === itemToTrash) {
      }
    }

    let randomKey = Math.random();
    this.setState({ refreshKey: randomKey, expenseitems: items });
  }

  requestVerification(e) {
    let postObject = {
      expense: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/eworkflow/requestVerification",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(
          "Verification Requested for Expense " + this.state.expnumber
        );
        let expense = output.data;
        expense.privileges = this.state.privileges;
        // this.setResponseToState(expense);
        if (this.state.isHistory && this.state.isHistory === true) {
          this.props.history.push({
            pathname: "/expense_invoicesearch/",
          });
        } else {
          this.props.history.push({
            pathname: "/expense/",
          });
        }
      } else {
      }
    });
  }

  rejectVerification(e) {
    let postObject = {
      expense: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/eworkflow/rejectVerification",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(
          "Verification Rejected On Expense " + this.state.expnumber
        );
        let expense = output.data;
        expense.privileges = this.state.privileges;
        // this.setResponseToState(expense);
        if (this.state.isHistory && this.state.isHistory === true) {
          this.props.history.push({
            pathname: "/expense_invoicesearch/",
          });
        } else {
          this.props.history.push({
            pathname: "/expense/",
          });
        }
      } else {
      }
    });
  }

  verifyExpense(e) {
    let postObject = {
      expense: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/eworkflow/verify", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success("Expense " + this.state.expnumber + " Verified");
          let expense = output.data;
          expense.privileges = this.state.privileges;
          // this.setResponseToState(expense);
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/expense_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/expense/",
            });
          }
        } else {
        }
      }
    );
  }
  revertVerification(e) {
    let postObject = {
      expense: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/eworkflow/revertVerification",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(
          "Verification Reverted On Expense " + this.state.expnumber + " "
        );
        let expense = output.data;
        expense.privileges = this.state.privileges;
        // this.setResponseToState(expense);
        if (this.state.isHistory && this.state.isHistory === true) {
          this.props.history.push({
            pathname: "/expense_invoicesearch/",
          });
        } else {
          this.props.history.push({
            pathname: "/expense/",
          });
        }
      } else {
      }
    });
  }
  modifyExpense(e) {
    let postObject = {
      expense: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/eworkflow/modify", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(
            "Expense " + this.state.expnumber + " Set for Modification"
          );
          let expense = output.data;
          expense.privileges = this.state.privileges;
          // this.setResponseToState(expense);
          if (this.state.isHistory && this.state.isHistory === true) {
            this.props.history.push({
              pathname: "/expense_invoicesearch/",
            });
          } else {
            this.props.history.push({
              pathname: "/expense/",
            });
          }
        } else {
        }
      }
    );
  }
  updateExpenseInBackend(e) {
    e.preventDefault();
    for (let k = 0; k < this.itemReferenceList.length; k++) {
      if (this.itemReferenceList[k] && this.itemReferenceList[k].current) {
        if (!this.itemReferenceList[k].current.validateItem()) {
          return;
        }
      }
    }
    if (null === this.state.expdate || "" === this.state.expdate) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Expense Date is required");
      return;
    } else {
      let currentDate = new Date();
      let expDate = new Date(this.state.expdate);
      let Difference_In_Time = currentDate.getTime() - expDate.getTime();
      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 540) {
        PtsAlert.error("Expense Date is too old");
        return;
      }

      Difference_In_Time = expDate.getTime() - currentDate.getTime();
      // To calculate the no. of days between two dates
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 31) {
        PtsAlert.error("Future Expense Date");
        return;
      }
    }

    /* Set Item Changed Data */
    for (let i = 0; i < this.itemReferenceList.length; i++) {
      if (this.itemReferenceList[i].current) {
        this.state.expenseitems[i].note =
          this.itemReferenceList[i].current.state.note;
        this.state.expenseitems[i].amount =
          this.itemReferenceList[i].current.state.amount;
        this.state.expenseitems[i].expensecategory =
          this.itemReferenceList[i].current.state.expensecategory;
      }
    }

    let expense = {
      id: this.state.id,
      purpose: this.state.purpose,
      expdate: this.state.expdate,
      createdon: new Date(),
      expnumber: this.state.expnumber,
      exchangerate: this.state.exchangerate,
      billnumber: this.state.billnumber,
      amount: this.state.amount,
      amountinwords: this.state.amountinwords,
      bu: this.state.bu,
      bankaccount: this.state.bankaccount,
      isAsset: this.state.isAsset,
      isSystemGenerated: this.state.isSystemGenerated,
      isRecurring: this.state.isRecurring,
      supplier: this.state.supplier,
      // expstatus: {},
      payment: this.state.payment,
      currency: {
        name: this.state.expenseCurrency,
        id: this.state.currencyid,
        symbol: this.state.currencySymbol,
      },
      expenseitems: this.state.expenseitems,
      // expenseDocuments: this.state.expenseDocuments,
      documentTypes: this.state.documentTypes,
    };

    if (expense.supplier) {
      let country = {
        id: this.state.countryid,
        name: this.state.country,
      };
      expense.supplier.country = country;
    }

    const postObject = {
      expense: expense,
      header: this.header,
    };

    FetchServerData.callPostService("/expensems/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success("Updated Successfully");
          this.props.history.push({
            pathname: "/expenseView",
            state: {
              expenseId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
          // this.setState({
          //   action: "view",
          //   render: true,
          // })
        } else {
        }
      }
    );
  }

  createExpenseInBackend(e) {
    e.preventDefault();
    for (let k = 0; k < this.itemReferenceList.length; k++) {
      if (this.itemReferenceList[k] && this.itemReferenceList[k].current) {
        if (!this.itemReferenceList[k].current.validateItem()) {
          return;
        }
      }
    }
    if ("" === this.state.expdate) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Expense Date is required");
      return;
    } else {
      let currentDate = new Date();
      let expDate = new Date(this.state.expdate);
      let Difference_In_Time = currentDate.getTime() - expDate.getTime();
      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 540) {
        PtsAlert.error("Expense Date is too old");
        return;
      }

      Difference_In_Time = expDate.getTime() - currentDate.getTime();
      // To calculate the no. of days between two dates
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 31) {
        PtsAlert.error("Future Expense Date");
        return;
      }
    }

    /* Set Item Changed Data */
    for (let i = 0; i < this.itemReferenceList.length; i++) {
      if (this.itemReferenceList[i].current) {
        this.state.expenseitems[i].note =
          this.itemReferenceList[i].current.state.note;
        this.state.expenseitems[i].amount =
          this.itemReferenceList[i].current.state.amount;
        this.state.expenseitems[i].expensecategory =
          this.itemReferenceList[i].current.state.expensecategory;
      }
    }

    let expense = {
      id: this.state.id,
      purpose: this.state.purpose,
      expdate: this.state.expdate,
      createdon: new Date(),
      expnumber: this.state.expnumber,
      exchangerate: this.state.exchangerate,
      billnumber: this.state.billnumber,
      amount: this.state.amount,
      amountinwords: this.state.amountinwords,
      bu: this.state.bu,
      bankaccount: this.state.bankaccount,
      isAsset: this.state.isAsset,
      // isSupplier: this.state.isSupplier,
      isSystemGenerated: this.state.isSystemGenerated,
      isRecurring: this.state.isRecurring,
      supplier: this.state.supplier,
      payment: this.state.payment,
      currency: {
        name: this.state.expenseCurrency,
        id: this.state.currencyid,
        symbol: this.state.currencySymbol,
      },
      expenseitems: this.state.expenseitems,
      expenseDocuments: this.state.expenseDocuments,
      documentTypes: this.state.documentTypes,
    };
    // if (this.state.isSupplier === false) {
    //   expense.supplier = null;
    // }
    if (expense.supplier) {
      let country = {
        id: this.state.countryid,
        name: this.state.country,
      };
      expense.supplier.country = country;
    }
    const postObject = {
      expense: expense,
      header: this.header,
    };

    FetchServerData.callPostService("/expensems/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success("Expense Created Successfully");
          let expense = output.data;
          let expenseState = {
            invoiceId: expense.id,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(
            ApplicationStateAction.setApplicationState({ expenseState })
          );
          this.props.history.push({
            pathname: "/expenseView",
            state: {
              expenseId: expense.id,
              action: "view",
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
              addAnotherExpense: true,
            },
          });
        } else {
        }
      }
    );
  }
  cancelEdit(e) {
    // this.setState({
    //   expenseId: this.state.id,
    //   action: "view",
    // })
    this.props.history.push({
      pathname: "/expenseView",
      state: {
        expenseId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  onDocumentAdd(document) {
    let doc = this.state.expenseDocuments;
    doc.push({
      id: doc.id,
      filename: doc.filename,
      url: doc.url,
      documenttype: doc.documenttype,
    });
    this.setState({ expenseDocuments: doc });
  }
  onDocumentDelete(index) {
    let doc = [];
    for (let i = 0; i < this.state.expenseDocuments.length; i++) {
      if (i === index) {
        continue;
      }
      doc.push(this.state.expenseDocuments[i]);
    }
    this.setState({ expenseDocuments: doc });
  }

  cancelView(e) {
    if (this.state.isHistory && this.state.isHistory === true) {
      this.props.history.push({
        pathname: "/expense_invoicesearch/",
      });
    } else {
      this.props.history.push({
        pathname: "/expense/",
        state: {},
      });
    }
  }

  backToHistory(e) {
    this.props.history.push({
      pathname: "/expense_invoicesearch/",
    });
  }

  showReferenceINRExpense() {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current
    ) {
      this.referenceInvoiceDialogueRef.current.showViewInINRExpense(
        this.state.id
      );
    }
  }

  addAnotherExpense() {
    this.props.history.push({
      pathname: "/expenseCreate",
      state: { action: "create" },
    });
  }

  getExpenseButtons() {
    let status = this.state.expstatus?.status;
    if (Utils.equalsIgnoreCase("CREATED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.state.currency?.isocode !== this.defaultCurrency.isocode && (
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
                padding: "7px",
              }}
              onClick={(e) => this.showReferenceINRExpense(e)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              &nbsp; View In {`${this.defaultCurrency.isocode}`}
            </Button>
          )}

          {this.state.addAnotherExpense && (
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              onClick={this.addAnotherExpense.bind(this)}
            >
              Add Another Expense
            </Button>
          )}

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.requestVerification(e)}
          >
            Request Verification
          </Button>

          {(Utils.isNull(this.state.isHistory) ||
            this.state.isHistory === false) && (
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          )}
          {this.state.isHistory && this.state.isHistory === true && (
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.backToHistory(e)}
            >
              Back to History
            </Button>
          )}
        </div>
      );
    } else if (Utils.equalsIgnoreCase("VERIFICATIONREJECTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.state.currency?.isocode !== this.defaultCurrency.isocode && (
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
                padding: "7px",
              }}
              onClick={(e) => this.showReferenceINRExpense(e)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              &nbsp; View In {`${this.defaultCurrency.isocode}`}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ThumbDownIcon />}
            onClick={(e) => this.modifyExpense(e)}
          >
            Modify
          </Button>

          {(Utils.isNull(this.state.isHistory) ||
            this.state.isHistory === false) && (
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          )}
          {this.state.isHistory && this.state.isHistory === true && (
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.backToHistory(e)}
            >
              Back to History
            </Button>
          )}
        </div>
      );
    } else if (Utils.equalsIgnoreCase("VERIFICATIONREQUESTED", status)) {
      if (Utils.hasPrivilege("APPROVE", this.state.privileges)) {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {this.state.currency?.isocode !== this.defaultCurrency.isocode && (
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: "#1b7189",
                  color: "#fff",
                  marginRight: "1rem",
                  padding: "7px",
                }}
                onClick={(e) => this.showReferenceINRExpense(e)}
              >
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  style={{ fontSize: "15px" }}
                ></i>{" "}
                &nbsp; View In {`${this.defaultCurrency.isocode}`}
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ThumbDownIcon />}
              onClick={(e) => this.rejectVerification(e)}
            >
              Reject Verification
            </Button>

            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<VerifiedUserIcon />}
              onClick={(e) => this.verifyExpense(e)}
            >
              Verify
            </Button>

            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      }
    } else if (Utils.equalsIgnoreCase("VERIFIED", status)) {
      if (Utils.hasPrivilege("APPROVE", this.state.privileges)) {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {this.state.currency?.isocode !== this.defaultCurrency.isocode && (
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: "#1b7189",
                  color: "#fff",
                  marginRight: "1rem",
                  padding: "7px",
                }}
                onClick={(e) => this.showReferenceINRExpense(e)}
              >
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  style={{ fontSize: "15px" }}
                ></i>{" "}
                &nbsp; View In {`${this.defaultCurrency.isocode}`}
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<SettingsBackupRestoreIcon />}
              onClick={(e) => this.revertVerification(e)}
            >
              Revert Verification
            </Button>

            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {(Utils.isNull(this.state.isHistory) ||
              this.state.isHistory === false) && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.cancelView(e)}
              >
                Back
              </Button>
            )}
            {this.state.isHistory && this.state.isHistory === true && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backToHistory(e)}
              >
                Back to History
              </Button>
            )}
          </div>
        );
      }
    }
  }
  getTableHeader() {
    return (
      <thead>
        <tr style={{ background: "#ececec" }}>
          <td
            style={{
              width: "5%",
              border: "1px solid #d8d7d7",
              padding: "0.5em",
            }}
          >
            <strong>#</strong>
          </td>
          <td
            style={{
              width: "30%",
              border: "1px solid #d8d7d7",
              padding: "0.5em",
            }}
          >
            <strong>Category</strong>
          </td>
          <td
            style={{
              width: "30%",
              border: "1px solid #d8d7d7",
              padding: "0.5em",
            }}
          >
            <strong>Note</strong>
          </td>
          <td
            style={{
              width: "30%",
              textAlign: "right",
              border: "1px solid #d8d7d7",
              padding: "0.5em",
            }}
          >
            <strong>Amount</strong>
          </td>

          {this.state.action !== "view" && (
            <td
              style={{
                width: "30%",
                textAlign: "right",
                border: "1px solid #d8d7d7",
              }}
            >
              <AddIconButton onClick={this.addExpenseItem.bind(this)} />
            </td>
          )}
        </tr>
      </thead>
    );
  }

  render() {
    if (this.state.render === false) {
      return "";
    } else {
      this.menuWithBreadScrumRef = React.createRef();
      this.referenceInvoiceDialogueRef = React.createRef();
      this.state.expenseitems &&
        this.state.expenseitems?.forEach((element, index) => {
          this.itemReferenceList[index] = React.createRef();
        });
      if (this.state.action === "create") {
        return this.createExpense();
      } else if (this.state.action === "edit") {
        return this.editExpense();
      } else {
        return this.viewExpense();
      }
    }
  }
  createExpense() {
    let expenseItems = [];
    /* Create List of Expense Item */
    let itemLoc = 1;
    this.state.expenseitems?.forEach((element, index) => {
      if ("delete" !== element.operation) {
        element.itemsrno = itemLoc++;
        let itemkey = index + "-" + this.state.refreshKey;
        expenseItems.push(
          <ExpenseItem
            ref={this.itemReferenceList[index]}
            key={itemkey}
            data={element}
            srno={index}
            action="edit"
            iteamIndex={index}
            expenseItems={this.state.expenseitems}
            onAdd={this.addExpenseItem.bind(this)}
            onDelete={this.expenseItemDeleted.bind(this)}
            onChange={this.expenseItemChanged.bind(this)}
          />
        );
      }
    });

    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = true;
    if (this.state.currency?.isocode === this.defaultCurrency?.isocode) {
      this.state.exchangeRate = 1;
      isExchangeRateMandator = false;
    }
    return (
      <>
        <MenuWithBreadScrum
          name={"Create " + this.title}
          addToBreadCrumb={"Create " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
        />

        <Row md={12} style={{ padding: "0.5em" }}>
          <ValidatorForm
            style={{ width: "100%" }}
            ref="form"
            onSubmit={this.createExpenseInBackend.bind(this)}
          >
            <Card md={12} className="expenseCard">
              <CardHeader style={{ margin: "0px", padding: "1.5em" }}>
                <Row md={12} style={{ alignItems: "flex-end", padding: "5px" }}>
                  <Col md={2} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      Expense Date<span className="asterisk">*</span>
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          required
                          fullWidth
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          variant="outlined"
                          id="invoicedate"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          value={this.state.expdate ? this.state.expdate : null}
                          onChange={this.handleExpenseDateChange.bind(this)}
                          // onBlur={this.handleExpenseDateBlurChange.bind(this)}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Col>
                  <Col md={2} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      Expense Number<span className="asterisk">*</span>
                    </label>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      id="expnumber"
                      type="text"
                      placeholder="Expense Number"
                      validators={["required"]}
                      onChange={this.handleExpenseNumberChange.bind(this)}
                      onBlur={this.handleExpenseNumberOnBlur.bind(this)}
                      value={this.state.expnumber}
                      name="expnumber"
                      InputLabelProps={{ shrink: true, style: {} }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {/* <GenerateIconButton

                                  /> */}
                            <RefreshIcon
                              className="gstnBtn"
                              generateExpenseNumber
                              onClick={(e) => this.generateExpenseNumber()}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                  <Col md={2} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      Bill Number<span className="asterisk">*</span>
                    </label>
                    <TextField
                      required
                      variant="outlined"
                      fullWidth
                      id="expensebillno"
                      type="text"
                      placeholder="Bill Number"
                      validators={["required"]}
                      onChange={this.handleBillNumberChange.bind(this)}
                      value={this.state.billnumber}
                      name="expensebillno"
                    />
                  </Col>
                  <Col md={2} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      Purpose<span className="asterisk">*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      id="expensenote"
                      type="text"
                      placeholder="Purpose"
                      validators={["required"]}
                      onChange={this.handleNoteChange.bind(this)}
                      // onBlur={this.handleNoteOnBlur.bind(this)}
                      value={this.state.purpose}
                      name="expensenote"
                      InputLabelProps={{ shrink: true, style: {} }}
                    />
                  </Col>
                  <Col md={2} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      Business Unit<span className="asterisk">*</span>
                    </label>
                    <Autocomplete
                      // variant="outlined"
                      disableClearable
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      id="businessUnit"
                      options={this.businessUnits}
                      value={this.state.bu}
                      defaultValue={this.state.bu}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.updateBusinessUnit(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          variant="outlined"
                          placeholder="Business Unit"
                          className="regCountry"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row md={12} style={{ alignItems: "flex-end", padding: "5px" }}>
                  <Col md={2} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      Expense Supplier<span className="asterisk">*</span>
                    </label>
                    {/* <TextField
                      variant="outlined"
                      required
                      InputLabelProps={{ shrink: true, style: {} }}
                      fullWidth
                      id="supplier"
                      type="text"
                      placeholder="Supplier Name"
                      onChange={this.handleSupplierChange.bind(this)}
                      // onBlur={this.handleSupplierOnBlur.bind(this)}
                      value={
                        this.state.supplier ? this.state.supplier.name : ""
                      }
                      name="supplier"
                    /> */}
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      style={{ width: "100%" }}
                      id="expenseSupplier"
                      options={this.state.expenseSupplierList}
                      value={this.state.supplier}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleSupplierChange(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          variant="outlined"
                          style={{}}
                          placeholder="Expense Supplier"
                          className="regCountry"
                          InputLabelProps={{ shrink: true, style: {} }}
                        />
                      )}
                    />
                  </Col>
                  {this.state.supplier && (
                    <>
                      <Col md={2} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          Supplier Country<span className="asterisk">*</span>
                        </label>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="exchangeRate"
                          type="text"
                          placeholder="Exchange Rate"
                          onChange={this.updateSupplierCountry.bind(this)}
                          value={this.state.country}
                          name="exchangeRate"
                          InputLabelProps={{ shrink: true, style: {} }}
                        />
                      </Col>
                      <Col md={2} style={{ padding: "5px" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          Currency<span className="asterisk">*</span>
                        </label>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="exchangeRate"
                          type="text"
                          placeholder="Exchange Rate"
                          onChange={this.updateCurrency.bind(this)}
                          value={this.state.expenseCurrency}
                          name="exchangeRate"
                          InputLabelProps={{ shrink: true, style: {} }}
                        />
                      </Col>
                      {isExchangeRateMandator === true && (
                        <Col md={2} style={{ padding: "5px" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                            style={{ color: "#000" }}
                          >
                            Exchange Rate<span className="asterisk">*</span>
                          </label>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="exchangeRate"
                            type="text"
                            placeholder="Exchange Rate"
                            onChange={this.handleExchangeRateChange.bind(this)}
                            value={this.state.exchangerate}
                            name="exchangeRate"
                            InputLabelProps={{ shrink: true, style: {} }}
                          />
                        </Col>
                      )}
                    </>
                  )}
                  <Col md={2} style={{ padding: "5px" }}>
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          id="isAsset"
                          checked={this.state.isAsset}
                          value={this.state.isAsset}
                          onChange={this.handleIsAssetChange.bind(this)}
                          style={{ background: "none" }}
                        />
                      }
                      label={<span style={{}}>Is Asset?</span>}
                    />
                  </Col>
                  <Col md={2} style={{ padding: "5px" }}>
                    <FormControlLabel
                      style={{
                        color: "#000",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          id="isRecurring"
                          checked={this.state.isRecurring}
                          value={this.state.isRecurring}
                          onChange={this.handleIsRecurringChange.bind(this)}
                          style={{ background: "none" }}
                        />
                      }
                      label={<span style={{}}>Is Recurring?</span>}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row md={12} style={{ margin: "0" }}>
                  <Col
                    md={11}
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                    }}
                  >
                    Expense Item Details
                  </Col>
                  <Col
                    md={1}
                    style={{
                      borderRight: "1px solid #eceaea",
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      padding: "0.5em",
                      textAlign: "right",
                    }}
                  >
                    <AddIconButton onClick={this.addExpenseItem.bind(this)} />
                  </Col>
                </Row>
                {expenseItems}
                <Row
                  md={12}
                  style={{ padding: "0px", margin: "12px 11px 0px 0px" }}
                >
                  <Col md={8}></Col>
                  <Col md={4} style={{ margin: "0", padding: "0" }}>
                    <Row>
                      <Col
                        md={8}
                        style={{
                          verticalAlign: "top",
                          // width: "40%",
                          textAlign: "right",
                        }}
                      >
                        <strong>Total Amount</strong>
                      </Col>
                      <Col
                        md={4}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <strong>
                          {this.state.currency !== null
                            ? this.state.currency.symbol
                            : ""}{" "}
                          {this.state.amount}
                        </strong>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "0.5em" }}>
                      <Col
                        md={12}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <span>{this.state.amountinwords}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row md={12}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <CreateButton type="submit" />
                    <CancelButton onClick={(e) => this.cancelView()} />
                  </div>
                </Row>
              </CardFooter>
            </Card>
          </ValidatorForm>
        </Row>
      </>
    );
  }
  editExpense() {
    let expenseItems = [];
    /* Create List of Expense Item */
    let itemLoc = 1;
    this.state.expenseitems.forEach((element, index) => {
      if ("delete" !== element.operation) {
        element.itemsrno = itemLoc++;
        let itemkey = index + "-" + this.state.refreshKey;
        expenseItems.push(
          <ExpenseItem
            ref={this.itemReferenceList[index]}
            key={itemkey}
            data={element}
            srno={index}
            action="edit"
            iteamIndex={index}
            expenseItems={this.state.expenseitems}
            onAdd={this.addExpenseItem.bind(this)}
            onDelete={this.expenseItemDeleted.bind(this)}
            onChange={this.expenseItemChanged.bind(this)}
          />
        );
      }
    });
    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = true;
    if (this.state.currency.isocode === this.defaultCurrency.isocode) {
      this.state.exchangeRate = 1;
      isExchangeRateMandator = false;
    }
    return (
      <>
        <MenuWithBreadScrum
          name={"Edit " + this.title}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Edit " + this.title}
          history={this.props.history}
        />

        <ValidatorForm
          style={{ width: "100%", color: "#000", padding: "0.5em" }}
          ref="form"
          onSubmit={this.updateExpenseInBackend.bind(this)}
        >
          <Card md={12} className="expenseCard">
            <CardHeader style={{ margin: "0px", padding: "1.5em" }}>
              <Row md={12} style={{ alignItems: "flex-end", padding: "5px" }}>
                <Col md={2} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                    style={{ color: "#000" }}
                  >
                    Expense Date<span className="asterisk"> *</span>
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        required
                        fullWidth
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        variant="outlined"
                        id="invoicedate"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        value={this.state.expdate ? this.state.expdate : null}
                        onChange={this.handleExpenseDateChange.bind(this)}
                        // onBlur={this.handleExpenseDateBlurChange.bind(this)}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Col>
                <Col md={2} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                    style={{ color: "#000" }}
                  >
                    Expense Number<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="expnumber"
                    type="text"
                    placeholder="Expense Number"
                    validators={["required"]}
                    onChange={this.handleExpenseNumberChange.bind(this)}
                    onBlur={this.handleExpenseNumberOnBlur.bind(this)}
                    value={this.state.expnumber}
                    name="expnumber"
                    InputLabelProps={{ shrink: true, style: {} }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RefreshIcon
                            className="gstnBtn"
                            generateExpenseNumber
                            onClick={(e) => this.generateExpenseNumber()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                    style={{ color: "#000" }}
                  >
                    Bill Number<span className="asterisk">*</span>
                  </label>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    id="expensebillno"
                    type="text"
                    placeholder="Bill Number"
                    validators={["required"]}
                    onChange={this.handleBillNumberChange.bind(this)}
                    value={this.state.billnumber}
                    name="expensebillno"
                  />
                </Col>
                <Col md={2} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                    style={{ color: "#000" }}
                  >
                    Purpose<span className="asterisk">*</span>
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    id="expensenote"
                    type="text"
                    placeholder="Purpose"
                    validators={["required"]}
                    onChange={this.handleNoteChange.bind(this)}
                    // onBlur={this.handleNoteOnBlur.bind(this)}
                    value={this.state.purpose}
                    name="expensenote"
                    InputLabelProps={{ shrink: true, style: {} }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                    style={{ color: "#000" }}
                  >
                    Business Unit<span className="asterisk">*</span>
                  </label>
                  <Autocomplete
                    // variant="outlined"
                    disableClearable
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    id="businessUnit"
                    options={this.businessUnits}
                    value={this.state.bu}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        placeholder="Business Unit"
                        className="regCountry"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", padding: "5px" }}>
                <Col md={2} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                    style={{ color: "#000" }}
                  >
                    Expense Supplier<span className="asterisk">*</span>
                  </label>
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    style={{ width: "100%" }}
                    id="expenseSupplier"
                    options={this.state.expenseSupplierList}
                    value={this.state.supplier}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.handleSupplierChange(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="outlined"
                        style={{}}
                        placeholder="Expense Supplier"
                        className="regCountry"
                        InputLabelProps={{ shrink: true, style: {} }}
                      />
                    )}
                  />
                </Col>
                {this.state.supplier && (
                  <>
                    <Col md={2} style={{ padding: "5px" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                        style={{ color: "#000" }}
                      >
                        Supplier Country<span className="asterisk">*</span>
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        placeholder="Supplier Country"
                        onChange={this.updateSupplierCountry.bind(this)}
                        value={this.state.country}
                        name="exchangeRate"
                        InputLabelProps={{ shrink: true, style: {} }}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                        style={{ color: "#000" }}
                      >
                        Currency<span className="asterisk">*</span>
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        placeholder="Currency"
                        onChange={this.updateCurrency.bind(this)}
                        value={this.state.expenseCurrency}
                        name="exchangeRate"
                        InputLabelProps={{ shrink: true, style: {} }}
                      />
                    </Col>
                    {isExchangeRateMandator === true && (
                      <Col
                        md={2}
                        style={{ padding: "5px", textAlign: "center" }}
                      >
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                          style={{ color: "#000" }}
                        >
                          Exchange Rate<span className="asterisk">*</span>
                        </label>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="exchangeRate"
                          type="text"
                          placeholder="Exchange Rate"
                          onChange={this.handleExchangeRateChange.bind(this)}
                          value={this.state.exchangerate}
                          name="exchangeRate"
                          InputLabelProps={{ shrink: true, style: {} }}
                        />
                      </Col>
                    )}
                  </>
                )}
                <Col md={2} style={{ padding: "5px" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isAsset}
                        value={this.state.isAsset}
                        onChange={this.handleIsAssetChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Asset?</span>}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isRecurring"
                        checked={this.state.isRecurring}
                        value={this.state.isRecurring}
                        onChange={this.handleIsRecurringChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Recurring?</span>}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ margin: "0" }}>
                <Col
                  md={11}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                  }}
                >
                  Expense Item Details
                </Col>
                <Col
                  md={1}
                  style={{
                    borderRight: "1px solid #eceaea",
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  <AddIconButton onClick={this.addExpenseItem.bind(this)} />
                </Col>
              </Row>
              {/* <Row md={12} style={{ padding: "0px", margin: "0" }}>
                <TableContainer>
                  <Table style={{ color: "black" }}>
                    {this.getTableHeader()}
                    <TableBody> */}
              {expenseItems}
              {/* </TableBody>
                  </Table>
                </TableContainer>
              </Row>*/}
              <Row
                md={12}
                style={{ padding: "0px", margin: "12px 11px 0px 0px" }}
              >
                <Col md={8}></Col>
                <Col md={4} style={{ margin: "0", padding: "0" }}>
                  {/* <TableContainer>
                    <Table>
                      <TableBody> */}
                  <Row>
                    <Col
                      md={8}
                      style={{
                        verticalAlign: "top",
                        textAlign: "right",
                      }}
                    >
                      <span>
                        {" "}
                        <strong>Total Amount</strong>
                      </span>
                    </Col>
                    <Col
                      md={4}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <span>
                        {this.state.currency !== null
                          ? this.state.currency.symbol
                          : ""}{" "}
                        {this.state.amount}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "0.5em" }}>
                    <Col
                      md={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <span>{this.state.amountinwords}</span>
                    </Col>
                  </Row>
                  {/* </TableBody>
                    </Table>
                  </TableContainer> */}
                </Col>
              </Row>
              <Row
                md={12}
                style={{
                  padding: "5px",
                  margin: "2em 0px 0px 0px",
                }}
              >
                <FileAttachmentList
                  data={this.state.expenseDocuments}
                  aid={this.state.id}
                  title="Expense Receipt"
                  history={this.props.history}
                  documentTypeList={this.state.documentTypes}
                  uploadAttachmentServiceUrl={this.uploadAttachmentServiceUrl}
                  deleteAttachmentServiceUrl={this.deleteAttachmentServiceUrl}
                />
              </Row>
              <Row md={12} style={{ padding: "5px", margin: "0" }}>
                <PaymentTransactionList
                  data={this.state.paymentTransations}
                  aid={this.state.id}
                  expdate={this.state.expdate}
                  expamount={this.state.amount}
                  currency={this.state.currency}
                  title="Payment Details"
                  history={this.props.history}
                  addPaymentTransactionServiceUrl={
                    this.addPaymentTransactionServiceUrl
                  }
                  updatePaymentTransactionServiceUrl={
                    this.updatePaymentTransactionServiceUrl
                  }
                  deletePaymentTransactionServiceUrl={
                    this.deletePaymentTransactionServiceUrl
                  }
                />
              </Row>
            </CardBody>
            <CardFooter>
              <Row md={12}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <SaveButton type="submit" />
                  <CancelButton onClick={(e) => this.cancelEdit()} />
                </div>
              </Row>
            </CardFooter>
          </Card>
        </ValidatorForm>
        {/* </Row> */}
      </>
    );
  }

  viewExpense() {
    let expenseItems = [];
    let itemLoc = 1;
    this.state.expenseitems.forEach((element, index) => {
      element.itemsrno = itemLoc++;
      let itemkey = index + "-" + this.state.refreshKey;
      expenseItems.push(
        <ExpenseItem
          key={itemkey}
          data={element}
          srno={index + 1}
          action="view"
          iteamIndex={index}
        />
      );
    });
    let menuWithBreadScrum = (
      <MenuWithBreadScrum
        ref={this.menuWithBreadScrumRef}
        name={"View " + this.title}
        docTitle={this.state.expnumber}
        breadCrumb={this.breadCrumb}
        addToBreadCrumb={"View " + this.title}
        history={this.props.history}
        backCallback={this.cancelView.bind(this)}
      />
    );

    return (
      <>
        <ExpenseDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
        />

        {menuWithBreadScrum}

        <Card md={12} style={{ margin: "1em" }}>
          <CardHeader>
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <Col
                md={12}
                style={{ padding: "5px", marginLeft: "10px", display: "flex" }}
              >
                <div className="row pb-1">
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "600",
                      color: "#1b7189",
                    }}
                  >
                    <lable
                      className="ms-1"
                      style={{
                        color: "rgb(0, 0, 0)",
                        textAlign: "left",
                        fontWeight: "650",
                      }}
                    >
                      Expense
                    </lable>{" "}
                    &nbsp;
                    {this.state.expnumber}
                  </span>
                </div>
              </Col>
            </Row>
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Expense Date &nbsp;:</strong>&nbsp;
                  {Utils.ptsDateFormat(this.state.expdate)}
                </label>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Created On&nbsp;:</strong>&nbsp;
                  {Utils.ptsDateFormat(this.state.createdon)}
                </label>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Bill Number&nbsp;:</strong>&nbsp;
                  {this.state.billnumber}
                </label>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Purpose&nbsp;:</strong>&nbsp;
                  {this.state.purpose}
                </label>
              </Col>
            </Row>
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Business Unit &nbsp;:</strong>&nbsp;
                  {this.state.bu.name}
                </label>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Is Asset &nbsp;:</strong>&nbsp;
                  {null === this.state.isAsset
                    ? "No"
                    : this.state.isAsset
                    ? "Yes"
                    : "No"}
                </label>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Is Recurring &nbsp;:</strong>&nbsp;
                  {null === this.state.isRecurring
                    ? "No"
                    : this.state.isRecurring
                    ? "Yes"
                    : "No"}
                </label>
              </Col>
              <Col md={3} style={{ alignItems: "center", color: "#000" }}>
                <label style={{ color: "#000" }}>
                  <strong>Status&nbsp;:</strong>&nbsp;
                  {this.state.expstatus && this.state.expstatus?.description
                    ? this.state.expstatus?.description
                    : ""}
                </label>
              </Col>
            </Row>
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Supplier Name &nbsp;:</strong>&nbsp;
                  {null === this.state.supplier
                    ? ""
                    : this.state.supplier?.name}
                </label>
              </Col>
              <Col md={3} style={{ alignItems: "center", color: "#000" }}>
                <label style={{ color: "#000" }}>
                  <strong>Supplier Country&nbsp;:</strong>&nbsp;
                  {this.state.supplier && this.state.supplier?.country
                    ? this.state.supplier?.country?.name
                    : ""}
                </label>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Currency&nbsp;:</strong>&nbsp;
                  {null === this.state.currency ? "" : this.state.currency.name}
                </label>
              </Col>
              <Col md={3} style={{ alignItems: "center", color: "#000" }}>
                <label style={{ color: "#000" }}>
                  <strong>Currency Rate&nbsp;:</strong>&nbsp;
                  {this.state.exchangerate && this.state.exchangerate
                    ? this.state.exchangerate
                    : ""}
                </label>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12} style={{ padding: "0px", margin: "0" }}>
              {/* <TableContainer> */}
              <table>
                {this.getTableHeader()}
                {expenseItems}
              </table>
              {/* </TableContainer> */}
            </Row>
            <Row
              md={12}
              style={{ padding: "0px", margin: "12px 11px 0px 0px" }}
            >
              <Col md={8}></Col>
              <Col md={4} style={{ margin: "0", padding: "0" }}>
                {/* <TableContainer>
                  <Table>
                    <TableBody> */}
                <Row>
                  <Col
                    md={8}
                    style={{
                      verticalAlign: "top",
                      // width: "40%",
                      textAlign: "right",
                    }}
                  >
                    <span>
                      <strong>Total Amount</strong>
                    </span>
                  </Col>
                  <Col md={4} style={{ textAlign: "right" }}>
                    <span>
                      {this.state.currency !== null
                        ? this.state.currency.symbol
                        : ""}{" "}
                      {this.state.amount}
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "0.5em" }}>
                  {/* <Col
                    md={3}
                    style={{
                      verticalAlign: "top",
                      textAlign: "right",
                    }}
                  >
                    <span>In Words</span>
                  </Col> */}
                  <Col md={12} style={{ textAlign: "right" }}>
                    <span>{this.state.amountinwords}</span>
                  </Col>
                </Row>
                {/* </TableBody>
                  </Table>
                </TableContainer> */}
              </Col>
            </Row>
            <Row
              md={12}
              style={{
                padding: "5px",
                margin: "2em 0px 0px 0px",
              }}
            >
              <FileAttachmentList
                data={this.state.expenseDocuments}
                aid={this.state.id}
                title="Expense Receipt"
                srcType="Expense"
                history={this.props.history}
                documentTypeList={this.state.documentTypes}
                uploadAttachmentServiceUrl={this.uploadAttachmentServiceUrl}
                deleteAttachmentServiceUrl={this.deleteAttachmentServiceUrl}
              />
            </Row>
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <PaymentTransactionList
                data={this.state.paymentTransations}
                expdate={this.state.expdate}
                expamount={this.state.amount}
                currency={this.state.currency}
                aid={this.state.id}
                title="Payment Details"
                history={this.props.history}
                addPaymentTransactionServiceUrl={
                  this.addPaymentTransactionServiceUrl
                }
                updatePaymentTransactionServiceUrl={
                  this.updatePaymentTransactionServiceUrl
                }
                deletePaymentTransactionServiceUrl={
                  this.deletePaymentTransactionServiceUrl
                }
              />
            </Row>
          </CardBody>
        </Card>
        <CardFooter>
          <Row md={12}>{this.getExpenseButtons()}</Row>
        </CardFooter>
      </>
    );
  }
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#1B7189",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
