import { GET_USER_MENUS, SET_USER_MENUS, SET_USER_TOPMENUS, SET_USER_DETAILS } from './userMenusTypes';

const initialState = {
  allMenus: [],
  topMenus: [],
  userDetails: {},
};

const userMenusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_MENUS:
      return {
        ...state,
      };
    case SET_USER_MENUS:
      return {
        ...state,
        allMenus: action.payload,
      };
    case SET_USER_TOPMENUS:
      return {
        ...state,
        topMenus: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    default:
      return state;
  }
};

export default userMenusReducer;
