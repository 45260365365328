import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import RemoveIconButton from "../../buttons/RemoveIconButton";
import PtsAlert from "../../ptsAlert/PtsAlert";
import AddIconButton from "../../buttons/AddIconButton";
export default class AdvanceVoucherItem extends Component {
  formRef = React.createRef();
  expenseCategoryList = store.getState().appData.expenseCategoryList;
  taxRateList = store.getState().staticData.taxRateList;
  products = store.getState().master.masterData.products;
  // custHsnCodeList = store.getState().master.masterData.custhsncodes;

  constructor(props) {
    super(props.srno);
    let action = props.action;
    let item = props.data;
    let productList = [];
    for (let i = 0; i < this.products.length; i++) {
      const element = this.products[i];
      if (element.producttype?.type !== "Purchase") {
        productList.push(element);
      }
    }

    this.state = {
      isIgstApplicable: props.isIgstApplicable,
      rowBackgroundColor: props.backgroundColor.background,
      itemsrno: item.itemsrno,
      index: props.srno,
      id: item.id,
      description: item.description,
      name: item.name,
      quantity: item.quantity,
      productcode: item.productcode,
      rate: item.rate,
      total: item.total,
      uuid: item.uuid,
      igst: item.igst,
      sgst: item.sgst,
      cgst: item.cgst,
      utgst: item.utgst,
      discount: item.discount,
      taxable: item.taxable,
      nontaxable: item.nontaxable,
      refinvitemamount: item.refinvitemamount,
      uom: item.uom,
      suprcmhsncode: item.suprcmhsncode,
      refinvitem: item.refinvitem,
      invoiceitemnumber: item.invoiceitemnumbe,
      cess: item.cess,
      taxrate:
        undefined === item.taxrate || null === item.taxrate
          ? this.getZeroTaxRate()
          : item.taxrate,
      // custhsncode:
      //   undefined === item.custhsncode || null === item.custhsncode
      //     ? this.getFirstCustHsnCode()
      //     : item.custhsncode,
      // taxrate: item.taxrate,
      custhsncode: item.custhsncode,
      operation: item.operation,
      version: item.version,
      onDelete: props.onDelete,
      onChange: props.onChange,
      doCalculations: props.doCalculations,
      action: action,
      render: true,
      isDelete: false,
      onAdvanceVoucherItemAdd: props.onAdd,
      custHsnCodeList: this.props.custHsnCodeList,
      productList: productList,
    };
  }

  getZeroTaxRate() {
    for (let i = 0; i < this.taxRateList.length; i++) {
      if (parseFloat(this.taxRateList[i].rate) === 0) {
        return this.taxRateList[i];
      }
    }
    return null;
  }

  getFirstCustHsnCode() {
    if (this.state.custHsnCodeList.length > 0) {
      return this.state.custHsnCodeList[0];
    }
    return null;
  }
  updateAdvanceVoucherItemCategory(e, name) {
    this.setState({ expensecategory: name });
  }

  doCalculations() {
    let item = this.state;
    let quantity = 1;
    quantity = Utils.getFloatValue(quantity);
    let rate = Utils.getFloatValue(item.taxable);
    if (parseFloat(quantity) === 0.0 || parseFloat(rate) === 0.0) {
      this.setState({
        taxable: 0.0,
        cgst: 0.0,
        sgst: 0.0,
        igst: 0.0,
        total: 0.0,
      });
      return;
    }

    let valueOfSupply = parseFloat(quantity) * parseFloat(rate);

    let taxable = parseFloat(valueOfSupply);
    let cess = item.cess ? item.cess : 0.0;
    cess = Utils.getFloatValue(cess);

    let cessAmount = 0.0;
    if (cess > 0 && taxable > 0) {
      cessAmount = (parseFloat(taxable) * parseFloat(cess)) / 100;
    }

    let taxrate = Utils.getFloatValue(item.taxrate?.rate);
    let cgst = 0.0;
    let sgst = 0.0;
    let igst = 0.0;
    let isIgstApplicable = item.isIgstApplicable;
    if (isIgstApplicable) {
      igst = (parseFloat(taxrate) / 100) * parseFloat(taxable);
      igst = igst.toFixed(2);
    } else {
      let newRate = parseFloat(taxrate) / 2;
      cgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      cgst = cgst.toFixed(2);
      sgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      sgst = sgst.toFixed(2);
    }
    let total =
      parseFloat(taxable) +
      parseFloat(cgst) +
      parseFloat(sgst) +
      parseFloat(igst) +
      parseFloat(cessAmount);
    total = parseFloat(total).toFixed(2);

    this.setState({
      taxable: parseFloat(taxable).toFixed(2),
      cgst: parseFloat(cgst).toFixed(2),
      sgst: parseFloat(sgst).toFixed(2),
      igst: parseFloat(igst).toFixed(2),
      total: parseFloat(total).toFixed(2),
    });

    if (this.state.doCalculations) {
      this.state.taxable = parseFloat(taxable).toFixed(2);
      this.state.cgst = parseFloat(cgst).toFixed(2);
      this.state.sgst = parseFloat(sgst).toFixed(2);
      this.state.igst = parseFloat(igst).toFixed(2);
      this.state.cess = parseFloat(cess).toFixed(2);
      this.state.total = parseFloat(total).toFixed(2);
      this.state.doCalculations();
    }
  }

  handleNameChange(e, product) {
    let val = e.target.value;
    for (let i = 0; i < this.state.productList.length; i++) {
      let product = this.state.productList[i];
      let prodDetails = product.name + "-" + product.code;
      if (Utils.equalsIgnoreCase(val, prodDetails)) {
        let hsnCode = product.custhsncode;
        let taxrate = product.taxrate;
        let uom = product.uom;
        let quantity = 1;
        let discount = 0;
        let cess = 0;
        let rate = product.rate;
        this.state.quantity = quantity;
        this.state.cess = cess;
        this.state.uom = uom;
        this.state.taxable = rate;
        this.state.custhsncode = hsnCode;
        this.state.taxrate = taxrate;
        this.state.rate = rate;
        this.setState({
          uom: uom,
          name: product.name,
          productcode: product.code,
          rate: rate,
          cess: cess,
          quantity: quantity,
          discount: discount,
          custhsncode: hsnCode,
          taxrate: taxrate,
          taxable: rate,
        });
        this.doCalculations();
        return;
      }
    }
    this.setState({ name: val });
  }

  handleTaxableAmtChange(e) {
    let taxableAmt = e.target.value;
    if (!taxableAmt || taxableAmt.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState({ taxable: taxableAmt });
    }
  }

  handleTaxableAmtBlurChange(e) {
    this.doCalculations();
  }
  handleCessChange(e) {
    let cess = e.target.value;
    if (!cess || cess.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState({ cess: cess });
    }
  }
  handleCessBlurChange(e) {
    this.doCalculations();
  }
  handleAmountChange(e) {
    let amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState({ amount: amount });
    }
  }
  handleAmountBlurChange(e) {
    this.doCalculations();
  }

  deleteAdvanceVoucherItem(e) {
    if (this.state.onDelete) {
      this.state.isDelete = true;
      this.state.onDelete(this.state);
    }
  }

  validateItem() {
    let custhsncode = this.state.custhsncode;
    let name = this.state.name;
    let taxable = this.state.taxable;
    let cess = this.state.cess;
    let taxrate = this.state.taxrate;
    // let amount = this.state.amount;
    if (Utils.isNullOrEmpty(custhsncode)) {
      document.getElementById("custhsncode" + this.state.index).focus();
      PtsAlert.error("Please Select Item SAC/HSN Code ");
      return false;
    }
    if (Utils.isNullOrEmpty(name)) {
      document.getElementById("name" + this.state.index).focus();
      PtsAlert.error("Please Enter Item Description");
      return false;
    }
    if (Utils.isNullOrEmpty(taxable)) {
      document.getElementById("taxable" + this.state.index).focus();
      PtsAlert.error("Please Enter Taxable Amount");
      return false;
    }
    if (Utils.isNullOrEmpty(cess)) {
      document.getElementById("cess" + this.state.index).focus();
      PtsAlert.error("Please Enter CESS Percentage");
      return false;
    }
    if (Utils.isNullOrEmpty(taxrate)) {
      document.getElementById("taxrate" + this.state.index).focus();
      PtsAlert.error("Please Select Tax Rate ");
      return false;
    }
    return true;
  }

  updateCustHsnCode(e, custhsn) {
    this.setState({ custhsncode: custhsn });
  }

  updateTaxRate(e, taxrate) {
    this.state.taxrate = taxrate;
    this.setState({ taxrate: taxrate });
    this.doCalculations();
  }
  render() {
    if (this.state.render === false) {
      return "";
    }
    this.state.isIgstApplicable = this.props.isIgstApplicable;
    if (this.state.action === "edit") {
      return this.editAdvanceVoucherItem();
    } else {
      return this.viewAdvanceVoucherItem();
    }
  }
  addAdvanceVoucherItem(e) {
    if (
      undefined !== this.state.onAdvanceVoucherItemAdd &&
      null !== this.state.onAdvanceVoucherItemAdd
    ) {
      this.state.onAdvanceVoucherItemAdd(this.state);
    }
  }
  editAdvanceVoucherItem() {
    return (
      <div
        style={{
          border: "0.5px solid #e4dede",
          background: this.state.rowBackgroundColor,
          padding: "9px 9px",
        }}
      >
        <Row>
          <Col md={6}>
            <span>
              <b>{this.state.itemsrno}</b>
            </span>
          </Col>
          <Col md={6}>
            <RemoveIconButton
              onClick={this.deleteAdvanceVoucherItem.bind(this)}
            />
            &nbsp;&nbsp;
            {this.props.iteamIndex === this.props.invoicedata.length - 1 && (
              <AddIconButton onClick={this.addAdvanceVoucherItem.bind(this)} />
            )}
            &nbsp;&nbsp;
          </Col>
        </Row>
        <Row
          md={12}
          style={{
            margin: "5px",
            padding: "0",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Col md={4} style={{ alignItems: "center" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Item Details <span className="asterisk"> *</span>
            </label>
            <input
              style={{
                // margin: "2.2em 0.5em 0.5em 0.5em",
                width: "100%",
                padding: "0.6em",
                marginTop: "-4px",
                marginLeft: "5px",
              }}
              type="text"
              id={"name" + this.state.index}
              list="data"
              value={this.state.name}
              onChange={(event, value) => {
                this.handleNameChange(event, value);
              }}
            />
            <datalist id="data">
              {this.state.productList.map((item, key) => (
                <option key={item.id} value={item.name + "-" + item.code} />
              ))}
            </datalist>
          </Col>
          <Col md={2}>
            <label className="form-label " htmlFor="collapsible-companyName">
              SAC/HSN<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              required
              disableClearable
              size="small"
              ListboxProps={{ className: "myCustomList" }}
              // style={{ margin: "0.5em" }}
              id={"custhsncode" + this.state.index}
              label=" "
              options={this.props.custHsnCodeList}
              value={this.state.custhsncode ? this.state.custhsncode : null}
              getOptionLabel={(option) => option.code}
              onChange={(event, value) => {
                this.updateCustHsnCode(event, value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder="SAC/HSN"
                    {...params}
                    variant="outlined"
                    className="regCountry"
                    InputLabelProps={{
                      shrink: true,
                      fontSize: "small !important",
                      style: { fontSize: "small !important" },
                    }}
                    fullWidth
                  />
                );
              }}
            />
          </Col>
          <Col md={2}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Advance Amt.
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              variant="outlined"
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              id={"taxable" + this.state.index}
              type="text"
              placeholder="Advance Amt."
              onChange={this.handleTaxableAmtChange.bind(this)}
              onBlur={this.handleTaxableAmtBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.taxable}
              name="taxable"
            />
          </Col>
          <Col md={2}>
            <label className="form-label " htmlFor="collapsible-companyName">
              CESS (%)
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              variant="outlined"
              placeholder="CESS %"
              id={"cess" + this.state.index}
              type="text"
              onChange={this.handleCessChange.bind(this)}
              onBlur={this.handleCessBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.cess}
              name="cess"
            />
          </Col>
          <Col md={2}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Tax Rate (%)<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              required
              disableClearable
              size="small"
              ListboxProps={{ className: "myCustomList" }}
              // style={{ margin: "0.5em" }}
              id={"taxrate" + this.state.index}
              options={this.taxRateList}
              value={this.state.taxrate}
              getOptionLabel={(option) => option.rate + "%"}
              onChange={(event, value) => {
                this.updateTaxRate(event, value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder="Tax Rate %"
                    {...params}
                    className="regCountry"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      fontSize: "small !important",
                      style: { fontSize: "small !important" },
                    }}
                    fullWidth
                  />
                );
              }}
            />
          </Col>
        </Row>

        <Row
          md={12}
          style={{
            margin: "5px",
            padding: "0",
            alignItems: "center",
            display: "flex",
          }}
        >
          {this.state.isIgstApplicable === false && (
            <>
              <Col md={6}></Col>
              <Col md={2}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  CGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{
                    min: 0,
                    style: { textAlign: "right", border: "0" },
                  }}
                  fullWidth
                  disabled
                  variant="outlined"
                  className="disableFiled"
                  placeholder="CGST"
                  id={"cgst" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.cgst}
                  name="taxrate"
                />
              </Col>
              <Col md={2}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  SGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  disabled
                  variant="outlined"
                  className="disableFiled"
                  placeholder="SGST"
                  id={"sgst" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.sgst}
                  name="sgst"
                />
              </Col>
              <Col md={2} style={{ alignSelf: "flex-end" }}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Total
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  variant="outlined"
                  disabled
                  className="disableFiled"
                  placeholder="Total"
                  id={"total" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.total}
                  name="total"
                />
                {/* <RemoveIconButton onClick={this.deleteAdvanceVoucherItem.bind(this)} /> */}
              </Col>
            </>
          )}
          {this.state.isIgstApplicable === true && (
            <>
              <Col md={8}></Col>
              <Col Col md={2}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  IGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  disabled
                  variant="outlined"
                  className="disableFiled"
                  placeholder="IGST"
                  id={"igst" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.igst}
                  name="igst"
                />
              </Col>
              <Col md={2} style={{ alignSelf: "flex-end" }}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Total
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  variant="outlined"
                  disabled
                  className="disableFiled"
                  placeholder="Total"
                  id={"total" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.total}
                  name="total"
                />
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }
  viewAdvanceVoucherItem() {
    return (
      <tr
        style={{
          // padding: "5px",
          margin: "0",
          // background: this.state.rowBackgroundColor,
        }}
      >
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            width: "10%",
          }}
        >
          <span style={{ float: "left" }}>{this.state.index}</span>
          <span style={{ float: "right" }}>{this.state.custhsncode?.code}</span>
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            width: "25%",
          }}
        >
          {this.state.name}
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "15%",
          }}
        >
          {Utils.getFloatValue(this.state.taxable)}
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "15%",
          }}
        >
          {Utils.getFloatValue(this.state.cess)}
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "15%",
          }}
        >
          {this.state.taxrate?.rate}
        </td>
        {this.state.isIgstApplicable === false && (
          <>
            <td
              style={{
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
                width: "15%",
              }}
            >
              {Utils.getFloatValue(
                parseFloat(this.state.cgst) + parseFloat(this.state.sgst)
              )}
            </td>
          </>
        )}
        {this.state.isIgstApplicable === true && (
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "15%",
            }}
          >
            {Utils.getFloatValue(this.state.igst)}
          </td>
        )}

        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "20%",
          }}
        >
          {Utils.getFloatValue(this.state.total)}
        </td>
      </tr>
    );
  }
}
