import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { IconButton, InputAdornment } from "@mui/material";
import { Autocomplete } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";
import SaveButton from '../../components/buttons/SaveButton';
import CancelButton from '../../components/buttons/CancelButton';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ToggleButton from '@mui/material/ToggleButton';
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Utils from "../../../provider/Utils";
import AdminFetchServerData from "../../../provider/AdminFetchServerData";
import store from '../../../redux/store';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import PtsAlert from '../../components/ptsAlert/PtsAlert';

class AdminAppUser extends Component {
	header = store.getState().adminHeader.adminHeader;
	appUserRoleList = store.getState().appUserRole.appUserRoleList;
	title = 'Admin User Details';
	breadCrumb = [
		{ pageid: '/appUserList', uiname: 'Admin User List' },
		{ pageid: '', uiname: 'Admin User' },
	];
	constructor(props) {
		super(props);
		let action = props.action;
		let appUserId = props.appUserId;
		// if (!action && props.state) {
		// 	action = props.state.action;
		// 	appUserId = props.state.appUserId;
		// };
		if (Utils.isNull(appUserId) &&
			props.history &&
			props.history.location &&
			props.history.location.state
		) {
			action = props.history.location.state.action;
			appUserId = props.history.location.state.appUserId;
		}
		let canRender = false;
		if (appUserId && appUserId > 0) {
			this.getUserDetails(appUserId);
		} else {
			canRender = true;
		}
		this.state = {
			action: action,
			appUserId: appUserId,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			password: "",
			confirmPassword: "",
			userStatus: {},
			appUserRole: null,
			showPassword: false,
			showConfirmPassword: false,
			isStatus: true,
			render: canRender,
			getUpdatePermission: false,
			getDeletePermission: false,
		};
	};
	getUserDetails(appUserId) {
		const postObject = {
			header: this.header,
			appuser: {
				id: appUserId,
			},
		};
		AdminFetchServerData.callPostService('/adminpanelAppuserms/get', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let userDetails = output.data.appuser;
					let getPermission = Utils.getRolePermission(output.data.privilegeList);
					let getUpdatePermission = getPermission[3];
					let getDeletePermission = getPermission[4];
					let userStatus = '';
					if (userStatus === null) {
						userStatus = false;
					} else {
						if (userDetails.userStatus.status === 'Active') {
							userStatus = true;
						} else {
							userStatus = false;
						};
					};
					this.setState({
						render: true,
						id: userDetails.id,
						firstName: userDetails.firstName,
						lastName: userDetails.lastName,
						email: userDetails.email,
						phone: userDetails.phone,
						isStatus: userStatus,
						userStatus: userDetails.userStatus,
						appUserRole: userDetails.profile,
						getUpdatePermission: getUpdatePermission,
						getDeletePermission: getDeletePermission,
					});
				} else {
				};
			})
			.catch((error) => {
			});
	};

	handlePasswordChange(e) {
		this.setState({ password: e.target.value });
	};
	handlePasswordConfirmChange(e) {
		this.setState({ confirmPassword: e.target.value });
	};
	handleFirstNameChange(e) {
		this.setState({ firstName: e.target.value });
	};
	handleLastNameChange(e) {
		this.setState({ lastName: e.target.value });
	};
	handleEmailChange(e) {
		this.setState({ email: e.target.value });
	};
	handleClickShowPassword() {
		this.setState({ showPassword: !this.state.showPassword });
	};
	handleClickShowConfirmPassword() {
		this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
	};

	handleUserRoleChange(selectedValue) {
		this.setState({ appUserRole: selectedValue });
	};

	handlePhoneChange(e) {
		let res = e.target.value;
		if (res.length <= 10) {
			this.setState({ phone: res });
		};
	};
	handleStatusChange(e) {
		this.setState({ isStatus: !this.state.isStatus });
	};

	createUserToBackend() {
		if (this.state.password !== this.state.confirmPassword) {
			return;
		};
		const postObject = {
			header: this.header,
			appuser: {
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				email: this.state.email,
				phone: this.state.phone,
				credential: this.state.password,
				profile: {
					name: this.state.appUserRole.name,
					id: this.state.appUserRole.id
				}
			},
		};
		AdminFetchServerData.callPostService('/adminpanelAppuserms/create', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				PtsAlert.success(output.message);
				let userData = output.data.appuser;
				this.getUserDetails(userData.id);
				this.setState({
					action: "view",
				})
			} else {
			};
		});
	};

	createCancel() {
		this.props.history.push("/appUserList");
	};

	editCancel() {
		this.props.history.push({
			pathname: "/viewAppUser",
			state: {
				action: "view",
				appUserId: this.state.id,
			},
		});
	};
	viewCancel() {
		this.props.history.push("/appUserList");
	};

	editAppUserDetails() {
		this.props.history.push({
			pathname: "/editAppUser",
			state: {
				action: "edit",
				appUserId: this.state.id,
			},
		});
		// this.setState({ action: "edit", appUserId: this.state.id, })
	};

	updateUserToBackend() {
		let userStatus;
		if (this.state.isStatus === true) {
			userStatus = "Active";
		} else {
			userStatus = "InActive";
		};
		const postObject = {
			header: this.header,
			appuser: {
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				email: this.state.email,
				phone: this.state.phone,
				id: this.state.id,
				profile: {
					id: this.state.appUserRole.id
				},

				userStatus: {
					status: userStatus,
				},
			},
		};
		AdminFetchServerData.callPostService('/adminpanelAppuserms/update', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				PtsAlert.success(output.message);
				// let userData = output.data.appuser;
				// this.getUserDetails(userData.id);
				this.props.history.push({
					pathname: "/viewAppUser",
					state: {
						action: "view",
						appUserId: this.state.id,
					},
				});
			} else {
			};
		});
	};

	deleteAppUserDetails() {
		const postObject = {
			header: this.header,
			appuser: {
				id: this.state.id,
			},
		};

		AdminFetchServerData.callPostService('/adminpanelAppuserms/delete', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					PtsAlert.success(output.message);
					this.props.history.push('/appUserList');
				} else {
				};
			})
			.catch((error) => { });
	};

	render() {
		if (this.state.action === 'create') {
			return this.createUser();
		} else if (this.state.action === 'edit') {
			return this.editUser();
		} else if (this.state.action === 'view') {
			return this.viewUser();
		};
	};
	createUser() {
		const error = this.state.password !== this.state.confirmPassword;
		return (
			<>
				<AdminMenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					history={this.props.history}
					name={this.title}
				/>
				<div className="row">
					<ValidatorForm className="text-start" ref="form" onSubmit={this.createUserToBackend.bind(this)}>
						<div className="row" style={{ marginTop: "1em" }}>
							<div className="col-md-12">
								<div className="card" id="organizationFormCard">
									<div className="card-body">
										<div className="row mb-4">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-firstname">
													First Name<span className="asterisk"> *</span>
												</label>
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-firstName"
													variant="outlined"
													placeholder="First Name"
													value={this.state.firstName}
													onChange={this.handleFirstNameChange.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-lastname">
													Last Name<span className="asterisk"> *</span>
												</label>
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-lastName"
													variant="outlined"
													placeholder="Last Name"
													value={this.state.lastName}
													onChange={this.handleLastNameChange.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-email">
													Email<span className="asterisk"> *</span>
												</label>
												<TextField
													type="email"
													className="textField"
													fullWidth
													id="basic-default-email"
													variant="outlined"
													placeholder="Email"
													aria-label="Email"
													aria-describedby="basic-icon-default-email2"
													value={this.state.email}
													onChange={this.handleEmailChange.bind(this)}
													validators={["required", "isEmail"]}
													errormessages={[
														"This field is required",
														"Email id is not valid",
													]}
													required
												/>
											</div>
										</div>
										<div className="row mb-4">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-appUserRole">
													Admin User Profile<span className="asterisk"> *</span>
													<Tooltip placement="top"
														title="User Profile are created by Admin Users in Settings"
														id="password-requirements-tooltip">
														<InfoIcon style={{ fontSize: "20px" }} />
													</Tooltip>
												</label>
												<Autocomplete
													size="small"
													fullWidth
													ListboxProps={{
														className: "myCustomList"
													}}
													id="appUserRoleList"
													options={this.appUserRoleList}
													value={this.state.appUserRole}
													defaultValue={this.state.appUserRole}
													getOptionLabel={(option) => option.name}
													onChange={(event, value) => {
														this.handleUserRoleChange(value);
													}}
													inputprops={{ style: { textTransform: "capitalize" } }}
													renderInput={(params) => (
														<TextField
															{...params}
															placeholder="Admin User Profile"
															variant="outlined"
															className="regCountry"
															validators={["required"]}
															errormessages={["This field is required"]}
															required
														/>
													)}
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-firstname">
													Password<span className="asterisk"> *</span>
												</label>
												<TextField
													type={this.state.showPassword ? "text" : "password"}
													className="textField"
													fullWidth
													required
													id="basic-default-password"
													variant="outlined"
													placeholder="Password"
													name='password'
													value={this.state.password}
													onChange={this.handlePasswordChange.bind(this)}
													InputProps={{
														// <-- This is where the toggle button is added.
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={this.handleClickShowPassword.bind(this)}
																>
																	{this.state.showPassword ? (
																		<VisibilityIcon />
																	) : (
																		<VisibilityOffIcon />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-lastname">
													Confirm Password<span className="asterisk"> *</span>
												</label>
												<TextField
													className="textField"
													fullWidth
													required
													id="confirmPassword"
													variant="outlined"
													placeholder="Confirm Password;"
													name="confirmPassword"
													type={this.state.showConfirmPassword ? "text" : "password"}
													value={this.state.confirmPassword}
													onChange={this.handlePasswordConfirmChange.bind(this)}
													helperText={error ? "Password & confirm password must match" : ""}
													error={error}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={this.handleClickShowConfirmPassword.bind(this)}
																>
																	{this.state.showConfirmPassword ? (
																		<VisibilityIcon />
																	) : (
																		<VisibilityOffIcon />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</div>
										</div>
										<div className="row mb-4">
											<div className="col-md-4" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-phone">
													Phone
												</label>
												<TextField
													type="number"
													className="textField"
													fullWidth
													id="basic-default-phone"
													variant="outlined"
													placeholder="Phone"
													aria-label="Phone"
													value={this.state.phone}
													onChange={this.handlePhoneChange.bind(this)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row d-flex align-items-center justify-content-between">
							<div className="col-sm-12 mt-3 text-center">
								<SaveButton id="saveButton" type="submit"> Save </SaveButton>
								&nbsp;&nbsp;
								<CancelButton onClick={(e) => this.createCancel()} />
							</div>
						</div>
					</ValidatorForm>
				</div >
			</>
		)
	};
	viewUser() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<AdminMenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						history={this.props.history}
						name={this.title}
						// backCallback={this.viewCancel.bind(this)}
						editCallback={this.editAppUserDetails.bind(this)}
						deleteCallback={this.deleteAppUserDetails.bind(this)}
						getDeletePermission={this.state.getDeletePermission}
						getUpdatePermission={this.state.getUpdatePermission}
					/>
					<div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
						<div className="card" id="organizationFormCard">
							<div className="row">
								{/* <div
									className="card-header d-flex align-items-center justify-content-between"
									id="assetCardHeader"
								>
									<h5 className="mb-0" id="headerLabel">
										User Details
									</h5>
								</div> */}
								<div className="card-body">
									<div className="row">
										<div className="col-md-4">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Name
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.firstName + " " + this.state.lastName}</span>
												</div>
											</div>
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Phone
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.phone}</span>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Email
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.email}</span>
												</div>
											</div>
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Status
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.userStatus?.status}</span>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Profile
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.appUserRole?.name}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-4 text-center">
							<Button
								type="submit"
								variant="contained"
								size="small"
								style={{
									background: "#717373",
									color: "#fff",
									marginRight: "1rem",
								}}
								startIcon={<ArrowBackIcon />}
								onClick={(e) => this.viewCancel()}
							>
								Back
							</Button>
						</div>
					</div>
				</>
			)
		}
	};
	editUser() {
		return (
			<>
				<AdminMenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					history={this.props.history}
					name={this.title}
				/>
				<div className="row">
					<ValidatorForm className="text-start" ref="form" onSubmit={this.updateUserToBackend.bind(this)}>
						<div className="row" style={{ marginTop: "1em" }}>
							<div className="col-md-12">
								<div className="card" id="organizationFormCard">
									<div className="card-body">
										<div className="row mb-4">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-firstname">
													First Name<span className="asterisk"> *</span>
												</label>
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-firstName"
													variant="outlined"
													placeholder="First Name"
													value={this.state.firstName}
													onChange={this.handleFirstNameChange.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-lastname">
													Last Name<span className="asterisk"> *</span>
												</label>
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-lastName"
													variant="outlined"
													placeholder="Last Name"
													value={this.state.lastName}
													onChange={this.handleLastNameChange.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-email">
													Email<span className="asterisk"> *</span>
												</label>
												<TextField
													type="email"
													className="textField"
													fullWidth
													id="basic-default-email"
													variant="outlined"
													placeholder="Email"
													aria-label="Email"
													aria-describedby="basic-icon-default-email2"
													value={this.state.email}
													onChange={this.handleEmailChange.bind(this)}
													validators={["required", "isEmail"]}
													errormessages={[
														"This field is required",
														"Email id is not valid",
													]}
													required
												/>
											</div>
										</div>
										<div className="row align-items-end">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-appUserRole">
													Admin User Profile<span className="asterisk"> *</span>
													<Tooltip placement="top"
														title="User Profile are created by Admin Users in Settings"
														id="password-requirements-tooltip">
														<InfoIcon style={{ fontSize: "20px", marginTop: "-5px" }} />
													</Tooltip>
												</label>
												<Autocomplete
													size="small"
													fullWidth
													ListboxProps={{
														className: "myCustomList"
													}}
													id="appUserRoleList"
													options={this.appUserRoleList}
													value={this.state.appUserRole}
													defaultValue={this.state.appUserRole}
													getOptionLabel={(option) => option.name}
													onChange={(event, value) => {
														this.handleUserRoleChange(value);
													}}
													inputprops={{ style: { textTransform: "capitalize" } }}
													renderInput={(params) => (
														<TextField
															{...params}
															placeholder="Admin User Profile"
															variant="outlined"
															className="regCountry"
															validators={["required"]}
															errormessages={["This field is required"]}
															required
														/>
													)}
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-phone">
													Phone
												</label>
												<TextField
													type="number"
													className="textField"
													fullWidth
													id="basic-default-phone"
													variant="outlined"
													placeholder="Phone"
													aria-label="Phone"
													value={this.state.phone}
													onChange={this.handlePhoneChange.bind(this)}
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-email">
													Status
												</label>
												<Tooltip title="Please click 'Click’ to change Status">
													<span className="ms-4">
														<ToggleButton
															value={this.state.isStatus ? "Active" : "InActive"}
															className="ToggleButtonActive"
															onChange={this.handleStatusChange.bind(this)}
															style={this.state.isStatus ?
																{
																	background: '#0B6F9D',
																	color: '#fff',
																	height: '33px',
																	width: "80px",
																	borderRadius: "5px",
																	textTransform: 'none',
																} :
																{
																	background: '#D7DBDD',
																	color: '#000',
																	height: '33px',
																	width: "80px",
																	borderRadius: "5px",
																	textTransform: 'none',
																}
															}
														>
															{this.state.isStatus ? "Active" : "InActive"}
														</ToggleButton>
													</span>
												</Tooltip>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row d-flex align-items-center justify-content-between">
							<div className="col-sm-12 mt-3 text-center">
								<SaveButton id="saveButton" type="submit"> Save </SaveButton>
								&nbsp;&nbsp;
								<CancelButton onClick={(e) => this.editCancel()} />
							</div>
						</div>
					</ValidatorForm>
				</div >
			</>
		)
	};
}
export default AdminAppUser;
