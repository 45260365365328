import React, { Component } from "react";
import { Row } from "reactstrap";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import "./Purchase.scss";
import FetchServerData from "../../../provider/FetchServerData";
import masterDataAction from "../../../redux/masterData/masterDataAction";

export class Purchase extends Component {
  header = store.getState().header.header;
  breadCrumb = [{ pageid: "/purchase", uiname: "Purchase", cssclass: "fa fa-cart-arrow-down" }];
  title = "Purchase";
  constructor(props) {
    super(props);
    let menu = null;
    if (this.props.location && this.props.location.pathname) {
      let pathname = this.props.location.pathname;
      let char = pathname.slice(-1);
      if (char !== "/") {
        pathname = pathname + "/";
      }
      menu = Utils.getMenu(pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      // this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    if (menu) {
      store.dispatch(appDataAction.setActiveMenu(menu.id));
    }
    let menuArray = Utils.getSubMenus(menu.id);

    this.state = {
      menuArray: menuArray,
      render: true,
    };
  }
  componentDidMount() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/masterms/getMasterData", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let masterData = output.data;
          this.customerList = masterData.customers;
          this.productList = masterData.products;
          this.custHsnCodeList = masterData.custhsncodes;
          this.bankAccountList = masterData.bankaccounts;
          this.businessUnitList = masterData.businessUnits;
          store.dispatch(masterDataAction.setMasterData(masterData));
          this.setState({ render: true });
        }
      }
    );
  }
  render() {
    if (this.state.render === false) {
      return "";
    }

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <div style={{ justifyContent: "center", margin: "0 auto" }}>
          <Row
            md={12}
            style={{ paddingTop: "3em", margin: "0 auto", width: "100%" }}
          >
            {UiUtils.getUiMenuArray(
              this.state.menuArray,
              this.props.history,
              this.state.color
            )}
          </Row>
        </div>
      </>
    );
  }
}

export default Purchase;
