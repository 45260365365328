import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RepeatIcon from "@material-ui/icons/Repeat";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Autocomplete } from "@mui/material";
import { format } from "date-fns";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import CancelButton from "../../buttons/CancelButton";
import CreateButton from "../../buttons/CreateButton";
// import RefreshButton from "../../buttons/RefreshButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import SalesInvoiceDialogue from "../../sales/SalesInvoiceDialogue";
import PtsAlert from "../../ptsAlert/PtsAlert";
import DeleteIconButton from "../../buttons/DeleteIconButton";

export class GstR1 extends Component {
  filingFrequencyList = store.getState().appData.filingFrequencyList;
  monthList = Utils.getMonthList();
  quarterList = Utils.quarterList();
  gstr1Credentials = store.getState().appData.gstr1Credentials;
  yearList = store.getState().appData.fyList;
  header = store.getState().header.header;
  title = "GSTR-1";
  constructor(props) {
    super(props);

    if (this.gstr1Credentials && this.gstr1Credentials.length > 0) {
      this.fileFrequency = this.gstr1Credentials[0].filefrequency.frequency;
    }

    this.tableIcons = UiUtils.getMaterialTableIcons();
    let gstr1Id = props.gstr1Id;
    // let fperiod = props.fperiod;
    let action = props.action;
    if (
      !gstr1Id &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      gstr1Id = props.history.location.state.gstr1Id;
      // fperiod = props.history.location.state.fperiod;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }
    let canRender = false;
    if (gstr1Id) {
      this.getGstr1Details(gstr1Id);
    } else {
      canRender = true;
    }
    this.state = {
      id: gstr1Id,
      quarterMonth: null,
      year: "",
      month: null,
      createdDate: "",
      period: {},
      action: action,
      render: canRender,
      status: "",
      fperiod: "",
      financialPeriod: "",
      gstOtp: null,
      customerType: {},
      invoiceTypes: {},
      gstr1Customers: [],
      gstr1Invoices: [],
      quarterList: [],
      fileFrequency: this.fileFrequency,
      isGstOTP: false,
    };
    // this.getPeriodDetails();
    this.getQuarterList();
  }

  getQuarterList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService(
      "/orgfinance/getQuarterList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let quarterList = output.data;
        this.setState({
          // render: true,
          quarterList: quarterList,
        });
      }
    });
  }

  handleYearChange(e, selected) {
    this.setState({ year: selected });
  }

  handleQuarterlyFinancialsChange(value) {
    this.setState({ quarterMonth: value });
  }
  handleMonthlyGSTR1Change(value) {
    this.setState({ month: value });
  }

  handlePeriodChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      period: selectedType,
    });
  }

  handleGstr1CustomerChange(e, value) {
    this.setState({ customer: value });
  }

  handleGstr1InvoiceTypeChange(e, value) {
    this.setState({ invoiceType: value });
  }

  cancelEdit(e) {
    this.props.history.push({
      pathname: "/gstr1view",
      state: {
        gstr1Id: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
    // this.setState({ action: 'view' });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/gstr1list/",
      state: { action: "view", breadCrumb: this.breadCrumb },
    });
  }
  getGstr1Details = (gstr1Id) => {
    const postObject = {
      gstr1: {
        id: gstr1Id,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1/refresh", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let gstr1Data = output.data;
          let gstr1Invoices = [];
          let gstr1customers = gstr1Data.gstr1customers;
          let invoiceTypes = [];
          let tempInvoiceTypes = [];
          let customerList = [];
          let tempCustomerList = [];
          for (let i = 0; i < gstr1customers.length; i++) {
            if (!tempCustomerList.includes(gstr1customers[i].customer.id)) {
              tempCustomerList.push(gstr1customers[i].customer.id);
              customerList.push(gstr1customers[i].customer);
            }
            for (let j = 0; j < gstr1customers[i].gstr1invoices.length; j++) {
              gstr1Invoices.push(gstr1customers[i].gstr1invoices[j].invoice);
              if (
                !tempInvoiceTypes.includes(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
                )
              ) {
                tempInvoiceTypes.push(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
                );
                invoiceTypes.push(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo
                );
              }
            }
          }
          // let cdate = gstr1Data.createdon;
          // let createdDate = new Date(cdate);
          // let dd = createdDate.getDate();
          // let mm = createdDate.getMonth() + 1;
          // let yyyy = createdDate.getFullYear();
          // createdDate = dd + "/" + mm + "/" + yyyy;
          this.setState({
            id: gstr1Data.id,
            createdDate: gstr1Data.createdon,
            status: gstr1Data.status,
            financialPeriod: gstr1Data.financialPeriod,
            fperiod: gstr1Data.fp,
            gstr1Invoices: gstr1Invoices,
            invoiceTypes: invoiceTypes,
            customerList: customerList,
            gstr1Customers: gstr1Data.gstr1customers,
            privileges: gstr1Data.privileges,
            render: true,
          });
        } else {
        }
      }
    );
  };

  refreshGstr1(fperiod) {
    const postObject = {
      gstr1: {
        fp: fperiod,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1/refresh", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let gstr1Data = output.data;
          let gstr1Invoices = [];
          let gstr1customers = gstr1Data.gstr1customers;
          let invoiceTypes = [];
          let tempInvoiceTypes = [];
          let customerList = [];
          let tempCustomerList = [];
          for (let i = 0; i < gstr1customers.length; i++) {
            if (!tempCustomerList.includes(gstr1customers[i].customer.id)) {
              tempCustomerList.push(gstr1customers[i].customer.id);
              customerList.push(gstr1customers[i].customer);
            }
            for (let j = 0; j < gstr1customers[i].gstr1invoices.length; j++) {
              gstr1Invoices.push(gstr1customers[i].gstr1invoices[j].invoice);
              if (
                !tempInvoiceTypes.includes(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
                )
              ) {
                tempInvoiceTypes.push(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
                );
                invoiceTypes.push(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo
                );
              }
            }
          }
          // let cdate = gstr1Data.createdon;
          // let createdDate = new Date(cdate);
          // let dd = createdDate.getDate();
          // let mm = createdDate.getMonth() + 1;
          // let yyyy = createdDate.getFullYear();
          // createdDate = dd + "/" + mm + "/" + yyyy;
          this.setState({
            id: gstr1Data.id,
            createdDate: gstr1Data.createdon,
            status: gstr1Data.status,
            fperiod: gstr1Data.fp,
            financialPeriod: gstr1Data.financialPeriod,
            gstr1Invoices: gstr1Invoices,
            invoiceTypes: invoiceTypes,
            customerList: customerList,
            gstr1Customers: gstr1Data.gstr1customers,
            privileges: gstr1Data.privileges,
            render: true,
          });
        } else {
        }
      }
    );
  }

  getPeriodDetails = () => {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1cread/getGstCred", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let periodFreq = output.data;

          this.setState({
            period: periodFreq.filefrequency,
          });
        } else {
        }
      }
    );
  };

  createGSTR1Backend = () => {
    // let fp = Utils.getMonthNumber(this.state.quarterMonth) + this.state.year;
    const postObject = {
      fyear: this.state.year,
      quarter: this.state.quarterMonth,
      month: this.state.month,
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let gstr1Data = output.data;
          this.props.history.push({
            pathname: "/gstr1view/",
            state: { gstr1Id: gstr1Data.id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  saveToGst() {
    const postObject = {
      header: this.header,
      gstr1: {
        id: this.state.id,
        fp: this.state.fperiod,
      },
      otp: this.state.gstOtp,
    };

    FetchServerData.callPostService("/connector/save", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let gstr1Data = output.data;
          let gstr1VoData = output.data.gstr1Vo;
          let gstr1Invoices = [];
          let gstr1customers = gstr1VoData.gstr1customers;
          let invoiceTypes = [];
          let tempInvoiceTypes = [];
          let customerList = [];
          let tempCustomerList = [];
          let isGstOTP = false;
          if (gstr1Data?.saveResponse?.action === "OTPREQUESTED") {
            isGstOTP = true;
          }
          for (let i = 0; i < gstr1customers.length; i++) {
            if (!tempCustomerList.includes(gstr1customers[i].customer.id)) {
              tempCustomerList.push(gstr1customers[i].customer.id);
              customerList.push(gstr1customers[i].customer);
            }
            for (let j = 0; j < gstr1customers[i].gstr1invoices.length; j++) {
              gstr1Invoices.push(gstr1customers[i].gstr1invoices[j].invoice);
              if (
                !tempInvoiceTypes.includes(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
                )
              ) {
                tempInvoiceTypes.push(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
                );
                invoiceTypes.push(
                  gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo
                );
              }
            }
          }

          this.setState({
            id: gstr1VoData.id,
            createdDate: gstr1VoData.createdon,
            status: gstr1VoData.status,
            fperiod: gstr1VoData.fp,
            financialPeriod: gstr1VoData.financialPeriod,
            gstr1Invoices: gstr1Invoices,
            invoiceTypes: invoiceTypes,
            customerList: customerList,
            gstr1Customers: gstr1VoData.gstr1customers,
            privileges: gstr1VoData.privileges,
            isGstOTP: isGstOTP,
            render: true,
          });
        } else {
        }
      }
    );
  }

  // saveToGst() {
  //   const postObject = {
  //     header: this.header,
  //     gstr1: {
  //       id: this.state.id,
  //       fp: this.state.fperiod,
  //     },
  //     otp: this.state.gstOtp,
  //   };

  //   FetchServerData.callPostService("/connector/save", postObject).then(
  //     (output) => {
  //       if (output.status === "SUCCESS") {
  //         PtsAlert.success(output.message);
  //         let gstr1Data = output.data;
  //         let isGstOTP = false;
  //         if (gstr1Data.action === "OTPREQUESTED") {
  //           isGstOTP = true;
  //         }
  //         this.setState({
  //           isGstOTP: isGstOTP,
  //           render: true,
  //         });
  //       } else {
  //       }
  //     }
  //   );
  // }

  getSaveSummary() {
    const postObject = {
      header: this.header,
      gstr1: {
        id: this.state.id,
        fp: this.state.fperiod,
      },
      otp: null,
    };

    FetchServerData.callPostService(
      "/connector/getSaveSummary",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
      } else {
      }
    });
  }

  getSaveStatus() {
    const postObject = {
      header: this.header,
      gstr1: {
        id: this.state.id,
        fp: this.state.fperiod,
      },
      otp: null,
    };

    FetchServerData.callPostService(
      "/connector/getSaveStatus",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let gstr1VoData = output.data.gstr1Vo;
        let gstr1Invoices = [];
        let gstr1customers = gstr1VoData.gstr1customers;
        let invoiceTypes = [];
        let tempInvoiceTypes = [];
        let customerList = [];
        let tempCustomerList = [];

        for (let i = 0; i < gstr1customers.length; i++) {
          if (!tempCustomerList.includes(gstr1customers[i].customer.id)) {
            tempCustomerList.push(gstr1customers[i].customer.id);
            customerList.push(gstr1customers[i].customer);
          }
          for (let j = 0; j < gstr1customers[i].gstr1invoices.length; j++) {
            gstr1Invoices.push(gstr1customers[i].gstr1invoices[j].invoice);
            if (
              !tempInvoiceTypes.includes(
                gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
              )
            ) {
              tempInvoiceTypes.push(
                gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo.type
              );
              invoiceTypes.push(
                gstr1customers[i].gstr1invoices[j].invoice.invoicetypeVo
              );
            }
          }
        }

        this.setState({
          id: gstr1VoData.id,
          createdDate: gstr1VoData.createdon,
          status: gstr1VoData.status,
          fperiod: gstr1VoData.fp,
          financialPeriod: gstr1VoData.financialPeriod,
          gstr1Invoices: gstr1Invoices,
          invoiceTypes: invoiceTypes,
          customerList: customerList,
          gstr1Customers: gstr1VoData.gstr1customers,
          privileges: gstr1VoData.privileges,
          render: true,
        });
      } else {
      }
    });
  }

  submitToGst() {}
  getSubmitSaveSummary() {}
  /* Get Bttons Based on Invoice Status and Permissions*/
  getButtonsAsPerStatusAndPermission() {
    let status = this.state.status ? this.state.status.status : "";
    // let privilegeList = this.privilegeList;
    if (
      Utils.equalsIgnoreCase("CREATED", status) ||
      Utils.equalsIgnoreCase("UPDATED", status)
    ) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.saveToGst(e)}
          >
            Save To GST
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("SAVEREQUESTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.getSaveStatus(e)}
          >
            Get Save Status
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("SAVED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.getSaveSummary(e)}
          >
            Get Save Summary
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.submitToGst(e)}
          >
            Submit To GST
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else if (Utils.equalsIgnoreCase("SUBMITREQUESTED", status)) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<RepeatIcon />}
            onClick={(e) => this.getSubmitSaveSummary(e)}
          >
            Get Submit Summary
          </Button>

          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    }
  }

  deleteGSTR1InBackend() {
    const postObject = {
      gstr1: {
        id: this.state.id,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1/delete", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("GSTR1 Deleted");
          this.props.history.push("/gstr1list");
        } else {
        }
      }
    );
  }

  gstOTPToggle() {
    this.setState({
      isGstOTP: false,
    });
  }

  handleGstOTPChange(e) {
    this.setState({
      gstOtp: e.target.value,
    });
  }
  cancelGstr1Otp() {
    this.setState({
      gstOtp: null,
      isGstOTP: false,
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.menuWithBreadScrumRef = React.createRef();
    this.referenceInvoiceDialogueRef = React.createRef();

    if (this.state.action === "create") {
      return this.createGSTR1();
      // }
      // if (this.state.action === 'edit') {
      //   return this.editGSTR1();
    } else {
      return this.viewGSTR1();
    }
  }

  createGSTR1() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"View " + this.title}
          history={this.props.history}
          name="Create GSTR-1"
        />

        <ValidatorForm
          style={{ width: "100%", color: "#000", marginTop: "1em" }}
          ref="form"
          onSubmit={this.createGSTR1Backend.bind(this)}
        >
          <Card>
            <CardBody>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Year<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    fullWidth
                    id="year"
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    options={this.yearList ? this.yearList : []}
                    value={this.state.year}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => {
                      this.handleYearChange(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        placeholder="Year"
                        className="regCountry"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
                {this.state.fileFrequency === "Quarterly" && (
                  <Col md={6} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Select Quarter<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="month"
                      options={
                        null === this.state.quarterList
                          ? []
                          : this.state.quarterList
                      }
                      value={this.state.quarterMonth}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        this.handleQuarterlyFinancialsChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Select Quarter"
                          InputLabelProps={{
                            shrink: true,
                            style: {},
                          }}
                        />
                      )}
                    />
                  </Col>
                )}
                {this.state.fileFrequency === "Monthly" && (
                  <Col md={6} style={{ padding: "5px" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      Select Month<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="month"
                      options={null === this.monthList ? [] : this.monthList}
                      value={this.state.month}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        this.handleMonthlyGSTR1Change(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Select Month"
                          InputLabelProps={{
                            shrink: true,
                            style: {},
                          }}
                        />
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row
                md={12}
                style={{ margin: 0, padding: 0, width: "100%" }}
              ></Row>
              {/* <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
                <Col md={6} style={{ padding: "5px" }}>
                  <Autocomplete
                    fullWidth
                    disabled
                    id="filingFrequency"
                    options={this.filingFrequencyList}
                    value={this.fileFrequency}
                    getOptionLabel={(option) => option}
                    // onChange={(event, value) => {
                    //   this.handleSignatureTypeChange(value);
                    // }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        className="regCountry"
                        {...params}
                        placeholder="Filing Frequency"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
              </Row> */}
            </CardBody>
            <CardFooter style={{ width: "100%" }}>
              <Row md={12}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <CreateButton type="submit" />
                  <CancelButton onClick={(e) => this.cancelView()} />
                </div>
              </Row>
            </CardFooter>
          </Card>
        </ValidatorForm>
      </div>
    );
  }

  showReferenceInvoice(e, rowData) {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current &&
      rowData.invnumber
    ) {
      this.referenceInvoiceDialogueRef.current.showInvoice(rowData.invnumber);
    }
  }
  viewGSTR1() {
    let filteredData = this.state.gstr1Invoices;
    let customer = this.state.customer;
    if (customer) {
      filteredData = filteredData.filter(function (e) {
        return e.customerVo.id === customer.id;
      });
    }
    let invoiceType = this.state.invoiceType;
    if (invoiceType) {
      filteredData = filteredData.filter(function (e) {
        return e.invoicetypeVo.type === invoiceType.type;
      });
    }

    return (
      <div style={{ display: "grid" }}>
        <SalesInvoiceDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
        />
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"View " + this.title}
          history={this.props.history}
          name="View GSTR-1 Details"
        />

        <Row md={12} style={{ padding: "0.5em" }}>
          <Col md={6} style={{ padding: "5px", textAlign: "start" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginLeft: "0.5rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          </Col>
          <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
            {(this.state.status?.status === "CREATED" ||
              this.state.status?.status === "UPDATED") && (
              <DeleteIconButton
                onClick={this.deleteGSTR1InBackend.bind(this)}
              />
            )}
          </Col>
        </Row>

        <Card>
          <CardHeader>
            <Row style={{ padding: "0.5em" }}>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Financial Period :</strong>
                </label>
                <span style={{ color: "#000" }}>
                  {this.state.financialPeriod}
                </span>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Created On Date :</strong>
                </label>
                <span style={{ color: "#000" }}>
                  {Utils.ptsDateFormat(this.state.createdDate)}
                </span>
              </Col>
              <Col md={3}>
                <label style={{ color: "#000" }}>
                  <strong>Status:</strong>
                </label>
                <span style={{ color: "#000" }}>
                  {this.state.status.status}
                </span>
              </Col>
              {/* <Col md={3}>
                <div style={{ float: "right" }}>
                  <RefreshButton
                    onClick={this.refreshGstr1.bind(this)}
                    style={{ float: "right" }}
                  />
                </div>
              </Col> */}
            </Row>
          </CardHeader>
          <CardBody>
            <Row style={{ padding: "0.5em" }}>
              <Col md={3}>
                <Autocomplete
                  fullWidth
                  id="month"
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  variant="outlined"
                  options={
                    this.state.customerList ? this.state.customerList : []
                  }
                  value={this.state.customer}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    this.handleGstr1CustomerChange(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="regCountry"
                      variant="outlined"
                      required
                      placeholder="Customer"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Col>
              <Col md={3}>
                <Autocomplete
                  fullWidth
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  id="invoiceType"
                  options={
                    this.state.invoiceTypes ? this.state.invoiceTypes : []
                  }
                  value={this.state.invoiceType}
                  getOptionLabel={(option) => option.description}
                  onChange={(event, value) => {
                    this.handleGstr1InvoiceTypeChange(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      className="regCountry"
                      placeholder="Invoice Type"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row md={12} style={{ padding: "0.5em" }}>
              <MaterialTable
                style={{ width: "100%", padding: "1em" }}
                icons={this.tableIcons}
                title="GSTR-1 Invoice List"
                columns={[
                  {
                    title: "#",
                    render: (rowData) => rowData.tableData.id + 1,
                    width: "5%",
                  },
                  { title: "Invoice Number", field: "invnumber", width: "20%" },
                  {
                    title: "Invoice Date",
                    render: (rowData) =>
                      format(new Date(rowData.invoicedate), "dd-MMM-yyyy"),
                    customSort: (a, b) => {
                      return (
                        new Date(a.invoicedate).getTime() -
                        new Date(b.invoicedate).getTime()
                      );
                    },
                    // fiyeld: 'invoicedate',
                    align: "left",
                    width: "20%",
                  },
                  {
                    title: "Customer",
                    render: (rowData) =>
                      rowData.customerVo ? rowData.customerVo.name : "",
                    align: "left",
                    width: "20%",
                  },
                  {
                    title: "Invoice Type",
                    render: (rowData) =>
                      rowData.invoicetypeVo
                        ? rowData.invoicetypeVo.description
                        : "",
                    align: "left",
                    width: "20%",
                  },
                ]}
                data={filteredData}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  pageSizeOptions: Utils.materialTablePageSize(),
                  pageSize: 10,
                  paging: filteredData.length > 10 ? filteredData : "",
                  headerStyle: {
                    backgroundColor: "#1b7189",
                    fontWeight: "bold",
                    color: "#fff",
                    align: "center",
                  },
                  rowStyle: {},
                }}
                // actions={actionList}
                onRowClick={(e, rowData) =>
                  this.showReferenceInvoice(e, rowData)
                }
              />
            </Row>
          </CardBody>
          <CardFooter>{this.getButtonsAsPerStatusAndPermission()}</CardFooter>
        </Card>
        <Dialog
          open={this.state.isGstOTP}
          // onClose={this.gstOTPToggle.bind(this)}
          style={{ padding: "1em" }}
        >
          <DialogContent style={{ marginTop: "1em", width: "350px" }}>
            <Row>
              <Col md={12} style={{ textAlign: "center" }}>
                <h4
                  style={{
                    color: "#162c50",
                    // margin: "1.1em 1em",
                    // padding: "1em",
                  }}
                >
                  {" "}
                  {/* {this.tollFreeNumberError ? this.tollFreeNumberError : ""} */}
                  Enter OTP Code
                </h4>{" "}
                {/* <br /> <br /> */}
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  inputProps={{ style: {} }}
                  InputLabelProps={{ style: {}, shrink: true }}
                  type="number"
                  placeholder="Enter OTP Code"
                  validators={["required"]}
                  onChange={this.handleGstOTPChange.bind(this)}
                  value={this.state.gstOtp}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions
            style={{
              padding: "1.5em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                // float: 'right',
                background: "#1B7189",
                color: "#fff",
              }}
              onClick={this.saveToGst.bind(this)}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              size="small"
              style={{
                // float: 'right',
                background: "#717373",
                color: "#fff",
              }}
              onClick={this.cancelGstr1Otp.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default GstR1;
