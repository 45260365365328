import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch, createMuiTheme } from "@material-ui/core";
import { Buffer } from "buffer";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import { exportComponentAsJPEG } from "react-component-export-image";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EmailIcon from "@material-ui/icons/Email";
// import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import MaterialTable from "material-table";
import Utils from "../../../../provider/Utils";
import ReactToPrint from "react-to-print";
import UiUtils from "../../../../provider/UiUtils";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Menu } from "@mui/material";
import appDataAction from "../../../../redux/appData/appDataAction";
import PtsAlert from "../../ptsAlert/PtsAlert";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});

export default class NonGstReport extends Component {
  breadCrumb = [];
  title = "Expense ";
  header = store.getState().header.header;
  org = store.getState().appData.settings.org;
  financialsYearArray = store.getState().appData.fyList;
  businessUnitList = store.getState().master.masterData.businessUnits;
  printPreviewRef = null;
  printComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.imageDwlRef = React.createRef();

    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      this.breadCrumb = props.history.location.state.breadCrumb;
      this.selectedTabIndex = props.history.location.state.selectedTabIndex;
    }

    this.state = {
      startDate: null,
      endDate: null,
      financialsYear: null,
      selectedBusinessUnit: this.businessUnitList[0],
      url: "",
      pdfdata: "",
      contentType: "application/pdf",
      expenseList: [],
      open: false,
      swichCheck: false,
      error: false,
      errorMassage: false,
      anchorEl: null,
    };
    this.getNonGstReportDetails();
  }

  handleStartDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.endDate);
    if (this.state.endDate === null && date === null) {
      this.setState({
        startDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (date === null) {
      this.setState({
        startDate: date,
        financialsYear: null,
        errorMassage: true,
      });
    } else if (fromDate > toDate) {
      this.setState({
        startDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        startDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleEndDateChange(date) {
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(date);
    if (this.state.startDate === null && date === null) {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (this.state.startDate === null) {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: true,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        endDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleSwichCheckChange(e) {
    this.setState({ swichCheck: e.target.checked });
  }

  updateFinancialYear(e, year) {
    this.setState({ financialsYear: year });
  }

  updateBusinessUnit(e, name) {
    this.setState({ selectedBusinessUnit: name });
  }

  getNonGstReportDetails(e) {
    // e.preventDefault();
    const postObject = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      financialYear: this.state.financialsYear,
      bu: {
        id: this.state.selectedBusinessUnit?.id,
        code: this.state.selectedBusinessUnit?.code,
      },
      header: this.header,
    };
    FetchServerData.callPostServiceForPDFReportData(
      "/reports/getExpenseList",
      postObject
    ).then((output) => {
      // let doc = "data:" + this.state.contentType + ";base64," + output.entity;
      // this.setState({ render: true, pdfdata: doc, });
      if (output.status === "SUCCESS") {
        let expenseData = output.data;
        let expenseList = output.data.expenseList;
        for (let i = 0; i < expenseList.length; i++) {
          expenseList[i].expdate = Utils.ptsDateFormat(expenseList[i].expdate);
        }
        this.setState({
          render: true,
          defaultStartDate: expenseData.fromDate,
          defaultEndDate: expenseData.toDate,
          startDate: expenseData.fromDate,
          endDate: expenseData.toDate,
          expenseList: expenseList,
          swichCheck: this.state.financialsYear === null ? true : false,
          open: false,
          anchorEl: null,
        });
      } else {
      }
    });
  }
  cancelView(e) {
    store.dispatch(appDataAction.setReportTab(this.selectedTabIndex));
    this.props.history.push({
      pathname: "/reports/",
    });
  }

  emailSalesVoucher() {}

  downloadImage() {
    let fileName = "Expense List";
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    if (this.imageDwlRef) {
      exportComponentAsJPEG(this.imageDwlRef, {
        fileName: fileName,
      });
    }
  }

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  filterRecords(e) {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }
    this.setState({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      financialsYear: this.state.financialsYear,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
    });
    this.getNonGstReportDetails();
  }
  resetFilter() {
    this.state.startDate = null;
    this.state.endDate = null;
    this.state.financialsYear = null;
    this.state.selectedBusinessUnit = this.businessUnitList[0];
    this.state.error = false;
    this.state.errorMassage = false;
    this.getNonGstReportDetails();
  }

  excelDownloadExpenseList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService(
      "/expenseimpexp/exportAllData",
      postObject
    ).then((output) => {
      let SupplierExcelData = output.data;
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        const byteArray = new Buffer(
          SupplierExcelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );
        const outputFilename = SupplierExcelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
      }
    });
  }

  render() {
    let logoData = Utils.getLogo();
    return (
      <>
        <MenuWithBreadScrum
          name={this.title}
          addToBreadCrumb={this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          backCallback={this.cancelView.bind(this)}
        />
        <Row md={12} style={{ padding: "5px" }}>
          <Col md={6} style={{ padding: "5px", alignItems: "center" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginLeft: "0.5rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          </Col>
          <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
            <ReactToPrint
              trigger={
                () => (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<PrintIcon />}
                    // onClick={(e) => this.printContent(e)}
                  >
                    Print
                  </Button>
                )
                // <Button>Print this out!</Button>
              }
              content={() => this.printComponentRef}
            />
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#5ac489",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={
                <i className="fa fa-download" style={{ fontSize: "16px" }}></i>
              }
              onClick={(e) => this.excelDownloadExpenseList()}
            >
              Download Excel
            </Button>
            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#9a7031",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<GetAppIcon />}
              onClick={(e) => this.downloadImage(e)}
              // onClick={() => exportComponentAsJPEG(this.imageDwlRef)}
            >
              Download
            </Button> */}

            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                // marginRight: "1rem",
              }}
              startIcon={<EmailIcon />}
              onClick={(e) => this.emailSalesVoucher(e)}
            >
              Email
            </Button> */}
          </Col>
        </Row>
        <div className="row mb-3">
          <div className="col-6 align-items-start text-start">
            <span className="ms-2">
              {this.state.selectedBusinessUnit &&
              this.state.selectedBusinessUnit !== null ? (
                <span>
                  <b>Business Unit : </b>
                  {this.state.selectedBusinessUnit.name + ", "}
                </span>
              ) : (
                ""
              )}
              {this.state.defaultStartDate &&
              this.state.defaultStartDate !== null ? (
                <span>
                  <b>Start Date : </b>
                  {Utils.ptsDateFormat(this.state.defaultStartDate) + ", "}
                </span>
              ) : (
                ""
              )}
              &nbsp;
              {this.state.defaultEndDate &&
              this.state.defaultEndDate !== null ? (
                <span>
                  <b>End Date : </b>
                  {Utils.ptsDateFormat(this.state.defaultEndDate) + ", "}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="col-6 align-items-end text-end">
            <span
              className="text-end me-1"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>
            {this.state.isFirstTimeLoading ? (
              ""
            ) : (
              <span
                className="me-1"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.resetFilter.bind(this)}
              >
                {"  "} &nbsp;&nbsp;
                <i class="fa fa-refresh" aria-hidden="true"></i> Reset
              </span>
            )}
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Filter</h5>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Business Unit
                </label>
                <Autocomplete
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  margin="normal"
                  className="expenseAutoComplete"
                  id="businessUnit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  options={this.businessUnitList}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Business Unit"
                      {...params}
                      style={{}}
                      variant="outlined"
                      className="regCountry"
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0px",
                    padding: "0",
                  }}
                  control={
                    <Switch
                      variant="outlined"
                      // id="isAsset"
                      checked={this.state.swichCheck}
                      value={this.state.swichCheck}
                      onChange={this.handleSwichCheckChange.bind(this)}
                      style={{ background: "none", margin: "8px" }}
                    />
                  }
                  label={<span>Custom search </span>}
                />
              </div>
              {!this.state.swichCheck && (
                <div className="col-md-12 mt-2" id="assetFormLabel">
                  <label className="form-label ">Financial Year</label>
                  <br />
                  <Autocomplete
                    fullWidth
                    variant="outlined"
                    id="supplierList"
                    ListboxProps={{ className: "myCustomList" }}
                    options={this.financialsYearArray}
                    value={this.state.financialsYear}
                    defaultValue={this.state.financialsYear}
                    getOptionLabel={(option) => option}
                    style={{ background: "#fff", borderRadius: "5px" }}
                    onChange={(event, value) => {
                      this.updateFinancialYear(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Financial Year"
                        className="regCountry"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {this.state.swichCheck && (
                <>
                  <div className="col-md-6 mt-2" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Start Date
                    </label>
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.startDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleStartDateChange.bind(this)}
                          helperText={
                            this.state.errorMassage
                              ? "Start Date is Required"
                              : ""
                          }
                          error={this.state.errorMassage}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-6 mt-2" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      End Date
                    </label>{" "}
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.endDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleEndDateChange.bind(this)}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  {this.state.error ? (
                    <lable className="errorMassageShow">
                      End Date should be after Start Date
                    </lable>
                  ) : (
                    ""
                  )}
                </>
              )}
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  // onClick={this.onFilterClick.bind(this)}
                  onClick={(e) => this.filterRecords(e)}
                >
                  <i className="fa-solid fa-filter"></i>
                  &nbsp; Filter
                </button>
                &nbsp; &nbsp;
                <button
                  className="resetButton"
                  onClick={this.resetFilter.bind(this)}
                >
                  <i className="fa fa-refresh"></i>
                  &nbsp; Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>
        <Row>
          <Col md={12}>
            <div ref={(el) => (this.printComponentRef = el)}>
              <Card
                ref={(el) => (this.printComponentRef = el)}
                md={12}
                id="printform"
                // ref={this.printComponentRef}
                // className="a4size"
                style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
              >
                <div ref={this.imageDwlRef}>
                  <CardBody style={{ color: "#000" }}>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "5px",
                        display: "flex",
                        borderLeft: "1px solid gray",
                        borderTop: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Col md={2}>
                        {Utils.isNotNullAndEmpty(logoData) && (
                          <img
                            src={logoData}
                            alt=""
                            style={{
                              height: "3em",
                              width: "3em",
                              padding: "0.1em",
                            }}
                          />
                        )}
                      </Col>
                      <Col md={8}>
                        <Row>
                          <strong
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            {Utils.getOrgName()}
                          </strong>
                        </Row>
                        <Row>
                          <span style={{ width: "100%", textAlign: "center" }}>
                            {Utils.getAddressAsText(this.org?.addressvo)}
                          </span>
                        </Row>
                      </Col>
                      <Col md={2}>
                        <span style={{ width: "100%", textAlign: "center" }}>
                          {/* {this.state.duplicateTriplicate} */}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "5px",
                        display: "flex",
                        borderLeft: "1px solid gray",
                        borderTop: "1px solid gray",
                        borderBottom: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Col
                        md={12}
                        style={{ fontSize: "x-large", textAlign: "center" }}
                      >
                        <strong>
                          Expense List
                          {/* {this.state.invoiceheading} */}
                        </strong>
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderRight: "1px solid gray",
                        borderLeft: "1px solid gray",
                        borderBottom: "1px solid gray",
                      }}
                      id="supplierReport"
                    >
                      {/* <Table style={{ margin: 0, padding: 0 }}>
                      {this.getHeader()}
                      <TableBody>{supplierListList} </TableBody>
                    </Table> */}
                      <MaterialTable
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        columns={[
                          {
                            title: "#",
                            render: (rowData) => rowData.tableData.id + 1,
                            cellStyle: {
                              width: "5%",
                            },
                            align: "left",
                            filtering: false,
                          },
                          {
                            title: "Date",
                            field: "expdate",
                            filtering: false,
                            width: "22%",
                          },
                          {
                            title: "Expense Number",
                            field: "expnumber",
                            width: "25%",
                            filtering: false,
                          },
                          {
                            title: "Purpose",
                            field: "purpose",
                            filtering: false,
                            width: "30%",
                          },
                          {
                            title: "Supplier",
                            field: "supplier",
                            align: "left",
                            cellStyle: {
                              width: "20%",
                              wordBreak: "break-word",
                            },
                          },
                          {
                            title: "Total",
                            render: (rowData) =>
                              rowData.currencysymbol +
                              " " +
                              parseFloat(rowData.amount).toFixed(2),
                            // field: "amount",
                            filtering: false,
                            width: "15%",
                            align: "right",
                            headerStyle: {
                              textAlign: "right",
                            },
                            customSort: (a, b) => {
                              return (
                                parseFloat(a.amount) - parseFloat(b.amount)
                              );
                            },
                            customFilterAndSearch: (term, rowData) =>
                              parseFloat(rowData.amount) === parseFloat(term),
                          },
                          // {
                          //   title: "Status",
                          //   field: "statusdescription",
                          //   align: "center",
                          //   cellStyle: {
                          //     width: "15%",
                          //   }
                          // },
                        ]}
                        data={this.state.expenseList}
                        options={{
                          sorting: true,
                          actionsColumnIndex: -1,
                          search: false,
                          paging: false,
                          toolbar: false,
                          headerStyle: {
                            backgroundColor: "#ececec",
                            fontWeight: "bold",

                            color: "#000",
                            align: "left",
                            textAlign: "left",
                          },

                          rowStyle: (rowData) => ({
                            // height: "3em",
                            textTransform: "capitalize",
                          }),
                        }}
                        actions={[]}
                      />
                    </Row>{" "}
                  </CardBody>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
