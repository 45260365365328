// import { Card } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { Component } from "react";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import store from "../../../redux/store";
import Role from "./Role";
import AddIconButton from "../buttons/AddIconButton";
import Utils from "../../../provider/Utils";
export class RoleList extends Component {
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      roles: props.data,
      onChangeSettings: this.props.onChange,
      render: false,
      action: this.props.action,
      pageSize: 10,
      isPaging: false,
      initialStep: 0,
    };
    this.getProfileList();
  }

  getProfileList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/profilems/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let profileList = output.data.profileList;
          let isPaging = false;
          if (profileList.length > 10) {
            isPaging = true;
          }
          this.setState({
            roles: profileList,
            isPaging: isPaging,
            render: true,
            showAlert: true,
            alertMessage: "Profile List Fetched Successfully",
          });
        } else {
        }
      }
    );
  }

  addRole = (e) => {
    this.setState({ action: "add_role", render: true });
  };

  roleChanged(changedAccount) {
    //this.props.onChange(changedAccount);
  }

  roleAdded(changedAccount) {
    //  this.props.onChange(changedAccount);
  }
  viewRole = (e, data) => {
    this.setState({ selectedAccount: data, action: "show_role" });
  };
  render() {
    if (this.state.action === "add_role") {
      return (
        <Role
          action="create"
          title="New Role"
          data={this.state.selectedAccount}
          roles={this.state.roles}
          onChange={this.roleAdded.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    }
    if (this.state.action === "show_role") {
      return (
        <Role
          action="view"
          title="Role Details"
          data={this.state.selectedAccount}
          roles={this.state.roles}
          onChange={this.roleAdded.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    }
    if (this.state.action === "view") {
      return this.showList();
    }
  }

  showList() {
    return (
      // <Card
      //   md={12}
      //   style={{
      //     width: "100%",
      //     border: "0.5px solid #1b7189",
      //     boxShadow: "2px 2px 2px lightgrey",
      //   }}
      // >
      <MaterialTable
        icons={this.tableIcons}
        localization={{
          body: {
            emptyDataSourceMessage: Utils.communizeTableText(),
          },
        }}
        title="Profile List"
        columns={[
          {
            title: "#",
            render: (rowData) => rowData.tableData.id + 1,
            cellStyle: {
              width: "5%",
            }
          },
          {
            title: "Profile",
            field: "name",
            align: "left",
          },
        ]}
        data={this.state.roles}
        column={{
          headerStyle: {
            color: "#fff",
          },
        }}
        options={{
          sorting: true,
          actionsColumnIndex: -1,
          paging: this.state.isPaging,
          pageSize: this.state.pageSize,
          initialPage: this.state.initialPage,
          headerStyle: {
            fontSize: "small !important",
            background: "#1b7189",
            fontWeight: "bold",
            color: "#fff",
            align: "center",
          },
          rowStyle: {},
          searchFieldStyle: {},
        }}
        actions={[
          {
            icon: () => <AddIconButton />,
            tooltip: "Add Profile",
            isFreeAction: true,
            onClick: (event) => this.addRole(),
          },
        ]}
        onRowClick={(e, rowData) => this.viewRole(e, rowData)}
      />
      // </Card>
    );
  }
}
export default RoleList;
