import { GET_REGISTRATIONINFO, SET_REGISTRATIONINFO } from './registrationDataTypes';
const initialState = {
  registrationInfo: {},
};

const registrationDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGISTRATIONINFO:
      return {
        ...state,
      };
    case SET_REGISTRATIONINFO:
      return {
        ...state,
        registrationInfo: action.payload,
      };
    default:
      return state;
  }
};

export default registrationDataReducer;
