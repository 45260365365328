import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import { exportComponentAsJPEG } from "react-component-export-image";
import FetchServerData from "../../../../provider/FetchServerData";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ReactToPrint from "react-to-print";
import "./SupplierWiseReport.scss";
import { Col, Row, Card, CardBody } from "reactstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import _ from "lodash";
import TableRow from "@material-ui/core/TableRow";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch, createMuiTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import appDataAction from "../../../../redux/appData/appDataAction";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});

export default class SupplierWiseReport extends Component {
  breadCrumb = [];
  title = "Supplier wise Purchase";
  printComponentRef = React.createRef();
  org = store.getState().appData.settings.org;
  financialsYearArray = store.getState().appData.fyList;
  businessUnitList = store.getState().master.masterData.businessUnits;

  constructor(props) {
    super(props);
    this.imageDwlRef = React.createRef();

    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      this.breadCrumb = props.history.location.state.breadCrumb;
      this.selectedTabIndex = props.history.location.state.selectedTabIndex;
    }
    let sortType = "";
    let selectedSort = "";
    if (sortType === "asc") {
      selectedSort = "des";
    } else {
      selectedSort = "asc";
    }

    this.state = {
      startDate: null,
      endDate: null,
      supplierList: [],
      supplier: null,
      url: "",
      pdfdata: "",
      contentType: "application/pdf",
      selectedBusinessUnit: this.businessUnitList[0],
      supplierWisePurchaseList: [],
      CustomerList: [],
      supplierWiseChartData: [],
      supplierOptions: null,
      supplierSeries: null,
      selectedSort: selectedSort,
      sortType: sortType,
      column: null,
      direction: null,
      financialsYear: null,
      defaultFinancialYear: null,
      swichCheck: false,
      error: false,
      errorMassage: false,
      render: false,
    };

    this.getSupplierWiseReportDetails();
  }

  // handleStartDateChange(date) {
  //   this.setState({ startDate: date });
  // }

  // handleEndDateChange(date) {
  //   this.setState({ endDate: date });
  // }

  handlesupplierChange(selectedType) {
    this.setState({
      supplier: selectedType,
    });
  }

  getSupplierWiseReportDetails() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      financialYear: this.state.financialsYear,
      bu: {
        id: this.state.selectedBusinessUnit?.id,
        code: this.state.selectedBusinessUnit?.code,
      },
    };
    FetchServerData.callPostServiceForPDFReportData(
      "/reports/getSupPurchaseList",
      postObject
    ).then((output) => {
      let supplierWiseReportData = output.data;
      let supplierWiseChartData =
        supplierWiseReportData.supplierwisePurchaseList;
      let supplierLabels = [];
      let purchaseAmount = [];
      let advanceValue = [];
      let creditValue = [];
      let debitValue = [];
      let refundValue = [];

      for (let i = 0; i < supplierWiseChartData.length; i++) {
        let date = supplierWiseChartData[i].supplierName;
        supplierLabels.push(date);
        purchaseAmount.push(supplierWiseChartData[i].purchaseAmount);
        advanceValue.push(supplierWiseChartData[i].advanceAmount);
        creditValue.push(supplierWiseChartData[i].creditAmount);
        debitValue.push(supplierWiseChartData[i].debitAmount);
        refundValue.push(supplierWiseChartData[i].refundAmount);
      }
      this.setState({
        defaultStartDate: supplierWiseReportData.fromDate,
        defaultEndDate: supplierWiseReportData.toDate,
        defaultFinancialYear: supplierWiseReportData.financialYear,
        startDate: supplierWiseReportData.fromDate,
        endDate: supplierWiseReportData.toDate,
        supplierWiseChartData: supplierWiseChartData,
        supplierWisePurchaseList: supplierWiseChartData,
        swichCheck: this.state.financialsYear === null ? true : false,
        render: true,
        open: false,
        supplierSeries: [
          {
            name: "Purchase",
            type: "bar",
            data: purchaseAmount,
            marginTop: "10px",
          },
          {
            name: "Advance",
            type: "bar",
            data: advanceValue,
          },
          {
            name: "Credit",
            type: "bar",
            data: creditValue,
          },
          {
            name: "Debit",
            type: "bar",
            data: debitValue,
          },
          {
            name: "Refund",
            type: "bar",
            data: refundValue,
          },
        ],
        supplierOptions: {
          chart: {
            type: "bar",
            height: 430,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          legend: {
            position: "top",
          },
          colors: [
            "#2E93fA",
            "#FF9800",
            "rgb(0, 227, 150)",
            "#ff3e1d",
            "rgb(135, 139, 182)",
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: "top",
              },
            },
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            hideOverlappingLabels: false,
            enabled: false,
            // offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: supplierLabels,
            labels: {
              style: {
                colors: "#247BA0",
              },
            },
            title: {
              text: "Supplier",
              style: {
                color: "#247BA0",
                fontSize: "14px",
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#247BA0",
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#247BA0",
              },
              title: {
                text: "Purchase Invoice Amount",
                style: {
                  color: "#247BA0",
                  fontSize: "14px",
                },
              },
              labels: {
                formatter: function (val) {
                  return val?.toFixed(0);
                },
                style: {
                  colors: "#247BA0",
                },
              },
            },
          ],
        },
      });
    });
  }

  cancelView(e) {
    this.props.history.push({
      pathname: "/reports/",
      state: {},
    });
  }

  downloadImage() {
    let fileName = "Supplier Wise Purchase";
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    if (this.imageDwlRef) {
      exportComponentAsJPEG(this.imageDwlRef, {
        fileName: fileName,
      });
    }
  }
  // handleHeaderClick = (event) => {
  //   console.log(event.target.id);
  //   const id = event.target.id;
  //   const data = [...this.state.supplierWiseChartData];

  //   data.sort((a, b) => {
  //     if (a[id] < b[id]) return -1;
  //     if (a[id] > b[id]) return 1;
  //     //  if (this.state.sortType == "asc") {
  //     //    if (a[id] < b[id]) return -1;
  //     //    if (a[id] > b[id]) return 1;
  //     //  }
  //     //  if (this.state.sortType == "des") {
  //     //    if (a[id] < b[id]) return 1;
  //     //    if (a[id] > b[id]) return -1;
  //     //  }
  //   });
  //   // setPosts(data);
  //   this.setState({
  //     supplierWiseChartData: data,
  //   });
  // };
  handleSort = (clickedColumn) => () => {
    // const { column, supplierWisePurchaseList, direction } = this.state;

    if (this.state.column !== clickedColumn) {
      // this.setState({
      this.state.column = clickedColumn;
      this.state.supplierWisePurchaseList = _.sortBy(
        this.state.supplierWisePurchaseList,
        [clickedColumn]
      );
      this.state.direction = "ascending";
      // });

      return;
    }
    this.showChartData();
  };

  showChartData() {
    let supplierLabels = [];
    let purchaseAmount = [];
    let advanceValue = [];
    let creditValue = [];
    let debitValue = [];
    let refundValue = [];
    let sortedSupplierList = this.state.supplierWisePurchaseList.reverse();
    for (let i = 0; i < sortedSupplierList.length; i++) {
      let date = sortedSupplierList[i].supplierName;
      supplierLabels.push(date);
      purchaseAmount.push(sortedSupplierList[i].purchaseAmount);
      advanceValue.push(sortedSupplierList[i].advanceAmount);
      creditValue.push(sortedSupplierList[i].creditAmount);
      debitValue.push(sortedSupplierList[i].debitAmount);
      refundValue.push(sortedSupplierList[i].refundAmount);
    }
    this.setState({
      render: true,
      supplierWisePurchaseList: this.state.supplierWisePurchaseList.reverse(),
      supplierWiseChartData: this.state.supplierWisePurchaseList.reverse(),
      direction:
        this.state.direction === "ascending" ? "descending" : "ascending",
      supplierSeries: [
        {
          name: "Purchase",
          type: "bar",
          data: purchaseAmount,
          marginTop: "10px",
        },
        {
          name: "Advance",
          type: "bar",
          data: advanceValue,
        },
        {
          name: "Credit",
          type: "bar",
          data: creditValue,
        },
        {
          name: "Debit",
          type: "bar",
          data: debitValue,
        },
        {
          name: "Refund",
          type: "bar",
          data: refundValue,
        },
      ],
      supplierOptions: {
        chart: {
          type: "bar",
          height: 430,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        legend: {
          position: "top",
        },
        colors: [
          "#2E93fA",
          "#FF9800",
          "rgb(0, 227, 150)",
          "#ff3e1d",
          "rgb(135, 139, 182)",
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          hideOverlappingLabels: false,
          enabled: false,
          // offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: supplierLabels,
          labels: {
            style: {
              colors: "#247BA0",
            },
          },
          title: {
            text: "Supplier",
            style: {
              color: "#247BA0",
              fontSize: "14px",
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#247BA0",
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#247BA0",
            },
            title: {
              text: "Purchase Invoice Amount",
              style: {
                color: "#247BA0",
                fontSize: "14px",
              },
            },
            labels: {
              formatter: function (val) {
                return val?.toFixed(0);
              },
              style: {
                colors: "#247BA0",
              },
            },
          },
        ],
      },
    });
  }

  getItem(item, srNo) {
    return (
      <TableRow style={{ width: "100%", margin: 0, padding: 0 }} key={srNo}>
        <TableCell
          style={{
            color: "#000",
            borderLeft: "0.5px solid gray",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            fontFamily: "sans-serif",
            width: "3%",
          }}
        >
          {srNo}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            width: "17%",
            fontFamily: "sans-serif",
          }}
        >
          {item.supplierName ? item.supplierName : ""}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            width: "15%",
            fontFamily: "sans-serif",
          }}
        >
          {item.supplierGstn ? item.supplierGstn : ""}
        </TableCell>

        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "left",
            width: "8%",
            fontFamily: "sans-serif",
          }}
        >
          {item.invType ? item.invType : ""}
        </TableCell>

        <TableCell
          style={{
            color: "#000",
            // fontWeight: "bold",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "right",
            width: "8%",
            fontFamily: "sans-serif",
          }}
        >
          {item.currencySymbol +
            " " +
            parseFloat(item.advanceAmount).toFixed(2)}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            // fontWeight: "bold",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "right",
            width: "10%",
            fontFamily: "sans-serif",
          }}
        >
          {item.currencySymbol +
            " " +
            parseFloat(item.purchaseAmount).toFixed(2)}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            // fontWeight: "bold",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "right",
            width: "10%",
            fontFamily: "sans-serif",
          }}
        >
          {item.currencySymbol + " " + parseFloat(item.creditAmount).toFixed(2)}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            // fontWeight: "bold",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "right",
            width: "10%",
            fontFamily: "sans-serif",
          }}
        >
          {item.currencySymbol + " " + parseFloat(item.debitAmount).toFixed(2)}
        </TableCell>

        <TableCell
          style={{
            color: "#000",
            // fontWeight: "bold",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "right",
            width: "10%",
            fontFamily: "sans-serif",
          }}
        >
          {item.currencySymbol + " " + parseFloat(item.refundAmount).toFixed(2)}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            // fontWeight: "bold",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "right",
            width: "10%",
            fontFamily: "sans-serif",
          }}
        >
          {item.currencySymbol + " " + parseFloat(item.rcmAmount).toFixed(2)}
        </TableCell>
      </TableRow>
    );
  }

  getHeader() {
    const { column, direction } = this.state;
    return (
      <TableHead
        style={{ width: "100%", margin: 0, padding: 0, background: "#ececec" }}
      >
        <TableRow
          style={{
            width: "100%",
            margin: 0,
            padding: 0,
            background: "#ececec",
          }}
        >
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              fontFamily: "sans-serif",
              borderLeft: "0.5px solid gray",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              fontSize: "12px",
              padding: "0.5em",
              width: "3%",
            }}
          >
            #
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              width: "17%",
              fontFamily: "sans-serif",
              cursor: "pointer",
            }}
            className="casino-btn"
            // onClick={this.handleHeaderClick.bind(this)}
            // id="supplierName"
            sorted={column === "supplierName" ? direction : null}
            onClick={this.handleSort("supplierName")}
          >
            Name
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              width: "15%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "supplierGstn" ? direction : null}
            onClick={this.handleSort("supplierGstn")}
            className="casino-btn"
          >
            GSTN
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "left",
              width: "5%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "invType" ? direction : null}
            onClick={this.handleSort("invType")}
            className="casino-btn"
          >
            Type
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "right",
              width: "10%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "advanceAmount" ? direction : null}
            onClick={this.handleSort("advanceAmount")}
            className="casino-btn"
          >
            Advance
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "right",
              width: "10%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "purchaseAmount" ? direction : null}
            onClick={this.handleSort("purchaseAmount")}
            className="casino-btn"
          >
            Purchase
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "right",
              width: "10%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "creditAmount" ? direction : null}
            onClick={this.handleSort("creditAmount")}
            className="casino-btn"
          >
            Credit
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "right",
              width: "10%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "debitAmount" ? direction : null}
            onClick={this.handleSort("debitAmount")}
            className="casino-btn"
          >
            Debit
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>

          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "right",
              width: "10%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "refundAmount" ? direction : null}
            onClick={this.handleSort("refundAmount")}
            className="casino-btn"
          >
            Refund
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>

          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "right",
              width: "10%",
              fontFamily: "sans-serif",
            }}
            sorted={column === "rcmAmount" ? direction : null}
            onClick={this.handleSort("rcmAmount")}
            className="casino-btn"
          >
            RCM
            {direction === "ascending" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon" />
              </>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  handleStartDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.endDate);
    if (this.state.endDate === null && date === null) {
      this.setState({
        startDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (date === null) {
      this.setState({
        startDate: date,
        financialsYear: null,
        error: false,
        errorMassage: true,
      });
    } else if (fromDate > toDate) {
      this.setState({
        startDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        startDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  handleEndDateChange(date) {
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(date);
    if (this.state.startDate === null && date === null) {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    } else if (this.state.startDate === null) {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: true,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        endDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        endDate: date,
        error: false,
        errorMassage: false,
        financialsYear: null,
      });
    }
  }

  updateFinancialYear(e, year) {
    this.setState({ financialsYear: year });
  }

  updateBusinessUnit(e, name) {
    this.setState({ selectedBusinessUnit: name });
  }

  handleSwichCheckChange(e) {
    this.setState({ swichCheck: e.target.checked });
  }
  goToBack() {
    store.dispatch(appDataAction.setReportTab(this.selectedTabIndex));
    this.props.history.push({
      pathname: "/reports/",
    });
  }

  filterRecords(e) {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }
    this.setState({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      financialsYear: this.state.financialsYear,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
    });
    this.getSupplierWiseReportDetails();
  }

  resetFilter() {
    this.state.startDate = null;
    this.state.endDate = null;
    this.state.financialsYear = null;
    this.state.error = false;
    this.state.errorMassage = false;
    this.state.selectedBusinessUnit = this.businessUnitList[0];
    this.getSupplierWiseReportDetails();
  }

  render() {
    let supplierWisePurchaseReportList = [];
    this.state.supplierWisePurchaseList.forEach((element, index) => {
      let srNo = index + 1;
      let item = this.getItem(element, srNo);
      supplierWisePurchaseReportList.push(item);
    });
    let logoData = Utils.getLogo();
    return (
      <>
        <MenuWithBreadScrum
          name={this.title}
          addToBreadCrumb={this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          backCallback={this.cancelView.bind(this)}
        />

        <Row md={12} style={{ padding: "5px" }}>
          <Col md={6} style={{ padding: "5px", textAlign: "start" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginLeft: "0.5rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.goToBack(e)}
            >
              Back
            </Button>
          </Col>
          <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  startIcon={<PrintIcon />}
                >
                  Print
                </Button>
              )}
              content={() => this.printComponentRef}
            />

            <Button
              variant="contained"
              size="small"
              style={{
                background: "#9a7031",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<GetAppIcon />}
              onClick={(e) => this.downloadImage(e)}
            >
              Download
            </Button>

            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                // marginRight: "1rem",
              }}
              startIcon={<EmailIcon />}
              onClick={(e) => this.emailSalesVoucher(e)}
            >
              Email
            </Button> */}
          </Col>
        </Row>
        <div className="row">
          <div className="col-8 align-items-start text-start">
            <span className="ms-2">
              {this.state.selectedBusinessUnit &&
              this.state.selectedBusinessUnit !== null ? (
                <span>
                  <b>Business Unit : </b>
                  {this.state.selectedBusinessUnit.name + ", "}
                </span>
              ) : (
                ""
              )}
              {this.state.defaultFinancialYear &&
              this.state.defaultFinancialYear !== null ? (
                <span>
                  <b>Financial Year : </b>
                  {this.state.defaultFinancialYear + ", "}
                </span>
              ) : (
                ""
              )}{" "}
              &nbsp;
              {this.state.defaultStartDate &&
              this.state.defaultStartDate !== null ? (
                <span>
                  <b>Start Date : </b>
                  {Utils.ptsDateFormat(this.state.defaultStartDate) + ", "}
                </span>
              ) : (
                ""
              )}
              &nbsp;
              {this.state.defaultEndDate &&
              this.state.defaultEndDate !== null ? (
                <span>
                  <b>End Date : </b>
                  {Utils.ptsDateFormat(this.state.defaultEndDate) + ", "}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="col-4 align-items-end text-end">
            <span
              className="text-end me-1"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>
            {this.state.isFirstTimeLoading ? (
              ""
            ) : (
              <span
                className="me-1"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.resetFilter.bind(this)}
              >
                {"  "} &nbsp;&nbsp;
                <i class="fa fa-refresh" aria-hidden="true"></i> Reset
              </span>
            )}
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Filter</h5>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Business Unit
                </label>
                <Autocomplete
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  margin="normal"
                  className="expenseAutoComplete"
                  id="businessUnit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  options={this.businessUnitList}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Business Unit"
                      {...params}
                      style={{}}
                      variant="outlined"
                      className="regCountry"
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0px",
                    padding: "0",
                  }}
                  control={
                    <Switch
                      variant="outlined"
                      // id="isAsset"
                      checked={this.state.swichCheck}
                      value={this.state.swichCheck}
                      onChange={this.handleSwichCheckChange.bind(this)}
                      style={{ background: "none", margin: "8px" }}
                    />
                  }
                  label={<span>Custom search </span>}
                />
              </div>
              {!this.state.swichCheck && (
                <div className="col-md-12 mt-2" id="assetFormLabel">
                  <label className="form-label ">Financial Year</label>
                  <br />
                  <Autocomplete
                    fullWidth
                    variant="outlined"
                    id="supplierList"
                    ListboxProps={{ className: "myCustomList" }}
                    options={this.financialsYearArray}
                    value={this.state.financialsYear}
                    defaultValue={this.state.financialsYear}
                    getOptionLabel={(option) => option}
                    style={{ background: "#fff", borderRadius: "5px" }}
                    onChange={(event, value) => {
                      this.updateFinancialYear(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Financial Year"
                        className="regCountry"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {this.state.swichCheck && (
                <>
                  <div className="col-md-6 mt-2" id="assetFormLabel">
                    <label className="form-label ">Start Date</label>
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.startDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleStartDateChange.bind(this)}
                          helperText={
                            this.state.errorMassage
                              ? "Start Date is Required"
                              : ""
                          }
                          error={this.state.errorMassage}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-6 mt-2" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      End Date
                    </label>{" "}
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          clearable
                          value={this.state.endDate}
                          // variant="inline"
                          margin="normal"
                          placeholder="DD-MM-YYYY"
                          fullWidth
                          variant="outlined"
                          id="ptsDateTimePicker"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          onChange={this.handleEndDateChange.bind(this)}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </div>
                  {this.state.error ? (
                    <lable className="errorMassageShow">
                      End Date should be after Start Date
                    </lable>
                  ) : (
                    ""
                  )}
                </>
              )}

              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  onClick={(e) => this.filterRecords(e)}
                >
                  <i className="fa-solid fa-filter"></i>
                  &nbsp; Filter
                </button>
                &nbsp; &nbsp;
                <button
                  className="resetButton"
                  onClick={this.resetFilter.bind(this)}
                >
                  <i className="fa fa-refresh"></i>
                  &nbsp; Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>
        <div
          ref={(el) => (this.printComponentRef = el)}
          // style={{ padding: "1em" }}
        >
          <div ref={this.imageDwlRef}>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-12 row d-flex align-items-end overflow-auto">
                      <h5 className="text-start pb-1">
                        Supplier vs. Purchase Invoice Amount
                      </h5>
                      <div
                        className="text-start overflow-auto "
                        id="chart"
                        style={{ padding: "1em", overflowX: "auto" }}
                      >
                        {this.state.supplierOptions !== null &&
                        this.state.supplierSeries !== null ? (
                          <ReactApexCharts
                            options={this.state.supplierOptions}
                            series={this.state.supplierSeries}
                            type="bar"
                            height={450}
                            // width={200 * Math.max(this.state.supplierWiseChartData.length)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Row style={{ marginTop: "1em" }}>
              <Col md={12}>
                <Card
                  ref={(el) => (this.printComponentRef = el)}
                  md={12}
                  id="printform"
                  // ref={this.printComponentRef}
                  // className="a4size"
                  style={{
                    tableLayout: "auto",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  <div ref={this.imageDwlRef}>
                    <CardBody style={{ color: "#000" }}>
                      <Row
                        md={12}
                        style={{
                          margin: "0",
                          padding: "5px",
                          display: "flex",
                          borderLeft: "1px solid gray",
                          borderTop: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <Col md={2}>
                          {Utils.isNotNullAndEmpty(logoData) && (
                            <img
                              src={logoData}
                              alt=""
                              style={{
                                height: "3em",
                                width: "3em",
                                padding: "0.1em",
                              }}
                            />
                          )}
                        </Col>
                        <Col md={8}>
                          <Row>
                            <strong
                              style={{ width: "100%", textAlign: "center" }}
                            >
                              {Utils.getOrgName()}
                            </strong>
                          </Row>
                          <Row>
                            <span
                              style={{ width: "100%", textAlign: "center" }}
                            >
                              {Utils.getAddressAsText(this.org?.addressvo)}
                            </span>
                          </Row>
                        </Col>
                        <Col md={2}>
                          <span style={{ width: "100%", textAlign: "center" }}>
                            {/* {this.state.duplicateTriplicate} */}
                          </span>
                        </Col>
                      </Row>
                      <Row
                        md={12}
                        style={{
                          margin: "0",
                          padding: "5px",
                          display: "flex",
                          borderLeft: "1px solid gray",
                          borderTop: "1px solid gray",
                          borderBottom: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <Col
                          md={12}
                          style={{ fontSize: "x-large", textAlign: "center" }}
                        >
                          <strong>
                            Supplier Wise Purchase
                            {/* {this.state.invoiceheading} */}
                          </strong>
                        </Col>
                      </Row>
                      <Row
                        md={12}
                        style={{
                          margin: "0",
                          padding: "0",
                          borderRight: "1px solid gray",
                          borderLeft: "1px solid gray",
                          borderBottom: "1px solid gray",
                        }}
                        id="supplierReport"
                      >
                        <Table style={{ margin: 0, padding: 0 }}>
                          {this.getHeader()}
                          <TableBody>
                            {supplierWisePurchaseReportList}{" "}
                          </TableBody>
                        </Table>
                        {/* <MaterialTable
                          icons={UiUtils.getMaterialTableIcons()}
                          title=""
                          columns={[
                            {
                              title: "#",
                              render: (rowData) => rowData.tableData.id + 1,
                              width: "2%",

                              align: "left",
                              filtering: false,
                            },
                            {
                              title: "Name",
                              field: "supplierName",
                              filtering: false,
                              // width: "10%",
                            },

                            {
                              title: "GSTN",
                              field: "supplierGstn",
                              filtering: false,
                              // width: "10%",
                            },
                            {
                              title: "Type",
                              field: "invType",
                              filtering: false,
                              // width: "10%",
                            },
                            {
                              title: "Advance",
                              field: "advanceAmount",
                              filtering: false,
                              // width: "10%",
                            },

                            {
                              title: "Purchase",
                              field: "purchaseAmount",
                              filtering: false,
                              // width: "10%",
                            },
                            {
                              title: "Debit",
                              field: "debitAmount",
                              filtering: false,
                              // width: "10%",
                            },
                            {
                              title: "Credit",
                              field: "creditAmount",
                              filtering: false,
                              // width: "10%",
                            },

                            {
                              title: "Refund",
                              field: "refundAmount",
                              filtering: false,
                              // width: "10%",
                            },

                            {
                              title: "RCM",
                              field: "rcmAmount",
                              filtering: false,
                              // width: "10%",
                            },
                          ]}
                          data={this.state.supplierWiseChartData}
                          options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            toolbar: false,
                            headerStyle: {
                              backgroundColor: "#ececec",
                              fontWeight: "bold",

                              color: "#000",
                              align: "left",
                              textAlign: "left",
                            },

                            rowStyle: (rowData) => ({
                              height: "3em",
                              textTransform: "capitalize",
                            }),
                          }}
                          actions={[]}
                        /> */}
                      </Row>
                    </CardBody>
                  </div>
                </Card>
                {/* <object
              fileName="customerList"
              style={{
                position: "relative",
                outline: "none",
                boarder: "none",
                width: "-webkit-fill-available",
                height: "-webkit-fill-available",
                padding: "1em",
                minHeight: "40em",
              }}
              data={this.state.pdfdata}
              type={this.state.contentType}
            /> */}
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
