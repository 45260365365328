import Button from "@material-ui/core/Button";
// import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { Component } from "react";
import { Card, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import EditIconButton from "../buttons/EditIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import SalesInvoiceNumberRow from "./NumberFormatRow";
import "./SalesInvoiceNumberFormat.scss";
export class ExpenseNumberFormat extends Component {
  documentFormatRowRefList = [];
  constructor(props) {
    super(props);
    this.state = {
      documentFormatList: [],
      suxfixTypeList: [],
      sepratorList: [],
      render: false,
    };
    this.getExpenseDocumentFormatList();
  }

  getExpenseDocumentFormatList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService(
      "/docformatms/getAllExpense",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseFormat = output.data;
        this.setState({
          suxfixTypeList: expenseFormat.suxfixTypeList,
          sepratorList: expenseFormat.sepratorList,
          documentFormatList: expenseFormat.documentFormatList,
          render: true,
        });
      } else {
      }
    });
  }

  saveExpenseNumberFormat() {
    let docNumberFormatList = [];
    this.documentFormatRowRefList.forEach((item, index) => {
      let currentItem = item.current.state;
      if (currentItem) {
        let formatRowObject = {
          id: currentItem.id,
          prefix: currentItem.prefix,
          prefixseprator: currentItem.prefixseprator,
          suffixseprator: currentItem.suffixseprator,
          resetifsuffixchange: currentItem.resetifsuffixchange,
          startingnumber: currentItem.startingnumber,
          sufixtype: currentItem.sufixtype,
          documenttype: currentItem.documenttype,
        };
        docNumberFormatList.push(formatRowObject);
      }
    });

    const header = store.getState().header.header;
    const postObject = {
      header: header,
      docNumberFormatList: docNumberFormatList,
    };
    FetchServerData.callPostService(
      "/docformatms/updateAllExpense",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let expenseFormat = output.data;
        PtsAlert.success(output.message);

        this.setState({
          suxfixTypeList: expenseFormat.suxfixTypeList,
          sepratorList: expenseFormat.sepratorList,
          documentFormatList: expenseFormat.documentFormatList,
          render: true,
          action: "view",
        });
      } else {
        PtsAlert.error(output.message);
      }
    });
  }

  setEditExpenseNumberFormat() {
    for (let i = 0; i < this.documentFormatRowRefList.length; i++) {
      if (
        this.documentFormatRowRefList[i] &&
        this.documentFormatRowRefList[i].current
      ) {
        this.documentFormatRowRefList[i].current.setState({ action: "edit" });
      }
    }
    this.setState({ action: "edit" });
  }
  cancelEdit() {
    for (let i = 0; i < this.documentFormatRowRefList.length; i++) {
      if (
        this.documentFormatRowRefList[i] &&
        this.documentFormatRowRefList[i].current
      ) {
        this.documentFormatRowRefList[i].current.setState({ action: "view" });
      }
    }
    this.setState({ action: "view" });
  }
  render() {
    if (this.state.action === "edit") {
      return this.editExpenseNumberFormat();
    } else {
      return this.viewExpenseNumberFormat();
    }
  }

  editExpenseNumberFormat() {
    if (this.state.render === false) {
      return <div />;
    }
    let numFormatList = [];
    this.documentFormatRowRefList = [];
    this.state.documentFormatList.forEach((item, index) => {
      let ref = React.createRef();
      this.documentFormatRowRefList[index] = ref;
      numFormatList.push(
        <SalesInvoiceNumberRow
          ref={this.documentFormatRowRefList[index]}
          data={item}
          sepratorList={this.state.sepratorList}
          suxfixTypeList={this.state.suxfixTypeList}
        />
      );
    });
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
          padding: "1em",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          {/* <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col>
              <span>
                <strong>Expense Number Format</strong>
              </span>
            </Col>
          </Row> */}
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col md={12} style={{ margin: "auto" }}>
              <span>
                <strong>Expense Number Format</strong>
              </span>
            </Col>
          </Row>
        </CardHeader>
        {/* <CardBody
          className="expenseCard"
          style={{ overflowX: "auto", padding: "0em" }}
        > */}
        {/* <TableContainer component={Paper}> */}
        <Table aria-label="a dense table" style={{}}>
          <TableHead style={{ borderTop: "none" }}>
            <TableRow style={{ background: "#878788" }}>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Document Type
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Prefix
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Separator
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Suffix
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Separator
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Start Number
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Reset Number
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Number Preview
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{numFormatList}</TableBody>
        </Table>
        {/* </TableContainer> */}
        {/* <table
            id="docNumTbl"
            className="table table-bordered"
            style={{ tableLayout: 'auto', width: '100%', overflowX: 'auto' }}
          >
            <thead style={{ background: '#999c9e', color: '#fff' }}>
              <tr>
                <th style={{ width: '15%', margin: '0', padding: '0.3em' }}>Document Type</th>
                <th style={{ width: '10%', margin: '0', padding: '0.3em' }}>Prefix</th>
                <th style={{ width: '10%', margin: '0', padding: '0.3em' }}>Separator</th>
                <th style={{ width: '12%', margin: '0', padding: '0.3em' }}>Suffix</th>
                <th style={{ width: '10%', margin: '0', padding: '0.3em' }}>Separator</th>
                <th style={{ width: '15%', margin: '0', padding: '0.3em' }}>Start No.</th>
                <th style={{ width: '12%', margin: '0', padding: '0.3em' }}>Reset No.</th>
                <th style={{ margin: '0', padding: '0.3em' }}>Preview</th>
              </tr>
            </thead>
            <tbody>{numFormatList}</tbody>
          </table> */}
        {/* </CardBody> */}
        <CardFooter>
          <div style={{ textAlign: "center" }}>
            <a>
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#1b7189",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<SaveIcon />}
                onClick={this.saveExpenseNumberFormat.bind(this)}
              >
                Update
              </Button>
              <Button
                variant="contained"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<CloseIcon />}
                onClick={(e) => this.cancelEdit()}
              >
                Cancel
              </Button>
            </a>
          </div>
        </CardFooter>
      </Card>
    );
  }
  viewExpenseNumberFormat() {
    if (this.state.render === false) {
      return <div />;
    }
    let numFormatList = [];
    this.documentFormatRowRefList = [];
    this.state.documentFormatList.forEach((item, index) => {
      let ref = React.createRef();
      this.documentFormatRowRefList[index] = ref;
      numFormatList.push(
        <SalesInvoiceNumberRow
          ref={this.documentFormatRowRefList[index]}
          data={item}
          sepratorList={this.state.sepratorList}
          suxfixTypeList={this.state.suxfixTypeList}
        />
      );
    });
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
          padding: "1em",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          {/* <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col>
              <span>
                <strong>Expense Number Format</strong>
              </span>
            </Col>
            <Col>
              <div style={{ float: "right" }}>
                <EditIconButton
                  onClick={this.setEditExpenseNumberFormat.bind(this)}
                  caption="Edit Number Format"
                />
              </div>
            </Col>
          </Row> */}
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col md={6} style={{ margin: "auto" }}>
              <span>
                <strong>Expense Number Format</strong>
              </span>
            </Col>
            <Col md={6} style={{ marginTop: "0.5em" }}>
              <div style={{ float: "right" }}>
                <EditIconButton
                  onClick={this.setEditExpenseNumberFormat.bind(this)}
                  caption="Edit Number Format"
                />
              </div>
            </Col>
          </Row>
        </CardHeader>
        {/* <CardBody md={12} style={{ overflowX: "auto" }}> */}
        {/* <TableContainer component={Paper}> */}
        <Table aria-label="a dense table" style={{}}>
          <TableHead style={{ borderTop: "none" }}>
            <TableRow style={{ background: "#878788" }}>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Document Type
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Prefix
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Separator
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Suffix
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Separator
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Start Number
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Reset Number
              </TableCell>
              <TableCell
                style={{ color: "#fff", border: "0.5px solid #dedddd" }}
              >
                Number Preview
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{numFormatList}</TableBody>
        </Table>
        {/* </TableContainer> */}
        {/* <table
            id="docNumTbl"
            className="table table-bordered"
            style={{ tableLayout: 'auto', width: '100%', overflowX: 'auto' }}
          >
            <thead style={{ background: '#999c9e', color: '#fff' }}>
              <tr>
                <th style={{ width: '15%', margin: '0', padding: '0.3em' }}>Document Type</th>
                <th style={{ width: '10%', margin: '0', padding: '0.3em' }}>Prefix</th>
                <th style={{ width: '10%', margin: '0', padding: '0.3em' }}>Separator</th>
                <th style={{ width: '12%', margin: '0', padding: '0.3em' }}>Suffix</th>
                <th style={{ width: '10%', margin: '0', padding: '0.3em' }}>Separator</th>
                <th style={{ width: '15%', margin: '0', padding: '0.3em' }}>Start No.</th>
                <th style={{ width: '12%', margin: '0', padding: '0.3em' }}>Reset No.</th>
                <th style={{ margin: '0', padding: '0.3em' }}>Preview</th>
              </tr>
            </thead>
            <tbody>{numFormatList}</tbody>
          </table> */}
        {/* </CardBody> */}
      </Card>
    );
  }
}

export default ExpenseNumberFormat;
