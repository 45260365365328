import React, { Component } from "react";
import Utils from "../../../provider/Utils";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import { Autocomplete } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import SaveButton from "../../components/buttons/SaveButton";
import CancelButton from "../../components/buttons/CancelButton";
import ToggleButton from "@mui/material/ToggleButton";
import AdminMenuWithBreadScrum from "../adminMenu/AdminMenuWithBreadScrum";
import AdminFetchServerData from "../../../provider/AdminFetchServerData";
import store from "../../../redux/store";
import PtsAlert from "../../components/ptsAlert/PtsAlert";
import CreateButton from "../../components/buttons/CreateButton";

class AdminGoodHsnCode extends Component {
  header = store.getState().adminHeader.adminHeader;
  taxRateList = store.getState().staticData.taxRateList;
  title = "Goods HSN Details";
  breadCrumb = [
    { pageid: "/goodsHsnList", uiname: "Goods HSN List" },
    { pageid: "", uiname: "Goods HSN Details" },
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    let goodsHsnId = props.goodsHsnId;

    if (
      Utils.isNull(goodsHsnId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      action = props.history.location.state.action;
      goodsHsnId = props.history.location.state.goodsHsnId;
    }
    let canRender = false;
    if (goodsHsnId && goodsHsnId > 0) {
      this.getGoodsHsnCodeDetails(goodsHsnId);
    } else {
      canRender = true;
    }

    this.state = {
      action: action,
      goodsHsnId: goodsHsnId,
      code: "",
      description: "",
      isnilrated: false,
      taxrate: null,
      render: false,
    };
  }

  getGoodsHsnCodeDetails(goodsHsnId) {
    const postObject = {
      header: this.header,
      goods: {
        id: goodsHsnId,
      },
    };
    AdminFetchServerData.callPostService("/goodsHsnCodes/get", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let goosHsnDetails = output.data.goods;
          let getPermission = Utils.getRolePermission(["*"]);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];

          this.setState({
            render: true,
            id: goosHsnDetails.id,
            code: goosHsnDetails.code,
            description: goosHsnDetails.description,
            isnilrated: goosHsnDetails.isnilrated,
            taxrate: goosHsnDetails.taxrate,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  viewCancel() {
    this.props.history.push("/goodsHsnList");
  }

  editGoodsHsnCodeDetail() {
    this.props.history.push({
      pathname: "/editGoodsHsn",
      state: {
        action: "edit",
        goodsHsnId: this.state.id,
      },
    });
    // this.setState({ action: "edit", appUserId: this.state.id, })
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  updateTaxRate(e, taxrate) {
    // this.setState({ taxrate: taxrate });
    this.setState({ taxrate: e.target.value });
  }

  handleIsNilRatedChange(e) {
    this.setState({ isnilrated: !this.state.isnilrated });
  }

  createGoodsHsnCodeToBackend() {
    const postObject = {
      header: this.header,
      goods: {
        code: this.state.code,
        description: this.state.description,
        taxrate: this.state.taxrate,
        isnilrated: this.state.isnilrated,
      },
    };
    AdminFetchServerData.callPostService("/goodsHsnCodes/create", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let goosHsnDetails = output.data.goods;
          this.getGoodsHsnCodeDetails(goosHsnDetails.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  updateGoodsHsnCodeToBackend() {
    const postObject = {
      header: this.header,
      goods: {
        id: this.state.goodsHsnId,
        code: this.state.code,
        description: this.state.description,
        taxrate: this.state.taxrate,
        isnilrated: this.state.isnilrated,
      },
    };
    AdminFetchServerData.callPostService("/goodsHsnCodes/update", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let goosHsnDetails = output.data.goods;
          this.props.history.push({
            pathname: "/viewGoodsHsn",
            state: {
              goodsHsnId: goosHsnDetails.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  deleteGoodsHsnCode() {
    const postObject = {
      header: this.header,
      goods: {
        id: this.state.id,
      },
    };

    AdminFetchServerData.callPostService("/goodsHsnCodes/delete", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.history.push("/goodsHsnList");
        } else {
        }
      })
      .catch((error) => {});
  }

  editCancel() {
    this.props.history.push({
      pathname: "/viewGoodsHsn",
      state: {
        action: "view",
        goodsHsnId: this.state.id,
      },
    });
  }

  createCancel() {
    this.props.history.push("/goodsHsnList");
  }

  render() {
    if (this.state.action === "create") {
      return this.createGoodsHsnCode();
    } else if (this.state.action === "view") {
      return this.viewGoodsHsnCode();
    } else if (this.state.action === "edit") {
      return this.editGoodsHsnCode();
    }
  }

  createGoodsHsnCode() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.createGoodsHsnCodeToBackend.bind(this)}
          >
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-firstname"
                        >
                          Code <span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-code"
                          variant="outlined"
                          placeholder="Code"
                          value={this.state.code}
                          onChange={this.handleCodeChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Tax Rate %<span className="asterisk"> *</span>
                        </label>
                        {/* <Autocomplete
                          required
                          disableClearable
                          // style={{ margin: "0.5em" }}
                          id={"taxrate" + this.state.index}
                          options={this.taxRateList}
                          value={this.state.taxrate}
                          getOptionLabel={(option) => option.rate + "%"}
                          onChange={(event, value) => {
                            this.updateTaxRate(event, value);
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                placeholder="Tax Rate %"
                                {...params}
                                style={{ textAlign: "right" }}
                                fullWidth
                                variant="outlined"
                                className="regCountry"
                              />
                            );
                          }}
                        /> */}
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-firstName"
                          variant="outlined"
                          placeholder="First Name"
                          value={this.state.taxrate}
                          onChange={this.updateTaxRate.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1 " id="assetFormLabel">
                        <label
                          className="form-label col-form-label fw-bold"
                          htmlFor="collapsible-email"
                        >
                          Is Nil Rated
                        </label>
                        <Tooltip title="Please click 'Click’ to change Status">
                          <span className="ms-4">
                            {this.state.isnilrated ? (
                              <ToggleButton
                                className="ToggleButtonActive"
                                onChange={this.handleIsNilRatedChange.bind(
                                  this
                                )}
                              >
                                Yes
                              </ToggleButton>
                            ) : (
                              <ToggleButton
                                className="ToggleButtonInActive"
                                onChange={this.handleIsNilRatedChange.bind(
                                  this
                                )}
                              >
                                No
                              </ToggleButton>
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="row align-items-end">
                      <div className="col-md-12 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-appUserRole"
                        >
                          Discription<span className="asterisk"> *</span>
                        </label>
                        <TextareaAutosize
                          variant="outlined"
                          fullWidth
                          id="description"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "0.5px solid #D5D8DC",
                          }}
                          minRows={2}
                          // label="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                          autoComplete="description"
                          placeholder="Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-sm-12 mt-3 text-center">
                <CreateButton id="saveButton" type="submit">
                  {" "}
                  Create{" "}
                </CreateButton>
                &nbsp;&nbsp;
                <CancelButton onClick={(e) => this.createCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }
  viewGoodsHsnCode() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            backCallback={this.viewCancel.bind(this)}
            editCallback={this.editGoodsHsnCodeDetail.bind(this)}
            deleteCallback={this.deleteGoodsHsnCode.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
            <div className="card" id="organizationFormCard">
              <div className="row">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row pt-1">
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Code
                        </label>
                        <div className="col-sm-9" id="OrganizationCol">
                          <span>{this.state.code}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row pt-1">
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Tax Rate
                        </label>
                        <div className="col-sm-9" id="OrganizationCol">
                          <span>{this.state.taxrate}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mt-1 " id="assetFormLabel">
                      <label
                        className="form-label col-form-label fw-bold"
                        htmlFor="collapsible-email"
                      >
                        Is Nil Rated
                      </label>
                      <span className="ms-4">
                        {this.state.isnilrated ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                  <div className="row pt-1">
                    <label
                      className="col-sm-1 col-form-label fw-bold"
                      htmlFor="basic-default-name"
                      id="assetFormLabel"
                    >
                      Discription
                    </label>
                    <div className="col-sm-11" id="OrganizationCol">
                      <span>{this.state.description}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: "#717373",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.viewCancel()}
              >
                Back
              </Button>
            </div>
          </div>
        </>
      );
    }
  }

  editGoodsHsnCode() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.updateGoodsHsnCodeToBackend.bind(this)}
          >
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-firstname"
                        >
                          Code <span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-code"
                          variant="outlined"
                          placeholder="Code"
                          value={this.state.code}
                          onChange={this.handleCodeChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Tax Rate %<span className="asterisk"> *</span>
                        </label>
                        {/* <Autocomplete
                          required
                          disableClearable
                          // style={{ margin: "0.5em" }}
                          id={"taxrate" + this.state.index}
                          options={this.taxRateList}
                          value={this.state.taxrate}
                          getOptionLabel={(option) => option.rate + "%"}
                          onChange={(event, value) => {
                            this.updateTaxRate(event, value);
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                placeholder="Tax Rate %"
                                {...params}
                                style={{ textAlign: "right" }}
                                fullWidth
                                variant="outlined"
                                className="regCountry"
                              />
                            );
                          }}
                        /> */}
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-firstName"
                          variant="outlined"
                          placeholder="First Name"
                          value={this.state.taxrate}
                          onChange={this.updateTaxRate.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1 " id="assetFormLabel">
                        <label
                          className="form-label col-form-label fw-bold"
                          htmlFor="collapsible-email"
                        >
                          Is Nil Rated
                        </label>
                        <Tooltip title="Please click 'Click’ to change Status">
                          <span className="ms-4">
                            {this.state.isnilrated ? (
                              <ToggleButton
                                className="ToggleButtonActive"
                                onChange={this.handleIsNilRatedChange.bind(
                                  this
                                )}
                              >
                                Yes
                              </ToggleButton>
                            ) : (
                              <ToggleButton
                                className="ToggleButtonInActive"
                                onChange={this.handleIsNilRatedChange.bind(
                                  this
                                )}
                              >
                                No
                              </ToggleButton>
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="row align-items-end">
                      <div className="col-md-12 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-appUserRole"
                        >
                          Discription<span className="asterisk"> *</span>
                        </label>
                        <TextareaAutosize
                          variant="outlined"
                          fullWidth
                          id="description"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "0.5px solid #D5D8DC",
                          }}
                          minRows={2}
                          // label="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                          autoComplete="description"
                          placeholder="Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-sm-12 mt-3 text-center">
                <SaveButton id="saveButton" type="submit">
                  {" "}
                  Save{" "}
                </SaveButton>
                &nbsp;&nbsp;
                <CancelButton onClick={(e) => this.editCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }
}
export default AdminGoodHsnCode;
