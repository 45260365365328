import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import store from "../../../../redux/store";

class NavSingleItemCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goToPage(page) {
    this.props.history.push({
      pathname: page,
    });
  }

  setText(text) {
    this.setState({ myText: text });
  }
  getChildMenus = (e, id, pageid) => {
    // this.props.history.push(pageid);
    let toMenuList = store.getState().userMenus.topMenus;
    //JSON.parse(localStorage.getItem('userMenuArr'));
    if (this.props.onSelect) {
      this.props.onSelect(id);
    }
    let childMenuArray = [];
    for (let i = 0; toMenuList && i < toMenuList.length; i++) {
      if (toMenuList[i].childMenu !== null) {
        if (toMenuList[i].id === id) {
          let obj = {
            menuid: toMenuList[i].menuid,
            icon: toMenuList[i].icon,
            pageid: toMenuList[i].pageid,
            step: toMenuList[i].step,
            id: toMenuList[i].id,
          };
          if (toMenuList[i] === id) {
            obj.isActive = true;
            if (this.props.onSelect) {
              this.props.onSelect(toMenuList[i]);
              obj.isActive = true;
            }
          } else {
            obj.isActive = true;
          }
          childMenuArray.push(obj);
          // localStorage.setItem('childMenuArr', JSON.stringify(childMenuArray));
        }
      }
    }

  };

  render() {
    // const url = item.pageid.charAt(0) === '/' ? item.pageid : `/${item.pageid}`;
    let item = this.props.item;
    let isActive = this.props.isActive;
    let activeMenu = store.getState().appData.activeMenu;
    if (item.id === activeMenu) {
      isActive = true;
    }
    if (isActive === true) {
      return (
        item.isactive &&
        <div
          className="nav-item"
          onMouseOver={() => this.setText(item.uiname)}
          onMouseLeave={() => this.setText(null)}
          onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}
        >
          {/* {this.props.isSidebarCollapsed === false && this.state.myText && (
            <span className="nav-item-label" style={{ zIndex: 100 }}>
              {item.uiname}
            </span>
          )} */}
          {this.props.isSidebarCollapsed === false && (
            <Tooltip
              title={item.uiname}
              placement="center"
              TransitionComponent={Zoom}
            >
              <NavLink
                to={item.pageid}
                activeClassName="active"
                className="menu-item"
                // style={{ backgroundColor: "#1b7189" }}
                style={{
                  width: this.props.isSidebarCollapsed ? "230px" : "65px",
                  // padding: "0.3em",
                }}
              >
                <div className="menu-link">
                  <i
                    // className={item.cssclass}
                    className={`menu-icon tf-icons ${item.icon}`}
                    style={{ color: "#fff", fontSize: "22px" }}
                  ></i>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ width: "20%" }}>
                    <i className={item.cssclass} style={{ color: "#fff" }}></i>
                  </div>
                  <div style={{ width: "80%" }}>
                    <span className="nav-item-label">{item.uiname}</span>
                  </div>
                </div> */}
              </NavLink>
            </Tooltip>
          )}
          {this.props.isSidebarCollapsed === true && (
            <NavLink
              to={item.pageid}
              activeClassName="active"
              className="menu-item"
              // style={{ backgroundColor: "#1b7189" }}
              style={{
                width: this.props.isSidebarCollapsed ? "230px" : "65px",
              }}
            >
              <div
                className="menu-link"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  fontSize: "14px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <i className={item.icon} style={{ color: "#fff" }}></i>
                </div>
                <div style={{ width: "80%" }}>
                  <span className="nav-item-label">{item.uiname}</span>
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  fontSize: "14px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <i className={item.cssclass} style={{ color: "#fff" }}></i>
                </div>
                <div style={{ width: "80%" }}>
                  <span className="nav-item-label">{item.uiname}</span>
                </div>
              </div> */}
            </NavLink>
          )}
        </div>
      );
    } else {
      return (
        item.isactive &&
        <div
          className="nav-item"
          onMouseOver={() => this.setText(item.uiname)}
          onMouseLeave={() => this.setText("")}
          onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}
        >
          {this.props.isSidebarCollapsed === false && (
            <Tooltip
              title={item.uiname}
              placement="right"
              TransitionComponent={Zoom}
            >
              <NavLink
                onMouseOver={() => this.setText(item.uiname)}
                onMouseLeave={() => this.setText("")}
                onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}
                to={item.pageid}
                activeClassName="inactive"
                className="menu-item"
                style={{
                  width: this.props.isSidebarCollapsed ? "230px" : "65px",
                  // padding: "0.3em",
                }}
              // style={{ boxShadow: '0px 1px 1px 1px #5e6061', padding: '10px' }}
              >
                <div className="menu-link">
                  <i
                    className={item.icon}
                    // className={`menu-icon tf-icons ${item.cssclass}`}
                    style={{ color: "#fff", fontSize: "22px" }}
                  ></i>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ width: "20%" }}>
                    <i className={item.cssclass} style={{ color: "#fff" }}></i>
                  </div>
                  <div style={{ width: "80%" }}>
                    <span className="nav-item-label">{item.uiname}</span>
                  </div>
                </div> */}
              </NavLink>
            </Tooltip>
          )}
          {this.props.isSidebarCollapsed === true && (
            <Tooltip
              title={item.uiname}
              placement="center"
              TransitionComponent={Zoom}
            >
              <NavLink
                onMouseOver={() => this.setText(item.uiname)}
                onMouseLeave={() => this.setText("")}
                onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}
                to={item.pageid}
                activeClassName="inactive"
                className="menu-item"
                style={{
                  width: this.props.isSidebarCollapsed ? "230px" : "65px",
                }}
              // style={{ boxShadow: '0px 1px 1px 1px #5e6061', padding: '10px' }}
              >
                <div
                  className="menu-link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ width: "20%" }}>
                    <i className={item.icon} style={{ color: "#fff" }}></i>
                  </div>
                  <div style={{ width: "80%" }}>
                    <span className="nav-item-label">{item.uiname}</span>
                  </div>
                </div>
                {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  fontSize: "14px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <i className={item.cssclass} style={{ color: "#fff" }}></i>
                </div>
                <div style={{ width: "80%" }}>
                  <span className="nav-item-label">{item.uiname}</span>
                </div>
              </div> */}
              </NavLink>
            </Tooltip>
          )}
        </div>
      );
    }
  }
}
export default withRouter(NavSingleItemCmp);
