import { GET_App_USER_ROLE, SET_App_USER_ROLE } from "./AppUserRoleTypes";

const getAppUserRole = () => {
  return {
    type: GET_App_USER_ROLE,
  };
};
const setAppUserRole = (data) => {
  return {
    type: SET_App_USER_ROLE,
    payload: data,
  };
};
const AppUserRoleAction = { getAppUserRole, setAppUserRole };
export default AppUserRoleAction;
