import { GET_SETTINGS, SET_SETTINGS } from './SettingDataTypes';

const initialState = {
    general: {},
};

const SettingDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
            };
        case SET_SETTINGS:
            return {
                ...state,
                general: action.payload,
            };
        default:
            return state;
    }
};

export default SettingDataReducer;
