import React, { Component } from "react";
import { Buffer } from "buffer";
import store from "../../../../redux/store";
import FetchServerData from "../../../../provider/FetchServerData";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import { Col, Row, Card, CardBody } from "reactstrap";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Utils from "../../../../provider/Utils";
import { exportComponentAsJPEG } from "react-component-export-image";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import ReactToPrint from "react-to-print";
import UiUtils from "../../../../provider/UiUtils";
import appDataAction from "../../../../redux/appData/appDataAction";
import PtsAlert from "../../ptsAlert/PtsAlert";
export default class ProductList extends Component {
  breadCrumb = [];
  title = "Product List";
  org = store.getState().appData.settings.org;
  header = store.getState().header.header;
  printPreviewRef = null;
  printComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.imageDwlRef = React.createRef();
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      this.breadCrumb = props.history.location.state.breadCrumb;
      this.selectedTabIndex = props.history.location.state.selectedTabIndex;
    }
    this.state = {
      url: "",
      pdfdata: "",
      contentType: "application/pdf",
      render: false,
      ProductList: [],
    };

    this.getProductReportList();
  }

  getProductReportList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostServiceForPDFReportData(
      "/reports/getProductList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let ProductList = output.data.productList;
        this.setState({ render: true, ProductList: ProductList });
      } else {
      }
    });
  }

  getHeader() {
    return (
      <TableHead
        style={{ width: "100%", margin: 0, padding: 0, background: "#ececec" }}
      >
        <TableRow
          style={{
            width: "100%",
            margin: 0,
            padding: 0,
            background: "#ececec",
          }}
        >
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              fontFamily: "sans-serif",
              borderLeft: "0.5px solid gray",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              fontSize: "12px",
              padding: "0.5em",
              width: "3%",
            }}
          >
            #
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              width: "17%",
              fontFamily: "sans-serif",
            }}
          >
            Name
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              width: "20%",
              fontFamily: "sans-serif",
            }}
          >
            Address
          </TableCell>

          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "left",
              width: "20%",
              fontFamily: "sans-serif",
            }}
          >
            GSTN
          </TableCell>
          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "left",
              width: "20%",
              fontFamily: "sans-serif",
            }}
          >
            Phone
          </TableCell>

          <TableCell
            style={{
              color: "#000",
              fontWeight: "bold",
              borderRight: "0.5px solid gray",
              borderBottom: "0.5px solid gray",
              padding: "0.5em",
              fontSize: "12px",
              textAlign: "left",
              width: "20%",
              fontFamily: "sans-serif",
            }}
          >
            Email
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  getItem(item, srNo) {
    return (
      <TableRow style={{ width: "100%", margin: 0, padding: 0 }} key={srNo}>
        <TableCell
          style={{
            color: "#000",
            borderLeft: "0.5px solid gray",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            fontFamily: "sans-serif",
            width: "3%",
          }}
        >
          {srNo}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            width: "17%",
            fontFamily: "sans-serif",
          }}
        >
          {item.name ? item.name : ""}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            width: "20%",
            fontFamily: "sans-serif",
          }}
        >
          {item.address ? item.address : ""}
        </TableCell>

        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "left",
            width: "20%",
            fontFamily: "sans-serif",
          }}
        >
          {item.gstn ? item.gstn : ""}
        </TableCell>
        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "left",
            width: "20%",
            fontFamily: "sans-serif",
          }}
        >
          {item.phone ? item.phone : ""}
        </TableCell>

        <TableCell
          style={{
            color: "#000",
            borderRight: "0.5px solid gray",
            borderBottom: "0.5px solid gray",
            padding: "0.5em",
            fontSize: "12px",
            textAlign: "left",
            width: "20%",
            fontFamily: "sans-serif",
          }}
        >
          {item.email ? item.email : ""}
        </TableCell>
      </TableRow>
    );
  }
  emailSalesVoucher() {}

  downloadImage() {
    let fileName = "Product List";
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    if (this.imageDwlRef) {
      exportComponentAsJPEG(this.imageDwlRef, {
        fileName: fileName,
      });
    }
  }

  excelDownloadProductList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService(
      "/productmgmt/exportToExcel",
      postObject
    ).then((output) => {
      let productExcelData = output.data;
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        const byteArray = new Buffer(
          productExcelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );
        const outputFilename = productExcelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
      }
    });
  }
  goToBack() {
    store.dispatch(appDataAction.setReportTab(this.selectedTabIndex));
    this.props.history.push({
      pathname: "/reports/",
    });
  }

  render() {
    let productList = [];
    this.state.ProductList?.forEach((element, index) => {
      let srNo = index + 1;
      let item = this.getItem(element, srNo);
      productList.push(item);
    });
    let logoData = Utils.getLogo();
    return (
      <>
        <MenuWithBreadScrum
          name={this.title}
          addToBreadCrumb={this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
        />
        {/* <object
          fileName="customerList"
          style={{
            position: "relative",
            outline: "none",
            boarder: "none",
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            padding: "1em",
            minHeight: "40em",
          }}
          data={this.state.pdfdata}
          type={this.state.contentType}
        /> */}
        <Row md={12} style={{ padding: "5px" }}>
          <Col md={6} style={{ textAlign: "start" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.goToBack(e)}
            >
              Back
            </Button>
          </Col>
          <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
            <ReactToPrint
              trigger={
                () => (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<PrintIcon />}
                    // onClick={(e) => this.printContent(e)}
                  >
                    Print
                  </Button>
                )
                // <Button>Print this out!</Button>
              }
              content={() => this.printComponentRef}
            />
            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#9a7031",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<GetAppIcon />}
              onClick={(e) => this.downloadImage(e)}
              // onClick={() => exportComponentAsJPEG(this.imageDwlRef)}
            >
              Download
            </Button> */}
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#5ac489",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={
                <i className="fa fa-download" style={{ fontSize: "16px" }}></i>
              }
              onClick={(e) => this.excelDownloadProductList()}
            >
              Download Excel
            </Button>
            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<EmailIcon />}
              onClick={(e) => this.emailSalesVoucher(e)}
            >
              Email
            </Button> */}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div ref={(el) => (this.printComponentRef = el)}>
              <Card
                ref={(el) => (this.printComponentRef = el)}
                md={12}
                id="printform"
                // ref={this.printComponentRef}
                // className="a4size"
                style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
              >
                <div ref={this.imageDwlRef}>
                  <CardBody style={{ color: "#000" }}>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "5px",
                        display: "flex",
                        borderLeft: "1px solid gray",
                        borderTop: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Col md={2}>
                        {Utils.isNotNullAndEmpty(logoData) && (
                          <img
                            src={logoData}
                            alt=""
                            style={{
                              height: "3em",
                              width: "3em",
                              padding: "0.1em",
                            }}
                          />
                        )}
                      </Col>
                      <Col md={8}>
                        <Row>
                          <strong
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            {Utils.getOrgName()}
                          </strong>
                        </Row>
                        <Row>
                          <span style={{ width: "100%", textAlign: "center" }}>
                            {Utils.getAddressAsText(this.org?.addressvo)}
                          </span>
                        </Row>
                      </Col>
                      <Col md={2}>
                        <span style={{ width: "100%", textAlign: "center" }}>
                          {/* {this.state.duplicateTriplicate} */}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "5px",
                        display: "flex",
                        borderLeft: "1px solid gray",
                        borderTop: "1px solid gray",
                        borderBottom: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Col
                        md={12}
                        style={{ fontSize: "x-large", textAlign: "center" }}
                      >
                        <strong>Product List</strong>
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{
                        margin: "0",
                        padding: "0",
                        borderRight: "1px solid gray",
                        borderLeft: "1px solid gray",
                        borderBottom: "1px solid gray",
                      }}
                      id="productReport"
                    >
                      <MaterialTable
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        columns={[
                          {
                            title: "#",
                            render: (rowData) => rowData.tableData.id + 1,
                            cellStyle: {
                              width: "5%",
                            },
                          },
                          {
                            title: "Name",
                            field: "name",
                            align: "left",
                            width: "20%",
                          },
                          {
                            title: "Code",
                            field: "code",
                            align: "left",
                            width: "20%",
                          },
                          {
                            title: "Type",
                            field: "producttype",
                            align: "left",
                            width: "15%",
                          },
                          {
                            title: "Status",
                            render: (rowData) =>
                              rowData.isactive ? "Active" : "InActive",
                            align: "left",
                            width: "15%",
                          },
                        ]}
                        data={this.state.ProductList}
                        options={{
                          sorting: true,
                          actionsColumnIndex: -1,
                          search: false,
                          paging: false,
                          toolbar: false,
                          headerStyle: {
                            backgroundColor: "#ececec",
                            fontWeight: "bold",

                            color: "#000",
                            align: "left",
                            textAlign: "left",
                          },

                          rowStyle: (rowData) => ({
                            height: "3em",
                            // textTransform: "capitalize",
                          }),
                        }}
                        actions={[]}
                      />
                    </Row>
                  </CardBody>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
