import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { Component } from "react";

export class ViewIconButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onClick: props.onClick,
            caption: props.caption,
            type: props.type,
        };
    }

    onClick() {
        if (this.state.onClick) {
            this.state.onClick();
        }
    }
    render() {
        return (
            <Tooltip title="View">
                <Button
                    type={this.state.type ? this.state.type : ""}
                    variant="contained"
                    size="small"
                    style={{
                        background: "#1b7189",
                        color: "white",
                        textTransform: "capitalize",
                        cursor: "pointer",
                        width: "80px",
                        float: "right",
                        marginRight: "1rem",
                        marginBottom: "1em",
                    }}
                    startIcon={<VisibilityIcon />}
                    onClick={this.onClick.bind(this)}
                >
                    View
                </Button>
            </Tooltip>
        );
    }
}
export default ViewIconButton;
