import { GET_ORGANIZATION, SET_ORGANIZATION } from './OrganizationDataTypes';

const initialState = {
    organization: {},
};

const OrganizationDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORGANIZATION:
            return {
                ...state,
            };
        case SET_ORGANIZATION:
            return {
                ...state,
                organization: action.payload,
            };
        default:
            return state;
    }
};

export default OrganizationDataReducer;
