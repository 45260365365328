import MaterialTable from "material-table";
import React, { Component } from "react";
import UiUtils from "../../../provider/UiUtils";
import BusinessUnit from "./BusinessUnit";
import AddIconButton from "../buttons/AddIconButton";
import Utils from "../../../provider/Utils";

export class BusinessUnitList extends Component {
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    let isPaging = false;
    if (this.props.data.length > 10) {
      isPaging = true;
    }
    this.state = {
      businessUnits: this.props.data,
      selectedAccount: "",
      onChangeSettings: this.props.onChange,
      render: true,
      action: this.props.action,
      pageSize: 10,
      isPaging: isPaging,
      initialStep: 0,
    };
    //this.getCustemerData();
  }

  addBusinessUnit = (e) => {
    this.setState({ action: "add_account" });
  };

  businessUnitChanged(changedAccount) {
    this.props.onChange(changedAccount);
  }

  businessUnitAdded(changedAccount) {
    this.props.onChange(changedAccount);
  }
  viewBusinessUnit = (e, data) => {
    this.setState({ selectedAccount: data, action: "show_account" });
  };
  render() {
    if (this.state.action === "add_account") {
      return (
        <BusinessUnit
          action="create"
          title="New Business Unit"
          data={null}
          businessUnits={this.state.businessUnits}
          onChange={this.businessUnitChanged.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    } else if (this.state.action === "show_account") {
      return (
        <BusinessUnit
          action="view"
          title="Business Unit Details"
          data={this.state.selectedAccount}
          businessUnits={this.state.businessUnits}
          onChange={this.businessUnitAdded.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    }
    if (this.state.action === "view") {
      return this.showList();
    }
  }

  showList() {
    return (
      <>
        <MaterialTable
          icons={this.tableIcons}
          localization={{
            body: {
              emptyDataSourceMessage: Utils.communizeTableText(),
            },
          }}
          title="Business Unit List"
          columns={[
            {
              title: "#",
              render: (rowData) => rowData.tableData.id + 1,
              cellStyle: {
                width: "5%",
              },
            },
            {
              title: "Unit Name",
              field: "name",
              align: "left",
              cellStyle: {
                width: "20%",
              },
            },
            {
              title: "Unit Code",
              field: "code",
              align: "left",
              cellStyle: {
                width: "15%",
              },
            },
            {
              title: "Start Date",
              render: (rowData) => Utils.ptsDateFormat(rowData.startdate),
              align: "left",
              type: "date",
              cellStyle: {
                width: "15%",
              },
            },
            {
              title: "Address",
              render: (rowData) => Utils.getAddressAsText(rowData.address),
              align: "left",
              width: "20%",
            },
          ]}
          data={this.state.businessUnits}
          column={{
            headerStyle: {
              color: "#fff",
            },
          }}
          options={{
            // pageSizeOptions: [3, 5, 10, 20, 30, 50, 75, 100],
            toolbar: true,
            paging: this.state.isPaging,
            pageSize: this.state.pageSize,
            initialPage: this.state.initialPage,
            pageSizeOptions: Utils.materialTablePageSize(),
            sorting: true,
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#1b7189",
              fontWeight: "bold",
              color: "#fff",
              align: "center",
            },
            rowStyle: {},
            searchFieldStyle: {},
          }}
          style={{
            boder: "1px solid black",
          }}
          actions={[
            {
              icon: () => <AddIconButton />,
              tooltip: "Add Business Unit",
              isFreeAction: true,
              onClick: (event) => this.addBusinessUnit(),
            },
          ]}
          onRowClick={(e, rowData) => this.viewBusinessUnit(e, rowData)}
        />
      </>
    );
  }
}
export default BusinessUnitList;
