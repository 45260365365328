import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Autocomplete, InputAdornment } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { NavLink, withRouter } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import Address from "../../address/Address";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import "./ImportSupplier.scss";
import BackIconButton from "../../buttons/BackIconButton";

export class ImportSupplier extends Component {
  countryList = store.getState().staticData.countryList;
  supplierTypeList = store.getState().appData.supplierTypeList;
  businessTypeList = store.getState().appData.businessTypeList;
  supplierCategoryList = store.getState().appData.supplierCategoryList;

  rcmSupplierCategories = store.getState().appData.rcmServiceCategories;
  originalRcmServiceCodes = [];
  title = "Supplier Import";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let supplierId = props.supplierId;
    let action = props.action;
    /* Set Data From history */

    if (
      Utils.isNull(supplierId) &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      supplierId = props.history.location.state.supplierId;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    this.state = {
      id: supplierId,
      name: "",
      description: "",
      // gstnverified: '',
      pan: "",
      gstn: "",
      phone: "",
      email: "",
      tradeName: "",
      usetnforinvoicing: false,
      isTradeNameApplicable: false,
      invoicetandc: "",
      isrcm: "false",
      rcmServiceCode: false,
      supplierType:
        this.supplierTypeList.length > 0 ? this.supplierTypeList[0] : {},
      businesstype:
        this.businessTypeList.length > 0 ? this.businessTypeList[0] : {},
      address: {},
      rcmSuppCategory: {},
      suppStatus: "InActive",
      supplierStatus: {},
      gstregstatus: "",
      suppCategoryId: "",
      privileges: [],
      selectedSupplierCategory: {},
      supRcmCodes: [],
      selectedSupplierRcmServiceList:
        this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0].supplierservices
          : [],
      selectedSupplierServiceCodeList:
        this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0].supplierservices.length > 0
            ? this.rcmSupplierCategories[0].supplierservices[0].rcmServices
            : []
          : [],
      rcmSuppServicetype: {},
      rcmSupplierServiceCode: [],
      action: action,
      selected: false,
      setSelected: false,
      showSuccessAlert: false,
      showFailAlert: false,
      selectedRcmSupplierCategory:
        this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0]
          : null,
      selectedRcmSupplierService:
        this.rcmSupplierCategories.length > 0
          ? this.rcmSupplierCategories[0].supplierservices.length > 0
            ? this.rcmSupplierCategories[0].supplierservices[0]
            : null
          : null,
      selectedRcmServiceCodes: [],
      render: false,
    };
    if (supplierId && supplierId > 0) {
      this.getSupplierDetails(supplierId);
    } else {
      this.setState({ render: true });
    }
  }

  handleSupplierCategoryChange(supplierCategory) {

    if (null === supplierCategory || supplierCategory === "") {
      return;
    }

    if (
      this.state.selectedSupplierCategory &&
      supplierCategory.id === this.state.selectedSupplierCategory.id
    ) {
      /* Same Category */
    } else {
    }

    this.setState({
      selectedSupplierCategory: supplierCategory,
    });
  }
  handleSupplierTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }

    if (
      Utils.equalsIgnoreCase(selectedType.type.toUpperCase(), "UNREGISTERED")
    ) {
      // document.getElementById("gstn").disabled = true;
      this.setState({
        supplierType: selectedType,
        selectedSupplierCategory: this.supplierCategoryList[1],
        country: {},
        // gstn: this.state.gstn,
        isTradeNameApplicable: false,
        showGSTN: false,
      });
    } else {
      // document.getElementById("gstn").disabled = false;
      this.setState({
        supplierType: selectedType,
        selectedSupplierCategory: this.supplierCategoryList[0],
        gstn: this.state.gstn,
        showGSTN: true,
      });
    }
  }

  handleGstnOnBlur(e) {
    let gstn = this.state.gstn;
    if (gstn && gstn.substr(5, 1) === "P") {
      this.setState({ isTradeNameApplicable: true });
    } else {
      this.setState({ isTradeNameApplicable: false });
    }
    if (
      this.state.supplierType &&
      this.state.supplierType.type.toUpperCase() === "REGISTERED"
    ) {
      if (null === gstn || gstn.length <= 0) {
        document.getElementById("gstn").focus();
        PtsAlert.error("Enter GSTN");
        return false;
      } else if (gstn.length < 15) {
        document.getElementById("gstn").focus();
        PtsAlert.error("GSTN Must be 15 Characters long");
        return false;
      } else {
        let gstnPattern =
          "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
        let patternArray = gstn.match(gstnPattern);
        if (patternArray === null) {
          PtsAlert.error(
            "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
          );
          return false;
        } else {
          let panNo = gstn.substr(2, 10);
          this.setState({ pan: panNo });
        }
      }
    }
  }
  handleTradeNameChange(e) {
    this.setState({ tradeName: e.target.value });
  }

  handleBusinessTypeChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      businesstype: selectedType,
    });
  }

  handleRcmSuppCategChange(rcmSupplier) {
    if (null === rcmSupplier || rcmSupplier === "") {
      return;
    }
    this.setState({
      selectedRcmSupplierCategory: rcmSupplier,
      selectedSupplierRcmServiceList: rcmSupplier.supplierservices,
      selectedRcmSupplierService:
        rcmSupplier.supplierservices.length > 0
          ? rcmSupplier.supplierservices[0]
          : null,
      selectedSupplierServiceCodeList:
        rcmSupplier.supplierservices[0].rcmServices,
    });
  }

  handleRcmSuppServiceChange(rcmSuppService) {
    if (null === rcmSuppService || rcmSuppService === "") {
      return;
    }
    if (
      this.state.selectedRcmSupplierService &&
      rcmSuppService.id === this.state.selectedRcmSupplierService.id
    ) {
      /* Same Category */
      this.setState({
        selectedRcmSupplierService: rcmSuppService,
        selectedSupplierServiceCodeList: rcmSuppService.rcmServices,
      });
    } else {
      // let rcmServiceCodeArray = this.state.selectedRcmServiceCodes === null ? [] : this.state.selectedRcmServiceCodes;
      // for (let i = 0; i < rcmServiceCodeArray.length; i++) {}
      this.setState({
        // selectedRcmServiceCodes: rcmServiceCodeArray,
        selectedRcmSupplierService: rcmSuppService,
        selectedSupplierServiceCodeList: rcmSuppService.rcmServices,
      });
    }
  }

  handleRcmServiceCodeChange(e, codeId) {

    // let selectedRcmCodeArray =
    //   undefined === this.state.selectedRcmServiceCodes || null === this.state.selectedRcmServiceCodes
    //     ? []
    //     : this.state.selectedRcmServiceCodes;
    const checked = e.target.checked;
    let selectedRcmCodeNew =
      null === this.state.supRcmCodes ? [] : this.state.supRcmCodes;
    if (checked === true) {
      // let selectedRcmCodeObject = {
      //   operation: "add",
      //   id: codeId,
      // };

      /* If Exists in SupRcmCodes */
      for (let i = 0; i < selectedRcmCodeNew.length; i++) {
        if (
          selectedRcmCodeNew[i].rcmservice &&
          codeId === selectedRcmCodeNew[i].rcmservice.id
        ) {
          selectedRcmCodeNew[i].rcmservice.operation = "update";
          this.setState({ supRcmCodes: selectedRcmCodeNew });
          return;
        }
      }

      let newServiceCode = {
        rcmservice: {
          id: codeId,
          operation: "add",
        },
      };
      selectedRcmCodeNew.push(newServiceCode);
      this.setState({ supRcmCodes: selectedRcmCodeNew });
      return;
      //   /* Dont Add if item was part of original List */
      //   for (let i = 0; i < this.originalRcmServiceCodes.length; i++) {
      //     if (codeId === this.originalRcmServiceCodes[i].id) {
      //       /* If Previous deleted then remove from List */
      //       for (let j = 0; j < selectedRcmCodeArray.length; j++) {
      //         if (codeId === selectedRcmCodeArray[j].id && selectedRcmCodeArray[j].operation === 'delete') {
      //           selectedRcmCodeArray[j] = { id: codeId };
      //           this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //           return;
      //         }
      //       }

      //       /* Add without operation */
      //       selectedRcmCodeArray.push({ id: codeId });
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       return;
      //     }
      //   }

      //   /* Deleted Object again Added */
      //   for (
      //     let i = 0;
      //     undefined !== this.state.selectedRcmCodeArray && i < this.state.selectedRcmCodeArray.length;
      //     i++
      //   ) {
      //     if (codeId === selectedRcmCodeArray[i].id) {
      //       selectedRcmCodeArray[i] = selectedRcmCodeObject;
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       return;
      //     }
      //   }
      //   /* Add Object */
      //   selectedRcmCodeArray.push(selectedRcmCodeObject);
      //   this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //   return;
    } else {
      // let selectedRcmCodeObject = {
      //   operation: "delete",
      //   id: codeId,
      // };
      /* Delete Only if exists in original List */
      for (let i = 0; i < this.originalRcmServiceCodes.length; i++) {
        if (codeId === this.originalRcmServiceCodes[i].id) {
          for (let j = 0; j < selectedRcmCodeNew.length; j++) {
            if (
              selectedRcmCodeNew[j].rcmservice &&
              codeId === selectedRcmCodeNew[j].rcmservice.id
            ) {
              selectedRcmCodeNew[i].rcmservice.operation = "delete";
              this.setState({ supRcmCodes: selectedRcmCodeNew, render: true });
              return;
            }
          }
        }
      }

      for (let j = 0; j < selectedRcmCodeNew.length; j++) {
        if (
          selectedRcmCodeNew[j].rcmservice &&
          codeId === selectedRcmCodeNew[j].rcmservice.id
        ) {
          //   selectedRcmCodeNew.splice(j, 1);
          selectedRcmCodeNew.splice(j, 1);

          this.setState({ supRcmCodes: selectedRcmCodeNew });
          return;
        }
      }

      //   /* Delete Only if exists in original List */
      //   for (let i = 0; i < this.originalRcmServiceCodes.length; i++) {
      //     if (codeId === this.originalRcmServiceCodes[i].id) {
      //       for (let j = 0; j < selectedRcmCodeArray.length; j++) {
      //         if (codeId === selectedRcmCodeArray[j].id) {
      //           selectedRcmCodeArray[j] = selectedRcmCodeObject;
      //           this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //           //console.log('selectedRcmCodeArray 11' + JSON.stringify(selectedRcmCodeArray));

      //           return;
      //         }
      //       }
      //       selectedRcmCodeArray.push(selectedRcmCodeObject);
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       //console.log('selectedRcmCodeArray 12' + JSON.stringify(selectedRcmCodeArray));
      //       return;
      //     }
      //   }

      //   /* Remove From Array */
      //   for (let i = 0; i < selectedRcmCodeArray.length; i++) {
      //     if (codeId === selectedRcmCodeArray[i].id) {
      //       selectedRcmCodeArray.splice(i, 1);
      //       this.setState({ selectedRcmServiceCodes: selectedRcmCodeArray });
      //       //console.log('selectedRcmCodeArray 13' + JSON.stringify(selectedRcmCodeArray));
      //       return;
      //     }
      //   }
    }
    //this.setState({selectedRcmServiceCodes:selectedRcmCodeArray});
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleGstVerifiedChange(e) {
    this.setState({ gstnverified: e.target.value });
  }

  handlePanChange(e) {
    this.setState({ pan: e.target.value.toUpperCase() });
  }

  handleGstnChange(e) {
    this.setState({ gstn: e.target.value.toUpperCase() });
  }



  handleUsetnforinvoicingChange(e) {
    this.setState({
      usetnforinvoicing:
        null === this.state.usetnforinvoicing
          ? false
          : !this.state.usetnforinvoicing,
    });
  }

  handleInvoicetandcChange(e) {
    this.setState({ invoicetandc: e.target.value });
  }
  handleRcmServiceChange(e) {
    this.setState({ isrcm: e.target.checked });
  }

  handleSuppStatusChange(selectedState) {
    if (Utils.equalsIgnoreCase(this.state.suppStatus, "InActive")) {
      this.setState({ suppStatus: "Active" });
    } else {
      this.setState({ suppStatus: "InActive" });
    }
  }

  handleAddressChange(inpuAddress) {
    this.setState({ address: inpuAddress });
  }

  regiStatus(e) {
    this.setState({ registatus: e.target.value });
  }

  formValidation(e) {
    let gstn = this.state.gstn;
    let panNo = this.state.pan;
    e.preventDefault();
    if (this.addressRef && this.addressRef.current) {
      if (!this.addressRef.current.validateAddress()) {
        return;
      }
    }
    let supplierType = this.state.supplierType;
    if (
      Utils.equalsIgnoreCase(
        supplierType && supplierType.type.toUpperCase(),
        "REGISTERED"
      )
    ) {
      /* GSTN Validation */
      document.getElementById("gstn").required = true;
      if (null === gstn || gstn.length <= 0) {
        document.getElementById("gstn").focus();
        PtsAlert.error("Enter GSTN");
        return false;
      } else if (gstn.length < 15) {
        document.getElementById("gstn").focus();
        PtsAlert.error("GSTN Must be 15 Characters long");
        return false;
      } else {
        let gstnPattern =
          "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
        let patternArray = gstn.match(gstnPattern);
        if (patternArray === null) {
          PtsAlert.error(
            "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
          );
          return false;
        }
      }

      /* PAN Number Validation */
      if (null === panNo || panNo.length <= 0) {
        document.getElementById("pan").focus();
        PtsAlert.error("Enter PAN Number");
        return false;
      } else if (panNo.length < 10) {
        document.getElementById("pan").focus();
        PtsAlert.error("PAN Number Must be 10 Characters long");
      } else {
        let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        let panPatternArray = panNo.match(regpan);
        if (panPatternArray === null) {
          PtsAlert.error(
            "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Characte"
          );
          return false;
        }
      }
    } else if (
      Utils.equalsIgnoreCase(
        supplierType && supplierType.type.toUpperCase(),
        "UNREGISTERED"
      )
    ) {
      this.setState({ gstn: "" });
      /* Check if Customer is from India or Out Of India */
      /*Indian Customer required Pan*/
      let address = this.state.address;
      // if ( address &&  address.country) {
      if (address) {
        if (address.country?.name !== "India") {
          /* Foreign Customer */
        } else {
          /* PAN Number Validation */
          if (null === panNo || panNo.length <= 0) {
            document.getElementById("pan").focus();
            PtsAlert.error("Enter PAN Number");
            return false;
          } else if (panNo.length < 10) {
            document.getElementById("pan").focus();
            PtsAlert.error("PAN Number Must be 10 Characters long");
          } else {
            let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
            let panPatternArray = panNo.match(regpan);
            if (panPatternArray === null) {
              PtsAlert.error(
                "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Characte"
              );
              return false;
            }
          }
        }
      }
    }

    if (this.state.action === "create") {
      return this.createSupplierInBackend(e);
    } else if (this.state.action === "edit") {
      return this.updateSupplierInBackend(e);
    }
  }

  formValidation2(e) {
    let gstn = this.state.gstn;
    let panNo = this.state.pan;

    let suppName = this.state.name;
    let gstnPattern =
      "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    let patternArray = gstn.match(gstnPattern);
    let panPatternArray = panNo.match(regpan);
    gstn = gstn.toUpperCase();

    if (gstn.length === 0) {
      this.setState({
        gstn: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter GSTN No",
      });
    } else if (gstn.length < 15) {
      this.setState({
        gstn: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter 15 Digit GSTN No",
      });
      //errorAlert("Enter 15 Digit GSTN No");
      return false;
    } else if (patternArray === null) {
      this.setState({
        gstn: e.target.value,
        showFailAlert: true,
        errorMessages:
          "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character",
      });
    } else if (suppName.length === 0) {
      this.setState({
        name: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter Name",
      });
    } else if (panNo.length < 10) {
      this.setState({
        pan: e.target.value,
        showFailAlert: true,
        errorMessages: "Enter 10 Digit PAN No",
      });
    } else if (panPatternArray === null) {
      this.setState({
        pan: e.target.value,
        showFailAlert: true,
        errorMessages:
          "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Character",
      });
    } else {
      if (this.state.action === "create") {
        return this.createSupplierInBackend(e);
      } else if (this.state.action === "edit") {
        return this.updateSupplierInBackend(e);
      }
    }
  }

  setSelectedCategoryListDetails(
    selectedRcmSupplierCategory,
    in_selectedRcmSupplierService
  ) {
    if (selectedRcmSupplierCategory) {
      for (let i = 0; i < this.rcmSupplierCategories.length; i++) {
        if (
          this.rcmSupplierCategories[i].id === selectedRcmSupplierCategory.id
        ) {
          let selectedSupplierRcmServiceList =
            this.rcmSupplierCategories[i].supplierservices;
          let selectedSupplierServiceCodeList = [];
          for (let j = 0; j < selectedSupplierRcmServiceList.length; j++) {
            if (
              selectedSupplierRcmServiceList[j].id ===
              in_selectedRcmSupplierService.id
            ) {
              selectedSupplierServiceCodeList =
                selectedSupplierRcmServiceList[j].rcmServices;
            }
          }
          this.setState({
            selectedSupplierRcmServiceList: selectedSupplierRcmServiceList,
            selectedSupplierServiceCodeList: selectedSupplierServiceCodeList,
          });
        }
      }
    }
  }

  getSupplierDetails(supplierId) {
    const header = store.getState().header.header;
    const postObject = {
      supplierid: supplierId,
      header: header,
    };

    FetchServerData.callPostService("/supimpexp/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplier = output.data;
          let isTradeNameApplicable = false;
          if (supplier.gstn && supplier.gstn.substr(5, 1) === "P") {
            isTradeNameApplicable = true;
          }

          let selectedRcmSupplierCategory = supplier.supplierServiceCategory;
          let selectedRcmSupplierService = {};
          // let selectedRcmServiceCodes = [];

          if (
            selectedRcmSupplierCategory &&
            selectedRcmSupplierCategory.supplierService &&
            selectedRcmSupplierCategory.supplierService.length > 0
          ) {
            selectedRcmSupplierService =
              selectedRcmSupplierCategory.supplierService[0];
            /* Set Selected Supplier Service CodeList */
            //   await this.setSelectedCategoryListDetails(selectedRcmSupplierCategory, selectedRcmSupplierService);
            //   if ( selectedRcmSupplierCategory) {
            for (let i = 0; i < this.rcmSupplierCategories.length; i++) {
              if (
                this.rcmSupplierCategories[i].id ===
                selectedRcmSupplierCategory.id
              ) {
                let selectedSupplierRcmServiceList =
                  this.rcmSupplierCategories[i].supplierservices;
                let selectedSupplierServiceCodeList = [];
                for (
                  let j = 0;
                  j < selectedSupplierRcmServiceList.length;
                  j++
                ) {
                  if (
                    selectedSupplierRcmServiceList[j].id ===
                    selectedRcmSupplierService.id
                  ) {
                    selectedSupplierServiceCodeList =
                      selectedSupplierRcmServiceList[j].rcmServices;
                  }
                }
                this.setState({
                  selectedSupplierRcmServiceList:
                    selectedSupplierRcmServiceList,
                  selectedSupplierServiceCodeList:
                    selectedSupplierServiceCodeList,
                });
              }
            }
            //   }
            if (selectedRcmSupplierService) {
              this.originalRcmServiceCodes = JSON.parse(
                JSON.stringify(selectedRcmSupplierService.rcmSupplierServices)
              );
              // selectedRcmServiceCodes = selectedRcmSupplierService.rcmSupplierServices;
              // for (let i = 0; i < selectedRcmServiceCodes.length; i++) {
              //   selectedRcmServiceCodes[i].operation = 'add';
              // }
              //this.originalRcmServiceCodes=selectedRcmServiceCodes;
            }
          }

          for (
            let i = 0;
            supplier.supRcmCodes && i < supplier.supRcmCodes.length;
            i++
          ) {
            if (supplier.supRcmCodes[i].rcmservice) {
              supplier.supRcmCodes[i].rcmservice.operation = "update";
            }
          }
          let inIsrcm = false;
          if (supplier.isrcm && supplier.isrcm.toUpperCase() === "YES") {
            inIsrcm = true;
          }
          this.setState({
            selectedRcmSupplierCategory: selectedRcmSupplierCategory,
            selectedRcmSupplierService: selectedRcmSupplierService,
            // selectedRcmServiceCodes: selectedRcmServiceCodes,
            isrcm: inIsrcm,
            supRcmCodes: supplier.supRcmCodes,
            //rcmServiceCode:true,
            id: supplier.id,
            name: supplier.name,
            tradeName: supplier.tradeName,
            usetnforinvoicing: supplier.usetnforinvoicing,
            businesstype: supplier.businesstype,
            selectedSupplierCategory: supplier.category,
            supplierType: supplier.suppliertypevo,
            gstn: supplier.gstn,
            pan: supplier.pan,
            description: supplier.description,
            phone: supplier.phone,
            email: supplier.email,
            registatus: supplier.gstregstatus,
            // suppStatus: supplier.supstatus.status,
            address: {
              id: supplier.addressvo.id,
              line1: supplier.addressvo.line1,
              line2: supplier.addressvo.line2,
              city: supplier.addressvo.city,
              pincode: supplier.addressvo.pin,
              state: supplier.addressvo.statevo,
              country: supplier.addressvo.statevo
                ? supplier.addressvo.statevo.countryvo
                : {},
            },

            gstregstatus: supplier.gstregstatus,
            privileges: supplier.privileges,
            isTradeNameApplicable: isTradeNameApplicable,
            showGSTN: Utils.equalsIgnoreCase(
              supplier.suppliertypevo?.type,
              "Registered"
            )
              ? true
              : false,
            render: true,
          });
          this.getMenuIconsAsPerStatusAndPermission()
        } else {
        }
      }
    );
  }

  createSupplierInBackend = () => {
    const header = store.getState().header.header;
    const postObject = {
      supplier: {
        name: this.state.name,
        // usetnforinvoicing: false,
        pan: this.state.pan,
        gstn: this.state.gstn,
        isrcm: this.state.isrcm,
        tradeName: this.state.tradeName,
        usetnforinvoicing: this.state.usetnforinvoicing,
        description: this.state.description,
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        suppliertypevo: this.state.supplierType,
        //custcategory: this.state.suppCategory,
        businesstype: this.state.businesstype,
        supRcmCodes: this.state.selectedRcmServiceCodes,
        addressvo: {
          id: this.state.address.id,
          line1: this.state.address.line1,
          line2: this.state.address.line2,
          city: this.state.address.city,
          pin: this.state.address.pincode,
          statevo: {
            id: this.state.address.state.id,
            name: this.state.address.state.name,
            code: this.state.address.state.code,
            countryvo: {
              id: this.state.address.country.id,
              name: this.state.address.country.name,
              code: this.state.address.country.code,
            },
          },
        },
        // gstnverified: this.state.gstnverified,
      },
      header: header,
    };

    FetchServerData.callPostService("/supimpexp/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplier = output.data;
          this.props.history.push({
            pathname: "/suppImport",
            state: { supplierId: supplier.id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  isServiceArrayContainsItem(array, item, currentArray) {
    if (!array || array.length <= 0) {
      return false;
    }
    for (let i = 0; i < array.length; i++) {
      let element = array[i];
      if (
        element.id === item.id &&
        item.operation &&
        item.operation.length > 0
      ) {
        return true;
      } else if (
        element.id + "" === item.id + "" &&
        item.operation.length > 0
      ) {
        return true;
      }
    }

    return false;
  }
  importSupplier = () => {
    const header = store.getState().header.header;
    for (
      let i = 0;
      this.state.supRcmCodes && i < this.state.supRcmCodes.length;
      i++
    ) {
      if (
        this.state.supRcmCodes[i].rcmservice &&
        (this.state.supRcmCodes[i].rcmservice.operation === "update" ||
          this.state.supRcmCodes[i].rcmservice.operation === "add")
      ) {
        //this.state.supRcmCodes.splice(i, 1);
        this.state.supRcmCodes[i].operation = "add";
        this.state.supRcmCodes[i].id = null;
      }

      if (
        this.state.supRcmCodes[i].rcmservice &&
        this.state.supRcmCodes[i].rcmservice.operation === "delete"
      ) {
        //this.state.supRcmCodes.splice(i, 1);
        this.state.supRcmCodes[i].operation = "delete";
        //  this.state.supRcmCodes[i].id = null;
      }
    }
    const postObject = {
      supplier: {
        id: this.state.id,
        name: this.state.name,
        // usetnforinvoicing: false,
        tradeName: this.state.tradeName,
        usetnforinvoicing: this.state.usetnforinvoicing,
        pan: this.state.pan,
        gstn: this.state.gstn,
        isrcm: this.state.isrcm,
        description: this.state.description,
        // supstatus: {
        //   status: this.state.suppStatus,
        // },
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        suppliertypevo: this.state.supplierType,
        //custcategory: this.state.suppCategory,
        businesstype: this.state.businesstype,
        supRcmCodes: this.state.supRcmCodes,
        addressvo: {
          id: this.state.address.id,
          line1: this.state.address.line1,
          line2: this.state.address.line2,
          city: this.state.address.city,
          pin: this.state.address.pincode,
          statevo: {
            id: this.state.address.state.id,
            name: this.state.address.state.name,
            code: this.state.address.state.code,
            countryvo: {
              id: this.state.address.country.id,
              name: this.state.address.country.name,
              code: this.state.address.country.code,
            },
          },
        },
        // gstnverified: this.state.gstnverified,
      },
      header: header,
    };

    FetchServerData.callPostService("/supimpexp/imp", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let supplier = output.data;
          this.props.history.push({
            pathname: "/suppImport",
            state: { supplierId: this.state.id, action: "view" },
          });
        } else {
        }
      }
    );
  };
  updateSupplierInBackend = () => {
    const header = store.getState().header.header;
    for (
      let i = 0;
      this.state.supRcmCodes && i < this.state.supRcmCodes.length;
      i++
    ) {
      if (
        this.state.supRcmCodes[i].rcmservice &&
        (this.state.supRcmCodes[i].rcmservice.operation === "update" ||
          this.state.supRcmCodes[i].rcmservice.operation === "add")
      ) {
        //this.state.supRcmCodes.splice(i, 1);
        this.state.supRcmCodes[i].operation = "add";
        this.state.supRcmCodes[i].id = null;
      }

      if (
        this.state.supRcmCodes[i].rcmservice &&
        this.state.supRcmCodes[i].rcmservice.operation === "delete"
      ) {
        //this.state.supRcmCodes.splice(i, 1);
        this.state.supRcmCodes[i].operation = "delete";
        //  this.state.supRcmCodes[i].id = null;
      }
    }
    let address = this.addressRef.current.state;
    const postObject = {
      supplier: {
        id: this.state.id,
        name: this.state.name,
        // usetnforinvoicing: false,
        tradeName: this.state.tradeName,
        usetnforinvoicing: this.state.usetnforinvoicing,
        pan: this.state.pan,
        gstn: this.state.gstn,
        isrcm: this.state.isrcm,
        description: this.state.description,
        supstatus: {
          status: this.state.suppStatus,
        },
        phone: this.state.phone,
        email: this.state.email,
        gstregstatus: this.state.registatus,
        suppliertypevo: this.state.supplierType,
        //custcategory: this.state.suppCategory,
        businesstype: this.state.businesstype,
        supRcmCodes: this.state.supRcmCodes,
        addressvo: {
          id: address.id,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          pin: address.pincode,
          statevo: {
            id: address.state.id,
            name: address.state.name,
            code: address.state.code,
            countryvo: {
              id: address.country.id,
              name: address.country.name,
              code: address.country.code,
            },
          },
        },
        // gstnverified: this.state.gstnverified,
      },
      header: header,
    };

    FetchServerData.callPostService("/supimpexp/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let supplier = output.data;
          this.props.history.push({
            pathname: "/suppImport",
            state: { supplierId: this.state.id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  deleteSupplier = (id) => {
    const header = store.getState().header.header;
    const postObject = {
      supplierid: id,
      header: header,
    };
    FetchServerData.callPostService("/supimpexp/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let supplier = output.data;
          this.props.history.push({
            pathname: "/suppImport",
            state: { supplierId: id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];
    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }

    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"]
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"]
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
      });
    }
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }

    this.menuWithBreadScrumRef = React.createRef();
    this.addressRef = React.createRef();

    if (this.state.action === "create") {
      return this.createSupplier();
    }
    if (this.state.action === "edit") {
      return this.editSupplier();
    } else {
      return this.viewSupplier();
    }
  }

  editButtonClicked() {
    this.props.history.push({
      pathname: "/importSupplierEdit",
      state: { supplierId: this.state.id, action: "edit", breadCrumb: this.breadCrumb },
    });
  }
  deleteButtonClicked() {
    this.deleteSupplier(this.state.id);
  }
  cancelEdit() {
    this.props.history.push({
      pathname: "/importSupplierView",
      state: { supplierId: this.state.id, action: "view", breadCrumb: this.breadCrumb },
    });
  }

  cancelView() {
    this.props.history.push({ pathname: "/suppImport" });
  }

  getGstn() {
    const header = store.getState().header.header;
    const postObject = {
      gstin: this.state.gstn,
      header: header,
    };
    FetchServerData.callPostService(
      "/publicapi/searchTxPayer",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let gstnDetails = output.data;
        this.setState({
          name: gstnDetails.legalname,
          state: gstnDetails.state,
          address: gstnDetails.address,
          registatus: gstnDetails.status,
          gstnverified: true,
        });
      } else {
      }
    });
  }

  isArrayContainsItem(array, item) {
    if (!array) {
      return false;
    }
    array.forEach((element) => {
      if (element.id === item.id) {
        return true;
      } else if (element.id + "" === item.id + "") {
        return true;
      }
    });
    return false;
  }

  editSupplier() {

    let serviceCodeArray = [];
    for (let i = 0; i < this.state.selectedSupplierServiceCodeList.length; i++) {
      let item = this.state.selectedSupplierServiceCodeList[i];
      let flag = false;
      if (this.state.supRcmCodes && this.state.supRcmCodes.length > 0) {
        for (let j = 0; j < this.state.supRcmCodes.length; j++) {
          let element = this.state.supRcmCodes[j];

          if (
            element.rcmservice &&
            element.rcmservice.id === item.id &&
            element.rcmservice.operation !== "delete"
          ) {
            flag = true;
          } else if (
            element.rcmservice &&
            element.rcmservice.id + "" === item.id + "" &&
            element.rcmservice.operation !== "delete"
          ) {
            flag = true;
          }
        }
      }

      if (flag) {
        serviceCodeArray.push(
          <li>
            <span>
              <Checkbox
                checked={true}
                //defaultChecked={ServiceCode.checked}
                value={item.id}
                // onChange={this.handleRcmServicecode}
                onChange={(e) => this.handleRcmServiceCodeChange(e, item.id)}
                name="checkedB"
                color="primary"
              />
              {item.description}
            </span>
          </li>
        );
      } else {
        serviceCodeArray.push(
          <li>
            <span>
              <Checkbox
                checked={false}
                //defaultChecked={ServiceCode.checked}
                value={item.id}
                // onChange={this.handleRcmServicecode}
                onChange={(e) => this.handleRcmServiceCodeChange(e, item.id)}
                name="checkedB"
                color="primary"
              />
              {item.description}
            </span>
          </li>
        );
      }
    }
    return (
      <div>
        <MenuWithBreadScrum
          name={"Edit " + this.title}
          addToBreadCrumb={"Edit " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
        />

        <Row md={12}>
          <Col md={12}>
            <ValidatorForm ref="form" onSubmit={this.formValidation.bind(this)}>
              <Row style={{ padding: "1em" }}>
                <Col
                  md={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Card style={{ height: "100%", position: "relative" }}>
                    <CardHeader className="cardHeaderStyle">
                      <label className="cardHeaderTitle">
                        <strong>Supplier Details</strong>
                      </label>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ alignItems: "flex-end", padding: "0.5em" }}>
                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Supplier Type
                          </label>
                          <Autocomplete
                            id="supplierType"
                            ListboxProps={{
                              className: "myCustomList"
                            }}
                            options={
                              null === this.supplierTypeList
                                ? []
                                : this.supplierTypeList
                            }
                            value={this.state.supplierType}
                            getOptionLabel={(option) => option.type}
                            onChange={(event, value) => {
                              this.handleSupplierTypeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                placeholder="Supplier Type"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>

                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Business Type
                          </label>
                          <Autocomplete
                            id="businesstype"
                            ListboxProps={{
                              className: "myCustomList"
                            }}
                            options={
                              null === this.businessTypeList
                                ? []
                                : this.businessTypeList
                            }
                            value={this.state.businesstype}
                            getOptionLabel={(option) => option.type}
                            onChange={(event, value) => {
                              this.handleBusinessTypeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                placeholder="Business Type"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {this.state.showGSTN ? (
                        <Row style={{ alignItems: "flex-end", padding: "0.5em" }}>
                          <Col md={6}>
                            <label
                              className="form-label"
                              htmlFor="collapsible-companyName"
                            >
                              GSTN
                            </label>
                            <TextField
                              fullWidth
                              id="gstn"
                              type="text"
                              placeholder="GSTN"
                              validators={["required"]}
                              errorMessages={["GSTN is required"]}
                              onChange={this.handleGstnChange.bind(this)}
                              onBlur={this.handleGstnOnBlur.bind(this)}
                              value={this.state.gstn}
                              name="gstn"
                              className="textField"
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="gstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row></Row>
                      )}
                      <Row style={{ alignItems: "flex-end", padding: "0.5em" }}>
                        <Col md={12}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Name
                          </label>
                          <TextField
                            fullWidth
                            required
                            id="name"
                            placeholder="Name"
                            value={this.state.name}
                            onChange={this.handleNameChange.bind(this)}
                            name="name"
                            validators={["required"]}
                            errorMessages={["Customer Name is Required"]}
                            autoComplete="name"
                            variant="outlined"
                            className="textField"
                          />
                        </Col>
                      </Row>
                      {true === this.state.isTradeNameApplicable && (
                        <>
                          <Row
                            style={{ alignItems: "flex-end", padding: "0.5em" }}
                          >
                            <Col md={6}>
                              <label
                                className="form-label"
                                htmlFor="collapsible-companyName"
                              >
                                Trade Name
                              </label>
                              <TextField
                                fullWidth
                                id="tradeName"
                                placeholder="Trade Name"
                                value={this.state.tradeName}
                                onChange={this.handleTradeNameChange.bind(this)}
                                name="tradename"
                                className="textField"
                                variant="outlined"
                              />
                            </Col>
                          </Row>
                          <Row
                            style={{ alignItems: "flex-end", padding: "0.5em" }}
                          >
                            <Col md={12}>
                              <label
                                style={{ width: "50%", fontWeight: "500" }}
                              >
                                Use Trade Name For Invoice?
                              </label>
                              {this.state.usetnforinvoicing === true && (
                                <ToggleButton
                                  className="ToggleButtonActive"
                                  onChange={this.handleUsetnforinvoicingChange.bind(
                                    this
                                  )}
                                >
                                  Yes
                                </ToggleButton>
                              )}
                              {this.state.usetnforinvoicing === false && (
                                <ToggleButton
                                  className="ToggleButtonInActive"
                                  onChange={this.handleUsetnforinvoicingChange.bind(
                                    this
                                  )}
                                >
                                  No
                                </ToggleButton>
                              )}
                            </Col>
                          </Row>{" "}
                        </>
                      )}
                      <Row style={{ alignItems: "flex-end", padding: "0.5em" }}>
                        <Col md={12}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Description
                          </label>
                          <TextField
                            fullWidth
                            id="description"
                            placeholder="Description"
                            value={this.state.description}
                            onChange={this.handleDescriptionChange.bind(this)}
                            autoComplete="description"
                            className="textField"
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                      <Row style={{ alignItems: "flex-end", padding: "0.5em" }}>
                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            PAN
                          </label>
                          <TextField
                            fullWidth
                            id="pan"
                            placeholder="PAN"
                            value={this.state.pan}
                            onChange={this.handlePanChange.bind(this)}
                            name="pan"
                            variant="outlined"
                          />
                        </Col>

                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Supplier Category
                          </label>
                          <Autocomplete
                            id="category"
                            ListboxProps={{
                              className: "myCustomList"
                            }}
                            disabled
                            options={
                              null === this.supplierCategoryList
                                ? []
                                : this.supplierCategoryList
                            }
                            value={this.state.selectedSupplierCategory}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              this.handleSupplierCategoryChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Supplier Category"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row style={{ alignItems: "flex-end", padding: "0.5em" }}>
                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Phone Number
                          </label>
                          <TextField
                            fullWidth
                            id="phone"
                            placeholder="Phone Number"
                            value={this.state.phone}
                            onChange={this.handlePhoneChange.bind(this)}
                            name="phone"
                            type="phone"
                            variant="outlined"
                          />
                        </Col>

                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Email
                          </label>
                          <TextField
                            fullWidth
                            id="email"
                            placeholder="Email"
                            value={this.state.email}
                            name="email"
                            type="email"
                            variant="outlined"
                            onChange={this.handleEmailChange.bind(this)}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Row style={{ marginBottom: "1em" }}>
                    <Card>
                      <CardHeader className="cardHeaderStyle">
                        <label className="cardHeaderTitle">
                          <strong>Registered Address</strong>
                        </label>
                      </CardHeader>
                      <CardBody>
                        <Address
                          title=""
                          ref={this.addressRef}
                          data={this.state.address}
                          onChange={this.handleAddressChange.bind(this)}
                          action="edit"
                        />
                      </CardBody>
                    </Card>
                    <Card
                      style={{
                        width: "-webkit-fill-available",
                        height:
                          true === this.state.isTradeNameApplicable
                            ? "55.5%"
                            : "46%",
                        position: "relative",
                        marginTop: "1em",
                      }}
                    >
                      <CardHeader className="cardHeaderStyle">
                        <label className="cardHeaderTitle">
                          <strong>Other Information</strong>
                        </label>
                      </CardHeader>
                      {/* <hr></hr> */}
                      <CardBody>
                        <Row style={{ padding: "5px", alignItems: "flex-end" }}>
                          {/* <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              Registration Status
                            </label>
                            <TextField
                              variant="outlined"
                              disabled
                              fullWidth
                              id="registatus"
                              placeholder="Registration Status"
                              value={this.state.registatus}
                              name="registatus"
                              autoComplete="registatus"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col> */}

                          <Col md={6} style={{ padding: "5px" }}>
                            <label
                              className="form-label "
                              htmlFor="collapsible-companyName"
                            >
                              GST verified
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              disabled
                              id="gstverified"
                              placeholder="GST verified"
                              value={this.state.gstnverified ? "Yes" : "No"}
                              onChange={this.handleGstVerifiedChange.bind(this)}
                              name="gstverified"
                              autoComplete="gstverified"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              </Row>
              <Row style={{ padding: "1em" }}>
                <Col>
                  {this.state.isrcm ? (
                    <>
                      <Card>
                        <CardBody>
                          {/* <Row>
                        <Col md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.isrcm}
                                onChange={this.handleRcmServiceChange.bind(this)}
                                name="checkedB"
                                color="primary"
                              />
                            }
                           placeholder="RCM"
                          />
                        </Col>
                      </Row> */}

                          {this.state.isrcm ? (
                            <div>
                              <Row>
                                <Col md={12}>
                                  <label
                                    className="form-label"
                                    htmlFor="collapsible-companyName"
                                  >
                                    Supplier Category
                                  </label>
                                  <Autocomplete
                                    id="supplierCategory"
                                    ListboxProps={{
                                      className: "myCustomList"
                                    }}
                                    options={
                                      null === this.rcmSupplierCategories
                                        ? []
                                        : this.rcmSupplierCategories
                                    }
                                    value={
                                      this.state.selectedRcmSupplierCategory
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                      this.handleRcmSuppCategChange(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Supplier Category"
                                        className="regCountry"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <label
                                    className="form-label"
                                    htmlFor="collapsible-companyName"
                                  >
                                    Supplier Service
                                  </label>
                                  <Autocomplete
                                    id="rcmSuppServicetype"
                                    ListboxProps={{
                                      className: "myCustomList"
                                    }}
                                    options={
                                      null ===
                                        this.state.selectedSupplierRcmServiceList
                                        ? []
                                        : this.state
                                          .selectedSupplierRcmServiceList
                                    }
                                    value={
                                      this.state.selectedRcmSupplierService
                                    }
                                    getOptionLabel={(option) => option.type}
                                    onChange={(event, value) => {
                                      this.handleRcmSuppServiceChange(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Supplier Service"
                                        className="regCountry"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <div className="rcmCodeList">
                                  <ul>{serviceCodeArray}</ul>
                                </div>
                              </Row>
                            </div>
                          ) : null}
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row md={12} style={{ marginTop: "2em" }}>
                <Col>
                  <CardFooter style={{ width: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        style={{ backgroundColor: "#1a7088", color: "#fff" }}
                        variant="contained"
                      // onClick={(e) => this.formValidation(e)}
                      >
                        Save
                      </Button>
                      &nbsp;
                      {/* <Button
                      style={{ backgroundColor: '#777777', color: '#fff' }}
                      onClick={e => this.cancel()}
                    >
                      Cancle
                    </Button> */}
                      <NavLink
                        style={{ backgroundColor: "#777777", color: "#fff" }}
                        to={{
                          pathname: "/supplierview/",
                          state: {
                            supplierId: this.state.id,
                            action: "view",
                            breadCrumb: this.breadCrumb,
                          },
                        }}
                        activeClassName="selectedLink"
                        strict
                      >
                        <Button
                          style={{ backgroundColor: "#777777", color: "#fff" }}
                        >
                          Cancel
                        </Button>
                      </NavLink>
                    </div>
                  </CardFooter>
                </Col>
              </Row>
            </ValidatorForm>
          </Col>
        </Row>
      </div>
    );
  }
  viewSupplier() {
    let rcmServiceList = [];
    if (this.state.supRcmCodes && this.state.supRcmCodes.length > 0) {
      this.state.supRcmCodes.forEach((item, index) => {
        if (item.rcmservice && item.rcmservice) {
          rcmServiceList.push(
            <li style={{ marginLeft: "1em" }}>
              <span>
                {item.rcmservice.code + "-" + item.rcmservice.description}
              </span>
            </li>
          );
        }
      });
    }

    return (
      <div>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          name={"View " + this.title}
          addToBreadCrumb={"View " + this.title}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
        // editCallback={this.editButtonClicked.bind(this)}
        // deleteCallback={this.deleteButtonClicked.bind(this)}
        />

        <Row md={12}>
          <Col md={6}>
            <Card>
              <CardHeader className="cardHeaderStyle">
                <label className="cardHeaderTitle">
                  <strong>Supplier Information</strong>
                </label>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      Name
                    </label>
                  </Col>
                  <Col md={8}>{this.state.name}</Col>
                </Row>
                {true === this.state.isTradeNameApplicable && (
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "#000", fontWeight: "bold" }}>
                        Trade Name
                      </label>
                    </Col>
                    <Col md={8}>{this.state.tradeName}</Col>
                  </Row>
                )}
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      Business Type
                    </label>
                  </Col>
                  <Col md={8}>
                    {null === this.state.businesstype
                      ? ""
                      : this.state.businesstype.type}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      Supplier Type
                    </label>
                  </Col>
                  <Col md={8}>
                    {null === this.state.supplierType
                      ? ""
                      : this.state.supplierType.type}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      GSTN
                    </label>
                  </Col>
                  <Col md={8}>{this.state.gstn}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      PAN
                    </label>
                  </Col>
                  <Col md={8}>{this.state.pan}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      Description
                    </label>
                  </Col>
                  <Col md={8}>{this.state.description}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      Phone
                    </label>
                  </Col>
                  <Col md={8}>{this.state.phone}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      Email
                    </label>
                  </Col>
                  <Col md={8}>{this.state.email}</Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000", fontWeight: "bold" }}>
                      Supplier Status
                    </label>
                  </Col>
                  <Col md={8}>{this.state.suppStatus}</Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col
            md={6}
            style={{
              padding: "1em",
              display: "flex",
              flexGrow: "1",
              flexDirection: "column",
            }}
          >
            <div md={12}>
              <Card md={12}>
                <CardHeader className="cardHeaderStyle">
                  <label className="cardHeaderTitle">
                    <strong>Registered Address</strong>
                  </label>
                </CardHeader>
                {/* <hr></hr> */}
                <CardBody>
                  <Address
                    ref={this.addressRef}
                    data={this.state.address}
                    action="view"
                  />
                </CardBody>
              </Card>
            </div>
            <div md={12} style={{ marginTop: "1em", height: "100%" }}>
              <Card md={12} style={{ flexGrow: "1", height: "100%" }}>
                <CardHeader className="cardHeaderStyle">
                  <label className="cardHeaderTitle">
                    <strong>Other Information</strong>
                  </label>
                </CardHeader>
                {/* <hr></hr> */}
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <label style={{ color: "#000" }}>
                        <strong>GSTN Verified</strong>
                      </label>
                    </Col>
                    <Col md={6}>
                      {this.state.gstnverified &&
                        true === this.state.gstnverified
                        ? "Yes"
                        : "No"}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row md={12} style={{ marginTop: "2em" }}>
          <Col>
            <CardFooter style={{ width: "100%" }}>
              <div style={{ textAlign: "center" }}>
                {/* <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  startIcon={<ArrowUpwardIcon />}
                  onClick={(e) => this.importSupplier(e)}
                >
                  Import
                </Button> */}
                <BackIconButton
                  // startIcon={<CloseIcon />}
                  onClick={(e) => this.cancelView()}
                >
                  Back
                </BackIconButton>
              </div>
            </CardFooter>
          </Col>
        </Row>
      </div>
    );
  }
  createSupplier() {
    return (
      <div>
        <Row
          md={12}
          style={{
            marginTop: "-5px",
            minHeight: "2.5em",
            backgroundColor: "white",
            alignItems: "flex-end",
            boxShadow: "0 0 10px",
          }}
        >
          <Col md={8}>
            <h5>
              <b>Create Supplier</b>
            </h5>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row style={{ minHeight: "1em" }}></Row>
        <Row md={12}>
          <Col md={12}>
            <ValidatorForm ref="form" onSubmit={this.formValidation.bind(this)}>
              <Row style={{ padding: "3em" }}>
                <Col md={6}>
                  <Card>
                    <CardHeader className="supplierHeading">
                      <b>Supplier Details</b>
                    </CardHeader>
                    <CardBody>
                      <Row className="supplierRow">
                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Supplier Type
                          </label>
                          <Autocomplete
                            id="supplierType"
                            ListboxProps={{
                              className: "myCustomList"
                            }}
                            options={
                              null === this.supplierTypeList
                                ? []
                                : this.supplierTypeList
                            }
                            value={this.state.supplierType}
                            getOptionLabel={(option) => option.type}
                            onChange={(event, value) => {
                              this.handleSupplierTypeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Supplier Type"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>

                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Business Type
                          </label>
                          <Autocomplete
                            id="businesstype"
                            ListboxProps={{
                              className: "myCustomList"
                            }}
                            options={
                              null === this.businessTypeList
                                ? []
                                : this.businessTypeList
                            }
                            value={this.state.businesstype}
                            getOptionLabel={(option) => option.type}
                            onChange={(event, value) => {
                              this.handleBusinessTypeChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Business Type"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {this.state.showGSTN ? (
                        <Row style={{ alignItems: "flex-end", padding: "0.5em" }}>
                          <Col md={6}>
                            <label
                              className="form-label"
                              htmlFor="collapsible-companyName"
                            >
                              GSTN
                            </label>
                            <TextField
                              fullWidth
                              id="gstn"
                              type="text"
                              placeholder="GSTN"
                              validators={["required"]}
                              errorMessages={["GSTN is required"]}
                              onChange={this.handleGstnChange.bind(this)}
                              onBlur={this.handleGstnOnBlur.bind(this)}
                              value={this.state.gstn}
                              name="gstn"
                              className="textField"
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="gstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row></Row>
                      )}
                      <Row className="customerRow">
                        <Col md={12}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Name
                          </label>
                          <TextField
                            fullWidth
                            id="name"
                            placeholder="Name"
                            value={this.state.name}
                            onChange={this.handleNameChange.bind(this)}
                            name="name"
                            validators={["required"]}
                            errorMessages={["Customer Name is Required"]}
                            autoComplete="name"
                            className="textField"
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                      <Row className="customerRow">
                        <Col md={12}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Description
                          </label>
                          <TextField
                            fullWidth
                            id="description"
                            placeholder="Description"
                            value={this.state.description}
                            onChange={this.handleDescriptionChange.bind(this)}
                            autoComplete="description"
                            className="textField"
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                      <Row className="customerRow">
                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            PAN
                          </label>
                          <TextField
                            fullWidth
                            id="pan"
                            placeholder="PAN"
                            value={this.state.pan}
                            onChange={this.handlePanChange.bind(this)}
                            validators={["required"]}
                            errorMessages={["PAN is Required"]}
                            name="pan"
                            autoComplete="pan"
                            className="textField"
                            variant="outlined"
                          />
                        </Col>

                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Supplier Category
                          </label>
                          <Autocomplete
                            id="category"
                            ListboxProps={{
                              className: "myCustomList"
                            }}
                            disabled
                            options={
                              null === this.supplierCategoryList
                                ? []
                                : this.supplierCategoryList
                            }
                            value={this.state.selectedSupplierCategory}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              this.handleSupplierCategoryChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Supplier Category"
                                className="regCountry"
                                variant="outlined"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="customerRow">
                        <label
                          className="form-label"
                          htmlFor="collapsible-companyName"
                        >
                          Phone Number
                        </label>
                        <Col md={6}>
                          <TextField
                            fullWidth
                            id="phone"
                            placeholder="Phone Number"
                            value={this.state.phone}
                            onChange={this.handlePhoneChange.bind(this)}
                            name="phone"
                            autoComplete="phone"
                            className="textField"
                            variant="outlined"
                          />
                        </Col>

                        <Col md={6}>
                          <label
                            className="form-label"
                            htmlFor="collapsible-companyName"
                          >
                            Email
                          </label>
                          <TextField
                            fullWidth
                            id="email"
                            placeholder="Email"
                            value={this.state.email}
                            name="email"
                            autoComplete="email"
                            type="email"
                            onChange={this.handleEmailChange.bind(this)}
                            className="textField"
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Address
                    title=""
                    ref={this.addressRef}
                    data={this.state.address}
                    onChange={this.handleAddressChange.bind(this)}
                    action="create"
                  />

                  <Card
                    style={{
                      // width: "-webkit-fill-available",
                      height:
                        true === this.state.isTradeNameApplicable
                          ? "55.5%"
                          : "46%",
                      position: "relative",
                      marginTop: "1em",
                    }}
                  >
                    <CardHeader className="cardHeaderStyle">
                      <label className="cardHeaderTitle">
                        <strong>Other Information</strong>
                      </label>
                    </CardHeader>
                    {/* <hr></hr> */}
                    <CardBody>
                      <Row style={{ padding: "5px", alignItems: "flex-end" }}>
                        {/* <Col md={6} style={{ padding: "5px" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            Registration Status
                          </label>
                          <TextField
                            variant="outlined"
                            disabled
                            fullWidth
                            id="registatus"
                            placeholder="Registration Status"
                            value={this.state.registatus}
                            name="registatus"
                            autoComplete="registatus"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Col> */}
                        {/* </Row>
                        <Row style={{ padding: '5px', alignItems: 'flex-end' }}> */}
                        <Col md={6} style={{ padding: "5px" }}>
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            GST verified
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            disabled
                            id="gstverified"
                            placeholder="GST verified"
                            value={this.state.gstnverified ? "Yes" : "No"}
                            onChange={this.handleGstVerifiedChange.bind(this)}
                            name="gstverified"
                            autoComplete="gstverified"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row md={12} style={{ marginTop: "2em" }}>
                <Col>
                  <CardFooter style={{ width: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        style={{ backgroundColor: "#1a7088", color: "#fff" }}
                        variant="contained"
                      // onClick={(e) => this.formValidation(e)}
                      >
                        Submit
                      </Button>
                      &nbsp;
                      {/* <Button
                      style={{ backgroundColor: '#777777', color: '#fff' }}
                      onClick={e => this.cancel()}
                    >
                      Cancle
                    </Button> */}
                      <NavLink
                        style={{ backgroundColor: "#777777", color: "#fff" }}
                        to="/suppImport"
                        activeClassName="selectedLink"
                        strict
                      >
                        <Button
                          style={{ backgroundColor: "#777777", color: "#fff" }}
                        >
                          Cancel
                        </Button>
                      </NavLink>
                    </div>
                  </CardFooter>
                </Col>
              </Row>
            </ValidatorForm>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(ImportSupplier);
