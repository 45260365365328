import { GET_HEADER, SET_HEADER } from './headerTypes';

export const getHeader = () => {
  return {
    type: GET_HEADER,
  };
};

export const setHeader = data => {
  return {
    type: SET_HEADER,
    payload: data,
  };
};
const headerAction = {
  getHeader, setHeader
}
export default headerAction;
