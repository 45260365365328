import React from "react";
import { Row, Col } from "reactstrap";

export class PrivacyPolicy extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			render: false,
		};
	}

	printContent() {
		let printContents = document.getElementById("printform").innerHTML;
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();

		document.body.innerHTML = originalContents;
		this.setState({ render: true });
		document.location.reload(true);
	}
	contactUs() {
		this.props.history.push({
			pathname: "/home",
			state: {
				scrollTo: "contact",
			},
		});
	}

	render() {
		return (
			<div
				id="termsConditionSection"
				style={{
					height: "90vh",
					overflowY: "scroll",
					padding: "0px 50px 30px 50px",
					background: "#fff",
				}}
			>
				<div
					style={{
						border: "2px solid lightgray",
						marginTop: " 10px",
						padding: "1em",
					}}
				>
					<section id="printform" className="contact">
						<Row>
							<Col md={12}>
								<h3 style={{ color: "#000", textAlign: "center" }}>
									<b>
										<u>Privacy Policy</u>
									</b>
								</h3>
							</Col>
						</Row>
						<Row
							style={{
								marginTop: "1em",
								fontWeight: "400",
								lineHeight: "28px",
							}}
						>
							<Col md={12} style={{ textAlign: "left" }}>
								<p>
									Taxonomy Hub (“us”, “we”, or “our”) is committed to respecting the privacy rights and concerns of all users of the Service.
									As such, we have established this Privacy Policy so that you can know our practices regarding the collection and use of
									information about visitors to the Service. The Service is intended to provide several different services to assist you in
									creating and maintaining an inventory of assets, including information regarding business assets. We use information that
									we collect from you to efficiently provide the Service. By using the Service, you consent to Provider’s collection, use,
									storage, deletion, and disclosure of information relating to you as set forth in this Privacy Policy. This Privacy Policy
									is only applicable to the Service and not to any other website that you may be able to access from the Service which may
									have its own data collection and use practices and policies.
								</p>
								<p>
									<b>Information that we collect and how we use it</b>
								</p>
								<p className="m-0">
									We may collect the following personal information from you within the Websites:
								</p>
								<ul className="policyList mb-3">
									<li>
										<p>
											Contact Information, such as name, email address, mailing address, or phone number.
										</p>
									</li>{" "}
									<li>
										<p>
											Information about your business, such as company name, company size, business type
										</p>
									</li>
								</ul>
								<p className="m-0">
									We may collect the following personal information from you
									when you use our Service(s).
								</p>{" "}
								<ul className="policyList mb-3">
									<li>
										<p>
											Contact Information, such as name, email address, mailing address, IP address, geographic location, or phone number.
										</p>
									</li>
									<li>
										<p>
											Billing Information, such as credit card number and billing address.
										</p>
									</li>
									<li>
										<p>
											Unique Identifiers, such as username, account number or password.
										</p>
									</li>{" "}
									<li>
										<p>
											Name and e-mail address when you provide feedback from the Service(s).
										</p>
									</li>
								</ul>
								<p className="m-0">
									We may use the personal information collected from within the Websites or when you use the Service(s) to:
								</p>
								<ul className="policyList mb-3">
									<li>
										<p>Provide you with the Service(s).</p>
									</li>
									<li>
										<p>Send you communication from the Service(s).</p>
									</li>
									<li>
										<p>
											Assess the needs of your business to determine or suggest
											suitable products
										</p>
									</li>
									<li>
										<p>Send you requested product or service information</p>
									</li>
									<li>
										<p>Respond to customer service requests</p>
									</li>
									<li>
										<p>Administer your account</p>
									</li>
									<li>
										<p>Respond to your questions and concerns</p>
									</li>
									<li>
										<p>
											{" "}
											Facilitate your transactions with other users when you use
											our Service(s).
										</p>
									</li>
								</ul>
								<p >
									When you provide us with personal information about your
									contacts, we will only use this information for the specific
									reason for which it is provided.
								</p>
								<br />
								<h6 style={{ color: "#000" }}>
									<b>Security</b>
								</h6>
								<p>
									The personal information that you provide to us is stored on servers with restricted access and protected by protocols and
									procedures designed to ensure the security of such information. In addition, we restrict access to personal information to
									Provider employees, independent contractors and agents who need to know this information to develop, operate and maintain
									the Service. All Provider personnel who have access to this information are trained in the maintenance and security of
									such information. However, no server, computer or communications network or system, or data transmission over the Internet
									can be guaranteed to be 100% secure. As a result, while we strive to protect user information, we cannot ensure or warrant
									the security of any information you transmit to us through use of the Service, and you acknowledge and agree that you provide
									such information and engage in such transmissions at your own risk. Once we receive a transmission from you, we will endeavour
									to maintain its security on our systems.
								</p>
								<br />
								<h6 style={{ color: "#000" }}>
									<b>Mobile Applications</b>
								</h6>
								<p>
									When you download, install, and use our Mobile Applications,
									we automatically collect information on the type of device you use,
									operating system version, and the device identifier (or “UDID”).
								</p>
								<p>
									We send you push notifications from time-to-time in order to
									update you about any events or promotions that we may be running.
									To ensure you receive proper notifications, we will need to collect
									certain information about your device such as operating system and
									user identification information.
								</p>
								<p>
									We collect your location-based information for the purpose of locating a place.
									We will only share this information with our mapping provider for the sole
									purpose of providing you this service.
								</p>
								<p>
									We use mobile analytics software to allow us to better understand the functionality
									of our Mobile Application on your mobile or handheld device. This Mobile Application
									may record information such as the events that occur within the Mobile Application,
									aggregated usage, performance data, and location. We do not link the information we
									store within the analytics software to any personally identifiable information you
									submit within the Mobile Application.
								</p>
								<p>
									This Privacy Policy may change from time to time, and we will
									post all changes on this page.
								</p>
								<br />
								<h6 style={{ color: "#000" }}>
									<b>Contact Us</b>
								</h6>
								<p>
									If you have any questions about this Privacy Policy, please
									&nbsp;
									<b>
										<a
											onClick={this.contactUs.bind(this)}
											style={{ cursor: "pointer", color: "#337AB7" }}
										>
											<b>CONTACT US.</b>
										</a>
									</b>
								</p>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		);
	}
}

export default PrivacyPolicy;
