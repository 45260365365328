import MaterialTable from "material-table";
import React, { Component } from "react";
import { Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";

export class ProductServiceList extends Component {
  productServicesMasterState =
    store.getState().applicationState.productServicesMasterState;
  productServicesMasterStateDetails = {};
  breadCrumb = [
    { pageid: "/master", uiname: "Master", cssclass: "fa fa-book" },
    { pageid: "/prodserv", uiname: "Product/Service List" },
  ];
  title = "Product/Service List";
  constructor(props) {
    super(props);
    // if (this.props.location && this.props.location.pathname) {
    //   let menu = Utils.getMenu(this.props.location.pathname);
    //   if (menu && menu.uiname && menu.uiname.length > 0) {
    //     //console.log('PATHNAME:######' + JSON.stringify(menu));
    //     this.title = menu.uiname;
    //   }
    //   this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    // }
    this.tableIcons = UiUtils.getMaterialTableIcons();
    if (this.productServicesMasterState) {
      this.productServicesMasterStateDetails = Object.getOwnPropertyNames(
        this.productServicesMasterState
      );
    }
    if (this.productServicesMasterStateDetails.length > 0) {
      this.state = {
        productList: "",
        productListArray: [],
        privileges: [],
        invoiceId: this.productServicesMasterState.invoiceId,
        pageSize: this.productServicesMasterState.pageSize,
        initialPage: this.productServicesMasterState.initialPage,
        isPaging: false,
        render: false,
      };
    } else {
      this.state = {
        productList: "",
        productListArray: [],
        privileges: [],
        pageSize: 10,
        initialStep: 0,
        isPaging: false,
        render: false,
      };
    }
    this.getProductData();
  }

  getProductData() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/productmgmt/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let privileges = output.data.privileges;
          let productList = output.data.productList;
          let isPaging = false;
          if (productList.length > 10) {
            isPaging = true;
          }
          this.setState({
            render: true,
            isPaging: isPaging,
            productListArray: productList,
            privileges: privileges,
          });
        }
      }
    );
  }
  addProductServices = (e) => {
    this.props.history.push({
      pathname: "/product",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  toProductDetails = (e, data) => {
    let productId = data.id;
    let productServicesMasterState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ productServicesMasterState })
    );
    this.props.history.push({
      pathname: "/productview",
      state: {
        productId: productId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };
  cancelView() {
    this.props.history.push({ pathname: "/master/" });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Product",
        isFreeAction: true,
        onClick: (event) => this.addProductServices(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <Row md={12} style={{ margin: 0, padding: 0 }}>
          <div style={{ width: "100%", padding: "1em" }}>
            <MaterialTable
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              icons={this.tableIcons}
              title="Product List"
              localization={{
                body: {
                  emptyDataSourceMessage: Utils.communizeTableText(),
                },
              }}
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Name",
                  field: "name",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "Code",
                  field: "code",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "Type",
                  field: "producttype",
                  align: "left",
                  width: "15%",
                },
                {
                  title: "Status",
                  render: (rowData) => rowData.isactive ? "Active" : "InActive",
                  align: "left",
                  width: "15%",
                },
              ]}
              data={this.state.productListArray}
              options={{
                paging: this.state.isPaging,
                pageSize: this.state.pageSize,
                initialPage: this.state.initialPage,
                pageSizeOptions: Utils.materialTablePageSize(),
                sorting: true,
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                  height: "3em",
                  textTransform: "capitalize",
                }),
              }}
              style={{
                boder: "1px solid black",
              }}
              onChangePage={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              actions={actionList}
              onRowClick={(e, rowData) => this.toProductDetails(e, rowData)}
            />
          </div>
        </Row>
      </>
    );
  }
}
export default ProductServiceList;
