import Tooltip from "@material-ui/core/Tooltip";
// import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";

export class RemoveIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{ cursor: "pointer", fontSize: "large", float: "right" }}
      >
        <Tooltip title="Remove">
          {/* <ClearIcon
            style={{
              borderRadius: '50%',
              background: 'black',
              color: 'white',
              fontSize: 'large',
              // boxShadow: '1px 1px 1px black',
            }}

          /> */}
          <Button
            className="add"
            onClick={this.onClick.bind(this)}
            style={{
              background: "#867e7e",
              color: "white",
              fontSize: "x-larger",
              textTransform: "capitalize",
              cursor: "pointer",
              float: "right",
              margin: "0.2em 0.5em 0.3em 0em",
            }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </Tooltip>
      </span>
    );
  }
}
export default RemoveIconButton;
