import React, { Component } from 'react';
import ReactApexCharts from 'react-apexcharts';

export class ApexPieChart extends Component {
	render() {
		return (
			<div className="text-start overflow-auto " id="chart" style={{ padding: "1em", overflowX: 'auto', }}>
				{
					this.props.series !== null && this.props.series !== null ?
						< ReactApexCharts
							series={this.props.series}
							options={{
								colors: this.props.dataKeyColors,
								labels: this.props.dataKeys,
								dataLabels: {
									enabled: true
								},
								legend: {
									show: false
								},
								chart: {
									type: 'pie',
								},

								responsive: [{
									breakpoint: 480,
									options: {
										chart: {
											width: 220,
										},
										legend: {
											position: 'bottom'
										}
									}
								}],
							}}
							type="pie"
							height={220}
							width={220}
						/>
						: ""
				}
			</div>
		);
	}
}

export default ApexPieChart;
