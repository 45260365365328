import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FileViewer from "react-file-viewer";
import { Buffer } from "buffer";
import React, { Component } from "react";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import PtsAlert from "../ptsAlert/PtsAlert";

export default class ViewReceipt extends Component {
  header = store.getState().header.header;
  tableIcons = UiUtils.getMaterialTableIcons();
  title = "Receipt Details";
  constructor(props) {
    super(props);
    let str = window.location.href;
    if (str.includes("viewReceipt?")) {
      let splitted = str.split("?");
      let splitForTid = str.split("&");
      let receiptUrl = splitted[1].substring(
        4,
        splitted[1].indexOf("&documentId")
      );
      let documentId = splitForTid[1].substring(11);
      this.viewDocument(receiptUrl, documentId);
    }

    this.state = {
      srcType: null,
      url: "",
      newUrl: "",
      expenseReceiptUrl: "",
      documentId: props.documentId,
      open: false,
    };
  }

  viewDocument(receiptUrl, documentId) {
    if (!receiptUrl) {
      PtsAlert.error("Receipt URL Not Available");
      return;
    }
    const postObject = {
      url: receiptUrl,
      documentid: documentId,
      header: this.header,
    };

    FetchServerData.callPostFileDownload("/filems/get", postObject).then(
      (output) => {
        if ("" !== output.data) {
          var expenseReceiptUrl =
            "data:application/*;base64," + output.data?.entity;
          const byteArray = new Buffer(
            output.data.entity.replace(/^[\w\d;:\/]+base64\,/g, ""),
            "base64"
          );
          const url = URL.createObjectURL(new Blob([byteArray]));
          let fileDetails = [];
          fileDetails = output.data?.metadata?.FileName;
          let fileName = "";
          if (fileDetails?.length > 0) {
            for (let i = 0; i < fileDetails?.length; i++) {
              fileName = fileDetails[i];
            }
          }
          let urlArr = fileName.split(".");
          let fileType = urlArr[1]?.toLowerCase();
          this.setState({
            newUrl: url,
            fileType: fileType,
            expenseReceiptUrl: expenseReceiptUrl,
            open: true,
          });
        }
      }
    );
  }

  downloadDocument(data) {
    const postObject = {
      url: data.receiptUrl,
      header: this.header,
    };

    FetchServerData.callPostFileDownload("/filems/get", postObject).then(
      (output) => {
        if ("" !== output.data) {
          PtsAlert.success("Receipt Downloaded Successfully");
          var url = "data:application/*;base64," + output.data.entity;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            !data.receiptFilename ? "download" : data.receiptFilename
          );
          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();
        }
      }
    );
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleServiceGoodsSelectChange(e) {
    this.setState({ serviceGoods: e.target.value });
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  hsnCodeSelected(e, selectedCode) {
    if (Utils.isNotNull(this.props.onCodeChange)) {
      this.props.onCodeChange(selectedCode);
      this.setState({ open: false });
    }
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        maxWidth="xxl"
        className="salesDialogue"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ backgroundColor: "#1b7189" }}
        >
          <span style={{ color: "#fff" }}>{this.title}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={this.handleClose.bind(this)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "#fff", fontWeight: "600" }} />
        </IconButton>
        <DialogContent style={{ minHeight: "500px" }}>
          <div
            md={12}
            style={{
              width: "100%",
              color: "#000",
              fontSize: "small",
              padding: "0.5em",
            }}
          >
            {this.state.fileType === "txt" ? (
              <iframe
                src={this.state.newUrl}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <div style={{ height: "100%" }}>
                <FileViewer
                  fileType={this.state.fileType}
                  filePath={this.state.newUrl}
                  onError={this.onError}
                  height={800}
                />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
