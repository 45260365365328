import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { Component } from "react";
export class BackIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      // <span
      //   onClick={this.onClick.bind(this)}
      //   style={{
      //     cursor: "pointer",
      //     fontSize: "larger",
      //     marginRight: "0.5em",
      //     float: "left",
      //   }}
      // >
      //   <ArrowBackIcon
      //     style={{
      //       border: "2px solid #fff",
      //       borderRadius: "25%",
      //       // background: '#2e652b',
      //       // color: 'white',
      //       // padding: '2px',
      //       // fontSize: 'x-large',
      //     }}
      //     className="backIconButton"
      //   />
      // </span>
      <Tooltip title="Back">
        <Button
          type={this.state.type ? this.state.type : ""}
          variant="contained"
          size="small"
          startIcon={<ArrowBackIcon />}
          style={{
            background: "#717373",
            color: "#fff",
            marginRight: "1rem",
            marginBottom: "1em",
            textTransform: "capitalize",
          }}
          onClick={this.onClick.bind(this)}
        >
          Back
          {/* {this.state.name ? this.state.name : "Back"} */}
        </Button>
      </Tooltip>
    );
  }
}
export default BackIconButton;
