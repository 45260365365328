import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Button from "@material-ui/core/Button";
import FetchServerData from "../../../../provider/FetchServerData";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ApexPieChart from "../../chart/ApexPieChart";
import { exportComponentAsJPEG } from "react-component-export-image";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { Menu } from "@mui/material";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
// import './QuarterlyFinancial.scss';
export default class QuaterlyReport extends Component {
  businessUnitList = store.getState().appData.settings.businessUnits;
  financialYearList = store.getState().appData.fyList;
  org = store.getState().appData.settings.org;
  // quarterList = Utils.getQuarterList();
  breadCrumb = [];
  title = "Quarterly Financial";
  constructor(props) {
    super(props);
    let action = props.action;
    this.imageDwlRef = React.createRef();
    /* Set Data From history */
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
      this.selectedTabIndex = props.history.location.state.selectedTabIndex;
    }

    this.state = {
      action: action,
      isItcEditEnabled: false,
      financialMonth: null,
      financialsYear: null,
      businessUnit:
        this.businessUnitList && this.businessUnitList.length > 0
          ? this.businessUnitList[0]
          : null,
      salesTaxable: "",
      salesIgst: "",
      salesCgst: "",
      salesSgst: "",
      salesCess: "",
      salesTotal: "",
      advanceTaxable: "",
      advanceIgst: "",
      advanceCgst: "",
      advanceSgst: "",
      advanceCess: "",
      advanceTotal: "",
      debitTaxable: "",
      debitIgst: "",
      debitCgst: "",
      debitSgst: "",
      debitCess: "",
      debitTotal: "",
      creditTaxable: "",
      creditIgst: "",
      creditCgst: "",
      creditSgst: "",
      creditCess: "",
      creditTotal: "",
      refundTaxable: "",
      refundIgst: "",
      refundCgst: "",
      refundSgst: "",
      refundCess: "",
      refundTotal: "",
      netTaxable: "",
      netIgst: "",
      netCgst: "",
      netSgst: "",
      netCess: "",
      netTotal: "",
      salesFinancialMonth: "",
      salesFinancialYear: "",
      salesRcmTaxable: "",
      salesRcmigst: "",
      salesRcmCgst: "",
      salesRcmSgst: "",
      salesRcmCess: "",
      salesRcmTotal: "",
      purchaseTaxable: "",
      purchaseIgst: "",
      purchaseCgst: "",
      purchaseSgst: "",
      purchaseCess: "",
      purchaseTotal: "",
      purchaseAdvanceTaxable: "",
      purchaseAdvanceIgst: "",
      purchaseAdvanceCgst: "",
      purchaseAdvanceSgst: "",
      purchaseAdvanceCess: "",
      purchaseAdvanceTotal: "",
      purchaseDebitTaxable: "",
      purchaseDebitIgst: "",
      purchaseDebitCgst: "",
      purchaseDebitSgst: "",
      purchaseDebitCess: "",
      purchaseDebitTotal: "",
      purchaseCreditTaxable: "",
      purchaseCreditIgst: "",
      purchaseCreditCgst: "",
      purchaseCreditSgst: "",
      purchaseCreditCess: "",
      purchaseCreditTotal: "",
      purchaseRefundTaxable: "",
      purchaseRefundIgst: "",
      purchaseRefundCgst: "",
      purchaseRefundSgst: "",
      purchaseRefundCess: "",
      purchaseRefundTotal: "",
      purchaseNetTaxable: "",
      purchaseNetIgst: "",
      purchaseNetCgst: "",
      purchaseNetSgst: "",
      purchaseNetCess: "",
      purchaseNetTotal: "",
      purchaseItcTaxable: "",
      purchaseItcIgst: "",
      purchaseItcCgst: "",
      purchaseItcSgst: "",
      purchaseItcCess: "",
      purchaseItcTotal: "",
      purchaseRcmTaxable: "",
      purchaseRcmigst: "",
      purchaseRcmCgst: "",
      purchaseRcmSgst: "",
      purchaseRcmCess: "",
      purchaseRcmTotal: "",
      expenseAmount: "",
      expenseFinancialMonth: "",
      expenseFinancialYear: "",
      gstSummary: {},
      gstCollected: "",
      gstCredit: "",
      purchaseFinancialMonth: "",
      purchaseFinancialYear: "",
      payableReceiveable: "",
      purchaseItcId: "",
      defaultFinancialsYear: null,
      defaultBusinessUnit: null,
      defaultFinancialMonth: null,
      quarterList: [],
      showing: true,
      open: false,
    };

    this.getQuarterlyFinancialsDeatils();
    this.getQuarterList();
  }

  getQuarterList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService(
      "/orgfinance/getQuarterList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let quarterList = output.data;
        this.setState({
          render: true,
          quarterList: quarterList,
        });
      }
    });
  }

  handleFinancialsYearChange(e) {
    this.setState({
      financialsYear: e,
    });
  }

  handleBusinessUnitChange(e) {
    this.setState({ businessUnit: e });
  }

  handleItcigstChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcIgst: e.target.value });
    }
  }

  handleItcCgstChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcCgst: e.target.value });
    }
  }

  handleItcSgstChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcSgst: e.target.value });
    }
  }

  handleItcCessChange(e) {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ purchaseItcCess: e.target.value });
    }
  }

  editCreditBal = () => {
    const header = store.getState().header.header;
    const postObject = {
      itcBalance: {
        id: this.state.purchaseItcId,
        cess: this.state.purchaseItcCess,
        igst: this.state.purchaseItcIgst,
        cgst: this.state.purchaseItcCgst,
        sgst: this.state.purchaseItcSgst,
      },

      header: header,
    };

    FetchServerData.callPostService("/itc/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let editCreditBal = output.data;
          this.setState({
            isItcEditEnabled: false,
          });
        } else {
        }
      }
    );
  };

  getBusinessUnitList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/bums/getlistbyheader", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.businessUnitListArray = output.data;
          this.setState({
            businessUnit: this.businessUnitListArray[0],
          });
        } else {
        }
      }
    );
  }

  getCreditBalfromGST() {
    const header = store.getState().header.header;
    const postObject = {
      otp: null,
      fyear: this.state.financialsYear,
      itcBalance: {
        month: "APR",
      },
      header: header,
    };

    FetchServerData.callPostService("/itc/calculateItc", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let CreditBalfromGST = output.data;
        } else {
        }
      }
    );
  }

  getQuarterlyFinancialsDeatils = () => {
    const header = store.getState().header.header;
    const postObject = {
      bucode: this.state.businessUnit?.name,
      fyear: this.state.financialsYear,
      financial: {
        month: this.state.financialMonth,
      },

      header: header,
    };
    FetchServerData.callPostService(
      "/orgfinance/getForQuarter",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let quaterlyData = output.data;
        this.gstSummary = quaterlyData.gstSummary;
        this.setResponseToState(quaterlyData);
      } else {
      }
    });
  };

  resetQuarterlyFinancialsDetails = (
    financialsYear,
    financialMonth,
    businessUnit
  ) => {
    const header = store.getState().header.header;
    const postObject = {
      bucode: businessUnit?.code,
      fyear: financialsYear,
      financial: {
        month: financialMonth,
      },

      header: header,
    };
    FetchServerData.callPostService(
      "/orgfinance/getForQuarter",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let quaterlyData = output.data;
        this.gstSummary = quaterlyData.gstSummary;
        this.setResponseToState(quaterlyData);
      } else {
      }
    });
  };

  setResponseToState(quaterlyData) {
    this.setState({
      //id: quaterly.id,
      open: false,
      salesTaxable: quaterlyData.sales.sales.taxable,
      salesIgst: quaterlyData.sales.sales.igst,
      salesCgst: quaterlyData.sales.sales.cgst,
      salesSgst: quaterlyData.sales.sales.sgst,
      salesCess: quaterlyData.sales.sales.cess,
      salesTotal: quaterlyData.sales.sales.total,
      advanceTaxable: quaterlyData.sales.receipt.taxable,
      advanceIgst: quaterlyData.sales.receipt.igst,
      advanceCgst: quaterlyData.sales.receipt.cgst,
      advanceSgst: quaterlyData.sales.receipt.sgst,
      advanceCess: quaterlyData.sales.receipt.cess,
      advanceTotal: quaterlyData.sales.receipt.total,
      debitTaxable: quaterlyData.sales.debit.taxable,
      debitIgst: quaterlyData.sales.debit.igst,
      debitCgst: quaterlyData.sales.debit.cgst,
      debitSgst: quaterlyData.sales.debit.sgst,
      debitCess: quaterlyData.sales.debit.cess,
      debitTotal: quaterlyData.sales.debit.total,
      creditTaxable: quaterlyData.sales.credit.taxable,
      creditIgst: quaterlyData.sales.credit.igst,
      creditCgst: quaterlyData.sales.credit.cgst,
      creditSgst: quaterlyData.sales.credit.sgst,
      creditCess: quaterlyData.sales.credit.cess,
      creditTotal: quaterlyData.sales.credit.total,
      refundTaxable: quaterlyData.sales.refund.taxable,
      refundIgst: quaterlyData.sales.refund.igst,
      refundCgst: quaterlyData.sales.refund.cgst,
      refundSgst: quaterlyData.sales.refund.sgst,
      refundCess: quaterlyData.sales.refund.cess,
      refundTotal: quaterlyData.sales.refund.total,
      netTaxable: quaterlyData.sales.nettaxable,
      netIgst: quaterlyData.sales.netigst,
      netCgst: quaterlyData.sales.netcgst,
      netSgst: quaterlyData.sales.netsgst,
      netCess: quaterlyData.sales.netcess,
      netTotal: quaterlyData.sales.nettotal,
      purchaseTaxable: quaterlyData.purchase.purchase.taxable,
      purchaseIgst: quaterlyData.purchase.purchase.igst,
      purchaseCgst: quaterlyData.purchase.purchase.cgst,
      purchaseSgst: quaterlyData.purchase.purchase.sgst,
      purchaseCess: quaterlyData.purchase.purchase.cess,
      purchaseTotal: quaterlyData.purchase.purchase.total,
      purchaseAdvanceTaxable: quaterlyData.purchase.receipt.taxable,
      purchaseAdvanceIgst: quaterlyData.purchase.receipt.igst,
      purchaseAdvanceCgst: quaterlyData.purchase.receipt.cgst,
      purchaseAdvanceSgst: quaterlyData.purchase.receipt.sgst,
      purchaseAdvanceCess: quaterlyData.purchase.receipt.cess,
      purchaseAdvanceTotal: quaterlyData.purchase.receipt.total,
      purchaseDebitTaxable: quaterlyData.purchase.debit.taxable,
      purchaseDebitIgst: quaterlyData.purchase.debit.igst,
      purchaseDebitCgst: quaterlyData.purchase.debit.cgst,
      purchaseDebitSgst: quaterlyData.purchase.debit.sgst,
      purchaseDebitCess: quaterlyData.purchase.debit.cess,
      purchaseDebitTotal: quaterlyData.purchase.debit.total,
      purchaseCreditTaxable: quaterlyData.purchase.credit.taxable,
      purchaseCreditIgst: quaterlyData.purchase.credit.igst,
      purchaseCreditCgst: quaterlyData.purchase.credit.cgst,
      purchaseCreditSgst: quaterlyData.purchase.credit.sgst,
      purchaseCreditCess: quaterlyData.purchase.credit.cess,
      purchaseCreditTotal: quaterlyData.purchase.credit.total,
      purchaseRefundTaxable: quaterlyData.purchase.refund.taxable,
      purchaseRefundIgst: quaterlyData.purchase.refund.igst,
      purchaseRefundCgst: quaterlyData.purchase.refund.cgst,
      purchaseRefundSgst: quaterlyData.purchase.refund.sgst,
      purchaseRefundCess: quaterlyData.purchase.refund.cess,
      purchaseRefundTotal: quaterlyData.purchase.refund.total,
      purchaseNetTaxable: quaterlyData.purchase.nettaxable,
      purchaseNetIgst: quaterlyData.purchase.netigst,
      purchaseNetCgst: quaterlyData.purchase.netcgst,
      purchaseNetSgst: quaterlyData.purchase.netsgst,
      purchaseNetCess: quaterlyData.purchase.netcess,
      purchaseNetTotal: quaterlyData.purchase.nettotal,
      purchaseItcIgst: quaterlyData.purchase.openingCredit?.igst,
      purchaseItcCgst: quaterlyData.purchase.openingCredit?.cgst,
      purchaseItcSgst: quaterlyData.purchase.openingCredit?.sgst,
      purchaseItcCess: quaterlyData.purchase.openingCredit?.cess,
      purchaseItcId: quaterlyData.purchase.openingCredit?.id,
      expenseAmount: quaterlyData.expense.amount,
      gstSummary: quaterlyData.gstSummary,
      gstCollected: quaterlyData.gstSummary.gstcollected,
      gstCredit: quaterlyData.gstSummary.gstcredit,
      payableReceiveable: quaterlyData.gstSummary.payableReceiveable,
      financialsYear: quaterlyData.year,
      financialMonth: quaterlyData.quarter,
      businessUnit: this.state.businessUnit,
      render: true,
    });
  }
  handleQuarterlyFinancialsChange(value) {
    this.setState({ financialMonth: value });
  }
  setItcEditEnabled(e) {
    this.setState({ isItcEditEnabled: !this.state.isItcEditEnabled });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/financialdetails/",
      state: {},
    });
  }

  goToBack() {
    store.dispatch(appDataAction.setReportTab(this.selectedTabIndex));
    this.props.history.push({
      pathname: "/reports/",
    });
  }

  resetFilter() {
    let financialsYear = null;
    let financialMonth = null;
    let businessUnit =
      this.businessUnitList && this.businessUnitList.length > 0
        ? this.businessUnitList[0]
        : null;
    this.setState({
      businessUnit: businessUnit,
    });

    this.resetQuarterlyFinancialsDetails(
      financialsYear,
      financialMonth,
      businessUnit
    );
  }

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  downloadImage() {
    let fileName = "Quaterly Financial Report";
    fileName = fileName.toString().split("/").join("");
    fileName = fileName.toString().split("\\").join("");
    if (this.imageDwlRef) {
      exportComponentAsJPEG(this.imageDwlRef, {
        fileName: fileName,
      });
    }
  }
  render() {
    let graphData = [];
    graphData.push({
      name: "GST Collected",
      value: this.state.gstCollected,
    });
    graphData.push({
      name: "GST Credit",
      value: this.state.gstCredit,
    });
    graphData.push({
      name: "Payable/Receivable",
      value: this.state.payableReceiveable,
    });

    let graphColors = ["#878bb6", "#4acab4", "#ff8153"];
    let totalQuarterlyGraphColors = ["#0077b6", "#4acab4", "#F7DC6F"];

    let totalQuarterlyFinancialData = [];
    totalQuarterlyFinancialData.push({
      name: "Sales",
      value: this.state.netTotal,
    });
    totalQuarterlyFinancialData.push({
      name: "Purchase",
      value: this.state.purchaseNetTotal,
    });
    totalQuarterlyFinancialData.push({
      name: "Expense",
      value: this.state.expenseAmount,
    });
    let logoData = Utils.getLogo();
    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={this.title}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <Row md={12} style={{ padding: "5px" }}>
          <Col md={6} style={{ textAlign: "start" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.goToBack(e)}
            >
              Back
            </Button>
          </Col>
          <Col md={6} style={{ padding: "5px", textAlign: "end" }}>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  startIcon={<PrintIcon />}
                >
                  Print
                </Button>
              )}
              content={() => this.printComponentRef}
            />

            <Button
              variant="contained"
              size="small"
              style={{
                background: "#9a7031",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<GetAppIcon />}
              onClick={(e) => this.downloadImage(e)}
            >
              Download
            </Button>

            {/* <Button
              variant="contained"
              size="small"
              style={{
                background: "#1b7189",
                color: "#fff",
                // marginRight: "1rem",
              }}
              startIcon={<EmailIcon />}
              onClick={(e) => this.emailSalesVoucher(e)}
            >
              Email
            </Button> */}
          </Col>
        </Row>
        <div className="row mb-4" style={{ padding: "0em 0.3em" }}>
          <div className="col-8 align-items-start text-start">
            <span className="ms-1">
              {this.state.financialMonth !== null ||
                this.state.financialsYear !== null ||
                this.state.businessUnit !== null ? (
                <span>
                  {this.state.financialMonth &&
                    this.state.financialMonth !== null ? (
                    <span>
                      <b>Quarter : </b>
                      {this.state.financialMonth + " , "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.financialsYear &&
                    this.state.financialsYear !== null ? (
                    <span>
                      <b>Financial Year : </b>
                      {this.state.financialsYear + " , "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.businessUnit &&
                    this.state.businessUnit !== null ? (
                    <span>
                      <b>Business Unit : </b>
                      {this.state.businessUnit.name}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="col-4 align-items-end text-end">
            <span
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>{" "}
            &nbsp;&nbsp;
            <span
              className="me-1"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.resetFilter.bind(this)}
            >
              <i class="fa fa-refresh" aria-hidden="true"></i> Reset
            </span>
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <ValidatorForm
              style={{ width: "100%", color: "#000" }}
              ref="form"
              onSubmit={this.getQuarterlyFinancialsDeatils.bind(this)}
            >
              <div className="row">
                <h5 className="bold">Filter</h5>

                <div className="row mt-2">
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      // className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      <b>Select Quarter</b>
                    </label>
                    <Autocomplete
                      fullWidth
                      variant="outlined"
                      id="month"
                      ListboxProps={{ className: "myCustomList" }}
                      options={
                        null === this.state.quarterList
                          ? []
                          : this.state.quarterList
                      }
                      value={this.state.financialMonth}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        this.handleQuarterlyFinancialsChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Select Quarter"
                          InputLabelProps={{
                            shrink: true,
                            style: {},
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      // className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      <b>Select Financial Year</b>
                    </label>
                    <Autocomplete
                      fullWidth
                      variant="outlined"
                      id="year"
                      ListboxProps={{ className: "myCustomList" }}
                      options={
                        null === this.financialYearList
                          ? []
                          : this.financialYearList
                      }
                      value={this.state.financialsYear}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        this.handleFinancialsYearChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="Select Financial Year"
                          InputLabelProps={{
                            shrink: true,

                            style: {},
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6" id="assetFormLabel">
                    {" "}
                    <label
                      // className="form-label "
                      htmlFor="collapsible-companyName"
                      style={{ color: "#000" }}
                    >
                      <b>Business Unit</b>
                    </label>
                    <Autocomplete
                      fullWidth
                      variant="outlined"
                      id="businessUnit"
                      ListboxProps={{ className: "myCustomList" }}
                      options={
                        null === this.businessUnitList
                          ? []
                          : this.businessUnitList
                      }
                      value={this.state.businessUnit}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleBusinessUnitChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          placeholder="Business Unit"
                          className="regCountry"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,

                            style: {},
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                {this.state.error ? (
                  <lable className="danger" style={{ color: "red" }}>
                    To Date should be after From Date
                  </lable>
                ) : (
                  ""
                )}
                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    // onClick={this.onFilterClick.bind(this)}
                    // onClick={(e) => this.filterRecords(e)}
                    type="submit"
                  // onClick={this.getMonthlyFinancialsDetails.bind(this)}
                  >
                    <i className="fa-solid fa-filter"></i>
                    &nbsp; Filter
                  </button>
                  &nbsp; &nbsp;
                  <button
                    className="resetButton"
                    onClick={this.resetFilter.bind(this)}
                  >
                    <i className="fa fa-refresh"></i>
                    &nbsp; Reset
                  </button>
                </div>
              </div>{" "}
            </ValidatorForm>
          </div>
        </Menu>
        <div ref={(el) => (this.printComponentRef = el)}>
          <Card
            md={12}
            id="printform"
            // ref={this.printComponentRef}
            // className="a4size"
            style={{ tableLayout: "auto", overflow: "auto", width: "100%" }}
          >
            <div ref={this.imageDwlRef}>
              <CardBody style={{ color: "#000" }}>
                <Row
                  md={12}
                  style={{
                    margin: "0",
                    padding: "5px",
                    display: "flex",
                    borderLeft: "1px solid gray",
                    borderTop: "1px solid gray",
                    borderRight: "1px solid gray",
                  }}
                >
                  <Col md={2}>
                    {Utils.isNotNullAndEmpty(logoData) && (
                      <img
                        src={logoData}
                        alt=""
                        style={{
                          height: "3em",
                          width: "3em",
                          padding: "0.1em",
                        }}
                      />
                    )}
                  </Col>
                  <Col md={8}>
                    <Row>
                      <strong style={{ width: "100%", textAlign: "center" }}>
                        {Utils.getOrgName()}
                      </strong>
                    </Row>
                    <Row>
                      <span style={{ width: "100%", textAlign: "center" }}>
                        {Utils.getAddressAsText(this.org?.addressvo)}
                      </span>
                    </Row>
                  </Col>
                  <Col md={2}>
                    <span style={{ width: "100%", textAlign: "center" }}>
                      {/* {this.state.duplicateTriplicate} */}
                    </span>
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{
                    margin: "0",
                    padding: "5px",
                    display: "flex",
                    borderLeft: "1px solid gray",
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                    borderRight: "1px solid gray",
                  }}
                >
                  <Col
                    md={12}
                    style={{ fontSize: "x-large", textAlign: "center" }}
                  >
                    <strong>
                      Quarterly Financial Report
                      {/* {this.state.invoiceheading} */}
                    </strong>
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{
                    margin: "0",
                    padding: "0",
                    borderRight: "1px solid gray",
                    borderLeft: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div style={{ marginTop: "1em", padding: "0em" }}>
                    <CardHeader style={{ color: "black" }}>
                      <strong>Outward Supply</strong>
                    </CardHeader>
                    <CardBody style={{ color: "black" }}>
                      <TableContainer component={Paper}>
                        <Table
                          size="small"
                          aria-label="a dense table"
                          style={{ fontSize: "smalle" }}
                        >
                          <TableHead>
                            <TableRow style={{ background: "#c5c5c5" }}>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                              >
                                Particulars
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                Taxable Value(Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                IGST (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                CGST (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                SGST (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                CESS (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                Total (Rs.)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key="outSales">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Sales
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.salesTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.salesIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.salesCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.salesSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.salesCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.salesTotal)}
                              </TableCell>
                            </TableRow>
                            <TableRow key="outAdvance">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Advance
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.advanceTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.advanceIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.advanceCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.advanceSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.advanceCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.advanceTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="outDebit">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Debit
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.debitTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.debitIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.debitCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.debitSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.debitCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.debitTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="outCredit">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Credit
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.creditTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.creditIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.creditCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.creditSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.creditCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.creditTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="outRefund">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Refund
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.refundTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.refundIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.refundCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.refundSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.refundCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.refundTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="outNet">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Net Amount
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.netTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.netIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.netCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.netSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.netCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.netTotal)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardBody>
                  </div>

                  <div style={{ marginTop: "1em", padding: "0em" }}>
                    <CardHeader style={{ color: "black" }}>
                      <strong>Inward Supply</strong>
                    </CardHeader>
                    <CardBody>
                      <TableContainer component={Paper}>
                        <Table
                          size="small"
                          aria-label="a dense table"
                          style={{ fontSize: "smalle" }}
                        >
                          <TableHead>
                            <TableRow style={{ background: "#c5c5c5" }}>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                              >
                                Particulars
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                Taxable Value(Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                IGST (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                CGST (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                SGST (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                CESS (Rs.)
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                Total (Rs.)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key="inSales">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Purchase
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseTotal)}
                              </TableCell>
                            </TableRow>
                            <TableRow key="inAdvance">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Advance
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseAdvanceTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseAdvanceIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseAdvanceCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseAdvanceSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseAdvanceCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseAdvanceTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="inDebit">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Debit
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {this.state.purchaseDebitTaxable}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseDebitIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseDebitCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseDebitSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseDebitCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseDebitTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="inCredit">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Credit
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCreditTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCreditIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCreditCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCreditSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCreditCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseCreditTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="inRefund">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Refund
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseRefundTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseRefundIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseRefundCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseRefundSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseRefundCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseRefundTotal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key="inNet">
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="left"
                              >
                                Net Amount
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseNetTaxable)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseNetIgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseNetCgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseNetSgst)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseNetCess)}
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.purchaseNetTotal)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardBody>
                  </div>

                  <div style={{ marginTop: "1em", padding: "0em" }}>
                    <CardHeader style={{ color: "black" }}>
                      <strong>Non-GST Expense</strong>
                    </CardHeader>
                    <CardBody>
                      <TableContainer component={Paper}>
                        <Table
                          size="small"
                          aria-label="a dense table"
                          style={{ fontSize: "smalle" }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                              >
                                <strong>Expense Amount</strong>
                              </TableCell>
                              <TableCell
                                style={{ border: "0.5px solid #9a9898" }}
                                align="right"
                              >
                                {Utils.getParseFloatValue(this.state.expenseAmount)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </CardBody>
                  </div>

                  <div style={{ marginTop: "1em", padding: "0em" }}>
                    <CardHeader style={{ color: "black" }}>
                      <Col md={6}>
                        <strong>Financial Summary</strong>
                      </Col>
                      <Col md={6}>
                        <strong>GST Summary</strong>
                      </Col>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <Table
                            size="small"
                            aria-label="a dense table"
                            style={{ border: "0", fontSize: "smalle" }}
                          >
                            <TableHead style={{ border: "none" }}>
                              <TableRow>
                                <Row>
                                  <Col md={6}>
                                    <TableCell style={{ border: "none" }}>
                                      <ApexPieChart
                                        style={{ margin: "0", padding: "0" }}
                                        series={[
                                          this.state.netTotal,
                                          this.state.purchaseNetTotal,
                                          this.state.expenseAmount,
                                        ]}
                                        title={"GST Summary"}
                                        dataKeys={[
                                          "Sales",
                                          "Purchase",
                                          "Expense",
                                        ]}
                                        dataKeyColors={
                                          totalQuarterlyGraphColors
                                        }
                                      />
                                    </TableCell>
                                  </Col>
                                  <Col md={6}>
                                    <TableCell
                                      align="left"
                                      style={{ border: "none" }}
                                    >
                                      <Row
                                        style={{
                                          padding: "5px",
                                          display: "block",
                                        }}
                                        className="mt-4"
                                      >
                                        <CheckBoxOutlineBlankIcon
                                          style={{
                                            color: totalQuarterlyGraphColors[0],
                                            background:
                                              totalQuarterlyGraphColors[0],
                                          }}
                                        />{" "}
                                        <strong>
                                          {`Sales : ${Utils.getParseFloatValue(totalQuarterlyFinancialData[0].value)}`}
                                        </strong>
                                      </Row>
                                      <Row
                                        style={{
                                          padding: "5px",
                                          display: "block",
                                        }}
                                      >
                                        <CheckBoxOutlineBlankIcon
                                          style={{
                                            color: totalQuarterlyGraphColors[1],
                                            background:
                                              totalQuarterlyGraphColors[1],
                                          }}
                                        />{" "}
                                        <strong>
                                          {`Purchase : ${Utils.getParseFloatValue(totalQuarterlyFinancialData[1].value)}`}
                                        </strong>
                                      </Row>
                                      <Row
                                        style={{
                                          padding: "5px",
                                          display: "block",
                                        }}
                                      >
                                        <CheckBoxOutlineBlankIcon
                                          style={{
                                            color: totalQuarterlyGraphColors[2],
                                            background:
                                              totalQuarterlyGraphColors[2],
                                          }}
                                        />{" "}
                                        <strong>
                                          {`Expense : ${Utils.getParseFloatValue(totalQuarterlyFinancialData[2].value)}`}
                                        </strong>
                                      </Row>
                                    </TableCell>
                                  </Col>
                                </Row>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </Col>
                        <Col md={6}>
                          <Table
                            size="small"
                            aria-label="a dense table"
                            style={{ border: "0", fontSize: "smalle" }}
                          >
                            <TableHead style={{ border: "none" }}>
                              <TableRow>
                                <Row>
                                  <Col md={6}>
                                    <TableCell style={{ border: "none" }}>
                                      <ApexPieChart
                                        style={{ margin: "0", padding: "0" }}
                                        series={[
                                          this.state.gstCollected,
                                          this.state.gstCredit,
                                          this.state.payableReceiveable,
                                        ]}
                                        title={"GST Summary"}
                                        dataKeys={[
                                          "GST Collected",
                                          "GST Credit",
                                          "Payable Receiveable",
                                        ]}
                                        dataKeyColors={graphColors}
                                      />
                                    </TableCell>
                                  </Col>
                                  <Col md={6}>
                                    <TableCell
                                      align="left"
                                      style={{ border: "none" }}
                                    >
                                      <Row
                                        style={{
                                          padding: "5px",
                                          display: "block",
                                        }}
                                        className="mt-4"
                                      >
                                        <CheckBoxOutlineBlankIcon
                                          style={{
                                            color: graphColors[0],
                                            background: graphColors[0],
                                          }}
                                        />{" "}
                                        <strong>
                                          {`GST Collected : ${Utils.getParseFloatValue(graphData[0].value)}`}
                                        </strong>
                                      </Row>
                                      <Row
                                        style={{
                                          padding: "5px",
                                          display: "block",
                                        }}
                                      >
                                        <CheckBoxOutlineBlankIcon
                                          style={{
                                            color: graphColors[1],
                                            background: graphColors[1],
                                          }}
                                        />{" "}
                                        <strong>
                                          {`GST Credit : ${Utils.getParseFloatValue(graphData[1].value)}`}
                                        </strong>
                                      </Row>
                                      <Row
                                        style={{
                                          padding: "5px",
                                          display: "block",
                                        }}
                                      >
                                        <CheckBoxOutlineBlankIcon
                                          style={{
                                            color: graphColors[2],
                                            background: graphColors[2],
                                          }}
                                        />{" "}
                                        <strong>
                                          {`Payable/Receivable : ${Utils.getParseFloatValue(graphData[2].value)}`}
                                        </strong>
                                      </Row>
                                    </TableCell>
                                  </Col>
                                </Row>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </div>
        {/* </div> */}
        {/* // </div> */}
      </>
    );
  }
}
