// import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
export class AddIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        style={{ cursor: "pointer", fontSize: "x-larger", float: "right" }}
      >
        {/* <Tooltip title="Add"> */}
        <Button
          className="add"
          onClick={this.onClick.bind(this)}
          style={{
            background: "rgb(27, 113, 137)",
            color: "white",
            fontSize: "x-larger",
            textTransform: "capitalize",
            cursor: "pointer",
            float: "right",
            margin: "0.2em 0.5em 0.3em 0em",
          }}
          startIcon={<AddIcon />}
        >
          Add
        </Button>
        {/* </Tooltip> */}
      </span>
    );
  }
}
export default AddIconButton;
