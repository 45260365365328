import { format } from "date-fns";
import Utils from "./Utils";
export const createEmptyInvoiceItem = () => {
  let newItem = {
    description: "",
    name: "",
    quantity: "0",
    productcode: "",
    rate: 0.0,
    total: 0.0,
    igst: 0.0,
    sgst: 0.0,
    cgst: 0.0,
    utgst: 0.0,
    discount: 0.0,
    taxable: 0.0,
    nontaxable: 0.0,
    cess: 0.0,
    operation: "add",
  };
  return newItem;
};
export const createInvoiceItem = (item) => {
  let newItem = {
    id: item.id,
    description: item.description,
    name: item.name,
    quantity: item.quantity,
    productcode: item.productcode,
    rate: item.rate,
    total: item.total,
    uuid: item.uuid,
    igst: item.igst,
    sgst: item.sgst,
    cgst: item.cgst,
    utgst: item.utgst,
    discount: item.discount,
    taxable: item.taxable,
    nontaxable: item.nontaxable,
    refinvitemamount: item.refinvitemamount,
    uom: item.uom,
    suprcmhsncode: item.suprcmhsncode,
    refinvitem: item.refinvitem,
    invoiceitemnumber: item.invoiceitemnumbe,
    cess: item.cess,
    taxrate: item.taxrate,
    custhsncode: item.custhsncode,
    operation: item.operation,
  };
  // if (item.operation) {
  //   newItem.operation = item.operation;
  // }
  return newItem;
};
export const setInvoiceItem = (targetObject, item) => {
  if (!item) {
    return null;
  }
  if (!targetObject) {
    targetObject = {};
  }
  targetObject.id = item.id;
  targetObject.description = item.description;
  targetObject.name = item.name;
  targetObject.quantity = item.quantity;
  targetObject.productcode = item.productcode;
  targetObject.rate = item.rate;
  targetObject.total = item.total;
  targetObject.uuid = item.uuid;
  targetObject.igst = item.igst;
  targetObject.sgst = item.sgst;
  targetObject.cgst = item.cgst;
  targetObject.utgst = item.utgst;
  targetObject.discount = item.discount;
  targetObject.taxable = item.taxable;
  targetObject.nontaxable = item.nontaxable;
  targetObject.refinvitemamount = item.refinvitemamount;
  targetObject.uom = item.uom;
  targetObject.suprcmhsncode = item.suprcmhsncode;
  targetObject.refinvitem = item.refinvitem;
  targetObject.invoiceitemnumber = item.invoiceitemnumbe;
  targetObject.cess = item.cess;
  targetObject.taxrate = item.taxrate;
  targetObject.custhsncode = item.custhsncode;
  return targetObject;
};
export const createAddressObject = (address) => {
  return address;
};
export const createInvoice = (invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  let outInvoice = {
    auditnotes: invoice.auditnotes,
    bankaccount: invoice.bankaccount,
    barcode: invoice.barcode,
    barcodeImage: invoice.barcodeImage,
    billtoaddress: invoice.billtoaddress,
    shiptoaddress: invoice.shiptoaddress,
    bu: invoice.bu,
    cdnreason: invoice.cdnreason,
    cess: invoice.cess,
    cesspercent: invoice.cesspercent,
    cgst: invoice.cgst,
    createdon: invoice.createdon,
    currency: invoice.currency,
    customerVo: invoice.customerVo,
    description: invoice.description,
    electronicreference: invoice.electronicreference,
    etin: invoice.etin,
    exchangerate: invoice.exchangerate,
    expinvoicetype: invoice.expinvoicetype,
    freez: invoice.freez,
    fright: invoice.fright,
    gstdate: invoice.gstdate,
    id: invoice.id,
    igst: invoice.igst,
    invnumber: invoice.invnumber,
    invoicedate: invoice.invoicedate,
    invoicesource: invoice.invoicesource,
    invoiceStatusList: invoice.invoiceStatusList,
    isUtilized: invoice.isUtilized,
    isAsset: invoice.isAsset,
    isbillingshippingsame: invoice.isbillingshippingsame,
    isexport: invoice.isexport,
    isimported: invoice.isimported,
    ismanualrefinv: invoice.ismanualrefinv,
    isnilrated: invoice.isnilrated,
    ispregst: invoice.ispregst,
    isrcmsale: invoice.isrcmsale,
    isRecurring: invoice.isRecurring,
    isroundup: invoice.isroundup,
    issez: invoice.issez,
    isSystemGenerated: invoice.isSystemGenerated,
    loadingpackaging: invoice.loadingpackaging,
    manualrefinv: invoice.manualrefinv,
    miscellaneous: invoice.miscellaneous,
    month: invoice.month,
    payment: invoice.payment,
    purchasedate: invoice.purchasedate,
    purchaseorder: invoice.purchaseorder,
    referencedInvoice: invoice.referencedInvoice,
    referenceinvnumber: invoice.referenceinvnumber,
    referencingInvoices: invoice.referencingInvoices,
    refinvoice: invoice.refinvoice,
    salesthrecom: invoice.salesthrecom,
    satype: invoice.satype,
    sezinvoicetype: invoice.sezinvoicetype,
    sgst: invoice.sgst,
    sharewithcust: invoice.sharewithcust,
    supplytype: invoice.supplytype,
    status: invoice.status,
    totaladvance: invoice.totaladvance,
    totalamount: invoice.totalamount,
    totalgst: invoice.totalgst,
    totalinwords: invoice.totalinwords,
    totaltaxable: invoice.totaltaxable,
    totaltaxableinwords: invoice.totaltaxableinwords,
    utgst: invoice.utgst,
    year: invoice.year,
    privileges: invoice.privileges,
  };
  let itemArray = [];
  if (Utils.isNotNull(invoice.invoiceItems)) {
    for (let i = 0; i < invoice.invoiceItems.length; i++) {
      let item = createInvoiceItem(invoice.invoiceItems[i]);
      itemArray.push(item);
    }
  }

  outInvoice.invoiceItems = itemArray;
  return outInvoice;
};

export const setInvoice = (targetObject, invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  if (!targetObject) {
    targetObject = {};
  }
  console.log(invoice.isroundup, "invoice.isroundup");
  targetObject.advanceAmountUsed = invoice.advanceAmountUsed;
  targetObject.advanceGstUsed = invoice.advanceGstUsed;
  targetObject.invoicetypeVo = invoice.invoicetypeVo;
  targetObject.paymentTransactions = invoice.paymentTransactions;
  // targetObject.auditnotes = invoice.auditnotes;
  targetObject.bankaccount = invoice.bankaccount;
  targetObject.barcode = invoice.barcode;
  targetObject.barcodeImage = invoice.barcodeImage;
  targetObject.billtoaddress = invoice.billtoaddress;
  targetObject.shiptoaddress = invoice.shiptoaddress;
  targetObject.bu = invoice.bu;
  targetObject.cdnreason = invoice.cdnreason;
  targetObject.cess = invoice.cess;
  targetObject.cesspercent = invoice.cesspercent;
  targetObject.cgst = invoice.cgst;
  targetObject.createdon = invoice.createdon;
  targetObject.currency = invoice.currency;
  targetObject.customerVo = invoice.customerVo;
  targetObject.description = invoice.description;
  targetObject.electronicreference = invoice.electronicreference;
  targetObject.etin = invoice.etin?.etin;
  targetObject.exchangerate = invoice.exchangerate;
  targetObject.expinvoicetype = invoice.expinvoicetype;
  targetObject.freez = invoice.freez;
  targetObject.fright = invoice.fright;
  targetObject.gstdate = invoice.gstdate;
  targetObject.id = invoice.id;
  targetObject.igst = invoice.igst;
  targetObject.invnumber = invoice.invnumber;
  targetObject.invoicedate = invoice.invoicedate;
  targetObject.invoicesource = invoice.invoicesource;
  targetObject.invoiceStatusList = invoice.invoiceStatusList;
  targetObject.isUtilized = invoice.isUtilized;
  targetObject.isAsset = invoice.isAsset;
  targetObject.isbillingshippingsame = invoice.isbillingshippingsame;
  targetObject.isexport = invoice.isexport;
  targetObject.isimported = invoice.isimported;
  targetObject.ismanualrefinv = invoice.ismanualrefinv;
  targetObject.isnilrated = invoice.isnilrated;
  targetObject.ispregst = invoice.ispregst;
  targetObject.isrcmsale = invoice.isrcmsale;
  targetObject.isRecurring = invoice.isRecurring;
  targetObject.isroundup = invoice.isroundup;
  targetObject.issez = invoice.issez;
  targetObject.isSystemGenerated = invoice.isSystemGenerated;
  targetObject.loadingpackaging = invoice.loadingpackaging;
  targetObject.manualrefinv = invoice.manualrefinv;
  targetObject.miscellaneous = invoice.miscellaneous;
  targetObject.month = invoice.month;
  targetObject.payment = invoice.payment;
  targetObject.purchasedate = invoice.purchasedate;
  targetObject.purchaseorder = invoice.purchaseorder;
  targetObject.referencedInvoice = invoice.referencedInvoice;
  targetObject.referenceinvnumber = invoice.referenceinvnumber;
  targetObject.referencingInvoices = invoice.referencingInvoices;
  targetObject.refinvoice = invoice.refinvoice;
  targetObject.salesthrecom = invoice.salesthrecom;
  targetObject.satype = invoice.satype;
  targetObject.sezinvoicetype = invoice.sezinvoicetype;
  targetObject.sgst = invoice.sgst;
  targetObject.sharewithcust = invoice.sharewithcust;
  targetObject.supplytype = invoice.supplytype;
  targetObject.status = invoice.status;
  targetObject.totaladvance = invoice.totaladvance;
  targetObject.totalamount = invoice.totalamount;
  targetObject.totalgst = invoice.totalgst;
  targetObject.totalinwords = invoice.totalinwords;
  targetObject.totaltaxable = invoice.totaltaxable;
  targetObject.totaltaxableinwords = invoice.totaltaxableinwords;
  targetObject.utgst = invoice.utgst;
  targetObject.year = invoice.year;
  targetObject.month = invoice.month;
  targetObject.privileges = invoice.privileges;
  let itemArray = [];
  for (let i = 0; i < invoice.invoiceItems.length; i++) {
    let item = createInvoiceItem(invoice.invoiceItems[i]);
    itemArray.push(item);
  }
  targetObject.invoiceItems = itemArray;
  return targetObject;
};

export const setInvoiceToState = (targetObject, invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  if (!targetObject) {
    return null;
  }
  let object = {
    advanceAmountUsed: 0.0,
    advanceGstUsed: 0.0,
    paymentTransactions: invoice.paymentTransactions,
    auditnotes: invoice.auditnotes,
    bankaccount: invoice.bankaccount,
    barcode: invoice.barcode,
    barcodeImage: invoice.barcodeImage,
    billtoaddress: invoice.billtoaddress,
    shiptoaddress: invoice.shiptoaddress,
    bu: invoice.bu,
    cdnreason: invoice.cdnreason,
    cess: invoice.cess,
    cesspercent: invoice.cesspercent,
    cgst: invoice.cgst,
    createdon: invoice.createdon,
    currency: invoice.currency,
    customerVo: invoice.customerVo,
    description: invoice.description,
    electronicreference: invoice.electronicreference,
    etin: invoice.etin,
    exchangerate: invoice.exchangerate,
    expinvoicetype: invoice.expinvoicetype,
    freez: invoice.freez,
    fright: invoice.fright,
    gstdate: invoice.gstdate,
    id: invoice.id,
    igst: invoice.igst,
    invnumber: invoice.invnumber,
    invoicedate: invoice.invoicedate,
    invoicesource: invoice.invoicesource,
    invoiceStatusList: invoice.invoiceStatusList,
    invoiceItems: invoice.invoiceItems,
    isUtilized: invoice.isUtilized,
    isAsset: invoice.isAsset,
    isbillingshippingsame: invoice.isbillingshippingsame,
    isexport: invoice.isexport,
    isimported: invoice.isimported,
    ismanualrefinv: invoice.ismanualrefinv,
    isnilrated: invoice.isnilrated,
    ispregst: invoice.ispregst,
    isrcmsale: invoice.isrcmsale,
    isRecurring: invoice.isRecurring,
    isroundup: invoice.isroundup,
    issez: invoice.issez,
    isSystemGenerated: invoice.isSystemGenerated,
    loadingpackaging: invoice.loadingpackaging,
    manualrefinv: invoice.manualrefinv,
    miscellaneous: invoice.miscellaneous,
    month: invoice.month,
    payment: invoice.payment,
    purchasedate: invoice.purchasedate,
    purchaseorder: invoice.purchaseorder,
    referencedInvoice: invoice.referencedInvoice,
    referenceinvnumber: invoice.referenceinvnumber,
    referencingInvoices: invoice.referencingInvoices,
    refinvoice: invoice.refinvoice,
    salesthrecom: invoice.salesthrecom,
    satype: invoice.satype,
    sezinvoicetype: invoice.sezinvoicetype,
    sgst: invoice.sgst,
    sharewithcust: invoice.sharewithcust,
    supplytype: invoice.supplytype,
    status: invoice.status,
    totaladvance: invoice.totaladvance,
    totalamount: invoice.totalamount,
    totalgst: invoice.totalgst,
    totalinwords: invoice.totalinwords,
    totaltaxable: invoice.totaltaxable,
    totaltaxableinwords: invoice.totaltaxableinwords,
    utgst: invoice.utgst,
    year: invoice.year,
    privileges: invoice.privileges,
    render: true,
  };
  // targetObject.setState(object);
};

export const getEmptyInvoice = () => {
  let emptyInvoice = {
    billtoaddress: null,
    shiptoaddress: null,
    bankaccount: null,
    paymentTransactions: [],
    auditnotes: [],
    barcode: "",
    barcodeImage: null,
    bu: null,
    cdnreason: null,
    cess: 0.0,
    cesspercent: 0,
    cgst: 0.0,
    createdon: format(new Date(), "yyyy-MM-dd"),
    currency: null,
    customerVo: null,
    description: "",
    electronicreference: "",
    etin: "",
    exchangerate: 1,
    expinvoicetype: null,
    freez: "",
    fright: "",
    gstdate: null,
    id: "",
    igst: "",
    invnumber: "",
    invoicedate: null,
    invoicesource: { source: "USERCREATED" },
    invoicetypeVo: null,
    invoiceStatusList: [],
    isUtilized: false,
    isAsset: false,
    isbillingshippingsame: "No",
    isexport: false,
    isimported: false,
    ismanualrefinv: false,
    isnilrated: false,
    ispregst: false,
    isrcmsale: false,
    isRecurring: false,
    isroundup: false,
    issez: false,
    isSystemGenerated: false,
    loadingpackaging: "",
    manualrefinv: "",
    miscellaneous: 0.0,
    advanceAmountUsed: 0.0,
    advanceGstUsed: 0.0,
    month: "",
    payment: null,
    privileges: [],
    purchasedate: null,
    purchaseorder: "",
    referencedInvoice: null,
    referenceinvnumber: "",
    referencingInvoices: null,
    refinvoice: null,
    salesthrecom: "",
    satype: null,
    sezinvoicetype: null,
    sgst: 0.0,
    sharewithcust: false,
    supplytype: null,
    totaladvance: 0.0,
    totalamount: 0.0,
    totalgst: 0.0,
    totalinwords: "",
    totaltaxable: 0.0,
    totaltaxableinwords: "",
    utgst: 0.0,
    year: "",
    invoiceItems: [],
  };
  return emptyInvoice;
};

export const setImportInvoice = (targetObject, invoice) => {
  if (!invoice || "" === invoice) {
    return null;
  }
  if (!targetObject) {
    targetObject = {};
  }
  // targetObject.advanceAmountUsed = invoice.advanceAmountUsed;
  // targetObject.advanceGstUsed = invoice.advanceGstUsed;
  targetObject.invoicetypeVo = invoice.invoicetypeVo;
  // targetObject.paymentTransactions = invoice.paymentTransactions;
  // targetObject.auditnotes = invoice.auditnotes;
  targetObject.bankaccount = invoice.bankaccount;
  targetObject.barcode = invoice.barcode;
  targetObject.barcodeImage = invoice.barcodeImage;
  targetObject.billtoaddress = invoice.billtoaddress;
  targetObject.shiptoaddress = invoice.shiptoaddress;
  targetObject.bu = invoice.bu;
  targetObject.cdnreason = invoice.cdnreason;
  targetObject.cess = invoice.cess;
  targetObject.cesspercent = invoice.cesspercent;
  targetObject.cgst = invoice.cgst;
  targetObject.createdon = invoice.createdon;
  targetObject.currency = invoice.currency;
  targetObject.customerVo = invoice.customerVo;
  targetObject.description = invoice.description;
  targetObject.electronicreference = invoice.electronicreference;
  targetObject.etin = invoice.etin;
  targetObject.exchangerate = invoice.exchangerate;
  targetObject.expinvoicetype = invoice.expinvoicetype;
  targetObject.freez = invoice.freez;
  targetObject.fright = invoice.fright;
  targetObject.gstdate = invoice.gstdate;
  targetObject.id = invoice.id;
  targetObject.igst = invoice.igst;
  targetObject.invnumber = invoice.invnumber;
  targetObject.invoicedate = invoice.invoicedate;
  targetObject.invoicesource = invoice.invoicesource;
  targetObject.invoiceStatusList = invoice.invoiceStatusList;
  // targetObject.isUtilized = invoice.isUtilized;
  // targetObject.isAsset = invoice.isAsset;
  targetObject.isbillingshippingsame = invoice.isbillingshippingsame;
  targetObject.isexport = invoice.isexport;
  targetObject.isimported = invoice.isimported;
  targetObject.ismanualrefinv = invoice.ismanualrefinv;
  targetObject.isnilrated = invoice.isnilrated;
  targetObject.ispregst = invoice.ispregst;
  targetObject.isrcmsale = invoice.isrcmsale;
  targetObject.isRecurring = invoice.isRecurring;
  targetObject.isroundup = invoice.isroundup;
  targetObject.issez = invoice.issez;
  targetObject.isSystemGenerated = invoice.isSystemGenerated;
  targetObject.loadingpackaging = invoice.loadingpackaging;
  targetObject.manualrefinv = invoice.manualrefinv;
  targetObject.miscellaneous = invoice.miscellaneous;
  targetObject.month = invoice.month;
  targetObject.payment = invoice.payment;
  targetObject.purchasedate = invoice.purchasedate;
  targetObject.purchaseorder = invoice.purchaseorder;
  targetObject.referencedInvoice = invoice.referencedInvoice;
  targetObject.referenceinvnumber = invoice.referenceinvnumber;
  targetObject.referencingInvoices = invoice.referencingInvoices;
  targetObject.refinvoice = invoice.refinvoice;
  targetObject.salesthrecom = invoice.salesthrecom;
  targetObject.satype = invoice.satype;
  targetObject.sezinvoicetype = invoice.sezinvoicetype;
  targetObject.sgst = invoice.sgst;
  targetObject.sharewithcust = invoice.sharewithcust;
  targetObject.supplytype = invoice.supplytype;
  targetObject.status = invoice.status;
  targetObject.totaladvance = invoice.totaladvance;
  targetObject.totalamount = invoice.totalamount;
  targetObject.totalgst = invoice.totalgst;
  targetObject.totalinwords = invoice.totalinwords;
  targetObject.totaltaxable = invoice.totaltaxable;
  targetObject.totaltaxableinwords = invoice.totaltaxableinwords;
  targetObject.utgst = invoice.utgst;
  targetObject.year = invoice.year;
  // targetObject.privileges = invoice.privileges;
  let itemArray = [];
  for (let i = 0; i < invoice.invoiceItems.length; i++) {
    let item = createInvoiceItem(invoice.invoiceItems[i]);
    itemArray.push(item);
  }
  targetObject.invoiceItems = itemArray;
  return targetObject;
};
export default {
  createInvoiceItem,
  createEmptyInvoiceItem,
  setInvoiceItem,
  createInvoice,
  setInvoice,
  setImportInvoice,
  setInvoiceToState,
  getEmptyInvoice,
};
