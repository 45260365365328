import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import React, { Component } from "react";
import { Label } from "reactstrap";
import "./SalesInvoiceNumberFormat.scss";

export class NumberFormatRow extends Component {
  suxfixTypeList = [];
  sepratorList = [];

  constructor(props) {
    super(props);

    this.suxfixTypeList = props.suxfixTypeList;
    this.sepratorList = props.sepratorList;

    let formatData = props.data;
    this.state = {
      id: null === formatData.id ? "" : formatData.id,
      prefix: null === formatData.prefix ? "" : formatData.prefix,
      prefixseprator:
        null === formatData.prefixseprator ? {} : formatData.prefixseprator,
      suffix: null === formatData.suffix ? "" : formatData.suffix,
      resetifsuffixchange:
        null === formatData.resetifsuffixchange
          ? ""
          : formatData.resetifsuffixchange,
      suffixseprator:
        null === formatData.suffixseprator ? {} : formatData.suffixseprator,
      startingnumber:
        null === formatData.startingnumber ? "" : formatData.startingnumber,
      documenttype:
        null === formatData.documenttype ? {} : formatData.documenttype,
      sufixtype: null === formatData.sufixtype ? {} : formatData.sufixtype,
      preview: "",
      action: this.props.action,
      render: true,
    };
    this.state.preview = this.getPreview();
  }

  getPreview() {
    let preview = "";
    if (this.state.prefix) {
      preview += this.state.prefix;
    }
    if (
      this.state.prefixseprator &&
      this.state.prefixseprator.type &&
      this.state.prefixseprator.type.trim() !== "NONE"
    ) {
      preview += this.state.prefixseprator.type;
    }
    if (this.state.sufixtype && this.state.sufixtype.type !== "NONE") {
      preview += this.state.sufixtype.type;
    }
    if (
      this.state.suffixseprator &&
      this.state.suffixseprator.type !== "NONE"
    ) {
      preview += this.state.suffixseprator.type;
    }
    if (this.state.startingnumber) {
      preview += this.state.startingnumber;
    }
    // this.setState({ preview: preview });
    return preview;
  }
  handlePrefixChange(e) {
    this.state.prefix = e.target.value;
    let preview = this.getPreview();
    this.setState({ prefix: e.target.value, preview: preview });
  }

  handlePrefixSeparatorChange(e) {
    this.sepratorList.forEach((item) => {
      if (e.target.value === item.id) {
        this.state.prefixseprator = item;
        let preview = this.getPreview();
        this.setState({ prefixseprator: item, preview: preview });
      }
    });
  }
  handleSufixTypeChange(e) {
    this.suxfixTypeList.forEach((item) => {
      if (e.target.value === item.id) {
        this.state.sufixtype = item;
        let preview = this.getPreview();
        this.setState({ sufixtype: item, preview: preview });
      }
    });
  }
  handleSufixSeparatorChange(e) {
    this.sepratorList.forEach((item) => {
      if (e.target.value === item.id) {
        this.state.suffixseprator = item;
        let preview = this.getPreview();
        this.setState({ suffixseprator: item, preview: preview });
      }
    });
  }

  handleStartingNumberChange(e) {
    this.state.startingnumber = e.target.value;
    let preview = this.getPreview();
    this.setState({ startingnumber: e.target.value, preview: preview });
  }

  handleResetNumberChange(e) {
    let preview = this.getPreview();
    this.setState({
      resetifsuffixchange: !this.state.resetifsuffixchange,
      preview: preview,
    });
  }

  render() {
    if (this.state.action === "edit") {
      return this.editNumberFormat();
    } else {
      return this.viewNumberFormat();
    }
  }
  editNumberFormat() {
    let separaterMenus = [];
    this.sepratorList.forEach((item) => {
      separaterMenus.push(<MenuItem value={item.id}>{item.type}</MenuItem>);
    });

    let sufixTypeMenus = [];
    this.suxfixTypeList.forEach((item) => {
      sufixTypeMenus.push(<MenuItem value={item.id}>{item.type}</MenuItem>);
    });
    return (
      <TableRow>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label>{this.state.documenttype.description}</Label>
        </TableCell>
        <TableCell style={{ margin: "0", padding: "0.3em" }}>
          <TextField
            fullWidth
            id="prefix"
            value={this.state.prefix}
            onChange={this.handlePrefixChange.bind(this)}
            //onBlur={() => {this.setPreview.bind(this)}}
            name="prefix"
            className="textField"
            InputProps={{ style: {} }}
            InputLabelProps={{ style: {} }}
          />
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Select
            labelId="prefixSeperator"
            id="prefixSeperator"
            value={
              null === this.state.prefixseprator.id
                ? ""
                : this.state.prefixseprator.id
            }
            onChange={this.handlePrefixSeparatorChange.bind(this)}
            InputProps={{ style: {} }}
            InputLabelProps={{ style: {} }}
          >
            {separaterMenus}
          </Select>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Select
            style={{}}
            labelId="sufixType"
            id="sufixType"
            value={
              null === this.state.sufixtype.id ? "" : this.state.sufixtype.id
            }
            onChange={this.handleSufixTypeChange.bind(this)}
            InputProps={{ style: {} }}
            InputLabelProps={{ style: {} }}
          >
            {sufixTypeMenus}
          </Select>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Select
            style={{}}
            labelId="sufixSeperator"
            id="sufixSeperator"
            value={
              null === this.state.suffixseprator.id
                ? ""
                : this.state.suffixseprator.id
            }
            onChange={this.handleSufixSeparatorChange.bind(this)}
            InputProps={{ style: {} }}
            InputLabelProps={{ style: {} }}
          >
            {separaterMenus}
          </Select>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <TextField
            fullWidth
            id="startNumber"
            value={this.state.startingnumber}
            onChange={this.handleStartingNumberChange.bind(this)}
            //onBlur={() => {this.setPreview.bind(this)}}
            name="startNumber"
            className="textField"
            InputProps={{ style: {} }}
            InputLabelProps={{ style: {} }}
          />
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          {this.state.resetifsuffixchange === true && (
            <ToggleButton
              className="ToggleButtonActive"
              onChange={this.handleResetNumberChange.bind(this)}
            >
              Yes
            </ToggleButton>
          )}
          {this.state.resetifsuffixchange === false && (
            <ToggleButton
              className="ToggleButtonInActive"
              onChange={this.handleResetNumberChange.bind(this)}
            >
              No
            </ToggleButton>
          )}
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label>{this.state.preview}</Label>
        </TableCell>
      </TableRow>
    );
  }
  viewNumberFormat() {
    let separaterMenus = [];
    this.sepratorList.forEach((item) => {
      separaterMenus.push(<MenuItem value={item.id}>{item.type}</MenuItem>);
    });

    let sufixTypeMenus = [];
    this.suxfixTypeList.forEach((item) => {
      sufixTypeMenus.push(<MenuItem value={item.id}>{item.type}</MenuItem>);
    });
    return (
      <TableRow>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label style={{ color: "#000" }}>
            {this.state.documenttype.description}
          </Label>
        </TableCell>
        <TableCell style={{ margin: "0", padding: "0.3em" }}>
          <Label style={{ color: "#000" }}>{this.state.prefix}</Label>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label style={{ color: "#000" }}>
            {this.state.prefixseprator.type}
          </Label>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label style={{ color: "#000" }}>{this.state.sufixtype.type}</Label>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label style={{ color: "#000" }}>
            {this.state.suffixseprator.type}
          </Label>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label style={{ color: "#000" }}>{this.state.startingnumber}</Label>
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          {this.state.resetifsuffixchange === true && (
            <ToggleButton className="ToggleButtonActive">Yes</ToggleButton>
          )}
          {this.state.resetifsuffixchange === false && (
            <ToggleButton className="ToggleButtonInActive">No</ToggleButton>
          )}
        </TableCell>
        <TableCell
          style={{
            margin: "0",
            padding: "0.3em",
            border: "0.5px solid #dedddd",
          }}
        >
          <Label>{this.state.preview}</Label>
        </TableCell>
      </TableRow>
    );
  }
}

export default NumberFormatRow;
