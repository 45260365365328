import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
export class PurchaseOrderList extends Component {
  header = store.getState().header.header;
  purchaseOrderState = store.getState().applicationState.purchaseOrderState;
  supplierList = store.getState().master.masterData.suppliers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  purchaseOrderStateDetails = {};

  breadCrumb = [
    {
      pageid: "/purchase",
      uiname: "Purchase",
      cssclass: "fa fa-cart-arrow-down",
    },
    { pageid: "/po", uiname: "Purchase Order List" },
  ];
  title = "Purchase Order List";
  constructor(props) {
    super(props);
    let AllSuppliers = {
      name: "All",
    };
    const supplierList = [AllSuppliers, ...this.supplierList];
    this.tableIcons = UiUtils.getMaterialTableIcons();
    if (this.purchaseOrderState) {
      this.purchaseOrderStateDetails = Object.getOwnPropertyNames(
        this.purchaseOrderState
      );
    }
    if (this.purchaseOrderStateDetails.length > 0) {
      this.state = {
        invoiceId: this.purchaseOrderState.invoiceId,
        pageSize: this.purchaseOrderState.pageSize,
        initialPage: this.purchaseOrderState.initialPage,
        selectedBusinessUnit: this.purchaseOrderState.selectedBusinessUnit,
        selectedSupplier: this.purchaseOrderState.selectedSupplier,
        isCapitalAsset: this.purchaseOrderState.isCapitalAsset,
        selectedPurchaseVoucherCategory: null,
        supplierList: supplierList,
        invoiceList: [],
        businessUnits: [],
        privileges: [],
        invoiceStatusList: [],
        invoiceCategoryList: [],
        startDate:null,
        endDate:null,
        render: false,
        open: false,
        isPaging: false,
        isFirstTimeLoading: false,
      };
    } else {
      this.state = {
        selectedTabIndex: 0,
        pageSize: 10,
        initialPage: 0,
        selectedBusinessUnit: this.businessUnitList[0],
        selectedSupplier: supplierList[0],
        selectedPurchaseVoucherCategory: null,
        supplierList: supplierList,
        invoiceList: [],
        businessUnits: [],
        privileges: [],
        invoiceStatusList: [],
        invoiceCategoryList: [],
        startDate:null,
        endDate:null,
        render: false,
        open: false,
        isPaging: false,
        isCapitalAsset: false,
        isFirstTimeLoading: true,
      };
    }
    this.getPurchaseOrderList(this.state.selectedBusinessUnit?.code, this.state.selectedSupplier?.id, this.state.isCapitalAsset, this.state.isFirstTimeLoading);
  }

  getPurchaseOrderList(bucode, supid, isCapitalAsset, reset) {
    const postObject = {
      header: this.header,
      bucode: bucode,
      supid: supid,
      isAsset: isCapitalAsset,
    };
    FetchServerData.callPostService("/poms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let invoiceDetails = output.data;
          let invoiceList = output.data.poList;
          let privileges = output.data.privileges;
          let invoiceStatusList = output.data.invoiceStatusList;
          // store.dispatch(appDataAction.setUsersBusinessUnits(businessUnits));
          // store.dispatch(appDataAction.setPurchaseVoucherCategories(invoiceCategoryList));
          let isPaging = false;
          if (invoiceList.length > 10) {
            isPaging = true;
          }
          for (let i = 0; i < invoiceList.length; i++) {
            invoiceList[i].invoicedate = Utils.ptsDateFormat(
              invoiceList[i].invoicedate
            );
            invoiceList[i].totaltaxable = `${invoiceList[i].currencysymbol} ${invoiceList[i].totaltaxable.toFixed(2)}`;
            invoiceList[i].totalamount = `${invoiceList[i].currencysymbol} ${invoiceList[i].totalamount.toFixed(2)}`;
          }
          this.setState({
            invoiceList: invoiceList,
            // businessUnits: businessUnits,
            // invoiceCategoryList: invoiceCategoryList,
            isFirstTimeLoading: reset ? true : false,
            selectedBusinessUnit: reset
              ? this.businessUnitList[0]
              : this.state.selectedBusinessUnit,
            selectedSupplier: reset ? this.state.supplierList[0] : this.state.selectedSupplier,
            privileges: privileges,
            isPaging: isPaging,
            isCapitalAsset: isCapitalAsset,
            invoiceStatusList: invoiceStatusList,
            startDate:invoiceDetails.startDate,
            endDate:invoiceDetails.endDate,
            open: false,
            render: true,
          });
        } else {
        }
      }
    );
  }

  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setPurchaseInvoiceTab(index));
    this.setState({ selectedTabIndex: index });
  }
  addPurchaseVoucher = (e) => {
    this.props.history.push({
      pathname: "/purchaseOrderCreate",
      state: {
        action: "create",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  toPurchaseVoucherDetails = (e, data) => {
    let purchaseOrderState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
      selectedSupplier: this.state.selectedSupplier,
      isCapitalAsset: this.state.isCapitalAsset,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ purchaseOrderState })
    );
    this.props.history.push({
      pathname: "/purchaseOrder",
      state: {
        invoiceId: data.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  getTablesWithStatus(status) {
    let filteredData = this.state.invoiceList.filter(function (e) {
      return e.status === status;
    });
    if (status === "All") {
      filteredData = this.state.invoiceList;
    }

    let actionList = [];
    // let readAction = {};
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddCircleIcon style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Purchase Voucher",
        isFreeAction: true,
        onClick: (event) => this.addPurchaseVoucher(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <Row md={12} style={{ padding: "5px" }}>
        <div style={{ width: "100%", padding: "2em" }}>
          <MaterialTable
            icons={this.tableIcons}
            localization={{
              body: {
                emptyDataSourceMessage: Utils.communizeTableText(),
              },
            }}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },
              {
                title: "Date",
                field: "invoicedate",
                type: "date",
                align: "left",
                cellStyle: {
                  width: "11%",
                },
              },
              {
                title: "Invoice Number",
                field: "invnumber",
                align: "left",
                cellStyle: {
                  width: "12%",
                },
              },
              {
                title: "Supplier",
                field: "suppliername",
                align: "left",
                cellStyle: {
                  width: "15%",
                  wordBreak: "break-word",
                },
              },
              {
                title: "Taxable Amount",
                field: "totaltaxable",
                align: "right",
                cellStyle: {
                  width: "12%",
                },
              },
              {
                title: "Invoice Amount",
                field: "totalamount",
                align: "right",
                cellStyle: {
                  width: "12%",
                },
              },
              {
                title: "Status",
                field: "statusdescription",
                align: "Left",
                cellStyle: {
                  width: "18%",
                  wordBreak: "break-word",
                },
              },
            ]}
            data={filteredData}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              pageSizeOptions: Utils.materialTablePageSize(),
              paging: this.state.isPaging,
              pageSize: this.state.pageSize,
              initialPage: this.state.initialPage,
              headerStyle: {
                backgroundColor: "#1b7189",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: {},
            }}
            style={{
              boder: "1px solid black",
            }}
            actions={actionList}
            onRowClick={(e, rowData) =>
              this.toPurchaseVoucherDetails(e, rowData)
            }
          />
        </div>
      </Row>
    );
  }
  updateBusinessUnit(e, bu) {
    this.setState({ selectedBusinessUnit: bu });
  }

  updateSupplier(e, supplier) {
    this.setState({ selectedSupplier: supplier });
  }

  handleCapitalAssetChange(e) {
    this.setState({ isCapitalAsset: e.target.checked });
  }

  updatePurchaseVoucherCategory(e, name) {
    this.setState({ selectedPurchaseVoucherCategory: name });
  }
  filterRecords(e) {
    let bucode = this.state.selectedBusinessUnit
      ? this.state.selectedBusinessUnit.code
      : "";
    let supid = this.state.selectedSupplier
      ? this.state.selectedSupplier.id
      : "";
    let isCapitalAsset = this.state.isCapitalAsset;
    this.getPurchaseOrderList(bucode, supid, isCapitalAsset);
  }
  resetFilter() {
    store.dispatch(ApplicationStateAction.setApplicationState({}));
    let bucode = "";
    let supid = "";
    let isCapitalAsset = false;
    this.getPurchaseOrderList(bucode, supid, isCapitalAsset, true);
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  cancelView() {
    this.props.history.push({ pathname: "/purchase/" });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  render() {
    if (this.state.render === false) {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
      );
    }

    // const tabList = this.state.invoiceStatusList.map((expStatus, keyIndex) => {
    //   return (
    //     <Tab key={keyIndex} onClick={(e) => this.selectedTab(expStatus, keyIndex)}>
    //       <span style={{ fontWeight: '600' }}>{expStatus.uiname}</span>
    //     </Tab>
    //   );
    // });

    // const tabPanelList = this.state.invoiceStatusList.map((tab, keyIndex) => {
    //   return <TabPanel key={keyIndex}>{this.getTablesWithStatus(tab.status)}</TabPanel>;
    // });

    // let selectedIndex = store.getState().appData.purchaseInvoiceSelectedTab;
    // if (Utils.isNull(selectedIndex)) {
    //   selectedIndex = 0;
    // }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />

        <div className="row">
          <div className="col-8 align-items-start text-start">
            <span className="ms-2">
              {this.state.selectedSupplier !== null || this.state.selectedBusinessUnit !== null
                || this.state.isCapitalAsset !== false ? (
                <span>
                  {this.state.selectedSupplier &&
                    this.state.selectedSupplier !== null ? (
                    <span>
                      <b>Supplier : </b>
                      {this.state.selectedSupplier?.name + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.selectedBusinessUnit &&
                    this.state.selectedBusinessUnit !== null ? (
                    <span>
                      <b> Business Unit : </b>
                      {this.state.selectedBusinessUnit?.name + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.isCapitalAsset &&
                    this.state.isCapitalAsset !== false ? (
                    <span>
                      <b> Is Capital Asset : </b>
                      {`${this.state.isCapitalAsset ? "Yes" : "No" }, `}
                    </span>
                  ) : (
                    ""
                  )}
                    {(this.state.startDate !== "" && this.state.startDate !== null) && (
                    <span>
                      <b>Start Date : </b>
                      {Utils.ptsDateFormat(this.state.startDate) + ", "}
                    </span>
                  )}
                  &nbsp;
                  {(this.state.endDate !== "" && this.state.endDate !== null) && (
                    <span>
                      <b>End Date : </b>
                      {Utils.ptsDateFormat(this.state.endDate) + ", "}
                    </span>
                  )}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="col-4 align-items-end text-end">
            <span
              className="text-end me-1"
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "black",
                cursor: "pointer",
              }}
              onClick={this.handleAccountDropdownOpen}
            >
              <FilterAltIcon
                style={{ fontSize: "28px", color: "black" }}
                aria-controls={this.state.open ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.open ? "true" : undefined}
              />
              Filter
            </span>
            {"  "}
            {this.state.isFirstTimeLoading ? (
              ""
            ) : (
              <span
                className="me-2"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.resetFilter.bind(this)}
              >
                &nbsp;&nbsp;<i class="fa fa-refresh" aria-hidden="true"></i> Reset
              </span>
            )}
          </div>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Filter</h5>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Supplier
                </label>
                <Autocomplete
                  fullWidth
                  ListboxProps={{ className: "myCustomList" }}
                  variant="outlined"
                  id="supplierList"
                  options={this.state.supplierList}
                  value={this.state.selectedSupplier}
                  defaultValue={this.state.selectedSupplier}
                  getOptionLabel={(option) => option.name}
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateSupplier(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Supplier"
                      className="regCountry"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-1" id="assetFormLabel">
                <label
                  className="form-label "
                  htmlFor="collapsible-supplierType"
                >
                  Business Unit
                </label>
                <Autocomplete
                  fullWidth
                  ListboxProps={{ className: "myCustomList" }}
                  variant="outlined"
                  id="businessUnit"
                  options={this.businessUnitList}
                  value={this.state.selectedBusinessUnit}
                  defaultValue={this.state.selectedBusinessUnit}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Select Business Unit"
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateBusinessUnit(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="regCountry"
                      placeholder="Business Unit"
                      variant="outlined"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-12 mt-2" id="assetFormLabel">
                <FormControlLabel
                  style={{
                    color: "#000",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                  }}
                  control={
                    <Checkbox
                      variant="outlined"
                      id="isAsset"
                      checked={this.state.isCapitalAsset}
                      value={this.state.isCapitalAsset}
                      onChange={this.handleCapitalAssetChange.bind(this)}
                      style={{ background: "none" }}
                    />
                  }
                  label={<span style={{}}>Is Capital Asset?</span>}
                />
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  // onClick={this.onFilterClick.bind(this)}
                  onClick={(e) => this.filterRecords(e)}
                >
                  <i className="fa-solid fa-filter"></i>
                  &nbsp; Filter
                </button>
                &nbsp; &nbsp;
                <button
                  className="resetButton"
                  onClick={this.resetFilter.bind(this)}
                >
                  <i className="fa fa-refresh"></i>
                  &nbsp; Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>

        <Row md={12} className="mt-4">
          <Col md={12}>
            <MaterialTable
              icons={this.tableIcons}
              title=""
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Date",
                  field: "invoicedate",
                  type: "date",
                  align: "left",
                  cellStyle: {
                    width: "11%",
                  },
                },
                {
                  title: "Number",
                  field: "invnumber",
                  align: "left",
                  cellStyle: {
                    width: "15%",
                  },
                },
                {
                  title: "Supplier",
                  field: "suppliername",
                  align: "left",
                  cellStyle: {
                    width: "20%",
                    wordBreak: "break-word",
                  },
                },
                {
                  title: "Taxable Amount",
                  align: "right",
                  field: "totaltaxable",
                  cellStyle: {
                    width: "15%",
                  },
                },
                {
                  title: "Invoice Amount",
                  field: "totalamount",
                  align: "right",
                  cellStyle: {
                    width: "15%",
                  },
                },
                {
                  title: "Status",
                  field: "statusdescription",
                  align: "Left",
                  cellStyle: {
                    width: "18%",
                    wordBreak: "break-word",
                  },
                },
              ]}
              data={this.state.invoiceList}
              options={{
                sorting: true,
                actionsColumnIndex: -1,
                pageSizeOptions: Utils.materialTablePageSize(),
                paging: this.state.isPaging,
                pageSize: this.state.pageSize,
                initialPage: this.state.initialPage,
                headerStyle: {
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                  height: "3em",
                  textTransform: "capitalize",
                }),
              }}
              style={{
                boder: "1px solid black",
              }}
              onChangePage={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              actions={[
                {
                  icon: () => (
                    <AddIconButton
                      style={{ fontSize: "larger", color: "#1b7189" }}
                    />
                  ),
                  tooltip: "Add Purchase Order",
                  isFreeAction: true,
                  onClick: (event) => this.addPurchaseVoucher(),
                },
              ]}
              onRowClick={(e, rowData) =>
                this.toPurchaseVoucherDetails(e, rowData)
              }
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default PurchaseOrderList;
