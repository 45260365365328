import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from 'react';
import { Col, Row } from "reactstrap";
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import ExpenseItem from './ExpenseItem';

export default class ExpenseDialogue extends Component {
	header = store.getState().header.header;
	tableIcons = UiUtils.getMaterialTableIcons();

	constructor(props) {
		super(props);
		this.state = {
			expense: null,
			expenseid: props.expense?.id,
			open: false,
		};
	}

	showViewInINRExpense(expenseid) {
		this.state = { open: false, expenseid: expenseid };
		if (Utils.isNullOrEmpty(expenseid)) {
			return;
		}
		const postObject = {
			expense: { id: expenseid },
			header: this.header,
		};
		FetchServerData.callPostService('/expensems/getExpenseInBaseCurrency', postObject).then((output) => {

			if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
				let expense = output.data;
				this.setState({ expense: expense, open: true });
			} else {
			}
		});
	}

	handleOpen() {
		this.setState({ open: true });
	}

	handleClose() {
		this.setState({ open: false });
	}
	handleServiceGoodsSelectChange(e) {
		this.setState({ serviceGoods: e.target.value });
	}

	handleCodeChange(e) {
		this.setState({ code: e.target.value });
	}
	hsnCodeSelected(e, selectedCode) {
		if (Utils.isNotNull(this.props.onCodeChange)) {
			this.props.onCodeChange(selectedCode);
			this.setState({ open: false });
		}
	}

	getTableHeader() {
		return (
			<TableHead>
				<TableRow style={{ background: "#ececec" }}>
					<TableCell style={{ width: "5%", border: "1px solid #d8d7d7" }}>
						<strong>#</strong>
					</TableCell>
					<TableCell style={{ width: "30%", border: "1px solid #d8d7d7" }}>
						<strong>Category</strong>
					</TableCell>
					<TableCell style={{ width: "30%", border: "1px solid #d8d7d7" }}>
						<strong>Note</strong>
					</TableCell>
					<TableCell
						style={{
							width: "30%",
							textAlign: "right",
							border: "1px solid #d8d7d7",
						}}
					>
						<strong>Amount</strong>
					</TableCell>
				</TableRow>
			</TableHead>
		);
	}

	render() {
		let expenseItemsList = [];
		this.state.expense?.expenseitems.forEach((element, index) => {
			expenseItemsList.push(
				<ExpenseItem key={index} data={element} srno={index + 1} action="view" />
			);
		});

		if (!this.state.expense) {
			return <></>;
		}
		return (
			<Dialog
				open={this.state.open}
				onClose={this.handleClose.bind(this)}
				maxWidth="xxl"
				className='salesDialogue'
			>
				<DialogTitle id="scroll-dialog-title" style={{ backgroundColor: "#1b7189" }}>
					<span style={{ color: '#fff' }}>
						Expense : {' '}{this.state.expense.expnumber}
					</span>
				</DialogTitle>
				<DialogContent>
					<div md={12} style={{ width: '100%', color: '#000', fontSize: 'small', padding: '0.5em' }}>
						<Row md={12} style={{ padding: "5px", margin: "0" }}>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Expense Date &nbsp;:</strong>&nbsp;
									{Utils.ptsDateFormat(this.state.expense?.expdate)}
								</label>
							</Col>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Created On&nbsp;:</strong>&nbsp;
									{Utils.ptsDateFormat(this.state.expense?.createdon)}
								</label>
							</Col>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Bill Number&nbsp;:</strong>&nbsp;
									{this.state.expense?.billnumber}
								</label>
							</Col>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Purpose&nbsp;:</strong>&nbsp;
									{this.state.expense?.purpose}
								</label>
							</Col>
						</Row>
						<Row md={12} style={{ padding: "5px", margin: "0" }}>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Business Unit &nbsp;:</strong>&nbsp;
									{this.state.expense?.bu.name}
								</label>
							</Col>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Is Asset &nbsp;:</strong>&nbsp;
									{null === this.state.expense?.isAsset
										? "No"
										: this.state.expense?.isAsset
											? "Yes"
											: "No"}
								</label>
							</Col>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Is Recurring &nbsp;:</strong>&nbsp;
									{null === this.state.expense?.isRecurring
										? "No"
										: this.state.expense?.isRecurring
											? "Yes"
											: "No"}
								</label>
							</Col>
							<Col md={3} style={{ alignItems: "center", color: "#000" }}>
								<label style={{ color: "#000" }}>
									<strong>Status&nbsp;:</strong>&nbsp;
									{this.state.expense?.expstatus && this.state.expense?.expstatus?.description
										? this.state.expense?.expstatus?.description
										: ""}
								</label>
							</Col>
						</Row>
						<Row md={12} style={{ padding: "5px", margin: "0" }}>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Supplier Name &nbsp;:</strong>&nbsp;
									{null === this.state.expense?.supplier
										? ""
										: this.state.expense?.supplier?.name}
								</label>
							</Col>
							<Col
								md={3}
								style={{ alignItems: "center", color: "#000" }}
							>
								<label style={{ color: "#000" }}>
									<strong>Supplier Country&nbsp;:</strong>&nbsp;
									{this.state.expense?.supplier && this.state.expense?.supplier?.country
										? this.state.expense?.supplier?.country?.name
										: ""}
								</label>
							</Col>
							<Col md={3}>
								<label style={{ color: "#000" }}>
									<strong>Currency&nbsp;:</strong>&nbsp;
									{null === this.state.expense?.currency ? "" : this.state.expense?.currency.name}
								</label>
							</Col>
							<Col
								md={3}
								style={{ alignItems: "center", color: "#000" }}
							>
								<label style={{ color: "#000" }}>
									<strong>Currency Rate&nbsp;:</strong>&nbsp;
									{this.state.expense?.exchangerate && this.state.expense?.exchangerate
										? this.state.expense?.exchangerate
										: ""}
								</label>
							</Col>
						</Row>
						<Row md={12} style={{ padding: "0px", margin: "0" }}>
							<TableContainer>
								<Table style={{ color: "black" }}>
									{this.getTableHeader()}
									<TableBody>{expenseItemsList}</TableBody>
								</Table>
							</TableContainer>
						</Row>

						<div className='row d-flex' style={{ margin: "1em 0.4em 1em 1em" }}>
							<div className='col-8'>	</div>
							<div className='col-4 align-items-end text-end'>
								<span className='me-4'>
									<strong>Total Amount</strong>
								</span>
								<span className='ms-2'>
									<strong>
										{this.state.expense?.currency !== null
											? this.state.expense?.currency.symbol
											: ""}{" "}
										{this.state.expense?.amount}
									</strong>
								</span>
							</div>
						</div>

						<div className='row align-items-end text-end' style={{ margin: "1em 0.4em 1em 1em" }}>
							<span>{this.state.expense?.amountinwords}</span>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						type="submit"
						variant="contained"
						size="small"
						style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
						onClick={this.handleClose.bind(this)}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog >
		);
	}
}
