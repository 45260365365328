import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./PrivacyPolicy.css";
import logo3 from "./../assets/images/logo3.png";
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = { render: false };
    window.scrollTo(0, 0);
  }
  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }

  contactUs() {
    this.props.history.push({
      pathname: "/home",
      state: {
        scrollTo: "contact",
      },
    });
  }

  render() {
    return (
      <>
        <div>
          <nav
            id="ptsHomeHeader"
            style={{ padding: 0 }}
            className="navbar navbar-default bootsnav navbar-fixed no-background white"
          >
            <div style={{ backgroundColor: "#054e63" }}>
              <div className="navbar-header">
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none text-end"
                  style={{ marginRight: "-5em", marginTop: "2em" }}
                  onClick={(e) => this.openMenu()}
                >
                  <i className="fa fa-bars" style={{ fontSize: "1.5em" }}></i>
                </button>{" "}
                <span
                  style={{ display: "inline-flex", margin: "1.5em 8.5em" }}
                >
                  <img
                    src={logo3}
                    className="logo"
                    alt=""
                    style={{ width: "44px" }}
                    width="auto"
                    height="auto"
                  />
                  &nbsp;&nbsp;
                  <h3
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      margin: "auto",
                    }}
                  >
                    Taxonomy Hub
                  </h3>
                </span>
                {/* </a> */}
              </div>
              <div className="collapse navbar-collapse" id="navbar-menu">
                <ul
                  className="nav navbar-nav navbar-right"
                  style={{ flexDirection: "inherit" }}
                >
                  <li>
                    <a
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={() => this.navigateToLink("home")}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => this.navigateToLink("/login")}
                      style={{ cursor: "pointer" }}
                    >
                      Login
                    </a>
                  </li>
                  {/* <li>
                      <a
                        onClick={() =>
                          this.navigateToLink("/personalDetailsReg")
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Register
                      </a>
                    </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
        {/* <section id="termsCondition" className="contact" style={{ padding: '75px 50px 20px 50px' }}> */}
        <div
          className="termsBanner"
          id="top"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <div
            className="container termsConditionsUI"
            style={{ border: "1px solid lightgray", padding: "2em" }}
          >
            <Row>
              <Col md={12}>
                <h3 style={{ color: "#000", textAlign: "center" }}>
                  <b>
                    <u>Privacy Policy</u>
                  </b>
                </h3>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "1em",
                fontWeight: "400",
                lineHeight: "28px",
              }}
            >
              <Col md={12} style={{ textAlign: "left" }}>
                <p>
                  Taxonomy Hub (“us”, “we”, or “our”) is committed to respecting the privacy rights and concerns of all users of the Service.
                  As such, we have established this Privacy Policy so that you can know our practices regarding the collection and use of
                  information about visitors to the Service. The Service is intended to provide several different services to assist you in
                  creating and maintaining an inventory of assets, including information regarding business assets. We use information that
                  we collect from you to efficiently provide the Service. By using the Service, you consent to Provider’s collection, use,
                  storage, deletion, and disclosure of information relating to you as set forth in this Privacy Policy. This Privacy Policy
                  is only applicable to the Service and not to any other website that you may be able to access from the Service which may
                  have its own data collection and use practices and policies.
                </p>
                <p>
                  <b>Information that we collect and how we use it</b>
                </p>
                <p className="m-0">
                  We may collect the following personal information from you within the Websites:
                </p>
                <ul className="policyList mb-3">
                  <li>
                    <p>
                      Contact Information, such as name, email address, mailing address, or phone number.
                    </p>
                  </li>{" "}
                  <li>
                    <p>
                      Information about your business, such as company name, company size, business type
                    </p>
                  </li>
                </ul>
                <p className="m-0">
                  We may collect the following personal information from you
                  when you use our Service(s).
                </p>{" "}
                <ul className="policyList mb-3">
                  <li>
                    <p>
                      Contact Information, such as name, email address, mailing address, IP address, geographic location, or phone number.
                    </p>
                  </li>
                  <li>
                    <p>
                      Billing Information, such as credit card number and billing address.
                    </p>
                  </li>
                  <li>
                    <p>
                      Unique Identifiers, such as username, account number or password.
                    </p>
                  </li>{" "}
                  <li>
                    <p>
                      Name and e-mail address when you provide feedback from the Service(s).
                    </p>
                  </li>
                </ul>
                <p className="m-0">
                  We may use the personal information collected from within the Websites or when you use the Service(s) to:
                </p>
                <ul className="policyList mb-3">
                  <li>
                    <p>Provide you with the Service(s).</p>
                  </li>
                  <li>
                    <p>Send you communication from the Service(s).</p>
                  </li>
                  <li>
                    <p>
                      Assess the needs of your business to determine or suggest
                      suitable products
                    </p>
                  </li>
                  <li>
                    <p>Send you requested product or service information</p>
                  </li>
                  <li>
                    <p>Respond to customer service requests</p>
                  </li>
                  <li>
                    <p>Administer your account</p>
                  </li>
                  <li>
                    <p>Respond to your questions and concerns</p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Facilitate your transactions with other users when you use
                      our Service(s).
                    </p>
                  </li>
                </ul>
                <p >
                  When you provide us with personal information about your
                  contacts, we will only use this information for the specific
                  reason for which it is provided.
                </p>
                <br />
                <h6 style={{ color: "#000" }}>
                  <b>Security</b>
                </h6>
                <p>
                  The personal information that you provide to us is stored on servers with restricted access and protected by protocols and
                  procedures designed to ensure the security of such information. In addition, we restrict access to personal information to
                  Provider employees, independent contractors and agents who need to know this information to develop, operate and maintain
                  the Service. All Provider personnel who have access to this information are trained in the maintenance and security of
                  such information. However, no server, computer or communications network or system, or data transmission over the Internet
                  can be guaranteed to be 100% secure. As a result, while we strive to protect user information, we cannot ensure or warrant
                  the security of any information you transmit to us through use of the Service, and you acknowledge and agree that you provide
                  such information and engage in such transmissions at your own risk. Once we receive a transmission from you, we will endeavour
                  to maintain its security on our systems.
                </p>
                <br />
                <h6 style={{ color: "#000" }}>
                  <b>Mobile Applications</b>
                </h6>
                <p>
                  When you download, install, and use our Mobile Applications,
                  we automatically collect information on the type of device you use,
                  operating system version, and the device identifier (or “UDID”).
                </p>
                <p>
                  We send you push notifications from time-to-time in order to
                  update you about any events or promotions that we may be running.
                  To ensure you receive proper notifications, we will need to collect
                  certain information about your device such as operating system and
                  user identification information.
                </p>
                <p>
                  We collect your location-based information for the purpose of locating a place.
                  We will only share this information with our mapping provider for the sole
                  purpose of providing you this service.
                </p>
                <p>
                  We use mobile analytics software to allow us to better understand the functionality
                  of our Mobile Application on your mobile or handheld device. This Mobile Application
                  may record information such as the events that occur within the Mobile Application,
                  aggregated usage, performance data, and location. We do not link the information we
                  store within the analytics software to any personally identifiable information you
                  submit within the Mobile Application.
                </p>
                <p>
                  This Privacy Policy may change from time to time, and we will
                  post all changes on this page.
                </p>
                <br />
                <h6 style={{ color: "#000" }}>
                  <b>Contact Us</b>
                </h6>
                <p>
                  If you have any questions about this Privacy Policy, please
                  &nbsp;
                  <b>
                    <a
                      onClick={this.contactUs.bind(this)}
                      style={{ cursor: "pointer", color: "#337AB7" }}
                    >
                      <b>CONTACT US.</b>
                    </a>
                  </b>
                </p>
              </Col>
            </Row>
          </div>
        </div>
        {/* </section> */}
        <footer style={{ background: "#000" }}>
          <div className="container termsConditionsUI">
            <div className="row">
              <div className="col-lg-6" style={{ margin: "auto" }}>
                <div
                  className="copyright"
                  style={{ fontSize: "12px", color: "#fff" }}
                >
                  © Copyright
                  <strong>
                    <span> AssetTrackifier</span>
                  </strong>
                  . All Rights Reserved
                </div>
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-3">
                <ul
                  style={{
                    float: "right",
                    listStyle: "none",
                    lineHeight: "3em",
                    margin: "0 100px 0px 0",
                  }}
                >
                  <li style={{ textAlign: "left" }}>
                    <i
                      className="fa fa-angle-right"
                      style={{ color: "#fff" }}
                    />

                    <a
                      onClick={() => this.navigateToLink("/termsConditions")}
                      style={{ color: "#fff", cursor: "pointer" }}
                    >
                      {" "}
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li style={{ textAlign: "left" }}>
                    <i
                      className="fa fa-angle-right"
                      style={{ color: "#fff" }}
                    />

                    <a
                      onClick={() => this.navigateToLink("/privacy")}
                      style={{ color: "#fff", cursor: "pointer" }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default PrivacyPolicy;
