import { CLEAR_ADMIN_USER_DATA, GET_ADMIN_USER_DATA, SET_ADMIN_USER_DATA } from './AdminAppUserDataTypes';

const getAdminUser = () => {
    return {
        type: GET_ADMIN_USER_DATA,
    };
};

const setAdminUser = (data) => {
    return {
        type: SET_ADMIN_USER_DATA,
        payload: data,
    };
};

const clearAdminUser = (data) => {
    return {
        type: CLEAR_ADMIN_USER_DATA,
        payload: data,
    };
};
const AdminAppUserDataAction = { getAdminUser, setAdminUser, clearAdminUser };
export default AdminAppUserDataAction;
