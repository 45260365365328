import MaterialTable from "material-table";
import React, { Component } from "react";
import UiUtils from "../../../provider/UiUtils";
import AddIconButton from "../buttons/AddIconButton";
import BankAccount from "./BankAccount";
import Utils from "../../../provider/Utils";

export class BankAccountList extends Component {
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    let isPaging = false;
    if (this.props.data.length > 10) {
      isPaging = true;
    }
    this.state = {
      bankAccounts: this.props.data,
      selectedAccount: "",
      onChangeSettings: this.props.onChange,
      render: true,
      action: this.props.action,
      pageSize: 10,
      isPaging: isPaging,
      initialStep: 0,
    };
  }

  addBankAccount = (e) => {
    this.setState({ action: "add_account" });
  };

  bankAccountChanged(changedAccount) {
    this.props.onChange(changedAccount);
  }

  bankAccountAdded(changedAccount) {
    this.props.onChange(changedAccount);
  }
  viewBankAccount = (e, data) => {
    this.setState({ selectedAccount: data, action: "show_account" });
  };
  render() {
    if (this.state.action === "add_account") {
      return (
        <BankAccount
          action="create"
          title="New Bank Account"
          data={null}
          bankAccounts={this.state.bankAccounts}
          onChange={this.bankAccountChanged.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    } else if (this.state.action === "show_account") {
      return (
        <BankAccount
          action="view"
          title="Bank Account Details"
          data={this.state.selectedAccount}
          bankAccounts={this.state.bankAccounts}
          onChange={this.bankAccountAdded.bind(this)}
          onChangeSettings={this.state.onChangeSettings}
        />
      );
    }
    if (this.state.action === "view") {
      return this.showList();
    }
  }

  showList() {
    let bankAccountDetails = this.state.bankAccounts;
    for (let i = 0; i < bankAccountDetails.length; i++) {
      if (
        bankAccountDetails[i].isdefault &&
        bankAccountDetails[i].isdefault === true
      ) {
        bankAccountDetails[i].status = "Yes";
      } else bankAccountDetails[i].status = "No";
    }
    return (
      <>
        <MaterialTable
          icons={this.tableIcons}
          localization={{
            body: {
              emptyDataSourceMessage: Utils.communizeTableText(),
            },
          }}
          title="Bank Account List"
          font="small"
          columns={[
            {
              title: "#",
              render: (rowData) => rowData.tableData.id + 1,
              cellStyle: {
                width: "5%",
              },
            },
            {
              title: "Account Identifier",
              field: "accountname",
              align: "left",
              width: "20%",
            },
            {
              title: "Bank Name",
              field: "bankname",
              align: "left",
              width: "20%",
            },
            {
              title: "Account Number",
              field: "accountnum",
              align: "left",
              width: "20%",
            },
            {
              title: "Account Holder",
              field: "accountholder",
              align: "left",
              width: "15%",
            },
            {
              title: "Is Default",
              field: "status",
              align: "left",
              width: "10%",
            },
          ]}
          data={this.state.bankAccounts}
          column={{
            headerStyle: {
              color: "#fff",
            },
          }}
          options={{
            sorting: true,
            paging: this.state.isPaging,
            pageSize: this.state.pageSize,
            initialPage: this.state.initialPage,
            pageSizeOptions: Utils.materialTablePageSize(),
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#1b7189",
              fontWeight: "bold",
              color: "#fff",
              align: "center",
            },
            rowStyle: {},
            searchFieldStyle: {},
          }}
          style={{}}
          actions={[
            {
              icon: () => <AddIconButton />,
              tooltip: "Add Bank Account",
              isFreeAction: true,
              onClick: (event) => this.addBankAccount(),
            },
          ]}
          onRowClick={(e, rowData) => this.viewBankAccount(e, rowData)}
        />
      </>
    );
  }
}
export default BankAccountList;
