import { TextareaAutosize } from "@material-ui/core";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import CancelButton from "../buttons/CancelButton";
import EditIconButton from "../buttons/EditIconButton";
import SaveButton from "../buttons/SaveButton";
import "./InvoiceDisclaimer.scss";
import SettingDataAction from "../../../redux/settings/SettingDataAction";

export class InvoiceDisclaimer extends Component {
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  classes = {
    root: {
      flexGrow: 1,
    },
    paper: {
      margin: "0.5em",
      padding: 20,
      height: "100%",
      textAlign: "center",
      // fontFamily: 'Roboto',
    },
  };
  constructor(props) {
    super(props);

    let invDisclamer = props.data;

    //     if(!invDisclamer){

    // return
    //     }
    this.state = {
      // id: null === invDisclamer.id  ? '' : invDisclamer.id,
      id: invDisclamer ? invDisclamer.id : "",
      invoicedisclaimer: invDisclamer ? invDisclamer.invoicedisclaimer : "",
      invoiceDisclaimerHandler: props.onChange,
      action: props.action,
      render: true,
    };
  }

  handleDisclaimerChange(e) {
    this.setState({ invoicedisclaimer: e.target.value });
  }

  updateInvoiceDisclaimerInBackend(selectedCountry) {
    const header = store.getState().header.header;
    this.setState({ render: false });
    const postObject = {
      settings: {
        id: this.state.id,
        disclaimer: {
          invoicedisclaimer: this.state.invoicedisclaimer,
        },
      },
      header: header,
    };
    FetchServerData.callPostService("/settings/updateDisclaimer", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let settingsData = output.data;
          store.dispatch(SettingDataAction.setSettings(settingsData));
          this.setState({
            id: settingsData.id,
            invoicedisclaimer: settingsData.disclaimer.invoicedisclaimer,
            action: "view",
            render: true,
          });
          if (this.state.invoiceDisclaimerHandler) {
            this.state.invoiceDisclaimerHandler(settingsData.disclaimer);
          }
        } else {
        }
      }
    );
  }

  cancel() {
    /*Go to View Page */
    this.state.render = false;
    this.setState({ action: "view", render: true });
  }

  editInvoiceDisclaimer(e) {
    this.setState({ action: "edit" });
  }
  cancelEdit() {
    /*Go to View Page */
    this.state.render = false;
    this.setState({ action: "view", render: true });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    if (this.state.action === "edit") {
      return this.updateInvoiceDisclaimer();
    } else {
      return this.viewInvoiceDisclaimer();
    }
  }

  viewInvoiceDisclaimer() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <CardHeader
          style={{ background: "#1b7189", padding: "5px", color: "#fff" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginLeft: "5px",
            }}
          >
            <Col md={6} style={{ margin: "auto" }}>
              <span>
                <strong>Sales Invoice Disclaimer</strong>
              </span>
            </Col>
            <Col md={6} style={{ marginTop: "0.5em" }}>
              <EditIconButton
                onClick={this.editInvoiceDisclaimer.bind(this)}
                caption="Edit Organization"
              ></EditIconButton>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Row className="addressRow">
            <Col md={2}>
              <label style={{ color: "#000" }}>Disclaimer</label>
            </Col>
            <Col md={6}>
              <TextareaAutosize
                disabled
                style={{ width: "100%", marginTop: "1em" }}
                inputProps={{ resize: "both", border: "0.5px solid lightgray" }}
                rowsMin={2}
                rowsMax={4}
                id="name"
              >
                {this.state.invoicedisclaimer}
              </TextareaAutosize>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  updateInvoiceDisclaimer() {
    return (
      <Card
        md={12}
        style={{
          width: "100%",
          border: "0.5px solid #1b7189",
          boxShadow: "2px 2px 2px lightgrey",
        }}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.updateInvoiceDisclaimerInBackend.bind(this)}
        >
          <CardHeader
            style={{ background: "#1b7189", padding: "18px 0px 18px 8px", color: "#fff" }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "5px",
              }}
            >
              <Col>
                <span>
                  <strong>Sales Invoice Disclaimer</strong>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="row d-flex addressRow" style={{ marginTop: "1em", flexWrap: "nowrap" }}>
              <Col md={2}>
                <label style={{ color: "#000", fontWeight: "600" }}>
                  Disclaimer
                </label>
              </Col>
              <Col md={6}>
                <TextareaAutosize
                  style={{ width: "100%" }}
                  inputProps={{
                    resize: "both",
                    border: "0.5px solid lightgray",
                  }}
                  rowsMin={2}
                  rowsMax={4}
                  onChange={this.handleDisclaimerChange.bind(this)}
                  id="name"
                >
                  {this.state.invoicedisclaimer}
                </TextareaAutosize>
              </Col>
              <Col md={4}>
                <SaveButton type="submit" />
                <CancelButton onClick={(e) => this.cancelEdit()} />
              </Col>
            </Row>
          </CardBody>
        </ValidatorForm>
      </Card >
    );
  }
}

export default InvoiceDisclaimer;
