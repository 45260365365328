import Axios from "axios";
// import  {toast} from 'react-toastify';
import PtsAlert from "../pts/components/ptsAlert/PtsAlert";
import appDataAction from "../redux/appData/appDataAction";
// import checkRequests from './CheckRequest';
//export class FetchServerData extends Component {
import headerAction from "../redux/header/headerAction";
import staticDataAction from "../redux/staticData/staticDataAction";
import store from "../redux/store";
import userMenuAction from "../redux/userMenus/userMenusAction";
import ApplicationStateAction from "../redux/applicationState/ApplicationStateAction";
import userMenusAction from "../redux/userMenus/userMenusAction";
export const serverURL = "/thubApi/2.0";

// export const showLoader = () =>
//   document.getElementById('iAmUniqueLoaderTHUB')
//     ? (document.getElementById('iAmUniqueLoaderTHUB').className = 'ptsloader')
//     : '';

// export const hideLoader = () =>
//   document.getElementById('iAmUniqueLoaderTHUB')
//     ? (document.getElementById('iAmUniqueLoaderTHUB').className = 'ptsloaderHide')
//     : '';
const showLoader = () =>
	document.getElementById("iAmUniqueModalAT")
		? (document.getElementById("iAmUniqueModalAT").style.display = "block")
		: "";
const hideLoader = () =>
	document.getElementById("iAmUniqueModalAT")
		? (document.getElementById("iAmUniqueModalAT").style.display = "none")
		: "";
export const callPostServiceForPDFData = (url, data) => {
	url = serverURL + url;
	// console.log('Service Call PDF Data :' + url + ' width data ' + JSON.stringify(data));
	hideLoader();
	showLoader();
	return new Promise(function (accept, reject) {
		Axios.post(url, data).then((response) => {
			hideLoader();
			const responseData = response.data;
			if (responseData && responseData.length > 0) {
				//PtsAlert.success(responseData.message);
				accept(responseData);
			} else {
				let errorMessage = "No Data From Server";
				PtsAlert.error("No Data Received from Server");
				reject(errorMessage);
			}
		});
	});
	hideLoader();
};
export const callPostServiceForPDFReportData = (url, data) => {
	url = serverURL + url;
	// console.log(
	// 	"Service Call PDF Data :" + url + " width data " + JSON.stringify(data)
	// );
	hideLoader();
	showLoader();
	return new Promise(function (accept, reject) {
		Axios.post(url, data).then((response) => {
			hideLoader();
			const responseData = response.data;
			if (responseData) {
				//PtsAlert.success(responseData.message);
				accept(responseData);
			} else {
				let errorMessage = "No Data From Server";
				PtsAlert.error("No Data Received from Server");
				reject(errorMessage);
			}
		});
	});
	hideLoader();
};
export const callPostFileDownload = (url, data) => {
	url = serverURL + url;
	hideLoader();
	showLoader();
	return new Promise(function (accept, reject) {
		Axios.post(url, data)
			.then((response) => {
				hideLoader();
				accept(response);
			})
			.catch((error) => {
				hideLoader();
				if (error.name !== null && error.name === "TimeoutError") {
					reject("Can't reach to server,please try again later.");
				} else if (error.status) {
					let errorMessage = "Unknown Error occurred";
					switch (error.status) {
						/*
									Unauthorized
									 */
						case 401:
							errorMessage = "You are not authenticate to call this service";
							break;
						/*
									Forbidden
								   */
						case 403:
							errorMessage =
								"You are not authorized to perform this operation or the resource is unavailable for some reason";
							break;
						/*
									internal server error
									 */
						case 500:
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						/*
									Request Timeout
									*/
						case 408:
							/*
										gatewat timeout
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 504:
							/*
									   Network connect timeout error
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 599:
							errorMessage = "Can't reach to server , please try again later.";
							break;
						/*
									 Service Unavailable
									 */
						case 503:
							errorMessage =
								"Service you are trying is currently unavailable , please try again later.";
							break;
						default:
							errorMessage = "You are not authenticate to call this service";
							break;
					}
					reject(errorMessage);
				} else {
					reject(error);
				}
			});
	});
	hideLoader();
};
export const callPostFileUpload = (url, data) => {
	url = serverURL + url;
	hideLoader();
	showLoader();
	return new Promise(function (accept, reject) {
		Axios.post(url, data)
			.then((response) => {
				hideLoader();
				const responseData = response.data;
				if (responseData.status === "SUCCESS") {
					//PtsAlert.success(responseData.message);

					// responseData.errorCode='TID_1000';
					// checkRequests.CheckRequests(responseData);
					accept(responseData);
				} else {
					// console.log(
					// 	"Service Call Failed :" +
					// 	url +
					// 	" width data " +
					// 	JSON.stringify(data) +
					// 	" Response:" +
					// 	JSON.stringify(responseData)
					// );
					let errCode = responseData.errorCode;
					responseData.message =
						null === responseData.message
							? "Null Response"
							: responseData.message;
					PtsAlert.error(responseData.message);
					if (errCode === "TID_1000") {
						try {
							localStorage.clear();
							store.clear();
						} catch (err) {
						}

						window.location = "/login";
						reject("");

						// checkRequests(responseData);
						/*
								  Navigate to Login Page
								*/
						//this.navCtr.setRoot(LoginPage);
					} else {
						accept(responseData);
					}
				}
			})
			.catch((error) => {
				hideLoader();
				if (error.name !== null && error.name === "TimeoutError") {
					reject("Can't reach to server,please try again later.");
				} else if (error.status) {
					let errorMessage = "Unknown Error occurred";
					switch (error.status) {
						/*
									Unauthorized
									 */
						case 401:
							errorMessage = "You are not authenticate to call this service";
							break;
						/*
									Forbidden
								   */
						case 403:
							errorMessage =
								"You are not authorized to perform this operation or the resource is unavailable for some reason";
							break;
						/*
									internal server error
									 */
						case 500:
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						/*
									Request Timeout
									*/
						case 408:
							/*
										gatewat timeout
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 504:
							/*
									   Network connect timeout error
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 599:
							errorMessage = "Can't reach to server , please try again later.";
							break;
						/*
									 Service Unavailable
									 */
						case 503:
							errorMessage =
								"Service you are trying is currently unavailable , please try again later.";
							break;
						default:
							errorMessage = "You are not authenticate to call this service";
							break;
					}
					reject(errorMessage);
				} else {
					reject(error);
				}
			});
	});
	hideLoader();
};

export const callPostService = (url, data) => {
	url = serverURL + url;
	// export callPostService(url, data){
	hideLoader();
	showLoader();
	return new Promise(function (accept, reject) {
		// if (!data.header || !data.header.sessionid) {
		//   PtsAlert.error("Login Session Invalid, Please login again ");

		//   window.location = "/login";
		//   reject("");
		// }
		Axios.post(url, data)
			.then((response) => {
				hideLoader();
				const responseData = response.data;
				if (responseData.status === "SUCCESS") {
					//PtsAlert.success(responseData.message);

					// responseData.errorCode='TID_1000';
					// checkRequests.CheckRequests(responseData);
					accept(responseData);
				} else {
					let errCode = responseData.errorCode;
					responseData.message =
						null === responseData.message
							? "Null Response"
							: responseData.message;
					PtsAlert.error(responseData.message);
					if (errCode === "TID_1000") {
						try {
							localStorage.clear();
							store.clear();
						} catch (err) {
						}

						window.location = "/login";
						reject("");

						// checkRequests(responseData);
						/*
								  Navigate to Login Page
								*/
						//this.navCtr.setRoot(LoginPage);
					} else {
						accept(responseData);
					}
				}
			})
			.catch((error) => {
				hideLoader();
				if (error.name !== null && error.name === "TimeoutError") {
					PtsAlert.error("Can't reach to server,please try again later.");
					reject("Can't reach to server,please try again later.");
				} else if (error.status) {
					let errorMessage =
						error.message && error.message
							? error.message
							: "Unknown Error occurred";

					switch (error.status) {
						/*
									Unauthorized
									 */
						case 401:
							errorMessage = "You are not authenticate to call this service";
							break;
						/*
									Forbidden
								   */
						case 403:
							errorMessage =
								"You are not authorized to perform this operation or the resource is unavailable for some reason";
							break;
						/*
									internal server error
									 */
						case 500:
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						/*
									Request Timeout
									*/
						case 408:
							/*
										gatewat timeout
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 504:
							/*
									   Network connect timeout error
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 599:
							errorMessage = "Can't reach to server , please try again later.";
							break;
						/*
									 Service Unavailable
									 */
						case 503:
							errorMessage =
								"Service you are trying is currently unavailable , please try again later.";
							break;
						default:
							errorMessage = "You are not authenticate to call this service";
							break;
					}
					PtsAlert.error(errorMessage);
					reject(errorMessage);
				} else {
					let errorMessage = error.message
						? error.message
						: "Unknown Error occurred";
					PtsAlert.error("Backend Api not available. Please try again later");
					reject("Backend Api not available. Please try again later");
				}
			});
	});
	hideLoader();
};

export const clearStorage = () => {
	store.dispatch(headerAction.setHeader({}));
	store.dispatch(appDataAction.setAppData({}));
	store.dispatch(staticDataAction.setStaticData({}));
	store.dispatch(userMenuAction.setUserMenus({}));
	store.dispatch(userMenusAction.setUserDetails({}));
	store.dispatch(userMenuAction.setUserTopMenus({}));
	store.dispatch(ApplicationStateAction.setApplicationState({}));
	localStorage.clear();
};
export const callPostServiceWithoutHeader = (url, data) => {
	url = serverURL + url;
	// export callPostService(url, data){
	return new Promise(function (accept, reject) {
		Axios.post(url, data)
			.then((response) => {
				const responseData = response.data;
				if (responseData.status === "SUCCESS") {
					//PtsAlert.success(responseData.message);

					// responseData.errorCode='TID_1000';
					// checkRequests.CheckRequests(responseData);
					accept(responseData);
				} else {
					let errCode = responseData.errorCode;
					responseData.message =
						null === responseData.message
							? "Null Response"
							: responseData.message;
					PtsAlert.error(responseData.message);
					if (errCode === "TID_1000") {
						try {
							localStorage.clear();
							store.clear();
						} catch (err) {
						}

						window.location = "/login";
						reject("");

						// checkRequests(responseData);
						/*
								  Navigate to Login Page
								*/
						//this.navCtr.setRoot(LoginPage);
					} else {
						accept(responseData);
					}
				}
			})
			.catch((error) => {
				if (error.name !== null && error.name === "TimeoutError") {
					reject("Can't reach to server,please try again later.");
				} else if (error.status) {
					let errorMessage = "Unknown Error occurred";
					switch (error.status) {
						/*
									Unauthorized
									 */
						case 401:
							errorMessage = "You are not authenticate to call this service";
							break;
						/*
									Forbidden
								   */
						case 403:
							errorMessage =
								"You are not authorized to perform this operation or the resource is unavailable for some reason";
							break;
						/*
									internal server error
									 */
						case 500:
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						/*
									Request Timeout
									*/
						case 408:
							/*
										gatewat timeout
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 504:
							/*
									   Network connect timeout error
									   */
							errorMessage =
								"Internal Server Error occurred , please try again later";
							break;
						case 599:
							errorMessage = "Can't reach to server , please try again later.";
							break;
						/*
									 Service Unavailable
									 */
						case 503:
							errorMessage =
								"Service you are trying is currently unavailable , please try again later.";
							break;
						default:
							errorMessage = "You are not authenticate to call this service";
							break;
					}
					reject(errorMessage);
				} else {
					reject(error);
				}
			});
	});
};
//}
export default {
	callPostFileDownload,
	callPostFileUpload,
	callPostService,
	callPostServiceForPDFData,
	callPostServiceForPDFReportData,
	callPostServiceWithoutHeader,
	clearStorage,
	hideLoader,
	showLoader,
};
