import MaterialTable from "material-table";
import React, { Component } from "react";
import { Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import AddIconButton from "../buttons/AddIconButton";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
import UserProfileAction from "../../../redux/userProfile/UserProfileAction";
import ApplicationStateAction from "../../../redux/applicationState/ApplicationStateAction";

export class UserManagement extends Component {
  header = store.getState().header.header;
  UserManagementState = store.getState().applicationState.UserManagementState;
  UserManagementStateDetails = {};
  breadCrumb = [];
  title = "User Management";
  constructor(props) {
    super(props);
    if (this.props.location && this.props.location.pathname) {
      let menu = Utils.getMenu(this.props.location.pathname);
      if (menu && menu.uiname && menu.uiname.length > 0) {
        this.title = menu.uiname;
      }
      this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    }
    this.tableIcons = UiUtils.getMaterialTableIcons();
    if (this.UserManagementState) {
      this.UserManagementStateDetails = Object.getOwnPropertyNames(
        this.UserManagementState
      );
    }
    if (this.UserManagementStateDetails.length > 0) {
      this.state = {
        productList: "",
        userDataList: [],
        userId: this.UserManagementState.userId,
        pageSize: this.UserManagementState.pageSize,
        initialPage: this.UserManagementState.initialPage,
        isPaging: false,
        render: false,
      };
    } else {
      this.state = {
        productList: "",
        userDataList: [],
        pageSize: 10,
        initialPage: 0,
        isPaging: false,
        render: false,
      };
    }
    this.getUserData();
    this.getUserProfileList();
  }

  getUserData() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/userms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let userData = output.data.appuserList;
          let isPaging = false;
          if (userData.length > 10) {
            isPaging = true;
          }
          this.setState({
            render: true,
            isPaging: isPaging,
            userDataList: userData,
          });
        } else {
        }
      }
    );
  }

  async getUserProfileList() {
    const postObject = {
      header: this.header,
    };
    await FetchServerData.callPostService(
      "/profilems/getList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let profileList = output.data.profileList;
        store.dispatch(UserProfileAction.setUserProfile(profileList));
        this.setState({
          profileList: profileList,
        });
      } else {
      }
    });
  }

  addUser = (e) => {
    this.props.history.push({
      pathname: "/usercreate",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  toUserDetails = (e, data) => {
    let userId = data.id;
    let UserManagementState = {
      userId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ UserManagementState })
    );
    //localStorage.setItem('customerId', JSON.stringify(customerId));
    this.props.history.push({
      pathname: "/userview/",
      state: { userId: userId, action: "view", breadCrumb: this.breadCrumb },
    });
  };
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name="User List"
        />
        <Row md={12}>
          <div style={{ width: "100%", marginTop: "3em" }}>
            <MaterialTable
              icons={this.tableIcons}
              onRowClick={(e, rowData) => this.toUserDetails(e, rowData)}
              localization={{
                body: {
                  emptyDataSourceMessage: Utils.communizeTableText(),
                },
              }}
              title=""
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Name",
                  render: (rowData) =>
                    rowData.firstName + " " + rowData.lastName,
                  align: "left",
                  width: "20%",
                },
                {
                  title: "Email",
                  field: "email",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "User Profile",
                  field: "profile",
                  align: "left",
                  width: "15%",
                },
                {
                  title: "Status",
                  field: "status",
                  align: "left",
                  width: "15%",
                },
              ]}
              data={this.state.userDataList}
              options={{
                sorting: true,
                paging: this.state.isPaging,
                pageSize: this.state.pageSize,
                initialPage: this.state.initialPage,
                pageSizeOptions: Utils.materialTablePageSize(),
                actionsColumnIndex: -1,
                // pageSizeOptions: [5, 10, 20, 50, 100],
                headerStyle: {
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.id === this.state.userId ? "#FEF5E7" : "",
                  height: "3em",
                  // textTransform: "capitalize",
                }),
              }}
              onChangePage={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              actions={[
                {
                  icon: () => (
                    <AddIconButton
                      style={{ fontSize: "larger", color: "#1b7189" }}
                    />
                  ),
                  tooltip: "Add User",
                  isFreeAction: true,
                  onClick: (event) => this.addUser(),
                },
              ]}
            />
          </div>
        </Row>
      </>
    );
  }
}
