// import Button from "@material-ui/core/Button";
// import InputLabel from "@material-ui/core/InputLabel";
// import NativeSelect from "@material-ui/core/NativeSelect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Menu } from "@mui/material";
import { Autocomplete } from "@mui/material";
import TextField from "@material-ui/core/TextField";
// import { ValidatorForm } from "react-material-ui-form-validator";
export class FilingHistory extends Component {
  monthList = Utils.getMonthList();
  financialsYearList = store.getState().appData.fyList;
  title = "Filing";
  breadCrumb = [
    { pageid: "/filing", uiname: "Filing", cssclass: "fa fa-files-o" },
    { pageid: "/filinghisory", uiname: "Filing Hisory" }
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    if (this.monthList) {
      this.monthList.splice(0, 0, "All");
    }

    let canRender = true;
    this.state = {
      id: "",
      month:
        this.monthList && this.monthList.length > 0 ? this.monthList[0] : "",
      financialYear:
        this.financialsYearList && this.financialsYearList.length > 0
          ? this.financialsYearList[0]
          : "",
      action: action,
      render: canRender,
      open: false,
    };
    this.getFilingDetails();
  }
  getFilingDetails = () => {
    const header = store.getState().header.header;
    const postObject = {
      fyear: this.state.financialYear,
      // month: this.state.month,
      header: header,
    };

    FetchServerData.callPostService("/publicapi/returntrack", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          this.setState({
            gstr1List: data.gstr1,
            gstr3bList: data.gstr3b,
            render: true,
            open: false,
          });
        } else {
        }
      }
    );
  };
  updateFinancialYear(selected) {
    this.setState({ financialYear: selected });
  }
  // updateMonth(e) {
  //   this.setState({ month: e.target.value });
  // }
  updateMonth(value) {
    this.setState({ month: value });
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  resetFilter() {
    if (this.monthList) {
      this.monthList.splice(0, 0, "All");
    }
    this.state.financialYear =
      this.financialsYearList && this.financialsYearList.length > 0
        ? this.financialsYearList[0]
        : "";
    // this.setState({
    //   month:
    //     this.monthList && this.monthList.length > 0 ? this.monthList[0] : null,
    //   financialYear:
    //     this.financialsYearList && this.financialsYearList.length > 0
    //       ? this.financialsYearList[0]
    //       : "",
    // });
    // store.dispatch(appDataAction.setHistorySalesSearchData({}));
    this.getFilingDetails();
  }
  render() {
    this.menuWithBreadScrumRef = React.createRef();
    let gstr1Array = [];
    if (this.state.gstr1List) {
      this.state.gstr1List.forEach((element, index) => {
        gstr1Array.push(
          <TableRow>
            <TableCell
              style={{
                borderLeft: "0.5px solid #b7b0b0",
                borderRight: "0.5px solid #b7b0b0",
                borderBottom: "0.5px solid #b7b0b0",
              }}
            >
              {element.month}
            </TableCell>
            <TableCell
              style={{
                // borderLeft: '0.5px solid #b7b0b0',
                borderRight: "0.5px solid #b7b0b0",
                borderBottom: "0.5px solid #b7b0b0",
              }}
            >
              {element.year}
            </TableCell>
            <TableCell
              style={{
                // borderLeft: '0.5px solid #b7b0b0',
                borderRight: "0.5px solid #b7b0b0",
                borderBottom: "0.5px solid #b7b0b0",
              }}
            >
              {element.filingdate}
            </TableCell>
            {element.status &&
              Utils.equalsIgnoreCase(element.status, "Filed") && (
                <TableCell
                  style={{
                    // borderLeft: '0.5px solid #b7b0b0',
                    borderRight: "0.5px solid #b7b0b0",
                    borderBottom: "0.5px solid #b7b0b0",

                    color: "green",
                  }}
                >
                  {element.status}
                </TableCell>
              )}
            {element.status &&
              !Utils.equalsIgnoreCase(element.status, "Filed") && (
                <TableCell
                  style={{
                    // borderLeft: '0.5px solid #b7b0b0',
                    borderRight: "0.5px solid #b7b0b0",
                    borderBottom: "0.5px solid #b7b0b0",

                    color: "red",
                  }}
                >
                  {element.status}
                </TableCell>
              )}
          </TableRow>
        );
      });
    }
    let gstr3BArray = [];
    if (this.state.gstr3bList) {
      this.state.gstr1List.forEach((element, index) => {
        gstr3BArray.push(
          <TableRow>
            <TableCell
              style={{
                borderLeft: "0.5px solid #b7b0b0",
                borderRight: "0.5px solid #b7b0b0",
                borderBottom: "0.5px solid #b7b0b0",
              }}
            >
              {element.month}
            </TableCell>
            <TableCell
              style={{
                // borderLeft: '0.5px solid #b7b0b0',
                borderRight: "0.5px solid #b7b0b0",
                borderBottom: "0.5px solid #b7b0b0",
              }}
            >
              {element.year}
            </TableCell>
            <TableCell
              style={{
                // borderLeft: '0.5px solid #b7b0b0',
                borderRight: "0.5px solid #b7b0b0",
                borderBottom: "0.5px solid #b7b0b0",
              }}
            >
              {element.filingdate}
            </TableCell>
            {element.status &&
              Utils.equalsIgnoreCase(element.status, "Filed") && (
                <TableCell
                  style={{
                    // borderLeft: '0.5px solid #b7b0b0',
                    borderRight: "0.5px solid #b7b0b0",
                    borderBottom: "0.5px solid #b7b0b0",

                    color: "green",
                  }}
                >
                  {element.status}
                </TableCell>
              )}
            {element.status &&
              !Utils.equalsIgnoreCase(element.status, "Filed") && (
                <TableCell
                  style={{
                    // borderLeft: '0.5px solid #b7b0b0',
                    borderRight: "0.5px solid #b7b0b0",
                    borderBottom: "0.5px solid #b7b0b0",

                    color: "red",
                  }}
                >
                  {element.status}
                </TableCell>
              )}
          </TableRow>
        );
      });
    }
    let header = (
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              background: "#fff",
              width: "25%",
              fontWeight: "600",
              borderLeft: "0.5px solid #b7b0b0",
              borderRight: "0.5px solid #b7b0b0",
              borderBottom: "0.5px solid #b7b0b0",
            }}
          >
            Month
          </TableCell>
          <TableCell
            style={{
              background: "#fff",
              width: "25%",
              fontWeight: "600",
              // borderLeft: '0.5px solid #b7b0b0',
              borderRight: "0.5px solid #b7b0b0",
              borderBottom: "0.5px solid #b7b0b0",
            }}
          >
            Year
          </TableCell>
          <TableCell
            style={{
              background: "#fff",
              width: "25%",
              fontWeight: "600",
              // borderLeft: '0.5px solid #b7b0b0',
              borderRight: "0.5px solid #b7b0b0",
              borderBottom: "0.5px solid #b7b0b0",
            }}
          >
            Filing Date
          </TableCell>
          <TableCell
            style={{
              background: "#fff",
              width: "25%",
              fontWeight: "600",
              // borderLeft: '0.5px solid #b7b0b0',
              borderRight: "0.5px solid #b7b0b0",
              borderBottom: "0.5px solid #b7b0b0",
            }}
          >
            Filing Status
          </TableCell>
        </TableRow>
      </TableHead>
    );
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name="Filing History"
        // docTitle={this.title}
        />
        <div md={12} style={{ margin: "1em" }}>
          <Card md={12} style={{ width: "100%" }}>
            {/* <Row md={12} style={{ alignItems: "flex-end", padding: "1em" }}>
              <Col md={2} style={{ margin: "5px" }}>
                <InputLabel style={{ float: "right", color: "#000" }}>
                  <strong>Financial Year</strong>
                </InputLabel>
              </Col>
              <Col md={2} style={{ margin: "5px" }}>
                <NativeSelect
                  style={{ width: "-webkit-fill-available" }}
                  id="financialYear"
                  value={this.state.financialsYear}
                  onChange={this.updateFinancialYear.bind(this)}
                >
                  {this.financialsYearList.map((e, keyIndex) => {
                    return (
                      <option key={keyIndex} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Col>
              <Col md={2} style={{ margin: "5px" }}>
                <InputLabel style={{ float: "right", color: "#000" }}>
                  <strong>Month</strong>
                </InputLabel>
              </Col>
              <Col md={2} style={{ margin: "5px" }}>
                <NativeSelect
                  style={{ width: "-webkit-fill-available" }}
                  id="month"
                  fullWidth
                  value={this.state.month}
                  onChange={this.updateMonth.bind(this)}
                >
                  {this.monthList.map((e, keyIndex) => {
                    return (
                      <option key={keyIndex} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Col>
              <Col md={2} style={{ margin: "5px" }}>
                <Button
                  variant="contained"
                  style={{ background: "#1b7189", color: "#fff" }}
                  onClick={(e) => this.getFilingDetails()}
                >
                  View
                </Button>
              </Col>
            </Row> */}
            <div
              className="row align-items-end text-end"
              style={{ padding: "0.5em" }}
            >
              <div className="col me-4">
                <span>
                  {this.state.financialYear !== null ||
                    this.state.month !== null ? (
                    <span>
                      ({" "}
                      {/* {this.state.month && this.state.month !== null ? (
                        <span>
                          <b>Month: </b>
                          {this.state.month + " , "}
                        </span>
                      ) : (
                        ""
                      )} */}
                      {this.state.financialYear &&
                        this.state.financialYear !== null ? (
                        <span>
                          <b>Financial Year : </b>
                          {this.state.financialYear}
                        </span>
                      ) : (
                        ""
                      )}
                      )
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                &nbsp;&nbsp;
                <span
                  className="text-end"
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={this.handleAccountDropdownOpen}
                >
                  <FilterAltIcon
                    style={{ fontSize: "28px", color: "black" }}
                    aria-controls={
                      this.state.open ? "dropdown-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? "true" : undefined}
                  />
                  Filter
                </span>
                {"  "} &nbsp;&nbsp;
                {/* {searchHistoryDetails.length > 0 ? ( */}
                <span
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={this.resetFilter.bind(this)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                </span>
                {/* ) : (
              ""
            )} */}
              </div>
            </div>
            <Menu
              anchorEl={this.state.anchorEl}
              id="dropdown-menu"
              className="filterDropdownMenu"
              open={this.state.open}
              onClose={this.handleAccountDropdownClose}
              // onClick={this.handleAccountDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 20,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div
                className="dropdown-item"
                style={{ cursor: "pointer", width: "400px", height: "100%" }}
              >
                {/* <ValidatorForm
                  style={{ width: "100%", color: "#000" }}
                  ref="form"
                  onSubmit={this.getFilingDetails.bind(this)}
                > */}
                <div className="row">
                  <h5 className="bold">Filter</h5>

                  <div className="row mt-2">
                    <div className="col-md-12" id="assetFormLabel">
                      <label
                        // className="form-label "
                        htmlFor="collapsible-companyName"
                        style={{ color: "#000" }}
                      >
                        <b>Financial Year</b>
                      </label>
                      <Autocomplete
                        fullWidth
                        variant="outlined"
                        id="year"
                        ListboxProps={{
                          className: "myCustomList"
                        }}
                        options={
                          null === this.financialsYearList
                            ? []
                            : this.financialsYearList
                        }
                        value={this.state.financialYear}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) => { this.updateFinancialYear(value) }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            className="regCountry"
                            variant="outlined"
                            placeholder="Select Financial Year"
                            InputLabelProps={{
                              shrink: true,

                              style: {},
                            }}
                          />
                        )}
                      />
                    </div>
                    {/* <div className="col-md-6" id="assetFormLabel">
                      <label
                        // className="form-label "
                        htmlFor="collapsible-companyName"
                        style={{ color: "#000" }}
                      >
                        <b>Month</b>
                      </label>
                      <Autocomplete
                        fullWidth
                        variant="outlined"
                        id="month"
                        options={null === this.monthList ? [] : this.monthList}
                        value={this.state.month}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) => {
                          this.updateMonth(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            className="regCountry"
                            variant="outlined"
                            placeholder="Month"
                            InputLabelProps={{
                              shrink: true,

                              style: {},
                            }}
                          />
                        )}
                      />
                    </div> */}
                  </div>

                  {this.state.error ? (
                    <lable className="danger" style={{ color: "red" }}>
                      To Date should be after From Date
                    </lable>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 mt-4">
                    <button
                      className="fileterButton"
                      // onClick={this.onFilterClick.bind(this)}
                      // onClick={(e) => this.filterRecords(e)}
                      type="submit"
                      onClick={this.getFilingDetails.bind(this)}
                    >
                      <i className="fa-solid fa-filter"></i>
                      &nbsp; Filter
                    </button>
                    &nbsp; &nbsp;
                    <button
                      className="resetButton"
                      onClick={this.resetFilter.bind(this)}
                    >
                      <i className="fa fa-refresh"></i>
                      &nbsp; Reset
                    </button>
                  </div>
                </div>{" "}
                {/* </ValidatorForm> */}
              </div>
            </Menu>
            <Row md={12} style={{ alignItems: "flex-end" }}>
              <Col md={6}>
                <Card style={{ margin: "5px" }}>
                  <Row
                    md={12}
                    style={{ border: "0.5px solid #b7b0b0", margin: 0 }}
                  >
                    <span
                      style={{
                        width: "100%",
                        color: "#fff",
                        background: "#1b7189",
                        fontWeight: "600",
                        textAlign: "center",
                        padding: "10px",
                        fontSize: "large",
                        border: "0.5px solid #b7b0b0",
                      }}
                    >
                      GSTR1
                    </span>
                  </Row>
                  <Row
                    md={12}
                    style={{ border: "0.5px solid #b7b0b0", margin: 0 }}
                  >
                    <Table>
                      {header}
                      <TableBody>{gstr1Array}</TableBody>
                    </Table>
                  </Row>
                </Card>
              </Col>
              <Col md={6}>
                <Card style={{ margin: "5px" }}>
                  <Row
                    md={12}
                    style={{ border: "0.5px solid #b7b0b0", margin: 0 }}
                  >
                    <span
                      style={{
                        width: "100%",
                        color: "#fff",
                        background: "#1b7189",
                        fontWeight: "600",
                        textAlign: "center",
                        padding: "10px",
                        fontSize: "large",
                        border: "0.5px solid #b7b0b0",
                      }}
                    >
                      GSTR3B
                    </span>
                  </Row>
                  <Row
                    md={12}
                    style={{ border: "0.5px solid #b7b0b0", margin: 0 }}
                  >
                    <Table>
                      {header}
                      <TableBody>{gstr3BArray}</TableBody>
                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
}
export default withRouter(FilingHistory);
