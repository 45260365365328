import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
export class EditIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      hideToolTip: props.hideToolTip,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          // marginRight: "0.5em",
          float: "right",
        }}
      >
        {this.props.hideToolTip ?
          <Button
            className="add"
            size="small"
            onClick={this.onClick.bind(this)}
            style={{
              background: "#867e7e",
              color: "white",
              fontSize: "x-larger",
              textTransform: "capitalize",
              cursor: "pointer",
              float: "right",
              marginRight: "1rem",
              marginBottom: "1em",
              padding: "5px 8px",
            }}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          :
          <Tooltip title="Edit">
            <Button
              className="add"
              size="small"
              onClick={this.onClick.bind(this)}
              style={{
                background: "#867e7e",
                color: "white",
                fontSize: "x-larger",
                textTransform: "capitalize",
                cursor: "pointer",
                float: "right",
                // margin: "0.2em 0.5em 0.3em 0em",
                marginRight: "1rem",
                marginBottom: "1em",
                padding: "5px 8px",
              }}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Tooltip>
        }
      </span>
    );
  }
}
export default EditIconButton;
