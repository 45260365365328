import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { Component } from "react";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";

// const styles = theme => ({
//   field: {
//     marginLeft: theme.spacing.unit,
//     marginRight: theme.spacing.unit,
//     height: '30px !important',
//   },
// });

export class RegistrationTandC extends Component {
  header = store.getState().header.header;
  salutationList = [
    { id: 1, name: "Mr" },
    { id: 2, name: "Mrs" },
    { id: 3, name: "Miss" },
  ];
  genderList = [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ];
  constructor(props) {
    super(props);
    this.state = {
      showSuccessAlert: false,
      showFailAlert: false,
      value: 0,
      errorMessage: "",
      errorMessageColor: "#000",
      checkbox: false,
    };
    // this.getStaticData();
  }
  nextPage() {
    this.props.history.push("/personalDetailsReg");
  }
  getDate() {
    var d = new Date();
    var str = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    return str;
  }
  getStaticData() {
    const postObject = {
      header: {
        appclient: "W",
        userid: "",
        sessionid: "",
        tid: null,
        bu: null,
        createdDate: this.getDate(),
        timezone: "Asia/Calcutta",
        timezoneoffset: -330,
      },
    };
    FetchServerData.callPostServiceWithoutHeader(
      "/staticms/getList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        // let staticData = output.data;
      } else {
      }
    });
  }

  handleChangeChk = (e) => {
    if (e.target.checked === "true") {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };
  render() {
    // const { classes, fieldProps } = this.props;
    return (
      <Col
        md={12}
        style={{ marginTop: "0em", backgroundColor: "#1B7189", height: "100vh" }}
      >
        <h2
          style={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#fff",
            marginTop: " 0.5em",
          }}
        >
          Taxonomy Hub - Registration
        </h2>
        {/* <span className="textHeader" style={{ color: '#1b7189 !important' }}>
          <b></b>{' '}
        </span> */}
        <Card style={{ padding: "2em", marginTop: "3em", height: "48em" }}>
          {/* <CardHeader className="textHeader">
            {' '} */}

          {/* </CardHeader> */}
          <Row>
            <Col md={12}>
              <div
                className="row card"
                style={{
                  marginLeft: "-10px",
                  overflow: "auto",
                  height: "42em",
                  background: "rgb(239, 239, 239)",
                }}
              >
                <div
                  className="col-md-12 tandc"
                  style={{ textAlign: "justify" }}
                >
                  <h3 style={{ textAlign: "center", marginTop: "1em" }}>
                    TAXONOMYHUB LICENCE TERMS AND CONDITIONS
                  </h3>
                  <h5 style={{ textAlign: "center" }}>Version 1</h5>
                  <div className="col-md-12 ">
                    <h4>Table of Contents</h4>
                    <br />
                    <table className="table">
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <h6>TAXONOMYHUB LICENCE TERMS AND CONDITIONS</h6>
                          </td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>1.</td>
                          <td>
                            <h6>CLIENT'S OBLIGATIONS; LICENSE RESTRICTIONS</h6>
                          </td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>2.</td>
                          <td>
                            <h6>TERM &amp; TERMINATION</h6>
                          </td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>3.</td>
                          <td>
                            <h6>CONFIDENTIALITY</h6>
                          </td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>4.</td>
                          <td>
                            <h6>PROPRIETARY RIGHTS</h6>
                          </td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>5.</td>
                          <td>
                            <h6>GENERAL TERMS AND CONDITIONS</h6>
                          </td>
                          <td>6</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ol className="tandc">
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        CLIENT'S OBLIGATIONS; LICENSE RESTRICTIONS
                      </span>{" "}
                      <br />
                      Client Will
                      <ol>
                        <li>
                          be responsible for its and it's Authorized Users'
                          compliance with this Agreement;
                        </li>
                        <li>
                          be solely responsible for the accuracy, quality,
                          integrity, and legality of Client Data (defined below)
                          and means relating to acquisition of the Client Data;
                        </li>
                        <li>
                          not provide, make available to, or permit other
                          individuals to use or access the Software Services,
                          except under the terms listed herein, and that Client
                          will responsible for any unauthorized activity of the
                          Software Services;
                        </li>
                        <li>
                          not sell, resell, rent, or lease the Software
                          Services;
                        </li>
                        <li>
                          not modify, translate, reverse engineer, decompile,
                          disassemble, create derivative works, or otherwise
                          attempt to derive the source code of the Software
                          Services;
                        </li>
                        <li>
                          not remove any proprietary notices or labels on the
                          Software Services;
                        </li>
                        <li>
                          not license the Software Services if Client (or any of
                          its Authorized Users) is a direct competitor of PTS or
                          its affiliated entities for the purposes of monitoring
                          the Software Service's availability, performance, or
                          functionality or for any other benchmarking or
                          competitive purposes;
                        </li>
                        <li>
                          not use the Software Services to store or transmit
                          infringing, libellous, unlawful, or tortious material
                          or to store or transmit material in violation of third
                          party privacy rights;
                        </li>
                        <li>
                          not use the Software Services to store or transmit
                          malicious code; or
                        </li>
                        <li>
                          interfere with or disrupt the integrity or performance
                          of the Software Services.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        TERM &amp; TERMINATION
                      </span>
                      <ol>
                        <h3>
                          <strong>Term.</strong>
                        </h3>
                        <li>
                          Unless terminated earlier in accordance with this
                          Section, this Agreement will begin on the Effective
                          Date and will continue until the end of the period
                          specified in the applicable quote or order form for
                          the Software Services (the 'Initial Service Term') and
                          shall be automatically renewed for additional terms of
                          the same duration as the Initial Service Term (each, a
                          'Renewal Term', collectively with the Initial Service
                          Term, the 'Term').
                        </li>
                        <br />
                        <h3>
                          <strong>Termination.</strong>
                        </h3>
                        <li>
                          Client may terminate this Agreement prior to
                          expiration of the then-current Term, payments made
                          against current term will not be return. PTS may
                          terminate this Agreement upon fifteen (15) days prior
                          written notice, or immediately if the Client becomes
                          subject to bankruptcy or any other proceeding relating
                          to insolvency, receivership, liquidation, or
                          assignment for the benefit of creditors, the Client
                          infringes or misappropriates PTS's intellectual
                          property, or breaches the License Restrictions or
                          Confidentiality provision set forth herein.
                        </li>
                        <br />
                        <h3>
                          <strong>Effect of Termination. </strong>
                        </h3>
                        <li>
                          Termination shall not relieve Client of the obligation
                          to pay any fees or other amounts accrued or payable to
                          PTS through the end of the then-current Term. Client
                          shall not receive a credit or refund for any fees or
                          payments made prior to termination. Sections 9 shall
                          survive any termination or expiration of this
                          Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        CONFIDENTIALITY
                      </span>
                      <br />
                      As used in this Agreement, "Confidential Information"
                      means any information disclosed by either party to the
                      other party, either directly or indirectly, in writing,
                      orally, or by inspection of tangible objects
                      <ol>
                        <li>
                          that the disclosing party identifies as confidential
                          or proprietary or
                        </li>
                        <li>
                          Software Services. Confidential Information may also
                          include confidential or proprietary information
                          disclosed to a disclosing party by a third party. The
                          receiving party will:
                          <ol>
                            <li>
                              hold the disclosing party's Confidential
                              Information in confidence;
                            </li>
                            <li>
                              restrict disclosure of such Confidential
                              Information to those of its employees or agents
                              with a need to know such information and who are
                              bound (i.e., as a condition to their employment or
                              agency) by obligations respecting the protection
                              of confidential information, which are
                              substantially similar to those of this Agreement
                              and which would extend to the disclosing party's
                              Confidential Information;
                            </li>
                          </ol>
                        </li>
                        <li>
                          use such Confidential Information only for the
                          purposes for which it was disclosed, unless otherwise
                          set forth herein; and
                        </li>
                        <li>
                          to the extent applicable, not modify, reverse
                          engineer, decompile, create other works from, or
                          disassemble any such Confidential Information, unless
                          expressly permitted by applicable law without the
                          possibility of contractual waiver or otherwise
                          specified in writing by the disclosing party. The
                          restrictions will not apply to Confidential
                          Information to the extent it
                          <ol>
                            <li>
                              was in the public domain at the time of
                              disclosure;
                            </li>
                            <li>
                              became publicly available after disclosure to the
                              receiving party without breach of this Agreement;
                            </li>
                            <li>
                              was lawfully received by the receiving party from
                              a third party without such restrictions;
                            </li>
                            <li>
                              was known to the receiving party, its employees or
                              agents without such restrictions prior to its
                              receipt from the disclosing party;
                            </li>
                            <li>
                              was independently developed by the receiving party
                              without breach of this Agreement;
                            </li>
                            <li>
                              was generally made available to third parties by
                              the disclosing party without such restriction; or
                            </li>
                            <li>
                              is required to be disclosed by the receiving party
                              pursuant to judicial order or other compulsion of
                              law, provided that the receiving party will
                              provide to the disclosing party prompt notice of
                              such order and comply with any protective order
                              imposed on such disclosure.
                            </li>
                            <strong>
                              The parties agree that any material breach of
                              Section 2 or this Section will cause irreparable
                              injury and that injunctive relief in a court of
                              competent jurisdiction will be appropriate to
                              prevent an initial or continuing breach of these
                              Sections in addition to any other relief to the
                              applicable party may be entitled.
                            </strong>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        PROPRIETARY RIGHTS
                      </span>
                      <br /> The Software Services are licensed, not sold. Use
                      herein of the word 'purchase' in conjunction with licenses
                      of the Software Services shall not imply a transfer of
                      ownership. Client acknowledges and agrees that, as between
                      Client and PTS, all right, title and interest, including
                      all copyright, trademark, patent, trade secret,
                      intellectual property (including but not limited to
                      algorithms and business processes) and other proprietary
                      rights, arising out of or relating to the provision of the
                      Software Services belong exclusively to PTS, other than
                      the Client Data. PTS is hereby granted a royalty-free,
                      fully-paid, worldwide, exclusive, transferable,
                      sub-licensable, irrevocable, and perpetual license to use
                      or incorporate into its products and services any
                      information, data, suggestions, enhancement requests,
                      recommendations or other feedback provided by Client or
                      its Authorized Users relating to the Software Services.
                      All rights not expressly granted under this Agreement are
                      reserved by PTS.
                    </li>
                    <br />
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        GENERAL TERMS AND CONDITIONS
                      </span>
                      <ol>
                        <li>
                          <strong>GENERAL PROVISIONS</strong>
                          <ol>
                            <li>
                              Where software TaxonomyHub is supplied to Customer
                              the terms of this Agreement shall govern access to
                              and use of the said software.
                            </li>
                            <li>
                              The Following definitions apply to this Agreement
                            </li>
                          </ol>{" "}
                          <strong>"Additional User"</strong> means a User above
                          the number of Users specified in the applicable
                          ordering document.
                          <br />
                          <strong>"Affiliates"</strong> means entities
                          controlling, controlled by or under common control
                          with Supplier. <br />
                          <strong>"Agreement"</strong> means (subject to clause
                          10.1.1) the terms and conditions set out in this
                          document ("Terms and Conditions"); the applicable
                          ordering document (together with any renewal thereof);
                          and Special Conditions (if any) agreed between
                          Supplier and Customer. In case of conflict between the
                          documents comprising this Agreement, the documents
                          comprising the Agreement shall prevail in the
                          following order of precedence: 1. Order Form; 2.
                          Special Conditions; 3. these Terms and Conditions.
                          <br /> <strong>"Charges"</strong> mean the fees
                          payable by Customer for the licensing of the
                          TaxonomyHub as specified in the applicable ordering
                          document.
                          <br /> <strong>"Customer"</strong> means entity
                          specified as the customer on the applicable ordering
                          document.
                          <br />
                          <strong>"Competitor"</strong> shall mean a third party
                          that is regularly engaged in the business of
                          developing or marketing TaxonomyHub that performs the
                          same or similar functions as one or more of the
                          modules of the TaxonomyHub licensed hereunder by
                          Customer.
                          <br /> <strong>"Control"</strong>: an entity will be
                          deemed to Control another entity if it has the power
                          to direct or cause the direction of the management or
                          policies of such entity, whether through the ownership
                          of voting securities, by contract or otherwise.
                          <br /> <strong>"Documentation"</strong>
                          means the User Guide(s) published from time to time
                          for the TaxonomyHub on the www.taxonomy.com <br />{" "}
                          <strong>"Initial Subscription Period"</strong> means
                          the period from the Start Date to the Initial Expiry
                          as specified in the applicable ordering document;
                          where the Start Date for the TaxonomyHub has for any
                          reason to be adjusted, Supplier may at its discretion
                          either a) adjust the Initial Subscription Period to
                          run from the revised Start Date or b) reduce it and
                          invoice on a pro-rata basis for the reduced period.
                          <br /> <strong>"Renewal Period"</strong> means each
                          successive 12-month period (or such other period as
                          the parties may agree) following the expiry of the
                          Initial Subscription Period.
                          <br /> <strong>"Special Conditions"</strong> means
                          individually negotiated variations, amendments and/or
                          additions to these Terms and Conditions or those of an
                          ordering document and are deemed to be included in
                          this Agreement.
                          <br /> <strong>"Start Date"</strong> in respect of
                          each TaxonomyHub licensed means the later of (a) the
                          date specified as the date on which access to the
                          TaxonomyHub is intended to start and (b) the actual
                          date on which access to that TaxonomyHub is given.
                          <br /> <strong>"Supplier"</strong>
                          means Proto Technology Solutions Registered in
                          Maharashtra. Registered office: Magarpatta City,
                          Hadapsar, Pune 411028.
                          <br />
                          <strong>"Term"</strong> in respect of each TaxonomyHub
                          licensed comprises the Initial Subscription Period and
                          any Renewal Period in relation to that TaxonomyHub.
                          <br /> <strong>"Trial Period"</strong> means a period
                          during which Customer may trial the TaxonomyHub
                          without charge (unless otherwise agreed), the length
                          of which will be notified to Customer.
                          <br /> <strong>"Updates"</strong>
                          mean any periodic TaxonomyHub releases, if any, for
                          purposes of 1) providing minor enhancements and/or
                          improvements, patches, fixes, or the like to the
                          TaxonomyHub; or 2) resolving technological issues
                          related to Customer's then-current Version of the
                          TaxonomyHub.
                          <br /> <strong>"User"</strong>
                          means personnel of Customer authorised by Supplier and
                          Customer to use the TaxonomyHub as more particularly
                          specified in the applicable ordering document.
                          <br /> <strong>"Version"</strong>
                          means a new release of the TaxonomyHub (outside a
                          point release) that includes a major revision,
                          alteration, improvement, modification, or the like, to
                          the current TaxonomyHub release.
                          <br /> <br />
                        </li>
                        <li>
                          <strong>TAXONOMYHUB LICENCE</strong>
                          <ol>
                            <li>
                              Supplier grants Customer a non-exclusive,
                              non-transferable, limited licence to use for the
                              number of Users at its licensed Site(s), the
                              TaxonomyHub (both number of Users and licensed
                              Site(s) are specified in the applicable ordering
                              document), in object code only, in Customer's
                              normal course of business (including the version
                              initially licensed together with any Updates
                              included in the subscription, but excluding any
                              new TaxonomyHub feature or substantial additional
                              functionality for which Supplier, in its sole
                              discretion, generally charges customers of the
                              TaxonomyHub additional charges) on TaxonomyHub. A
                              "Site" means all personal computers, servers or
                              minicomputers (including networked systems) with
                              the same operating system platform at a single
                              location or at different locations which are
                              connected to internet.
                            </li>
                            <li>
                              Customer may make copies of the data TaxonomyHub
                              for backup purposes only through Support services
                              provided by TaxonomyHub. Each copy made by
                              Customer must include the copyright/proprietary
                              rights notice(s) embedded in and affixed to the
                              TaxonomyHub. All other copying is prohibited
                            </li>
                            <li>
                              Other Restrictions Customer may not publish,
                              transmit, retransmit, disseminate, broadcast,
                              circulate, sell, resell, loan, lease, distribute
                              or transfer TaxonomyHub to third parties, nor
                              reverse engineer, decompile, disassemble or
                              otherwise attempt to discern the source code of
                              the components of the TaxonomyHub. Customer may
                              not use TaxonomyHub, nor allow TaxonomyHub to be
                              used, to provide data management or processing
                              services for third parties. Customer may not
                              reproduce all or any portion of the TaxonomyHub
                              (except as expressly permitted herein) or any
                              accompanying Documentation, or modify, translate
                              or
                            </li>
                            <li>
                              Other Licences: The TaxonomyHub may be used to
                              access and use various Supplier products and
                              services, ("Supplier Services"). All access to and
                              use of such Supplier Services by means of the
                              TaxonomyHub, including any charges for such access
                              and use, will be governed by the terms applicable
                              to the relevant Supplier Services.
                            </li>
                            <li>
                              Title Customer hereby acknowledges and agrees that
                              all right, title and interest in and to the
                              TaxonomyHub, the Documentation and any other
                              related materials are, and shall remain, vested
                              solely in Supplier, its Affiliates and other
                              TaxonomyHub owners, if any, and Customer shall not
                              hold itself out as having any ownership or other
                              rights with respect thereto, except as
                              specifically granted hereunder. Except as
                              expressly permitted herein, Customer agrees that
                              it shall make no use of the TaxonomyHub, the
                              Documentation or any other related materials
                              without Supplier's prior written consent. Any and
                              all goodwill associated with such rights shall
                              inure directly and exclusively to the benefit of
                              Supplier.
                            </li>
                            <li>
                              Trial Terms Save for the provisions in respect of
                              Charges, the terms of this Agreement shall apply
                              to Customer during any Trial Period. This
                              Agreement will terminate at the end of the Trial
                              Period and Customer's licence and right to use the
                              TaxonomyHub will cease at the end of the Trial
                              Period unless a further Trial Period is agreed or
                              where Customer subscribes to the TaxonomyHub from
                              the expiry date of any such Trial Period.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>SUBSCRIPTION</strong>
                          <br /> TaxonomyHub account holders may access the
                          Specified Service by any of our Subscriptions:
                          <ol>
                            <li>
                              Basic Service: Basic Services provided in
                              TaxonomyHub;
                            </li>
                            <li>
                              Premium Service: Consists of all services provided
                              by the TaxonomyHub
                            </li>
                            <li>
                              Mobile Service: via a supported mobile platform.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>THIRD PARTY APPLICATION USAGE</strong>
                          <br />
                          The TaxonomyHub Service provides a platform for third
                          party applications, websites and services to make
                          available products and services to you ("Third Party
                          Applications"), and your use of these Third-Party
                          Applications is subject to their terms of use. You
                          understand and agree that TaxonomyHub is not
                          responsible or liable for the behaviour, features, or
                          content of any Third-Party Application.
                        </li>
                        <br />
                        <li>
                          <strong>CONFIDENTIAL INFORMATION </strong>
                          <br />
                          <ol>
                            <li>
                              Supplier acknowledges that any documents, the
                              contents thereof, or other proprietary or
                              confidential materials expressly designated as
                              confidential that are provided to Supplier by
                              Customer during the Term of this Agreement
                              ("Customer Confidential Information") are valuable
                              assets of Customer. Supplier will take reasonable
                              steps to ensure that the Customer Confidential
                              Information is not used or disclosed except as
                              expressly permitted by this Agreement. Supplier
                              will not permit any unaffiliated third-party
                              access to, in any manner, the Customer
                              Confidential Information, except as provided in
                              this Agreement. Customer Confidential Information
                              shall not include information that consists of
                              ideas, concepts, know-how or techniques relating
                              to the enhancement, customisation, installation or
                              implementation of the TaxonomyHub.
                            </li>
                            <li>
                              Customer acknowledges and agrees that the
                              TaxonomyHub constitutes a valuable proprietary
                              product of Supplier and that the TaxonomyHub,
                              together with the terms of this Agreement, shall
                              be referred to as the "Supplier Confidential
                              Information." Customer will take reasonable steps
                              to ensure that the Supplier Confidential
                              Information is not used or disclosed except as
                              expressly permitted by this Agreement. Customer
                              will not permit any third-party access to, in any
                              manner, the Supplier Confidential Information ,
                              except as provided in this Agreement. Customer may
                              permit its independent contractors' access to the
                              Supplier Confidential Information to the extent
                              necessary for such contractor's provision of
                              services to Customer if such contractor executes a
                              confidentiality agreement with Customer or
                              Supplier which prohibits the contractor from using
                              or disclosing the Supplier Confidential
                              Information; provided, however, that such
                              independent contractors may not include any
                              Competitor.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>CHARGES</strong>
                          <br />
                          <ol>
                            <li>
                              The Charges payable by Customer for use of the
                              TaxonomyHub will be as specified in the applicable
                              ordering document and will cover the TaxonomyHub
                              licence, maintenance, and support services as
                              specified bellow..
                            </li>
                            <li>
                              Charges and modifications
                              <ol>
                                <li>
                                  Except in the event of early termination of
                                  this Agreement as permitted herein, Customer
                                  shall pay the Charges for the TaxonomyHub for
                                  the Initial Subscription Period and any
                                  Renewal Period (and Trial Period where so
                                  agreed) or as otherwise provided for by this
                                  clause 6.
                                </li>
                                <li>
                                  Where Customer requests access to any
                                  TaxonomyHub for an Additional User, each
                                  Additional User will be charged for at the
                                  Additional User fee rate, pro-rated, for the
                                  remainder of the Initial Subscription Period
                                  or the then-current Renewal Period (as
                                  applicable), unless otherwise specified on the
                                  ordering document.
                                </li>
                                <li>
                                  Once opted for the TaxonomyHub service and
                                  chosen the subscription, customer will have to
                                  use software for said subscription.
                                </li>
                                <li>
                                  The parties agree to enter into good faith
                                  negotiations regarding this Agreement
                                  (including the financial provisions) if either
                                  party deems there is a material change in
                                  Customer's organisational structure,
                                  including, but not limited to mergers,
                                  acquisitions, a significant increase in the
                                  number of relevant personnel at a site,
                                  divestitures or downsizing.
                                </li>
                                <li>
                                  Notification of Proposed Charges for Renewal
                                  Period: Where Supplier wishes to make no
                                  increase in the Charges and/or Additional User
                                  fees ("Proposed Charges") for a Renewal Period
                                  then unless notice of termination is served by
                                  Customer in accordance with clause 9.2.3, then
                                  the Term shall be deemed to be extended for
                                  the Renewal Period.
                                </li>
                                <li>
                                  6.2.6. Where Supplier wishes to modify the
                                  Charges for the Renewal Period ("Proposed
                                  Charges") in respect of any TaxonomyHub,
                                  Supplier will endeavour to issue Customer with
                                  a renewal notification specifying the Proposed
                                  Charges for each relevant TaxonomyHub
                                  ("Renewal Notification") not less than 1
                                  calendar month before the end of the Initial
                                  Subscription Period (or Renewal Period, if
                                  applicable).
                                </li>
                                <li>
                                  Customer is deemed to agree to extend the
                                  Agreement for the Renewal Period and to pay
                                  the Proposed Charges for each relevant
                                  software for the Renewal Period, either: (a)
                                  on the expiry of the "Objection Deadline"
                                  (being 1 calendar month after the date of the
                                  Renewal Notification, unless Supplier has
                                  prior to that date received notice in writing
                                  that Customer does not accept the Proposed
                                  Charges); or (b) after bona fide discussions
                                  as provided for by clause 6.2.9, have resulted
                                  in agreement on the Proposed Charges;
                                  whichever is the later, and thereafter these
                                  Proposed Charges shall not amount to a
                                  Detrimental Amendment for the purposes of
                                  clause 9.2.1 hereof.
                                </li>
                                <li>
                                  Where, on expiry of the Initial Subscription
                                  Period or Renewal Period (as applicable), the
                                  Objection Deadline has not elapsed, Supplier
                                  shall have the option to continue supplying
                                  each relevant TaxonomyHub on the terms of this
                                  Agreement until the Objection Deadline elapses
                                  and shall be entitled to invoice Customer for
                                  Charges incurred at the existing rates. On
                                  expiry of the Objection Deadline, unless
                                  clause 6.2.9 applies, Supplier shall be
                                  entitled to invoice for the additional amounts
                                  due in respect of the period between the start
                                  of the Renewal Period and the expiry of the
                                  Objection Deadline.
                                </li>
                                <li>
                                  Where, on expiry of the Objection Deadline,
                                  the Proposed Charges are under active bona
                                  fide discussion between the parties, Supplier
                                  shall: (a) whilst, in Supplier's reasonable
                                  opinion, such discussions are proceeding
                                  without undue delay, continue supplying each
                                  relevant TaxonomyHub on the terms of this
                                  Agreement during that period and to bill
                                  Customer for Charges incurred at the existing
                                  rates; (b) be entitled, once agreement has
                                  been reached on Proposed Charges, to invoice
                                  for the additional amounts due in respect of
                                  the period between the start of the Renewal
                                  Period and such agreement being reached; and
                                  (c) in the event that such agreement is not
                                  reached within a reasonable period, at
                                  Supplier's sole discretion have the right to
                                  require and Customer shall cease all use of
                                  the TaxonomyHub, uninstall the TaxonomyHub and
                                  confirm by email to Supplier on
                                  taxonomyhub.legals@prototsolutions.com that
                                  the said TaxonomyHub has been uninstalled.
                                </li>
                                <li>
                                  Billing and Payment: All Charges are exclusive
                                  of applicable sales, use, value added,
                                  personal property and other taxes, which are
                                  additionally payable by Customer.
                                </li>
                                <li>
                                  All Telecoms charges incurred in using any
                                  TaxonomyHub Services are the responsibility of
                                  Customer
                                </li>
                                <li>
                                  Customer will pay any undisputed invoice
                                  rendered by Supplier in full within 30 days of
                                  invoice date.
                                </li>
                                <li>
                                  If full payment is not made by the due date,
                                  except to the extent that any part non-payment
                                  relates to a bona fides disputed invoice,
                                  without prejudice to any rights or remedies
                                  otherwise available, Supplier reserves(a) the
                                  right to charge interest on the outstanding
                                  balance of all overdue sums at the rate of 3%
                                  per annum above the current base rate at India
                                  Bank or the maximum interest rate permitted by
                                  law, whichever is the greater and (b) the
                                  right to require and Customer shall cease all
                                  use of the TaxonomyHub, uninstall the
                                  TaxonomyHub and confirm by email to Supplier
                                  on taxonomyhub.legals@prototsolutions.com that
                                  the said TaxonomyHub has been uninstalled.
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>
                            DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY{" "}
                          </strong>
                          <br />
                          <ol>
                            <li>
                              Warranties
                              <ol>
                                <li>
                                  Except as specifically provided in this
                                  Agreement, the TaxonomyHub is provided "as is"
                                  without warranty of any kind, express or
                                  implied, including but not limited to
                                  warranties of performance, merchantability,
                                  fitness for a particular purpose, accuracy,
                                  omissions, completeness, correctness and
                                  delays. Customer agrees that outputs from the
                                  TaxonomyHub will not, under any circumstances,
                                  be considered legal or professional advice and
                                  are not meant to replace the experience and
                                  sound professional judgment of professional
                                  advisors in full knowledge of the
                                  circumstances and details of any matter on
                                  which advice is sought.
                                </li>
                                <li>
                                  Supplier warrants to Customer that it holds
                                  itself the necessary rights to grant the
                                  rights specified in this Agreement and that it
                                  has authority to enter into this Agreement
                                  with C ustomer.
                                </li>
                                <li>
                                  Some systems/TaxonomyHub may not be capable of
                                  functional with low network speed internet
                                  connections and Customer acknowledges (a) that
                                  it has made appropriate investigations into
                                  the necessary systems/TaxonomyHub required to
                                  support Customer's use of the relevant
                                  functionality and (b) that performance of that
                                  TaxonomyHub may vary with equipment and
                                  telecommunications links with which it is
                                  used.
                                </li>
                                <li>
                                  TaxonomyHub may be depend upon third-party
                                  systems to fulfil certain functionality,
                                  Customer acknowledges that it has made
                                  appropriate investigations into the necessary
                                  systems/TaxonomyHub required to support
                                  Customer's use of the relevant functionality.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Exclusion of liability
                              <ol>
                                <li>
                                  Neither Supplier, its Affiliates nor any
                                  licensors of the foregoing make any warranty
                                  that access to any TaxonomyHub will be
                                  uninterrupted, secure, complete or error free.
                                </li>
                                <li>
                                  Other than in respect of the warranty given in
                                  Clause 7.1.2 and 8.1 Supplier shall not be
                                  liable in contract, tort, delict or otherwise
                                  for any loss of whatsoever kind howsoever
                                  arising suffered in connection with the
                                  TaxonomyHub.
                                </li>
                                <li>
                                  Supplier shall not be liable in contract,
                                  tort, delict or otherwise for any loss of
                                  revenue, business, anticipated savings or
                                  profits, loss of goodwill or data or for any
                                  indirect or consequential loss whatsoever,
                                  howsoever arising suffered in connection with
                                  the TaxonomyHub.
                                </li>
                                <li>
                                  Without prejudice to the generality of clauses
                                  7.2.1 to 7.2.3, in no event shall Supplier,
                                  its Affiliates and/or the licensors of the
                                  foregoing be liable to Customer for any
                                  claim(s) relating in any way to: (a)
                                  Customer's inability or failure to perform
                                  legal or other research related work or to
                                  perform such legal or other research or
                                  related work properly or completely, even if
                                  assisted by Supplier, its Affiliates and/or
                                  licensors of the foregoing or any decision
                                  made or action taken by Customer in reliance
                                  on the TaxonomyHub; or (b) any lost profits
                                  (whether direct or indirect) or any
                                  consequential, exemplary, incidental, indirect
                                  or special damages relating in whole or in
                                  part to Customers' rights under this Agreement
                                  or use of or inability to use the TaxonomyHub
                                  even if Suppliers, its Affiliates and/or
                                  licensors of the foregoing have been advised
                                  of the possibility of such damages.
                                </li>
                                <li>
                                  Other than in respect of the warranty given in
                                  Clause 7.1.2 and 8.1 Supplier will have no
                                  liability whatsoever for any liability of
                                  Customer to any third party which might arise.
                                </li>
                                <li>
                                  Customer shall accept sole responsibility for
                                  and Supplier shall not be liable for the use
                                  of the TaxonomyHub by Customer, or any User
                                  and Customer shall hold Supplier harmless and
                                  fully indemnified against any claims, costs,
                                  damages, loss and liabilities arising out of
                                  any such use.
                                </li>
                                <li>
                                  Nothing in this Agreement confers or purports
                                  to confer on any third party any benefit or
                                  any right to enforce any term of this
                                  Agreement.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Limitation of Liability
                              <ol>
                                <li>
                                  Other than in respect of the warranty given in
                                  Clause 7.1.2 and 8.1, Customer's exclusive
                                  remedy and Supplier's, (its Affiliates' and/or
                                  licensors of the foregoing entire liability
                                  under this Agreement if any, for any claim(s)
                                  for damages relating to the TaxonomyHub made
                                  against them individually or jointly whether
                                  based in contract or negligence shall be
                                  limited to the aggregate amount of the Charges
                                  paid by Customer relative to the specific
                                  aspect of the TaxonomyHub which is the basis
                                  of the claim(s) during the 12 month period
                                  preceding the event giving rise to such claim.
                                </li>
                                <li>
                                  None of the terms of this Agreement shall
                                  operate to: (a) exclude or restrict liability
                                  for fraud or for death or personal injury
                                  resulting from the negligence of Supplier or
                                  its Affiliates or the appointed agents or
                                  employees of Supplier or its Affiliates whilst
                                  acting in the course of their employment; or
                                  (b) affect statutory rights where this
                                  Agreement is entered into as a consumer
                                  transaction.
                                </li>
                                <li>
                                  Except for claims relating to non-payment of
                                  the Charges or improper use of the
                                  TaxonomyHub, no claim regardless of form which
                                  in any way arises out of this Agreement may be
                                  made, nor action based upon such claim
                                  brought, by either party to this Agreement
                                  more than one year after the basis for the
                                  claim becomes known to the party desiring to
                                  assert it.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Failures Not Caused by Supplier will not be
                              responsible to the extent that the TaxonomyHub
                              fails to perform due to one or more of the
                              following: (1) the malfunction of TaxonomyHub not
                              provided by Supplier (2) the malfunction of
                              hardware, (3) Customer's negligence or fault, (4)
                              Customer's failure to follow the instructions set
                              forth in the Documentation, (5) material changes
                              in the operating environment not authorised by
                              Supplier, (6) modifications to or changes in the
                              TaxonomyHub not made or suggested by Supplier or
                              (7) Customer's failure to implement and maintain a
                              proper and adequate backup and recovery system for
                              the TaxonomyHub and associated files. If Supplier
                              discovers that a failure is caused by one of the
                              above, Supplier reserves the right to charge
                              Customer for its work in investigating such
                              failure. At Customer's request and at a fee to be
                              agreed upon, Supplier will thereafter assist
                              Customer in resolving such failure. It is
                              Customer's responsibility to develop and implement
                              a proper and adequate backup and recovery system.
                            </li>
                            <li>
                              Exclusive Remedies: The remedies in clauses 7
                              (Disclaimer of Warranties and Limitation of
                              Liability), 8 (Infringement Claims) and 9 (Term
                              and Termination) are Customer's exclusive remedies
                              and are in lieu of all other legal or equitable
                              remedies and all liabilities or obligations on the
                              part of Supplier for damages (except for death and
                              personal injury) arising out of, relating to, or
                              in connection with this Agreement, including, but
                              not limited to, the licensing, delivery,
                              installation, use or performance of the
                              TaxonomyHub or the integration of the TaxonomyHub
                              with other TaxonomyHub or hardware.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>INFRINGEMENT CLAIMS</strong>
                          <br />
                          <ol>
                            <li>
                              Supplier warrants to Customer that no TaxonomyHub
                              to which Customer has subscribed, nor its features
                              infringe any industrial or intellectual property
                              rights of any third party.
                            </li>
                            <li>
                              Customer shall promptly inform Supplier if
                              Customer becomes aware of: (a) any unauthorised
                              use of the TaxonomyHub; (b) any actual,
                              threatened, or suspected infringement of any
                              intellectual property of Supplier, its Affiliates
                              and/or licensors of the foregoing in the
                              TaxonomyHub which comes to Customer's notice; and
                              (c) any claim by any third party coming to its
                              notice that the TaxonomyHub infringes the
                              intellectual property or other rights of any other
                              person.
                            </li>
                            <li>
                              Customer shall at the request and expense of
                              Supplier do all such things as may be reasonably
                              required to assist Supplier in taking or resisting
                              proceedings in relation to any infringement or
                              claim referred to in this clause and in
                              maintaining the validity and enforceability of the
                              intellectual property of Supplier, its Affiliates
                              and/or licensors of the foregoing in the
                              TaxonomyHub..
                            </li>
                            <li>
                              In the event a claim of infringement is made
                              against Supplier or Customer with respect to the
                              TaxonomyHub, Supplier, for the purpose of settling
                              such claim, may, at its option, in respect of such
                              allegedly infringing TaxonomyHub: (i) substitute
                              fully equivalent non-infringing TaxonomyHub; or
                              (ii) modify the TaxonomyHub so that it no longer
                              infringes but remains functionally equivalent. If,
                              as a result of such claim, Customer or Supplier is
                              permanently enjoined from using the TaxonomyHub by
                              a final, non-appealable decree from a court of
                              competent jurisdiction, Supplier will take one or
                              both actions set forth in (i) and (ii) above or
                              will obtain for Customer at Supplier's expense the
                              right to continue to use the TaxonomyHub.
                            </li>
                            <li>
                              Supplier's obligations to Customer pursuant to
                              this clause 8 is contingent upon Supplier being
                              given prompt notice and control of, and detailed
                              information with regard to, any such claim, suit
                              or proceeding .Customer shall have the right to
                              participate at its own cost in the defence of any
                              such claim or action through legal counsel of its
                              choosing. Customer shall not settle any such claim
                              or action without Supplier's prior written
                              consent.
                            </li>
                            <li>
                              This clause 8 contains Supplier's entire
                              obligation and the exclusive remedies of Customer
                              with regard to any claimed infringement arising
                              out of or based upon the TaxonomyHub used by
                              Customer.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>TERM AND TERMINATION </strong>
                          <br />
                          <ol>
                            <li>
                              This Agreement will, once approved by Supplier,
                              commence on the Start Date and shall continue for
                              the Initial Subscription Period and shall then
                              renew for successive Renewal Periods until the end
                              of the final Renewal Period unless earlier
                              termination takes place in accordance with the
                              provisions set out in clause 9.
                            </li>
                            <li>
                              This Agreement may be terminated by:
                              <ol>
                                <li>
                                  Customer on written notice to Supplier after
                                  receiving notice of an amendment (as permitted
                                  under this Agreement) which is materially
                                  detrimental to Customer ("Detrimental
                                  Amendment"), which for the avoidance of doubt
                                  includes an increase in Charges and/or
                                  substantial loss of content or functionality
                                  in the TaxonomyHub to Customer's detriment and
                                  for which no reasonable substitute is
                                  provided), which notice shall not take effect
                                  until the date on which such amendment or
                                  increase comes into effect; or
                                </li>
                                <li>
                                  either party on written notice to the other
                                  if: (a) the other commits a material breach of
                                  this Agreement, provided that where the breach
                                  is capable of being remedied then the
                                  defaulting party shall have failed to remedy
                                  the same within 30 days of receiving notice
                                  specifying the breach and requiring its
                                  remedy; or (b) the other is adjudicated
                                  bankrupt, enters into liquidation or any
                                  arrangement or composition with or assignment
                                  for the benefit of its creditors or if a
                                  trustee or a receiver or administrator or
                                  administrative receiver or receiver and
                                  manager is appointed against the whole or any
                                  part of its assets or business; or
                                </li>
                                <li>
                                  Supplier, with immediate effect, if any
                                  organisation, which Supplier acting reasonably
                                  determines to be a Competitor of Supplier
                                  acquires Control of Customer.
                                </li>
                              </ol>
                            </li>
                            <li>
                              If at any time Supplier for any reason decides to
                              cease general provision of the TaxonomyHub,
                              Supplier may, on providing not less than ninety
                              (90) days' written notice to Customer, cease to
                              provide any further Maintenance Services (see
                              clause 11).
                            </li>
                            <li>
                              Upon termination for whatsoever reason, if
                              Customer has pre-paid any Charges in respect of
                              TaxonomyHub being terminated or cancelled
                              Supplier's sole liability to Customer in respect
                              of such termination shall be to refund the
                              pre-paid Charges in respect of that TaxonomyHub
                              for the period following termination to the end of
                              the Term. No such refund shall be required in
                              event of termination for Customer's breach of this
                              Agreement.
                            </li>
                            <li>
                              Expiry or termination of this Agreement shall be
                              without prejudice to the accrued rights and
                              obligations of the parties.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>GENERAL PROVISIONS </strong>
                          <br />
                          <ol>
                            <li>
                              <strong>Effect of Agreement:</strong> This
                              Agreement (including any applicable ordering
                              document) embodies the entire understanding
                              between the parties with respect to the subject
                              matter of this Agreement and supersedes any and
                              all prior understandings and agreements, oral or
                              written, relating to the subject matter.
                              Furthermore, this Agreement supersedes the terms
                              and conditions of any clickthrough agreement
                              associated with the TaxonomyHub. Such Special
                              Conditions as are agreed between Supplier and
                              Customer shall apply, subject to clauses 10.1.1
                              and 10.1.2 for the Term.
                              <ol>
                                <li>
                                  Except as otherwise provided in this
                                  Agreement, Supplier may amend the terms and
                                  conditions of this Agreement ("Amended Terms")
                                  by giving Customer at least 15 days prior
                                  written or online notice. Unless Customer is
                                  notified to the contrary by Supplier, such
                                  Amended Terms shall only apply after the
                                  expiry of any Initial Subscription Period, or
                                  after the expiry of the current Renewal Period
                                  as the case may be.
                                </li>
                                <li>
                                  Where agreed Special Conditions are affected
                                  by Amended Terms, the parties shall enter into
                                  good faith negotiations and agree amendments
                                  to the Special Conditions to reflect the
                                  party's intentions. Where agreement cannot be
                                  reached, such Amended Terms may amount to a
                                  Detrimental Amendment and clause 9.2.1 may
                                  apply.
                                </li>
                                <li>
                                  Any other amendment must be in writing and
                                  signed by both parties.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Force Majeure Supplier shall not be liable for any
                              delay or failure in performing hereunder if caused
                              by factors beyond its reasonable control, such as
                              acts of God, acts of any government, war or other
                              hostility, civil disorder, the elements, fire,
                              explosion, power failure, equipment failure,
                              failure of telecommunications or Internet
                              services, industrial or labour dispute, inability
                              to obtain necessary supplies and the like.
                            </li>
                            <li>
                              Notices Except as otherwise provided, all notices
                              and correspondence must be given in writing to
                              Supplier at: 105, Silver Chambers, Vaiduwadi,
                              Hadapsar, Pune 411028 or such other addresses as
                              may from time to time be notified to Customer in
                              writing; and to Customer at the address set out in
                              the applicable ordering document unless otherwise
                              notified to Supplier in writing.
                            </li>
                            <li>
                              <strong>Governing Law and Assignment:</strong>{" "}
                              This Agreement and all matters arising out of it
                              shall, unless otherwise specified on the
                              applicable ordering document or by Supplier in
                              writing, in all respects be governed by the laws
                              of Indian Government and shall be subject to the
                              non-exclusive jurisdiction of the India courts.
                              However nothing in this clause shall exclude or
                              limit applicable mandatory local law relating to
                              Customer. Supplier may, upon written notice to
                              Customer, assign or transfer this Agreement or any
                              rights and obligations hereunder either to an
                              Affiliate or to a third party successor to all or
                              substantially all of the business, stock or assets
                              of Supplier's legal information business, in each
                              case, without the prior consent of Customer.
                              Supplier may without the prior written consent of
                              Customer and without notice assign any benefit or
                              transfer, delegate or sub-contract any of their
                              duties and obligations under this Agreement to any
                              third party, provided that in the case of
                              sub-contracting, Supplier shall remain responsible
                              for the performance by its sub-contractors of such
                              obligations under the Agreement. Neither this
                              Agreement nor any part or portion may be assigned,
                              sublicensed or otherwise transferred by Customer
                              without Supplier's prior written consent. Should
                              any provision of this Agreement be held to be
                              void, invalid, unenforceable or illegal by a
                              court, the validity and enforceability of the
                              other provisions will not be affected thereby.
                              Failure of any party to enforce any provision of
                              this Agreement will not constitute or be construed
                              as a waiver of such provision or of the right to
                              enforce such provision. The headings and captions
                              contained in this Agreement are inserted for
                              convenience only and do not constitute a part of
                              this Agreement.
                            </li>
                            <li>
                              Export Laws Customer agrees to comply with all
                              relevant export laws and regulations of the India
                              and other countries (collectively, "Export Laws")
                              to ensure that no TaxonomyHub or any portion of it
                              is exported, directly or indirectly, in violation
                              of Export Laws, and that no access to the
                              specified services is given by Customer to any
                              embargoed country or their nationals, or any other
                              embargoed/denied persons listed from time to time
                              by the India or other counties.
                            </li>
                            <li>
                              Survival Clauses 5 (Confidential Information), 7
                              (Disclaimer of Warranties and Limitation of
                              Liability), and 10 (General Provisions) shall
                              survive any termination or expiry of this
                              Agreement.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>MAINTENANCE TERMS </strong>
                          <br />
                          <ol>
                            <li>
                              Maintenance &amp; Support Services Maintenance
                              &amp; Support Services consist of the following:
                              <ol>
                                <li>
                                  <strong>Updates:</strong> Supplier may provide
                                  Updates to and/or new Versions for the
                                  TaxonomyHub to Customer which shall be
                                  included in the Charges. Customer will be
                                  responsible for installing any such Updates
                                  and/or new Versions. However, Supplier will
                                  provide technical support for only the most
                                  current Version and the immediately preceding
                                  Version of the TaxonomyHub.
                                </li>
                                <li>
                                  <strong>Telephone Support:</strong> Supplier
                                  will provide telephone support for purposes of
                                  handling Customer questions relating to the
                                  operation of the TaxonomyHub. Telephone
                                  support is provided by Supplier to Customer.
                                  For more details on Training and Support see
                                  www.taxonomyhub.com.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Supplier's Obligations
                              <ol>
                                <li>
                                  Supplier's obligations hereunder will extend
                                  only to (a) the Updates and Versions of the
                                  TaxonomyHub provided to Customer by Supplier;
                                  and (b) TaxonomyHub that has not been modified
                                  or altered in any way by anyone other than
                                  Supplier.
                                </li>
                                <li>
                                  Maintenance Services will not include services
                                  for the items for which Supplier is not
                                  responsible set forth in clause 7.4 of this
                                  Agreement.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Customer Obligations.
                              <ol>
                                <li>
                                  <strong>Cooperation:</strong> Customer shall
                                  ensure that Supplier's personnel are provided
                                  with such information under Customer's control
                                  as is reasonably necessary to enable Supplier
                                  to comply with its obligations hereunder.
                                </li>
                                <li>
                                  <strong>Updates and New Versions:</strong> In
                                  the event that Supplier determines that any of
                                  Customer's reported maintenance problems
                                  cannot be resolved due to Customer's failure
                                  to install Updates or procure new Versions of
                                  the TaxonomyHub, Customer will be given a
                                  reasonable opportunity to install such Updates
                                  or procure a new Version. If, after such
                                  opportunity, Customer fails or otherwise
                                  refuses to install such Updates or procure
                                  such new Version, Supplier shall be relieved
                                  of its obligations under this clause 11
                                </li>
                              </ol>
                            </li>
                            <li>
                              Remedies: Customer's sole remedy for Supplier's
                              material breach of its obligations under this
                              clause 11 will be to have Supplier re-perform the
                              defective services so that they conform to the
                              specifications provided herein.
                            </li>
                            <strong>
                              INSTALLATION AND TRAINING SERVICES.{" "}
                            </strong>
                            <li>
                              Installation of all hardware and supporting system
                              required for TaxonomyHub is the responsibility of
                              Customer.
                            </li>
                            <li>
                              Training is offered via a range of methods
                              including webex, face to face and telephone
                              sessions. Customer should contact Supplier to
                              arrange mutually agreeable methods, dates and
                              times.
                            </li>
                            <li>
                              Remedies: Customer's sole remedy for Supplier's
                              material breach of its obligations under this
                              clause 12 will be to have Supplier re-perform the
                              defective services so that they conform to the
                              specifications provided herein.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          <strong>STATUS CHECK </strong>
                          <br /> The following clauses apply to Status Check
                          TaxonomyHub only: <br /> Upon expiry or termination of
                          this Agreement (whether for a Trial or paid
                          subscription), Customer's licence and right to use the
                          Status Check TaxonomyHub or any part thereof shall end
                          immediately and Customer must uninstall/disable the
                          TaxonomyHub and confirm by email to Supplier on
                          taxonomyhub. legal@prototsolutions.com that the said
                          TaxonomyHub has been uninstalled/disabled.
                          <ol>
                            <li>
                              Where Customer subscribes to the TaxonomyHub from
                              the expiry date of any Trial Period, subject to
                              the execution of a new Order Form, Customer shall
                              not be required to uninstall the TaxonomyHub as
                              set out in clause 13.1 above.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "black" }}
                    checked={this.state.checkbox}
                    onChange={this.handleChangeChk}
                  />
                }
                label="I accept the terms and condition"
                style={{ color: "green" }}
              />
            </Col>
            <Col md={6}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={!this.state.checkbox}
                style={{
                  float: "right",
                  background: "#1b7189",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                startIcon={<ArrowForwardIcon />}
                onClick={this.nextPage.bind(this)}
              >
                Next
              </Button>
            </Col>
            {/* <div style={{ width: '100%', textAlign: 'center', marginBottom: ' 1em' }}></div> */}
          </Row>
          {/* <div style={{ width: '100%', textAlign: 'right', marginBottom: ' 1em' }}></div> */}
        </Card>
      </Col>
    );
  }
}

export default RegistrationTandC;
