import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import CancelButton from "../../buttons/CancelButton";
import CreateButton from "../../buttons/CreateButton";
import SaveButton from "../../buttons/SaveButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";

export class GstUser extends Component {
  filingFrequencyList = store.getState().appData.filingFrequencyList;
  signatureFrequencyList = store.getState().appData.signatureFrequencyList;
  title = "Gst User";
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    let userId = props.userId;
    let action = props.action;

    if (
      !userId &&
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      userId = props.history.location.state.userId;
      action = props.history.location.state.action;
      this.breadCrumb = props.history.location.state.breadCrumb;
    }

    let canRender = false;
    if (userId && userId > 0) {
      this.getUserDetails(userId);
    } else {
      canRender = true;
    }
    this.state = {
      id: userId,
      username: "",
      filingFreq: null,
      signatureType: this.signatureFrequencyList[0],
      gstin: "",
      action: action,
      render: canRender,
    };
  }

  getUserDetails = (userId) => {
    // const header = store.getState().header.header;
    const postObject = {
      credential: {
        id: userId,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1cread/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let userData = output.data;
          this.setState({
            id: userData.id,
            username: userData.username,
            signatureType: userData.signature.signaturetype,
            filingFreq: userData.filefrequency,
            gstin: userData.gstin,
            render: true,
          });
        } else {
        }
        this.getMenuIconsAsPerStatusAndPermission();
      }
    );
  };

  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  handleFilingFreqChange(selectedType) {
    if (null === selectedType || selectedType === "") {
      return;
    }
    this.setState({
      filingFreq: selectedType,
    });
  }

  handleSignatureTypeChange(selectedType) {
    // if(null === selectedType || selectedType === ''){
    //     return;
    // }
    this.setState({
      signatureType: selectedType,
    });
  }

  createUserInBackend = () => {
    // let header = JSON.parse(localStorage.getItem('header'));
    // const header = store.getState().header.header;
    const postObject = {
      credential: {
        username: this.state.username,
        filefrequency: {
          frequency: this.state.filingFreq.frequency,
        },
        signature: {
          signaturetype: {
            type: this.state.signatureType.type,
          },
        },
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1cread/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.setAppData();
          // let userData = output.data;
          this.props.history.push({
            pathname: "/gstr1usermgmt",
            //state: { userId: userData.id,action:'view' },
          });
        } else {
        }
      }
    );
  };

  updateUserInBackend = () => {
    // let header = JSON.parse(localStorage.getItem('header'));
    // const header = store.getState().header.header;
    const postObject = {
      credential: {
        id: this.state.id,
        username: this.state.username,
        filefrequency: {
          frequency: this.state.filingFreq.frequency,
        },
        signature: {
          signaturetype: {
            type: this.state.signatureType.type,
          },
        },
      },
      header: this.header,
    };
    FetchServerData.callPostService("/gstr1cread/update", postObject).then(
      (output) => {
        this.setAppData();
        if (output.status === "SUCCESS") {
          let userData = output.data;
          this.props.history.push({
            pathname: "/gstr1usermgmt",
            state: { userId: userData.id, action: "view" },
          });
        } else {
        }
      }
    );
  };

  setAppData() {
    // return new Promise(function (accept, reject) {

    FetchServerData.callPostServiceWithoutHeader("/dashboardms/getList", {
      header: this.header,
    })
      .then((output) => {
        if (output.status === "SUCCESS") {
          store.dispatch(appDataAction.setAppData(output.data));
          // accept(1);
        } else {
          // reject(output.message);
        }
      })
      .catch((error) => {
        // reject('Cannot Fetch App Data From Server, Error:' + error.message);
      });
    // });
  }
  deleteUserInBackend = () => {
    // let header = JSON.parse(localStorage.getItem('header'));
    // const header = store.getState().header.header;
    const postObject = {
      credential: {
        id: this.state.id,
      },
      header: this.header,
    };

    FetchServerData.callPostService("/gstr1cread/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.props.history.push({
            pathname: "/gstr1usermgmt",
            state: { action: "view", breadCrumb: this.breadCrumb },
          });
        } else {
        }
      }
    );
  };

  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = ["EDIT", "DELETE"];

    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    } else {
      buttonList = ["EDIT", "DELETE"];
    }

    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      let printCallback = null;

      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        printCallback: printCallback,
      });
    }
  }
  printButtonClicked() {
    // this.props.history.push({
    //   pathname: '/creditNotePrint',
    //   state: {
    //     invoice: this.state,
    //     action: 'view',
    //     customerList: this.customerList,
    //     productList: this.productList,
    //     businessUnitList: this.businessUnitList,
    //     custHsnCodeList: this.custHsnCodeList,
    //     breadCrumb: this.breadCrumb,
    //     isHistory: this.state.isHistory,
    //   },
    // });
  }
  editButtonClicked() {
    // this.props.history.push({
    //   pathname: '/creditNoteEdit',
    //   state: {
    //     invoiceId: this.state.id,
    //     action: 'edit',
    //     customerList: this.customerList,
    //     productList: this.productList,
    //     businessUnitList: this.businessUnitList,
    //     custHsnCodeList: this.custHsnCodeList,
    //     breadCrumb: this.breadCrumb,
    //     isHistory: this.state.isHistory,
    //   },
    // });
    this.props.history.push({
      pathname: "/gstr1useredit/",
      state: {
        userId: this.state.id,
        action: "edit",
        breadCrumb: this.breadCrumb,
      },
    });
  }
  deleteButtonClicked() {
    this.deleteUserInBackend();
  }

  cancelEdit(e) {
    this.props.history.push({
      pathname: "/gstr1userview/",
      state: {
        userId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
    // this.setState({ action: 'view' });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/gstr1usermgmt/",
      state: {
        userId: this.state.id,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.menuWithBreadScrumRef = React.createRef();
    if (this.state.action === "create") {
      return this.createUser();
    } else if (this.state.action === "edit") {
      return this.editUser();
    } else {
      return this.viewUser();
    }
  }

  viewUser() {
    return (
      <div style={{ display: "grid" }}>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"View " + this.title}
          history={this.props.history}
          name="View GST User"
        // docTitle={this.title}
        />
        <Card
          md={12}
          style={{ width: "100%", color: "#000", padding: "0.5em" }}
        >
          {/* <CardHeader
            className="cardHeaderStyle"
            // style={{ borderBottom: "1px solid lightgrey" }}
          >
            <label className="cardHeaderTitle">
              <strong>GST User Details</strong>
            </label>
          </CardHeader> */}
          <CardHeader style={{ padding: "1em" }}>
            <label className="cardHeaderTitle">
              {" "}
              <strong>GST User Details </strong>
            </label>
          </CardHeader>
          <CardBody style={{ padding: "0em 1em" }}>
            {/* <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
              <label
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#000",
                  width: "40%",

                  textAlign: "left",
                }}
              >
                <strong>User Name</strong>
              </label>
              <label
                style={{
                  width: "60%",
                  color: "#000",

                  margin: "0",
                  padding: "0",
                  textAlign: "left",
                }}
              >
                {this.state.username}
              </label>
            </Row> */}
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>User Name</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.username}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>GSTN</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.gstin}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Filing Frequency</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.filingFreq
                      ? this.state.filingFreq.frequency
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label style={{ color: "#000" }}>
                      <strong>Signature Type</strong>
                    </label>
                  </Col>
                  <Col md={8} style={{ color: "#000" }}>
                    {this.state.signatureType
                      ? this.state.signatureType.type
                      : ""}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
              <label
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#000",
                  width: "40%",

                  textAlign: "left",
                }}
              >
                <strong>GSTN</strong>
              </label>
              <label
                style={{
                  width: "60%",
                  color: "#000",

                  margin: "0",
                  padding: "0",
                  textAlign: "left",
                }}
              >
                {this.state.gstin}
              </label>
            </Row> */}
            {/* <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
              <label
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#000",
                  width: "40%",

                  textAlign: "left",
                }}
              >
                <strong>Filing Frequency</strong>
              </label>
              <label
                style={{
                  width: "60%",
                  color: "#000",

                  margin: "0",
                  padding: "0",
                  textAlign: "left",
                }}
              >
                {this.state.filingFreq ? this.state.filingFreq.frequency : ""}
              </label>
            </Row> */}
            {/* <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
              <label
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#000",
                  width: "40%",

                  textAlign: "left",
                }}
              >
                <strong>Signature Type</strong>
              </label>
              <label
                style={{
                  width: "60%",
                  color: "#000",

                  margin: "0",
                  padding: "0",
                  textAlign: "left",
                }}
              >
                {this.state.signatureType ? this.state.signatureType.type : ""}
              </label>
            </Row> */}
          </CardBody>
        </Card>

        <CardFooter style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.cancelView(e)}
            >
              Back
            </Button>
          </div>
        </CardFooter>
      </div>
    );
  }

  editUser() {
    this.menuWithBreadScrumRef = React.createRef();
    return (
      <div>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Edit " + this.title}
          history={this.props.history}
          name="Edit GST User"
        />

        <ValidatorForm
          style={{ width: "100%", color: "#000", marginTop: "1em" }}
          ref="form"
          onSubmit={this.updateUserInBackend.bind(this)}
        >
          <Card>
            <CardBody>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Username<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    id="Username"
                    type="text"
                    placeholder="Username"
                    onChange={this.handleUsernameChange.bind(this)}
                    name="Username"
                    autoComplete="Username"
                    className="textField"
                    value={this.state.username}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                {/* </Row>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}> */}
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    GSTR1 Filing Frequency<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    fullWidth
                    id="filingFreq"
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    options={
                      null === this.filingFrequencyList
                        ? []
                        : this.filingFrequencyList
                    }
                    value={this.state.filingFreq}
                    getOptionLabel={(option) => option.frequency}
                    onChange={(event, value) => {
                      this.handleFilingFreqChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        placeholder="GSTR1 Filing Frequency"
                        InputLabelProps={{ shrink: true }}
                        className="regCountry"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Signature Type<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    fullWidth
                    id="signatureType"
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    options={this.signatureFrequencyList}
                    value={this.state.signatureType}
                    getOptionLabel={(option) => option.type}
                    onChange={(event, value) => {
                      this.handleSignatureTypeChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Signature Type"
                        className="regCountry"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CardFooter style={{ width: "100%" }}>
            <Row md={12}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <SaveButton type="submit" />
                <CancelButton onClick={(e) => this.cancelEdit()} />
              </div>
            </Row>
          </CardFooter>
        </ValidatorForm>
      </div>
    );
  }
  createUser() {
    this.menuWithBreadScrumRef = React.createRef();
    return (
      <div>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb={"Create " + this.title}
          history={this.props.history}
          name="Create GST User"
        />

        <ValidatorForm
          style={{ width: "100%", color: "#000", marginTop: "1em" }}
          ref="form"
          onSubmit={this.createUserInBackend.bind(this)}
        >
          <Card>
            <CardBody>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Username<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    id="Username"
                    type="text"
                    placeholder="Username"
                    onChange={this.handleUsernameChange.bind(this)}
                    name="Username"
                    autoComplete="Username"
                    className="textField"
                    value={this.state.username}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                {/* </Row>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}> */}
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    GSTR1 Filing Frequency<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    fullWidth
                    id="filingFreq"
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    options={
                      null === this.filingFrequencyList
                        ? []
                        : this.filingFrequencyList
                    }
                    value={this.state.filingFreq}
                    getOptionLabel={(option) => option.frequency}
                    onChange={(event, value) => {
                      this.handleFilingFreqChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        placeholder="GSTR1 Filing Frequency"
                        className="regCountry"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ margin: 0, padding: 0, width: "100%" }}>
                <Col md={6} style={{ padding: "5px" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Signature Type<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    fullWidth
                    id="signatureType"
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    options={this.signatureFrequencyList}
                    value={this.state.signatureType}
                    getOptionLabel={(option) => option.type}
                    onChange={(event, value) => {
                      this.handleSignatureTypeChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Signature Type"
                        InputLabelProps={{ shrink: true }}
                        className="regCountry"
                      />
                    )}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CardFooter style={{ width: "100%" }}>
            <Row md={12}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <CreateButton type="submit" />
                <CancelButton onClick={(e) => this.cancelView()} />
              </div>
            </Row>
          </CardFooter>
        </ValidatorForm>
      </div>
    );
  }
}
export default withRouter(GstUser);
