import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
// import TextField from "@material-ui/core/TextField";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
// import Visibility from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import supplierImportFile from "../../../../assets/importTemplate/Supplier_Import.xlsx";
export class ImportSupplierList extends Component {
  fileUploadRef = React.createRef();
  breadCrumb = [
    { pageid: "/import", uiname: "Import", cssclass: "fa fa-upload" },
    { pageid: "/suppImport", uiname: "Supplier Import List" },
  ];
  title = "Supplier Import List";
  constructor(props) {
    super(props);
    // if (this.props.location && this.props.location.pathname) {
    //   let menu = Utils.getMenu(this.props.location.pathname);
    //   if (menu && menu.uiname && menu.uiname.length > 0) {
    //     this.title = menu.uiname;
    //   }
    //   this.breadCrumb = Utils.getMenuHierarchy(this.props.location.pathname);
    // }
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      selectedFile: { name: "" },
      showAddSupplier: false,
      supplierListArray: [],
      selectedItems: [],
      isPaging: false,
      initialPage: 0,
      pageSize: 10,
      isSelectAll: false,
    };
    this.getSupplierData();
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = (e) => {
    // Create an object of formData
    // e.prventDefault();

    if (!this.state.selectedFile.name || this.state.selectedFile.name === "") {
      PtsAlert.error("Please select file to upload");
      return;
    }
    let header = store.getState().header.header;
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("sid", header.sessionid);
    formData.append("tid", header.tid);
    formData.append("uid", header.email);
    formData.append("appclient", header.appclient);
    // Details of the uploaded file
    // Request made to the backend api
    // Send formData object
    // Axios.post('/bulkimport/supplier', formData);
    FetchServerData.callPostFileUpload("/bulkimport/supplier", formData).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.fileUploadRef.current.value = "";

          this.getSupplierData();
        }
      }
    );
  };

  getSupplierData() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/supimpexp/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let SupplierList = output.data.supplierList;
          let isPaging = false;
          if (SupplierList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            isPaging: isPaging,
            supplierListArray: SupplierList,
          });
        }
      }
    );
  }

  addSupplier = (e) => {
    this.props.history.push({
      pathname: "/importSupplier",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  viewSupplier = (e, data) => {
    let supplierId = data.id;
    this.props.history.push({
      pathname: "/importSupplierView",
      state: {
        supplierId: supplierId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  selectAllSuppliers = (e) => {
    let selectedItems = this.state.supplierListArray;
    for (let i = 0; i < this.state.supplierListArray.length; i++) {
      selectedItems[i].isSelected = e.target.checked;
    }
    this.setState({
      supplierListArray: selectedItems,
      isSelectAll: e.target.checked,
    });
  };

  rowItemSelected(suppid, e) {
    // let selectedObj = {};
    // let selectedItems = [];
    // for (let i = 0; i < suppid.length; i++) {
    //   selectedObj = suppid[i].id;
    //   selectedItems.push(selectedObj);

    //   this.setState({
    //     supplierListArray: selectedItems,
    //   });
    // }
    let selectedItems = this.state.supplierListArray;
    for (let i = 0; i < selectedItems.length; i++) {
      if (suppid === selectedItems[i].id) {
        if (e.target.checked) {
          selectedItems[i].isSelected = true;
        } else {
          selectedItems[i].isSelected = false;
        }
      }
    }
    this.setState({ supplierListArray: selectedItems });
  }

  bukImportSupplier(e) {
    let importSupplierList = [];
    for (let i = 0; i < this.state.supplierListArray.length; i++) {
      if (this.state.supplierListArray[i].tableData?.checked === true) {
        importSupplierList.push(this.state.supplierListArray[i].id);
      }
    }
    if (!importSupplierList.length > 0) {
      return PtsAlert.error("Please Select Supplier");
    }
    let header = store.getState().header.header;
    const postObject = {
      header: header,
      supids: importSupplierList,
    };
    FetchServerData.callPostService("/supimpexp/bulkImp", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ selectedItems: [], isSelectAll: false });
          this.getSupplierData();
        }
      }
    );
  }

  bulkOverrideSupplier(e) {
    let importSupplierList = [];
    for (let i = 0; i < this.state.supplierListArray.length; i++) {
      if (this.state.supplierListArray[i].tableData?.checked === true) {
        importSupplierList.push(this.state.supplierListArray[i].id);
      }
    }
    if (!importSupplierList.length > 0) {
      return PtsAlert.error("Please Select Supplier");
    }
    let header = store.getState().header.header;
    const postObject = {
      header: header,
      supids: importSupplierList,
    };
    FetchServerData.callPostService("/supimpexp/bulkoverride", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ selectedItems: [], isSelectAll: false });
          this.getSupplierData();
        }
      }
    );
  }

  bulkDeleteSupplier(e) {
    let importSupplierList = [];
    for (let i = 0; i < this.state.supplierListArray.length; i++) {
      if (this.state.supplierListArray[i].tableData?.checked === true) {
        importSupplierList.push(this.state.supplierListArray[i].id);
      }
    }
    if (!importSupplierList.length > 0) {
      return PtsAlert.error("Please Select Supplier");
    }
    let header = store.getState().header.header;
    const postObject = {
      header: header,
      supplierids: importSupplierList,
    };
    FetchServerData.callPostService("/supimpexp/bulkDelete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ selectedItems: [], isSelectAll: false });
          this.getSupplierData();
        }
      }
    );
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/import/",
      state: {},
    });
  }
  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        {/* <div
          style={{
            display: "grid",
            backgroundColor: "#d6dde6",
            width: "100%",
            marginTop: "1em",
            padding: "1em",
          }}
        > */}
        <Row md={12} style={{ padding: "1em" }}>
          <ValidatorForm
            style={{ width: "100%" }}
            ref="form"
            onSubmit={this.onFileUpload.bind(this)}
          >
            <Card md={12} style={{ width: "100%", padding: "1em" }}>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={4}>
                  <label style={{}}>
                    Choose File To Import (
                    <span style={{ color: "red" }}>Excel file only</span>)
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ float: "right" }}>
                    <a
                      href={supplierImportFile}
                      id="dwnlink"
                      download="Supplier_Import.xlsx"
                      // class="pull-right"
                    >
                      <strong>Download Sample Excel Format</strong>
                    </a>
                  </label>
                </Col>
              </Row>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={12}>
                  <div
                    style={{
                      border: "1px solid gray",
                      padding: "1em",
                      marginTop: "0.5em",
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      ref={this.fileUploadRef}
                      onChange={this.onFileChange.bind(this)}
                      required
                    />
                    {/* <TextField
                        required
                        type="file"
                        id="file"
                        label="Select File to Upload"
                        ref={this.fileUploadRef}
                        onChange={this.onFileChange.bind(this)}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: "small !important" },
                        }}
                      /> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<SaveIcon />}
                    // onClick={(e) => this.onFileUpload()}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </Card>
          </ValidatorForm>
        </Row>
        <Row md={12} style={{ padding: "5px" }}>
          <div style={{ width: "100%", padding: "1em" }}>
            <MaterialTable
              icons={this.tableIcons}
              title="Imported Supplier List"
              onRowClick={(event, rowData) => {
                this.viewSupplier(event, rowData);
                event.stopPropagation();
              }}
              columns={[
                // {
                //   title: " ",
                //   render: (rowData) => {
                //     // //console.log('Is Selected: ' + rowData.id + ':' + rowData.isSelected);
                //     return (
                //       <Checkbox
                //         checked={
                //           undefined === rowData.isSelected ||
                //           null === rowData.isSelected ||
                //           false === rowData.isSelected
                //             ? false
                //             : rowData.isSelected
                //         }
                //         color="primary"
                //         inputProps={{
                //           "aria-label": "checkbox with default color",
                //         }}
                //         id={rowData.tableData.id}
                //         onClick={(e) => this.rowItemSelected(e, rowData.id)}
                //       ></Checkbox>
                //     );
                //   },
                //   width: "5%",
                // },
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Name",
                  field: "name",
                  align: "left",
                  width: "20%",
                },
                {
                  title: "GSTN",
                  field: "gstn",
                  align: "left",
                  width: "15%",
                },
                {
                  title: "TradeName",
                  field: "tradeName",
                  align: "left",
                  width: "15%",
                },
                {
                  title: "Address",
                  field: "address",
                  align: "left",
                  width: "20%",
                },
              ]}
              data={this.state.supplierListArray}
              onSelectionChange={(rows) => {
                this.rowItemSelected(rows);
              }}
              options={{
                selection: true,
                showTextRowsSelected: false,
                sorting: true,
                pageSize: this.state.pageSize,
                paging: this.state.isPaging,
                pageSizeOptions: Utils.materialTablePageSize(),
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: (rowData) => {
                  if (
                    undefined !== rowData.isexistinmain &&
                    rowData.isexistinmain &&
                    rowData.isexistinmain === true
                  ) {
                    return { backgroundColor: "#ecc0a0" };
                  }
                },
              }}
              style={{
                boder: "1px solid black",
              }}
              actions={
                [
                  // {
                  //   icon: () => (
                  //     <Checkbox
                  //       checked={this.state.isSelectAll}
                  //       color="primary"
                  //       inputProps={{
                  //         "aria-label": "checkbox with default color",
                  //       }}
                  //     ></Checkbox>
                  //   ),
                  //   tooltip: "SelectAll",
                  //   isFreeAction: true,
                  //   onClick: (event) => this.selectAllSuppliers(event),
                  // },
                  // {
                  //   icon: () => <Visibility />,
                  //   tooltip: "View Customer",
                  //   onClick: (e, rowData) => this.viewSupplier(e, rowData),
                  // },
                ]
              }
              // onRowClick={(e, rowData) => this.viewSupplier(e, rowData)}
            />
            {/* </CardBody>
          </Card> */}
          </div>
        </Row>
        <Row md={12}>
          <Col md={12} style={{ textAlign: "center", alignItems: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bukImportSupplier(e)}
            >
              Bulk Import
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bulkOverrideSupplier(e)}
            >
              Bulk Override
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<DeleteIcon />}
              onClick={(e) => this.bulkDeleteSupplier(e)}
            >
              Bulk Delete
            </Button>
          </Col>
        </Row>
        {/* </div> */}
      </>
    );
  }
}
export default ImportSupplierList;
