import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { Component } from "react";
import PtsConfirmDialogue from "../ptsAlert/PtsConfirmDialogue";
import Button from "@material-ui/core/Button";
export class DeleteIconButton extends Component {
  confirmDialogueRef = React.createRef();
  constructor(props) {
    let domainObject = props.domainObject ? props.domainObject : "";
    super(props);
    this.state = {
      domainObject: domainObject,
      title: props.title ? props.title : "Delete Confirmation",
      text: props.text
        ? props.text
        : "Are you sure about deleting " +
        domainObject +
        ", It cannot be recovered once deleted?",
      onClick: props.onClick,
      caption: props.caption,
      hideToolTip: props.hideToolTip,
    };
  }

  deleteConfirmed() {
    if (this.confirmDialogueRef && this.confirmDialogueRef.current) {
      this.confirmDialogueRef.current.setState({ open: false });
    }
    if (this.state.onClick) {
      this.state.onClick();
    }
  }

  deleteNotConfirmed() {
  }
  onClick() {
    if (
      this.confirmDialogueRef &&
      this.confirmDialogueRef.current &&
      this.confirmDialogueRef.current.state.open === false
    ) {
      this.confirmDialogueRef.current.handleClickOpen();
    }
  }
  render() {
    this.confirmDialogueRef = React.createRef();
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          marginRight: "0.5em",
          float: "right",
        }}
      >
        <PtsConfirmDialogue
          ref={this.confirmDialogueRef}
          title={this.state.title}
          text={this.state.text}
          open={false}
          confirmCallback={this.deleteConfirmed.bind(this)}
          notConfirmCallback={this.deleteNotConfirmed.bind(this)}
        />
        {this.state.hideToolTip ?
          <Button
            className="add"
            size="small"
            onClick={this.onClick.bind(this)}
            style={{
              background: "#867e7e",
              color: "white",
              textTransform: "capitalize",
              cursor: "pointer",
              width: "80px",
              float: "right",
              // marginRight: "1rem",
              marginBottom: "1em",
              padding: "5px 8px",
            }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          :
          <Tooltip title="Delete">
            <Button
              className="add"
              size="small"
              onClick={this.onClick.bind(this)}
              style={{
                background: "#867e7e",
                color: "white",
                textTransform: "capitalize",
                cursor: "pointer",
                width: "80px",
                float: "right",
                // marginRight: "1rem",
                marginBottom: "1em",
                padding: "5px 8px",
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Tooltip>
        }
      </span>
    );
  }
}
export default DeleteIconButton;
