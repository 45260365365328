import MaterialTable from "material-table";
import React, { Component } from "react";
import { Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import AddIconButton from "../../buttons/AddIconButton";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";

export class SupplierList extends Component {
  supplierMasterState = store.getState().applicationState.supplierMasterState;
  supplierMasterStateDetails = {};
  breadCrumb = [
    { pageid: "/master", uiname: "Master", cssclass: "fa fa-book" },
    { pageid: "/supp", uiname: "Supplier Master List" },
  ];
  title = "Supplier Master List";

  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();

    if (this.supplierMasterState) {
      this.supplierMasterStateDetails = Object.getOwnPropertyNames(
        this.supplierMasterState
      );
    }
    if (this.supplierMasterStateDetails.length > 0) {
      this.state = {
        supplierList: [],
        privileges: [],
        invoiceId: this.supplierMasterState.invoiceId,
        pageSize: this.supplierMasterState.pageSize,
        initialPage: this.supplierMasterState.initialPage,
        isPaging: false,
        render: false,
      };
    } else {
      this.state = {
        supplierList: [],
        privileges: [],
        pageSize: 10,
        initialStep: 0,
        isPaging: false,
        render: false,
      };
    }
    this.getSupplierData();
  }

  getSupplierData() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/supplierms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplierList = output.data.supplierList;
          let privileges = output.data.privileges;
          let isPaging = false;
          if (supplierList.length > 10) {
            isPaging = true;
          }
          this.setState({
            render: true,
            isPaging: isPaging,
            supplierList: supplierList,
            privileges: privileges,
          });
        }
      }
    );
  }

  addSupplier = (e) => {
    this.props.history.push({
      pathname: "/supplier",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  toSupplierDetails = (e, data) => {
    let supplierId = data.id;
    let supplierMasterState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ supplierMasterState })
    );
    this.props.history.push({
      pathname: "/supplierview",
      state: {
        supplierId: supplierId,
        action: "view",
        breadCrumb: this.breadCrumb,
      },
    });
  };

  cancelView() {
    this.props.history.push({ pathname: "/master/" });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    let actionList = [];
    if (Utils.hasPrivilege("CREATE", this.state.privileges)) {
      actionList.push({
        icon: () => (
          <AddIconButton style={{ fontSize: "larger", color: "#1b7189" }} />
        ),
        tooltip: "Add Supplier",
        isFreeAction: true,
        onClick: (event) => this.addSupplier(),
      });
    } else if (Utils.hasPrivilege("READ", this.state.privileges)) {
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        <Row md={12} style={{ margin: 0, padding: 0 }}>
          <div style={{ width: "100%", padding: "1em" }}>
            <MaterialTable
              icons={this.tableIcons}
              title="Supplier List"
              localization={{
                body: {
                  emptyDataSourceMessage: Utils.communizeTableText(),
                },
              }}
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Name",
                  field: "name",
                  align: "left",
                  cellStyle: {
                    width: "25%",
                  },
                },
                {
                  title: "GSTN",
                  field: "gstn",
                  align: "left",
                  cellStyle: {
                    width: "15%",
                  },
                },
                {
                  title: "TradeName",
                  field: "tradeName",
                  align: "left",
                  cellStyle: {
                    width: "15%",
                  },
                },
                {
                  title: "Address",
                  field: "address",
                  align: "left",
                  cellStyle: {
                    width: "40%",
                  },
                },
              ]}
              data={this.state.supplierList}
              options={{
                paging: this.state.isPaging,
                pageSize: this.state.pageSize,
                initialPage: this.state.initialPage,
                pageSizeOptions: Utils.materialTablePageSize(),
                sorting: true,
                actionsColumnIndex: -1,
                headerStyle: {
                  // overflow: "scroll",
                  backgroundColor: "#1b7189",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "center",
                },
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                  height: "3em",
                  textTransform: "capitalize",
                }),
              }}
              onChangePage={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              actions={actionList}
              onRowClick={(e, rowData) => this.toSupplierDetails(e, rowData)}
            />
          </div>
        </Row>
      </>
    );
  }
}

export default SupplierList;
