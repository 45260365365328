import React, { Component } from 'react'
import store from '../../../redux/store';
import AdminFetchServerData from '../../../provider/AdminFetchServerData';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
class AdminProfile extends Component {
	header = store.getState().adminHeader.adminHeader;
	userData = store.getState().adminUserDetails.adminUserDetails;
	title = 'Admin Profile';
	breadCrumb = [{ pageid: '', uiname: 'Profile' }];
	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			address: {},
		}
		this.getProfile();
	}
	getProfile() {
		const postObject = {
			header: this.header,
			appuser: {
				id: this.userData.id,
			}
		};
		AdminFetchServerData.callPostService("/adminpanelAppuserms/profile", postObject).then(
			(output) => {
				if (output.status === "SUCCESS") {
					let userDetails = output.data.appuser;
					this.setState({
						render: true,
						firstName: userDetails.firstName,
						lastName: userDetails.lastName,
						email: userDetails.email,
						userStatus: userDetails.userStatus,
						userProfile: userDetails.profile,
					});
				} else {
				}
			}
		);
	};
	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<AdminMenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						history={this.props.history}
						name={this.title}
					// backCallback={this.viewCancel.bind(this)}
					// editCallback={this.editEmail.bind(this)}
					/>
					<div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
						<div className="card" id="organizationFormCard">
							<div className="row">
								<div
									className="card-header d-flex align-items-center justify-content-between"
									id="assetCardHeader"
								>
									<h5 className="mb-0" id="headerLabel">
										User Details
									</h5>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-md-4">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Name
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.firstName + " " + this.state.lastName}</span>
												</div>
											</div>
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Status
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.userStatus?.status}</span>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Email
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.email}</span>
												</div>
											</div>
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Profile
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.userProfile?.name}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)
		}
	}
}
export default AdminProfile;