import TextField from "@material-ui/core/TextField";
import { Autocomplete, InputAdornment } from "@mui/material";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import PurchaseUtils from "../../../../provider/PurchaseUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import RemoveIconButton from "../../buttons/RemoveIconButton";
import PtsAlert from "../../ptsAlert/PtsAlert";
import SearchHsnSacDialogue from "../SearchHsnSacDialogue";
import "./PurchaseAdvanceVoucher.scss";
import AddIconButton from "../../buttons/AddIconButton";

export default class PurchaseAdvanceVoucherItem extends Component {
  formRef = React.createRef();
  expenseCategoryList = store.getState().appData.expenseCategoryList;
  taxRateList = store.getState().staticData.taxRateList;
  products = PurchaseUtils.getPurchaseProducts(
    store.getState().master.masterData.products
  );
  custHsnCodeList = store.getState().master.masterData.custhsncodes;

  constructor(props) {
    super(props);
    let action = props.action;
    let item = props.data;
    let productList = [];

    for (let i = 0; i < this.products.length; i++) {
      const element = this.products[i];
      if (element.producttype?.type !== "Sales") {
        productList.push(element)
      }
    }

    this.state = {
      isIgstApplicable: props.isIgstApplicable,
      rowBackgroundColor: props.backgroundColor.background,
      itemsrno: item.itemsrno,
      index: props.srno,
      id: item.id,
      description: item.description,
      name: item.name,
      quantity: item.quantity,
      productcode: item.productcode,
      rate: item.rate,
      total: item.total,
      uuid: item.uuid,
      igst: item.igst,
      sgst: item.sgst,
      cgst: item.cgst,
      utgst: item.utgst,
      discount: item.discount,
      taxable: item.taxable,
      nontaxable: item.nontaxable,
      refinvitemamount: item.refinvitemamount,
      uom: item.uom,
      suprcmhsncode: item.suprcmhsncode,
      refinvitem: item.refinvitem,
      invoiceitemnumber: item.invoiceitemnumbe,
      cess: item.cess,
      taxrate: Utils.isNull(item.taxrate)
        ? this.getZeroTaxRate()
        : item.taxrate,
      suphsncodes: Utils.isNull(item.suphsncodes) ? "" : item.suphsncodes,
      operation: item.operation,
      version: item.version,
      onDelete: props.onDelete,
      onChange: props.onChange,
      doCalculations: props.doCalculations,
      action: action,
      render: true,
      isDelete: false,
      onPurchaseAdvanceItemAdd: props.onAdd,
      productList: productList,
    };
  }

  getZeroTaxRate() {
    for (let i = 0; i < this.taxRateList.length; i++) {
      if (parseFloat(this.taxRateList[i].rate) === 0) {
        return this.taxRateList[i];
      }
    }
    return null;
  }

  getFirstCustHsnCode() {
    if (this.custHsnCodeList.length > 0) {
      return this.custHsnCodeList[0];
    }
    return null;
  }
  updatePurchaseAdvanceVoucherItemCategory(e, name) {
    this.setState({ expensecategory: name });
  }
  doCalculations() {
    let item = this.state;
    let quantity = 1;
    quantity = Utils.getFloatValue(quantity);
    let rate = Utils.getFloatValue(item.taxable);
    if (parseFloat(quantity) === 0.0 || parseFloat(rate) === 0.0) {
      this.setState({
        taxable: 0.0,
        cgst: 0.0,
        sgst: 0.0,
        igst: 0.0,
        total: 0.0,
      });
      return;
    }

    let valueOfSupply = parseFloat(quantity) * parseFloat(rate);
    let discount = 0.0;
    discount = Utils.getFloatValue(discount);
    let discountAmount = 0;
    if (discount > 0 && valueOfSupply > 0) {
      discountAmount = (parseFloat(valueOfSupply) * parseFloat(discount)) / 100;
    }

    let taxable = parseFloat(valueOfSupply) - parseFloat(discountAmount);

    let cess = item.cess ? item.cess : 0.0;
    cess = Utils.getFloatValue(cess);

    let cessAmount = 0.0;
    if (cess > 0 && taxable > 0) {
      cessAmount = (parseFloat(taxable) * parseFloat(cess)) / 100;
    }

    let taxrate = Utils.getFloatValue(item.taxrate.rate);
    let cgst = 0.0;
    let sgst = 0.0;
    let igst = 0.0;
    let isIgstApplicable = item.isIgstApplicable;
    if (isIgstApplicable) {
      igst = (parseFloat(taxrate) / 100) * parseFloat(taxable);
      igst = igst.toFixed(2);
    } else {
      let newRate = parseFloat(taxrate) / 2;
      cgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      cgst = cgst.toFixed(2);
      sgst = (parseFloat(newRate) / 100) * parseFloat(taxable);
      sgst = sgst.toFixed(2);
    }
    let total =
      parseFloat(taxable) +
      parseFloat(cgst) +
      parseFloat(sgst) +
      parseFloat(igst) +
      parseFloat(cessAmount);
    total = parseFloat(total).toFixed(2);

    this.setState({
      taxable: parseFloat(taxable).toFixed(2),
      cgst: parseFloat(cgst).toFixed(2),
      sgst: parseFloat(sgst).toFixed(2),
      igst: parseFloat(igst).toFixed(2),
      total: parseFloat(total).toFixed(2),
    });

    if (this.state.doCalculations) {
      this.state.taxable = parseFloat(taxable).toFixed(2);
      this.state.cgst = parseFloat(cgst).toFixed(2);
      this.state.sgst = parseFloat(sgst).toFixed(2);
      this.state.igst = parseFloat(igst).toFixed(2);
      this.state.cess = parseFloat(cess).toFixed(2);
      this.state.total = parseFloat(total).toFixed(2);
      this.state.doCalculations();
    }
  }

  handlePurchaseNameChange(e) {
    let val = e.target.value;
    for (let i = 0; i < this.state.productList.length; i++) {
      let product = this.state.productList[i];
      let prodDetails = product.name + "-" + product.code;
      if (Utils.equalsIgnoreCase(val, prodDetails)) {
        let hsnCode = product.hsncode;
        let taxrate = product.taxrate;
        let uom = product.uom;
        let quantity = 1;
        let discount = 0;
        let cess = 0;
        let rate = product.rate;
        this.state.quantity = quantity;
        this.state.cess = cess;
        this.state.uom = uom;
        this.state.taxable = rate;
        this.state.suphsncodes = hsnCode;
        this.state.taxrate = taxrate;
        this.state.rate = rate;
        this.state.rate = rate;
        this.setState({
          uom: uom,
          name: product.name,
          productcode:product.code,
          rate: rate,
          cess: cess,
          quantity: quantity,
          discount: discount,
          suphsncodes: { code: hsnCode },
          taxrate: taxrate,
          taxable: rate,
        });
        this.doCalculations();
        return;
      }
    }
    this.setState({ name: val });
  }

  handleTaxableAmtChange(e) {
    let taxableAmt = e.target.value;
    if (!taxableAmt || taxableAmt.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ taxable: taxableAmt }));
    }
  }

  handleTaxableAmtBlurChange(e) {
    this.doCalculations();
  }
  handleCessChange(e) {
    let cess = e.target.value;
    if (!cess || cess.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ cess: cess }));
    }
  }
  handleCessBlurChange(e) {
    this.doCalculations();
  }
  handleAmountChange(e) {
    let amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      // amount = Number(amount).toFixed(2);
      this.setState(() => ({ amount: amount }));
    }
  }
  handleAmountBlurChange(e) {
    let val = Number(e.target.value).toFixed(2);
    this.state.amount = val;
    this.setState({ amount: val });
    if (this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  deletePurchaseAdvanceVoucherItem(e) {
    if (this.state.onDelete) {
      this.state.isDelete = true;
      this.state.onDelete(this.state);
    }
  }

  validateItem() {
    let suphsncodes = this.state.suphsncodes;
    let name = this.state.name;
    let taxable = this.state.taxable;
    let cess = this.state.cess;
    let taxrate = this.state.taxrate;
    // let amount = this.state.amount;
    if (!suphsncodes || "" === suphsncodes) {
      document.getElementById("suphsncodes" + this.state.index).focus();
      PtsAlert.error("Please Select Item SAC/HSN Code ");
      return false;
    }
    if (Utils.isNullOrEmpty(name)) {
      document.getElementById("name" + this.state.index).focus();
      PtsAlert.error("Please Enter Item Description");
      return false;
    }
    if (Utils.isNullOrEmpty(taxable)) {
      document.getElementById("taxable" + this.state.index).focus();
      PtsAlert.error("Please Enter Taxable Amount");
      return false;
    }
    if (Utils.isNullOrEmpty(cess)) {
      document.getElementById("cess" + this.state.index).focus();
      PtsAlert.error("Please Enter CESS Percentage");
      return false;
    }
    if (Utils.isNullOrEmpty(taxrate)) {
      document.getElementById("taxrate" + this.state.index).focus();
      PtsAlert.error("Please Select Tax Rate ");
      return false;
    }
    return true;
  }

  // updateSupHsnCode(e, suphsn) {
  //   let suphsncode = { code: suphsn };
  //   this.setState({ suphsncodes: suphsn });
  // }
  updateSupHsnCode(e) {
    let suphsncode = { code: e.target.value };
    this.setState({ suphsncodes: suphsncode });
  }

  onCodeChange(changedCode) {
    // this.state.suphsncodes.code = changedCode.code;
    let suphsncodes = { code: changedCode.code };
    this.setState({ suphsncodes: suphsncodes });
  }
  updateTaxRate(e, taxrate) {
    this.state.taxrate = taxrate;
    this.setState({ taxrate: taxrate });
    this.doCalculations();
  }
  render() {
    if (this.state.render === false) {
      return "";
    }
    this.state.isIgstApplicable = this.props.isIgstApplicable;
    if (this.state.action === "edit") {
      return this.editPurchaseAdvanceVoucherItem();
    } else {
      return this.viewPurchaseAdvanceVoucherItem();
    }
  }
  addPurchaseAdvanceVoucherItem(e) {
    if (
      undefined !== this.state.onPurchaseAdvanceItemAdd &&
      null !== this.state.onPurchaseAdvanceItemAdd
    ) {
      this.state.onPurchaseAdvanceItemAdd(this.state);
    }
  }
  editPurchaseAdvanceVoucherItem() {
    return (
      <div
        style={{
          border: "1px solid #e4dede",
          background: this.state.rowBackgroundColor,
        }}
      >
        <Row>
          <Col md={6} style={{ display: "block" }}>
            <span style={{ padding: "0.5em", height: "100% !important" }}>
              <b>{this.state.itemsrno}</b>
            </span>
          </Col>
          <Col md={6}>
            <RemoveIconButton
              onClick={this.deletePurchaseAdvanceVoucherItem.bind(this)}
            />
            &nbsp;&nbsp;
            {this.props.iteamIndex === this.props.invoicedata.length - 1 && (
              <AddIconButton
                onClick={this.addPurchaseAdvanceVoucherItem.bind(this)}
              />
            )}
            &nbsp;&nbsp;
          </Col>
        </Row>
        <Row
          md={12}
          style={{
            margin: "5px",
            padding: "0",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Col md={4} style={{ display: "block", alignItems: "center" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Item Details<span className="asterisk"> *</span>
            </label>
            <input
              style={{
                width: "100%",
                padding: "0.6em",
              }}
              type="text"
              id={"name" + this.state.index}
              list="data"
              label="Item Details"
              value={this.state.name}
              onChange={(event, value) => {
                this.handlePurchaseNameChange(event, value);
              }}
            />
            <datalist id="data">
              {this.state.productList.map((item, key) => (
                <option key={item.id} value={item.name + "-" + item.code} />
              ))}
            </datalist>
          </Col>
          <Col md={2} style={{ display: "block" }}>
            {/* <div style={{ display: "flex", alignItems: "center" }}> */}
            <label className="form-label " htmlFor="collapsible-companyName">
              SAC/HSN
            </label>

            <TextField
              // style={{ margin: "0.5em", shrink: "true" }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              id={"suphsncodes" + this.state.index}
              type="text"
              placeholder="SAC/HSN "
              onChange={this.updateSupHsnCode.bind(this)}
              value={this.state.suphsncodes ? this.state.suphsncodes.code : ""}
              name="taxable"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* <GenerateIconButton

                                  /> */}
                    <SearchHsnSacDialogue
                      style={{ float: "right" }}
                      className="gstnBtn"
                      onCodeChange={this.onCodeChange.bind(this)}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/* <SearchHsnSacDialogue
                style={{ float: "right" }}
                className="gstnBtn"
                onCodeChange={this.onCodeChange.bind(this)}
              /> */}
            {/* </div> */}
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Advance Amt.<span className="asterisk"> *</span>
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              variant="outlined"
              // inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              id={"taxable" + this.state.index}
              type="text"
              placeholder="Advance Amt."
              onChange={this.handleTaxableAmtChange.bind(this)}
              onBlur={this.handleTaxableAmtBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.taxable}
              name="taxable"
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              CESS %<span className="asterisk"> *</span>
            </label>
            <TextField
              // style={{ margin: "0.5em" }}
              // inputProps={{ min: 0, style: { textAlign: "right" } }}
              fullWidth
              variant="outlined"
              placeholder="CESS %"
              required
              id={"cess" + this.state.cess}
              type="text"
              onChange={this.handleCessChange.bind(this)}
              onBlur={this.handleCessBlurChange.bind(this)}
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.cess}
              name="cess"
            />
          </Col>
          <Col md={2} style={{ display: "block" }}>
            <label className="form-label " htmlFor="collapsible-companyName">
              Tax Rate %<span className="asterisk"> *</span>
            </label>
            <Autocomplete
              required
              disableClearable
              ListboxProps={{
                className: "myCustomList"
              }}
              // style={{ margin: "0.5em" }}
              id={"taxrate" + this.state.index}
              options={this.taxRateList}
              value={this.state.taxrate}
              getOptionLabel={(option) => option.rate + "%"}
              onChange={(event, value) => {
                this.updateTaxRate(event, value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder="Tax Rate %"
                    {...params}
                    style={{ textAlign: "right" }}
                    fullWidth
                    variant="outlined"
                    className="regCountry"
                  />
                );
              }}
            />
          </Col>
        </Row>

        <Row
          md={12}
          style={{
            margin: "5px",
            padding: "0",
            alignItems: "center",
            display: "flex",
          }}
        >
          {this.state.isIgstApplicable === false && (
            <>
              <Col md={6}></Col>
              <Col md={2}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  CGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{
                    min: 0,
                    style: { textAlign: "right", border: "0" },
                  }}
                  fullWidth
                  disabled
                  className="disableFiled"
                  variant="outlined"
                  placeholder="CGST"
                  id={"cgst" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.cgst}
                  name="taxrate"
                />
              </Col>
              <Col md={2}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  SGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  // inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  disabled
                  className="disableFiled"
                  variant="outlined"
                  placeholder="SGST"
                  id={"sgst" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.sgst}
                  name="sgst"
                />
              </Col>
              <Col md={2} style={{ alignSelf: "flex-end" }}>
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Total
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  variant="outlined"
                  disabled
                  className="disableFiled"
                  placeholder="Total"
                  id={"total" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.total}
                  name="total"
                />
                {/* </div> */}
              </Col>
            </>
          )}
          {this.state.isIgstApplicable === true && (
            <>
              <Col md={8} style={{ display: "block" }}></Col>
              <Col Col md={2} style={{ display: "block" }}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  IGST
                </label>
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  disabled
                  className="disableFiled"
                  variant="outlined"
                  placeholder="IGST"
                  id={"igst" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.igst}
                  name="igst"
                />
              </Col>
              <Col md={2} style={{ alignSelf: "flex-end" }}>
                <label
                  className="form-label "
                  htmlFor="collapsible-companyName"
                >
                  Total
                </label>
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                <TextField
                  // style={{ margin: "0.5em" }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  variant="outlined"
                  disabled
                  className="disableFiled"
                  placeholder="Total"
                  id={"total" + this.state.index}
                  type="text"
                  onChange={this.handleAmountChange.bind(this)}
                  onBlur={this.handleAmountBlurChange.bind(this)}
                  value={this.state.total}
                  name="total"
                />
                {/* <a
                  onClick={this.deletePurchaseAdvanceVoucherItem.bind(this)}
                  style={{ cursor: "pointer", float: "right" }}
                >
                  <i
                    class="fa fa-remove fa-lg"
                    style={{ color: "#cc5050" }}
                  ></i>
                </a> */}
                {/* </div> */}
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }

  viewPurchaseAdvanceVoucherItem() {
    return (
      <tr
        style={{
          margin: "0",
        }}
      >
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            width: "10%",
          }}
        >
          <span style={{ float: "left" }}>{this.state.index}</span>
          <span style={{ float: "right" }}>
            {this.state.suphsncodes.code}
          </span>
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            width: "25%",
          }}
        >
          {this.state.name}
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "15%",
          }}
        >
          {Utils.getFloatValue(this.state.taxable)}
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "15%",
          }}
        >
          {Utils.getFloatValue(this.state.cess)}
        </td>
        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "15%",
          }}
        >
          {this.state.taxrate?.rate}
        </td>
        {this.state.isIgstApplicable === false && (
          <>
            <td
              style={{
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
                width: "15%",
              }}
            >
              {Utils.getFloatValue(
                parseFloat(this.state.cgst) + parseFloat(this.state.sgst)
              )}
            </td>
          </>
        )}
        {this.state.isIgstApplicable === true && (
          <td
            style={{
              border: "0.5px solid #ece6e6",
              padding: "0.5em",
              textAlign: "right",
              width: "15%",
            }}
          >
            {Utils.getFloatValue(this.state.igst)}
          </td>
        )}

        <td
          style={{
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
            width: "20%",
          }}
        >
          {Utils.getFloatValue(this.state.total)}
        </td>
      </tr>
    );
  }
}
