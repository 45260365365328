import {
  CLEAR_APPLICATION_STATE,
  GET_APPLICATION_STATE,
  SET_APPLICATION_STATE,
} from "./ApplicationStateTypes";

const initialState = {
  customerMasterState: {},
  supplierMasterState: {},
  expenseSupplierMasterState: {},
  productServicesMasterState: {},
  salesVoucherState: {},
  salesAdvanceVoucherState: {},
  salesDebitNoteState: {},
  salesCreditNoteState: {},
  salesRefundNoteState: {},
  purchaseOrderState: {},
  purchaseVoucherState: {},
  purchaseAdvanceVoucherState: {},
  purchaseCreditNoteState: {},
  purchaseDebitNoteState: {},
  purchaseRefundVoucherState: {},
  purchaseRCMVoucherState: {},
  purchaseRCMPaymentVoucherState: {},
  purchaseGSIVoucherState: {},
  purchasePaymentVoucherState: {},
  expenseState: {},
  salesHistoryState: {},
  purchaseHistoryState: {},
  expenseHistoryState: {},
  adminRegistrationState: {},
  UserManagementState: {},
};

const ApplicationStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATION_STATE:
      return {
        ...state,
      };
    case SET_APPLICATION_STATE:
      return {
        ...state,
        customerMasterState: action.payload.customerMasterState,
        supplierMasterState: action.payload.supplierMasterState,
        expenseSupplierMasterState: action.payload.expenseSupplierMasterState,
        productServicesMasterState: action.payload.productServicesMasterState,
        salesVoucherState: action.payload.salesVoucherState,
        salesAdvanceVoucherState: action.payload.salesAdvanceVoucherState,
        salesDebitNoteState: action.payload.salesDebitNoteState,
        salesCreditNoteState: action.payload.salesCreditNoteState,
        salesRefundNoteState: action.payload.salesRefundNoteState,
        purchaseOrderState: action.payload.purchaseOrderState,
        purchaseVoucherState: action.payload.purchaseVoucherState,
        purchaseAdvanceVoucherState: action.payload.purchaseAdvanceVoucherState,
        purchaseCreditNoteState: action.payload.purchaseCreditNoteState,
        purchaseDebitNoteState: action.payload.purchaseDebitNoteState,
        purchaseRefundVoucherState: action.payload.purchaseRefundVoucherState,
        purchaseRCMVoucherState: action.payload.purchaseRCMVoucherState,
        purchaseRCMPaymentVoucherState:
          action.payload.purchaseRCMPaymentVoucherState,
        purchaseGSIVoucherState: action.payload.purchaseGSIVoucherState,
        purchasePaymentVoucherState: action.payload.purchasePaymentVoucherState,
        expenseState: action.payload.expenseState,
        salesHistoryState: action.payload.salesHistoryState,
        purchaseHistoryState: action.payload.purchaseHistoryState,
        expenseHistoryState: action.payload.expenseHistoryState,
        adminRegistrationState: action.payload.adminRegistrationState,
        UserManagementState: action.payload.UserManagementState,
      };

    case CLEAR_APPLICATION_STATE:
      return {
        ...state,
        customerMasterState: null,
        supplierMasterState: null,
        expenseSupplierMasterState: null,
        productServicesMasterState: null,
        salesVoucherState: null,
        salesAdvanceVoucherState: null,
        salesDebitNoteState: null,
        salesCreditNoteState: null,
        salesRefundNoteState: null,
        purchaseOrderState: null,
        purchaseVoucherState: null,
        purchaseAdvanceVoucherState: null,
        purchaseCreditNoteState: null,
        purchaseDebitNoteState: null,
        purchaseRefundVoucherState: null,
        purchaseRCMVoucherState: null,
        purchaseRCMPaymentVoucherState: null,
        purchaseGSIVoucherState: null,
        purchasePaymentVoucherState: null,
        expenseState: null,
        salesHistoryState: null,
        purchaseHistoryState: null,
        expenseHistoryState: null,
        adminRegistrationState: null,
        UserManagementState: null,
      };
    default:
      return state;
  }
};

export default ApplicationStateReducer;
