import React, { Component } from "react";
import { Row } from "reactstrap";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import MenuWithBreadScrum from "../menuBox/MenuWithBreadScrum";
export default class Master extends Component {
  breadCrumb = [{ pageid: "/master", uiname: "Master", cssclass: "fa fa-book" }];
  title = "Master";
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();

    // this.childArray = JSON.parse(localStorage.getItem('childMenuArr'));

    // let allMenus = store.getState().userMenus.allMenus;
    let activeMenu = store.getState().appData.activeMenu;
    let masterArray = Utils.getSubMenus(activeMenu);
    this.state = {
      masterArray: masterArray,
      render: true,
    };
  }

  render() {
    if (this.state.render === false) {
      return "";
    }

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name="Master"
        />
        <div style={{ justifyContent: "center", margin: "0 auto" }}>
          <Row md={12} style={{ width: "100%" }}>
            {UiUtils.getUiMenuArray(
              this.state.masterArray,
              this.props.history,
              this.state.color,
              this.breadCrumb
            )}
          </Row>
        </div>
      </>
    );
  }
}
