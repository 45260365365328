import { LOGIN, LOGOUT } from './appUserTypes';
// import stateLoader from '../stateLoader';
const initialState = {};

const appUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
      };
    case LOGOUT:
      state.header = null;
      localStorage.clear();
      // stateLoader.saveState({});
      return {
        ...state,
        header: null,
      };
    default:
      return state;
  }
};

export default appUserReducer;
