import React, { Component } from 'react'
import Utils from '../../../provider/Utils';
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from '@material-ui/core/Tooltip';
import { Autocomplete } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import SaveButton from '../../components/buttons/SaveButton';
import CancelButton from '../../components/buttons/CancelButton';
import ToggleButton from '@mui/material/ToggleButton';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import AdminFetchServerData from '../../../provider/AdminFetchServerData';
import store from '../../../redux/store';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import CreateButton from '../../components/buttons/CreateButton';

class AdminServiceAccountingCode extends Component {
	header = store.getState().adminHeader.adminHeader;
	taxRateList = store.getState().staticData.taxRateList;
	title = 'SAC HSN Details';
	breadCrumb = [
		{ pageid: '/sacHsnList', uiname: 'SAC HSN List' },
		{ pageid: '', uiname: 'SAC HSN Details' },
	];
	constructor(props) {
		super(props);
		let action = props.action;
		let sacHsnId = props.sacHsnId;
		let serviceGroupList = [];

		if (Utils.isNull(sacHsnId) &&
			props.history &&
			props.history.location &&
			props.history.location.state
		) {
			action = props.history.location.state.action;
			sacHsnId = props.history.location.state.sacHsnId;
			serviceGroupList = props.history.location.state.serviceGroupList;
		}
		let canRender = false;
		if (sacHsnId && sacHsnId > 0) {
			this.getSacHsnCodeDetails(sacHsnId);
		} else {
			canRender = true;
		}

		this.state = {
			action: action,
			sacHsnId: sacHsnId,
			code: "",
			description: "",
			groupDescription: null,
			isnilrated: false,
			taxrate: null,
			serviceGroupList: serviceGroupList,
			render: false,
		}
	}

	getSacHsnCodeDetails(sacHsnId) {
		const postObject = {
			header: this.header,
			service: {
				id: sacHsnId,
			},
		};
		AdminFetchServerData.callPostService('/sacHsnCodes/get', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let sacHsnDetails = output.data.service;
					let getPermission = Utils.getRolePermission(["*"]);
					let getUpdatePermission = getPermission[3];
					let getDeletePermission = getPermission[4];

					this.setState({
						render: true,
						id: sacHsnDetails.id,
						code: sacHsnDetails.code,
						description: sacHsnDetails.description,
						groupDescription: sacHsnDetails.group,
						isnilrated: sacHsnDetails.isnilrated,
						taxrate: sacHsnDetails.taxrate,
						getUpdatePermission: getUpdatePermission,
						getDeletePermission: getDeletePermission,
					});
				} else {
				};
			})
			.catch((error) => {
			});
	};

	viewCancel() {
		this.props.history.push("/sacHsnList");
	}

	editSacHsnCodeDetail() {
		this.props.history.push({
			pathname: "/editSacHsn",
			state: {
				action: "edit",
				sacHsnId: this.state.id,
				serviceGroupList: this.state.serviceGroupList,
			},
		});
		// this.setState({ action: "edit", appUserId: this.state.id, })
	};

	handleCodeChange(e) {
		this.setState({ code: e.target.value })
	}

	handleDescriptionChange(e) {
		this.setState({ description: e.target.value });
	}
	handleGroupDescriptionChange(e, selected) {
		this.setState({ groupDescription: selected });
	}

	updateTaxRate(e, taxrate) {
		// this.setState({ taxrate: taxrate });
		this.setState({ taxrate: e.target.value });
	}

	handleIsNilRatedChange(e) {
		this.setState({ isnilrated: !this.state.isnilrated });
	};

	createSacHsnCodeToBackend() {
		const postObject = {
			header: this.header,
			service: {
				code: this.state.code,
				description: this.state.description,
				taxrate: this.state.taxrate,
				isnilrated: this.state.isnilrated,
				group: this.state.groupDescription,
			},
		};
		AdminFetchServerData.callPostService('/sacHsnCodes/create', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let sacHsnDetails = output.data.service;
					this.props.history.push({
						pathname: "/viewSacHsn",
						state: {
							sacHsnId: sacHsnDetails.id,
							action: "view",
							breadCrumb: this.breadCrumb,
							serviceGroupList: this.state.serviceGroupList,
						},
					});
				} else {
				};
			})
			.catch((error) => {
			});
	}

	updateSacHsnCodeToBackend() {
		const postObject = {
			header: this.header,
			service: {
				id: this.state.sacHsnId,
				code: this.state.code,
				description: this.state.description,
				taxrate: this.state.taxrate,
				isnilrated: this.state.isnilrated,
				group: this.state.groupDescription,
			},
		};
		AdminFetchServerData.callPostService('/sacHsnCodes/update', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let sacHsnDetails = output.data.service;
					this.props.history.push({
						pathname: "/viewSacHsn",
						state: {
							sacHsnId: sacHsnDetails.id,
							action: "view",
							breadCrumb: this.breadCrumb,
							serviceGroupList: this.state.serviceGroupList,
						},
					});
				} else {
				};
			})
			.catch((error) => {
			});
	}

	deleteSacsHsnCode() {
		const postObject = {
			header: this.header,
			service: {
				id: this.state.id,
			},
		};

		AdminFetchServerData.callPostService('/sacHsnCodes/delete', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					PtsAlert.success(output.message);
					this.props.history.push('/sacHsnList');
				} else {
				};
			})
			.catch((error) => { });
	}

	editCancel() {
		this.props.history.push({
			pathname: "/viewSacHsn",
			state: {
				action: "view",
				sacHsnId: this.state.id,
				breadCrumb: this.breadCrumb,
				serviceGroupList: this.state.serviceGroupList,
			},
		});
	}

	render() {
		if (this.state.action === "create") {
			return this.createSacHsnCode()
		} else if (this.state.action === "view") {
			return this.viewSacHsnCode()
		} else if (this.state.action === "edit") {
			return this.editSacHsnCode()
		}
	}

	createSacHsnCode() {
		return (
			<>
				<AdminMenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					history={this.props.history}
					name="Create SAC HSN"
				/>
				<div className="row">
					<ValidatorForm className="text-start" ref="form" onSubmit={this.createSacHsnCodeToBackend.bind(this)}>
						<div className="row" style={{ marginTop: "1em" }}>
							<div className="col-md-12">
								<div className="card" id="organizationFormCard">
									<div className="card-body">
										<div className="row mb-4">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-firstname">
													Code <span className="asterisk"> *</span>
												</label>
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-firstName"
													variant="outlined"
													placeholder="First Name"
													value={this.state.code}
													onChange={this.handleCodeChange.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-companyName">
													Tax Rate %<span className="asterisk"> *</span>
												</label>
												{/* <Autocomplete
                          required
                          disableClearable
                          // style={{ margin: "0.5em" }}
                          id={"taxrate" + this.state.index}
                          options={this.taxRateList}
                          value={this.state.taxrate}
                          getOptionLabel={(option) => option.rate + "%"}
                          onChange={(event, value) => {
                            this.updateTaxRate(event, value);
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                placeholder="Tax Rate %"
                                {...params}
                                style={{ textAlign: "right" }}
                                fullWidth
                                variant="outlined"
                                className="regCountry"
                              />
                            );
                          }}
                        /> */}
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-firstName"
													variant="outlined"
													placeholder="First Name"
													value={this.state.taxrate}
													onChange={this.updateTaxRate.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1 " id="assetFormLabel">
												<label className="form-label col-form-label fw-bold" htmlFor="collapsible-email">
													Is Nil Rated
												</label>
												<Tooltip title="Please click 'Click’ to change Status">
													<span className="ms-4">
														{this.state.isnilrated ?
															<ToggleButton
																className="ToggleButtonActive"
																onChange={this.handleIsNilRatedChange.bind(this)}
															>
																Yes
															</ToggleButton>
															:
															<ToggleButton
																className="ToggleButtonInActive"
																onChange={this.handleIsNilRatedChange.bind(this)}
															>
																No
															</ToggleButton>
														}
													</span>
												</Tooltip>
											</div>
										</div>
										<div className="row">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-appUserRole">
													Group<span className="asterisk"> *</span>
												</label>
												<Autocomplete
													required
													disableClearable
													ListboxProps={{
														className: "myCustomList"
													}}
													// style={{ margin: "0.5em" }}
													id={"group" + this.state.index}
													options={this.state.serviceGroupList}
													value={this.state.groupDescription}
													getOptionLabel={(option) => option.description}
													onChange={(event, value) => {
														this.handleGroupDescriptionChange(event, value);
													}}
													renderInput={(params) => {
														return (
															<TextField
																placeholder="Group"
																{...params}
																style={{ textAlign: "right" }}
																fullWidth
																variant="outlined"
																className="regCountry"
															/>
														);
													}}
												/>
											</div>
											<div className="col-md-8 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-appUserRole">
													Discription<span className="asterisk"> *</span>
												</label>
												<TextareaAutosize
													variant="outlined"
													fullWidth
													id="description"
													style={{
														width: "100%",
														padding: "10px",
														border: "0.5px solid #D5D8DC",
													}}
													minRows={2}
													// label="Description"
													value={this.state.description}
													onChange={this.handleDescriptionChange.bind(this)}
													autoComplete="description"
													placeholder="Description"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row d-flex align-items-center justify-content-between">
							<div className="col-sm-12 mt-3 text-center">
								<CreateButton id="createButton" type="submit"> Create </CreateButton>
								&nbsp;&nbsp;
								<CancelButton onClick={(e) => this.viewCancel()} />
							</div>
						</div>
					</ValidatorForm>
				</div >
			</>
		)
	}

	viewSacHsnCode() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<AdminMenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						history={this.props.history}
						name={this.title}
						backCallback={this.viewCancel.bind(this)}
						editCallback={this.editSacHsnCodeDetail.bind(this)}
						deleteCallback={this.deleteSacsHsnCode.bind(this)}
						getDeletePermission={this.state.getDeletePermission}
						getUpdatePermission={this.state.getUpdatePermission}
					/>
					<div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
						<div className="card" id="organizationFormCard">
							<div className="row">
								<div className="card-body">
									<div className="row">
										<div className="col-md-4">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Code
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.code}</span>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Tax Rate
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.taxrate}</span>
												</div>
											</div>
										</div>
										<div className="col-md-4 mt-1 " id="assetFormLabel">
											<label className="form-label col-form-label fw-bold" htmlFor="collapsible-email">
												Is Nil Rated
											</label>
											<span className="ms-4">
												{this.state.isnilrated ?
													<ToggleButton
														// value={this.state.isnilrated}
														className="ToggleButtonActive"
														// onChange={this.handleIsNilRatedChange.bind(this)}
														style={{
															background: '#0B6F9D',
															color: '#fff',
															height: '33px',
															width: "80px",
															borderRadius: "5px",
															textTransform: 'none',
														}
														}
													>
														Yes
													</ToggleButton>
													:
													<ToggleButton
														// value={this.state.isnilrated}
														className="ToggleButtonActive"
														// onChange={this.handleIsNilRatedChange.bind(this)}
														style={{
															background: '#D7DBDD',
															color: '#000',
															height: '33px',
															width: "80px",
															borderRadius: "5px",
															textTransform: 'none',
														}
														}
													>
														No
													</ToggleButton>
												}
											</span>
										</div>
									</div>
									<div className="row pt-1">
										<div className="col-md-4 mt-1 " id="assetFormLabel">
											<div className="row pt-1">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Group
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<span>{this.state.groupDescription?.description}</span>
												</div>
											</div>
										</div>
										<div className="col-md-8 mt-1 " id="assetFormLabel">
											<div className="row pt-1">
												<label
													className="col-sm-2 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Discription
												</label>
												<div className="col-sm-10" id="OrganizationCol">
													<span>{this.state.description}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-4 text-center">
							<Button
								type="submit"
								variant="contained"
								size="small"
								style={{
									background: "#717373",
									color: "#fff",
									marginRight: "1rem",
								}}
								startIcon={<ArrowBackIcon />}
								onClick={(e) => this.viewCancel()}
							>
								Back
							</Button>
						</div>
					</div>
				</>
			)
		}
	}

	editSacHsnCode() {
		return (
			<>
				<AdminMenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					history={this.props.history}
					name={this.title}
				/>
				<div className="row">
					<ValidatorForm className="text-start" ref="form" onSubmit={this.updateSacHsnCodeToBackend.bind(this)}>
						<div className="row" style={{ marginTop: "1em" }}>
							<div className="col-md-12">
								<div className="card" id="organizationFormCard">
									<div className="card-body">
										<div className="row mb-4">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-firstname">
													Code <span className="asterisk"> *</span>
												</label>
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-firstName"
													variant="outlined"
													placeholder="First Name"
													value={this.state.code}
													onChange={this.handleCodeChange.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-companyName">
													Tax Rate %<span className="asterisk"> *</span>
												</label>
												{/* <Autocomplete
                          required
                          disableClearable
                          // style={{ margin: "0.5em" }}
                          id={"taxrate" + this.state.index}
                          options={this.taxRateList}
                          value={this.state.taxrate}
                          getOptionLabel={(option) => option.rate + "%"}
                          onChange={(event, value) => {
                            this.updateTaxRate(event, value);
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                placeholder="Tax Rate %"
                                {...params}
                                style={{ textAlign: "right" }}
                                fullWidth
                                variant="outlined"
                                className="regCountry"
                              />
                            );
                          }}
                        /> */}
												<TextField
													type="text"
													className="textField"
													fullWidth
													id="basic-default-firstName"
													variant="outlined"
													placeholder="First Name"
													value={this.state.taxrate}
													onChange={this.updateTaxRate.bind(this)}
													validators={["required"]}
													errormessages={["This field is required"]}
													required
												/>
											</div>
											<div className="col-md-4 mt-1 " id="assetFormLabel">
												<label className="form-label col-form-label fw-bold" htmlFor="collapsible-email">
													Is Nil Rated
												</label>
												<Tooltip title="Please click 'Click’ to change Status">
													<span className="ms-4">
														{this.state.isnilrated ?
															<ToggleButton
																className="ToggleButtonActive"
																onChange={this.handleIsNilRatedChange.bind(this)}
															>
																Yes
															</ToggleButton>
															:
															<ToggleButton
																className="ToggleButtonInActive"
																onChange={this.handleIsNilRatedChange.bind(this)}
															>
																No
															</ToggleButton>
														}
													</span>
												</Tooltip>
											</div>
										</div>
										<div className="row">
											<div className="col-md-4 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-appUserRole">
													Group<span className="asterisk"> *</span>
												</label>
												<Autocomplete
													required
													disableClearable
													ListboxProps={{
														className: "myCustomList"
													}}
													// style={{ margin: "0.5em" }}
													id={"group" + this.state.index}
													options={this.state.serviceGroupList}
													value={this.state.groupDescription}
													getOptionLabel={(option) => option.description}
													onChange={(event, value) => {
														this.handleGroupDescriptionChange(event, value);
													}}
													renderInput={(params) => {
														return (
															<TextField
																placeholder="Group"
																{...params}
																style={{ textAlign: "right" }}
																fullWidth
																variant="outlined"
																className="regCountry"
															/>
														);
													}}
												/>
											</div>
											<div className="col-md-8 mt-1" id="assetFormLabel">
												<label className="form-label " htmlFor="collapsible-appUserRole">
													Discription<span className="asterisk"> *</span>
												</label>
												<TextareaAutosize
													variant="outlined"
													fullWidth
													id="description"
													style={{
														width: "100%",
														padding: "10px",
														border: "0.5px solid #D5D8DC",
													}}
													minRows={2}
													// label="Description"
													value={this.state.description}
													onChange={this.handleDescriptionChange.bind(this)}
													autoComplete="description"
													placeholder="Description"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row d-flex align-items-center justify-content-between">
							<div className="col-sm-12 mt-3 text-center">
								<SaveButton id="saveButton" type="submit"> Save </SaveButton>
								&nbsp;&nbsp;
								<CancelButton onClick={(e) => this.editCancel()} />
							</div>
						</div>
					</ValidatorForm>
				</div >
			</>
		)
	}
}
export default AdminServiceAccountingCode;