import React from "react";
export default function SidebarToggleButton({
  isSidebarCollapsed,
  toggleSidebar,
}) {
  const chevronClassName = isSidebarCollapsed
    ? "is-collapsed"
    : "is-not-collapsed";
  const screenReaderLabel = isSidebarCollapsed
    ? "Expand Sidebar Navigation"
    : "Collapse Sidebar Navigation";
  return (
    <span
      onClick={toggleSidebar}
      className={`m-r sidebar-toggle ${chevronClassName}`}
      aria-label={screenReaderLabel}
      style={{
        // marginTop: "1em",
        cursor: "pointer",
      }}
    >
      <i
        class="bx bx-chevron-left bx-sm align-middle"
        style={{
          fontSize: "25px",
          transform: isSidebarCollapsed ? "rotatey(0deg)" : "rotatey(180deg)",
        }}
      ></i>
      {/* <FA name={'fa fa-navicon'} /> */}
    </span>
  );
}
