import Tooltip from "@material-ui/core/Tooltip";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
export class PrintPreviewIconButton extends Component {
    constructor(props) {
        super(props);
        // let domainObject = props.domainObject ? props.domainObject : '';
        this.state = {
            domainObject: props.onClick,
            onClick: props.onClick,
            caption: props.caption,
        };
    }

    onClick() {
        if (this.state.onClick) {
            this.state.onClick();
        }
    }
    render() {
        return (
            <Tooltip title="Print Preview">
                <Button
                    className="add"
                    onClick={this.onClick.bind(this)}
                    style={{
                        background: "#246577",
                        color: "#fff",
                        marginRight: "1rem",
                        marginBottom: "1em",
                        textTransform: "capitalize",
                        float: "right",
                        padding: "4px 8px",
                    }}
                    startIcon={<PrintRoundedIcon />}
                >
                    Print Preview
                </Button>
            </Tooltip>
        );
    }
}
export default PrintPreviewIconButton;
