import Tooltip from "@material-ui/core/Tooltip";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
export class DownloadIconButton extends Component {
  confirmDialogueRef = React.createRef();
  constructor(props) {
    let domainObject = props.domainObject ? props.domainObject : "";
    super(props);
    this.state = {
      domainObject: domainObject,
      title: props.title ? props.title : "Delete Confirmation",
      text: props.text
        ? props.text
        : "Are you sure about deleting " +
          domainObject +
          ", It cannot be recovered once deleted?",
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    this.confirmDialogueRef = React.createRef();
    return (
      // <span
      //   onClick={this.onClick.bind(this)}
      //   style={{
      //     cursor: "pointer",
      //     fontSize: "x-larger",
      //     marginRight: "0.5em",
      //     float: "right",
      //   }}
      // >
      <>
        <Tooltip title="Download">
          {/* <CloudDownloadIcon
            style={{
              border: "2px solid #fff",
              borderRadius: "25%",
              // background: '#196927',
              // color: 'white',
              padding: "2px",
              // fontSize: 'x-large',
            }}
            className="downloadIconButton"
          /> */}

          <Button
            // className="downloadIconButton"
            size="small"
            onClick={this.onClick.bind(this)}
            style={{
              background: "#867e7e",
              color: "white",
              // fontSize: "x-large",
              textTransform: "capitalize",
              cursor: "pointer",
              // fontSize: "x-larger",
              width: "100px",
              float: "right",
              // margin: "0.2em 0.5em 0.3em 0em",
              marginRight: "1rem",
              marginBottom: "1em",
            }}
            // style={{
            //   background: "#867e7e",
            //   color: "white",
            //   fontSize: "x-large",
            //   textTransform: "capitalize",
            //   cursor: "pointer",
            //   fontSize: "x-larger",
            //   float: "right",
            //   // margin: "0.2em 0.5em 0.3em 0em",
            //   marginRight: "1rem",
            //   marginBottom: "1em",
            // }}
            startIcon={<CloudDownloadIcon />}
          >
            Download
          </Button>
        </Tooltip>
      </>
    );
  }
}
export default DownloadIconButton;
