import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import Utils from "../../../provider/Utils";
import appDataAction from "../../../redux/appData/appDataAction";
import store from "../../../redux/store";
import PageAlertContext from "../../components/pageAlert/PageAlertContext";
import NavOverlay from "./components/NavOverlay";
import NavSingleItemCmp from "./components/NavSingleItemCmp";
import NavSpacer from "./components/NavSpacer";
import logo from "../../../assets/images/th.png";
import { Link } from "react-router-dom";
import ToggleSidebarButton from "../../components/header/components/ToggleSidebarButton";
import "./AdminSidebar.css";
export class AdminSidebar extends Component {
	menuList = store.getState().adminUserDetails.adminUserDetails.profile.menus;

	constructor(props) {
		super(props);
		let userMenuArray = Utils.getTopMenus();
		let activeMenu = store.getState().appData.activeMenu;
		if (undefined === activeMenu || null === activeMenu) {
			activeMenu =
				(userMenuArray && userMenuArray.length) > 0 ? userMenuArray[0].id : -1;
		}
		this.state = {
			sidebarMenuArr: this.menuList,
			activeMenu: activeMenu,
		};
	}
	selectedChildMenu = (menuid) => {
		for (let i = 0; i < this.state.sidebarMenuArr.length; i++) {
			if (this.state.sidebarMenuArr[i].id === menuid) {
				store.dispatch(appDataAction.setActiveMenu(menuid));
				this.setState({ activeMenu: menuid });
			}
		}
	};
	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key)) return false;
		}
		return true;
	}
	render() {
		// if (window.location.pathname === '/login') return null;

		const navItems = (items) => {
			if (!this.isEmpty(items)) {
				return items.map((item, index) => itemType(item, index));
			}
		};

		const itemType = (item, index) => {
			if (!this.isEmpty(item)) {
				let isActive = false;
				if (item.id === this.state.activeMenu) {
					isActive = true;
				}
				return (
					<NavSingleItemCmp
						isSidebarCollapsed={this.props.isSidebarCollapsed}
						isActive={isActive}
						item={item}
						key={index}
						onSelect={this.selectedChildMenu.bind(this)}
					/>
				);
			}
		};

		// const NavBrand = ({ logo, logoText }) => {
		//   return (
		//     <div className="site-logo-bar">
		//       <NavLink to="/" className="navbar-brand">
		//         {logo && <img src={logo} alt="" />}
		//         {logoText && <span className="logo-text">{logoText}</span>}
		//       </NavLink>
		//     </div>
		//   );
		// };

		return (
			<PageAlertContext.Consumer>
				{(consumer) => {
					const hasPageAlertClass = consumer.alert ? "has-alert" : "";
					return (
						<div
							className={`app-sidebar ${hasPageAlertClass}`}
							style={{
								// boxShadow: '0px 0px 12px 0px #ccc',
								// boxShadow: "inset 0 0 15px #000000",
								// marginTop: "3.6em",
								zIndex: 1500,
								// transition: "width 0.3s linear",
								// fontSize:'14px',
								display: "box",
								maxWidth: "max-content !important",
								minWidth: "min-content !important",
							}}
						>
							{/* <NavBrand logo={this.props.logo} logoText={this.props.logoText} style={{ color: '#fff' }} /> */}

							<nav>
								<div className="app-brand demo">
									<Link to="/dashboard" className="app-brand-link">
										<span
											className="app-brand-logo demo"
											style={{ marginLeft: "0.8em", padding: "10px 12px" }}
										>
											{/* <div
                  style={{
                    borderRadius: "60%",
                    backgroundColor: "#fff",
                    height: "44px",
                    width: "44px",
                  }}
                > */}
											<img
												src={logo}
												className="img-fluid"
												alt=""
												// width={17}
												style={{
													// marginLeft: "14.5px",
													paddingTop: "8px",
													width: "30px",
												}}
												data-app-dark-img="illustrations/girl-with-laptop-dark.png"
												data-app-light-img="illustrations/girl-with-laptop-light.png"
											/>
											{/* </div> */}
										</span>
										{this.props.isSidebarCollapsed ?
											<span
												className="app-brand-text demo menu-text fw-bolder ms-2"
												style={{
													textTransform: "capitalize",
													fontSize: "23px",
													fontFamily: "Poppins",
													color: "#fff",
													marginTop: "0.2em",
												}}
											>
												Taxonomy Hub
											</span> : ""}
									</Link>
									<div
										className="layout-menu-toggle menu-link text-large ms-auto"
										style={{
											left: this.props.isSidebarCollapsed ? "16rem" : "4.1rem",
											cursor: "pointer",
											width: "2.5em",
											transition: "width 0.3s linear",
										}}
									// onClick={this.toggleSidebar}
									>
										<ToggleSidebarButton
											toggleSidebar={this.props.toggleSidebar}
											isSidebarCollapsed={this.props.isSidebarCollapsed}
										/>
										{/* <i
                      className="bx bx-chevron-left bx-sm align-middle"
                      style={{
                        transform: this.state.isOpen
                          ? "rotatey(0deg)"
                          : "rotatey(180deg)",
                        cursor: "pointer",
                      }}
                      onClick={this.toggleSidebar}
                    /> */}
									</div>
								</div>
								<ul id="main-menu" className="menu-inner py-1">
									{/* {this.sidebarMenu()} */}

									{navItems(this.state.sidebarMenuArr.sort((a, b) => a.displaysequence - b.displaysequence))}
									<NavSpacer />
									{/* // {navItems(this.props.nav.bottom)} */}
								</ul>
							</nav>
							{this.props.isSidebarCollapsed && (
								<NavOverlay onClick={this.props.toggleSidebar} />
							)}
						</div>
					);
				}}
			</PageAlertContext.Consumer>
		);
	}
}
export default AdminSidebar;
