import { SET_MASTER, GET_MASTER } from './masterDataTypes';
export const getMasterData = () => {
  return {
    type: GET_MASTER,
  };
};
export const setMasterData = (data) => {
  return {
    type: SET_MASTER,
    payload: data,
  };
};
const masterDataAction = {
  getMasterData, setMasterData
}
export default masterDataAction;
