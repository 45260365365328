import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { Autocomplete } from "@mui/material";
import { format } from "date-fns";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import PurchaseUtils from "../../../../provider/PurchaseUtils";
import Utils from "../../../../provider/Utils";
import store from "../../../../redux/store";
import Address from "../../address/Address";
import AuditNoteList from "../../auditNote/AuditNoteList";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import PurchaseInvoiceDialogue from "../../purchase/PurchaseInvoiceDialogue";
import "./ImportPurchaseInvoices.scss";
import ImportPurchaseVoucherItem from "./ImportPurchaseVoucherItem";
export default class ImportPurchaseVoucher extends Component {
  /* References Used */
  itemReferenceList = [];
  billingAddressRef = null;
  shippingAddressRef = null;
  referenceInvoiceDialogueRef = React.createRef();
  menuWithBreadScrumRef = null;
  auditNoteListRef = null;
  maxDate = new Date().getMonth() + 1;
  minDate = new Date().getFullYear() - 1;

  /* Data Used from Store */
  header = store.getState().header.header;
  currencyList = store.getState().staticData.currencyList;
  countryList = store.getState().staticData.countryList;
  defaultCountry = store.getState().appData.settings.defaultCountry;
  defaultCurrency = store.getState().appData.settings.defaultCurrency;
  exportInvoiceTypeList = store.getState().appData.expInvoiceTypeList;
  sezInvoiceTypeList = store.getState().appData.sezInvoiceTypeList;
  /* Data Passed from Parent Component */
  customerList = [];
  businessUnitList = store.getState().organization.organization.businessUnits;
  productList = [];
  custHsnCodeList = [];
  privilegeList = [];
  yesNoList = ["No", "Yes"];
  /* URL for Payment Transaction Component */
  addPaymentTransactionServiceUrl = "/salespaymenttxms/create";
  updatePaymentTransactionServiceUrl = "/salespaymenttxms/update";
  deletePaymentTransactionServiceUrl = "/salespaymenttxms/delete";
  customerAdvanceVoucherList = [];
  title = "Purchase Invoice";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let invoiceId = props.invoiceId;
    let action = props.action;
    let isHistory = props.source && props.source === "history" ? true : false;

    /* Set Data From history */
    if (props.history.location.state) {
    }
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      invoiceId = props.history.location.state.invoiceId;
      action = props.history.location.state.action;
      this.customerList = props.history.location.state.customerList;
      this.productList = props.history.location.state.productList;
      this.custHsnCodeList = props.history.location.state.custHsnCodeList;
      // this.businessUnitList = props.history.location.state.businessUnitList;
      this.breadCrumb = props.history.location.state.breadCrumb;
      isHistory = props.history.location.state.isHistory
        ? props.history.location.state.isHistory
        : false;
    }
    /* Set Empty PurchaseInvoice Data */
    let emptyInvoice = PurchaseUtils.getEmptyInvoice();
    emptyInvoice.id = invoiceId;
    emptyInvoice.action = action;
    emptyInvoice.render = false;
    emptyInvoice.advanceNumber = null;
    this.state = emptyInvoice;
    this.state.isHistory = isHistory;
    this.state.isimported = true;
    this.state.bu =
      this.businessUnitList && this.businessUnitList.length > 0
        ? this.businessUnitList[0]
        : null;
    // this.state.invoicetype = {
    //   type: 'SA',
    // };
    /*If Create Operation then Show Form Directly */
    if ("create" === action) {
      let year = "";
      let month = "";
      if (Utils.isNotNullAndEmpty(emptyInvoice.invoicedate)) {
        let date = new Date(emptyInvoice.invoicedate, "yyyy-MM-dd");
        year = date.split("-")[0];
        month = Utils.getMonth(date.split("-")[1]);
      }
      this.state.year = year;
      this.state.month = month;
      this.state.render = true;
      let item = PurchaseUtils.createEmptyInvoiceItem();
      this.state.invoiceitems = [];
      this.state.invoiceitems.push(item);
    } else {
      /* For Edit and View get data */
      this.state.render = false;
      this.getImportPurchaseVoucherFromBackend(invoiceId);
    }
  }

  handleSalesThroughECOMChange(e) {
    this.setState({ salesthrecom: e.target.checked });
  }
  handleEtinChange(e) {
    this.setState({ etin: e.target.value });
  }
  getETINDetails(e) {
    let etin = this.state.etin;
    if (this.validateGSTN(etin, "etin", "ETIN")) {
      /*Call Service to Get ETIN Details */
    } else {
    }
  }
  validateGSTN(gstn, elementname, elementlabel) {
    if (null === gstn || gstn.length <= 0) {
      document.getElementById(elementname).focus();
      PtsAlert.error("Enter " + elementlabel);
      return false;
    } else if (gstn.length < 15) {
      document.getElementById(elementname).focus();
      PtsAlert.error(elementlabel + " Must be 15 Characters long");
      return false;
    } else {
      let gstnPattern =
        "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
      let patternArray = gstn.match(gstnPattern);
      if (patternArray === null) {
        PtsAlert.error(
          "Enter Valid " +
          elementlabel +
          " No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
        );
        return false;
      }
      return true;
    }
  }
  editButtonClicked() {
    this.props.history.push({
      pathname: "/importPurchaseVoucherEdit",
      state: {
        invoiceId: this.state.id,
        action: "edit",
        customerList: this.customerList,
        productList: this.productList,
        businessUnitList: this.businessUnitList,
        custHsnCodeList: this.custHsnCodeList,
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
    // this.setState({ action: 'edit' });
  }
  deleteButtonClicked() {
    this.deleteImportPurchaseVoucherInBackend();
  }

  /* Set Invoice Data to Component State */
  setResponseToState(invoice) {
    if (Utils.isNull(invoice.privileges)) {
      invoice.privileges = this.state.privileges;
    }
    if (invoice.invoicedate) {
      try {
        let dat = new Date(invoice.invoicedate);
        let todate = dat.getDate();
        if (todate < 10) {
          todate = "0" + todate;
        }
        let tomonth = dat.getMonth() + 1;
        if (tomonth < 10) {
          tomonth = "0" + tomonth;
        }
        let toyear = dat.getFullYear();
        let original_date = toyear + "-" + tomonth + "-" + todate;
        invoice.invoicedate = original_date;
      } catch (e) {
        invoice.invoicedate = new Date(invoice.invoicedate);
      }
    }
    this.setState({
      auditnotes: invoice.auditnotes,
      bankaccount: invoice.bankaccount,
      barcode: invoice.barcode,
      barcodeImage: invoice.barcodeImage,
      billtoaddress: invoice.billtoaddress,
      shiptoaddress: invoice.shiptoaddress,
      bu: invoice.bu,
      cdnreason: invoice.cdnreason,
      cess: invoice.cess,
      cesspercent: invoice.cesspercent,
      cgst: invoice.cgst,
      createdon: invoice.createdon,
      currency: invoice.currency,
      supplier: invoice.supplier,
      description: invoice.description,
      electronicreference: invoice.electronicreference,
      etin: invoice.etin,
      exchangerate: invoice.exchangerate,
      expinvoicetype: invoice.expinvoicetype,
      freez: invoice.freez,
      fright: invoice.fright,
      gstdate: invoice.gstdate,
      id: invoice.id,
      igst: invoice.igst,
      invnumber: invoice.invnumber,
      invoicedate: invoice.invoicedate,
      invoicesource: invoice.invoicesource,
      invoiceStatusList: invoice.invoiceStatusList,
      isAsset: invoice.isAsset,
      isbillingshippingsame: invoice.isbillingshippingsame,
      isimported: invoice.isimported,
      ismanualrefinv: invoice.ismanualrefinv,
      isnilrated: invoice.isnilrated,
      ispregst: invoice.ispregst,
      isrcmsale: invoice.isrcmsale,
      isRecurring: invoice.isRecurring,
      isroundup: invoice.isroundup,
      issez: invoice.issez,
      isSystemGenerated: invoice.isSystemGenerated,
      loadingpackaging: invoice.loadingpackaging,
      manualrefinv: invoice.manualrefinv,
      miscellaneous: invoice.miscellaneous,
      month: invoice.month,
      payment: invoice.payment,
      paymentTransations: invoice.paymentTransations,
      privileges: invoice.privileges,
      purchasedate: invoice.purchasedate,
      purchaseorder: invoice.purchaseorder,
      referencedInvoice: invoice.referencedInvoice,
      referenceinvnumber: invoice.referenceinvnumber,
      referencingInvoices: invoice.referencingInvoices,
      refinvoice: invoice.refinvoice,
      salesthrecom: invoice.salesthrecom,
      satype: invoice.satype,
      sezinvoicetype: invoice.sezinvoicetype,
      sgst: invoice.sgst,
      sharewithcust: invoice.sharewithcust,
      supplytype: invoice.supplytype,
      status: invoice.status,
      totaladvance: invoice.totaladvance,
      totalamount: invoice.totalamount,
      totalgst: invoice.totalgst,
      totalinwords: invoice.totalinwords,
      totaltaxable: invoice.totaltaxable,
      totaltaxableinwords: invoice.totaltaxableinwords,
      utgst: invoice.utgst,
      year: invoice.year,
      invoicetype: invoice.invoicetype,
      invoiceitems: invoice.invoiceitems,
      render: true,
    });
    this.getMenuIconsAsPerStatusAndPermission();
    if (this.auditNoteListRef && this.auditNoteListRef.current) {
      this.auditNoteListRef.current.setState({
        auditNoteList: this.state.auditnotes,
      });
    }
  }

  /*Handlers for each Element on Component */
  handleModelOpen() {
    this.setState({ isModelOpen: true });
  }

  handleModelClose() {
    this.setState({ isModelOpen: false });
  }
  setMonthYear(invdate) {
    let date = new Date(invdate);
    let year = date.getFullYear();
    let month = Utils.getMonth(date.getMonth() + 1);
    this.setState({ month: month, year: year });
  }
  handleImportPurchaseVoucherDateChange(e) {
    let invdate = format(new Date(e.target.value), "yyyy-MM");
    this.setMonthYear(invdate);
    this.setState({ invoicedate: e.target.value, gstdate: e.target.value });
  }

  handlePODateChange(e) {
    this.setState({ purchasedate: e.target.value });
  }
  handleGstDateChange(e) {
    this.setState({ gstdate: e.target.value });
  }
  handleImportPurchaseVoucherDateBlurChange(e) {
    this.setExchangeRate(this.state.currency);
  }
  handleMiscellaneousChange(e) {
    this.setState({ miscellaneous: e.target.value });
  }
  handleMiscellaneousBlurChange(e) {
    this.doCalculations();
  }

  handleImportPurchaseVoucherNumberChange(e) {
    this.setState({ invnumber: e.target.value });
  }
  handleImportPurchaseVoucherparseFloatOnBlur(e) {
    this.setState({ invnumber: e.target.value });
  }

  handleIsSEZChange(e) {
    if (e.target.checked) {
      this.setState({ issez: "Yes" });
    } else {
      this.setState({ issez: "No" });
    }
  }
  handlePurchaseThroughECOMChange(e) {
    this.setState({ salesthrecom: e.target.checked });
  }
  handleIsAdvanceReceivedChange(e) {
    if (
      Utils.isNull(this.state.supplier) ||
      Utils.isNull(this.state.supplier.id)
    ) {
      PtsAlert.error("Please Select Customer");
      document.getElementById("customer").focus();
      return;
    }
    if (e.target.checked === true) {
      /*Get Advance Voucher for Customer*/
      let postObject = {
        supplier: {
          id: this.state.supplier.id,
        },
        header: this.header,
      };
      this.setState({ isAdvanceReceived: e.target.checked });
      FetchServerData.callPostService(
        "/customerms/getCustReceipts",
        postObject
      ).then((output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          this.customerAdvanceVoucherList = output.data.invoices;
          this.setState({ render: true });
        } else {
        }
      });
    } else {
      this.customerAdvanceVoucherList = [];
      this.setState({ isAdvanceReceived: e.target.checked, refinvoice: null });
    }
  }
  handleAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }
  handleIsNillRatedChange(e) {
    if (e.target.checked) {
      this.setState({ isnilrated: "Yes" });
    } else {
      this.setState({ isnilrated: "No" });
    }
  }

  handleBillingShippingSame(e) {
    if (e.target.checked) {
      this.setState({ isbillingshippingsame: "Yes" });
    } else {
      this.setState({ isbillingshippingsame: "No" });
    }
  }
  handleShareWithCustomerChange(e) {
    this.setState({ sharewithcust: e.target.checked });
  }
  handleIsSupplierChange(e) {
    let supplier = this.state.supplier;
    if (e.target.checked === false) {
      supplier = null;
    }
    this.setState({ isSupplier: e.target.checked, supplier: supplier });
  }
  handleIsRecurringChange(e) {
    this.setState({ isRecurring: e.target.checked });
  }

  handleRemarkChange(e) {
    this.setState({ description: e.target.value });
  }

  handleExchangeRateChange(e) {
    this.setState({ exchangerate: e.target.value });
  }

  handlePurchaseOrderChange(e) {
    this.setState({ purchaseorder: e.target.value });
  }

  addAuditNote(e) {
    this.setState({ auditNote: e.target.value });
  }
  updateCustomer(e, customer) {
    if (Utils.isNotNull(customer.addressvo)) {
      this.setState({ supplier: customer, billtoaddress: customer.addressvo });
      if (
        Utils.isNotNull(customer.addressvo.statevo) &&
        Utils.isNotNull(customer.addressvo.statevo.countryvo)
      ) {
        this.setState({
          currency: customer.addressvo.statevo.countryvo.currency,
        });
        this.setExchangeRateForCustomer(customer);
      }
    } else {
      this.setState({ supplier: customer });
    }
  }

  updateBusinessUnit(e, bu) {
    this.setState({ bu: bu });
  }

  updateAdvanceVoucher(e, av) {
    this.setState({ advanceNumber: av });
  }
  updateReferenceAdvanceVoucher(e, voucher) {
    this.setState({
      refinvoice: voucher,
      referenceinvnumber: voucher.invnumber,
    });
  }
  updateSezInvoiceType(e, seztype) {
    this.setState({ sezinvoicetype: seztype });
  }
  setExchangeRate(currency) {
    if (Utils.isNull(currency)) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCurrency(currency, this.defaultCurrency)
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let base = this.defaultCurrency.isocode;
    let invoiceDate = this.state.invoicedate;
    if (
      !Utils.isNotNullAndEmpty(currency) ||
      !Utils.isNotNullAndEmpty(invoiceDate)
    ) {
      return;
    }
    if (!Utils.isSupportedCurrency(currency.isocode)) {
      PtsAlert.warn('Currency "' + currency.name + '" Not Supported');
      return;
    }

    let postObject = {
      targetCurrency: currency.isocode,
      currencyRate: {
        basecurrency: base,
        date: invoiceDate,
      },
      header: this.header,
    };

    FetchServerData.callPostService(
      "/exchangeratems/getExchangeRate",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        // let rates = JSON.parse(output.data.rates);
        let rate = output.data;
        if (Utils.isNotNull(rate.value)) {
          let value = rate.value;
          let exchangeRate = 1 / parseFloat(value);
          this.setState({ exchangerate: exchangeRate });
          return;
        } else {
          this.setState({ exchangerate: "" });
        }
      } else {
      }
    });
  }
  setExchangeRateForCustomer(customer) {
    if (
      Utils.isNull(customer) ||
      Utils.isNull(customer.addressvo) ||
      Utils.isNull(customer.addressvo.statevo) ||
      Utils.isNull(customer.addressvo.statevo.countryvo)
    ) {
      return;
    }
    if (
      Utils.isExchangeRateApplicableForCountry(
        customer.addressvo.statevo.countryvo,
        this.defaultCountry
      )
    ) {
      this.setState({ isexport: true });
    } else {
      this.setState({ exchangerate: 1, isexport: false });
      return;
    }
    let currency = customer.addressvo.statevo.countryvo.currency;
    this.setExchangeRate(currency);
  }

  updateExportType(e, expType) {
    this.setState({ expinvoicetype: expType });
  }
  updateCurrency(e, currency) {
    this.state.currency = currency;
    if (currency.isocode === this.defaultCurrency.isocode) {
    } else {
      this.setExchangeRate(currency);
    }
    this.setState({ currency: currency });
    this.doCalculations();
  }

  doCalculations() {
    let totalAmount = 0.0;
    let totalTaxable = 0.0;
    let totalCgst = 0.0;
    let totalSgst = 0.0;
    let totalIgst = 0.0;
    let totalCess = 0.0;

    for (let i = 0; i < this.itemReferenceList.length; i++) {
      if (this.itemReferenceList[i] && this.itemReferenceList[i].current) {
        let taxable = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.taxable
        );
        let cgst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.cgst
        );
        let sgst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.sgst
        );
        let igst = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.igst
        );
        let cess = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.cess
        );
        let total = Utils.getFloatValue(
          this.itemReferenceList[i].current.state.total
        );
        if (cess > 0) {
          let calculatedCess = (parseFloat(taxable) * parseFloat(cess)) / 100;
          totalCess = parseFloat(totalCess) + parseFloat(calculatedCess);
        }
        totalTaxable = parseFloat(totalTaxable) + parseFloat(taxable);
        totalCgst = parseFloat(totalCgst) + parseFloat(cgst);
        totalSgst = parseFloat(totalSgst) + parseFloat(sgst);
        totalIgst = parseFloat(totalIgst) + parseFloat(igst);
        total =
          parseFloat(taxable) +
          parseFloat(cgst) +
          parseFloat(sgst) +
          parseFloat(igst) +
          parseFloat(totalCess);
        totalAmount = parseFloat(totalAmount) + parseFloat(total);
      }
    }
    let miscellaneous = Utils.getFloatValue(this.state.miscellaneous);
    totalAmount = parseFloat(totalAmount) + parseFloat(miscellaneous);

    // Utils.getAmountInWords(totalAmount, this.state.currency).then((output) => {
    //   this.setState({ totalinwords: output });
    // });
    let amountInWords = Utils.getAmountInWords(
      totalAmount,
      this.state.currency
    );

    /* Advance Amount */
    let advanceUsed = 0.0;
    let gstUsed = 0.0;
    if (Utils.isNotNull(this.state.refinvoice)) {
      let remainingAdvanceTaxable =
        parseFloat(this.state.refinvoice.totaltaxable) -
        parseFloat(this.state.refinvoice.utilizedAmount);
      let advanceVoucherGst =
        parseFloat(
          this.state.refinvoice.cgst ? this.state.refinvoice.cgst : 0.0
        ) +
        parseFloat(
          this.state.refinvoice.sgst ? this.state.refinvoice.sgst : 0.0
        ) +
        parseFloat(
          this.state.refinvoice.igst ? this.state.refinvoice.igst : 0.0
        );

      let remainingAdvanceGst =
        parseFloat(advanceVoucherGst) -
        parseFloat(this.state.refinvoice.utilizedGst);

      let currentInvoiceGst =
        parseFloat(totalCgst) + parseFloat(totalSgst) + parseFloat(totalIgst);
      if (parseFloat(totalTaxable) >= parseFloat(remainingAdvanceTaxable)) {
        advanceUsed = parseFloat(remainingAdvanceTaxable);
      } else {
        advanceUsed = parseFloat(totalTaxable);
      }

      if (parseFloat(currentInvoiceGst) >= parseFloat(remainingAdvanceGst)) {
        gstUsed = parseFloat(remainingAdvanceGst);
      } else {
        gstUsed = parseFloat(currentInvoiceGst);
      }
      // advanceUsed = parseFloat(this.state.totaltaxable) - parseFloat(remainingAdvanceTaxable);
      // gstUsed = parseFloat(currentInvoiceGst) - parseFloat(remainingAdvanceGst);
      //let remainingAdvanceGst = parseFloat(advanceVoucherGst) - parseFloat(remainingAdvanceGst);
    }

    this.setState({
      totaltaxable: parseFloat(totalTaxable).toFixed(2),
      sgst: parseFloat(totalSgst).toFixed(2),
      cgst: parseFloat(totalCgst).toFixed(2),
      igst: parseFloat(totalIgst).toFixed(2),
      cess: parseFloat(totalCess).toFixed(2),
      totalamount: parseFloat(totalAmount).toFixed(2),
      totalinwords: amountInWords,
      advanceAmountUsed: advanceUsed,
      advanceGstUsed: gstUsed,
    });
  }

  invoiceItemChanged(item) {
    this.doCalculations();
  }
  invoiceItemDeleted(item) {
    if (!item.operation || "update" === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index - 1;
      let items = [];
      for (let i = 0; i < this.state.invoiceitems.length; i++) {
        let item = this.state.invoiceitems[i];
        if (index === i) {
          item.operation = "delete";
        }
        items.push(item);
      }
      this.setState({ invoiceitems: items });
    }
    if ("add" === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index - 1;
      let items = [];
      for (let i = 0; i < this.state.invoiceitems.length; i++) {
        if (index === i) {
          this.itemReferenceList.splice(i, 1);
          continue;
        }
        items.push(this.state.invoiceitems[i]);
      }
      this.setState({ invoiceitems: items });
    }
  }

  createAddressObject(address) {
    let addressvo = {
      id: address.id,
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      pin: address.pincode,
      statevo: {
        id: address.state.id,
        name: address.state.name,
        code: address.state.code,
        countryvo: {
          id: address.country.id,
          name: address.country.name,
          code: address.country.code,
        },
      },
    };

    return addressvo;
  }
  /* Validator */
  validateImportPurchaseVoucher(e) {
    e.preventDefault();
    /* Validate Items */
    for (let k = 0; k < this.state.invoiceitems.length; k++) {
      if (this.itemReferenceList[k] && this.itemReferenceList[k].current) {
        /* Set latest Values to Object */
        let currentItem = PurchaseUtils.createInvoiceItem(
          this.itemReferenceList[k].current.state
        );
        this.state.invoiceitems[k] = currentItem;
        if (!this.itemReferenceList[k].current.validateItem()) {
          return false;
        }
      }
    }
    let isBillingShippingSame = this.state.isbillingshippingsame;
    if (Utils.equalsIgnoreCase(isBillingShippingSame, "No")) {
      if (this.shippingAddressRef && this.shippingAddressRef.current) {
        /*Validate Shipping Address */
        if (!this.shippingAddressRef.current.validateAddress()) {
          return false;
        } else {
          let shippingAddress = this.createAddressObject(
            this.shippingAddressRef.current.state
          );
          this.state.shiptoaddress = shippingAddress;
        }
      } else {
        PtsAlert.error("Shipping Address: not provided");
        return;
      }
    }

    if (Utils.isNull(this.state.invoicedate)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Purchase Invoice Date is required");
      return false;
    } else {
      let currentDate = new Date();
      let invDate = new Date(this.state.invoicedate);
      let Difference_In_Time = currentDate.getTime() - invDate.getTime();
      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 540) {
        PtsAlert.error("Purchase Invoice Date is too old");
        return false;
      }

      Difference_In_Time = invDate.getTime() - currentDate.getTime();
      // To calculate the no. of days between two dates
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 31) {
        PtsAlert.error("Future Purchase Invoice Date");
        return false;
      }
    }

    if (Utils.isNull(this.state.gstdate)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Purchase Invoice GST Date is required");
      return false;
    }
    if (Utils.isNull(this.state.invnumber)) {
      // document.getElementById('expdate').focus();
      PtsAlert.error("Purchase Invoice Number is required");
      return false;
    }

    if (Utils.isNull(this.state.bu)) {
      PtsAlert.error("Please Select Business Unit ");
      return false;
    }

    if (
      Utils.isNotNull(this.state.isAdvanceReceived) &&
      this.state.isAdvanceReceived
    ) {
      if (
        Utils.isNull(this.state.advanceNumber) ||
        Utils.isNull(this.state.refinvoice)
      ) {
        // document.getElementById('advanceReference').focus();
        PtsAlert.error("Please Select Reference Advance Voucher");
        return false;
      }
    }
    return true;
  }

  /* Backend Call to Populate Advance Voucher */
  populateAdavnceVoucher(e) {
    if (
      Utils.isNull(this.state.advanceNumber) ||
      Utils.isNull(this.state.advanceNumber.id)
    ) {
      return;
    }
    const postObject = {
      // invoice: { id: this.state.advanceNumber.id },
      invoiceid: this.state.advanceNumber.id,
      header: this.header,
    };
    FetchServerData.callPostService("/rems/get", postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let receipt = output.data;
        let newItemList = [];
        this.setState({ invoiceitems: [] });
        for (let i = 0; i < receipt.invoiceitems.length; i++) {
          let item = receipt.invoiceitems[i];
          item.id = null;
          item.quantity = 1;
          item.rate = item.taxable;
          item.operation = "add";
          newItemList.push(item);
          // this.itemReferenceList.push(React.createRef());
        }
        this.setState({
          refinvoice: receipt,
          invoiceitems: newItemList,
          referenceinvnumber: receipt.invnumber,
        });
        this.doCalculations();
      } else {
      }
    });
  }
  /* Backend Calls to CREATE/GET/UPDATE/DELETE PurchaseInvoice */
  deleteImportPurchaseVoucherInBackend() {
    const postObject = {
      invoiceid: this.state.id,
      header: this.header,
    };

    FetchServerData.callPostService("/purchaseimpexp/delete", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success(
            "Imported Purchase Invoice " + this.state.invnumber + "Deleted "
          );
          this.props.history.push({
            pathname: "/purchaseImport",
            state: {
              invoiceId: this.state.id,
              action: "view",
              breadCrumb: this.breadCrumb,
            },
          });
        } else {
        }
      }
    );
  }

  importPurchaseVoucherIntoMain(id) {
    const postObject = {
      invoice: { id: this.state.id },
      header: this.header,
    };
    FetchServerData.callPostService("/purchaseimpexp/imp", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          // let invoice = output.data;
          this.cancelView();
        } else {
        }
      }
    );
  }

  getImportPurchaseVoucherFromBackend(id) {
    const postObject = {
      invoiceid: id,
      header: this.header,
    };
    FetchServerData.callPostService("/purchaseimpexp/get", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          if (Utils.isNotNull(invoice.refinvoice)) {
            let advanceNumber = {
              id: invoice.refinvoice.id,
              invoiceNumber: invoice.refinvoice.invnumber,
            };

            this.setState({
              isAdvanceReceived: true,
              advanceNumber: advanceNumber,
            });
          }

          /*Set Business Unit*/
          let bu = this.state.bu;
          if (Utils.isNullOrEmpty(bu)) {
            bu =
              this.businessUnitList && this.businessUnitList.length > 0
                ? this.businessUnitList[0]
                : null;
          }
          invoice.bu = bu;
          for (
            let i = 0;
            invoice.invoiceitems && i < invoice.invoiceitems.length;
            i++
          ) {
            // let ref = React.createRef();
            // this.itemReferenceList.push(ref);
            invoice.invoiceitems[i].operation = "update";
          }

          this.privilegeList = invoice.privileges;

          // this.shippingAddressRef = React.createRef;
          // this.billingAddressRef = React.createRef;
          this.setResponseToState(invoice);
          /*Set Title */
          if (invoice.invoicetype) {
            this.title = invoice.invoicetype.description;
            if (
              this.menuWithBreadScrumRef &&
              this.menuWithBreadScrumRef.current
            ) {
              // this.menuWithBreadScrumRef.current.state.name = this.title;
              this.menuWithBreadScrumRef.current.setState({
                name: this.title,
                addToBreadCrumb: this.title,
              });
            }
          }
        } else {
        }
      }
    );
  }

  updateImportPurchaseVoucherInBackend(e) {
    if (!this.validateImportPurchaseVoucher(e)) {
      return;
    }

    let invoiceData = {};
    invoiceData = PurchaseUtils.setImportInvoice(invoiceData, this.state);
    invoiceData.isAdvanceReceived = this.state.isAdvanceReceived;
    const postObject = {
      invoice: invoiceData,
      header: this.header,
    };


    FetchServerData.callPostService("/purchaseimpexp/update", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          PtsAlert.success("Updated Successfully");
          this.props.history.push({
            pathname: "/importPurchaseVoucher",
            state: {
              invoiceId: this.state.id,
              action: "view",
              customerList: this.customerList,
              productList: this.productList,
              businessUnitList: this.businessUnitList,
              custHsnCodeList: this.custHsnCodeList,
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  createImportPurchaseVoucherInBackend(e) {
    e.preventDefault();
    if (!this.validateImportPurchaseVoucher(e)) {
      return;
    }
    let invoiceData = {};
    invoiceData = PurchaseUtils.setInvoice(invoiceData, this.state);

    const postObject = {
      invoice: invoiceData,
      header: this.header,
    };
    FetchServerData.callPostService("/purchaseimpexp/create", postObject).then(
      (output) => {

        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          for (let i = 0; i < invoice.invoiceitems.length; i++) {
            invoice.invoiceitems[i].operation = "update";
          }
          this.setResponseToState(invoice);
          this.props.history.push({
            pathname: "/importPurchaseVoucher",
            state: {
              invoiceId: this.state.id,
              action: "view",
              customerList: this.customerList,
              productList: this.productList,
              businessUnitList: this.businessUnitList,
              custHsnCodeList: this.custHsnCodeList,
              breadCrumb: this.breadCrumb,
              isHistory: this.state.isHistory,
            },
          });
        } else {
        }
      }
    );
  }

  /* Go Back Functionality Implementation */
  cancelEdit(e) {
    // this.setState({ action: 'view', render: false });
    // this.getImportPurchaseVoucherFromBackend(this.state.id);
    // this.setState({ action: 'view' });
    this.props.history.push({
      pathname: "/importPurchaseVoucher",
      state: {
        invoiceId: this.state.id,
        action: "view",
        customerList: this.customerList,
        productList: this.productList,
        businessUnitList: this.businessUnitList,
        custHsnCodeList: this.custHsnCodeList,
        breadCrumb: this.breadCrumb,
        isHistory: this.state.isHistory,
      },
    });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: "/purchaseImport",
      state: { breadCrumb: this.breadCrumb },
    });
  }

  /* Get Bttons Based on PurchaseInvoice Status */
  getButtonsAsPerStatusAndPermission() {
    // let status = this.state.status && this.state.status.status ? this.state.status.status : null;
    // if (null === status) {

    if (
      this.state.action &&
      Utils.equalsIgnoreCase(this.state.action, "view")
    ) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#1b7189",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowUpwardIcon />}
            onClick={(e) => this.importPurchaseVoucherIntoMain(e)}
          >
            Import
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBackIcon />}
            onClick={(e) => this.cancelView(e)}
          >
            Back
          </Button>
        </div>
      );
    }

    // }
  }

  /* Get Menu Buttons based on Invoice Status and Permissions */
  getMenuIconsAsPerStatusAndPermission() {
    let buttonList = [];

    if (
      Utils.equalsIgnoreCase(this.state.action, "edit") ||
      Utils.equalsIgnoreCase(this.state.action, "create")
    ) {
      buttonList = [];
      if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
        this.menuWithBreadScrumRef.current.setState({
          editCallback: null,
          deleteCallback: null,
          printCallback: null,
        });
      }
      return;
    }
    if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
      buttonList = [...buttonList, "EDIT"]
    }
    if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
      buttonList = [...buttonList, "DELETE"]
    }
    // if (this.state.status) {
    //   if (Utils.equalsIgnoreCase(this.state.status.status, "CREATED")) {
    //     if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
    //       buttonList = [...buttonList, "EDIT"]
    //     }
    //     if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
    //       buttonList = [...buttonList, "DELETE"]
    //     }
    //   } else if (
    //     Utils.equalsIgnoreCase(
    //       this.state.status.status,
    //       "VERIFICATIONREQUESTED"
    //     )
    //   ) {
    //     // buttonList = ['PRINT'];
    //   } else if (
    //     Utils.equalsIgnoreCase(this.state.status.status, "VERIFICATIONREJECTED")
    //   ) {
    //     // buttonList = ['PRINT'];
    //   } else {
    //     // buttonList = ['PRINT'];
    //   }
    // }
    // if (
    //   Utils.hasPrivilege("UPDATE", this.state.privileges) &&
    //   Utils.hasPrivilege("DELETE", this.state.privileges)
    // ) {
    // } else if (Utils.hasPrivilege("UPDATE", this.state.privileges)) {
    //   for (let i = 0; i < buttonList.length; i++) {
    //     if (buttonList[i] === "DELETE") {
    //       buttonList.splice(i, 1);
    //     }
    //   }
    // } else if (Utils.hasPrivilege("DELETE", this.state.privileges)) {
    //   for (let i = 0; i < buttonList.length; i++) {
    //     if (buttonList[i] === "UPDATE") {
    //       buttonList.splice(i, 1);
    //     }
    //   }
    // }
    if (this.menuWithBreadScrumRef && this.menuWithBreadScrumRef.current) {
      let editCallback = null;
      let deleteCallback = null;
      let printCallback = null;

      if (buttonList.includes("EDIT")) {
        editCallback = this.editButtonClicked.bind(this);
      }

      if (buttonList.includes("DELETE")) {
        deleteCallback = this.deleteButtonClicked.bind(this);
      }

      if (buttonList.includes("PRINT")) {
        printCallback = this.printButtonClicked.bind(this);
      }

      this.menuWithBreadScrumRef.current.setState({
        editCallback: editCallback,
        deleteCallback: deleteCallback,
        printCallback: printCallback,
      });
    }
  }
  getAdvanceVoucherDetails() {
    if (
      true === this.state.isAdvanceReceived &&
      Utils.isNotNullAndEmpty(this.state.refinvoice)
    ) {
      return (
        <>
          <Row md={12} style={{ padding: "5px", margin: "0" }}>
            <Col
              md={12}
              style={{
                background: "#c5dde4",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
              }}
            >
              Advance Voucher Details
            </Col>
          </Row>

          <Row md={12} style={{ padding: "5px", margin: "0" }}>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Advance Amt.
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              CESS %
            </Col>

            {Utils.isIgstApplicable(this.state.supplier) !== true && (
              <>
                <Col
                  md={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  CGST
                </Col>
                <Col
                  md={1}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                    textAlign: "right",
                  }}
                >
                  SGST
                </Col>
              </>
            )}

            {Utils.isIgstApplicable(this.state.supplier) !== false && (
              <Col
                md={2}
                style={{
                  background: "#ececec",
                  color: "#000",
                  fontWeight: "bold",
                  border: "0.5px solid #ece6e6",
                  padding: "0.5em",
                  textAlign: "right",
                }}
              >
                IGST
              </Col>
            )}

            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Total
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Utilized GST
            </Col>
            <Col
              md={2}
              style={{
                background: "#ececec",
                color: "#000",
                fontWeight: "bold",
                border: "0.5px solid #ece6e6",
                padding: "0.5em",
                textAlign: "right",
              }}
            >
              Utilized Taxable
            </Col>
          </Row>
          <Row md={12} style={{ padding: "5px", margin: "0" }}>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.totaltaxable}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.cess}
            </Col>

            {Utils.isIgstApplicable(this.state.supplier) !== true && (
              <>
                <Col
                  md={1}
                  style={{
                    border: "0.5px solid #ece6e6",
                    textAlign: "right",
                    color: "#000",
                  }}
                >
                  {this.state.refinvoice.cgst}
                </Col>
                <Col
                  md={1}
                  style={{
                    border: "0.5px solid #ece6e6",
                    textAlign: "right",
                    color: "#000",
                  }}
                >
                  {this.state.refinvoice.sgst}
                </Col>
              </>
            )}

            {Utils.isIgstApplicable(this.state.supplier) !== false && (
              <Col
                md={2}
                style={{
                  border: "0.5px solid #ece6e6",
                  textAlign: "right",
                  color: "#000",
                }}
              >
                {this.state.refinvoice.igst}
              </Col>
            )}

            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.totalamount}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.utilizedGst}
            </Col>
            <Col
              md={2}
              style={{
                border: "0.5px solid #ece6e6",
                textAlign: "right",
                color: "#000",
              }}
            >
              {this.state.refinvoice.utilizedAmount}
            </Col>
          </Row>
        </>
      );
    } else {
      return "";
    }
  }

  /*Main Render Function */
  render() {
    if (this.state.render === false) {
      return "";
    }
    /* Create References for Calculations */
    this.billingAddressRef = React.createRef();
    this.shippingAddressRef = React.createRef();
    this.menuWithBreadScrumRef = React.createRef();
    this.auditNoteListRef = React.createRef();
    this.state.invoiceitems &&
      this.state.invoiceitems.forEach((element, index) => {
        this.itemReferenceList[index] = React.createRef();
      });
    if (this.state.action === "edit") {
      return this.editImportPurchaseVoucher();
    } else {
      return this.viewImportPurchaseVoucher();
    }
  }

  editImportPurchaseVoucher() {
    let invoiceItemsList = [];
    /* Create List of PurchaseVoucher Item */
    let isIgstApplicable = Utils.isIgstApplicable(this.state.supplier);
    this.state.invoiceitems &&
      this.state.invoiceitems.forEach((element, index) => {
        if ("delete" !== element.operation) {
          invoiceItemsList.push(
            <ImportPurchaseVoucherItem
              ref={this.itemReferenceList[index]}
              key={index}
              isIgstApplicable={isIgstApplicable}
              data={element}
              srno={index}
              action="edit"
              productList={this.productList}
              custHsnCodeList={this.custHsnCodeList}
              doCalculations={this.doCalculations.bind(this)}
              onDelete={this.invoiceItemDeleted.bind(this)}
              onChange={this.invoiceItemChanged.bind(this)}
            />
          );
        }
      });

    let billingAddress = (
      <Address
        ref={this.billingAddressRef}
        title="Billing Address"
        data={this.state.billtoaddress}
        action="view"
      />
    );

    let shippingAddress = "";
    if (Utils.equalsIgnoreCase(this.state.isbillingshippingsame, "No")) {
      shippingAddress = (
        <Address
          ref={this.shippingAddressRef}
          title="Shipping Address"
          data={this.state.shiptoaddress}
          action="create"
        />
      );
    }

    /* Check if Currency Different than Default Currency */
    let isExchangeRateMandator = false;
    if (this.state.supplier && this.state.supplier.addressvo) {
      let address = this.state.supplier.addressvo;
      if (
        (isExchangeRateMandator = Utils.isExchangeRateApplicableForCountry(
          address.statevo.countryvo,
          this.defaultCountry
        ))
      ) {
        this.state.exchangeRate = 1;
        isExchangeRateMandator = true;
      }
    }

    return (
      <>
        <MenuWithBreadScrum
          ref={this.menuWithBreadScrumRef}
          breadCrumb={this.breadCrumb}
          addToBreadCrumb="Edit Purchase Invoice Import"
          history={this.props.history}
          name="Edit Purchase Invoice Import"
        />

        <ValidatorForm
          style={{ width: "100%", color: "#000", padding: "0.5em" }}
          ref="form"
          onSubmit={this.updateImportPurchaseVoucherInBackend.bind(this)}
        >
          <Card md={12}>
            <CardHeader style={{ margin: "0px", padding: "0.5em" }}>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Supplier<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    disableClearable
                    disabled
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    id="supplier"
                    size="small"
                    options={
                      undefined === this.supplierList ||
                        null === this.supplierList
                        ? []
                        : this.supplierList
                    }
                    value={this.state.supplier}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateSupplier(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Supplier"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />

                  {/* <span style={{  }}>{this.state.supplier.name}</span> */}
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Voucher Date<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    id="invoicedate"
                    onChange={this.handleImportPurchaseVoucherDateChange.bind(
                      this
                    )}
                    onBlur={this.handleImportPurchaseVoucherDateBlurChange.bind(
                      this
                    )}
                    value={this.state.invoicedate ? this.state.invoicedate : ""}
                    placeholder="Voucher Date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Voucher Number<span className="asterisk"> *</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      fullWidth={true}
                      variant="outlined"
                      inputProps={{ style: {} }}
                      InputLabelProps={{ style: {}, shrink: true }}
                      id="invnumber"
                      type="text"
                      placeholder="Voucher Number"
                      validators={["required"]}
                      onChange={this.handleImportPurchaseVoucherNumberChange.bind(
                        this
                      )}
                      value={this.state.invnumber}
                      name="invnumber"
                    />
                    {/* <IconButton onClick={(e) => this.generateInvoiceNumber()}>
                      <AutorenewIcon style={{ background: 'green', color: '#fff' }} />
                    </IconButton> */}
                  </div>
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Remark
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    inputProps={{ style: {} }}
                    InputLabelProps={{ style: {}, shrink: true }}
                    id="invoicenote"
                    type="text"
                    placeholder="Remark"
                    validators={["required"]}
                    onChange={this.handleRemarkChange.bind(this)}
                    // onBlur={this.handleNoteOnBlur.bind(this)}
                    value={this.state.description}
                    name="invoicenote"
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Business Unit<span className="asterisk"> *</span>
                  </label>
                  <Autocomplete
                    disableClearable
                    id="businessUnit"
                    ListboxProps={{
                      className: "myCustomList"
                    }}
                    options={this.businessUnitList ? this.businessUnitList : []}
                    value={this.state.bu}
                    defaultValue={this.state.bu}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Business Unit"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isAsset}
                        value={this.state.isAsset}
                        onChange={this.handleIsNillRatedChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Nil Rated?</span>}
                  />
                </Col>
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Purhcase Order<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    variant="outlined"
                    inputProps={{ style: {} }}
                    fullWidth
                    id="po"
                    type="text"
                    placeholder="Purhcase Order"
                    onChange={this.handlePurchaseOrderChange.bind(this)}
                    // onBlur={this.handleGSTNOnBlur.bind(this)}
                    value={
                      this.state.purchaseorder ? this.state.purchaseorder : ""
                    }
                    name="purchaseorder"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Purchase Date<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    id="podate"
                    onChange={this.handlePODateChange.bind(this)}
                    value={
                      this.state.purchasedate ? this.state.purchasedate : " "
                    }
                    placeholder="Purchase Date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    GST Date<span className="asterisk"> *</span>
                  </label>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth={true}
                    type="date"
                    variant="outlined"
                    onChange={this.handleGstDateChange.bind(this)}
                    value={this.state.gstdate}
                    placeholder="GST Date"
                  />
                </Col>

                {isExchangeRateMandator === true && (
                  <>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Currency<span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        disableClearable
                        ListboxProps={{
                          className: "myCustomList"
                        }}
                        style={{ width: "100%", textAlign: "center" }}
                        // className="expenseAutoComplete"
                        id="currency"
                        options={this.currencyList ? this.currencyList : []}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.updateCurrency(event, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            required
                            {...params}
                            className="regCountry"
                            variant="outlined"
                            placeholder="Currency"
                          />
                        )}
                      />
                    </Col>
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        Exchange Rate<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        variant="outlined"
                        required
                        inputProps={{ style: {} }}
                        InputLabelProps={{ style: {}, shrink: true }}
                        fullWidth
                        id="exchangeRate"
                        type="text"
                        placeholder="Exchange Rate"
                        onChange={this.handleExchangeRateChange.bind(this)}
                        // onBlur={this.handleGSTNOnBlur.bind(this)}
                        value={this.state.exchangerate}
                        name="exchangeRate"
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row md={12} style={{ alignItems: "flex-end", margin: "5px" }}>
                {this.state.isAdvanceReceived &&
                  this.state.isAdvanceReceived === true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <FormControlLabel
                        style={{
                          color: "#000",
                          width: "100%",
                          margin: "0",
                          padding: "0",
                        }}
                        control={
                          <Checkbox
                            variant="outlined"
                            disabled
                            id="isAdvanceReceived"
                            label="Is Advance Received"
                            checked={
                              this.state.isAdvanceReceived
                                ? this.state.isAdvanceReceived
                                : false
                            }
                            value={
                              this.state.isAdvanceReceived
                                ? this.state.isAdvanceReceived
                                : false
                            }
                            onChange={this.handleIsAdvanceReceivedChange.bind(
                              this
                            )}
                            style={{ color: "#000", background: "none" }}
                          />
                        }
                        label={<span style={{}}>Is Advance Received?</span>}
                      />
                    </Col>
                  )}
                {this.state.isAdvanceReceived &&
                  this.state.isAdvanceReceived !== true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <FormControlLabel
                        style={{
                          color: "#000",
                          width: "100%",
                          margin: "0",
                          padding: "0",
                        }}
                        control={
                          <Checkbox
                            variant="outlined"
                            id="isAdvanceReceived"
                            label="Is Advance Received"
                            checked={
                              this.state.isAdvanceReceived
                                ? this.state.isAdvanceReceived
                                : false
                            }
                            value={
                              this.state.isAdvanceReceived
                                ? this.state.isAdvanceReceived
                                : false
                            }
                            onChange={this.handleIsAdvanceReceivedChange.bind(
                              this
                            )}
                            style={{ color: "#000", background: "none" }}
                          />
                        }
                        label={<span style={{}}>Is Advance Received?</span>}
                      />
                    </Col>
                  )}
                {this.state.isAdvanceReceived &&
                  this.state.isAdvanceReceived === true && (
                    <>
                      <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                        <label
                          className="form-label "
                          htmlFor="collapsible-companyName"
                        >
                          Advance Voucher<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          ListboxProps={{
                            className: "myCustomList"
                          }}
                          // className="invoiceAutoComplete"
                          // style={{ padding: '0.5em' }}
                          disabled
                          disableClearable
                          id="advanceReference"
                          options={
                            this.supplierAdvanceVoucherList
                              ? this.supplierAdvanceVoucherList
                              : []
                          }
                          value={
                            this.state.advanceNumber
                              ? this.state.advanceNumber
                              : null
                          }
                          getOptionLabel={(option) => option.invoiceNumber}
                          onChange={(event, value) => {
                            this.updateAdvanceVoucher(event, value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              fullWidth={true}
                              {...params}
                              className="regCountry"
                              variant="outlined"
                              placeholder="Advance Voucher"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Col>
                      {/* <Col md={2} style={{ padding: '5px', textAlign: 'left' }}>
                      <Button
                        variant="contained"

                        style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                        startIcon={<SaveIcon />}
                        onClick={(e) => this.populateAdavnceVoucher(e)}
                      >
                        Verify
                      </Button>
                    </Col> */}
                    </>
                  )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSEZDeemedExport"
                        checked={
                          this.state.issez &&
                            Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        value={
                          this.state.issez &&
                            Utils.equalsIgnoreCase(this.state.issez, "Yes")
                            ? true
                            : false
                        }
                        onChange={this.handleIsSEZChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>SEZ/Deemed Export?</span>}
                  />
                </Col>
                {Utils.equalsIgnoreCase(this.state.issez, "Yes") && (
                  <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                    <label
                      className="form-label "
                      htmlFor="collapsible-companyName"
                    >
                      SEZ Category<span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      ListboxProps={{
                        className: "myCustomList"
                      }}
                      disableClearable
                      id="sezCategory"
                      options={this.sezInvoiceTypeList}
                      value={this.state.sezinvoicetype}
                      defaultValue={this.state.sezinvoicetype}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, value) => {
                        this.updateSezInvoiceType(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth={true}
                          {...params}
                          className="regCountry"
                          variant="outlined"
                          placeholder="SEZ Category"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Col>
                )}

                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isSalesThroughECOM"
                        checked={
                          this.state.salesthrecom &&
                            this.state.salesthrecom === true
                            ? true
                            : false
                        }
                        value={
                          this.state.salesthrecom &&
                            this.state.salesthrecom === true
                            ? true
                            : false
                        }
                        onChange={this.handleSalesThroughECOMChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Sales Through ECOM?</span>}
                  />
                </Col>
                {this.state.salesthrecom &&
                  this.state.salesthrecom === true && (
                    <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        ETIN<span className="asterisk"> *</span>
                      </label>
                      <div style={{ display: "flex" }}>
                        <TextField
                          required
                          fullWidth={true}
                          variant="outlined"
                          inputProps={{ style: {} }}
                          id="etin"
                          type="text"
                          placeholder="ETIN"
                          onChange={this.handleEtinChange.bind(this)}
                          value={this.state.etin}
                          name="invnumber"
                          InputLabelProps={{ shrink: true }}
                        />
                        <IconButton onClick={(e) => this.getETINDetails()}>
                          <AutorenewIcon
                            style={{ background: "green", color: "#fff" }}
                          />
                        </IconButton>
                      </div>
                    </Col>
                  )}
                <Col md={2} style={{ padding: "5px", textAlign: "left" }}>
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isAsset}
                        value={this.state.isAsset}
                        onChange={this.handleAssetChange.bind(this)}
                        style={{ color: "#000", background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is Capital Asset?</span>}
                  />
                </Col>
              </Row>

              <Row
                md={12}
                style={{ alignItems: "center", margin: "0", padding: "0" }}
              >
                <Col md={8}></Col>
                <Col
                  md={4}
                  style={{
                    padding: "0",
                    margin: "0",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <span style={{ color: "black" }}>Billing Shipping Same?</span>
                  <Checkbox
                    variant="outlined"
                    id="isBillingShippingSame"
                    label="Is Nill Rated"
                    checked={
                      Utils.equalsIgnoreCase(
                        this.state.isbillingshippingsame,
                        "Yes"
                      )
                        ? true
                        : false
                    }
                    onChange={this.handleBillingShippingSame.bind(this)}
                    style={{ color: "gray", background: "none" }}
                  />
                </Col>
              </Row>
              {this.state.supplier && (
                <Row
                  md={12}
                  style={{ overflow: "scroll", display: "flex", margin: "5px" }}
                >
                  <Col
                    md={4}
                    style={{
                      // paddingLeft: "20px",
                      // margin: "0",
                      // display: "block",
                      // border: "0.5px solid #f3f4f5",
                    }}
                  >
                    <Row md={12} style={{ marginLeft: "-0.3em" }}>
                      <label style={{ fontWeight: "600", color: "#1b7189", fontSize: "16px" }}>
                        <b>Supplier Details</b>
                      </label>
                    </Row>
                    <Row md={12} style={{ marginLeft: "0.5em" }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Name</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",

                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.name}
                      </Col>
                    </Row>
                    <Row md={12} style={{ marginLeft: "0.5em" }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Address</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {Utils.getAddressAsText(this.state.supplier.addressvo)}
                      </Col>
                    </Row>
                    <Row md={12} style={{ marginLeft: "0.5em" }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Supplier Type</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.suppliertypevo.description}
                      </Col>
                    </Row>
                    <Row md={12} style={{ marginLeft: "0.5em" }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>GSTN</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.gstn}
                      </Col>
                    </Row>
                    <Row md={12} style={{ marginLeft: "0.5em" }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>PAN</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.supplier.pan}
                      </Col>
                    </Row>
                    <Row md={12} style={{ marginLeft: "0.5em" }}>
                      <Col
                        md={5}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        <strong>Currency</strong>
                      </Col>
                      <Col
                        md={7}
                        style={{
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.currency ? this.state.currency.name : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={4}
                    style={{
                      paddingLeft: "20px",
                      margin: "0",
                      display: "block",
                      // border: "0.5px solid #f3f4f5",
                    }}
                  >
                    {billingAddress}
                  </Col>
                  <Col
                    md={4}
                    style={{
                      paddingLeft: "20px",
                      margin: "0",
                      display: "block",
                      // border: "0.5px solid #f3f4f5",
                    }}
                  >
                    {/* <Row md={12} style={{ alignItems: 'center', margin: '0', padding: '0' }}> */}
                    {shippingAddress}
                    {/* </Row> */}
                  </Col>
                </Row>
              )}
            </CardHeader>
            <CardBody>
              <Row
                md={12}
                style={{ padding: "5px", margin: "0" }}
              >
                <Col
                  md={12}
                  style={{
                    background: "#ececec",
                    color: "#000",
                    fontWeight: "bold",
                    border: "0.5px solid #ece6e6",
                    padding: "0.5em",
                  }}
                >
                  Purchase Voucher Item Details
                </Col>
                {/* <Col
                  md={1}
                  style={{
                    borderRight: '1px solid #eceaea',
                    background: '#ececec',
                    color: '#000',
                    fontWeight: 'bold',
                    padding: '0.5em',
                    textAlign: 'right',
                  }}
                >
                  <a onClick={this.addPurchaseVoucherItem.bind(this)} style={{ cursor: 'pointer', float: 'right' }}>
                    <i
                      class="fa fa-plus fa-lg"
                      style={{
                        color: '#1da21d',
                        background: '#fff',
                        borderRadius: '50%',

                        padding: '5px',
                      }}
                    ></i>
                  </a>
                </Col> */}
              </Row>
              {invoiceItemsList}

              <Row
                md={12}
                style={{ padding: "0px", margin: "0", marginTop: "1em" }}
              >
                <Col
                  md={8}
                  style={{ margin: "0", padding: "1em" }}
                ></Col>
                <Col md={4} style={{ margin: "0", padding: "0" }}>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Value Of Supply</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {this.state.totaltaxable}
                    </Col>
                  </Row>
                  {Utils.isIgstApplicable(this.state.customerVo) === false && (
                    <>
                      <Row
                        md={12}
                        style={{ padding: "5px", margin: "0" }}
                      >
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            // fontWeight: '600',
                            // //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>CGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.cgst)}
                        </Col>
                      </Row>
                      <Row
                        md={12}
                        style={{ padding: "5px", margin: "0" }}
                      >
                        <Col
                          md={9}
                          style={{
                            color: "#000",
                            // fontWeight: '600',
                            // //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          <strong>SGST</strong>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            color: "#000",
                            //border: '0.5px solid #ece6e6',
                            padding: "0.1em",
                            textAlign: "right",
                          }}
                        >
                          {Utils.getFloatValue(this.state.sgst)}
                        </Col>
                      </Row>
                    </>
                  )}
                  {Utils.isIgstApplicable(this.state.customerVo) === true && (
                    <Row
                      md={12}
                      style={{ padding: "5px", margin: "0" }}
                    >
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // //border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>IGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          //border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.igst)}
                      </Col>
                    </Row>
                  )}

                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>CESS</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.cess)}
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Misc.(If Any)</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <TextField
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        required
                        type="text"
                        // variant="outlined"
                        id="miscellaneous"
                        onChange={this.handleMiscellaneousChange.bind(this)}
                        onBlur={this.handleMiscellaneousBlurChange.bind(this)}
                        value={this.state.miscellaneous}
                      />
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>Total Amount</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        //border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {this.state.currency && this.state.currency.symbol
                        ? this.state.currency.symbol
                        : ""}
                      {Utils.getFloatValue(this.state.totalamount)}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                md={12}
                style={{ padding: "5px", margin: "0" }}
              >
                <Col
                  md={12}
                  style={{
                    // fontWeight: '600',
                    color: "#000",
                    // //border: '0.5px solid #ece6e6',
                    padding: "0.1em",
                    textAlign: "right",
                  }}
                >
                  {this.state.totalinwords}
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row md={12}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<SaveIcon />}
                  // onClick={e => this.formValidation(e)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      background: "#717373",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={(e) => this.cancelEdit()}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </CardFooter>
          </Card>
        </ValidatorForm>
      </>
    );
  }

  showReferenceInvoice() {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current &&
      this.state.referenceinvnumber
    ) {
      this.referenceInvoiceDialogueRef.current.showInvoice(
        this.state.referenceinvnumber,
        this.state.supplier.id
      );
    }
  }
  getHeader() {
    return (
      <Row md={12} style={{ padding: "5px", margin: "0" }}>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          <span style={{ float: "left" }}>#</span>
          <span style={{ float: "right" }}>SAC/HSN</span>
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          Item Details
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
          }}
        >
          UOM
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Qty
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Unit Price
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Gross Value
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Discount
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Net Value
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          CESS %
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Tax Rate %
        </Col>
        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          GST
        </Col>

        <Col
          md={1}
          style={{
            background: "#ececec",
            color: "#000",
            fontWeight: "bold",
            border: "0.5px solid #ece6e6",
            padding: "0.5em",
            textAlign: "right",
          }}
        >
          Total
        </Col>
      </Row>
    );
  }
  viewImportPurchaseVoucher() {
    let invoiceItemsList = [];
    let isIgstApplicable = Utils.isIgstApplicable(this.state.supplier);
    this.state.invoiceitems &&
      this.state.invoiceitems.forEach((element, index) => {
        invoiceItemsList.push(
          <ImportPurchaseVoucherItem
            isIgstApplicable={isIgstApplicable}
            key={index}
            data={element}
            srno={index}
            action="view"
            productList={this.productList}
            custHsnCodeList={this.custHsnCodeList}
            doCalculations={this.doCalculations.bind(this)}
            onDelete={this.invoiceItemDeleted.bind(this)}
            onChange={this.invoiceItemChanged.bind(this)}
          />
        );
      });

    let menuWithBreadScrum = (
      <MenuWithBreadScrum
        ref={this.menuWithBreadScrumRef}
        breadCrumb={this.breadCrumb}
        addToBreadCrumb="View Purchase Invoice Import"
        history={this.props.history}
        name="View Purchase Invoice Import"
        docTitle={this.state.invnumber}
      />
    );

    return (
      <>
        <PurchaseInvoiceDialogue
          ref={this.referenceInvoiceDialogueRef}
          style={{ float: "right" }}
        />

        {menuWithBreadScrum}

        <Card
          md={12}
          style={{ width: "100%", color: "#000", padding: "0.5em" }}
        >
          <CardHeader>
            <Row md={12} style={{ margin: "0", padding: "0", display: "flex" }}>
              <Col
                md={4}
                style={{ padding: "0", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%", padding: "5px" }}>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Name</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.name}
                    </label>
                  </div>

                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Address</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {Utils.getAddressAsText(this.state.supplier.addressvo)}
                    </label>
                  </div>

                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.suppliertypevo.description}
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>GSTN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.gstn}
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PAN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.pan}
                    </label>
                  </div>

                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Purchase Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.supplier.name}
                    </label>
                  </div>

                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Business Unit</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.bu?.name}
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Currency Rate</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.exchangerate}
                    </label>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "0", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%", padding: "5px" }}>
                  <Row
                    md={12}
                    style={{
                      width: "100%",
                      height: "50%",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Address
                      ref={this.billingAddressRef}
                      title="Billing Address"
                      data={this.state.billtoaddress}
                      action="view"
                    />
                  </Row>
                  {/* </Col>
              <Col md={3} style={{ padding: '5px', margin: '0', display: 'flex' }}> */}
                  <Row
                    md={12}
                    style={{
                      width: "100%",
                      height: "50%",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Address
                      ref={this.shippingAddressRef}
                      title="Shipping Address"
                      data={this.state.shiptoaddress}
                      action="view"
                    />
                  </Row>
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "0", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%", padding: "5px" }}>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Created On</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* { this.state.createdon ? format(new Date(), 'yyyy-MM-dd') : ''} */}
                      {Utils.ptsDateFormat(this.state.createdon)}
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Voucher Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoicedate} */}
                      {Utils.ptsDateFormat(this.state.invoicedate)}
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>GST Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.gstdate} */}
                      {Utils.ptsDateFormat(this.state.gstdate)}
                    </label>
                  </div>

                  {(this.state.isimported === true ||
                    Utils.equalsIgnoreCase(this.state.isimported, "true")) && (
                      <div style={{ width: "100%" }}>
                        <label
                          style={{
                            color: "#000",
                            width: "40%",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          <strong>Is Import</strong>
                        </label>
                        <label
                          style={{
                            width: "60%",
                            color: "#000",
                            margin: "0",
                            textAlign: "left",
                          }}
                        >
                          Yes
                        </label>
                      </div>
                    )}
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Status</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.status && this.state.status.description
                        ? this.state.status.description
                        : ""}
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PO </strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.purchaseorder ? this.state.purchaseorder : ""}
                    </label>
                  </div>

                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>PO Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.purchasedate ? this.state.purchasedate : ''} */}
                      {Utils.ptsDateFormat(this.state.purchasedate)}
                    </label>
                  </div>

                  {this.state.salesthrecom &&
                    this.state.salesthrecom === true && (
                      <>
                        <div style={{ width: "100%" }}>
                          <label
                            style={{
                              color: "#000",
                              width: "40%",
                              margin: "0",
                              padding: "0",
                              textAlign: "left",
                            }}
                          >
                            <strong>Purchase Thr. ECOM </strong>
                          </label>
                          <label
                            style={{
                              width: "60%",
                              color: "#000",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Yes
                          </label>
                        </div>

                        <div style={{ width: "100%" }}>
                          <label
                            style={{
                              color: "#000",
                              width: "40%",
                              margin: "0",
                              padding: "0",
                              textAlign: "left",
                            }}
                          >
                            <strong>ETIN </strong>
                          </label>
                          <label
                            style={{
                              width: "60%",
                              color: "#000",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {this.state.etin
                              ? this.state.etin.toUpperCase()
                              : ""}
                          </label>
                        </div>
                      </>
                    )}
                  <div style={{ width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      <strong>Reference</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={this.showReferenceInvoice.bind(this)}
                      >
                        <strong>
                          {this.state.referenceinvnumber
                            ? this.state.referenceinvnumber
                            : ""}
                        </strong>
                      </Link>
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <table>
              <thead>
                <tr md={12} style={{ padding: "0px", margin: "0" }}>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    <span style={{ float: "left" }}>#</span>
                    <span style={{ float: "right" }}>SAC/HSN</span>
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "20%",
                    }}
                  >
                    Item Details
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    UOM
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "3%",
                    }}
                  >
                    Qty
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "8%",
                    }}
                  >
                    Unit Price
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Gross Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Discount %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Net Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "5%",
                    }}
                  >
                    CESS %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    Tax Rate %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    GST
                  </td>

                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Total
                  </td>
                </tr>
              </thead>
              {invoiceItemsList}
            </table>
            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <Col
                md={8}
                style={{ margin: "0", padding: "1em" }}
              >
                {/* <Row md={12} style={{ flexDirection: 'column' }}>
                  <PaymentTransactionList
                    style={{ width: '100%' }}
                    data={this.state.paymentTransations}
                    aid={this.state.id}
                    title="Payments"
                    type="Invoice"
                    history={this.props.history}
                    onPaymentChange={this.onPaymentChange.bind(this)}
                    addPaymentTransactionServiceUrl={this.addPaymentTransactionServiceUrl}
                    updatePaymentTransactionServiceUrl={this.updatePaymentTransactionServiceUrl}
                    deletePaymentTransactionServiceUrl={this.deletePaymentTransactionServiceUrl}
                  />
                </Row> */}
                {this.state.status &&
                  Utils.equalsIgnoreCase(
                    "VERIFICATIONREQUESTED",
                    this.state.status ? this.state.status.status : ""
                  ) && (
                    <Row md={12} style={{ flexDirection: "column" }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="auditnote"
                        type="text"
                        variant="outlined"
                        label="Audit Note"
                        multiline={true}
                        rows={2}
                        value={
                          this.state.auditNote ? this.state.auditNote : " "
                        }
                        onChange={this.addAuditNote.bind(this)}
                      />
                    </Row>
                  )}
                <Row md={12} style={{ flexDirection: "column" }}>
                  <AuditNoteList
                    ref={this.auditNoteListRef}
                    data={this.state.auditnotes}
                    aid={this.state.id}
                    title="Audit Notes"
                    history={this.props.history}
                    addPaymentTransactionServiceUrl={
                      this.addPaymentTransactionServiceUrl
                    }
                    updatePaymentTransactionServiceUrl={
                      this.updatePaymentTransactionServiceUrl
                    }
                    deletePaymentTransactionServiceUrl={
                      this.deletePaymentTransactionServiceUrl
                    }
                  />
                </Row>
              </Col>
              <Col md={4} style={{ margin: "0", padding: "0" }}>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Value Of Supply</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.totaltaxable)}
                  </Col>
                </Row>
                {Utils.isIgstApplicable(this.state.supplier) === false && (
                  <>
                    <Row
                      md={12}
                      style={{ padding: "5px", margin: "0" }}
                    >
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>CGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.cgst)}
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      style={{ padding: "5px", margin: "0" }}
                    >
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>SGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.sgst)}
                      </Col>
                    </Row>
                  </>
                )}

                {Utils.isIgstApplicable(this.state.supplier) === true && (
                  <Row
                    md={12}
                    style={{ padding: "5px", margin: "0" }}
                  >
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>IGST</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.igst)}
                    </Col>
                  </Row>
                )}

                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>CESS</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.cess)}
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Misc.(If Any)</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.miscellaneous)}
                  </Col>
                </Row>
                <Row
                  md={12}
                  style={{ padding: "5px", margin: "0" }}
                >
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Total Amount</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.currency.symbol !== null
                      ? this.state.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.totalamount)}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              md={12}
              style={{ padding: "5px", margin: "0" }}
            >
              <Col
                md={12}
                style={{
                  color: "#000",
                  padding: "0.1em",
                  textAlign: "right",
                }}
              >
                {this.state.totalinwords}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row md={12}>{this.getButtonsAsPerStatusAndPermission()}</Row>
          </CardFooter>
        </Card>
      </>
    );
  }
}
