import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
// import TextField from "@material-ui/core/TextField";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
// import Visibility from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import purchaseImportFile from "../../../../assets/importTemplate/Purchase_Invoice_Import.xlsx";
export class ImportPurchaseInvoicesList extends Component {
  header = store.getState().header.header;
  purchaseFileUploadRef = React.createRef();
  selectAllCheckboxRef = React.createRef();
  invoiceTypeList = ["All", "B2B", "IMPORT", "AT", "CDNR"];
  breadCrumb = [
    { pageid: "/import", uiname: "Import", cssclass: "fa fa-upload" },
    { pageid: "/purchaseImport", uiname: "Purchase Invoice Import List" },
  ];
  title = "Purchase Invoice Import List";
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      selectedFile: { name: "" },
      showAddsalesInvoice: false,
      salesInvoiceList: [],
      selectedItems: [],
      pageSize: 10,
      isSelectAll: false,
      render: true,
    };
    this.getPurchaseInvoiceData();
  }
  componentDidMount() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/masterms/getMasterData", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let masterData = output.data;
          this.supplierList = masterData.suppliers;
          this.productList = masterData.products;
          this.custHsnCodeList = masterData.custhsncodes;
          this.bankAccountList = masterData.bankaccounts;
          this.businessUnitList = masterData.businessUnits;
          this.setState({ render: true });
        }
      }
    );
  }
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = (e) => {
    // Create an object of formData
    e.preventDefault();
    if (!this.state.selectedFile.name || this.state.selectedFile.name === "") {
      PtsAlert.error("Please select file to upload");
      return;
    }
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("sid", this.header.sessionid);
    formData.append("tid", this.header.tid);
    formData.append("uid", this.header.email);
    formData.append("appclient", this.header.appclient);

    FetchServerData.callPostFileUpload(
      "/bulkimport/purchaseInvoice",
      formData
    ).then((output) => {
      if (output.status === "SUCCESS") {
        this.setState({ selectedFile: null });
        this.getPurchaseInvoiceData();
      }
      // this.setState({ selectedFile: '' });
      if (this.purchaseFileUploadRef) {
        this.purchaseFileUploadRef.current.value = null;
        this.purchaseFileUploadRef.current.value = "";
        // this.state.selectedFile.name = '';
      }
    });
  };

  getPurchaseInvoiceData() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService("/purchaseimpexp/getall", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let salesInvoiceList = output.data.invoiceList;
          this.setState({
            salesInvoiceList: salesInvoiceList,
          });
        }
      }
    );
  }

  addPurchaseInvoice = (e) => {
    this.props.history.push({
      pathname: "/importPurchaseInvoice",
      state: { action: "create", breadCrumb: this.breadCrumb },
    });
  };

  viewPurchaseInvoice = (e, data) => {
    // let salesInvoiceId = data.id;
    this.props.history.push({
      pathname: "/importPurchaseVoucher",
      state: {
        invoiceId: data.id,
        action: "view",
        supplierList: this.supplierList,
        productList: this.productList,
        businessUnitList: this.businessUnitList,
        custHsnCodeList: this.custHsnCodeList,
        breadCrumb: this.breadCrumb,
      },
    });
  };

  selectAllPurchaseInvoices = (e) => {
    let selectedIndex = store.getState().appData.importPurchaseSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }
    let type = this.invoiceTypeList[selectedIndex];
    let filteredData = [];
    if (Utils.equalsIgnoreCase(type, "All")) {
      filteredData = this.state.salesInvoiceList;
    } else {
      filteredData = this.state.salesInvoiceList.filter(function (inv) {
        if (inv.invtype === "IMPORT" && type === "IMP") {
          return true;
        }
        if (inv.invtype === "RE" && type === "AT") {
          return true;
        }
        return inv.invtype === type;
      });
    }
    let selectedItems = this.state.salesInvoiceList;
    for (let i = 0; i < selectedItems.length; i++) {
      selectedItems[i].isSelected = false;
    }

    for (let i = 0; i < selectedItems.length; i++) {
      for (let j = 0; j < filteredData.length; j++) {
        if (filteredData[j].id === selectedItems[i].id) {
          selectedItems[i].isSelected = e.target.checked;
        }
      }
    }
    this.setState({
      salesInvoiceList: selectedItems,
      isSelectAll: e.target.checked,
    });
  };

  rowItemSelected(e, custid) {
    let selectedItems = this.state.salesInvoiceList;
    for (let i = 0; i < selectedItems.length; i++) {
      if (custid === selectedItems[i].id) {
        if (e.target.checked) {
          selectedItems[i].isSelected = true;
        } else {
          selectedItems[i].isSelected = false;
        }
      }
    }
    this.setState({ salesInvoiceList: selectedItems });
  }

  bukImportPurchaseInvoices(e) {
    let importPurchaseInvoiceList = [];
    for (let i = 0; i < this.state.salesInvoiceList.length; i++) {
      if (this.state.salesInvoiceList[i].tableData?.checked === true) {
        importPurchaseInvoiceList.push(this.state.salesInvoiceList[i].id);
      }
    }
    if (!importPurchaseInvoiceList.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    const postObject = {
      header: this.header,
      invoices: importPurchaseInvoiceList,
    };
    FetchServerData.callPostService(
      "/purchaseimpexp/impInvoices",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.setState({ selectedItems: [], isSelectAll: false });
        this.getPurchaseInvoiceData();
      }
    });
  }

  bulkOverridePurchaseInvoice(e) {
    let importPurchaseInvoiceList = [];
    for (let i = 0; i < this.state.salesInvoiceList.length; i++) {
      if (this.state.salesInvoiceList[i].tableData?.checked === true) {
        importPurchaseInvoiceList.push(this.state.salesInvoiceList[i].id);
      }
    }
    if (!importPurchaseInvoiceList.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    const postObject = {
      header: this.header,
      invoices: importPurchaseInvoiceList,
    };
    FetchServerData.callPostService(
      "/purchaseimpexp/overrideinvoices",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.setState({ selectedItems: [], isSelectAll: false });
        this.getPurchaseInvoiceData();
      }
    });
  }

  bulkDeletePurchaseInvoice(e) {
    let importPurchaseInvoiceList = [];
    for (let i = 0; i < this.state.salesInvoiceList.length; i++) {
      if (this.state.salesInvoiceList[i].tableData?.checked === true) {
        importPurchaseInvoiceList.push(this.state.salesInvoiceList[i].id);
      }
    }
    if (!importPurchaseInvoiceList.length > 0) {
      return PtsAlert.error("Please Select Invoice");
    }
    const postObject = {
      header: this.header,
      invoice: { invoiceids: importPurchaseInvoiceList },
    };
    FetchServerData.callPostService(
      "/purchaseimpexp/deleteInvoices",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.setState({ selectedItems: [], isSelectAll: false });
        this.getPurchaseInvoiceData();
      }
    });
  }
  getTablesWithInvoiceType(type) {
    let filteredData = [];
    if (Utils.equalsIgnoreCase(type, "All")) {
      filteredData = this.state.salesInvoiceList;
    } else {
      filteredData = this.state.salesInvoiceList.filter(function (inv) {
        if (inv.invtype === "IMPORT" && type === "IMP") {
          return true;
        }
        if (inv.invtype === "RE" && type === "AT") {
          return true;
        }
        return inv.invtype === type;
      });
    }
    return (
      <MaterialTable
        component={Paper}
        icons={this.tableIcons}
        title=""
        columns={[
          // {
          //   title: " ",
          //   render: (rowData) => {
          //     return (
          //       <Checkbox
          //         checked={
          //           rowData.isSelected || true === rowData.isSelected
          //             ? rowData.isSelected
          //             : false
          //         }
          //         color="primary"
          //         inputProps={{ "aria-label": "checkbox with default color" }}
          //         id={rowData.tableData.id}
          //         onClick={(e) => this.rowItemSelected(e, rowData.id)}
          //       ></Checkbox>
          //     );
          //   },
          //   width: "5%",
          // },
          {
            title: "#",
            render: (rowData) => rowData.tableData.id + 1,
            cellStyle: {
              width: "5%",
            },
          },
          {
            title: "Invoice Number",
            field: "invnumber",
            align: "left",
            width: "20%",
          },
          {
            title: "Month",
            field: "month",
            align: "left",
            width: "15%",
          },
          {
            title: "Year",
            field: "year",
            align: "left",
            width: "15%",
          },
          {
            title: "Type",
            field: "invtype",
            align: "left",
            width: "20%",
          },
          {
            title: "Is Duplicate",
            field: "isexistinmain",
            align: "left",
            width: "20%",
          },
        ]}
        data={filteredData}
        onSelectionChange={(rows) => {
          this.rowItemSelected(rows);
        }}
        options={{
          selection: true,
          showTextRowsSelected: false,
          sorting: true,
          paging: filteredData.length > this.state.pageSize,
          pageSizeOptions: Utils.materialTablePageSize(),
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#1b7189",
            fontWeight: "bold",
            color: "#fff",
            align: "center",
          },
          rowStyle: (rowData) => {
            if (rowData.isexistinmain && rowData.isexistinmain === true) {
              return { backgroundColor: "#ecc0a0" };
            }
          },
        }}
        onRowClick={(event, rowData) => {
          this.viewPurchaseInvoice(event, rowData);
          event.stopPropagation();
        }}
        style={{
          boder: "1px solid black",
        }}
        actions={
          [
            // {
            //   icon: () => (
            //     <Checkbox
            //       checked={this.state.isSelectAll}
            //       ref={this.selectAllCheckboxRef}
            //       color="primary"
            //       inputProps={{ "aria-label": "checkbox with default color" }}
            //     ></Checkbox>
            //   ),
            //   tooltip: "SelectAll",
            //   isFreeAction: true,
            //   onClick: (event) => this.selectAllPurchaseInvoices(event),
            // },
            // {
            //   icon: () => <Visibility />,
            //   tooltip: "View Customer",
            //   onClick: (e, rowData) => this.viewPurchaseInvoice(e, rowData),
            // },
          ]
        }
        // onRowClick={(e, rowData) => this.viewPurchaseInvoice(e, rowData)}
      />
    );
  }
  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setImportPurchaseTab(index));
    let selectedList = this.state.salesInvoiceList;
    for (let i = 0; i < selectedList.length; i++) {
      selectedList[i].isSelected = false;
    }
    this.setState({
      isSelectAll: false,
      selectedTabIndex: index,
      salesInvoiceList: selectedList,
    });
  }

  cancelView(e) {
    this.props.history.push({
      pathname: "/import/",
      state: {},
    });
  }
  render() {
    const tabList = this.invoiceTypeList.map((invType, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(invType, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{invType}</span>
        </Tab>
      );
    });

    const tabPanelList = this.invoiceTypeList.map((invType, keyIndex) => {
      return (
        <TabPanel key={keyIndex}>
          {this.getTablesWithInvoiceType(invType)}
        </TabPanel>
      );
    });

    let selectedIndex = store.getState().appData.importPurchaseSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      selectedIndex = 0;
    }

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          backCallback={this.cancelView.bind(this)}
        />
        {/* <div
          style={{
            display: "grid",
            backgroundColor: "#d6dde6",
            width: "100%",
            marginTop: "1em",
            padding: "1em",
          }}
        > */}
        <Row md={12} style={{ padding: "1em", margin: "0" }}>
          <ValidatorForm
            style={{ width: "100%" }}
            ref="form"
            onSubmit={this.onFileUpload.bind(this)}
          >
            <Card md={12} component={Paper} style={{ width: "100%" }}>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={4}>
                  <label>
                    Choose File To Import (
                    <span style={{ color: "red" }}>Excel file only</span>)
                  </label>
                </Col>
                <Col md={8}>
                  <label style={{ float: "right" }}>
                    <a
                      href={purchaseImportFile}
                      id="dwnlink"
                      download="PurchaseInvoice_Import.xlsx"
                      // class="pull-right"
                    >
                      <b> Download Sample Excel Format</b>
                    </a>
                  </label>
                </Col>
              </Row>
              <Row md={12} style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={12}>
                  <div
                    style={{
                      border: "1px solid gray",
                      padding: "1em",
                      marginTop: "0.5em",
                    }}
                  >
                    {/* <TextField
                        required
                        type="file"
                        id="file"
                        label="Select File to Upload"
                        ref={this.purchaseFileUploadRef}
                        onChange={this.onFileChange.bind(this)}
                        InputLabelProps={{ shrink: true }}
                      /> */}
                    <input
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      ref={this.purchaseFileUploadRef}
                      onChange={this.onFileChange.bind(this)}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ margin: "10px", alignItems: "flex-end" }}>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      background: "#1b7189",
                      color: "#fff",
                      marginRight: "1rem",
                    }}
                    startIcon={<SaveIcon />}
                    // onClick={(e) => this.onFileUpload()}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              {/* </form> */}
            </Card>
          </ValidatorForm>
        </Row>
        <Row md={12} style={{ padding: "1em", margin: "0" }}>
          {/* <div style={{ width: '-webkit-fill-available' }}> */}
          <Tabs
            selectedIndex={selectedIndex}
            md={12}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList
              style={{
                minWidth: "5em",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {tabList}
            </TabList>
            {tabPanelList}
          </Tabs>
          {/* </div> */}
        </Row>
        <Row md={12} style={{ padding: "1em", margin: "0" }}>
          <Col md={12} style={{ textAlign: "center", alignItems: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bukImportPurchaseInvoices(e)}
            >
              Bulk Import
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowUpwardIcon />}
              onClick={(e) => this.bulkOverridePurchaseInvoice(e)}
            >
              Bulk Override
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#1b7189",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<DeleteIcon />}
              onClick={(e) => this.bulkDeletePurchaseInvoice(e)}
            >
              Bulk Delete
            </Button>
          </Col>
        </Row>
        {/* </div> */}
      </>
    );
  }
}
export default ImportPurchaseInvoicesList;
