import {
  CLEAR_BREADCRUMB_DETAILS,
  GET_BREADCRUMB_DETAILS,
  SET_BREADCRUMB_DETAILS,
} from "./BreadCrumbDetailsType";

const getBreadCrumbDetails = () => {
  return {
    type: GET_BREADCRUMB_DETAILS,
  };
};

const setBreadCrumbDetails = (data) => {
  return {
    type: SET_BREADCRUMB_DETAILS,
    payload: data,
  };
};

const clearBreadCrumbDetails = (data) => {
  return {
    type: CLEAR_BREADCRUMB_DETAILS,
    payload: data,
  };
};
const BreadCrumbDetailsAction = {
  getBreadCrumbDetails,
  setBreadCrumbDetails,
  clearBreadCrumbDetails,
};
export default BreadCrumbDetailsAction;
