import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import Logo from "../assets/images/th.png";
import "./ApplicationLayout.scss";
import { Col, Row } from "reactstrap";
import Page from "../pts/components/page/Page";
import { PageAlert } from "../pts/components/pageAlert/PageAlert";
import ContextProviders from "../pts/components/utilities/ContextProviders";
// import handleKeyAccessibility, {
//   handleClickAccessibility,
// } from "../pts/helpers/handleTabAccessibility";
import routes from "../views";
import Loaders from "../views/elements/Loaders";
import nav from "../_nav";
import handleKeyAccessibility, {
  handleClickAccessibility,
} from "../pts/helpers/handleTabAccessibility";
import Header from "../pts/components/header/Header";
import SidebarNav from "../pts/components/sidebarNav/SidebarNav";
import { PageContent } from "../pts/components/page/PageContent";
import Footer1 from "../pts/components/footer/Footer1";
import FetchServerData from "../provider/FetchServerData";
import store from "../redux/store";
import userMenuAction from "../redux/userMenus/userMenusAction";
import masterDataAction from "../redux/masterData/masterDataAction";
import OrganizationDataAction from "../redux/organizationData/OrganizationDataAction";
import SettingDataAction from "../redux/settings/SettingDataAction";
import BreadCrumbDetailsAction from "../redux/breadCrumbDetails/BreadCrumbDetailsAction";
import appDataAction from "../redux/appData/appDataAction";
import userMenusAction from "../redux/userMenus/userMenusAction";
const MOBILE_SIZE = 992;
export class ApplicationLayout extends Component {
  constructor(props) {
    super(props);
    //this.user = localStorage.getItem('user');
    this.state = {
      sidebarCollapsed: true,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      showSuccessAlert: false,
      showFailAlert: false,
      showSuccessLoader: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    } else {
      this.setState({ sidebarCollapsed: false, isMobile: false });
    }
  };
  componentDidUpdate(prev) {
    if (
      this.state.isMobile &&
      prev.location.pathname !== this.props.location.pathname
    ) {
      this.toggleSideCollapse();
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("keydown", handleKeyAccessibility);
    document.addEventListener("click", handleClickAccessibility);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  toggleSideCollapse = () => {
    this.setState((prevState) => ({
      sidebarCollapsed: !prevState.sidebarCollapsed,
    }));
  };
  closeChat = () => {
    this.setState({ showChat1: false });
  };
  handleSpanClick = () => {
    // localStorage.clear();
    if (this.props.history) {
      this.props.history.push("/login");
    }
  };
  // logoutHandler = (e) => {
  //   // store.clean();
  //   if (this.props.history) {
  //     this.props.history.push("/login");
  //   }
  // };
  logoutHandler() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/userms/logout", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          store.dispatch(userMenuAction.setUserMenus({}));
          store.dispatch(userMenusAction.setUserDetails({}));
          store.dispatch(userMenuAction.setUserTopMenus({}));
          store.dispatch(masterDataAction.setMasterData({}));
          store.dispatch(OrganizationDataAction.setOrganization({}));
          store.dispatch(SettingDataAction.setSettings({}));
          store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails([]));
          store.dispatch(appDataAction.setActiveMenu());
          this.props.history.push("/login");
        }
      }
    );
  }
  termsAndConditionsHandler = (e) => {
    this.props.history.push("/termsConditions");
  };
  aboutUsHandler = (e) => {
    this.props.history.push("/aboutUs");
  };
  profileHandler = (e) => {
    if (this.props.history) {
      this.props.history.push("/admindetails");
    }
  };
  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = !sidebarCollapsed
      ? "side-menu-collapsed"
      : "";

    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
            <SidebarNav
              nav={nav}
              logo={Logo}
              logoText="TaxonomyHub"
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              {...this.props}
            />
            <Page>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                {...this.props}
              >
                <React.Fragment>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      {/* New */}
                      <span
                        title="User Profile"
                        onClick={(e) => this.profileHandler(e)}
                      >
                        <i
                          className="fa fa-user-circle-o"
                          style={{ color: "#fff", fontSize: "18px" }}
                        />
                      </span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      {/* <Avatar size="small" color="blue" initials="JS" /> */}
                      <span
                        title="Logout"
                        onClick={(e) => this.logoutHandler(e)}
                      >
                        <i
                          className="fa fa-sign-out"
                          style={{ color: "#fff", fontSize: "18px" }}
                        />
                      </span>
                    </DropdownToggle>
                  </UncontrolledDropdown>

                  {/* <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      <span
                        className="terms"
                        style={{ color: "#fff" }}
                        onClick={(e) => this.termsAndConditionsHandler(e)}
                      >
                        T&C
                      </span>
                    </DropdownToggle>
                  </UncontrolledDropdown> */}
                </React.Fragment>
              </Header>
              <PageContent>
                {this.state.showSuccessLoader && <Loaders></Loaders>}
                <Switch>
                  {routes.map((page, key) => (
                    <Route
                      path={page.path}
                      component={page.component}
                      key={key}
                    />
                  ))}
                  <Redirect to="/login" />
                </Switch>
              </PageContent>
            </Page>
          </div>
          <Footer1>
            <div className="applicationFooter">
              <Row>
                <Col lg={6}>
                  <span>
                    <ul
                      style={{
                        listStyle: "none",
                        display: "inline-flex",
                        margin: "auto",
                      }}
                    >
                      <li style={{ textAlign: "left" }}>
                        <a
                          href="/termsCondition"
                          // target="_blank"
                          // onClick={(e) => this.termsAndConditionsHandler(e)}
                          style={{ color: "#fff", cursor: "pointer" }}
                        >
                          {" "}
                          Terms &amp; Conditions
                        </a>
                      </li>
                      &nbsp;
                      {/* <li style={{ textAlign: "left" }}>
                        <a
                          onClick={() => this.navigateToLink("/privacyPolicy")}
                          style={{ color: "#fff", cursor: "pointer" }}
                        >
                          {" "}
                          | &nbsp; Privacy Policy &nbsp;
                        </a>
                      </li> */}
                      <li style={{ textAlign: "left" }}>
                        <a
                          href="/aboutUs"
                          target="_blank"
                          // onClick={(e) => this.aboutUsHandler(e)}
                          style={{ color: "#fff", cursor: "pointer" }}
                        >
                          {" "}
                          | &nbsp; About Us
                        </a>
                      </li>
                    </ul>
                  </span>
                </Col>
                <Col lg={6}>
                  <span>Copyright © 2017. All rights reserved.</span>
                </Col>
              </Row>
            </div>
          </Footer1>
        </div>
      </ContextProviders>
    );
  }
}
export default withRouter(ApplicationLayout);
