import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import React, { Component } from "react";

export class CreateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      type: props.type,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <Tooltip title="Create">
        <Button
          type={this.state.type ? this.state.type : ""}
          variant="contained"
          size="small"
          style={{
            background: "#1b7189",
            color: "#fff",
            marginRight: "1rem",
          }}
          startIcon={<AddIcon />}
          // {this.state.onClick? onClick=this.onClick.bind(this)}
          onClick={this.onClick.bind(this)}
          className="createButton"
        >
          Create
        </Button>
      </Tooltip>
    );
  }
}
export default CreateButton;
