import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@material-ui/core/Link";
// import { format } from 'date-fns';
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import Address from "../address/Address";
import AuditNoteList from "../auditNote/AuditNoteList";
import PurchaseVoucherItem from "./purchaseVoucher/PurchaseVoucherItem";
import PurchaseAdvanceVoucherItem from "./advanceVoucher/PurchaseAdvanceVoucherItem";

export default class PurchaseInvoiceDialogue extends Component {
  header = store.getState().header.header;
  tableIcons = UiUtils.getMaterialTableIcons();

  constructor(props) {
    super(props);
    this.state = {
      invoice: null,
      invNumber: props.invNumber,
      supplierid: props.supplier?.id,
      pageId: props.pageId,
      purchaseOrderTitle: false,
      open: false,
    };
  }

  componentDidMount() {
    this.state.pageId = this.props.pageId;
    this.state.invoiceType = this.props.invoiceType;
  }

  showReferenceInvoice() {
    this.showAdvanceInvoice(
      this.state.invoice?.referenceinvnumber,
      this.state.invoice?.supplier.id
    );
  }

  showViewInINROrderInvoice(invNumber) {
    if (Utils.isNullOrEmpty(invNumber)) {
      this.setState({ open: false, invNumber: invNumber });
      return;
    }
    const postObject = {
      po: { id: invNumber },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/poms/getPOInBaseCurrency",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let invoice = output.data;
        this.setState({
          invoice: invoice,
          open: true,
          purchaseOrderTitle: true,
        });
      } else {
      }
    });
  }
  showViewInINRInvoice(invNumber) {
    if (Utils.isNullOrEmpty(invNumber)) {
      this.setState({ open: false, invNumber: invNumber });
      return;
    }
    const postObject = {
      invoice: { id: invNumber },
      header: this.header,
    };
    FetchServerData.callPostService(
      "/psams/getInvoiceInBaseCurrency",
      postObject
    ).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
        let invoice = output.data;
        let pageId =
          invoice.invoicetype?.type === "RE"
            ? "AdvancePurchaseVoucherItem"
            : "PurchaseVoucherItem";
        this.setState({
          invoice: invoice,
          open: true,
          purchaseOrderTitle: false,
          pageId: pageId,
        });
      } else {
      }
    });
  }

  showOrderInvoice(invNumber, supplierid) {
    this.state = { open: false, invNumber: invNumber };
    if (Utils.isNullOrEmpty(invNumber)) {
      return;
    }
    const postObject = {
      po: { invnumber: invNumber, supplier: { id: supplierid } },
      header: this.header,
    };
    FetchServerData.callPostService("/poms/getinvbynum", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          this.setState({
            invoice: invoice,
            open: true,
            purchaseOrderTitle: true,
            pageId: "PurchaseVoucherItem",
          });
        } else {
        }
      }
    );
  }

  showAdvanceInvoice(invNumber, supplierid) {
    this.state = { open: false, invNumber: invNumber };
    if (Utils.isNullOrEmpty(invNumber)) {
      return;
    }
    const postObject = {
      invoice: { invnumber: invNumber, supplier: { id: supplierid } },
      header: this.header,
    };
    FetchServerData.callPostService("/prems/getInvoiceByNum", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          let pageId =
            invoice.invoicetype?.type === "RE"
              ? "AdvancePurchaseVoucherItem"
              : "PurchaseVoucherItem";
          this.setState({
            invoice: invoice,
            open: true,
            purchaseOrderTitle: false,
            pageId: pageId,
          });
        } else {
        }
      }
    );
  }

  showInvoice(invNumber, supplierid) {
    this.state = { open: false, invNumber: invNumber };
    if (Utils.isNullOrEmpty(invNumber)) {
      return;
    }
    const postObject = {
      invoice: { invnumber: invNumber, supplier: { id: supplierid } },
      header: this.header,
    };
    FetchServerData.callPostService("/psams/getInvoiceByNum", postObject).then(
      (output) => {
        if (Utils.equalsIgnoreCase(output.status, "SUCCESS")) {
          let invoice = output.data;
          let pageId =
            invoice.invoicetype?.type === "RE"
              ? "AdvancePurchaseVoucherItem"
              : "PurchaseVoucherItem";
          this.setState({
            invoice: invoice,
            open: true,
            purchaseOrderTitle: false,
            pageId: pageId,
          });
        } else {
        }
      }
    );
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleServiceGoodsSelectChange(e) {
    this.setState({ serviceGoods: e.target.value });
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  hsnCodeSelected(e, selectedCode) {
    if (Utils.isNotNull(this.props.onCodeChange)) {
      this.props.onCodeChange(selectedCode);
      this.setState({ open: false });
    }
  }

  getReferencingInvoices() {
    if (
      this.state.invoice.referencingInvoices &&
      this.state.invoice.referencingInvoices.length > 0
    ) {
      let refInvList = [];
      for (let i = 0; i < this.state.invoice.referencingInvoices.length; i++) {
        let inv = this.state.invoice.referencingInvoices[i];
        if (inv) {
          refInvList.push(
            <>
              <Link
                style={{ color: "#0d6efd" }}
                // className="text-underline-hover"
                // onClick={(e) => {
                // if (
                //   this.referenceInvoiceDialogueRef &&
                //   this.referenceInvoiceDialogueRef.current
                // ) {
                // this.showInvoice(
                //   inv.invoiceNumber, this.state.invoice.supplier?.id
                // );
                // }
                // }}
              >
                <strong>{inv.invoiceNumber ? inv.invoiceNumber : ""}</strong>
              </Link>
              <span>,&nbsp;</span>
            </>
          );
        }
      }
      return refInvList;
    } else {
      return "";
    }
  }

  render() {
    if (!this.state.invoice) {
      return <></>;
    }

    if (this.state.pageId === "AdvancePurchaseVoucherItem") {
      return this.AdvancePurchaseVoucher();
    } else if (this.state.pageId === "PurchaseVoucherItem") {
      return this.PurchaseVoucher();
    }
  }
  AdvancePurchaseVoucher() {
    let invoiceItemsList = [];
    let isIgstApplicable = Utils.isIgstApplicable(this.state.invoice?.supplier);
    this.state.invoice?.invoiceitems &&
      this.state.invoice?.invoiceitems.forEach((element, index) => {
        invoiceItemsList.push(
          <PurchaseAdvanceVoucherItem
            key={index}
            isIgstApplicable={isIgstApplicable}
            data={element}
            srno={index + 1}
            action="view"
            backgroundColor={
              index % 2 ? { background: "#FFF" } : { background: "#FFF" }
            }
            productList={this.productList}
            invoicedata={this.state.invoiceitems}
            iteamIndex={index}
          />
        );
      });
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        maxWidth="xxl"
        className="salesDialogue"
        // classes={{ minWidth: '800px' }}
        // scroll={scroll}
        //   aria-labelledby="scroll-dialog-title"
        //   aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ backgroundColor: "#1b7189" }}
        >
          <span style={{ color: "#fff" }}>
            {this.state.purchaseOrderTitle === true ? (
              <>Purchase Order : {this.state.invoice.invnumber}</>
            ) : (
              <>
                {this.state.invoice.invoicetype
                  ? this.state.invoice.invoicetype.description
                  : ""}{" "}
                : {this.state.invoice.invnumber}
              </>
            )}
          </span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={this.handleClose.bind(this)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "#fff", fontWeight: "600" }} />
        </IconButton>
        <DialogContent>
          <div
            md={12}
            style={{
              width: "100%",
              color: "#000",
              fontSize: "small",
              padding: "0.5em",
            }}
          >
            {/* <CardHeader> */}
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%" }}>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Name</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.name}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.suppliertypevo?.description}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>GSTN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.gstn}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>PAN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.pan}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Share With Customer</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.sharewithcust &&
                      this.state.invoice.sharewithcust === true
                        ? "Yes"
                        : "No"}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Invoice Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.invoicetype
                        ? this.state.invoice.invoicetype.description
                        : ""}
                    </label>
                  </Row>

                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Business Unit</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.bu ? this.state.invoice.bu.name : ""}
                    </label>
                  </Row>
                  {this.state.invoice?.exchangerate &&
                    this.state.invoice?.exchangerate > 1 && (
                      <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                        <label
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "#000",
                            width: "40%",
                            fontSize: "small",
                            textAlign: "left",
                          }}
                        >
                          <strong>Currency Rate</strong>
                        </label>
                        <label
                          style={{
                            width: "60%",
                            color: "#000",
                            fontSize: "small",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.invoice.exchangerate}
                        </label>
                      </Row>
                    )}
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Row style={{ flex: 1 }}>
                    <Address
                      // ref={this.billingAddressRef}
                      title="Billing Address"
                      data={this.state.invoice.billtoaddress}
                      action="view"
                    />
                  </Row>

                  <Row style={{ flex: 1 }}>
                    <Address
                      // ref={this.shippingAddressRef}
                      title="Shipping Address"
                      data={this.state.invoice.shiptoaddress}
                      action="view"
                    />
                  </Row>
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ margin: 0, padding: 0, width: "100%" }}>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Created On</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* { this.state.invoice.createdon ? format(new Date(), 'yyyy-MM-dd') : ''} */}
                      {Utils.ptsDateFormat(this.state.invoice.createdon)}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Invoice Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoice.invoicedate} */}
                      {Utils.ptsDateFormat(this.state.invoice.invoicedate)}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>GST Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoice.gstdate} */}
                      {Utils.ptsDateFormat(this.state.invoice.gstdate)}
                    </label>
                  </Row>

                  {(this.state.invoice.isexport === true ||
                    Utils.equalsIgnoreCase(
                      this.state.invoice.isexport,
                      "true"
                    )) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Export</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}

                  {this.state.invoice.isAsset === true && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Asset Sale</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Status</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.status
                        ? this.state.invoice.status.description
                        : ""}
                    </label>
                  </Row>
                  {Utils.isNotNullAndEmpty(
                    this.state.invoice.purchaseorder
                  ) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>PO </strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.invoice.purchaseorder
                          ? this.state.invoice.purchaseorder
                          : ""}
                      </label>
                    </Row>
                  )}
                  {Utils.isNotNullAndEmpty(this.state.invoice.purchasedate) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>PO Date</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {/* {this.state.invoice.purchasedate ? this.state.invoice.purchasedate : ''} */}
                        {Utils.ptsDateFormat(this.state.invoice.purchasedate)}
                      </label>
                    </Row>
                  )}
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Remark</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.description
                        ? this.state.invoice.description
                        : ""}
                    </label>
                  </Row>

                  {Utils.isNotNullAndEmpty(
                    this.state.invoice.referencingInvoices
                  ) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Referencing Invoice</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {this.getReferencingInvoices()}
                      </label>
                    </Row>
                  )}

                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: 0,
                        padding: 0,
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Bank Account</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.bankaccount && (
                        <span>
                          {this.state.invoice.bankaccount.bankname},
                          {this.state.invoice.bankaccount.accountnum},
                          {this.state.invoice.bankaccount.address}
                        </span>
                      )}
                    </label>
                  </Row>
                </div>
              </Col>
            </Row>
            <table>
              <thead>
                <tr md={12} style={{ padding: "0px", margin: "0" }}>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    <span style={{ float: "left" }}>#</span>
                    <span style={{ float: "right" }}>SAC/HSN</span>
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "25%",
                    }}
                  >
                    Item Details
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    Advance Amt.
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    CESS %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    Tax Rate %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    GST
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "20%",
                    }}
                  >
                    Total
                  </td>
                </tr>
              </thead>
              {invoiceItemsList}
            </table>
            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <Col md={8} style={{ margin: "0", padding: "1em" }}></Col>
              <Col md={4} style={{ margin: "0", padding: "0" }}>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Value Of Supply</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.invoice.currency.symbol !== null
                      ? this.state.invoice.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.invoice.totaltaxable)}
                  </Col>
                </Row>
                {Utils.isIgstApplicable(this.state.invoice?.supplier) ===
                  false && (
                  <>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>CGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.invoice.cgst)}
                      </Col>
                    </Row>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>SGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.invoice.sgst)}
                      </Col>
                    </Row>
                  </>
                )}

                {Utils.isIgstApplicable(this.state.invoice.supplier) ===
                  true && (
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>IGST</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.invoice.igst)}
                    </Col>
                  </Row>
                )}

                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>CESS</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {parseFloat(this.state.invoice.cess)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Misc.(If Any)</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.invoice.miscellaneous)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Total Amount</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.invoice.currency.symbol !== null
                      ? this.state.invoice.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.invoice.totalamount)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={12}
                    style={{
                      // fontWeight: '600',
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.invoice.totalinwords}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              md={12}
              style={{ flexDirection: "column", minHeight: "1em" }}
            ></Row>
            {/* </CardBody> */}

            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <AuditNoteList
                ref={this.auditNoteListRef}
                data={this.state.invoice.auditnotes}
                aid={this.state.invoice.id}
                title="Audit Notes"
                history={this.props.history}
              />
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  PurchaseVoucher() {
    let invoiceItemsList = [];
    let isIgstApplicable = Utils.isIgstApplicable(this.state.invoice.supplier);
    this.state.invoice.invoiceitems &&
      this.state.invoice.invoiceitems.forEach((element, index) => {
        invoiceItemsList.push(
          <PurchaseVoucherItem
            isIgstApplicable={isIgstApplicable}
            key={index}
            data={element}
            srno={index + 1}
            action="view"
            productList={this.productList}
            custHsnCodeList={this.custHsnCodeList}
          />
        );
      });

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        maxWidth="xxl"
        className="salesDialogue"
        // classes={{ minWidth: '800px' }}
        // scroll={scroll}
        //   aria-labelledby="scroll-dialog-title"
        //   aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ backgroundColor: "#1b7189" }}
        >
          <span style={{ color: "#fff" }}>
            {this.state.purchaseOrderTitle === true ? (
              <>Purchase Order : {this.state.invoice.invnumber}</>
            ) : (
              <>
                {this.state.invoice.invoicetype
                  ? this.state.invoice.invoicetype.description
                  : ""}{" "}
                : {this.state.invoice.invnumber}
              </>
            )}
          </span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={this.handleClose.bind(this)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "#fff", fontWeight: "600" }} />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={this.handleClose.bind(this)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "#fff", fontWeight: "600" }} />
        </IconButton>
        <DialogContent>
          <div
            md={12}
            style={{
              width: "100%",
              color: "#000",
              fontSize: "small",
              padding: "0.5em",
            }}
          >
            {/* <CardHeader> */}
            <Row md={12} style={{ padding: "5px", margin: "0" }}>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ width: "100%" }}>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Name</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.name}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Supplier Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.suppliertypevo?.description}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>GSTN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.gstn}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>PAN</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.supplier.pan}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Share With Customer</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.sharewithcust &&
                      this.state.invoice.sharewithcust === true
                        ? "Yes"
                        : "No"}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Invoice Type</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.invoicetype
                        ? this.state.invoice.invoicetype.description
                        : ""}
                    </label>
                  </Row>

                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Business Unit</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.bu ? this.state.invoice.bu.name : ""}
                    </label>
                  </Row>
                  {this.state.invoice?.exchangerate &&
                    this.state.invoice?.exchangerate > 1 && (
                      <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                        <label
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "#000",
                            width: "40%",
                            fontSize: "small",
                            textAlign: "left",
                          }}
                        >
                          <strong>Currency Rate</strong>
                        </label>
                        <label
                          style={{
                            width: "60%",
                            color: "#000",
                            fontSize: "small",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.invoice.exchangerate}
                        </label>
                      </Row>
                    )}
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Row style={{ flex: 1 }}>
                    <Address
                      // ref={this.billingAddressRef}
                      title="Billing Address"
                      data={this.state.invoice.billtoaddress}
                      action="view"
                    />
                  </Row>

                  <Row style={{ flex: 1 }}>
                    <Address
                      // ref={this.shippingAddressRef}
                      title="Shipping Address"
                      data={this.state.invoice.shiptoaddress}
                      action="view"
                    />
                  </Row>
                </div>
              </Col>
              <Col
                md={4}
                style={{ padding: "5px", margin: "0", display: "flex" }}
              >
                <div style={{ margin: 0, padding: 0, width: "100%" }}>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Created On</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* { this.state.invoice.createdon ? format(new Date(), 'yyyy-MM-dd') : ''} */}
                      {Utils.ptsDateFormat(this.state.invoice.createdon)}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Invoice Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoice.invoicedate} */}
                      {Utils.ptsDateFormat(this.state.invoice.invoicedate)}
                    </label>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>GST Date</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {/* {this.state.invoice.gstdate} */}
                      {Utils.ptsDateFormat(this.state.invoice.gstdate)}
                    </label>
                  </Row>

                  {(this.state.invoice.isexport === true ||
                    Utils.equalsIgnoreCase(
                      this.state.invoice.isexport,
                      "true"
                    )) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Export</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}

                  {this.state.invoice.isAsset === true && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>Is Asset Sale</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        Yes
                      </label>
                    </Row>
                  )}
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Status</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.status
                        ? this.state.invoice.status.description
                        : ""}
                    </label>
                  </Row>
                  {Utils.isNotNullAndEmpty(
                    this.state.invoice.purchaseorder
                  ) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>PO </strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {this.state.invoice.purchaseorder
                          ? this.state.invoice.purchaseorder
                          : ""}
                      </label>
                    </Row>
                  )}
                  {Utils.isNotNullAndEmpty(this.state.invoice.purchasedate) && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        <strong>PO Date</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          fontSize: "small",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                        }}
                      >
                        {Utils.ptsDateFormat(this.state.invoice?.purchasedate)}
                      </label>
                    </Row>
                  )}
                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        width: "40%",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Remark</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.description
                        ? this.state.invoice.description
                        : ""}
                    </label>
                  </Row>
                  {/* 
                  {this.state.invoice?.referencingInvoices.length > 0 &&
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Referencing Invoice</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {this.getReferencingInvoices()}
                      </label>
                    </Row>
                  }
                  {this.state.invoice?.referenceinvnumber !== "" &&
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Referenced Invoice</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Link
                          style={{ color: "#0d6efd", padding: "0" }}
                        // onClick={this.showReferenceInvoice.bind(this)}
                        // className="text-underline-hover"
                        >
                          <strong>
                            {this.state.invoice.referenceinvnumber
                              ? this.state.invoice.referenceinvnumber
                              : ""}
                          </strong>
                        </Link>
                      </label>
                    </Row>
                  } */}

                  {this.state.invoice?.referencingInvoices.length > 0 && (
                    <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#000",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <strong>Referencing Invoice</strong>
                      </label>
                      <label
                        style={{
                          width: "60%",
                          color: "#000",
                          margin: "0",
                          padding: "0",
                          textAlign: "left",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {this.getReferencingInvoices()}
                      </label>
                    </Row>
                  )}
                  {this.state.invoice?.referenceinvnumber !== "" &&
                    this.state.invoiceType === "SA" && (
                      <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                        <label
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "#000",
                            width: "40%",
                            textAlign: "left",
                          }}
                        >
                          <strong>Purchase Invoices</strong>
                        </label>
                        <label
                          style={{
                            width: "60%",
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <Link
                            style={{ color: "#0d6efd", padding: "0" }}
                            // onClick={this.showReferenceInvoice.bind(this)}
                            // className="text-underline-hover"
                          >
                            <strong>
                              {this.state.invoice.referenceinvnumber
                                ? this.state.invoice.referenceinvnumber
                                : ""}
                            </strong>
                          </Link>
                        </label>
                      </Row>
                    )}

                  {this.state.invoice?.referenceinvnumber !== "" &&
                    this.state.invoiceType !== "SA" && (
                      <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                        <label
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "#000",
                            width: "40%",
                            textAlign: "left",
                          }}
                        >
                          <strong>Advance Voucher</strong>
                        </label>
                        <label
                          style={{
                            width: "60%",
                            color: "#000",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <Link
                            style={{ color: "#0d6efd", padding: "0" }}
                            // onClick={this.showReferenceInvoice.bind(this)}
                            // className="text-underline-hover"
                          >
                            <strong>
                              {this.state.invoice.referenceinvnumber
                                ? this.state.invoice.referenceinvnumber
                                : ""}
                            </strong>
                          </Link>
                        </label>
                      </Row>
                    )}

                  <Row style={{ margin: 0, padding: 0, width: "100%" }}>
                    <label
                      style={{
                        color: "#000",
                        width: "40%",
                        margin: 0,
                        padding: 0,
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      <strong>Bank Account</strong>
                    </label>
                    <label
                      style={{
                        width: "60%",
                        color: "#000",
                        fontSize: "small",
                        margin: "0",
                        padding: "0",
                        textAlign: "left",
                      }}
                    >
                      {this.state.invoice.bankaccount && (
                        <span>
                          {this.state.invoice.bankaccount.bankname},
                          {this.state.invoice.bankaccount.accountnum},
                          {this.state.invoice.bankaccount.address}
                        </span>
                      )}
                    </label>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* </CardHeader> */}
            {/* <CardBody> */}
            {this.state.invoice?.referenceinvnumber !== "" &&
              this.state.invoiceType !== "SA" && (
                <Row md={12} style={{ padding: "0em 1em 1em 1em" }}>
                  {/* <Col md={12} > */}
                  <Col
                    md={12}
                    style={{
                      background: "#c5dde4",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                    }}
                  >
                    Advance Voucher Details
                  </Col>
                  <table>
                    <thead>
                      <tr
                        md={12}
                        style={{ padding: "0px", margin: "0", width: "800px" }}
                      >
                        <td
                          style={{
                            background: "rgb(239, 244, 247)",
                            color: "#000",
                            fontWeight: "bold",
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            width: "10%",
                            textAlign: "right",
                          }}
                        >
                          Advance Amt.
                        </td>
                        <td
                          style={{
                            background: "rgb(239, 244, 247)",
                            color: "#000",
                            fontWeight: "bold",
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            width: "7%",
                            textAlign: "right",
                          }}
                        >
                          CESS %
                        </td>
                        {Utils.isIgstApplicable(
                          this.state.invoice?.supplier
                        ) !== true && (
                          <>
                            <td
                              style={{
                                background: "rgb(239, 244, 247)",
                                color: "#000",
                                fontWeight: "bold",
                                border: "0.5px solid #ece6e6",
                                padding: "0.5em",
                                width: "7%",
                                textAlign: "right",
                              }}
                            >
                              CGST
                            </td>
                            <td
                              style={{
                                background: "rgb(239, 244, 247)",
                                color: "#000",
                                fontWeight: "bold",
                                border: "0.5px solid #ece6e6",
                                padding: "0.5em",
                                width: "7%",
                                textAlign: "right",
                              }}
                            >
                              SGST
                            </td>
                          </>
                        )}
                        {Utils.isIgstApplicable(
                          this.state.invoice?.supplier
                        ) !== false && (
                          <>
                            <td
                              style={{
                                background: "rgb(239, 244, 247)",
                                color: "#000",
                                fontWeight: "bold",
                                border: "0.5px solid #ece6e6",
                                padding: "0.5em",
                                width: "7%",
                                textAlign: "right",
                              }}
                            >
                              IGST
                            </td>
                          </>
                        )}
                        <td
                          style={{
                            background: "rgb(239, 244, 247)",
                            color: "#000",
                            fontWeight: "bold",
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            width: "10%",
                            textAlign: "right",
                          }}
                        >
                          Total
                        </td>
                        <td
                          style={{
                            background: "rgb(239, 244, 247)",
                            color: "#000",
                            fontWeight: "bold",
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            width: "10%",
                            textAlign: "right",
                          }}
                        >
                          Utilized GST
                        </td>
                        <td
                          style={{
                            background: "rgb(239, 244, 247)",
                            color: "#000",
                            fontWeight: "bold",
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            width: "10%",
                            textAlign: "right",
                          }}
                        >
                          Utilized Taxable
                        </td>
                        <td
                          style={{
                            background: "rgb(239, 244, 247)",
                            color: "#000",
                            fontWeight: "bold",
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            width: "10%",
                            textAlign: "right",
                          }}
                        >
                          Available GST
                        </td>
                        <td
                          style={{
                            background: "rgb(239, 244, 247)",
                            color: "#000",
                            fontWeight: "bold",
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            width: "10%",
                            textAlign: "right",
                          }}
                        >
                          Available Taxable
                        </td>
                      </tr>
                    </thead>
                    <tr
                      style={{
                        // padding: "5px",
                        margin: "0",
                        // background: this.state.rowBackgroundColor,
                      }}
                    >
                      <td
                        style={{
                          border: "0.5px solid #ece6e6",
                          padding: "0.5em",
                          textAlign: "right",
                          width: "10%",
                        }}
                      >
                        <span style={{ float: "right" }}>
                          {this.state.invoice?.refinvoice.totaltaxable}
                        </span>
                      </td>
                      <td
                        style={{
                          border: "0.5px solid #ece6e6",
                          padding: "0.5em",
                          textAlign: "right",
                          width: "10%",
                        }}
                      >
                        {this.state.invoice?.refinvoice.cess}
                      </td>
                      {Utils.isIgstApplicable(this.state.invoice?.supplier) !==
                        true && (
                        <>
                          <td
                            style={{
                              border: "0.5px solid #ece6e6",
                              padding: "0.5em",
                              width: "7%",
                              textAlign: "right",
                            }}
                          >
                            {this.state.invoice?.refinvoice.cgst}
                          </td>
                          <td
                            style={{
                              border: "0.5px solid #ece6e6",
                              padding: "0.5em",
                              textAlign: "right",
                              width: "7%",
                            }}
                          >
                            {this.state.invoice?.refinvoice.sgst}
                          </td>
                        </>
                      )}
                      {Utils.isIgstApplicable(this.state.invoice?.supplier) !==
                        false && (
                        <td
                          style={{
                            border: "0.5px solid #ece6e6",
                            padding: "0.5em",
                            textAlign: "right",
                            width: "7%",
                          }}
                        >
                          {this.state.invoice?.refinvoice.igst}
                        </td>
                      )}
                      <td
                        style={{
                          border: "0.5px solid #ece6e6",
                          padding: "0.5em",
                          textAlign: "right",
                          width: "10%",
                        }}
                      >
                        {this.state.invoice?.refinvoice.totalamount}
                      </td>
                      <td
                        style={{
                          border: "0.5px solid #ece6e6",
                          padding: "0.5em",
                          textAlign: "right",
                          width: "10%",
                        }}
                      >
                        {this.state.invoice?.refinvoice?.advanceGstUsed}
                      </td>
                      <td
                        style={{
                          border: "0.5px solid #ece6e6",
                          padding: "0.5em",
                          textAlign: "right",
                          width: "10%",
                        }}
                      >
                        {this.state.invoice?.refinvoice?.advanceAmountUsed}
                      </td>
                      <td
                        style={{
                          border: "0.5px solid #ece6e6",
                          padding: "0.5em",
                          textAlign: "right",
                          width: "10%",
                        }}
                      >
                        {this.state.invoice?.refinvoice?.cess +
                          this.state.invoice?.refinvoice?.sgst +
                          this.state.invoice?.refinvoice?.cgst +
                          this.state.invoice?.refinvoice?.igst -
                          this.state.invoice?.refinvoice.advanceGstUsed}
                      </td>
                      <td
                        style={{
                          border: "0.5px solid #ece6e6",
                          padding: "0.5em",
                          textAlign: "right",
                          width: "10%",
                        }}
                      >
                        {this.state.invoice?.refinvoice?.totaltaxable -
                          this.state.invoice?.refinvoice?.advanceAmountUsed}
                      </td>
                    </tr>
                  </table>
                  {/* </Col> */}
                </Row>
              )}
            <Row
              md={12}
              style={{ padding: "5px", margin: "0", minHeight: "1em" }}
            ></Row>
            <table>
              <thead>
                <tr md={12} style={{ padding: "0px", margin: "0" }}>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    <span style={{ float: "left" }}>#</span>
                    <span style={{ float: "right" }}>SAC/HSN</span>
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "20%",
                    }}
                  >
                    Item Details
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      width: "10%",
                    }}
                  >
                    UOM
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "3%",
                    }}
                  >
                    Qty
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "8%",
                    }}
                  >
                    Unit Price
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Gross Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Discount %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "8%",
                    }}
                  >
                    Net Value
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "5%",
                    }}
                  >
                    CESS %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    Tax Rate %
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "15%",
                    }}
                  >
                    GST
                  </td>
                  <td
                    style={{
                      background: "#ececec",
                      color: "#000",
                      fontWeight: "bold",
                      border: "0.5px solid #ece6e6",
                      padding: "0.5em",
                      textAlign: "right",
                      width: "10%",
                    }}
                  >
                    Total
                  </td>
                </tr>
              </thead>
              {invoiceItemsList}
            </table>
            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <Col md={8} style={{ margin: "0", padding: "1em" }}></Col>
              <Col md={4} style={{ margin: "0", padding: "0" }}>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Value Of Supply</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.invoice.currency.symbol !== null
                      ? this.state.invoice.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.invoice.totaltaxable)}
                  </Col>
                </Row>
                {Utils.isIgstApplicable(this.state.invoice.supplier) ===
                  false && (
                  <>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>CGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.invoice.cgst)}
                      </Col>
                    </Row>
                    <Row md={12} style={{ padding: "5px", margin: "0" }}>
                      <Col
                        md={9}
                        style={{
                          color: "#000",
                          // fontWeight: '600',
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        <strong>SGST</strong>
                      </Col>
                      <Col
                        md={3}
                        style={{
                          color: "#000",
                          // border: '0.5px solid #ece6e6',
                          padding: "0.1em",
                          textAlign: "right",
                        }}
                      >
                        {Utils.getFloatValue(this.state.invoice.sgst)}
                      </Col>
                    </Row>
                  </>
                )}

                {Utils.isIgstApplicable(this.state.invoice.supplier) ===
                  true && (
                  <Row md={12} style={{ padding: "5px", margin: "0" }}>
                    <Col
                      md={9}
                      style={{
                        color: "#000",
                        // fontWeight: '600',
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      <strong>IGST</strong>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        color: "#000",
                        // border: '0.5px solid #ece6e6',
                        padding: "0.1em",
                        textAlign: "right",
                      }}
                    >
                      {Utils.getFloatValue(this.state.invoice.igst)}
                    </Col>
                  </Row>
                )}

                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>CESS</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {parseFloat(this.state.invoice.cess)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Misc.(If Any)</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {Utils.getFloatValue(this.state.invoice.miscellaneous)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={9}
                    style={{
                      color: "#000",
                      // fontWeight: '600',
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    <strong>Total Amount</strong>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.invoice.currency.symbol !== null
                      ? this.state.invoice.currency.symbol
                      : ""}
                    {Utils.getFloatValue(this.state.invoice.totalamount)}
                  </Col>
                </Row>
                <Row md={12} style={{ padding: "5px", margin: "0" }}>
                  <Col
                    md={12}
                    style={{
                      // fontWeight: '600',
                      color: "#000",
                      // border: '0.5px solid #ece6e6',
                      padding: "0.1em",
                      textAlign: "right",
                    }}
                  >
                    {this.state.invoice.totalinwords}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              md={12}
              style={{ flexDirection: "column", minHeight: "1em" }}
            ></Row>
            {/* </CardBody> */}

            <Row
              md={12}
              style={{ padding: "0px", margin: "0", marginTop: "1em" }}
            >
              <AuditNoteList
                ref={this.auditNoteListRef}
                data={this.state.invoice.auditnotes}
                aid={this.state.invoice.id}
                title="Audit Notes"
                history={this.props.history}
              />
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
