import React, { Component } from "react";
import { matchPath } from "react-router-dom";
import { Collapse, Nav } from "reactstrap";
//import store from '../../../redux/store';
import Utils from "../../../provider/Utils";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import store from "../../../redux/store";
import { connect } from "react-redux";
import appDataAction from "../../../redux/appData/appDataAction";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import "./Header.scss";
import userMenusAction from "../../../redux/userMenus/userMenusAction";
import masterDataAction from "../../../redux/masterData/masterDataAction";
import OrganizationDataAction from "../../../redux/organizationData/OrganizationDataAction";
import SettingDataAction from "../../../redux/settings/SettingDataAction";
import BreadCrumbDetailsAction from "../../../redux/breadCrumbDetails/BreadCrumbDetailsAction";
import FetchServerData from "../../../provider/FetchServerData";

export class Header extends Component {
  logoData = store.getState().appData.logoData;
  userDetails = store.getState().userMenus.userDetails;
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getPageTitle = () => {
    let name;
    this.props.routes.map((prop) => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false,
        })
      ) {
        name = prop.name;
      }
      return null;
    });
    return name;
  };

  organizationInfo = () => {
    store.dispatch(appDataAction.setSettingsTab(1));
    this.props.history.push({
      pathname: "/settings",
      state: {
        infoPageId: "organizationInfo",
      },
    });
  };
  menuListToggle = () => {
    this.setState({
      menuList: !this.state.menuList,
    });
  };

  openMenu() {
    this.setState({
      menuList: true,
    });
  }
  logoutHandler() {
    let header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService("/userms/logout", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          store.dispatch(userMenusAction.setUserMenus({}));
          store.dispatch(userMenusAction.setUserDetails({}));
          // store.dispatch(userMenuAction.setUserTopMenus({}));
          store.dispatch(masterDataAction.setMasterData({}));
          store.dispatch(OrganizationDataAction.setOrganization({}));
          store.dispatch(SettingDataAction.setSettings({}));
          store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails([]));
          store.dispatch(appDataAction.setActiveMenu());
          this.props.history.push("/login");
        }
      }
    );
  }
  profileHandler = (e) => {
    if (this.props.history) {
      this.props.history.push("/admindetails");
    }
  };
  render() {
    // if (window.location.pathname === '/login') return null;
    let logoData = store.getState().appData.logoData;
    return (
      <>
        <section id="navbar">
          <nav
            className="layout-navbar navbar navbar-expand-lg fixed-top navbar-detached align-items-center bg-navbar-theme minMaxNavbar"
            style={{
              margin: "0px",
              float: "right",
              width: this.props.isSidebarCollapsed ? "85%" : "95%",
              position: "fixed",
              marginLeft: this.props.isSidebarCollapsed ? "19em" : "7em",
              zIndex: "1000",
            }}
            id="layout-navbar"
          >
            {/* <div className="container"> */}
            <div className="col-md-3 text-stert">
              <div className="row align-items-start">
                <span
                  style={{
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  {`Last Login: ${Utils.getUtcToLocalDateTime(this.userDetails?.lastLogin)} ${this.userDetails?.lastLoginsource !== null ? "(" + this.userDetails?.lastLoginsource + ")" : ""}`}
                </span>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              {logoData.length > 0 ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      {Utils.base64ToImage()}
                      <span className="ms-4"
                        style={{
                          fontSize: "18px",
                          color: "#fff",
                          fontWeight: 650,
                        }}
                      >
                        {Utils.getOrgName()}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12 d-flex">
                      <span
                        className="logoinfodot blink_me"
                        onClick={this.organizationInfo.bind(this)}
                      >
                        <Tooltip
                          placement="top"
                          title="Logo are uploaded by admin user in Settings"
                          id="password-requirements-tooltip"
                        >
                          <InfoIcon
                            className="blink_me"
                            style={{
                              fontSize: "25px",
                              color: "#fff",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      </span>
                      <span className="ms-4"
                        style={{
                          fontSize: "18px",
                          color: "#fff",
                          fontWeight: 650,
                        }}>
                        {Utils.getOrgName()}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-3 me-2" style={{ float: "right" }}>
              <button
                type="button"
                className="mobile-nav-toggle d-lg-none"
                style={{ marginRight: "0.7em" }}
                onClick={(e) => this.openMenu()}
              >
                <i className="fa fa-bars" style={{ fontSize: "1.5em" }}></i>
              </button>
              {/* <NavbarToggler
                onClick={this.toggle}
                style={{ backgroundColor: "#fff" }}
              /> */}
              <Collapse
                isOpen={this.state.isOpen}
                navbar
                style={{ float: "right" }}
              >
                <Nav
                  // className="ml-auto"
                  navbar
                // style={{ marginBottom: "-1em" }}
                >
                  <span style={{ color: "#fff", paddingTop: "24px", fontWeight: "600", cursor: "pointer" }}
                    onClick={() => this.profileHandler()}>
                    {this.userDetails?.firstName + " " + this.userDetails?.lastName}
                  </span>
                  {this.props.children}
                </Nav>
              </Collapse>
            </div>
            {/* </div> */}
          </nav>
        </section >
        <Modal
          isOpen={this.state.menuList}
          toggle={this.menuListToggle}
          size="sm"
          fade={false}
          style={{ opacity: 1 }}
        >
          <Row>
            <Col md={12}>
              <CloseIcon
                onClick={(e) => this.menuListToggle()}
                style={{ float: "right" }}
              />
            </Col>
          </Row>
          <ModalBody>
            <nav className="mobileViewNav">
              <ul>
                <li>
                  <a
                    onClick={() => this.profileHandler()}
                    target="_blank"
                    title="User Profile"
                  >
                    <i
                      className="fa fa-user-circle-o"
                      style={{ color: "#000", fontSize: "22px" }}
                    />
                  </a>
                </li>

                <li style={{ marginTop: "1em" }}>
                  <a onClick={(e) => this.logoutHandler(e)}>
                    <i
                      className="fa fa-sign-out"
                      style={{ color: "#000", fontSize: "22px" }}
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { logoData: state.appData.logoData };
};
export default connect(mapStateToProps)(Header);
