import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Autocomplete } from "@mui/material";
import FetchServerData from "../../../provider/FetchServerData";
import UiUtils from "../../../provider/UiUtils";
import Utils from "../../../provider/Utils";
import store from "../../../redux/store";
import PtsAlert from "../ptsAlert/PtsAlert";

export default class SearchHsnSacDialogue extends Component {
  serviceGoodList = ["Service", "Goods"];
  header = store.getState().header.header;

  tableIcons = UiUtils.getMaterialTableIcons();

  constructor(props) {
    super(props);
    this.state = {
      onCodeChange: props.onCodeChange,
      code: "",
      codeList: [],
      open: false,
      isPaging: false,
    };
  }
  componentWillMount() {
    this.state = { open: false };
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleServiceGoodsSelectChange(e, selected) {
    this.setState({ serviceGoods: selected });
  }
  searchSacHsnCode(e) {
    if (
      Utils.isNull(this.state.code) ||
      this.state.code.toString().trim().length < 4
    ) {
      document.getElementById("code").focus();
      PtsAlert.error("Please Enter 4 digit Code");
      return;
    }
    const postObject = {
      header: this.header,
      hsncode: this.state.code,
    };
    if (Utils.equalsIgnoreCase(this.state.serviceGoods, "GOODS")) {
      FetchServerData.callPostService("/hsns/getgoodslike", postObject).then(
        (output) => {
          if (output.status === "SUCCESS") {
            let sacCodeList = output.data;
            let codeList = [];
            for (let i = 0; i < sacCodeList.length; i++) {
              let code = sacCodeList[i];
              let obj = { code: code.goodscode, description: code.goods };
              codeList.push(obj);
            }
            let isPaging = false;
            if (codeList.length > 10) {
              isPaging = true;
            }
            this.setState({ codeList: codeList, isPaging: isPaging });
          }
        }
      );
    } else {
      FetchServerData.callPostService("/hsns/getServiceLike", postObject).then(
        (output) => {
          if (output.status === "SUCCESS") {
            let sacCodeList = output.data;
            let codeList = [];
            for (let i = 0; i < sacCodeList.length; i++) {
              let code = sacCodeList[i];
              let obj = { code: code.servicecode, description: code.service };
              codeList.push(obj);
            }
            let isPaging = false;
            if (codeList.length > 10) {
              isPaging = true;
            }
            this.setState({ codeList: codeList, isPaging: isPaging });
          }
        }
      );
    }
  }
  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  hsnCodeSelected(e, selectedCode) {
    if (Utils.isNotNull(this.props.onCodeChange)) {
      this.props.onCodeChange(selectedCode);
      this.setState({ open: false });
    }
  }
  render() {
    return (
      <>
        <SearchIcon
          style={{
            cursor: "pointer",
            background: "#1b7189",
            color: "#fff",
            // background: "#1b7189",
            // color: "#fff",
            // marginRight: "1rem",
            height: "40.8px",
            padding: "4px",
            width: "40px",
            // marginTop: "-5px",
            marginRight: "-0.7em",
          }}
          onClick={this.handleOpen.bind(this)}
        />

        <Dialog
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          fullWidth={true}
          maxWidth="xl"
        >
          <DialogTitle style={{ background: "#282929", color: "#fff", padding: "6px 10px 6px 20px" }}>
            <label style={{ color: "#fff", fontWeight: 600 }}>
              Search SAC/HSN
            </label>
          </DialogTitle>
          <DialogContent style={{ margin: "10px", padding: "0" }}>
            {/* <ValidatorForm onSubmit={this.searchSacHsnCode.bind(this)}> */}
            <Row md={12} style={{ margin: "0", padding: "10px" }}>
              <Col className="mt-2" md={4}>
                <Autocomplete
                  disableClearable
                  ListboxProps={{
                    className: "myCustomList"
                  }}
                  id="businessUnit"
                  size="small"
                  options={
                    undefined === this.serviceGoodList ||
                      null === this.serviceGoodList
                      ? []
                      : this.serviceGoodList
                  }
                  value={this.state.serviceGoods}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    this.handleServiceGoodsSelectChange(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth={true}
                      variant="outlined"
                      className="regCountry"
                      {...params}
                      placeholder="Select"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Col>
              <Col md={4} style={{ padding: "5px", textAlign: "left" }}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth={true}
                  variant="outlined"
                  id="code"
                  onChange={this.handleCodeChange.bind(this)}
                  value={this.state.code ? this.state.code : ""}
                  placeholder="Enter Code"
                />
              </Col>
              <Col md={4} style={{ margin: "auto" }}>
                <Button
                  // type="submit"
                  variant="contained"
                  // className="codeBtn"
                  size="small"
                  style={{
                    background: "#1b7189",
                    color: "#fff",
                    // marginRight: "1rem",
                    height: "38px",
                    padding: "5px",
                    width: "120px",
                    // marginRight: "-0.7em",
                  }}
                  startIcon={<SearchIcon />}
                  onClick={this.searchSacHsnCode.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
            {/* </ValidatorForm> */}
            <Row md={12}>
              <MaterialTable
                icons={this.tableIcons}
                title=""
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        height: "0px",
                      }}
                    >
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
                columns={[
                  {
                    title: "#",
                    render: (rowData) => rowData.tableData.id + 1,
                    cellStyle: {
                      width: "5%",
                    }
                  },
                  {
                    title: "SAC/HSN",
                    field: "code",
                    align: "left",
                    cellStyle: {
                      width: "40%",
                    }
                  },
                  {
                    title: "Description",
                    field: "description",
                    align: "left",
                    cellStyle: {
                      width: "55%",
                    }
                  },
                ]}
                data={this.state.codeList ? this.state.codeList : []}
                options={{
                  sorting: true,
                  search: false,
                  actionsColumnIndex: -1,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  pageSize: 10,
                  paging: this.state.isPaging,
                  headerStyle: {
                    backgroundColor: "#717373",
                    fontWeight: "bold",
                    color: "#fff",
                    align: "center",
                  },
                  rowStyle: { fontSize: "small" },
                }}
                style={{
                  boder: "1px solid black",
                  width: "100%",
                }}
                //   actions={actionList}
                onRowClick={(e, rowData) => this.hsnCodeSelected(e, rowData)}
              />
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              // startIcon={<ArrowBackIcon />}
              onClick={this.handleClose.bind(this)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
