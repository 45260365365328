import React, { Component } from 'react'
import MaterialTable from 'material-table'
import AdminFetchServerData from "../../../provider/AdminFetchServerData";
import store from "../../../redux/store";
import uiUtils from '../../../provider/UiUtils';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import ApplicationStateAction from '../../../redux/applicationState/ApplicationStateAction';
class AdminRegistrationList extends Component {
	header = store.getState().adminHeader.adminHeader;
	title = 'Registration List';
	breadCrumb = [{ pageid: '', uiname: 'Registration List' }];
	constructor() {
		super();
		this.state = {
			activeRegistrationList: [],
			initialPage: 0,
			pageSize: 10,
			isPaging: false,
			render: false,
		};
		this.getRegistrationsList();
	}

	getRegistrationsList() {
		const postObject = {
			header: this.header,
		};

		AdminFetchServerData.callPostService('/adminpanelStatsms/getAccounts', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				let activeRegistrationList = output.data.tenentList;
				let isPaging = false;
				if (activeRegistrationList.length > this.state.pageSize) {
					isPaging = true;
				}
				this.setState({
					render: true,
					isPaging: isPaging,
					activeRegistrationList: activeRegistrationList,
					initialPage: this.state.initialPage,
				});
			} else {
			}
		});
	}

	toRegistrationDetails(e, data) {
		let adminRegistrationState = {
			registrationId: data.id,
			initialPage: this.state.initialPage,
			pageSize: this.state.pageSize,
		};
		store.dispatch(ApplicationStateAction.setApplicationState({ adminRegistrationState }));
		this.props.history.push({
			pathname: "/viewRegistration",
			state: {
				action: "view",
				registrationId: data.id,
			},
		});
	}

	render() {
		return (
			<>
				<AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
				<div className="mt-4" style={{ maxWidth: '100%' }}>
					<MaterialTable
						title=""
						icons={uiUtils.getMaterialTableIcons()}
						onRowClick={(event, rowData) => {
							this.toRegistrationDetails(event, rowData);
							event.stopPropagation();
						}}
						localization={{
							body: {
								emptyDataSourceMessage: "Data not found",
							},
						}}
						columns={[
							{
								title: '#',
								render: (rowData) => rowData.tableData.id + 1,
								width: '5%',
								align: 'left',
								cellStyle: {
									width: '6%',
								},
							},
							{
								title: 'Company Name',
								field: 'name',
								cellStyle: {
									width: '18%',
								},
							},
							{
								title: 'Email',
								field: 'email',
								cellStyle: {
									width: '18%',
								},
							},
							{
								title: 'GSTN',
								field: 'gstn',
								cellStyle: {
									width: '18%',
								},
							},
							{
								title: 'Disabled Reason',
								field: 'disabledReason',
								cellStyle: {
									width: '18%',
								},
							},
							{
								title: 'Is Disabled',
								field: 'isdisabled',
								cellStyle: {
									width: '18%',
								},
							},
						]}
						data={this.state.activeRegistrationList}
						options={{
							sorting: true,
							actionsColumnIndex: -1,
							initialPage: this.state.initialPage,
							pageSize: this.state.pageSize,
							pageSizeOptions: [5, 10, 20, 50, 100],
							paging: this.state.isPaging,
							headerStyle: {
								backgroundColor: '#1b7189',
								fontWeight: 'bold',
								color: "#ffff",
								align: 'left',
							},
							rowStyle: (rowData) => ({
								backgroundColor: rowData.id === this.state.userId ? '#FEF5E7' : '',
								height: '3em',
								textTransform: 'capitalize',
							}),
						}}
					// onChangePage={(page, pageSize) => {
					// 	this.handleMaterialTablePageChange(page, pageSize);
					// }}
					/>
				</div>
			</>
		)
	}
}
export default AdminRegistrationList;