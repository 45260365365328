import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import { format } from "date-fns";
import MaterialTable from "material-table";
import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import PaymentsIcon from "@mui/icons-material/Payments";
import Badge from "@material-ui/core/Badge";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Switch, createMuiTheme } from "@material-ui/core";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row } from "reactstrap";
import FetchServerData from "../../../../provider/FetchServerData";
import UiUtils from "../../../../provider/UiUtils";
import Utils from "../../../../provider/Utils";
import appDataAction from "../../../../redux/appData/appDataAction";
import store from "../../../../redux/store";
import MenuWithBreadScrum from "../../menuBox/MenuWithBreadScrum";
import PtsAlert from "../../ptsAlert/PtsAlert";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import moment from "moment";
import { Menu } from "@mui/material";
import { Buffer } from "buffer";
import ApplicationStateAction from "../../../../redux/applicationState/ApplicationStateAction";
import PaymentDetailsDialogue from "../../payments/PaymentDetailsDialogue";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#008181",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#008181",
      },
    },
  },
});
export class SalesHistory extends Component {
  header = store.getState().header.header;
  customerList = store.getState().master.masterData.customers;
  businessUnitList = store.getState().master.masterData.businessUnits;
  salesHistoryState = store.getState().applicationState.salesHistoryState;
  financialsYearArray = store.getState().appData.fyList;
  salesHistoryStateDetails = {};
  breadCrumb = [
    { pageid: "/history", uiname: "History", cssclass: "fa fa-history" },
    { pageid: "/sales_invoicesearch", uiname: "Sales History List" },
  ];
  title = "Sales History";
  salesHeaderTabList = ["Search By Date", "Search By Number"];
  constructor(props) {
    super(props);
    let allCustomers = {
      name: "All",
    };
    const customerList = [allCustomers, ...this.customerList];
    this.tableIcons = UiUtils.getMaterialTableIcons();
    let salesHistoryData = store.getState().appData.historySalesSearchData;
    if (this.salesHistoryState) {
      this.salesHistoryStateDetails = Object.getOwnPropertyNames(
        this.salesHistoryState
      );
    }
    if (this.salesHistoryStateDetails.length > 0) {
      this.state = {
        invoiceId: this.salesHistoryState.invoiceId,
        pageSize: this.salesHistoryState.pageSize,
        initialPage: this.salesHistoryState.initialPage,
        isAsset: this.salesHistoryState.isAsset,
        selectedCustomer: this.salesHistoryState.selectedCustomer,
        selectedBusinessUnit: this.salesHistoryState.selectedBusinessUnit,
        selectedTabIndex:
          salesHistoryData && salesHistoryData.selectedTabIndex
            ? salesHistoryData.selectedTabIndex
            : 0,
        selectedHeaderTabIndex:
          salesHistoryData && salesHistoryData.selectedHeaderTabIndex
            ? salesHistoryData.selectedHeaderTabIndex
            : 0,
        value: 0,
        startDate: this.salesHistoryState.startDate,
        endDate: this.salesHistoryState.endDate,
        financialsYear: this.salesHistoryState.financialsYear,
        invoiceTypeArray: [],
        invoiceStatusArray: [],
        selectedInvoice: this.salesHistoryState.selectedInvoice,
        selectedExcelInvoice: null,
        invoiceListArr: [],
        invNumber: "",
        isPaging: false,
        isFirstTimeLoading: true,
        open: false,
        searchSaleHistoryDetails: salesHistoryData,
        downloadOpen: false,
        isExcelAsset: false,
        excelStartDate: null,
        excelEndDate: null,
        excelFinancialsYear: null,
        customerList: customerList,
        excelError: false,
        excelErrorMassage: false,
        swichCheck: false,
        excelSwichCheck: false,
        errorMassage: false,
        error: false,
        render: false,
      };
    } else {
      this.state = {
        selectedTabIndex:
          salesHistoryData && salesHistoryData.selectedTabIndex
            ? salesHistoryData.selectedTabIndex
            : 0,
        selectedHeaderTabIndex:
          salesHistoryData && salesHistoryData.selectedHeaderTabIndex
            ? salesHistoryData.selectedHeaderTabIndex
            : 0,
        value: 0,
        startDate: null,
        endDate: null,
        invoiceTypeArray: [],
        invoiceStatusArray: [],
        selectedInvoice: null,
        selectedExcelInvoice: null,
        invoiceListArr: [],
        invNumber: "",
        initialStep: 0,
        pageSize: 10,
        isAsset: false,
        isExcelAsset: false,
        isPaging: false,
        isFirstTimeLoading: false,
        open: false,
        searchSaleHistoryDetails: salesHistoryData,
        downloadOpen: false,
        financialsYear: null,
        excelStartDate: null,
        excelEndDate: null,
        excelFinancialsYear: null,
        selectedCustomer: customerList[0],
        selectedExcelCustomer: customerList[0],
        customerList: customerList,
        selectedBusinessUnit: this.businessUnitList[0],
        errorMassage: false,
        error: false,
        excelError: false,
        excelErrorMassage: false,
        swichCheck: false,
        excelSwichCheck: false,
        render: false,
      };
    }
    store.dispatch(appDataAction.setHistorySalesTab(0));
    this.onTagsChange = this.onTagsChange.bind(this);
    if (salesHistoryData) {
      this.getSalesHistoryData(this.state.isFirstTimeLoading);
    }
  }
  componentDidMount() {
    this.getSalesHistoryStaticData();
  }
  getSalesHistoryStaticData = () => {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
    };
    FetchServerData.callPostService(
      "/consolidatems/getInvTypes",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let history = output.data;
        let invoiceTypeListArray = history.invoiceTypes;
        let invoiceStatusList = history.invoiceStatusList;
        let allInvoiceType = {
          description: "All",
        };
        const invoiceTypeList = [allInvoiceType, ...invoiceTypeListArray];
        this.setState({
          invoiceTypeArray: invoiceTypeList,
          selectedInvoice: invoiceTypeList[0],
          selectedExcelInvoice: invoiceTypeList[0],
          invoiceStatusArray: invoiceStatusList,
        });
      } else {
      }
    });
  };

  getSalesHistoryData = (reset) => {
    if (this.state.errorMassage || this.state.error) {
      return false;
    }
    let financialYear = this.state.financialsYear;

    let historyData = {
      selectedCustomer: this.state.selectedCustomer,
      financialsYear: financialYear,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
      isAsset: this.state.isAsset,
      invNumber: this.state.invNumber,
      selectedInvoice: this.state.selectedInvoice,
      selectedTabIndex: this.state.selectedTabIndex,
      selectedHeaderTabIndex: this.state.selectedHeaderTabIndex,
    };
    store.dispatch(appDataAction.setHistorySalesSearchData(historyData));
    if (this.state.selectedHeaderTabIndex === 0) {
      let invoiceTypeArray = [this.state.selectedInvoice?.type];

      const postObject = {
        header: this.header,
        custid: this.state.selectedCustomer?.id,
        financialYear: financialYear,
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        invtypes: invoiceTypeArray,
        bu: {
          id: this.state.selectedBusinessUnit?.id,
          code: this.state.selectedBusinessUnit?.code,
        },
        isAsset: this.state.isAsset,
      };
      FetchServerData.callPostService(
        "/invoicesearchms/byDate",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let invoiceData = output.data;
          let invoiceList = output.data.invoiceList;
          for (let i = 0; i < invoiceList.length; i++) {
            invoiceList[i].invdate = new Date(invoiceList[i].invdate);
            invoiceList[i].taxable = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].taxable.toFixed(2)}`;
            invoiceList[i].total = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].total.toFixed(2)}`;
          }
          let isPaging = false;
          if (invoiceList.length > 10) {
            isPaging = true;
          }
          this.setState({
            invoiceListArr: invoiceList,
            financialsYear: invoiceData.financialYear,
            excelFinancialsYear: invoiceData.financialYear,
            excelStartDate:
              invoiceData.financialYear === null ? invoiceData.fromDate : null,
            excelEndDate:
              invoiceData.financialYear === null ? invoiceData.toDate : null,
            defaultFinancialYear: invoiceData.financialYear,
            defaultStartDate: invoiceData.fromDateStr,
            defaultEndDate: invoiceData.toDateStr,
            startDate: invoiceData.fromDate,
            endDate: invoiceData.toDate,
            swichCheck: financialYear === null ? true : false,
            excelSwichCheck: financialYear === null ? true : false,
            isPaging: isPaging,
            isFirstTimeLoading: reset ? true : false,
            open: false,
            render: true,
          });
        } else {
        }
      });
    }
    if (this.state.selectedHeaderTabIndex === 1) {
      let invoiceTypeArray = [];
      for (
        let i = 0;
        this.state.selectedInvoice && i < this.state.selectedInvoice.length;
        i++
      ) {
        invoiceTypeArray.push(this.state.selectedInvoice[i].type);
      }
      const postObject = {
        header: this.header,
        invnumber: this.state.invNumber,
      };
      FetchServerData.callPostService(
        "/invoicesearchms/byNumber",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          // let invoiceData = output.data;
          let invoiceList = output.data;
          for (let i = 0; i < invoiceList.length; i++) {
            invoiceList[i].invdate = new Date(invoiceList[i].invdate);
            invoiceList[i].taxable = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].taxable.toFixed(2)}`;
            invoiceList[i].total = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].total.toFixed(2)}`;
          }
          this.setState({
            invoiceListArr: invoiceList,
            isFirstTimeLoading: reset ? true : false,
            open: false,
            render: true,
          });
        } else {
        }
      });
    }
  };

  resetSalesHistoryData = (e) => {
    // if (Utils.isNull(this.state.selectedHeaderTabIndex)) {
    //   this.state.selectedHeaderTabIndex = 0;
    // }
    // if (this.state.selectedHeaderTabIndex === 0) {
    //   if (
    //     Utils.isNullOrEmpty(this.state.startDate) ||
    //     Utils.isNullOrEmpty(this.state.endDate)
    //   ) {
    //     return;
    //   }
    // }

    let historyData = {
      selectedCustomer: null,
      financialsYear: null,
      startDate: null,
      endDate: null,
      isAsset: false,
      invNumber: "",
      selectedInvoice: null,
      selectedTabIndex: this.state.selectedTabIndex,
      selectedHeaderTabIndex: this.state.selectedHeaderTabIndex,
    };
    store.dispatch(appDataAction.setHistorySalesSearchData(historyData));
    if (this.state.selectedHeaderTabIndex === 0) {
      const postObject = {
        header: this.header,
        selectedCustomer: null,
        financialYear: null,
        fromDate: "",
        toDate: "",
        invtypes: [null],
        bucode: null,
        isAsset: false,
      };
      FetchServerData.callPostService(
        "/invoicesearchms/byDate",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let invoiceData = output.data;
          let invoiceList = output.data.invoiceList;
          for (let i = 0; i < invoiceList.length; i++) {
            invoiceList[i].invdate = new Date(invoiceList[i].invdate);
            invoiceList[i].taxable = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].taxable.toFixed(2)}`;
            invoiceList[i].total = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].total.toFixed(2)}`;
          }
          let isPaging = false;
          if (invoiceList.length > 10) {
            isPaging = true;
          }
          this.setState({
            invoiceListArr: invoiceList,
            isPaging: isPaging,
            financialsYear: invoiceData.financialYear,
            excelFinancialsYear: invoiceData.financialYear,
            excelStartDate:
              invoiceData.financialYear === null ? invoiceData.fromDate : null,
            excelEndDate:
              invoiceData.financialYear === null ? invoiceData.toDate : null,
            startDate: invoiceData.fromDate,
            endDate: invoiceData.toDate,
            defaultFinancialYear: invoiceData.financialYear,
            defaultStartDate: invoiceData.fromDateStr,
            defaultEndDate: invoiceData.toDateStr,
            selectedCustomer: this.state.customerList[0],
            selectedInvoice: this.state.invoiceTypeArray[0],
            selectedBusinessUnit: this.businessUnitList[0],
            swichCheck: this.state.financialsYear === null ? true : false,
            excelSwichCheck: this.state.financialsYear === null ? true : false,
            isFirstTimeLoading: false,
            isAsset: false,
            open: false,
            errorMassage: false,
            render: true,
          });
        } else {
        }
      });
    }
    if (this.state.selectedHeaderTabIndex === 1) {
      let invoiceTypeArray = [];
      for (
        let i = 0;
        this.state.selectedInvoice && i < this.state.selectedInvoice.length;
        i++
      ) {
        invoiceTypeArray.push(this.state.selectedInvoice[i].type);
      }
      const postObject = {
        header: this.header,
        invnumber: "",
      };
      FetchServerData.callPostService(
        "/invoicesearchms/byNumber",
        postObject
      ).then((output) => {
        if (output.status === "SUCCESS") {
          let invoiceList = output.data;
          for (let i = 0; i < invoiceList.length; i++) {
            invoiceList[i].invdate = new Date(invoiceList[i].invdate);
            invoiceList[i].taxable = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].taxable.toFixed(2)}`;
            invoiceList[i].total = `${
              invoiceList[i].currencySymobol
            } ${invoiceList[i].total.toFixed(2)}`;
          }
          this.setState({
            invoiceListArr: invoiceList,
            isFirstTimeLoading: false,
            open: false,
            errorMassage: false,
            render: true,
          });
        } else {
        }
      });
    }
  };

  downloadDayExcelFromBackend() {
    if (this.state.excelErrorMassage || this.state.excelError) {
      return false;
    }

    const header = store.getState().header.header;
    let invoiceTypeArray = this.state.selectedExcelInvoice?.type;
    let bucode = this.state.selectedBusinessUnit?.code;
    const postObject = {
      header: header,
      financialYear: this.state.excelFinancialsYear,
      fromDate: this.state.excelStartDate,
      toDate: this.state.excelEndDate,
      isAsset: this.state.isExcelAsset,
      invoiceType: invoiceTypeArray,
      customerId: this.state.selectedExcelCustomer?.id,
      bucode: bucode,
    };

    FetchServerData.callPostService(
      "/impexp/exportSalesInvoices",
      postObject
    ).then((output) => {
      let salesExcelData = output.data;
      if (output.status === "SUCCESS") {
        const byteArray = new Buffer(
          salesExcelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );

        const outputFilename = salesExcelData.filename;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        this.setState({
          // excelStartDate: null,
          // excelEndDate: null,
          // excelFinancialsYear: null,
          selectedBusinessUnit: this.businessUnitList[0],
          selectedCustomer: this.state.customerList[0],
          selectedExcelInvoice: this.state.invoiceTypeArray[0],
          downloadOpen: false,
          excelErrorMassage: false,
          isExcelAsset: false,
        });
      } else {
      }
    });
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleStartDateChange(date) {
    let fromDate = Date.parse(date);
    let toDate = Date.parse(this.state.endDate);
    if (this.state.endDate === null && date === null) {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    } else if (date === null) {
      this.setState({
        startDate: date,
        errorMassage: true,
        error: false,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        startDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        startDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    }
  }
  handleSwichCheckChange(e) {
    this.setState({ swichCheck: e.target.checked });
  }
  handleExcelSwichCheckChange(e) {
    this.setState({ excelSwichCheck: e.target.checked });
  }

  updateBusinessUnit(e, name) {
    this.setState({ selectedBusinessUnit: name });
  }

  handleEndDateChange(date) {
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(date);

    if (this.state.startDate === null && date === null) {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    } else if (this.state.startDate === null) {
      this.setState({
        endDate: date,
        errorMassage: true,
        error: false,
        financialsYear: null,
      });
    } else if (fromDate > toDate) {
      this.setState({
        endDate: date,
        error: true,
        errorMassage: false,
        financialsYear: null,
      });
    } else {
      this.setState({
        endDate: date,
        errorMassage: false,
        error: false,
        financialsYear: null,
      });
    }
  }

  handleExcelStartDateChange(exdate) {
    let startDate = Date.parse(exdate);
    let endDate = Date.parse(this.state.excelEndDate);

    if (this.state.excelEndDate === null && exdate === null) {
      this.setState({
        excelStartDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (exdate === null) {
      this.setState({
        excelStartDate: exdate,
        excelErrorMassage: true,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelStartDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelStartDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    }
  }

  handleExcelEndDateChange(exdate) {
    let startDate = Date.parse(this.state.excelStartDate);
    let endDate = Date.parse(exdate);

    if (this.state.excelStartDate === null && exdate === null) {
      this.setState({
        excelEndDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (this.state.excelStartDate === null) {
      this.setState({
        excelEndDate: exdate,
        excelErrorMassage: true,
        excelError: false,
        excelFinancialsYear: null,
      });
    } else if (startDate > endDate) {
      this.setState({
        excelStartDate: exdate,
        excelError: true,
        excelErrorMassage: false,
        excelFinancialsYear: null,
      });
    } else {
      this.setState({
        excelEndDate: exdate,
        excelErrorMassage: false,
        excelError: false,
        excelFinancialsYear: null,
      });
    }
  }

  updateFinancialYear(e, year) {
    this.setState({ financialsYear: year, startDate: null, endDate: null });
  }

  updateExcelFinancialYear(e, year) {
    this.setState({
      excelFinancialsYear: year,
      excelStartDate: null,
      excelEndDate: null,
    });
  }

  handleInvoiceTypeChange(e) {
    this.setState({ selectedInvoice: e.target.value });
  }

  handleAssetChange(e) {
    this.setState({ isAsset: e.target.checked });
  }

  handleExcelAssetChange(e) {
    this.setState({ isExcelAsset: e.target.checked });
  }

  updateCustomer(e, customer) {
    this.setState({ selectedCustomer: customer });
  }
  updateExcelCustomer(e, customer) {
    this.setState({ selectedExcelCustomer: customer });
  }

  handleInvoiceNumberChange(e) {
    this.setState({ invNumber: e.target.value });
  }
  onTagsChange = (event, values) => {
    this.setState({
      selectedInvoice: values,
    });
  };
  onExcelTypeChange = (event, values) => {
    this.setState({
      selectedExcelInvoice: values,
    });
  };
  selectedInvoiceHeaderTab(tabName, index) {
    store.dispatch(appDataAction.setHistorySalesHeaderTab(index));

    this.setState({ selectedHeaderTabIndex: index });
  }
  selectedTab(tabName, index) {
    store.dispatch(appDataAction.setHistorySalesTab(index));
    this.setState({ selectedTabIndex: index });
  }

  paymentDetails(invoiceId) {
    if (
      this.referenceInvoiceDialogueRef &&
      this.referenceInvoiceDialogueRef.current
    ) {
      this.referenceInvoiceDialogueRef.current.showPaymentDetails(invoiceId);
    }
  }

  getTablesWithStatus(status) {
    let filteredData = this.state.invoiceListArr?.filter(function (e) {
      return e.status === status;
    });
    if (status === "All") {
      filteredData = this.state.invoiceListArr;
    }

    return (
      <Row md={12} style={{ padding: "0em 0.3em" }}>
        <div style={{ width: "100%" }}>
          <MaterialTable
            icons={this.tableIcons}
            title=""
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  width: "5%",
                },
              },
              {
                title: "Invoice Date",
                render: (rowData) => format(rowData.invdate, "dd-MMM-yyyy"),
                customSort: (a, b) => {
                  return (
                    new Date(a.invdate).getTime() -
                    new Date(b.invdate).getTime()
                  );
                },
                cellStyle: {
                  width: "12%",
                },
              },
              {
                title: "Invoice No",
                field: "number",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Customer Name",
                field: "custname",
                cellStyle: {
                  width: "20%",
                  wordBreak: "break-word",
                },
              },
              {
                title: "Taxable Amount",
                field: "taxable",
                align: "right",
                cellStyle: {
                  width: "14%",
                },
              },
              {
                title: "Invoice Amount",
                field: "total",
                align: "right",
                cellStyle: {
                  width: "14%",
                },
              },
              {
                title: "Status",
                field: "status",
                cellStyle: {
                  width: "15%",
                },
              },
            ]}
            data={filteredData}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              paging: filteredData.length > this.state.pageSize,
              pageSize: this.state.pageSize,
              initialPage: this.state.initialPage,
              pageSizeOptions: Utils.materialTablePageSize(),
              headerStyle: {
                backgroundColor: "#1b7189",
                fontWeight: "bold",
                color: "#fff",
                align: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.id === this.state.invoiceId ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            style={{
              boder: "1px solid black",
            }}
            actions={[
              (rowData) => ({
                icon: () =>
                  rowData.hasPayment === true ? (
                    <>
                      <Badge color="primary" overlap="rectangular">
                        <PaymentsIcon
                          className="message"
                          style={{
                            fontSize: "25px",
                            alignItems: "center",
                            marginLeft: "20px",
                            color: "#1b7189",
                          }}
                        />
                      </Badge>
                    </>
                  ) : (
                    <div
                      style={{ marginLeft: "1.4em", fontSize: "1.5rem" }}
                    ></div>
                  ),
                onClick: () => this.paymentDetails(rowData.id),
                tooltip: "Payment Details",
              }),
            ]}
            onRowClick={(e, rowData) => this.toSalesInvoiceDetails(e, rowData)}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
          />
        </div>
      </Row>
    );
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  toSalesInvoiceDetails = (e, data) => {
    let salesHistoryState = {
      invoiceId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      selectedInvoice: this.state.selectedInvoice,
      selectedCustomer: this.state.selectedCustomer,
      selectedBusinessUnit: this.state.selectedBusinessUnit,
      isAsset: this.state.isAsset,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ salesHistoryState })
    );
    if (Utils.equalsIgnoreCase(data.invoicetype, "Sales Voucher")) {
      this.props.history.push({
        pathname: "/salesVoucher",
        state: {
          invoiceId: data.id,
          action: "view",
          breadCrumb: this.breadCrumb,
          isHistory: true,
        },
      });
    } else if (Utils.equalsIgnoreCase(data.invoicetype, "Advance Voucher")) {
      this.props.history.push({
        pathname: "/receipt",
        state: {
          invoiceId: data.id,
          action: "view",
          breadCrumb: this.breadCrumb,
          isHistory: true,
        },
      });
    } else if (Utils.equalsIgnoreCase(data.invoicetype, "Refund Voucher")) {
      this.props.history.push({
        pathname: "/receipt",
        state: {
          invoiceId: data.id,
          action: "view",
          breadCrumb: this.breadCrumb,
          isHistory: true,
        },
      });
    } else if (Utils.equalsIgnoreCase(data.invoicetype, "Credit Note")) {
      this.props.history.push({
        pathname: "/creditNote",
        state: {
          invoiceId: data.id,
          action: "view",
          breadCrumb: this.breadCrumb,
          isHistory: true,
        },
      });
    } else if (Utils.equalsIgnoreCase(data.invoicetype, "Debit Note")) {
      this.props.history.push({
        pathname: "/debitNote",
        state: {
          invoiceId: data.id,
          action: "view",
          breadCrumb: this.breadCrumb,
          isHistory: true,
        },
      });
    } else if (Utils.equalsIgnoreCase(data.invoicetype, "RF")) {
      this.props.history.push({
        pathname: "/refundVoucher",
        state: {
          invoiceId: data.id,
          action: "view",
          breadCrumb: this.breadCrumb,
          isHistory: true,
        },
      });
    }
  };
  getInvoiceHeaderTab(keyindex) {
    if (keyindex === 0) {
      return (
        <>
          <div className="row mt-2">
            <div className="col-md-12" id="assetFormLabel">
              <h5 className="bold">Filter</h5>
              <label className="form-label " htmlFor="collapsible-supplierType">
                Customer
              </label>
              <Autocomplete
                fullWidth
                ListboxProps={{
                  className: "myCustomList",
                }}
                variant="outlined"
                id="customerList"
                options={this.state.customerList}
                value={this.state.selectedCustomer}
                defaultValue={this.state.selectedCustomer}
                getOptionLabel={(option) => option.name}
                noOptionsText="Select Customer"
                onChange={(event, value) => {
                  this.updateCustomer(event, value);
                }}
                style={{ background: "#fff", borderRadius: "5px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="regCountry"
                    placeholder="Customer"
                    InputLabelProps={{
                      fontSize: "small !important",
                      style: { fontSize: "small !important" },
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="row mt-2 d-flex">
            <div className="col-md-12" id="assetFormLabel">
              <FormControlLabel
                style={{
                  color: "#000",
                  width: "100%",
                  margin: "0px",
                  padding: "0",
                }}
                control={
                  <Switch
                    variant="outlined"
                    // id="isAsset"
                    checked={this.state.swichCheck}
                    value={this.state.swichCheck}
                    onChange={this.handleSwichCheckChange.bind(this)}
                    style={{ background: "none", margin: "8px" }}
                  />
                }
                label={<span>Custom search </span>}
              />
            </div>
          </div>
          <div className="row mt-1">
            {!this.state.swichCheck && (
              <div className="col-md-12" id="assetFormLabel">
                <label
                  className="form-label "
                  // htmlFor="collapsible-companyName"
                >
                  Financial Year
                </label>
                <br />
                <Autocomplete
                  fullWidth
                  ListboxProps={{
                    className: "myCustomList",
                  }}
                  variant="outlined"
                  id="supplierList"
                  options={this.financialsYearArray}
                  value={this.state.financialsYear}
                  defaultValue={this.state.financialsYear}
                  getOptionLabel={(option) => option}
                  style={{ background: "#fff", borderRadius: "5px" }}
                  onChange={(event, value) => {
                    this.updateFinancialYear(event, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Financial Year"
                      className="regCountry"
                      InputLabelProps={{
                        fontSize: "small !important",
                        style: { fontSize: "small !important" },
                      }}
                    />
                  )}
                />
              </div>
            )}
            {this.state.swichCheck && (
              <>
                <div className="col-md-6" id="assetFormLabel">
                  <label
                    className="form-label "
                    // htmlFor="collapsible-companyName"
                  >
                    Start Date
                  </label>
                  <br />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        clearable
                        value={this.state.startDate}
                        // variant="inline"
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="ptsDateTimePicker"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        onChange={this.handleStartDateChange.bind(this)}
                        helperText={
                          this.state.errorMassage
                            ? "Start Date is Required"
                            : ""
                        }
                        error={this.state.errorMassage}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-md-6" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    End Date
                  </label>{" "}
                  <br />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        clearable
                        value={this.state.endDate}
                        // variant="inline"
                        margin="normal"
                        placeholder="DD-MM-YYYY"
                        fullWidth
                        variant="outlined"
                        id="ptsDateTimePicker"
                        inputVariant="outlined"
                        format="dd-MM-yyyy"
                        onChange={this.handleEndDateChange.bind(this)}
                        helperText={
                          this.state.endDateErrorMassage
                            ? "End Date is Required"
                            : ""
                        }
                        error={this.state.endDateErrorMassage}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </div>
                {this.state.error ? (
                  <lable className="errorMassageShow">
                    End Date should be after Start Date
                  </lable>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <div className="row mt-2">
            <div className="col-md-6" id="assetFormLabel">
              <label className="form-label " htmlFor="collapsible-companyName">
                Invoice Type
              </label>
              <Autocomplete
                // multiple
                size="small"
                ListboxProps={{
                  className: "myCustomList",
                }}
                id="tags-outlined"
                style={{ background: "#fff", borderRadius: "5px" }}
                options={
                  this.state.invoiceTypeArray ? this.state.invoiceTypeArray : []
                }
                getOptionLabel={(option) => option.description}
                value={
                  this.state.selectedInvoice ? this.state.selectedInvoice : null
                }
                defaultValue={
                  this.state.selectedInvoice ? this.state.selectedInvoice : null
                }
                filterSelectedOptions
                // onChange={this.onTagsChange.bind(this)}
                onChange={(event, value) => {
                  this.onTagsChange(event, value);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="outlined"
                    className="regCountry"
                    InputLabelProps={{
                      shrink: true,
                      style: {},
                    }}
                    placeholder="Invoice Type"
                  />
                )}
              />
            </div>
            <div className="col-md-6" id="assetFormLabel">
              <label className="form-label " htmlFor="collapsible-companyName">
                Business Unit
              </label>
              <Autocomplete
                fullWidth
                ListboxProps={{ className: "myCustomList" }}
                variant="outlined"
                id="businessUnit"
                options={this.businessUnitList}
                value={this.state.selectedBusinessUnit}
                defaultValue={this.state.selectedBusinessUnit}
                getOptionLabel={(option) => option.name}
                noOptionsText="Select Business Unit"
                style={{ background: "#fff", borderRadius: "5px" }}
                onChange={(event, value) => {
                  this.updateBusinessUnit(event, value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="regCountry"
                    placeholder="Business Unit"
                    variant="outlined"
                    InputLabelProps={{
                      fontSize: "small !important",
                      style: { fontSize: "small !important" },
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-2" id="assetFormLabel">
              <FormControlLabel
                style={{
                  color: "#000",
                  width: "100%",
                  margin: "0",
                  padding: "0",
                }}
                control={
                  <Checkbox
                    variant="outlined"
                    id="isAsset"
                    checked={this.state.isAsset}
                    value={this.state.isAsset}
                    onChange={this.handleAssetChange.bind(this)}
                    style={{ background: "none" }}
                  />
                }
                label={<span style={{}}>Is asset sales?</span>}
              />
            </div>
            <div className="col-md-12 mt-4">
              <button
                className="fileterButton"
                // onClick={this.onFilterClick.bind(this)}
                // onClick={(e) => this.filterRecords(e)}
                onClick={this.getSalesHistoryData.bind(this)}
              >
                <i className="fa-solid fa-filter"></i>
                &nbsp; Filter
              </button>
              &nbsp; &nbsp;
              <button
                className="resetButton"
                onClick={this.resetFilter.bind(this)}
              >
                <i className="fa fa-refresh"></i>
                &nbsp; Reset
              </button>
            </div>
          </div>
        </>
      );
    } else if (keyindex === 1) {
      return (
        <div className="row">
          <h5 className="bold">Filter</h5>
          <div className="row">
            <div className="col-md-12" id="assetFormLabel">
              <label className="form-label" htmlFor="collapsible-companyName">
                Invoice Number
              </label>
              <br />
              <TextField
                // margin="normal"
                variant="outlined"
                style={{ background: "#fff", borderRadius: "5px" }}
                required
                fullWidth
                id="invoiceNumber"
                onChange={this.handleInvoiceNumberChange.bind(this)}
                placeholder="Invoice Number"
                name="invoiceNumber"
                type="text"
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                value={this.state.invNumber ? this.state.invNumber : ""}
              />
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <button
              className="fileterButton"
              // onClick={this.onFilterClick.bind(this)}
              // onClick={(e) => this.filterRecords(e)}
              onClick={this.getSalesHistoryData.bind(this)}
            >
              <i className="fa-solid fa-filter"></i>
              &nbsp; Filter
            </button>
            &nbsp; &nbsp;
            <button
              className="resetButton"
              onClick={this.resetInvNumFilter.bind(this)}
            >
              <i className="fa fa-refresh"></i>
              &nbsp; Reset
            </button>
          </div>
        </div>
      );
    }
  }

  validateForm(e, type) {
    e.preventDefault();
    if (this.state.selectedHeaderTabIndex === 0) {
      let sdate = this.state.startDate;
      let edate = this.state.endDate;

      let sYear = new Date(sdate).getFullYear();
      let eYear = new Date(edate).getFullYear();
      let sMnt = new Date(sdate).getMonth();
      sMnt = parseInt(sMnt) + 1;

      let eMnt = new Date(edate).getMonth();
      eMnt = parseInt(eMnt) + 1;
      // let invoiceCategory = this.state.selectedCategory;

      if (new Date(sdate).getTime() > new Date(edate).getTime()) {
        PtsAlert.error("Start date should be Less than or Equals to End date");
        this.setState({
          startDate: format(new Date(), "yyyy-MM-dd"),
        });
        return true;
      }
      if (new Date(sdate).getTime() > new Date().getTime()) {
        PtsAlert.error("Future Date Not Allowed");
        this.setState({
          startDate: format(new Date(), "yyyy-MM-dd"),
        });
        return true;
      }
      if (new Date(edate).getTime() > new Date().getTime()) {
        PtsAlert.error("Future Date Not Allowed");
        this.setState({
          endDate: format(new Date(), "yyyy-MM-dd"),
        });
        return true;
      }
      if (sYear < eYear) {
        let calYr = parseInt(eYear) - parseInt(sYear);
        if (calYr > 1) {
          PtsAlert.error(
            "Difference between Start Date and End Date should not Exceed One Year"
          );
          return true;
        } else if (calYr === 1) {
          if (sMnt < eMnt) {
            PtsAlert.error(
              "Difference between Start Date and End Date should not Exceed One Year"
            );
            return true;
          }
        }
      }
    }
    if (this.state.selectedHeaderTabIndex === 1) {
      let invNumber = this.state.invNumber;
      if (Utils.isNotNullAndEmpty(invNumber)) {
        return false;
      } else {
        PtsAlert.error("Please Enter Invoice Number");
        return true;
      }
    }
    return false;
  }

  resetFilter() {
    this.setState({
      selectedCustomer: null,
      startDate: null,
      endDate: null,
      financialsYear: null,
      isAsset: false,
      error: false,
      errorMassage: false,
      selectedInvoice: this.state.invoiceTypeArray[0],
    });
    store.dispatch(appDataAction.setHistorySalesSearchData({}));
    this.resetSalesHistoryData();
  }

  resetInvNumFilter() {
    this.setState({
      invNumber: "",
    });
    store.dispatch(appDataAction.setHistorySalesSearchData({}));
    this.resetSalesHistoryData();
  }

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };
  handleExcelDownloadDropdownOpen = (e) => {
    this.setState({ downloadOpen: true, anchorEl1: e.currentTarget });
  };
  handleExcelDownloadDropdownClose = () => {
    this.setState({ downloadOpen: false, anchorEl1: null });
  };
  // filterRecords(e) {
  //   let startDate = this.state.startDate ? this.state.startDate : "";
  //   let endDate = this.state.endDate ? this.state.endDate : "";
  //    let selectedInvoice = this.state.selectedInvoice
  //      ? this.state.selectedInvoice.description
  //      : "";
  //   this.getSalesHistoryData(bucode, custid);
  // }

  render() {
    // const error = this.state.startDate === null;

    this.referenceInvoiceDialogueRef = React.createRef();
    if (this.state.render === false) {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
      );
    }
    /* Header Tabs */
    const headerTabList = this.salesHeaderTabList.map((expTab, keyIndex) => {
      return (
        <Tab
          style={{ minHeight: "1em" }}
          key={keyIndex}
          onClick={(e) => this.selectedInvoiceHeaderTab(expTab, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{expTab}</span>
        </Tab>
      );
    });

    const tabList = this.state.invoiceStatusArray.map((expStatus, keyIndex) => {
      return (
        <Tab
          key={keyIndex}
          onClick={(e) => this.selectedTab(expStatus, keyIndex)}
        >
          <span style={{ fontWeight: "600" }}>{expStatus.uiname}</span>
        </Tab>
      );
    });

    const tabPanelList = this.state.invoiceStatusArray.map((tab, keyIndex) => {
      return (
        <TabPanel key={keyIndex}>
          <PaymentDetailsDialogue
            ref={this.referenceInvoiceDialogueRef}
            style={{ float: "right" }}
          />
          {this.getTablesWithStatus(tab.status)}
        </TabPanel>
      );
    });

    let selectedHeaderIndex =
      store.getState().appData.historySalesHeaderSelectedTab;
    if (Utils.isNull(selectedHeaderIndex)) {
      this.state.selectedHeaderTabIndex = 0;
      selectedHeaderIndex = 0;
    }

    let selectedIndex = store.getState().appData.historySalesSelectedTab;
    if (Utils.isNull(selectedIndex)) {
      this.state.selectedTabIndex = 0;
      selectedIndex = 0;
    }
    const headerTabPanelList = this.salesHeaderTabList.map((tab, keyIndex) => {
      return (
        <TabPanel
          style={{
            minHeight: "1em",
            padding: "1em",
            border: "1px solid lightgray",
          }}
          key={keyIndex}
        >
          {this.getInvoiceHeaderTab(selectedHeaderIndex)}
        </TabPanel>
      );
    });

    // let searchHistoryDetails = {};
    // if (this.state.searchSaleHistoryDetails) {
    //   searchHistoryDetails = Object.getOwnPropertyNames(
    //     this.state.searchSaleHistoryDetails
    //   );
    // }
    return (
      <div>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        {this.state.selectedHeaderTabIndex === 0 ? (
          <div className="row">
            <div className="col-8 align-items-start text-start">
              <span className="ms-1">
                {/* {(this.state.selectedCustomer !== "" && this.state.selectedCustomer !== null) ||
                  (this.state.defaultStartDate !== "" && this.state.defaultStartDate !== null) ||
                  (this.state.defaultEndDate !== "" && this.state.defaultEndDate !== null) ||
                  (this.state.selectedInvoice && this.state.selectedInvoice !== null) ||
                  this.state.isAsset !== false ? ( */}
                <span>
                  {this.state.selectedCustomer &&
                  this.state.selectedCustomer !== null ? (
                    <span>
                      <b>Customer : </b>
                      {this.state.selectedCustomer?.name + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.defaultFinancialYear !== "" &&
                    this.state.defaultFinancialYear !== null && (
                      <span>
                        <b>Financial Year : </b>
                        {this.state.defaultFinancialYear + ", "}
                      </span>
                    )}{" "}
                  &nbsp;
                  {this.state.defaultStartDate !== "" &&
                    this.state.defaultStartDate !== null && (
                      <span>
                        <b>Start Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultStartDate) +
                          ", "}
                      </span>
                    )}
                  &nbsp;
                  {this.state.defaultEndDate !== "" &&
                    this.state.defaultEndDate !== null && (
                      <span>
                        <b>End Date : </b>
                        {Utils.ptsDateFormat(this.state.defaultEndDate) + ", "}
                      </span>
                    )}
                  {this.state.selectedInvoice &&
                  this.state.selectedInvoice !== null ? (
                    <span>
                      <b>Invoice Type : </b>
                      {this.state.selectedInvoice.description + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.selectedBusinessUnit &&
                  this.state.selectedBusinessUnit !== null ? (
                    <span>
                      <b>Business Unit : </b>
                      {this.state.selectedBusinessUnit.name + ", "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.isAsset && this.state.isAsset !== false ? (
                    <span>
                      <b>Is Asset Sales : </b>
                      {this.state.isAsset ? "Yes" : "No"}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                {/* ) : (
                  ""
                )} */}
              </span>
            </div>
            <div className="col-4 align-items-end text-end">
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleAccountDropdownOpen}
              >
                <FilterAltIcon
                  style={{ fontSize: "28px", color: "black" }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                />
                Filter
              </span>
              {"  "} &nbsp;&nbsp;
              {this.state.isFirstTimeLoading ? (
                <span
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={this.resetFilter.bind(this)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                </span>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleExcelDownloadDropdownOpen}
              >
                {/* <FilterAltIcon
                  style={{ fontSize: "28px", color: "black" }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                /> */}
                <Button
                  variant="contained"
                  size="small"
                  id="excelBtn"
                  aria-controls={
                    this.state.downloadOpen ? "dropdown-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={this.state.downloadOpen ? "true" : undefined}
                  style={{
                    background: "#5ac489",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                  startIcon={<i class="fa fa-download"></i>}
                  // onClick={(e) => this.downloadDayExcelFromBackend()}
                >
                  Download Excel
                </Button>
              </span>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-8 align-items-start text-start">
              <span className="ms-4">
                {this.state.invNumber ? (
                  <span>
                    {this.state.invNumber && this.state.invNumber !== null ? (
                      <span>
                        <b>Invoice Number: </b>
                        {this.state.invNumber}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="col-4 align-items-end text-end">
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleAccountDropdownOpen}
              >
                <FilterAltIcon
                  style={{ fontSize: "28px", color: "black" }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                />
                Filter
              </span>
              {"  "} &nbsp;&nbsp;
              {this.state.isFirstTimeLoading ? (
                <span
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={this.resetInvNumFilter.bind(this)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                </span>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              <span
                className="text-end"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={this.handleExcelDownloadDropdownOpen}
              >
                {/* <FilterAltIcon
                  style={{ fontSize: "28px", color: "black" }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                /> */}
                <Button
                  variant="contained"
                  size="small"
                  id="excelBtn"
                  aria-controls={
                    this.state.downloadOpen ? "dropdown-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={this.state.downloadOpen ? "true" : undefined}
                  style={{
                    background: "#5ac489",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                  startIcon={<i class="fa fa-download"></i>}
                  // onClick={(e) => this.downloadDayExcelFromBackend()}
                >
                  Download Excel
                </Button>
              </span>
            </div>
          </div>
        )}
        <Menu
          anchorEl={this.state.anchorEl}
          id="dropdown-menu"
          className="filterDropdownMenu"
          open={this.state.open}
          onClose={this.handleAccountDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <Row md={12}>
              <Tabs
                selectedIndex={selectedHeaderIndex}
                md={12}
                style={{
                  width: "100%",
                  color: "#1b7189",
                  outline: "none",
                  // margin: "0",
                }}
              >
                <TabList
                  style={{
                    minWidth: "5em",
                    color: "#1b7189",
                    outline: "none",
                    margin: "0",
                  }}
                >
                  {headerTabList}
                </TabList>
                {headerTabPanelList}
              </Tabs>
            </Row>
          </div>
        </Menu>
        <Menu
          anchorEl={this.state.anchorEl1}
          id="dropdown-menu1"
          className="filterDropdownMenu"
          open={this.state.downloadOpen}
          onClose={this.handleExcelDownloadDropdownClose}
          // onClick={this.handleAccountDropdownClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div
            className="dropdown-item"
            style={{ cursor: "pointer", width: "400px", height: "100%" }}
          >
            <div className="row">
              <h5 className="bold">Download Excel</h5>
              <div className="row">
                <div className="col-md-12" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-supplierType"
                  >
                    Customer
                  </label>
                  <Autocomplete
                    fullWidth
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    variant="outlined"
                    id="customerList"
                    options={this.state.customerList}
                    value={this.state.selectedExcelCustomer}
                    defaultValue={this.state.selectedExcelCustomer}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Select Customer"
                    onChange={(event, value) => {
                      this.updateExcelCustomer(event, value);
                    }}
                    style={{ background: "#fff", borderRadius: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        placeholder="Customer"
                        InputLabelProps={{
                          fontSize: "small !important",
                          style: { fontSize: "small !important" },
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row mt-2 d-flex">
                <div className="col-md-12" id="assetFormLabel">
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0px",
                      padding: "0",
                    }}
                    control={
                      <Switch
                        variant="outlined"
                        // id="isAsset"
                        checked={this.state.excelSwichCheck}
                        value={this.state.excelSwichCheck}
                        onChange={this.handleExcelSwichCheckChange.bind(this)}
                        style={{ background: "none", margin: "8px" }}
                      />
                    }
                    label={<span>Custom search </span>}
                  />
                </div>
              </div>
              <div className="row mt-2">
                {!this.state.excelSwichCheck && (
                  <div className="col-md-12" id="assetFormLabel">
                    <label
                      className="form-label "
                      // htmlFor="collapsible-companyName"
                    >
                      Financial Year
                    </label>
                    <br />
                    <Autocomplete
                      fullWidth
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                      variant="outlined"
                      id="supplierList"
                      options={this.financialsYearArray}
                      value={this.state.excelFinancialsYear}
                      defaultValue={this.state.excelFinancialsYear}
                      getOptionLabel={(option) => option}
                      style={{ background: "#fff", borderRadius: "5px" }}
                      onChange={(event, value) => {
                        this.updateExcelFinancialYear(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Financial Year"
                          className="regCountry"
                          InputLabelProps={{
                            fontSize: "small !important",
                            style: { fontSize: "small !important" },
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                {this.state.excelSwichCheck && (
                  <>
                    <div className="col-md-6" id="assetFormLabel">
                      <label
                        className="form-label "
                        // htmlFor="collapsible-companyName"
                      >
                        Start Date
                      </label>
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            clearable
                            value={this.state.excelStartDate}
                            // variant="inline"
                            margin="normal"
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            variant="outlined"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="dd-MM-yyyy"
                            onChange={this.handleExcelStartDateChange.bind(
                              this
                            )}
                            helperText={
                              this.state.excelErrorMassage
                                ? "Start Date is Required"
                                : ""
                            }
                            error={this.state.excelErrorMassage}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-6" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-companyName"
                      >
                        End Date
                      </label>{" "}
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            clearable
                            value={this.state.excelEndDate}
                            // variant="inline"
                            margin="normal"
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            variant="outlined"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="dd-MM-yyyy"
                            onChange={this.handleExcelEndDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    {this.state.excelError ? (
                      <lable className="errorMassageShow">
                        End Date should be after Start Date
                      </lable>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <div className="row mt-2">
                <div className="col-md-6" id="assetFormLabel">
                  {" "}
                  <label
                    className="form-label "
                    htmlFor="collapsible-companyName"
                  >
                    Invoice Type
                  </label>
                  <Autocomplete
                    // multiple
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    size="small"
                    id="tags-outlined"
                    style={{ background: "#fff", borderRadius: "5px" }}
                    options={
                      this.state.invoiceTypeArray
                        ? this.state.invoiceTypeArray
                        : []
                    }
                    getOptionLabel={(option) => option.description}
                    defaultValue={
                      this.state.selectedExcelInvoice
                        ? this.state.selectedExcelInvoice
                        : null
                    }
                    filterSelectedOptions
                    onChange={this.onExcelTypeChange.bind(this)}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="outlined"
                        className="regCountry"
                        InputLabelProps={{
                          shrink: true,

                          style: {},
                        }}
                        placeholder="Invoice Type"
                      />
                    )}
                  />
                </div>
                <div className="col-md-6" id="assetFormLabel">
                  <label
                    className="form-label "
                    htmlFor="collapsible-supplierType"
                  >
                    Business Unit
                  </label>
                  <Autocomplete
                    ListboxProps={{
                      className: "myCustomList",
                    }}
                    margin="normal"
                    className="expenseAutoComplete"
                    id="businessUnit"
                    style={{ background: "#fff", borderRadius: "5px" }}
                    options={this.businessUnitList}
                    value={this.state.selectedBusinessUnit}
                    defaultValue={this.state.selectedBusinessUnit}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.updateBusinessUnit(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Business Unit"
                        {...params}
                        style={{}}
                        variant="outlined"
                        className="regCountry"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12 mt-2" id="assetFormLabel">
                  <FormControlLabel
                    style={{
                      color: "#000",
                      width: "100%",
                      margin: "0",
                      padding: "0",
                    }}
                    control={
                      <Checkbox
                        variant="outlined"
                        id="isAsset"
                        checked={this.state.isExcelAsset}
                        value={this.state.isExcelAsset}
                        onChange={this.handleExcelAssetChange.bind(this)}
                        style={{ background: "none" }}
                      />
                    }
                    label={<span style={{}}>Is asset sale?</span>}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="fileterButton"
                  // onClick={this.onFilterClick.bind(this)}
                  // onClick={(e) => this.filterRecords(e)}
                  onClick={this.downloadDayExcelFromBackend.bind(this)}
                >
                  <i className="fa fa-download"></i>
                  &nbsp; Download
                </button>
                &nbsp; &nbsp;
                {/* <button
              className="resetButton"
              onClick={this.resetFilter.bind(this)}
            >
              <i className="fa fa-refresh"></i>
              &nbsp; Reset
            </button> */}
              </div>
            </div>
          </div>
        </Menu>
        <Row md={12} style={{ marginTop: "1em" }}>
          <Tabs
            md={12}
            selectedIndex={selectedIndex}
            style={{
              width: "100%",
              color: "#1b7189",
              outline: "none",
              margin: "0",
            }}
          >
            <TabList
              style={{
                minWidth: "5em",
                color: "#1b7189",
                outline: "none",
                margin: "0",
              }}
            >
              {" "}
              {tabList}
            </TabList>
            {tabPanelList}
          </Tabs>
        </Row>
      </div>
    );
  }
}

export default withStyles()(SalesHistory);
