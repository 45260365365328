import React from "react";
//  export default ({children})=>(
//         <>
//             <Header/>
//             {children}
//             <Footer/>
//         </>
//  )
import { Route } from "react-router-dom";
// import "../layouts/ApplicationLayout.scss";
import ContextProviders from "../pts/components/utilities/ContextProviders";
const LoginLayout = ({ children }) => (
  <div className="justify-content-center">{children}</div>
);

const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LoginLayout>
          <Component {...matchProps} />
        </LoginLayout>
      )}
    />
  );
};

export default LoginLayoutRoute;
